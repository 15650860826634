import React, { useEffect } from "react";
import { CardContent, Divider, Grid, Skeleton, TextField, styled } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useBuilding } from "../../../actions";
import { MCard, TransferList, useAlertContext } from "../../../components/Base";
import { LoadingButton } from "@mui/lab";
import { GroupMemberDto } from "../../../definitions/model/Group";
import MyhouseCheckbox from "../../../components/Base/MyhouseCheckbox";
import { TextFieldConnector } from "../../../components/Base/FormConnector";

const StyledForm = styled("form")(() => ({
  width: "100%",
}));

const BuildingManagerInfo = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<{ email: string }>({ defaultValues: { email: "" } });
  const [{ building, loading: buildingLoading }, buildingActions] = useBuilding();
  const [loading, setLoading] = React.useState(false);

  const alertContext = useAlertContext();

  useEffect(() => {
    if (building) {
      reset({ email: building.ManagerEmail });
    }
  }, [building]);

  const save = async (data: { email: string }) => {
    try {
      setLoading(true);
      if (building) {
        await buildingActions.updateManagerInfo(building.Id, data.email);
      }
    } catch (error) {
      alertContext.showAlert({ text: t("General.Errors.ErrorOccurred"), severity: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <MCard type="main" title={t("Building.BuildingManager")}>
      <CardContent>
        {buildingLoading && <Skeleton height={30} width="50%" variant="rounded"></Skeleton>}
        {!buildingLoading && (
          <StyledForm onSubmit={handleSubmit(save)}>
            <Grid container spacing={1} mb={1}>
              <Grid item xs={12} sm={6}>
                <Controller
                  rules={{ required: true }}
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("UserProfile.EmailAddress")}
                      fullWidth
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ""}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <LoadingButton
                            loading={loading}
                            type="submit"
                            variant="contained"
                            color="secondary"
                            disabled={!!errors.email}
                          >
                            {t("General.Buttons.Save")}
                          </LoadingButton>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </StyledForm>
        )}
        <Divider sx={{ marginTop: 2 }} />
        {buildingLoading && (
          <>
            <Skeleton height={20} width={50} variant="rounded" sx={{ marginBottom: 1, marginTop: 1 }}></Skeleton>
            <Skeleton height={30} width="100%" variant="rounded" sx={{ marginBottom: 1 }}></Skeleton>
            <Skeleton height={150} width="100%" variant="rounded" sx={{ marginBottom: 1 }}></Skeleton>
          </>
        )}
        {!buildingLoading && <BroadcastToTenants />}
      </CardContent>
    </MCard>
  );
};

interface Tenant extends GroupMemberDto {
  Address: string;
}

type TenantsMessageFormType = {
  tenants: Tenant[];
  message: string;
  buildingId: string;
};
const BroadcastToTenants = () => {
  const { t } = useTranslation();
  const [{ building }, buildingActions] = useBuilding();
  const alertContext = useAlertContext();
  const tenants =
    building?.Units.filter((u) => u.Id !== building.OfficeUnitId)
      .flatMap((u) =>
        u.Members.map((member) => ({
          ...member,
          Address: `${u.Address.HouseNo} ${u.Address.DoorNumber}-${u.Address.Floor}`,
        }))
      )
      .sort((a, b) => a.Address.localeCompare(b.Address)) ?? [];
  const distinctTenants = [...new Map(tenants.map((item) => [item.UserId, item])).values()];
  const {
    handleSubmit,
    register,
    unregister,
    setValue,
    formState: { errors },
  } = useForm<TenantsMessageFormType>({ defaultValues: { tenants: [], message: "" } });
  const [loading, setLoading] = React.useState(false);
  const [selectedTenants, setSelectedTenants] = React.useState<Tenant[]>([]);

  React.useEffect(() => {
    register("tenants", { required: true });
    register("buildingId", { required: true });

    return () => {
      unregister("tenants");
      unregister("buildingId");
    };
  }, []);

  useEffect(() => {
    if (building) setValue("buildingId", building.Id);
  }, [building]);

  const ckeckAllTenants = (checked: boolean) => {
    if (checked) {
      setValue("tenants", distinctTenants);
      setSelectedTenants(distinctTenants);
    } else {
      setValue("tenants", []);
      setSelectedTenants([]);
    }
  };
  const sendMessageToTenants = async (data: TenantsMessageFormType) => {
    setLoading(true);
    try {
      await buildingActions.sendMessageToTenants(
        data.buildingId,
        data.tenants.map((t) => t.UserId),
        data.message
      );
      setValue("tenants", []);
      setValue("message", "");
      setSelectedTenants([]);
    } catch (error) {
      alertContext.showAlert({ text: t("General.Errors.ErrorOccurred"), severity: "error" });
    } finally {
      setLoading(false);
    }
  };
  return (
    <StyledForm onSubmit={handleSubmit(sendMessageToTenants)}>
      <Grid container mt={1}>
        <Grid container item direction="column" xs={12} sx={{ flexWrap: "nowrap" }} mt={1}>
          <Grid item sx={{ marginLeft: 0.5, marginBottom: 1 }}>
            <MyhouseCheckbox size="small" onChange={(e) => ckeckAllTenants(e.target.checked)} label={"Select all"} />
          </Grid>
          <Grid item xs={12} sx={{}}>
            <TransferList
              options={distinctTenants}
              selected={selectedTenants}
              fieldLabel={t("Building.BroadcastToTenants")}
              placeholder={t("Building.BroadcastToTenants")}
              onChanges={(s) => {
                setValue("tenants", s);
                setSelectedTenants(s);
              }}
              getOptionLabel={(o) => `${o.Address} / ${o.Name}`}
              getOptionSelected={(s, o) => s.UserId === o.UserId}
              error={!!errors.tenants}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={1}>
          <TextFieldConnector register={register("message", { required: true })}>
            <TextField
              multiline
              rows={5}
              placeholder={t("Building.BroadcastToTenantsText")}
              fullWidth
              variant="outlined"
              error={!!errors.message}
            />
          </TextFieldConnector>
        </Grid>
        <Grid item container sx={{ justifyContent: "flex-end", alignItems: "center" }} mt={1}>
          <Grid item>
            <LoadingButton loading={loading} type="submit" variant="contained" color="secondary">
              {t("General.Buttons.Send")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </StyledForm>
  );
};

export default BuildingManagerInfo;
