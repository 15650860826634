import ApiService from "../../services/api.service";
import {
  LinkRequestState,
  WorkerLinkRequest,
  WorkerModel,
  WorkerRatingDetails,
  CreateCustomWorker,
  WorkerCompany,
  CompanyAutocompleteItem,
  CustomWorkerRequest,
} from "../../definitions/model/Worker";
import { SearchResult } from "../../definitions/model/SearchResult";

const WorkersService = {
  getWorkers0: async (
    postCode: number,
    communeCode: number[],
    workerType: number[],
    name: string,
    skip: number,
    take: number
  ) => {
    const typeParam = workerType.map((t) => "&type=" + t).join("");
    const codes = communeCode.map((t) => "&communeCode=" + t).join("");
    const url = `/api/workers?postCode=${postCode}${codes}${typeParam}&name=${name}&skip=${skip}&take=${take}`;
    const result = await ApiService.get<SearchResult<WorkerModel>>(url);
    return result;
  },

  getWorkers: async (customWorkerRequest: CustomWorkerRequest, skip: number, take: number) => {
    const params = new URLSearchParams();
    params.append("skip", skip.toString());
    params.append("take", take.toString());

    // Add the customWorkerRequest parameters
    Object.entries(customWorkerRequest).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((val) => params.append(key, val.toString()));
      } else if (value !== undefined && value !== null) {
        params.append(key, value.toString());
      }
    });

    const url = `/api/workers?${params.toString()}`;
    const result = await ApiService.get<SearchResult<WorkerModel>>(url);
    return result;
  },

  getWorker: async (workerId: string) => {
    const url = `/api/workers/${workerId}`;
    const result = await ApiService.get<WorkerModel>(url);
    return result;
  },
  getUnitWorkers: async (unitId: string): Promise<WorkerModel[]> => {
    const url = `/api/workers/units/${unitId}`;
    const result = await ApiService.get<WorkerModel[]>(url);
    return result;
  },
  getBuildingWorkers: async (buildingId: string): Promise<WorkerModel[]> => {
    const url = `/api/workers/buildings/${buildingId}`;
    const result = await ApiService.get<WorkerModel[]>(url);
    return result;
  },
  linkWorkerToUnit: async (workerId: string, unitId: string): Promise<WorkerModel> => {
    const url = `/api/workers/${workerId}/units/${unitId}`;
    const result = await ApiService.post<WorkerModel>(url, null);
    return result;
  },
  unlinkWorkerFromUnit: async (workerId: string, unitId: string): Promise<WorkerModel> => {
    const url = `/api/workers/${workerId}/units/${unitId}`;
    const result = await ApiService.delete<WorkerModel>(url, null);
    return result;
  },
  linkWorkerToBuilding: async (workerId: string, buildingId: string): Promise<WorkerModel> => {
    const url = `/api/workers/${workerId}/buildings/${buildingId}`;
    const result = await ApiService.post<WorkerModel>(url, null);
    return result;
  },
  unlinkWorkerFromBuilding: async (workerId: string, buildingId: string): Promise<WorkerModel> => {
    const url = `/api/workers/${workerId}/buildings/${buildingId}`;
    const result = await ApiService.delete<WorkerModel>(url, null);
    return result;
  },
  rateUnitWorker: async (workerId: string, unitId: string, rating: number, comment: string | null): Promise<boolean> => {
    const url = `/api/workers/${workerId}/units/${unitId}/rate`;
    const model = {
      rating,
      comment,
    };
    return await ApiService.put<boolean>(url, model);
  },
  rateBuildingWorker: async (workerId: string, buildingId: string, rating: number, comment: string | null): Promise<boolean> => {
    const url = `/api/workers/${workerId}/buildings/${buildingId}/rate`;
    const model = {
      rating,
      comment,
    };
    return await ApiService.put<boolean>(url, model);
  },
  getDetailedRatings: async (workerIds: string[]): Promise<WorkerRatingDetails[]> => {
    const url = "/api/workers/getDetailedRatings";
    return await ApiService.post<WorkerRatingDetails[]>(url, workerIds);
  },
  getWorkerLinkRequests: async (houseId: string): Promise<SearchResult<WorkerLinkRequest>> => {
    const url = "/api/workers/link/" + houseId;
    return await ApiService.get<SearchResult<WorkerLinkRequest>>(url);
  },
  processWorkerLinkRequest: async (workerId: string, unitId: string, state: LinkRequestState): Promise<any> => {
    const url = "/api/workers/link/";
    return await ApiService.post<any>(url, { workerId, unitId, state });
  },
  createWorker: async (worker: CreateCustomWorker): Promise<WorkerModel> => {
    const url = "/api/workers/createWorker";
    const result = await ApiService.post<WorkerModel>(url, { ...worker });
    return result;
  },
  getCompanies: async (name: string): Promise<SearchResult<CompanyAutocompleteItem>> => {
    const url = `/api/workers/company/search?name=${name}`;
    const result = await ApiService.get<SearchResult<CompanyAutocompleteItem>>(url);
    return result;
  },
  createCompany: async (company: WorkerCompany): Promise<CompanyAutocompleteItem> => {
    const url = `/api/workers/company`;
    return await ApiService.post<CompanyAutocompleteItem>(url, company);
  },
  toggleCraftsmanGreenTag: async (workerId: string) => {
    const result = await ApiService.post<any>(`/api/workers/green/${workerId}`, null);
    return result;
  },
};

export default WorkersService;
