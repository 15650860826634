import i18next from "i18next";
import { CraftsmanType } from "../definitions/model/Worker";


const WorkerHelper = {
  GetWorkerTypes: (searchString: string): number[] => {
    let types = [] as number[];
    if (searchString.length > 2) {
      const typeByString = CraftsmanType.filter((type) => {
        const translatedValue = i18next.t(type.value).toLowerCase();
        const translatedValues = translatedValue.split(" ");
        return translatedValues.some((value: string) => value === searchString.trim().toLowerCase());
      });

      if (typeByString.length > 0) {
        types = typeByString.map((type) => type.key);
      }
    }
    return types;
  },
};

export default WorkerHelper;
