import React, { useEffect, useReducer } from "react";
import Grid from "@mui/material/Grid";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  DashboardActionRequest,
  DashboardEntryModel,
  SubscriptionChangeActionPayload,
} from "../../../../definitions/model/Dashboard";
import { AppThunkDispatch } from "../../../../definitions/Action";
import {
  changeDashboardSubscription,
  changeLocalSubscription,
} from "../../../../actions/admin/admin.actions";
import { connect, ConnectedProps } from "react-redux";
import { DashboardSubscriptionFrequency } from "../../../../constants/enums";
import Fade from "@mui/material/Fade";

type SubscriptionsState = {
  alarm: boolean;
  subscription: boolean;
  subscriptonFrequency: number;
};

const initialState: SubscriptionsState = {
  alarm: false,
  subscription: false,
  subscriptonFrequency: 0,
};

const reducer = (
  state: SubscriptionsState,
  action: { type: keyof SubscriptionsState | "set"; payload: unknown }
) => {
  if (action.type === "set") {
    return { ...(action.payload as SubscriptionsState) };
  }
  return { ...state, [action.type]: action.payload };
};

type SubscriptionsProps = {
  entry: DashboardEntryModel;
};

const Subscriptions = (
  props: SubscriptionsProps & SubscriptionConnectedProps
): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });

  const changeSubscribeStatus = (
    frequency: DashboardSubscriptionFrequency,
    entry: DashboardEntryModel,
    subscribe: boolean
  ) => {
    props.changeDashboardSubscription({
      Id: entry.Id,
      ServiceName: entry.Name,
      Frequency: frequency,
      Method: subscribe
        ? "notifications/subscribe"
        : "notifications/unsubscribe",
    } as DashboardActionRequest);

    props.changeLocalSubscription({
      entryId: entry.Id,
      isActive: subscribe,
      isAlarm: false,
      frequency,
    });
  };

  const handleAlarmSubscriptionChange = (
    entry: DashboardEntryModel,
    subscribe: boolean
  ) => {
    props.changeDashboardSubscription({
      Id: entry.Id,
      ServiceName: entry.Name,
      Method: subscribe ? "alarm/subscribe" : "alarm/unsubscribe",
    } as DashboardActionRequest);

    props.changeLocalSubscription({
      entryId: entry.Id,
      isActive: subscribe,
      isAlarm: true,
    });
  };

  useEffect(() => {
    dispatch({
      type: "set",
      payload: {
        subscription: props.entry.Subscribed,
        subscriptonFrequency: props.entry?.SubscriptionFrequency ?? 0,
        alarm: props.entry.AlarmSubscription,
      },
    });

    return () => {
      dispatch({
        type: "set",
        payload: initialState,
      });
    };
  }, [props.entry]);

  const handleSubscriptionChange = (e: unknown, checked: boolean) => {
    dispatch({ type: "subscription", payload: checked });
    changeSubscribeStatus(state.subscriptonFrequency, props.entry, checked);
  };

  const handleAlarmChange = (e: unknown, checked: boolean) => {
    dispatch({ type: "alarm", payload: checked });
    handleAlarmSubscriptionChange(props.entry, checked);
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={10}>
        <FormControlLabel
          label="Alarm"
          control={
            <Switch checked={state.alarm} onChange={handleAlarmChange} />
          }
        />
      </Grid>
      <Grid item xs={10}>
        <FormControlLabel
          label="Subscription"
          control={
            <Switch
              checked={state.subscription}
              onChange={handleSubscriptionChange}
            />
          }
        />
      </Grid>
      <Grid item xs={10} container justifyContent="center">
        <Fade in={state.subscription}>
          <ToggleButtonGroup
            value={state.subscriptonFrequency}
            size="small"
            color="secondary"
            exclusive
            onChange={(e, v) => {
              dispatch({ type: "subscriptonFrequency", payload: v });
              changeSubscribeStatus(v, props.entry, state.subscription);
            }}
          >
            <ToggleButton value={0}>On fail</ToggleButton>
            <ToggleButton value={1}>Daily</ToggleButton>
            <ToggleButton value={2}>Weekly</ToggleButton>
            <ToggleButton value={3}>Monthly</ToggleButton>
          </ToggleButtonGroup>
        </Fade>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  changeDashboardSubscription: (data: DashboardActionRequest) =>
    dispatch(changeDashboardSubscription(data)),
  changeLocalSubscription: (data: SubscriptionChangeActionPayload) =>
    dispatch(changeLocalSubscription(data)),
});

const connector = connect(null, mapDispatchToProps);

type SubscriptionConnectedProps = ConnectedProps<typeof connector>;

export default connector(Subscriptions);
