import ApiService from "../../../../services/api.service";
import {
  PricePredictionModel,
  PredictionTestUnitModel,
  UpdatePreditcionContract,
} from "./defenitions";

export const PricePredictionsService = {
  async getPredictionModels(): Promise<PricePredictionModel[]> {
    const url = "/api/admin/predictions";
    const result = await ApiService.get<PricePredictionModel[]>(url);
    return result;
  },
  async deletePredictionModel(id: string): Promise<boolean> {
    const url = "/api/admin/predictions/" + id;
    const result = await ApiService.delete<boolean>(url);
    return result;
  },
  async editPredictionModel(data: UpdatePreditcionContract): Promise<PricePredictionModel> {
    const url = `/api/admin/predictions/${data.Id}`;
    return await ApiService.post<PricePredictionModel>(url, data);
  },
  async activatePredictionModel(id: string): Promise<void> {
    const url = `/api/admin/predictions/${id}/activate`;
    return await ApiService.post(url, null);
  },
  async reinitPredictions(): Promise<boolean> {
    const url = `/api/admin/predictions/reinit`;
    return await ApiService.put<boolean>(url, null);
  },
  async renewPredictions(): Promise<PredictionTestUnitModel[]> {
    const url = `/api/admin/predictions/renew`;
    return await ApiService.post<PredictionTestUnitModel[]>(url, null);
  },
  async renewOnePrediction(id: string): Promise<PredictionTestUnitModel> {
    const url = `/api/admin/predictions/units/${id}/renew`;
    return await ApiService.post<PredictionTestUnitModel>(url, null);
  },
  async getPredictionTestUnits(): Promise<PredictionTestUnitModel[]> {
    const url = "/api/admin/predictions/units";
    const result = await ApiService.get<PredictionTestUnitModel[]>(url);
    return result;
  },
  async addUnitForPredictionTests(
    unitId: string
  ): Promise<PredictionTestUnitModel> {
    const url = `/api/admin/predictions/units/${unitId}`;
    const result = await ApiService.put<PredictionTestUnitModel>(url, null);
    return result;
  },
  async deleteUnitForPredictionTests(testUnitId: string): Promise<string> {
    const url = `/api/admin/predictions/units/${testUnitId}`;
    const result = await ApiService.delete<string>(url);
    return result;
  },
};
