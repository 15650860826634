import React, { useContext, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { CO2EmissionSource } from "../../CO2Plan";
import CO2TargetValueSourceComponent, { StyledSourceComponentGrid } from "./CO2TargetValueSourceComponent";
import CO2TargetOptionsSourceComponent from "./CO2TargetOptionsSourceComponent";
import CO2TargetHighLevelSourceComponent from "./CO2TargetHighLevelSourceComponent";
import { useTranslation } from "react-i18next";
import Co2TargetSubOptionsSourceComponent, {
  Co2TargetPerdaySelectionComponent,
} from "./Co2TargetSubOptionsSourceComponent";
import { CO2Context } from "../CO2ContextWrapper";

type HierarchyItem = {
  Id: string;
  Code: number;
  Name: string;
  IsSelected: boolean;
  Value?: number | null;
  CombinedValue?: number | null;
  CombinedValueInitial?: number | null;
};

type HierarchyLevel =
  | {
      Items: HierarchyItem[];
      SelectedItemId: string;
      Type: "source";
      Order: number;
      Sources: CO2EmissionSource[];
    }
  | {
      Items: HierarchyItem[];
      SelectedItemId: string;
      SourceId: string;
      Type: "option";
    }
  | {
      Value: number;
      Source: CO2EmissionSource;
      Type: "value";
    }
  | {
      Items: HierarchyItem[];
      Source: CO2EmissionSource;
      SelectedItemId: string;
      SourceValue: number | null;
      Type: "subValue";
    }
  | {
      Source: CO2EmissionSource;
      Sources: CO2EmissionSource[];
      IsTarget: boolean;
      selected: number;
      Type: "perDay";
    };

type ElementToDisplay = HierarchyLevel | string;

type CO2TargetEmissionSourcesProps = {
  displayTemplate: string | null;
  highLevelSources: CO2EmissionSource[];
  personId: string | null;
};

const CO2TargetEmissionSourcesComponent = (props: CO2TargetEmissionSourcesProps): JSX.Element => {
  const [initialElements, setInitialElements] = useState<ElementToDisplay[][]>([]);
  const [targetElements, setTargetElements] = useState<ElementToDisplay[][]>([]);
  const [isValueOptimal, setIsValueOptimal] = useState<boolean | undefined>();
  const [isValueInputVisible, setIsValueInputVisible] = useState(false);
  const { isMultiSelect, targetValue } = useContext(CO2Context);
  const { i18n } = useTranslation("translation");

  useEffect(() => {
    const initialHierarchy = props.highLevelSources
      .filter((s) => s.IsInitiallySelected)
      .map((s) => {
        return buildHierarchy(props.highLevelSources, s, false);
      });

    const targetHierarchy = props.highLevelSources
      .filter((s) => s.IsSelected)
      .sort((a, b) => a.Order - b.Order)
      .map((s) => {
        return buildHierarchy(props.highLevelSources, s, true);
      });

    const CheckIsOptimal = (hierarchy: HierarchyLevel[]): boolean => {
      return (
        hierarchy
          .map((level) => {
            if (level.Type === "source" || level.Type === "option") {
              const selected = level.Items.find((item) => item.Id === level.SelectedItemId);
              return selected?.Code === 1;
            }
            return true;
          })
          .filter((isOptimalSelection) => !isOptimalSelection).length === 0
      );
    };

    setIsValueOptimal(
      initialHierarchy.every((hierarchy) => CheckIsOptimal(hierarchy)) &&
        targetHierarchy.every((hierarchy) => CheckIsOptimal(hierarchy))
    );

    setInitialElements(initialHierarchy.map((h) => buildElementsToDisplay(h)));
    setTargetElements(targetHierarchy.map((h) => buildElementsToDisplay(h)));
  }, [props.highLevelSources, i18n.language]);

  useEffect(() => {
    setIsValueInputVisible(targetElements.some((el) => el.some((el) => typeof el !== "string" && el.Type === "value")));
  }, [targetElements]);

  const buildHierarchy = (
    currentLevelSources: CO2EmissionSource[],
    selectedSource: CO2EmissionSource,
    isTarget: boolean
  ): HierarchyLevel[] => {
    // if (isTarget) {
    //   if (targetItem) {
    //     source = currentLevelSources.find((s) => s.Id == targetItem.Id);
    //   } else {
    //     source = currentLevelSources.filter((s) => s.IsSelected)[
    //       props.index ?? 0
    //     ];
    //     setTargetItem(source);
    //   }
    // }
    // if (!source && !isTarget) {
    //   source = currentLevelSources.filter((s) => s.IsInitiallySelected)[
    //     props.index ?? 0
    //   ];
    // }

    // const source = source;

    const level: HierarchyLevel = {
      Items: currentLevelSources.map((src) => {
        return {
          Id: src.Id,
          Code: src.Code,
          Name: src.TargetName || src.Name,
          IsSelected: isTarget ? src.IsSelected : src.IsInitiallySelected ?? false,
          Value: src.Value,
          CombinedValue: src.CombinedValue,
        };
      }),
      SelectedItemId: selectedSource?.Id ?? "",
      Order: selectedSource.Order,
      Sources: currentLevelSources,
      Type: "source",
    };
    if (selectedSource.HasChildSources) {
      const selectedChild = selectedSource.ChildSources.find((c) => (isTarget ? c.IsSelected : c.IsInitiallySelected));
      if (selectedChild) {
        const nestedLevel = buildHierarchy(selectedSource.ChildSources, selectedChild, isTarget);

        return [level, ...nestedLevel];
      }
      return [level];
    }
    if (selectedSource.HasOptions) {
      const nestedOptionsLevel: HierarchyLevel = {
        Items: selectedSource.Options.map((option) => {
          return {
            Id: option.Id,
            Code: option.Code,
            Name: option.TargetName || option.Name,
            IsSelected: option.IsSelected,
          };
        }),
        SourceId: selectedSource.Id,
        SelectedItemId: (isTarget ? selectedSource?.SelectedOptionId : selectedSource?.SelectedInitialOptionId) ?? "",
        Type: "option",
      };

      return [level, nestedOptionsLevel];
    }

    const result: HierarchyLevel[] = [level];

    if (selectedSource.ThingEmission) {
      const sourceValue =
        selectedSource.ChildSources?.find((s) => (isTarget ? s.IsSelected : s.IsInitiallySelected)) ?? selectedSource;
      const nestedOptionsLevel: HierarchyLevel = {
        Value: isTarget ? sourceValue.Value! : sourceValue.ValueInitial!,
        Source: sourceValue,
        Type: "value",
      };

      result.push(nestedOptionsLevel);

      if (selectedSource.CombinedMaxAmount != null && selectedSource.ChildSources && isMultiSelect) {
        const nestedSubValueLevel: HierarchyLevel = {
          Items: selectedSource.ChildSources.map((option) => {
            return {
              Id: option.Id,
              Code: option.Code,
              Name: option.TargetName || option.Name,
              IsSelected: option.IsSelected,
              Value: option.Value,
            };
          }),
          Source: selectedSource,
          SourceValue: selectedSource.Value,
          SelectedItemId:
            selectedSource.ChildSources.find((x) => (isTarget ? x.IsSelected : x.IsInitiallySelected))?.Id || "",
          Type: "subValue",
        };
        result.push(nestedSubValueLevel);
      }
    }

    if (selectedSource.CombinedMaxAmount != null) {
      if (result.filter((r) => r.Type === "value")?.length === 0) {
        const nestedOptionsLevel: HierarchyLevel = {
          Value: (selectedSource.Value || selectedSource.ValueInitial) ?? 0,
          Source: selectedSource,
          Type: "value",
        };

        result.push(nestedOptionsLevel);
      }

      if (isMultiSelect) {
        const nestedPerDayValue: HierarchyLevel = {
          Source: selectedSource,
          Sources: currentLevelSources,
          IsTarget: isTarget,
          Type: "perDay",
          selected: isTarget ? selectedSource.CombinedValue ?? 0 : selectedSource.CombinedValueInitial ?? 0,
        };

        result.push(nestedPerDayValue);
      }
    }

    return result;
  };

  const buildElementsToDisplay = (hierarchy: HierarchyLevel[]): ElementToDisplay[] => {
    // combine parts of display template and hierarchy into a single array
    const displayTemplateSplitted = (props.displayTemplate ?? "").split("{%}");
    let sourceIsTemplate = true;
    const result: ElementToDisplay[] = [];
    while (hierarchy.length > 0 || displayTemplateSplitted.length > 0) {
      const source: HierarchyLevel[] | string[] = sourceIsTemplate ? displayTemplateSplitted : hierarchy;
      if (source.length === 0 && !sourceIsTemplate) {
        break;
      }

      if (source.length > 0) {
        result.push(source[0]);
        source.shift();
      }
      sourceIsTemplate = !sourceIsTemplate;
    }

    return result;
  };

  const { t } = useTranslation("translation");
  return (
    <Grid item container justifyContent="center">
      <Grid item container justifyContent={"center"} spacing={2}>
        {!isValueOptimal && (
          <>
            <Grid item container direction="row">
              <Grid item container direction="column" alignItems="flex-start" justifyContent="flex-start" xs={2} pt={3}>
                <Typography sx={{ lineHeight: 1.5 }} variant="subtitle1">
                  {t("ClimatePlan.General.Today")}
                </Typography>
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                sx={{ flex: 1, maxWidth: "fit-content" }}
                direction={initialElements.length > 1 ? "column" : "row"}
                py={2}
              >
                {initialElements.map((e, i) => (
                  <ElementsDisplayComponent
                    key={"initial_" + i}
                    elements={e}
                    disabled={true}
                    personId={props.personId}
                    index={i}
                  />
                ))}
                {isValueInputVisible && (
                  <CO2TargetValueSourceComponent
                    source={props.highLevelSources.reduce((acc, item) => {
                      if (item.ValueInitial && acc.ValueInitial) {
                        if (
                          !acc.IsInitiallySelected ||
                          (item.IsInitiallySelected && item.ValueInitial > acc.ValueInitial)
                        ) {
                          return item;
                        }
                      }
                      return acc;
                    }, props.highLevelSources[0])}
                    personId={props.personId}
                    sources={props.highLevelSources}
                    itemsCount={initialElements.length}
                    disabled
                    isInitial
                    withBg={initialElements.length > 1}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item container direction="row">
              <Grid item container direction="column" alignItems="flex-start" xs={2} pt={3}>
                <Typography sx={{ lineHeight: 1.5 }} variant="subtitle1">
                  {t("ClimatePlan.General.Future")}
                </Typography>
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                sx={{ flex: 1, maxWidth: "fit-content" }}
                direction={initialElements.length > 1 ? "column" : "row"}
                py={2}
              >
                {targetElements.map((e, i) => (
                  <ElementsDisplayComponent
                    key={"target_" + i}
                    elements={e}
                    disabled={false}
                    personId={props.personId}
                    index={i}
                  />
                ))}
                {isValueInputVisible && (
                  <CO2TargetValueSourceComponent
                    source={props.highLevelSources.reduce((acc, item) => {
                      if (item.Value && acc.Value) {
                        if (!acc.IsSelected || (item.IsSelected && item.Value > acc.Value)) {
                          if (targetValue && item.Value != targetValue) return { ...item, Value: targetValue };
                          return item;
                        }
                      }
                      return acc;
                    }, props.highLevelSources[0])}
                    personId={props.personId}
                    sources={props.highLevelSources}
                    itemsCount={targetElements.length}
                    withBg={initialElements.length > 1}
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}
        {isValueOptimal && (
          <Grid item py={2}>
            <Typography align="center">{t("ClimatePlan.TargetSurvey.YouAreClimateHero")}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CO2TargetEmissionSourcesComponent;

const ElementsDisplayComponent = (props: {
  elements: ElementToDisplay[];
  personId: string | null;
  disabled: boolean;
  index: number;
}) => {
  return (
    <StyledSourceComponentGrid item withBackground={props.index % 2} alignItems="center" justifyContent="flex-start">
      <Grid item container alignItems="center">
        {props.elements.map((element, index) => {
          if (typeof element === "string") {
            if (element.length > 0) {
              return (
                <Grid item px={0.5} key={"" + index}>
                  <Typography variant="body1">{element}</Typography>
                </Grid>
              );
            } else {
              return <span key={index} />;
            }
          }
          switch (element.Type) {
            case "source":
              return (
                <Grid item key={"" + index}>
                  <CO2TargetHighLevelSourceComponent
                    childSources={element.Items}
                    selectedSourceId={element.SelectedItemId}
                    order={element.Order}
                    personId={props.personId}
                    disabled={props.disabled}
                    key={"" + index}
                    sources={element.Sources}
                  />
                </Grid>
              );
            case "option":
              return (
                <Grid item key={"" + index}>
                  <CO2TargetOptionsSourceComponent
                    options={element.Items}
                    selectedOption={element.SelectedItemId}
                    personId={props.personId}
                    sourceId={element.SourceId}
                    disabled={props.disabled}
                    key={"" + index}
                  />
                </Grid>
              );
            case "subValue":
              return (
                <Grid item key={"" + index}>
                  <Co2TargetSubOptionsSourceComponent
                    options={element.Items}
                    selectedOption={element.SelectedItemId !== "" ? element.SelectedItemId : element.Items[0].Id}
                    sourceValue={element.SourceValue}
                    personId={props.personId}
                    disabled={props.disabled}
                    key={"" + index}
                  />
                </Grid>
              );

            case "perDay":
              return (
                <Grid item key={"" + index}>
                  <Co2TargetPerdaySelectionComponent
                    personId={props.personId}
                    source={element.Source}
                    sources={element.Sources}
                    value={element.selected}
                    isTarget={element.IsTarget}
                    disabled={props.disabled}
                    key={"" + index}
                  />
                </Grid>
              );
          }
        })}
      </Grid>
    </StyledSourceComponentGrid>
  );
};
