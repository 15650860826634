import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useAlertContext } from "../../../../../components/Base";
import { ArticleCategory } from "../../../../../constants/enums";
import { ArticleModel } from "../../../../../definitions/model/Club";
import TransferList, {
  TransferListItem,
} from "../../../../../components/Base/TransferList";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";
import CircularProgressButton from "../../../../../components/Base/CircularProgressButton";

type UpdateArticleFormData = {
  Title: string;
  UniqueName: string;
  ClubId: string;
  IsPublished: boolean;
  Categories: ArticleCategory[];
  Tag: string;
};

type ArticleFormProps = {
  article: ArticleModel;
  updateArticle: (article: ArticleModel) => Promise<ArticleModel | null>;
};

const LandingForm = ({
  article,
  updateArticle,
}: ArticleFormProps): JSX.Element => {
  const methods = useForm<UpdateArticleFormData>({
    defaultValues: {
      Title: article?.Title ?? "",
      ClubId: article?.ClubId ?? "",
      UniqueName: article?.UniqueName ?? "",
      IsPublished: article?.IsPublished ?? false,
      Categories: article?.Categories ?? [],
      Tag: article?.Tag ?? "",
    },
  });

  const { showAlert } = useAlertContext();

  const [isUpdating, setIsUpdating] = useState(false);

  const {
    handleSubmit,
    register,
    unregister,
    formState: { errors },
    setValue,
  } = methods;

  useEffect(() => {
    register("Categories");
    return () => {
      unregister("Categories");
    };
  }, [register, unregister]);

  const availableCategories: Array<TransferListItem> = Object.entries(
    ArticleCategory
  )
    .filter(([key, value]) => {
      return (
        typeof value === "string" &&
        (article.Categories ?? []).indexOf(
          ArticleCategory[value as keyof typeof ArticleCategory]
        ) === -1
      );
    })
    .map(([key, value]) => {
      const listItem: TransferListItem = {
        name: value.toString(),
        pageValue: "",
        subPageValue: value.toString(),
      };
      return listItem;
    });

  const initiallySelectedCategories: Array<TransferListItem> = (
    article?.Categories ?? []
  ).map((category) => {
    const listItem: TransferListItem = {
      name: ArticleCategory[category],
      pageValue: "",
      subPageValue: ArticleCategory[category],
    };
    return listItem;
  });

  const togglePublish = () => {
    setIsUpdating(true);
    updateArticle({
      ...article,
      IsPublished: !article.IsPublished,
    }).finally(() => setIsUpdating(false));
  };

  const onSumbit = (formData: UpdateArticleFormData) => {
    setIsUpdating(true);
    updateArticle({
      ...article,
      ...formData,
    })
      .then(() => {
        showAlert({ severity: "success", text: "Template updated" });
      })
      .catch(() =>
        showAlert({ severity: "error", text: "Template not updated" })
      )
      .finally(() => setIsUpdating(false));
  };

  const setCategories = (selected: TransferListItem[]) => {
    const categories = selected.map(
      (item) => ArticleCategory[item.name as keyof typeof ArticleCategory]
    );

    setValue("Categories", categories);
  };

  return (
    <form onSubmit={handleSubmit(onSumbit)} style={{ width: "100%"}}>
      <Grid container spacing={2}>
        <Grid item container xs={12} justifyContent="center">
          <TextFieldConnector register={register("Title", { required: true })}>
            <TextField
              variant="outlined"
              fullWidth
              label="Title"
              margin="dense"
              error={!!errors.Title}
            />
          </TextFieldConnector>
          <TextFieldConnector
            register={register("UniqueName", { required: true })}
          >
            <TextField
              variant="outlined"
              fullWidth
              label="Unique name"
              margin="dense"
              error={!!errors.UniqueName}
            />
          </TextFieldConnector>
          <Grid item xs={12}>
            <TransferList
              options={availableCategories}
              selected={initiallySelectedCategories}
              fieldLabel="Categories"
              placeholder="Select categories"
              onChanges={setCategories}
              getOptionLabel={(o: TransferListItem) => o.name}
              getOptionSelected={(s: TransferListItem, o: TransferListItem) => {
                return (
                  s.pageValue === o.pageValue &&
                  s.subPageValue === o.subPageValue
                );
              }}
            />
          </Grid>
          <TextFieldConnector register={register("Tag")}>
            <TextField
              variant="outlined"
              fullWidth
              label="Tag"
              margin="dense"
              error={!!errors.Tag}
            />
          </TextFieldConnector>
        </Grid>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <CircularProgressButton
              style={{ minWidth: 180 }}             
              onClick={togglePublish}             
              inProgress={isUpdating}
            >{
              article.IsPublished ? "Unpublish article" : "Publish article"
            }</CircularProgressButton>
          </Grid>

          <Grid item>
            <CircularProgressButton
              type="submit"              
              inProgress={isUpdating}
            >
              Update
            </CircularProgressButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default LandingForm;
