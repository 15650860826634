import React, { useCallback } from "react";
import { styled } from "@mui/material";

type SameSizeContainerProps = {
  children: React.ReactNode;
  breakOn?: "lg" | "md" | "sm" | "xl" | "xs";
  gap?: [number, number] | number;
};

type StylesProps = {
  elementsCount: number;
  breakOn: "lg" | "md" | "sm" | "xl" | "xs";
  gap: [number, number];
};

const Container = styled("div", {
  shouldForwardProp: (propName) => propName !== "elementsCount" && propName !== "breakOn" && propName !== "gap"
})<StylesProps>
(({theme, gap, breakOn, elementsCount})=>({
  display: "grid",
  gap: theme.spacing(...gap),
  gridTemplateColumns: `repeat(${elementsCount}, 1fr)`,
  [theme.breakpoints.down(breakOn)]: {
    gridTemplateColumns: "1fr",
    gridTemplateRows: `repeat(${elementsCount}, 1fr)`,
  },
}));

const SameSizeContainer = (props: SameSizeContainerProps): JSX.Element => {
  // fill both row and column gap
  const getGap = useCallback(() => {
    let gap: [number, number] = [0, 0];
    if (props.gap) {
      if (Number.isInteger(props.gap)) {
        gap = [props.gap as number, props.gap as number];
      }
      if (Array.isArray(props.gap)) {
        if (props.gap.length === 2) {
          gap = props.gap;
        } else if (props.gap.length === 1) {
          gap = [props.gap[0], props.gap[0]];
        } else if (props.gap.length > 2) {
          throw new Error("Gap can contain maximum of 2 numbers");
        }
      }
    }
    return gap;
  }, [props.gap]);

  const breakOn = props.breakOn ?? "xs";
  const elementsCount =  React.Children.toArray(props.children).filter((x) => !!x).length
  
  return (
    <Container gap={getGap()} breakOn={breakOn} elementsCount={elementsCount}>
      {props.children}
    </Container>
  );
};

export default React.memo(SameSizeContainer);
