import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import {
  Grid,
  Button,
  ThemeProvider,
  StyledEngineProvider,
  LinearProgress,
  FormControlLabel,
  Typography,
  styled,
} from "@mui/material";
import { connect, ConnectedProps, Provider } from "react-redux";
import { GrapesEditor, GrapesTypes, LoadComponentOptions } from "../../defenitions";
import store from "../../../../reducers/store";
import { getOptionalTheme } from "../../../../constants/theme";
import { isReactComponent } from ".";
import AutoCompleteComponent from "../../../Autocomplete/AutoComplete";
import { AppThunkDispatch } from "../../../../definitions/Action";
import { trackSearchAutocompleteFilledIn, getUnit, useUnitState } from "../../../../actions/unit.actions";
import { PreviousRouteProvider } from "../../../../routes/PreviouseRouteContext";
import UnitHelper from "../../../../helpers/unit-helper";
import Unit from "../../../../definitions/model/unit/Unit";
import { moveInToProperty } from "../../../../actions/user.actions";
import { FormProvider, useForm } from "react-hook-form";
import { MCheckbox } from "../../../Base";
import { sendFamilyJoinRequest } from "../../../../actions";
import { useTranslation } from "react-i18next";
import { ControllerConnector } from "../../../Base/FormConnector";

const AutoCompleteComponentWithError = styled(AutoCompleteComponent, {
  shouldForwardProp: (propName: string) => propName !== "showAutocompleteError",
})<{ showAutocompleteError: boolean }>(({ theme, showAutocompleteError }) => ({
  boxShadow: showAutocompleteError ? "inset 0 0 3px 2px " + theme.palette.error.dark : "inherit",
}));

type UnitAddressFormProps = {
  inputBackground: string;
  inputColor: string;
  btnMoveInText: string;
  btnText: string;
  btnVariant: "text" | "outlined" | "contained";
  btnColor: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
};

export type AutocompleteStyles = {
  color: string;
  background: string;
};

type MoveInFormData = {
  PropertyId: string;
  IsResident: boolean;
};

const UnitAddressFormComponent = (props: UnitAddressFormConnectedProps): JSX.Element => {
  const { t } = useTranslation("translation");

  const styles = {
    color: props.inputColor,
    background: props.inputBackground,
  };

  const [loading, setLoading] = useState(false);
  const [unit, setUnit] = useState<Unit>();
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [showAutocompleteError, setShowAutocompleteError] = useState(false);
  const stateUnit = useUnitState();
  const methods = useForm<MoveInFormData>({
    defaultValues: {
      PropertyId: "",
      IsResident: false,
    },
  });
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
  } = methods;

  useEffect(() => {
    if (stateUnit && !showAutocomplete) setUnit(stateUnit.unit ?? undefined);
    if (!stateUnit && !showAutocomplete) setShowAutocomplete(true);
  }, [stateUnit]);

  useEffect(() => {
    register("PropertyId");
    return () => unregister("PropertyId");
  }, [register, unregister]);

  const changeUnitButtonClick = () => {
    setShowAutocomplete(true);
  };

  const redirectToUnit = (unitId: string) => {
    setShowAutocompleteError(false);
    if (unitId) {
      props.trackUnitAddressFilledIn();
      props.getUnit(unitId).then((u) => {
        setUnit(u);
        setValue("PropertyId", unitId);
      }).catch(() => {
        setShowAutocompleteError(true);
      });
    }
  };

  const submit = (data: MoveInFormData) => {
    if (!data.PropertyId) {
      setShowAutocompleteError(true);
      return;
    }
    //For now we allow all sers to move-in directly, whithou checking if property alredy occupied
    // if (unit && unit.FamilyGroupId !== NIL_UUID) {
    //   setLoading(true);
    //   props
    //     .sendFamilyJoinRequest(data.PropertyId)
    //     .then((result) => {
    //       if (result) setShowAutocomplete(false);
    //     })
    //     .finally(() => setLoading(false));
    // }

    setLoading(true);
    props
      .moveInToProperty(data.PropertyId)
      .then((u: Unit | null | undefined) => {
        if (u) {
          setUnit(u);
          setShowAutocomplete(false);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {loading && <LinearProgress color="primary" />}
        {!showAutocomplete && unit && (
          <>
            <span>{UnitHelper.GetFullAddress(unit.Address)}</span>
            <Button
              size="small"
              style={{
                minWidth: 120,
                marginLeft: 10,
              }}
              variant={props.btnVariant}
              color={props.btnColor}
              onClick={changeUnitButtonClick}
            >
              {props.btnText}
            </Button>
          </>
        )}
        {showAutocomplete && (
          <>
            <AutoCompleteComponentWithError
              key={`search_autocomplete`}
              placeholder={t("Autocomplete.Placeholder")}
              size="small"
              setValueCallback={redirectToUnit}
              gjsStyles={styles}
              showAutocompleteError={showAutocompleteError}
            ></AutoCompleteComponentWithError>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(submit)}>
                {/*{unitAlredyOccupied && (*/}
                {/*    <Alert severity="warning">*/}
                {/*        Der er brugere tilknyttet adressen. Send en anmodning, hvis du*/}
                {/*        ønsker at flytte ind.*/}
                {/*    </Alert>*/}
                {/*)}*/}
                <FormControlLabel
                  control={
                    <ControllerConnector name="IsResident" type="checkbox">
                      <MCheckbox size="small" error={!!errors.IsResident} />
                    </ControllerConnector>
                  }
                  label={<Typography variant="caption">{t("Pages.PropertyFacts.ConfirmWhereILive")}</Typography>}
                  sx={{
                    "&.MuiFormControlLabel-root": {
                      margin: 0,
                    },
                  }}
                />
                <Grid container justifyContent="center">
                  <Button
                    style={{
                      minWidth: 50,
                    }}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    sx={{
                      width: "120px",
                      "& span": {
                        width: "auto",
                      },
                    }}
                  >
                    {props.btnMoveInText}
                  </Button>
                </Grid>
              </form>
            </FormProvider>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  moveInToProperty: (unitId: string) => dispatch(moveInToProperty(unitId, false)),
  sendFamilyJoinRequest: (unitId: string) => dispatch(sendFamilyJoinRequest(unitId)),
  trackUnitAddressFilledIn: () => dispatch(trackSearchAutocompleteFilledIn()),
  getUnit: (id: string) => dispatch(getUnit(id)),
});

const connector = connect(null, mapDispatchToProps);

type UnitAddressFormConnectedProps = ConnectedProps<typeof connector> & UnitAddressFormProps;

export const UnitAddressForm = connector(UnitAddressFormComponent);

export default function (editor: GrapesEditor, options: LoadComponentOptions, landingTheme?: string): void {
  const domComponents = editor.DomComponents;
  const blockManager = editor.BlockManager;
  const defaultType = domComponents.getType("default");
  const { model, view } = defaultType;
  const compName = "UnitAddressForm";
  blockManager.add(GrapesTypes.UnitAddressComponent, {
    label: "Search autocomplete",
    category: options.categoryLabel,
    attributes: { class: "fa fa-home" },
    content: `<div data-gjs-type="${GrapesTypes.UnitAddressComponent}"></div>`,
  });

  const traits = [
    {
      type: "color",
      label: "Input background",
      changeProp: 1,
      name: "inputBackground",
    },
    {
      type: "color",
      label: "Input color",
      changeProp: 1,
      name: "inputColor",
    },
    {
      label: "MoveIn Button title",
      name: "btnMoveInText",
      changeProp: 1,
    },
    {
      label: "Button title",
      name: "btnText",
      changeProp: 1,
    },
    {
      type: "select",
      label: "Button variant",
      changeProp: 1,
      name: "btnVariant",
      typeid: 0,
      options: [
        { id: "text", name: "text" },
        { id: "outlined", name: "outlined" },
        { id: "contained", name: "contained" },
      ],
    },
    {
      type: "select",
      label: "Button color",
      changeProp: 1,
      name: "btnColor",
      typeid: 0,
      options: [
        { id: "inherit", name: "inherit" },
        { id: "primary", name: "primary" },
        { id: "secondary", name: "secondary" },
        { id: "default", name: "default" },
      ],
    },
  ];

  const subscriptions = traits.map((trait) => trait.name);

  domComponents.addType(GrapesTypes.UnitAddressComponent, {
    isComponent(el: HTMLElement) {
      if (
        (el.getAttribute && el.getAttribute("data-gjs-type") === GrapesTypes.UnitAddressComponent) ||
        (el.attributes &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (el.attributes as any)["data-gjs-type"] === GrapesTypes.UnitAddressComponent) ||
        isReactComponent(el, compName)
      ) {
        return {
          type: GrapesTypes.UnitAddressComponent,
        };
      }
    },
    model: {
      defaults: {
        ...model.prototype.defaults,
        droppable: false,
        inputBackground: "#ffffff",
        inputColor: "#000000",
        btnMoveInText: "Flyt ind",
        btnText: "Ændre",
        btnVariant: "outlined",
        btnColor: "secondary",
        traits: [...model.prototype.defaults.traits, ...traits],
      },
    },
    view: view.extend({
      init() {
        subscriptions.forEach((subscription) => {
          this.listenTo(this.model, `change:${subscription}`, this.handleChanges);
        });
      },

      handleChanges() {
        const root = ReactDOM.createRoot(this.el);
        root.unmount();
        this.render();
      },

      onRender() {
        const { el } = this;
        const comps = this.model.get("components");
        const { inputBackground, inputColor, btnMoveInText, btnText, btnVariant, btnColor } = this.model.attributes;
        comps.reset();
        const compString = `
          <${compName} 
            inputBackground=${inputBackground}
            inputColor=${inputColor}
            btnMoveInText="${btnMoveInText}"
            btnText="${btnText}"
            btnVariant=${btnVariant}
            btnColor=${btnColor}
          ></${compName}>
        `;
        comps.add(compString);
        const root = ReactDOM.createRoot(el);

        root.render(
          <>
            <StyledEngineProvider>
              <ThemeProvider theme={getOptionalTheme(landingTheme)}>
                <Provider store={store}>
                  <BrowserRouter>
                    <PreviousRouteProvider>
                      <UnitAddressForm
                        inputBackground={inputBackground}
                        inputColor={inputColor}
                        btnMoveInText={btnMoveInText}
                        btnText={btnText}
                        btnVariant={btnVariant}
                        btnColor={btnColor}
                      />
                    </PreviousRouteProvider>
                  </BrowserRouter>
                </Provider>
              </ThemeProvider>
            </StyledEngineProvider>
          </>
        );
      },
    }),
  });
}
