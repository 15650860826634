import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { getLandings } from "../../../../actions/admin/admin.landing.actions";
import {
  createBehaviourRule,
  getActiveBehaviourRules,
  getBehaviourRules,
  removeBehaviourRule,
  updateBehaviourRule,
} from "../../../../actions/behaviourRules.actions";
import LoadWrapper from "../../../../components/Base/LoadWrapper";
import { useAlertContext } from "../../../../components/Base/MyhouseAlert";
import { AppThunkDispatch } from "../../../../definitions/Action";
import {
  CreateBehaviourRuleContract,
  UpdateBehaviourRuleContract,
} from "../../../../definitions/BehaviourRule";
import { useDefaultReducer } from "../../../../helpers/hooks";
import { ApplicationState } from "../../../../reducers/store";
import BehaviourRule from "./components/BehaviourRuleComponent";
import CreateBehaviourRuleForm from "./components/CreateBehaviourRuleForm";
import { EditRuleInfoFormData, RegistrationBehaviorState } from "./defenitions";
import {styled} from "@mui/material";

const WideButton = styled(Button)({
    maxWidth: "none",
    textAlign: "center",
});

const initialState: RegistrationBehaviorState = {
  loading: false,
  expandCreateRule: false,
};

const RegistrationBehaviourPage = (
  props: BeheviorRulesConnectedProps
): JSX.Element => {
  const [state, dispatch] = useDefaultReducer(initialState);
  const { showAlert } = useAlertContext();

  useEffect(() => {
    dispatch({ type: "loading", payload: true });
    props.getBehaviourRules().finally(() => {
      dispatch({ type: "loading", payload: false });
    });

    return () => {
      props.getActiveBehaviourRules();
    };
  }, []);

  const onCreateClick = () => {
    dispatch({ type: "expandCreateRule", payload: true });
  };

  const hideCreateBlock = () => {
    dispatch({ type: "expandCreateRule", payload: false });
  };

  const onSubmit = async (data: EditRuleInfoFormData) => {
    try {
      await props.createBehaviourRule({
        Fields: data.fields,
        Name: data.name,
        Path: data.path,
        From: data.from,
        To: data.to,
      });
      showAlert({
        severity: "success",
        text: "Rule created",
      });
      hideCreateBlock();
    } catch (error: any) {
      showAlert({
        severity: "error",
        text: error ? error : "Rule was not created",
      });
    }
  };

  const onEdit = (id: string) => async (data: EditRuleInfoFormData) => {
    try {
      await props.updateBehaviourRule({
        Id: id,
        Fields: data.fields,
        Name: data.name,
        Path: data.path,
        From: data.from,
        To: data.to,
      });
      showAlert({
        severity: "success",
        text: "Rule updated",
      });
    } catch (error: any) {
      showAlert({
        severity: "error",
        text: error ? error : "Rule was not updated",
      });
    }
  };

  const onRemove = async (id: string) => {
    try {
      await props.removeBehaviourRule(id);
      showAlert({
        severity: "success",
        text: "Rule removed",
      });
    } catch (error: any) {
      showAlert({
        severity: "error",
        text: error ? error : "Rule was not updated",
      });
    }
  };

  const inputs = ["Phone", "Name", "CO2Mail"];
  return (
    <Grid container justifyContent="center">
      <Grid container item md={6} sm={8} xs={10} spacing={2}>
        <Grid item xs={12}>
          <Fade in={!state.expandCreateRule}>
            <WideButton
              variant="outlined"
              fullWidth
              color="secondary"
              onClick={onCreateClick}
            >
              Add new rule
            </WideButton>
          </Fade>
          <Collapse in={state.expandCreateRule} unmountOnExit mountOnEnter>
            <>
              <CreateBehaviourRuleForm
                autocompleteLoading={props.landingLoading}
                onAutocompleteLoad={props.getLandings}
                inputs={inputs}
                onSubmit={onSubmit}
                onHide={hideCreateBlock}
              />
              {props.loading && <LinearProgress />}
            </>
          </Collapse>
        </Grid>
        <LoadWrapper isLoading={state.loading} result={props.rules}>
          {props.rules.map((r) => (
            <Grid key={r.Id} item xs={12}>
              <BehaviourRule
                inputs={inputs}
                rule={r}
                onEdit={onEdit(r.Id)}
                onDelete={onRemove}
                autocompleteLoading={props.landingLoading}
                onAutocompleteLoad={props.getLandings}
              />
            </Grid>
          ))}
        </LoadWrapper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  rules: state.behaviourRules.rules,
  landing: state.adminLanding.landings,
  landingLoading: state.adminLanding.loading,
  loading: state.behaviourRules.loading,
  error: state.behaviourRules.error,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getBehaviourRules: () => dispatch(getBehaviourRules()),
  getActiveBehaviourRules: () => dispatch(getActiveBehaviourRules()),
  createBehaviourRule: (contract: CreateBehaviourRuleContract) =>
    dispatch(createBehaviourRule(contract)),
  updateBehaviourRule: (contract: UpdateBehaviourRuleContract) =>
    dispatch(updateBehaviourRule(contract)),
  removeBehaviourRule: (id: string) => dispatch(removeBehaviourRule(id)),
  getLandings: (term: string) => dispatch(getLandings(term)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type BeheviorRulesConnectedProps = ConnectedProps<typeof connector>;

export default connector(RegistrationBehaviourPage);
