import React, { useEffect, useReducer } from "react";
import { Grid, Accordion, AccordionDetails, AccordionSummary, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { AppThunkDispatch } from "../../../../definitions/Action";
import { connect, ConnectedProps } from "react-redux";
import { getEditor, useClubs } from "../../../../actions";
import { ExpandMore } from "@mui/icons-material";
import GrapesJsEditor from "../../../../components/Grapes/GrapesJsEditor";
import { FormBuilderDTO, LandingType } from "../../../../definitions/Landing";
import { ArticleModel } from "../../../../definitions/model/Club";
import ArticleForm from "./components/ArticleForm";

type EditArticlePageState = {
  accordionOpen: boolean;
  article: ArticleModel | null;
  editor: FormBuilderDTO | null;
  templateLoading: boolean;
  editorLoading: boolean;
};

const initialState: EditArticlePageState = {
  accordionOpen: false,
  article: null,
  editor: null,
  templateLoading: false,
  editorLoading: false,
};

const reducer = (state: EditArticlePageState, action: { type: string; payload?: unknown }) => {
  switch (action.type) {
    case "toggleAccordion":
      return { ...state, accordionOpen: !state.accordionOpen };
    default:
      return {
        ...state,
        [action.type]: action.payload,
      };
  }
};

const EditTemplatePage = ({ getEditor }: EditTemplateConnectedProps) => {
  const { articleId } = useParams<{ articleId: string }>();
  const [state, dispatch] = useReducer(reducer, initialState);
  const onFullfilled = (type: string) => (result: unknown) => dispatch({ type, payload: result });
  const toggleAccordion = () => dispatch({ type: "toggleAccordion" });
  const setIsLoading = (name: string, value: boolean) => dispatch({ type: name + "Loading", payload: value });

  const [, actions] = useClubs();

  useEffect(() => {
    const loadArticleAndEditor = async () => {
      if (!state.article) {
        setIsLoading("article", true);
        try {
          if (!articleId) return;
          const article = await actions.getArticle(articleId);
          onFullfilled("article")(article);
        } catch (err) {
          console.log(err);
        } finally {
          setIsLoading("article", false);
        }
      }

      if (state.article) {
        setIsLoading("editor", true);
        try {
          const editor = await getEditor(state.article.Id);
          onFullfilled("editor")(editor);
        } catch (err) {
          console.log(err);
        } finally {
          setIsLoading("editor", false);
        }
      }
    };
    loadArticleAndEditor();
    return () => {};
  }, [state.article]);

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={8}>
        <Accordion expanded={state.accordionOpen} onChange={toggleAccordion}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            {state.accordionOpen ? "Hide " : "Expand "}
            {state.article?.Title} edit form
          </AccordionSummary>
          <AccordionDetails>
            {!state.templateLoading ? (
              state.article && <ArticleForm article={state.article} updateArticle={actions.updateArticle} />
            ) : (
              <Grid container justifyContent="center">
                <CircularProgress />
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        {!state.editorLoading && state.editor && (
          <GrapesJsEditor id="landingEditor" type={LandingType.News} builder={state.editor} />
        )}
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getEditor: (editorId: string) => dispatch(getEditor(editorId)),
});

const connector = connect(null, mapDispatchToProps);

type EditTemplateConnectedProps = ConnectedProps<typeof connector>;

export default connector(EditTemplatePage);
