import { Dialog, DialogTitle, LinearProgress } from "@mui/material";
import React from "react";
import { CompanyAdModel, CompanyModel } from "../../../../../definitions/Company";
import CompanyAdDataForm from "./CompanyAdDataForm";

type CompanyAdEditFormProps = {
  ad: CompanyAdModel | null;
  companies: CompanyModel[];
  open: boolean;
  loading: boolean;
  handleClose: () => void;
  editAd: (ad: CompanyAdModel) => Promise<void>;
};

const CompanyAdEditDialog = (props: CompanyAdEditFormProps): JSX.Element => {
  const submit = (ad: CompanyAdModel) => {
    if (props.ad) {
      props
        .editAd({
          ...ad,
          CompanyId: props.ad.CompanyId,
          Id: props.ad.Id,
        })
        .then(() => props.handleClose());
    }
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit company</DialogTitle>
      {!!props.ad && (
        <CompanyAdDataForm
          submit={submit}
          id={props.ad?.Id ?? undefined}
          existingAd={props.ad}
          companies={props.companies}
          handleClose={props.handleClose}
        />
      )}
      {props.loading && <LinearProgress />}
    </Dialog>
  );
};

export default CompanyAdEditDialog;
