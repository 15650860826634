import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useForm, Control, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";
import { useAlertContext } from "../../../../../components/Base";
import { v4 as uuidv4 } from "uuid";
import { ReplacementModel, SystemMetaModel } from "../../../../../definitions/model/SystemMetaModel";
import { LoadingButton } from "@mui/lab";
import { useAdmin } from "../../../../../actions/admin/admin.actions";

const emptyMetaModel: SystemMetaModel = {
  Path: "",
  Hostname: "",
  Replacements: {},
  Id: uuidv4(),
};

export type SystemMetaFormData = {
  Hostname: string;
  Path: string;
  Replacements: ReplacementModel;
};

type SystemMetaEditorProps = {
  open: boolean;
  systemMeta: SystemMetaModel | null;
  handleClose: () => void;
};

const SystemMetaEditor = (props: SystemMetaEditorProps) => {
  const { t } = useTranslation("translation");
  const [{ error, loading }, { createSystemMeta, updateSystemMeta }] = useAdmin();

  useEffect(() => {
    if (props.systemMeta) {
      setValue("Hostname", props.systemMeta.Hostname);
      setValue("Path", props.systemMeta.Path);
      setValue("Replacements", props.systemMeta.Replacements);
    }
  }, []);

  const { showAlert } = useAlertContext();

  const methods = useForm<SystemMetaFormData>({
    defaultValues: {
      Hostname: emptyMetaModel.Hostname,
      Path: emptyMetaModel.Path,
      Replacements: emptyMetaModel.Replacements,
    },
  });

  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = methods;

  const handleReplacementChange = (key: string, value: string) => {
    setValue("Replacements", { ...getValues("Replacements"), [key]: value });
  };

  const handleReplacementRemove = (key: string, value: string) => {
    const replacements = getValues("Replacements");
    delete replacements[key];
    setValue("Replacements", replacements);
  };

  useEffect(() => {
    if (error) {
      showAlert({ text: error, severity: "error" });
    }
  }, [error]);

  const options = [
    { value: "title", title: "Title" },
    { value: "og:title", title: "og:Title" },
    { value: "description", title: "Description" },
    { value: "og:description", title: "og:Description" },
  ];

  const onSubmit = async (data: SystemMetaFormData) => {
    if (!props.systemMeta) {
      await createSystemMeta(data.Hostname, data.Path, data.Replacements);
      props.handleClose();
    } else {
      await updateSystemMeta(props.systemMeta.Id, data.Hostname, data.Path, data.Replacements);
      props.handleClose();
    }
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">System meta record</DialogTitle>
      <DialogContent>
        <Grid
          container
          justifyContent="flex-center"
          xs={12}
          md={6}
          component={"form"}
          onSubmit={handleSubmit(onSubmit)}
          gap={2}
        >
          <Grid item xs={12}>
            <TextFieldConnector register={register("Hostname", { required: true })}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Hostname"
                error={!!errors.Hostname}
                InputLabelProps={{ shrink: true }}
              />
            </TextFieldConnector>
          </Grid>
          <Grid item xs={12}>
            <TextFieldConnector register={register("Path")}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Path"
                error={!!errors.Path}
                InputLabelProps={{ shrink: true }}
              />
            </TextFieldConnector>
          </Grid>
          <Grid container item xs={12} gap={2}>
            <Replacement
              value={""}
              tag={""}
              mode={"create"}
              onUpdate={handleReplacementChange}
              onDelete={handleReplacementRemove}
              control={control}
              selectOptions={options}
              watch={watch}
            />
            {Object.entries(watch("Replacements")).map(([key, value]) => (
              <Replacement
                key={key}
                tag={key}
                value={value}
                mode={"update"}
                onUpdate={handleReplacementChange}
                onDelete={handleReplacementRemove}
                control={control}
                selectOptions={options}
                watch={watch}
              />
            ))}
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            <LoadingButton type="submit" variant="contained" color="secondary" loading={loading}>
              {t("General.Buttons.Save")}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

type ReplacementProps = {
  tag: string;
  value: string;
  mode: "create" | "update";
  onUpdate: (name: string, value: string) => void;
  onDelete: (name: string, value: string) => void;
  control: Control<SystemMetaFormData, any>;
  watch: UseFormWatch<SystemMetaFormData>;
  selectOptions: {
    value: string;
    title: string;
  }[];
};

const Replacement = (props: ReplacementProps) => {
  const [tag, setTag] = useState(props.tag);
  const [value, setValue] = useState(props.value);
  const [selectOptoons, setSelectOptions] = useState(props.selectOptions);
  const [isSelectError, setIsSelectError] = useState(false);
  const [isTexfieldError, setIsTextFieldError] = useState(false);

  useEffect(() => {
    setSelectOptions(
      props.selectOptions.filter((option) => {
        if (Object.keys(props.watch("Replacements")).includes(option.value) && option.value !== tag) return false;
        return true;
      })
    );
  }, [props.watch("Replacements")]);

  useEffect(() => {
    if (props.mode !== "update") return;
    props.onUpdate(tag, value);
  }, [tag, value]);

  return (
    <Grid item container xs={12} spacing={0.5}>
      <Grid item xs={3}>
        <FormControl fullWidth size={"small"} variant={"outlined"}>
          <InputLabel>Meta tag</InputLabel>
          <Select
            variant={"outlined"}
            label={"Meta tag"}
            value={tag}
            error={isSelectError}
            onChange={(e) => {
              setIsSelectError(false);
              setTag(e.target.value);
            }}
          >
            {selectOptoons.map((option) => (
              <MenuItem value={option.value} key={option.value}>
                {option.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={8}>
        <TextField
          fullWidth
          size={"small"}
          multiline
          variant={"outlined"}
          value={value}
          error={isTexfieldError}
          onChange={(e) => {
            setIsTextFieldError(false);
            setValue(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton
          color="secondary"
          size="small"
          onClick={() => {
            if (props.mode == "create") {
              if (!tag) {
                setIsSelectError(true);
                return;
              }
              if (!value) {
                setIsTextFieldError(true);
                return;
              }
              setTag((prevTag) => {
                setValue((prevValue) => {
                  props.onUpdate(prevTag, prevValue);
                  return "";
                });
                return "";
              });
            } else props.onDelete(tag, value);
          }}
        >
          {props.mode == "create" ? <Add /> : <Delete />}
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default SystemMetaEditor;
