import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Grid,
  Fade,
  Box,
  CircularProgress,
  Container,
  Button,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useParams, Link } from "react-router-dom";

import LandingArticle from "../../components/Base/LandingArticle";
import MainContainer from "../../components/Layout/MainContainer";
import { getOneNews } from "./newsstream.actions";
import { ApplicationState } from "../../reducers/store";
import { AppThunkDispatch } from "../../definitions/Action";
import { useTranslation } from "react-i18next";

const NewsDetailsPage = (props: NewsStreamPageConnectedProps) => {
  const { t } = useTranslation("translation");
  const { newsId } = useParams<{ newsId: string }>();
  const [title, setTitle] = useState("");

  const { getOneNews } = props;

  useEffect(() => {
    if (newsId) {
      getOneNews(newsId);
    }
  }, [newsId]);

  useEffect(() => {
    if (props.news && props.news.CustomNews) {
      setTitle(props.news.CustomNews.Title);
    }
  }, [props.news]);

  return (
    <MainContainer title={title}>
      <Container maxWidth="md">
        <Grid container justifyContent="center" sx={(theme)=>({
          color: theme.palette.primary.dark,
        })}>
          <Grid container item>
            <Button
              component={Link}
              to={"/news"}
              type="submit"
              color="secondary"
            >
              <ArrowBack fontSize="small" />
              {t("General.Buttons.Back")}
            </Button>
            {props.news && (
              <LandingArticle categorized={false}  model={props.news?.CustomNews} type="full" />
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Fade in={props.loading}>
            <Box my={2.25}>
              <CircularProgress size={50} />
            </Box>
          </Fade>
        </Grid>
      </Container>
    </MainContainer>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  news: state.newsstream.oneNews,
  loading: state.newsstream.loading,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getOneNews: (newsId: string) => dispatch(getOneNews(newsId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type NewsStreamPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(NewsDetailsPage);