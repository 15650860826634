import React, { useEffect, useMemo, useReducer } from "react";
import { Grid, CircularProgress, Button, Alert, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SystemMessageModel } from "../../../../definitions/model/SystemMessage";
import { AdminTable, Column, Icon } from "../../components/AdminTable/AdminTable";
import { Add } from "@mui/icons-material";
import { useSystemMessage } from "../../../../actions/admin/admin.system-message.actions";

const ListContainer = styled(Grid)({
  overflowY: "auto",
  width: "100%",
  height: "calc(100vh - 250px)",
  padding: 0,
  marginTop: 20,
});

type SystemMessagesPageState = {
  loading: boolean;
  messages: SystemMessageModel[];
  missing: string[];
};

const initialState: SystemMessagesPageState = {
  loading: false,
  messages: [],
  missing: [],
};

const reducer = (state: SystemMessagesPageState, action: any) => {
  return { ...state, [action.type]: action.payload };
};

const SystemMessagesPage = () => {
  const navigate = useNavigate();
  const [sysMessageState, sysMessageActions] = useSystemMessage();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!state.messages || state.messages.length === 0) {
      dispatch({ type: "loading", payload: true });

      sysMessageActions.getSystemMessageMissingTypes().then((types) => {
        if (types) dispatch({ type: "missing", payload: types.map((type) => type.Key) });
      });

      sysMessageActions
        .getSystemMessages()
        .then((messages) => {
          if (messages && messages.length > 0) dispatch({ type: "messages", payload: messages });
        })
        .finally(() => dispatch({ type: "loading", payload: false }));
    }
  }, [state.messages]);

  const handleCreate = () => {
    navigate("/admin/system-messages/new");
  };

  // const handleDeleteSystemMessage = (id: string) => {
  //   dispatch({ type: "loading", payload: true });

  //   props
  //     .deleteSystemMessage(id)
  //     .then((r) => {
  //       if (r) {
  //         dispatch({
  //           type: "messages",
  //           payload: state.messages.filter((x) => x.Id !== id),
  //         });
  //       }
  //     })
  //     .finally(() => dispatch({ type: "loading", payload: false }));
  // };

  const columns: Column<SystemMessageModel>[] = useMemo(
    () => [
      {
        name: "Created",
        accessor: "Created",
        format: (data) => new Date(data).toLocaleString("da-dk"),
      },
      {
        name: "Modified",
        accessor: "Updated",
        format: (data) => new Date(data).toLocaleString("da-dk"),
      },
      {
        name: "Name",
        accessor: "Name",
      },
      {
        name: "Type",
        accessor: "TypeText",
      },
      {
        name: "Edit",
        icon: Icon.Edit,
        callback: (data) => navigate("/admin/system-messages/" + data.Id),
      },
    ],
    []
  );

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item container xs={10} justifyContent="center">
        {state.missing.length > 0 && (
          <Alert severity="error">
            Here is missing email templates : <strong>{state.missing.join(", ")}</strong>
          </Alert>
        )}
      </Grid>
      <Grid container item xs={10}>
        {state.loading && (
          <ListContainer container justifyContent="center" alignItems="center">
            <CircularProgress />
          </ListContainer>
        )}
        <AdminTable
          data={state.messages}
          columns={columns}
          pagination="client"
          headerButton={
            <Grid container item justifyContent="flex-start">
              <Button color="primary" variant="contained">
                <Add onClick={handleCreate} />
              </Button>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
};

export default SystemMessagesPage;
