import React from "react";

import { WorkerModel } from "../../../definitions/model/Worker";
import { MCard } from "../../../components/Base";
import WorkerComponent from "./worker-component";
import { List } from "@mui/material";
import { useServicebookWorker } from "../workers.actions";

type WorkerComponentProps = {
  workers: Array<WorkerModel>;
  title: string;
  targetId: string;
  mode: "unit" | "building";
};

const WorkersGroup = (props: WorkerComponentProps) => {
  const [, { unlinkWorkerFromUnit, unlinkWorkerFromBuilding, rateUnitWorker, rateBuildingWorker }] =
    useServicebookWorker();

  const unlinkWorker = async (workerId: string) => {
    try {
      if (props.mode === "building") {
        return await unlinkWorkerFromBuilding(workerId, props.targetId);
      }
      return await unlinkWorkerFromUnit(workerId, props.targetId);
    } catch (error) {
      console.error(error);
    }
  };

  const rate = async (wokrerId: string, rate: number, comment: string | null) => {
    try {
      if (props.mode === "building") {
        return await rateBuildingWorker(wokrerId, props.targetId, rate, comment);
      }
      return await rateUnitWorker(wokrerId, props.targetId, rate, comment);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {!!props.workers.length && (
        <MCard type="main" title={props.title}>
          <List disablePadding>
            {props.workers.map((w, i) => (
              <WorkerComponent
                key={i}
                worker={w}
                linked={true}
                showControls={true}
                error={null}
                linkWorkerToUnit={(workerId) => Promise.resolve(undefined)}
                unlinkWorkerFromUnit={unlinkWorker}
                rateWorker={rate}
              />
            ))}
          </List>
        </MCard>
      )}
    </>
  );
};

export default WorkersGroup;
