import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import CompanyTestimonialDataForm from "./CompanyTestimonialDataForm";
import { CompanyTestimonialModel, CompanyModel } from "../../../../../definitions/Company";

type CompanyTestimonialDialogProps = {
  companies: CompanyModel[];
  domains: string[];
  open: boolean;
  handleClose: () => void;
  createTestimonial: (testimonial: CompanyTestimonialModel) => Promise<void>;
};

const CompanyTestimonialCreationDialog = (props: CompanyTestimonialDialogProps): JSX.Element => {
  const submit = (ad: CompanyTestimonialModel) => {
    props.createTestimonial(ad).then(() => props.handleClose());
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Create company testimonial</DialogTitle>
      <CompanyTestimonialDataForm
        submit={submit}
        handleClose={props.handleClose}
        companies={props.companies}
        domains={props.domains}
      />
    </Dialog>
  );
};

export default CompanyTestimonialCreationDialog;
