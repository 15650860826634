import React, { useState, useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Button,
  useMediaQuery,
  Icon,
  useTheme,
  Skeleton,
  Badge,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AuthDialog, { Step, StepArray } from "../Dialogs/AuthDialog";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HamburgerMenu from "./HamburgerMenu";
import UnitHelper from "../../helpers/unit-helper";
import { UserConstants } from "../../constants";
import MoveInDialog from "../../pages/House/PropertyFacts/components/MoveInDialog";
import JoinFamilyDialog from "../../pages/Family/components/JoinFamilyDialog";
import CheckLogoBlue from "../../assets/check-logo-blue.png";
import CheckLogoPurple from "../../assets/check-logo.png";
import UserAvatar from "./UserAvatar";
import { useTranslation } from "react-i18next";
import LanguageChange from "./LanguageChange";
import PopperMenu from "./PopperMenu";
import { useBuilding, useUnit, useUser } from "../../actions";

type LoginButtonProps = {
  target?: string;
  rel?: string;
};

type LinkComponentProps = {
  component?: React.ElementType;
  to?: string;
};

const TopBarGridContainer = styled(Grid)({
  paddingLeft: 16,
  paddingRight: 16,
  minHeight: 50,
  position: "relative",
  zIndex: 100,
});

const LogoTypography = styled(Typography)(({ theme }) => ({
  letterSpacing: 12,
  cursor: "pointer",
  [theme.breakpoints.up("sm")]: {
    letterSpacing: 8,
    fontSize: 12,
  },
}));

const NewLogoIcon = styled(Icon)(() => ({
  display: "flex",
  flexWrap: "nowrap",
  alignItems: "center",
  width: "auto",
  "&:hover": {
    cursor: "pointer",
  },
  "& img": {
    height: "inherit",
  },
  "@media print": {
    filter: " drop-shadow(5px 5px 5px rgba(0,0,0,0.75))",
  },
}));

export const SquaredButton = styled(Button)<LinkComponentProps>(() => ({
  borderRadius: 0,
  padding: 1,
  minWidth: "auto",
}));

const SmallUserAvatar = styled(UserAvatar)({
  width: 28,
  height: 28,
});

const LoginButton = styled(SquaredButton)<LoginButtonProps>(({ theme }) => ({
  height: 45,
  padding: "0 20px",
  [theme.breakpoints.down("xs")]: {
    height: 40,
    padding: "0 20px",
    "& p": {
      fontSize: 10,
    },
  },
}));

const UserName = styled("span")({
  maxWidth: 200,
  display: "inline-block",
  wordWrap: "break-word",
});

const StyledGrid = styled(Grid)(() => ({
  flexWrap: "nowrap",
  "@media print": {
    display: "none",
  },
}));
const StyledCheck = styled("img")(() => ({
  width: 32,
}));
const LogoText = styled(Typography)(() => ({
  fontSize: "19px",
  lineHeight: 0.8,
  padding: 0,
}));

export const startPageNavigationItems: { name: string; url: string; id: string }[] = [
  {
    name: "CO2Calculator.StartPage.AboutStart.Navigation.Sustainability",
    url: "/klimaplan/sustainability",
    id: "sustainability",
  },
  {
    name: "CO2Calculator.StartPage.AboutStart.Navigation.Clients",
    url: "/klimaplan/clients",
    id: "clients",
  },
  {
    name: "CO2Calculator.StartPage.AboutStart.Navigation.AboutUs",
    url: "/klimaplan/about-start",
    id: "about",
  },
  {
    name: "CO2Calculator.StartPage.AboutStart.Navigation.Media",
    url: "/klimaplan/media",
    id: "media",
  },
  {
    name: "CO2Calculator.StartPage.AboutStart.Navigation.Top10",
    url: "/klimaplan/top-municipality",
    id: "top-kommune",
  },
  {
    name: "CO2Calculator.StartPage.AboutStart.Navigation.Contact",
    url: "/klimaplan/team",
    id: "cdm-team",
  },
];

export type TopBarProps = {
  hideAuth?: boolean;
  hideButtons?: boolean;
  startPage?: boolean;
};

type TopBarState = {
  openAuth: boolean;
  terms: boolean;
  notifications: boolean;
  showJoinFamilyDialog: boolean;
  phoneNotifications: boolean | undefined;
  registrationUnitId: string | undefined;
  registrationWithName: boolean | undefined;
  authDestination: string;
};

const initialState: TopBarState = {
  openAuth: false,
  terms: false,
  notifications: false,
  showJoinFamilyDialog: false,
  phoneNotifications: undefined,
  registrationUnitId: undefined,
  registrationWithName: undefined,
  authDestination: "",
};

const reducer = (state: TopBarState, action: { type: string; payload?: any }) => {
  return { ...state, [action.type]: action.payload };
};
const TopBar = (props: TopBarProps) => {
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const [step, setStep] = useState<Step>("initial");
  const [
    {
      uiMode,
      mainUser,
      user,
      openLoginDialog,
      openCreateDialog,
      authDestination,
      authUserRating,
      onRegistrationComplete,
    },
    userActions,
  ] = useUser();
  const [{ unit, loading: unitLoading }] = useUnit();
  const [{ building, loading: buildingLoading }] = useBuilding();
  const theme = useTheme();
  const [climateTheme, setClimateTheme] = useState(false);
  const [communeTheme, setCommuneTheme] = useState(false);
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const globalDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const toggleJoinFamilyDialog = () =>
    dispatch({
      type: "showJoinFamilyDialog",
      payload: !state.showJoinFamilyDialog,
    });
  const craftsmensLoginUrl: string = process.env.REACT_APP_SERVICEBOOK_WEB_URL || "";

  useEffect(() => {
    setClimateTheme((theme as any)["name"] === "communeTheme" || (theme as any)["name"] === "commercialTheme");
    setCommuneTheme((theme as any)["name"] === "communeTheme");
  }, [theme]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("flogin") && params.get("flogin")) setTimeout(() => showAuth("login"), 1500);

    const showRegistration = params.has("freg") ? params.get("freg") : null;
    if (showRegistration) {
      if (params.has("terms"))
        dispatch({
          type: "terms",
          payload: JSON.parse(params.get("terms") || "false"),
        });
      if (params.has("notifications"))
        dispatch({
          type: "notifications",
          payload: JSON.parse(params.get("notifications") || "false"),
        });
      if (params.has("phoneNotifications"))
        dispatch({
          type: "phoneNotifications",
          payload: JSON.parse(params.get("phoneNotifications") || "false"),
        });
      if (params.has("regUnitId"))
        dispatch({
          type: "registrationUnitId",
          payload: params.get("regUnitId") || undefined,
        });
      if (params.has("regWithName"))
        dispatch({
          type: "registrationWithName",
          payload: JSON.parse(params.get("regWithName") || "false"),
        });

      const step: Step = StepArray.includes(showRegistration as Step) ? (showRegistration as Step) : "register";
      setTimeout(() => showAuth(step), 1500);
    }
  }, []);
  useEffect(() => {
    if (openCreateDialog) {
      if (unit) dispatch({ type: "registrationUnitId", payload: unit.Id });
      dispatch({ type: "authDestination", payload: "unit-route" });
      showAuth("register");
      globalDispatch({ type: UserConstants.OPEN_CREATE_DIALOG_SUCCEEDED });
    }
  }, [openCreateDialog]);

  useEffect(() => {
    if (openLoginDialog) {
      showAuth("login");
      globalDispatch({ type: UserConstants.OPEN_LOGIN_DIALOG_SUCCEEDED });
    }
  }, [openLoginDialog]);

  useEffect(() => {
    if (authDestination) {
      dispatch({ type: "authDestination", payload: authDestination });
    }
  }, [authDestination]);

  const showAuth = (step: Step) => {
    setStep(step);
    dispatch({ type: "openAuth", payload: true });
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const goToMainPage = () => {
    if (climateTheme) {
      if (user === null) {
        navigate(communeTheme ? "/klimaplan" : "/");
        return;
      } else {
        navigate("/co2calculator");
        return;
      }
    }

    navigate(user === null ? "/" : "/overview");
  };

  const showAlertHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    userActions.setAuthDestination(false, window.location.href, authUserRating);
    userActions.openLoginDialog(false);
  };

  const onModeSwitchClick = () => {
    const newMode = uiMode === "unit" ? "building" : "unit";
    userActions.setUiMode(newMode);
    navigate(newMode === "unit" ? "/overview" : "/buildings");
  };

  return (
    <>
      <TopBarGridContainer container justifyContent="space-between" alignItems="center">
        <Grid item xs={6} sm={4} md={4}>
          {!climateTheme && (
            <LogoTypography onClick={goToMainPage} variant="body2">
              myhouse
            </LogoTypography>
          )}
          {climateTheme && (theme as any)["name"] === "communeTheme" && (
            <NewLogoIcon fontSize="large" onClick={goToMainPage}>
              <Grid container justifyContent="row" item sx={{ flexWrap: "nowrap" }}>
                <StyledCheck src={CheckLogoPurple} alt="Logo" />
                <Grid item container direction="column" sx={{ width: "auto" }} justifyContent={"center"}>
                  <LogoText align="left">{t("General.Logo.Our")}</LogoText>
                  <LogoText color="secondary">{t("General.Logo.Climateplan")}</LogoText>
                </Grid>
              </Grid>
            </NewLogoIcon>
          )}
          {climateTheme && (theme as any)["name"] === "commercialTheme" && (
            <NewLogoIcon fontSize="large" onClick={goToMainPage}>
              <Grid container justifyContent="row" item sx={{ flexWrap: "nowrap" }}>
                <StyledCheck src={CheckLogoBlue} alt="Logo" />
                <Grid item container direction="column" sx={{ width: "auto" }} justifyContent={"center"}>
                  <LogoText align="left">{t("General.Logo.Our")}</LogoText>
                  <LogoText color="secondary">{t("General.Logo.Climateplan")}</LogoText>
                </Grid>
              </Grid>
            </NewLogoIcon>
          )}
        </Grid>
        <Grid item container xs={6} sm={8} justifyContent="flex-end">
          <Grid item container direction="column" alignItems="flex-end">
            <StyledGrid item container justifyContent="flex-end">
              {isSm && (
                <HamburgerMenu
                  startPage={props.startPage}
                  hideAuth={props.hideAuth}
                  hideButtons={props.hideButtons}
                  craftsmensLoginUrl={craftsmensLoginUrl}
                  registerClick={() => showAuth("register")}
                  loginClick={() => showAuth("login")}
                />
              )}
              {props.startPage && !isSm && (
                <>
                  {startPageNavigationItems
                    .filter((item) => (climateTheme ? true : item.id === "cdm-team"))
                    .map((item) => (
                      <Button key={item.id} onClick={() => navigate(item.url)} color="inherit" variant="text">
                        {t(item.name)}
                      </Button>
                    ))}
                </>
              )}
              {!isSm && user && (
                <>
                  {climateTheme && (
                    <SquaredButton
                      sx={{ mr: 1 }}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                    >
                      <SmallUserAvatar variant="rounded" user={user}></SmallUserAvatar>
                    </SquaredButton>
                  )}
                </>
              )}
              <PopperMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
              {!isSm && !user && !props.hideButtons && (
                <>
                  {!climateTheme && (
                    <LoginButton onClick={() => navigate("/partner")}>{t("Menu.PartnerLogin")}</LoginButton>
                  )}
                  {!props.hideAuth && (
                    <LoginButton onClick={() => showAuth("register")}>{t("General.Buttons.Create")}</LoginButton>
                  )}
                </>
              )}

              {!isSm && (
                <>
                  {!user && !props.hideButtons && (
                    <LoginButton
                      color="inherit"
                      style={{ marginRight: "8px" }}
                      onClick={() => showAuth("login")}
                      variant="text"
                    >
                      <Typography color="inherit" variant="body2">
                        {t("Menu.HomeownerLogin")}
                      </Typography>
                    </LoginButton>
                  )}
                  {user && !climateTheme && (
                    <>
                      {mainUser ? (
                        <Badge
                          overlap="circular"
                          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                          badgeContent={<SmallUserAvatar variant="rounded" user={user} />}
                          sx={{ marginRight: 2 }}
                          onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                        >
                          <SmallUserAvatar variant="rounded" user={mainUser} />
                        </Badge>
                      ) : (
                        <>
                          {user.MovedInBuildings.length > 0 && (
                            <FormControlLabel
                              sx={{ marginRight: 0 }}
                              control={<UISwitch checked={uiMode === "unit"} onChange={onModeSwitchClick} />}
                              label=""
                            />
                          )}
                          <SquaredButton
                            color="inherit"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                          >
                            {user.MovedInBuildings.length > 0 ? (
                              <>
                                <Typography variant="body2">
                                  <UserName>{user.Name ? user.Name : t("Menu.Menu")}</UserName>
                                </Typography>
                                <ArrowDropDownIcon />{" "}
                              </>
                            ) : (
                              <>
                                <Typography variant="body2">
                                  {t("Menu.Welcome")}, <UserName>{user.Name ? user.Name : t("Menu.Menu")}</UserName>
                                </Typography>
                                <ArrowDropDownIcon />{" "}
                              </>
                            )}
                          </SquaredButton>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              <LanguageChange />
            </StyledGrid>
            {!isSm && uiMode === "unit" && unit && user?.MovedInUnits?.includes(unit.Id) && (
              <Grid item>
                {unitLoading && <Skeleton variant="rectangular" animation="wave" width={200} height={30} />}
                {unit && !unitLoading && (
                  <Typography align="right" variant="body2" sx={{ width: isXs ? "150px" : "auto" }}>
                    {UnitHelper.GetFullAddress(unit.Address)}
                  </Typography>
                )}
              </Grid>
            )}
            {!isSm && uiMode === "building" && building && (
              <Grid item>
                {buildingLoading && <Skeleton variant="rectangular" animation="wave" width={200} height={30} />}
                {building && !buildingLoading && (
                  <Typography align="right" variant="body2" sx={{ width: isXs ? "150px" : "auto" }}>
                    {UnitHelper.GetFullAddress(building.Address)}
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </TopBarGridContainer>
      <AuthDialog
        setStep={setStep}
        step={step}
        open={state.openAuth}
        TermsOfUse={state.terms}
        Notifications={state.notifications}
        PhoneNotifications={state.phoneNotifications}
        closeDialog={() => {
          dispatch({ type: "openAuth", payload: false });
        }}
        showAlertHandler={showAlertHandler}
        simpleRegistration={!!state.registrationUnitId}
        propertyid={state.registrationUnitId}
        showNameField={state.registrationWithName}
        onRegistrationComplete={onRegistrationComplete}
      />

      {unit && (
        <>
          <MoveInDialog showJoinFamilyDialog={toggleJoinFamilyDialog} presetUnit={unit} />
          <JoinFamilyDialog open={state.showJoinFamilyDialog} closeDialog={toggleJoinFamilyDialog} presetUnit={unit} />
        </>
      )}
    </>
  );
};

const UISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.secondary.main, // "#aab4be",
        ...theme.applyStyles("dark", {
          backgroundColor: "#8796A5",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.secondary.dark, //"#001e3c",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M1 11v10h5v-6h4v6h5V11L8 6z M10 3v1.97l7 5V11h2v2h-2v2h2v2h-2v4h6V3zm9 6h-2V7h2z"/></svg>')`,
    },
    ...theme.applyStyles("dark", {
      backgroundColor: "#003892",
    }),
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.secondary.light, //"#aab4be",
    borderRadius: 20 / 2,
    ...theme.applyStyles("dark", {
      backgroundColor: "#8796A5",
    }),
  },
}));

export default TopBar;
