import React, { useReducer, useEffect } from "react";
import { Grid, Box, Typography, TextField, useTheme, useMediaQuery, styled } from "@mui/material";
import { convertToCurency } from "../../../services/converter.service";
import background from "../../../assets/housePriceBackground.svg";
import User from "../../../definitions/model/User";
import Unit, { ExtraPriceUserInput } from "../../../definitions/model/unit/Unit";
import { useDebounce } from "../../../helpers/hooks";
import { MCard } from "../../../components/Base";
import { useTranslation } from "react-i18next";

const MarginYGridStyled = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(3, 0),
}));

const BackgroundBoxStyled = styled(Box)(() => ({
  background: `url(${background}) center`,
  backgroundSize: "cover",
  height: 50,
  width: "100%",
  marginTop: -2,
}));

type FactorCardProps = {
  title: string;
  className?: string;
  value: number;
  name: string;
  setValue: (name: string, value: number) => void;
  isXs: boolean;
};

const FactorCard = React.memo((props: FactorCardProps) => {
  const { title, setValue, name, value } = props;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    const string = value == 0 ? input.value.replace("0", "") : input.value;
    const currentValue = parseInt(string.replace(/\D/g, ""));

    const number = Number.isNaN(currentValue) ? 0 : currentValue;

    setValue(name, number);
  };

  return (
    <Grid item md={6} sm={8} xs={10} justifyContent="center" alignItems="center" container>
      <Grid item sm={6} xs={12} container justifyContent="center">
        <Typography component="label">{title}</Typography>
      </Grid>
      <Grid item sm={6} xs={12} container justifyContent="center">
        <TextField
          value={`${convertToCurency(value, ".")}`}
          onChange={handleChange}
          variant="outlined"
          inputProps={{
            sx: {
              textAlign: "center",
              padding: "10px 14px",
            },
          }}
        />
      </Grid>
    </Grid>
  );
});

FactorCard.displayName = "FactorCard";

type HouseExtraPriceUserInputProps = {
  estimatedPrice: number;
  unit: Unit;
  user: User;
  updateExtraPriceUserInput: (unitId: string, userInputPrice: ExtraPriceUserInput) => void;
};

const initialState = {
  LocationFactor: 0,
  ExteriorCondition: 0,
  AmenityFactor: 0,
  InteriorCondition: 0,
};

const reducer = (state: typeof initialState, action: { type: string; payload: number }) => {
  return { ...state, [action.type]: action.payload };
};

const HouseExtraPriceUserInput = ({
  estimatedPrice,
  unit,
  user,
  updateExtraPriceUserInput,
}: HouseExtraPriceUserInputProps): JSX.Element => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation("translation");
  const [state, dispatch] = useReducer(reducer, unit.ExtraPriceUserInput ?? initialState);

  const handleChange = (field: string, value: number) => dispatch({ type: field, payload: value });

  const handleSumbit = () => {
    updateExtraPriceUserInput(unit.Id, { ...state, UserId: user.Id });
  };

  const debouncedState = useDebounce(state, 400);

  useEffect(() => {
    if (debouncedState !== initialState && debouncedState !== unit.ExtraPriceUserInput) {
      handleSumbit();
    }
  }, [debouncedState]);

  return (
    <MCard type="narrow" title={t("Unit.ExtraPrice.ExtraValueTitle")} contentType="narrow">
      <Grid
        container
        justifyContent="center"
        sx={{
          color: "primary.contrastText",
        }}
      >
        <MarginYGridStyled item xs={10} container spacing={isXs ? 3 : 4} justifyContent="center">
          <Grid item xs={12}>
            <Typography align="justify">{t("Unit.ExtraPrice.ExtraPriceDescription")}</Typography>
          </Grid>
          <FactorCard
            title={`${t("Unit.ExtraPrice.LocationFactor")}: `}
            value={state.LocationFactor}
            name="LocationFactor"
            setValue={handleChange}
            isXs={isXs}
          ></FactorCard>
          <FactorCard
            title={`${t("Unit.ExtraPrice.ExteriorCondition")}: `}
            value={state.ExteriorCondition}
            name="ExteriorCondition"
            setValue={handleChange}
            isXs={isXs}
          ></FactorCard>
          <FactorCard
            title={`${t("Unit.ExtraPrice.AmenityFactor")}: `}
            value={state.AmenityFactor}
            name="AmenityFactor"
            setValue={handleChange}
            isXs={isXs}
          ></FactorCard>
          <FactorCard
            title={`${t("Unit.ExtraPrice.InteriorCondition")}: `}
            value={state.InteriorCondition}
            name="InteriorCondition"
            setValue={handleChange}
            isXs={isXs}
          ></FactorCard>
        </MarginYGridStyled>

        <Grid
          container
          justifyContent="center"
          sx={{
            backgroundColor: "primary.main",
            color: "primary.contrastText",
          }}
        >
          <BackgroundBoxStyled></BackgroundBoxStyled>
          <Box px={5} width="100%">
            <Box py={4} textAlign="center">
              <Typography variant="h4" style={{ color: "#fff" }}>
                {convertToCurency(
                  Math.round(
                    (estimatedPrice ?? 0) +
                      state.LocationFactor +
                      state.AmenityFactor +
                      state.ExteriorCondition +
                      state.InteriorCondition
                  ),
                  "."
                )}
                kr.
              </Typography>
              <Typography variant="body2" component="div">
                {t("Unit.ExtraPrice.PriceAccordingToUserOpinion")}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </MCard>
  );
};

export default HouseExtraPriceUserInput;
