import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import RemoveProfileDialog from "../../../components/Dialogs/RemoveProfileDialog";
import User from "../../../definitions/model/User";
import { UnitFoldersType } from "../../../definitions/Document";
import { GroupMembersDto } from "../../../definitions/model/Group";
import Unit from "../../../definitions/model/unit/Unit";
import CircularProgressButton from "../../../components/Base/CircularProgressButton";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../actions/user.actions";
import { useDocument } from "../../../actions/document.actions";

const StyledButton = styled(CircularProgressButton)({
  root: {
    justifyContent: "center",
    fontSize: 14,
    fontWeight: 600,
  },
});

type UserRemoveProps = {
  user: User;
  unit?: Unit;

  getUserFamilies: (unitId: string | null) => Promise<GroupMembersDto[]>;
  removeUser: () => Promise<void>;
};

const UserRemove = (props: UserRemoveProps): JSX.Element => {
  const [{ user, authUserRating }, { openLoginDialog, setAuthDestination }] = useUser();
  const [documentsState, documentActions] = useDocument();
  const [isFamilyLoading, setFamilyLoading] = useState(false);
  const [openRemoveProfile, setOpenRemoveProfile] = useState(false);
  const [userFolders, setUserFolders] = useState<UnitFoldersType[]>([]);
  const [families, setFamilies] = useState<GroupMembersDto[]>([]);
  const { t } = useTranslation("translation");

  useEffect(() => {
    if (props.unit) {
      setFamilyLoading(true);
      if (props.user) documentActions.getUserFolders(props.user.Id).then(setUserFolders);
      props
        .getUserFamilies(props.unit ? props.unit.Id : null)
        .then(setFamilies)
        .finally(() => setFamilyLoading(false));
    }
  }, [props.unit]);

  const openRemoveDialog = () => {
    if (!user) {
      setAuthDestination(false, window.location.href, authUserRating);
      openLoginDialog(false);
      return;
    }

    setOpenRemoveProfile(true);
  };

  const sendProfileRemoveRequest = async (removeAll?: boolean) => {
    if (removeAll) {
      await documentActions.deleteDocumentsFromUnits([], [], true);
    }

    await props.removeUser();
  };

  return (
    <>
      <Grid item>
        <StyledButton variant="text" color="primary" inProgress={isFamilyLoading} onClick={openRemoveDialog}>
          {t("UserProfile.DeleteProfile")}
        </StyledButton>
      </Grid>
      <RemoveProfileDialog
        open={openRemoveProfile}
        from={location.pathname}
        families={families}
        folders={userFolders}
        userId={props.user.Id}
        sendProfileRemoveRequest={sendProfileRemoveRequest}
        closeDialog={() => setOpenRemoveProfile(false)}
      />
    </>
  );
};

export default UserRemove;
