import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Container, Grid, Tab } from "@mui/material";
import React from "react";
import { useDefaultReducer } from "../../../../helpers/hooks";
import SystemMetaPage from "./components/SystemMetaPage";
import SystemStrings from "./components/SystemStrings";

type tabType = "system-meta" | "system-strings";
type State = {
  activeTab: tabType;
};

const defaultState: State = {
  activeTab: "system-meta",
};

const SystemSettings = () => {
  const [state, dispatch] = useDefaultReducer<State>(defaultState);
  const handleChange = (event: React.SyntheticEvent, newValue: tabType) => {
    dispatch({ type: "activeTab", payload: newValue });
  };

  return (
    <Container>
      <TabContext value={state.activeTab}>
        <Grid container justifyContent="center">
          <TabList onChange={handleChange}>
            <Tab value="system-meta" label="System Meta" />
            <Tab value="system-strings" label="System Strings" />
          </TabList>
        </Grid>
        <TabPanel value="system-meta">
            <SystemMetaPage/>
        </TabPanel>
        <TabPanel value="system-strings">
            <SystemStrings/>
        </TabPanel>
      </TabContext>
    </Container>
  );
};

export default SystemSettings;
