import React, { useMemo, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import { CheckCircle, Replay } from "@mui/icons-material";
import Button from "@mui/material/Button";

import { PricePredictionModel, UpdatePreditcionContract } from "../defenitions";
import { Collapse } from "@mui/material";
import EditPredictionForm from "./EditPredictionForm";
import {
  AdminTable,
  Column,
  Icon,
} from "../../../components/AdminTable/AdminTable";

type PredictionsListProps = {
  predictions: PricePredictionModel[];
  loading: boolean;
  activatePrediction: (id: string) => void;
  deletePrediction: (id: string) => void;
  reinitPredictions: () => void;
  editPrediction: (
    data: UpdatePreditcionContract
  ) => Promise<PricePredictionModel | null>;
};

const PredictionsList = (props: PredictionsListProps): JSX.Element => {
  const [expandEditPrediction, setExpandEditPrediction] = useState(false);
  const [editingPrediction, setEditingPrediction] =
    useState<PricePredictionModel | null>(null);

  const onEditPredictionClick = (pred: PricePredictionModel) => {
    setEditingPrediction(pred);
    setExpandEditPrediction(true);
  };

  const editPrediction = async (data: UpdatePreditcionContract) => {
    const result = await props.editPrediction(data);
    if (result) {
      setExpandEditPrediction(false);
      return result;
    }

    return null;
  };

  const hideEditPredictionBlock = () => {
    setExpandEditPrediction(false);
    setEditingPrediction(null);
  };

  const columns: Column<PricePredictionModel>[] = useMemo(
    () => [
      {
        name: "Is Active",
        accessor: "IsActive",
        format: (data) => (data ? <CheckCircle color="secondary" /> : ""),
      },
      {
        name: "Created",
        accessor: "Created",
        format: (data) => new Date(data).toLocaleString("da-dk"),
      },
      {
        name: "Name",
        accessor: "Name",
      },
      {
        name: "Type",
        accessor: "property_type",
      },
      {
        name: "Accuracy",
        accessor: "accuracy",
        format: (data) => {
          return `${Math.round((data + Number.EPSILON) * 100) / 100}%`;
        },
      },
      {
        name: "Publish",
        icon: Icon.Disable,
        format: (data) => {
          return data.IsActive ? Icon.Disable : Icon.Public;
        },
        callback: (data) => props.activatePrediction(data.Id),
      },
      {
        name: "Edit",
        icon: Icon.Edit,
        callback: (data) => onEditPredictionClick(data),
      },
      {
        name: "Delete",
        icon: Icon.Delete,
        callback: (data) => props.deletePrediction(data.Id),
      },
    ],
    []
  );

  return (
    <>
      <Collapse in={expandEditPrediction} unmountOnExit mountOnEnter>
        <EditPredictionForm
          prediction={editingPrediction}
          onSubmit={editPrediction}
          onHide={hideEditPredictionBlock}
        />
      </Collapse>
      <TableContainer
        sx={{
          overflowY: "auto",
          width: "100%",
          height: "80vh",
          padding: 0,
        }}
      >
        <AdminTable
          data={props.predictions}
          columns={columns}
          loading={props.loading}
          pagination="client"
          headerButton={
            <Button
              variant="contained"
              color="primary"
              onClick={props.reinitPredictions}
            >
              <Replay />
            </Button>
          }
        />
      </TableContainer>
    </>
  );
};

export default PredictionsList;
