import React, { ChangeEvent, useEffect } from "react";
import Container from "@mui/material/Container";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDocument } from "../../../actions/document.actions";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import MainContainer from "../../../components/Layout/MainContainer";
import LoadWrapper from "../../../components/Base/LoadWrapper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useDefaultReducer } from "../../../helpers/hooks";
import FilePreviewDialog from "./components/FilePreviewDialog";
import { Documents } from "./Folders";
import { useTranslation } from "react-i18next";
import FolderCard from "./FolderCard";
import { DocumentSourceType, UnitFoldersType } from "../../../definitions/Document";
import { useUser } from "../../../actions/user.actions";
import { IDocument } from "@cyntler/react-doc-viewer";
import { useUnit } from "../../../actions";

type RemoveDocumentsState = {
  checkedDocuments: string[];
  previewDocument: IDocument | undefined;
  userUnitsFolders: UnitFoldersType[];
  loading: boolean;
};

const initialState: RemoveDocumentsState = {
  checkedDocuments: [],
  previewDocument: undefined,
  userUnitsFolders: [],
  loading: false,
};

const RemoveDocumentsPage = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [{ user }] = useUser();
  const [{ unit }] = useUnit();
  const [{ documentFolders, loading }, documentActions] = useDocument();
  const navigate = useNavigate();
  const [state, dispatch] = useDefaultReducer(initialState);
  const { t } = useTranslation("translation");
  useEffect(() => {
    if (id) {
      documentActions.getFolders(id, DocumentSourceType.Unit);
      return () => {
        if (unit) documentActions.getFolders(unit?.Id, DocumentSourceType.Unit);
      };
    } else if (user) {
      dispatch({
        type: "loading",
        payload: true,
      });
      documentActions
        .getUserFolders(user.Id)
        .then((result) =>
          dispatch({
            type: "userUnitsFolders",
            payload: result,
          })
        )
        .finally(() => {
          dispatch({
            type: "loading",
            payload: false,
          });
        });
    }
  }, [id, user]);

  useEffect(() => {
    if (!location?.state?.from) {
      navigate("/");
    }
  }, [location?.state?.from]);

  const onDocumentSelect = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      dispatch({
        type: "checkedDocuments",
        payload: [...state.checkedDocuments, e.currentTarget.value],
      });
    } else {
      dispatch({
        type: "checkedDocuments",
        payload: state.checkedDocuments.filter((x) => x !== e.currentTarget.value),
      });
    }
  };

  const onFolderSelect = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const folders = id ? documentFolders.flatMap((x) => x) : state.userUnitsFolders.flatMap((x) => x.Folders);

    const folder = folders.find((f) => f.Id === e.currentTarget.value);

    if (folder) {
      const documentIds = folder.Documents.map((x) => x.Id);

      if (checked) {
        dispatch({
          type: "checkedDocuments",
          payload: [...state.checkedDocuments, ...documentIds],
        });
      } else {
        dispatch({
          type: "checkedDocuments",
          payload: state.checkedDocuments.filter((x) => !documentIds.includes(x)),
        });
      }
    }
  };

  const setPreviewDocument = (document: Documents) => {
    dispatch({
      type: "previewDocument",
      payload: document,
    });
  };

  const handelDocumentRemove = async () => {
    if (id) {
      await documentActions.deleteDocuments(id, state.checkedDocuments);
    } else {
      await documentActions.deleteDocumentsFromUnits(
        state.userUnitsFolders.flatMap((x) => x.UnitId),
        state.checkedDocuments
      );
    }
    navigate(location.state.from + "?fromDocumentsRemove=true", {
      state: {
        selectedUnitId: id ? id : "all",
        externalState: location.state.externalState,
      },
    });
  };

  return (
    <MainContainer>
      <Container maxWidth="md">
        <Typography paragraph variant="h4">
          {t("DocumentsRemove.Page.Header")}
        </Typography>
        {id ? (
          <LoadWrapper
            isLoading={loading}
            result={documentFolders}
            loadingComponent={
              <Grid container justifyContent="center" alignItems="center">
                <CircularProgress />
              </Grid>
            }
          >
            {documentFolders.map((x) =>
              x.map((folder) => (
                <Box key={folder.Id}>
                  <FolderCard
                    folder={folder}
                    folderChecked={() =>
                      state.checkedDocuments.length > 0 &&
                      folder.Documents.every((d) => !!state.checkedDocuments.includes(d.Id))
                    }
                    documentChecked={(documentId: string) => state.checkedDocuments.includes(documentId)}
                    onFolderSelect={onFolderSelect}
                    onDocumentSelect={onDocumentSelect}
                    setPreviewDocument={setPreviewDocument}
                  />
                </Box>
              ))
            )}
          </LoadWrapper>
        ) : (
          <LoadWrapper isLoading={state.loading} result={state.userUnitsFolders}>
            {state.userUnitsFolders.map(
              (unitFolders) =>
                unitFolders.Folders?.length > 0 && (
                  <>
                    <Typography variant="h5">{unitFolders.Address}</Typography>
                    {unitFolders.Folders.map((folder) => (
                      <FolderCard
                        key={folder.Id}
                        folder={folder}
                        folderChecked={() =>
                          state.checkedDocuments.length > 0 &&
                          folder.Documents.every((d) => !!state.checkedDocuments.includes(d.Id))
                        }
                        documentChecked={(documentId: string) => state.checkedDocuments.includes(documentId)}
                        onFolderSelect={onFolderSelect}
                        onDocumentSelect={onDocumentSelect}
                        setPreviewDocument={setPreviewDocument}
                      />
                    ))}
                  </>
                )
            )}
          </LoadWrapper>
        )}
        <Grid container justifyContent="center">
          <Box p={2}>
            <Button
              disabled={state.loading || loading}
              color="secondary"
              variant="contained"
              onClick={handelDocumentRemove}
            >
              {t("DocumentsRemove.Page.RemoveDocuments")}
            </Button>
          </Box>
        </Grid>
        <FilePreviewDialog
          document={state.previewDocument}
          open={Boolean(state.previewDocument)}
          onClose={() => setPreviewDocument(undefined)}
        />
      </Container>
    </MainContainer>
  );
};

export default RemoveDocumentsPage;
