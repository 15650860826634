import React, { useState } from "react"
import { Fab, Dialog, DialogContent, Slide, SlideProps, Button } from "@mui/material" 
import { Add } from "@mui/icons-material";

import WidgetEditForm from "./WidgetEditForm"
import { WidgetCreateModel } from "../../../../../definitions/Landing";
import { ClubModel } from "../../../../../definitions/model/Club";
import { CommuneLocation } from "../../../../../definitions/model/unit/Commune";

const Transition = React.forwardRef(function Transition(
    props: SlideProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type DialogProps = {
    addWidget: (widget: WidgetCreateModel) => void;
    getCommunes: () => Promise<Array<CommuneLocation> | undefined>;
    clubs: Array<ClubModel>;
    communes: Array<CommuneLocation>;
}

const WidgetCreateDialog = (props: DialogProps) => {
    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const addWidget = (widget: WidgetCreateModel) => {
        props.addWidget(widget)
        handleClose()
    }

    return (
        <>
            <Button onClick={handleOpen} color="primary" variant="contained">
                <Add />
            </Button>
            <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose}>
                <DialogContent>
                    <WidgetEditForm addWidget={addWidget} clubs={props.clubs} communes={props.communes} getCommunes={props.getCommunes} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default WidgetCreateDialog