import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Grid, styled, Typography } from "@mui/material";
import { useLandingActions, useUser } from "../../actions";
import { useTranslation } from "react-i18next";
import GrapesJsxRenderer from "../../components/Grapes/GrapesJsxRenderer";
import { replace } from "react-router-dom";
import { RandomLineTermsSkeleton } from "../../components/Base/DetailsSkeleton";
import i18n from "../../i18n";
import { LanguageType } from "../../definitions/Menu";
import { LandingPageDto } from "../../definitions/Landing";

const TermsPage = () => {
  const { t } = useTranslation();
  const [userState, actions] = useUser();

  const landingActions = useLandingActions();

  const gridRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState<LanguageType>(i18n.language as LanguageType);
  const [termsPage, setTermsPage] = useState<LandingPageDto | null>(null);

  useEffect(() => {
    let lang;
    if (!userState.user?.Language) {
      lang = i18n.language;
    } else {
      setLoading(true);
      new Promise(() => {
        lang = userState.user?.Language;
      }).finally(() => {
        setLoading(false);
      });
    }

    if (lang !== language) {
      setLanguage(lang as LanguageType);
    }
  }, [userState.user?.Language, i18n.language]);

  useEffect(() => {
    setLoading(true);
    const getAsync = async () => {
      const landing = await landingActions.getLatestTerms(language);
      setLoading(false);
      setTermsPage(landing);
    };
    getAsync();
    return () => {};
  }, [language]);

  const handleError = (e: Error) => {
    console.error("Grapes JSX renderer error: " + e.message);
  };

  const acceptTerms = async () => {
    try {
      await actions.acceptNewTerms();
      await actions._getBasicData();
      await actions.getUserSettings();

      replace("/overview");
    } catch (error) {
      console.error(error);
    }
  };

  const rejectTerms = () => {
    replace("/profile");
  };

  const ButtonAcceptTerms = () => {
    return (
      <Grid
        container
        spacing={2}
        direction={"row-reverse"}
        sx={{
          marginBottom: "10px",
          marginTop: "-22px",
        }}
      >
        <Grid item>
          <Button variant="contained" onClick={rejectTerms} sx={{ width: "90px" }}>
            {t("General.Buttons.Reject")}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={acceptTerms} sx={{ width: "90px" }}>
            {t("General.Buttons.Accept")}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container maxWidth="md">
      {userState.needToAcceptTerms && <ButtonAcceptTerms />}

      <GridStyled
        container
        item
        isScrollable={false}
        offsetTop={0}
        ref={gridRef}
        isLight={userState.themeName !== "communeTheme"}
      >
        {!loading ? (
          <>
            {termsPage?.Id ? (
              <GrapesJsxRenderer jsx={termsPage.Html} onError={handleError} />
            ) : (
              <Typography variant="h2">{t("Pages.LandingPage.LandingPageNotFound")}</Typography>
            )}
          </>
        ) : (
          <Grid container justifyContent="center" minWidth="inherit">
            <Grid item container direction="column" justifyContent="left" xs={12} marginTop={"25px"}>
              <RandomLineTermsSkeleton />
            </Grid>
          </Grid>
        )}
      </GridStyled>

      {userState.needToAcceptTerms && termsPage?.Id && <ButtonAcceptTerms />}
    </Container>
  );
};

export default TermsPage;

const GridStyled = styled(Grid, {
  shouldForwardProp: (propName: string) =>
    propName !== "isScrollable" && propName !== "isLight" && propName !== "offsetTop",
})<{ isScrollable: boolean; isLight: boolean; offsetTop: number }>(({ theme, isScrollable, offsetTop, isLight }) => ({
  flex: 1,
  justifyContent: "center",
  overflowY: isScrollable ? "scroll" : "initial",
  height: isScrollable ? `calc(100vh - ${offsetTop}px)` : "auto",
  color: isLight ? "black" : "white",
  "& div:first-child": {
    "& div > p > a": {
      color: isLight ? theme.palette.primary.main : theme.palette.secondary.main,
      textDecoration: "none",
    },
  },
}));
