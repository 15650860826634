import React, { ReactNode, useState } from "react";
import { Slide, Box, Dialog, DialogContent, Button, DialogContentText, DialogActions, SlideProps } from "@mui/material";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(
  props: SlideProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type ConfrimDialogProps = {
  text: string;
  open?: boolean;
  okButtonText?: string;
  OkClickHandler: () => void;
  extraOkButtonText?: string;
  extraOkClickHandler?: () => void;
  onClose?: () => void;
  children?: ReactNode;
};

const ConfirmDialog = (props: ConfrimDialogProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const [innerOpen, setOpen] = useState(false);

  const isOpened = props.children ? innerOpen : props.open ?? false;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (props.children) {
      setOpen(false);
    } else {
      if (props.onClose) {
        props.onClose();
      } else {
        throw new Error("[Confirm Dialog]: 'onClose' is undefined.");
      }
    }
  };

  const handleOkClick = () => {
    props.OkClickHandler();
    handleClose();
  };

  return (
    <Box display="inline-block">
      {props.children &&
        React.cloneElement(props.children as React.ReactElement, {
          onClick: handleClickOpen,
        })}
      <Dialog open={isOpened} TransitionComponent={Transition} keepMounted onClose={handleClose}>
        <DialogContent>
          <DialogContentText>{props.text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOkClick} color="secondary">
            {props.okButtonText ?? t("General.Buttons.Ok")}
          </Button>
          {props.extraOkClickHandler && (
            <Button
              onClick={() => {
                if (props.extraOkClickHandler) props.extraOkClickHandler();
                handleClose();
              }}
              color="secondary"
            >
              {props.extraOkButtonText ?? t("General.Buttons.Ok")}
            </Button>
          )}
          <Button onClick={handleClose} color="secondary">
            {t("General.Buttons.Undo")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ConfirmDialog;
