import React from "react";
import BaseDialog from "../../../components/Dialogs/BaseDialog";
import { Button, DialogActions, DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type SmsConsentDialogProps = {
  open: boolean;
  handleClose: (confirmed?: boolean) => Promise<void>;
};

const SmsConsentDialog = (props: SmsConsentDialogProps) => {
  const { t } = useTranslation();
  return (
    <BaseDialog closeDialog={() => props.handleClose()} open={props.open}>
      <DialogTitle sx={{ textAlign: "center" }}>
        {t("ServiceBook.Workers.CheckSmsHeader")}
      </DialogTitle>
      <Typography variant="body1" align="center">
        {t("ServiceBook.Workers.CheckSmsText", { hostname: window.location.hostname })}
      </Typography>
      <DialogActions sx={{ justifyContent: "center", marginBottom: "18px" }}>
        <Button
          onClick={() => props.handleClose(true)}
          color="primary"
          variant="contained"
        >
          {t("General.Buttons.Yes")}
        </Button>
        <Button
          onClick={() => props.handleClose()}
          color="primary"
          variant="outlined"
        >
          {t("General.Buttons.No")}
        </Button>
      </DialogActions>
    </BaseDialog>
  );
};

export default SmsConsentDialog;
