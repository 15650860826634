import React, { useEffect, useReducer } from "react";
import { Grid, Accordion, AccordionDetails, AccordionSummary, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { AppThunkDispatch } from "../../../../definitions/Action";
import { connect, ConnectedProps } from "react-redux";
import { getTemplate, updateTemplate, getTemplateEditor } from "../../../../actions";
import TemplateForm from "./components/TemplateForm";
import { ExpandMore } from "@mui/icons-material";
import GrapesJsEditor from "../../../../components/Grapes/GrapesJsEditor";
import { FormBuilderDTO, TemplateModel, LandingType } from "../../../../definitions/Landing";

type EditTemplatePageState = {
  accordionOpen: boolean;
  template: TemplateModel | null;
  editor: FormBuilderDTO | null;
  templateLoading: boolean;
  editorLoading: boolean;
};

const initialState: EditTemplatePageState = {
  accordionOpen: false,
  template: null,
  editor: null,
  templateLoading: false,
  editorLoading: false,
};

const reducer = (state: EditTemplatePageState, action: { type: string; payload?: unknown }) => {
  switch (action.type) {
    case "toggleAccordion":
      return { ...state, accordionOpen: !state.accordionOpen };
    default:
      return {
        ...state,
        [action.type]: action.payload,
      };
  }
};

const EditTemplatePage = ({ getTemplate, updateTemplate, getEditor }: EditTemplateConnectedProps) => {
  const { id } = useParams<{id: string}>();
  const [state, dispatch] = useReducer(reducer, initialState);
  const onFullfilled = (type: string) => (result: unknown) => dispatch({ type, payload: result });
  const toggleAccordion = () => dispatch({ type: "toggleAccordion" });
  const setIsLoading = (name: string, value: boolean) => dispatch({ type: name + "Loading", payload: value });

  useEffect(() => {
    if (!state.template && id) {
      setIsLoading("template", true);
      getTemplate(id)
        .then(onFullfilled("template"))
        .finally(() => setIsLoading("template", false));
    }

    if (state.template) {
      setIsLoading("editor", true);
      getEditor(state.template.Id)
        .then(onFullfilled("editor"))
        .finally(() => setIsLoading("editor", false));
    }
  }, [state.template]);

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={8}>
        <Accordion expanded={state.accordionOpen} onChange={toggleAccordion}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            {state.accordionOpen ? "Hide " : "Expand "}
            {state.template?.Title} edit form
          </AccordionSummary>
          <AccordionDetails>
            {!state.templateLoading ? (
              state.template && <TemplateForm template={state.template} updateTemplate={updateTemplate} />
            ) : (
              <Grid container justifyContent="center">
                <CircularProgress />
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        {!state.editorLoading && state.editor && state.template && (
          <GrapesJsEditor id="landingEditor" type={LandingType.Landing} builder={state.editor} externalId={state.template.Id} />
        )}
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getEditor: (editorId: string) => dispatch(getTemplateEditor(editorId)),
  getTemplate: (templateId: string) => dispatch(getTemplate(templateId)),
  updateTemplate: (template: TemplateModel) => dispatch(updateTemplate(template)),
});

const connector = connect(null, mapDispatchToProps);

type EditTemplateConnectedProps = ConnectedProps<typeof connector>;

export default connector(EditTemplatePage);
