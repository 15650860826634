import React, { useState } from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@mui/material";
import Clear from "@mui/icons-material/Clear";
import Add from "@mui/icons-material/Add";
import Email from "@mui/icons-material/Email";
import { LoadWrapper } from "../../../../../components/Base";
import { SystemStringModel } from "../../../../../definitions/model/SystemString";

type UtmSourcesProps = {
  utmSources: SystemStringModel[];
  loading: boolean;
  showAddUtmSource: () => void;
};

const UtmSources = (props: UtmSourcesProps): JSX.Element => {
  return (
    <Grid container justifyContent="center" spacing={2} style={{ margin: "24px 0" }}>
      <Grid item sm={8} xs={12}>
        <LoadWrapper isLoading={props.loading} result={props.utmSources}>
          <List style={{ width: "100%" }}>
            {props.utmSources &&
              props.utmSources.map((utmSource) => (
                <React.Fragment key={utmSource.Value}>
                  <ListItem>
                    <ListItemText primary={utmSource.Value} secondary={utmSource.TypeString} />

                    <ListItemSecondaryAction>
                      {/* <IconButton onClick={() => props.unsubscribeFromAlarm(utmSource.Id)} size="large">
                        <Clear />
                      </IconButton> */}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
          </List>
        </LoadWrapper>
      </Grid>
      <Grid item container justifyContent="flex-end" sm={8} xs={12}>
        <Grid item>
          <Button variant="contained" color="secondary" startIcon={<Add />} onClick={props.showAddUtmSource}>
            Add utm source
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UtmSources;
