import React from "react";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  Button,
  IconButton,
  InputAdornment,
  Input,
  InputLabel,
  CardHeader,
  CardContent,
  Card,
  useTheme,
  useMediaQuery,
  styled,
} from "@mui/material";
import { ToggleButton, ToggleButtonGroup, Rating } from "@mui/material";
import { useForm, Controller } from "react-hook-form";

import { MenuListItem } from "../../../../Overview/Components/MenuBlockList";
import {
  NavigationItem,
  UpdateNavigationItemContract,
  CreateNavigationItemContract,
  TranslationText,
} from "../../../../../definitions/Menu";
import { Photo } from "@mui/icons-material";
import FileInput from "../../../../../components/Base/FileInput";
import { useTranslation } from "react-i18next";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";
import { GetTranslatedText } from "../../../../../helpers/translation-helper";

const pageLinks = [
  {
    label: "vores klimaplan",
    value: "/co2calculator",
  },
  {
    label: "Tips og tricks til mere bæredygtighed",
    value: "/articles",
  },
  {
    label: "Min bolig",
    value: "unit-route/profile",
  },
  {
    label: "Min bygning",
    value: "/buildings/building-route",
  },
  {
    label: "Min boligalarm",
    value: "/aboutproperty",
  },
  {
    label: "Mit nærområde",
    value: "/map",
  },
  {
    label: "Mine lokalnyheder",
    value: "/news",
  },
  {
    label: "Min servicebog",
    value: "unit-route/servicebook",
  },
  {
    label: "Mine håndværkere",
    value: "/workers",
  },
  {
    label: "Håndværkeropgaver",
    value: "unit-route/tasks",
  },
  {
    label: "Mine boligdokumenter",
    value: "/documents",
  },
  {
    label: "Min profil",
    value: "/profile",
  },
  {
    label: "Min boligs 3D model",
    value: "unit-route/model",
  },
  {
    label: "Style Terminator",
    value: "/styles",
  },
  {
    label: "Neighbor groups",
    value: "/neighbor-groups",
  },
];

const SelectGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    margin: "-8px 0 8px 0",
  },
}));

type NavigationEditorFormData = {
  Titles: TranslationText[];
  TargetUrl: string;
  IsPublished: boolean;
  UnitSensitive: boolean;
  UserSensitive: boolean;
  Rating: number;
  ShowInTopBar: boolean;
  ShowAtStartPage: boolean;
  BackgroundImageUrl: string;
  RequireLogin: boolean;
};

type NavigationEditorProps = {
  navItem: NavigationItem | null;
  updateNavigationItem: (item: UpdateNavigationItemContract) => Promise<NavigationItem | undefined>;
  addNavigationItem: (item: CreateNavigationItemContract) => Promise<NavigationItem | undefined>;
  updateBackgroundImage: (image: File) => Promise<string | undefined>;
  closeEditor: () => void;
  type: "overview" | "partner";
};

const initialTitles: TranslationText[] = [
  {
    Language: "da",
    Text: "",
  },
  {
    Language: "en",
    Text: "",
  },
];

const NavigationEditor = (props: NavigationEditorProps): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation("translation");
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const { navItem } = props;

  const methods = useForm<NavigationEditorFormData>({
    defaultValues: {
      Titles: navItem?.Titles ? navItem?.Titles : initialTitles,
      TargetUrl: props.type === "overview" ? navItem?.TargetUrl ?? pageLinks[0].value : navItem?.TargetUrl ?? "",
      IsPublished: navItem?.IsPublished ?? true,
      UnitSensitive: navItem?.UnitSensitive ?? false,
      UserSensitive: navItem?.UserSensitive ?? false,
      Rating: navItem?.Rating ?? 0,
      ShowInTopBar: navItem?.ShowInTopBar ?? false,
      ShowAtStartPage: navItem?.ShowAtStartPage ?? false,
      BackgroundImageUrl: navItem?.BackgroundImageUrl ?? "",
      RequireLogin: navItem?.RequireLogin ?? false,
    },
  });
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = methods;

  const updatePictureHandler = async (image: File) => {
    const backgroundUrl = await props.updateBackgroundImage(image);
    if (backgroundUrl) {
      setValue("BackgroundImageUrl", backgroundUrl);
    }
  };

  const submit = (data: NavigationEditorFormData) => {
    if (navItem && navItem.Id) {
      props.updateNavigationItem({ ...navItem, ...data });
      props.closeEditor();
      return;
    }

    props.addNavigationItem(data);
    props.closeEditor();
  };

  return (
    <Card square sx={{ border: "solid 1px #e4e4e4" }}>
      <CardHeader title="Navigation Editor"></CardHeader>
      <CardContent>
        <Grid container justifyContent="center" spacing={2}>
          <Grid container item direction="column">
            <form onSubmit={handleSubmit(submit)}>
              <Grid container alignItems="baseline">
                <Grid container direction={isSm ? "column" : "row"} spacing={2}>
                  <Grid item md={6}>
                    <TextFieldConnector register={register("Titles.0.Text", {})}>
                      <TextField
                        style={{ marginTop: 0 }}
                        autoFocus
                        label="Title-Da"
                        margin="dense"
                        type="text"
                        variant="outlined"
                        fullWidth
                        error={!!errors.Titles}
                      />
                    </TextFieldConnector>
                    <TextFieldConnector register={register("Titles.1.Text", {})}>
                      <TextField
                        style={{ marginTop: 0 }}
                        autoFocus
                        label="Title-En"
                        margin="dense"
                        type="text"
                        variant="outlined"
                        fullWidth
                        error={!!errors.Titles}
                      />
                    </TextFieldConnector>
                  </Grid>
                  {props.type === "overview" ? (
                    <SelectGrid item md={6}>
                      <Controller
                        render={({ field }) => (
                          <Select {...field} fullWidth variant="outlined" margin="dense">
                            {pageLinks.map((item, index) => (
                              <MenuItem key={index} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                        name="TargetUrl"
                        control={control}
                      />
                    </SelectGrid>
                  ) : (
                    <Grid item md={6}>
                      <TextFieldConnector
                        register={register("TargetUrl", {
                          required: true,
                          pattern:
                            /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)|\/[-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
                        })}
                      >
                        <TextField
                          style={{ marginTop: 0 }}
                          autoFocus
                          label="TargetUrl"
                          margin="dense"
                          type="text"
                          variant="outlined"
                          fullWidth
                          error={!!errors.TargetUrl}
                        />
                      </TextFieldConnector>
                    </Grid>
                  )}
                </Grid>
                <Grid container direction={isSm ? "column-reverse" : "row"} spacing={2} style={{ paddingTop: 5 }}>
                  <Grid container direction="column" item md={6}>
                    <Controller
                      render={({ field }) => (
                        <ToggleButtonGroup
                          {...field}
                          onChange={(e, value) => field.onChange(value)}
                          exclusive
                          color="secondary"
                        >
                          <StyledToggleButton value={true}>Publish</StyledToggleButton>
                          <StyledToggleButton value={false}>Unpublish</StyledToggleButton>
                        </ToggleButtonGroup>
                      )}
                      name="IsPublished"
                      control={control}
                    />
                    {props.type === "partner" && (
                      <Controller
                        render={({ field }) => (
                          <ToggleButtonGroup
                            {...field}
                            onChange={(e, value) => field.onChange(value)}
                            exclusive
                            color="secondary"
                          >
                            <StyledToggleButton value={true}>Requires login</StyledToggleButton>
                            <StyledToggleButton value={false}>No login</StyledToggleButton>
                          </ToggleButtonGroup>
                        )}
                        name="RequireLogin"
                        control={control}
                      />
                    )}
                    {props.type === "overview" && (
                      <>
                        {" "}
                        <Controller
                          render={({ field }) => (
                            <ToggleButtonGroup
                              {...field}
                              onChange={(e, value) => field.onChange(value)}
                              exclusive
                              color="secondary"
                            >
                              <StyledToggleButton value={true}>Show in TopBar</StyledToggleButton>
                              <StyledToggleButton value={false}>Hide from TopBar</StyledToggleButton>
                            </ToggleButtonGroup>
                          )}
                          name="ShowInTopBar"
                          control={control}
                        />
                        <Controller
                          render={({ field }) => (
                            <ToggleButtonGroup
                              {...field}
                              onChange={(e, value) => field.onChange(value)}
                              exclusive
                              color="secondary"
                            >
                              <StyledToggleButton value={true}>Show at StartPage</StyledToggleButton>
                              <StyledToggleButton value={false}>Hide from StartPage</StyledToggleButton>
                            </ToggleButtonGroup>
                          )}
                          name="ShowAtStartPage"
                          control={control}
                        />
                        <Controller
                          render={({ field }) => (
                            <ToggleButtonGroup
                              {...field}
                              onChange={(e, value) => field.onChange(value)}
                              exclusive
                              color="secondary"
                            >
                              <StyledToggleButton value={true}>Unit sensitive</StyledToggleButton>
                              <StyledToggleButton value={false}>Not</StyledToggleButton>
                            </ToggleButtonGroup>
                          )}
                          name="UnitSensitive"
                          control={control}
                        />
                        <Controller
                          render={({ field }) => (
                            <ToggleButtonGroup
                              {...field}
                              onChange={(e, value) => field.onChange(value)}
                              exclusive
                              color="secondary"
                            >
                              <StyledToggleButton value={true}>User sensitive</StyledToggleButton>
                              <StyledToggleButton value={false}>Not</StyledToggleButton>
                            </ToggleButtonGroup>
                          )}
                          name="UserSensitive"
                          control={control}
                        />
                      </>
                    )}
                    {watch().UserSensitive && (
                      <Controller
                        render={({ field }) => (
                          <Rating
                            {...field}
                            onChange={(e, value) => field.onChange(value ?? 0)}
                            size="large"
                            name="rating"
                            color="secondary"
                          ></Rating>
                        )}
                        name="Rating"
                        control={control}
                      />
                    )}
                  </Grid>
                  <Grid container direction="column" item md={6}>
                    <InputLabel htmlFor="BackgroundImageUrl">Background</InputLabel>
                    <TextFieldConnector
                      register={register("BackgroundImageUrl", {
                        required: true,
                      })}
                    >
                      <Input
                        id="BackgroundImageUrl"
                        type="text"
                        disabled
                        margin="dense"
                        fullWidth
                        error={!!errors.BackgroundImageUrl}
                        endAdornment={
                          <InputAdornment position="end">
                            <FileInput getPicture={updatePictureHandler}>
                              <IconButton color="secondary" size="large">
                                <Photo></Photo>
                              </IconButton>
                            </FileInput>
                          </InputAdornment>
                        }
                      />
                    </TextFieldConnector>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                <Button variant="contained" color="secondary" type="submit" style={{ marginRight: 10 }}>
                  {t("General.Buttons.Save")}
                </Button>

                <Button variant="contained" onClick={props.closeEditor}>
                  {t("General.Buttons.Close")}
                </Button>
              </Grid>
            </form>
            <Grid container justifyContent="center" style={{ marginTop: 70 }}>
              <MenuListItem
                title={GetTranslatedText(watch().Titles)}
                background={watch().BackgroundImageUrl}
                isPublished={watch().IsPublished}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const StyledToggleButton = styled(ToggleButton)({
  minWidth: 132,
  fontSize: 13,
  marginBottom: 5,
  padding: "6px 11px",
  "&:hover, &$selected, &$selected:hover": {
    backgroundColor: "#54602c;",
    color: "#fff",
  },
});

export default NavigationEditor;
