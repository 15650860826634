import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  CardContent,
  Rating,
  Chip,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import MCard from "../../../components/Base/MyhouseCard";
import User, { UserProfileDTO } from "../../../definitions/model/User";
import { PhoneField } from "../../../components/Base/FormattedFields";
import { PhoneRegexp } from "../../../services/validation.service";
import FileInput from "../../../components/Base/FileInput";
import { useAlertContext } from "../../../components/Base/MyhouseAlert";
import { useTranslation } from "react-i18next";
import UserAvatar from "../../../components/Layout/UserAvatar";
import { ControllerConnector, TextFieldConnector } from "../../../components/Base/FormConnector";
import { useUserTheme } from "../../../helpers/hooks";
import { UserType } from "../../../constants/enums";
import { styled, useTheme } from "@mui/material/styles";
import { matchIsValidTel } from "mui-tel-input";
import { Diversity3, Storefront } from "@mui/icons-material";
import ThemeChangeComponent from "../../../components/Base/ThemeChangeComponent";

const UserPhotoGrid = styled(Grid)(({ theme }) => ({
  width: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "82px",
    marginLeft: "auto",
  },
  "& .upload-span": {
    fontSize: 12,
    cursor: "pointer",
    color: theme.palette.secondary.main,
    textAlign: "center",
    marginTop: "4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  "& .MuiRating-root": {
    marginTop: "-20",
    color: theme.palette.secondary.dark,
    background: "#ffffff80",
  },
}));

const UserPhoto = styled(UserAvatar)(({ theme }) => ({
  width: "100px",
  height: "100px",
  fontSize: 40,
  margin: "0 auto",
  "& .MuiAvatar-img": {
    width: "100%",
    minWidth: "100px",
    minHeight: "100px",
    height: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80px",
    height: "80px",
  },
}));

type InfoProps = {
  user: User;
  updateUserProfile: (userProfileData: UserProfileDTO) => Promise<User | undefined>;
  error: string | null;
  updateUserPicture: (picture: File) => void;
  setUserTheme: (themeName: string | null) => void;
};

type RegisterFormData = {
  name: string;
  email: string;
  phone: string;
};

const UserInfo = (props: InfoProps): JSX.Element => {
  const { user, error, updateUserPicture } = props;
  const userTheme = useUserTheme();
  const { showAlert } = useAlertContext();
  const { t } = useTranslation("translation");
  const [themeChecked, setThemeChecked] = React.useState<string | null>(null);

  const handleThemeChange = (event: React.MouseEvent<HTMLElement>, newTheme: string | null) => {
    setThemeChecked(newTheme);
    props.setUserTheme(newTheme);
  };

  useEffect(() => {
    setThemeChecked((userTheme as any)["name"]);
  }, []);

  useEffect(() => {
    if (error) {
      showAlert({
        severity: "error",
        text: error,
        duration: 3000,
      });
    }
  }, [error]);

  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<RegisterFormData>({
    defaultValues: {
      name: user.Name,
      phone: user.Phone,
      email: user.Email,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const submit = (data: RegisterFormData) => {
    setIsLoading(true);
    const formatedPhone = data.phone.split(" ").reduce((acc, item, index) => {
      if (!index) {
        return acc + item + " ";
      }
      return acc + item;
    }, "");
    props
      .updateUserProfile({
        Email: data.email,
        Name: data.name,
        Phone: formatedPhone,
        Language: user.Language,
      })
      .finally(() => setIsLoading(false));
  };

  const updatePictureHandler = (picture: File) => {
    updateUserPicture(picture);
  };

  return (
    <MCard type="main" title={t("UserProfile.UpdateProfile") ?? ""} showProgress={isLoading}>
      <CardContent>
        <FormProvider {...methods}>
          {user.Role == UserType.Administrator && (
            <Grid container item direction="column" justifyContent="flex-start">
              <Typography paragraph variant="body1">{t("UserProfile.ThemeSwitch")}</Typography>             
              <ThemeChangeComponent themeChecked={themeChecked} handleThemeChange={handleThemeChange} />
            </Grid>
          )}
          <Grid container item direction="row" alignItems="flex-start" spacing={1}>
            {!!user.IsCommuneUser && (
              <Grid item>
                <Chip
                  label={t("UserProfile.CommuneUser")}
                  icon={<Diversity3 />}
                  color="secondary"
                  variant="outlined"
                ></Chip>
              </Grid>
            )}
            {!!user.IsCompanyUser && (
              <Grid item>
                <Chip
                  label={t("UserProfile.CompanyUser")}
                  icon={<Storefront />}
                  color="secondary"
                  variant="outlined"
                ></Chip>
              </Grid>
            )}
          </Grid>
          <form onSubmit={handleSubmit(submit)}>
            <Grid direction={{ sm: "row", xs: "column-reverse" }} container justifyContent="space-between">
              <Grid item lg={4} md={5} sm={6}>
                <Typography sx={{ fontSize: 14 }}>{t("Pages.House.Address")}</Typography>
                <Typography sx={{ fontSize: { md: 21, sm: 17 }, marginBottom: "10px" }}>{user.Address}</Typography>

                <TextFieldConnector register={register("name")}>
                  <TextField
                    sx={{ marginTop: { md: 2, sm: 1, xs: 1 } }}
                    variant="outlined"
                    fullWidth
                    type="text"
                    size="small"
                    label={t("Base.ContactMeBox.Name")}
                  />
                </TextFieldConnector>

                <ControllerConnector
                  name="phone"
                  rules={{
                    pattern: PhoneRegexp,
                    maxLength: 16,
                    validate: (e: any) => matchIsValidTel(e),
                  }}
                >
                  <PhoneField
                    sx={{ marginTop: { md: 2, sm: 1, xs: 1 } }}
                    variant="outlined"
                    fullWidth
                    size="small"
                    label={t("ServiceBook.Tel")}
                    error={!!errors.phone}
                  />
                </ControllerConnector>

                <TextFieldConnector register={register("email")}>
                  <TextField
                    sx={{ marginTop: { md: 2, sm: 1, xs: 1 } }}
                    variant="outlined"
                    fullWidth
                    type="email"
                    size="small"
                    label={t("UserProfile.EmailAddress")}
                  />
                </TextFieldConnector>
              </Grid>

              <UserPhotoGrid
                container
                item
                direction="column"
                alignItems="center"
                mt={1}
              >
                <UserPhoto user={user} />
                <Rating value={user.Rating} size="small" name="rating" color="secondary" readOnly />
                <FileInput getPicture={updatePictureHandler}>
                  <span className="upload-span">{t("UserProfile.UploadProfilePicture")}</span>
                </FileInput>
              </UserPhotoGrid>
            </Grid>
            <Grid container justifyContent="center">
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                type="submit"
                sx={{
                  maxWidth: "300px",
                  marginTop: "30px",
                }}
              >
                {t("General.Buttons.Save")}
              </Button>
            </Grid>
          </form>
        </FormProvider>
      </CardContent>
    </MCard>
  );
};

export default UserInfo;
