import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SameSizeContainer from "../../Base/SameSizeContainer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

type RemoveDocumentsPartProps = {
  from: string;
  unitId?: string;
  hasDocuments: boolean;
  state?: unknown;
  dialogType: "moveout" | "remove-profile";
  onRemoveAll: () => Promise<void>;
  onLeaveAll: () => Promise<void>;
  onCloseClick: () => void;
};

const RemoveDocumentsPart = (props: RemoveDocumentsPartProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const path = props.unitId ? `remove-documents/${props.unitId}` : "remove-documents";

  const isMoveOutDialog = props.dialogType === "moveout";
  const translationPath = isMoveOutDialog ? "MoveOutDialog" : "RemoveProfileDialog";

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item sm={10} xs={12}>
        <Typography paragraph>{t(`${translationPath}.Header`)}</Typography>
        <Typography align="justify">{t(`${translationPath}.DocumentsMessage`)}</Typography>
      </Grid>
      <Grid item sm={10} xs={12}>
        <SameSizeContainer gap={2} breakOn="sm">
          <Button variant="contained" color="secondary" onClick={props.onRemoveAll} sx={{ maxWidth: "none" }}>
            {t("DocumentsRemove.Dialog.RemoveAll")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{ maxWidth: "none" }}
            component={Link}
            to={path}
            state={{ from: props.from, externalState: props.state }}
          >
            {t("DocumentsRemove.Dialog.SelectDocuments")}
          </Button>
          <Button variant="contained" color="secondary" onClick={props.onLeaveAll} sx={{ maxWidth: "none" }}>
            {t(`DocumentsRemove.Dialog.${isMoveOutDialog ? "LeaveAllAndMoveOut" : "LeaveAllAndRemoveProfile"}`)}
          </Button>
          <Button variant="contained" color="secondary" onClick={props.onCloseClick} sx={{ maxWidth: "none" }}>
            {t("General.Buttons.Undo")}
          </Button>
        </SameSizeContainer>
      </Grid>
    </Grid>
  );
};

export default RemoveDocumentsPart;
