import React, { useEffect, ChangeEvent, useReducer, useMemo } from "react";
import { Grid, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Verified } from "@mui/icons-material";
import MTextField from "../../../../components/Base/MyhouseTextField";
import { AdminTable, Column, Icon } from "../../components/AdminTable/AdminTable";
import { Communes, WorkerModel, CraftsmanType } from "../../../../definitions/model/Worker";
import { useAdminCraftsman } from "../../../../actions/admin/admin.craftsman.actions";
import { useTranslation } from "react-i18next";
import WorkerHelper from "../../../../helpers/worker-helper";

type CraftsmanPageState = {
  searchString: string;
  activeCraftsman: WorkerModel | null;
  communeNumber: number | null;
  craftsmanType: number | null;
  page: number;
  take: number;
  resetPaging: boolean;
};

const initialState: CraftsmanPageState = {
  searchString: "",
  activeCraftsman: null,
  craftsmanType: null,
  communeNumber: null,
  page: 0,
  take: 10,
  resetPaging: false,
};

const reducer = (state: CraftsmanPageState, action: { type: keyof CraftsmanPageState; payload: unknown }) => {
  return { ...state, [action.type]: action.payload };
};

const CraftsmenPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [craftsmanState, actions] = useAdminCraftsman();

  const toggleGreen = (id: string) => {
    actions.toggleCraftsmanGreenTag(id);
  };

  useEffect(() => {
    if (craftsmanState.craftsmen.Count) {
      dispatch({ type: "resetPaging", payload: true });
    }
    actions.getCraftsmen(
      {
        SearchString: state.searchString,
        FilterByType: state.craftsmanType !== null &&  state.craftsmanType !==0,
        PostCode: 0,        
        CommuneCode: state.communeNumber ? [state.communeNumber] : [],
        Types: state.craftsmanType ? [state.craftsmanType]: WorkerHelper.GetWorkerTypes(state.searchString),
      },
      0,
      state.take
    );

    return () => {};
  }, [state.communeNumber, state.craftsmanType, state.searchString]);

  useEffect(() => {
    if (state.page !== 0) {
      actions.getCraftsmen(
        {
          SearchString: state.searchString,          
          PostCode: 0, 
          FilterByType: state.craftsmanType !== null &&  state.craftsmanType !==0,         
          CommuneCode: state.communeNumber ? [state.communeNumber] : [],
          Types: state.craftsmanType ? [state.craftsmanType] : WorkerHelper.GetWorkerTypes(state.searchString),
        },
        state.page * state.take,
        state.take
      );
    }

    return () => {};
  }, [state.page, state.take]);

  const columns: Column<WorkerModel>[] = useMemo(
    () => [
      {
        name: "Name",
        accessor: "Organization",
      },
      {
        name: "Address",
        accessor: "Address",
      },
      {
        name: "Postal Code",
        accessor: "PostCode",
      },
      {
        name: "City",
        accessor: "City",
      },
      {
        name: "Green",
        accessor: "GreenTag",
        format: (data) => (data ? <Verified color="secondary" /> : ""),
      },
      {
        name: "Toggle green",
        icon: Icon.Disable,
        format: (data) => {
          return data.GreenTag ? Icon.Disable : Icon.Verified;
        },
        callback: (data) => {
          toggleGreen(data.Id);
        },
      },
    ],
    []
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: "searchString", payload: event.currentTarget.value });

  const workerTypeChange = (event: SelectChangeEvent<number>) => {
    dispatch({ type: "craftsmanType", payload: event.target.value as number });
  };

  const communeChange = (event: SelectChangeEvent<number>) => {
    dispatch({ type: "communeNumber", payload: event.target.value as number });
  };

  const changePagination = ({ page, rows }: { page: number; rows: number }) => {
    dispatch({ type: "page", payload: page });
    dispatch({ type: "take", payload: rows });
  };

  const { t } = useTranslation();

  return (
    <>
      <Grid container justifyContent="center">
        <Grid container spacing={3} item sm={10} xs={12}>
          <Grid item container>
            <MTextField fullWidth placeholder={t("ServiceBook.Workers.SearchByTitle")!} onChange={handleChange} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid marginBottom={2}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="worker-commune-label">{t("ServiceBook.Workers.FilterByMunicipality")}</InputLabel>
                <Select
                  labelId="worker-commune-label"
                  label={t("ServiceBook.Workers.FilterByMunicipality")}
                  value={state.communeNumber || ""}
                  onChange={communeChange}
                >
                  <MenuItem value={0}>
                    <em>{t("ServiceBook.ReportList.No")}</em>
                  </MenuItem>
                  {Communes.map((type) => (
                    <MenuItem key={type.key} value={type.key}>
                      {`${type.key} - ${type.value}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel id="worker-type-label">{t("ServiceBook.Workers.FilterWorker")}</InputLabel>
              <Select
                labelId="worker-type-label"
                label={t("ServiceBook.Workers.FilterWorker")}
                value={state.craftsmanType || ""}
                onChange={workerTypeChange}
              >
                <MenuItem value={0}>
                  <em>{t("ServiceBook.ReportList.No")}</em>
                </MenuItem>
                {CraftsmanType.filter((x) => x.key !== 200)
                  .map((type) => ({
                    key: type.key,
                    value: t(type.value),
                  }))
                  .sort((a, b) => {
                    if (a.key == 100) return 1;
                    return a.value > b.value ? 1 : -1;
                  })
                  .map((type) => (
                    <MenuItem key={type.key} value={type.key}>
                      {type.value}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item container>
            <AdminTable
              data={craftsmanState.craftsmen.Results}
              count={craftsmanState.craftsmen.Count}
              columns={columns}
              loading={false}
              pagination={"server"}
              onPaginationChange={changePagination}
              resetPaging={state.resetPaging}
              headerButton={<></>}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CraftsmenPage;
