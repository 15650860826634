import { Dispatch } from "redux";
import { AdminPartnersActionTypes } from "./admin.partners.actiontypes";
import { AppAction, AppActionThunk } from "../../../../definitions/Action";
import {
  NavigationItem,
  UpdateNavigationItemContract,
  CreateNavigationItemContract,
} from "../../../../definitions/Menu";
import AdminPartnersService from "./admin.partners.service";
import { ApplicationState } from "../../../../reducers/store";
import { useSelector } from "react-redux";
import {
  operationFailedActionGeneral,
  useAppDispatch,
} from "../../../../actions";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(
    payload,
    AdminPartnersActionTypes.PARTNERS_OPERATION_FAILED
  );
};

export const useAdminPartnersState = () =>
  useSelector((state: ApplicationState) => state.adminPartners);

export const useAdminPartnersActions = () => {
  const dispatch = useAppDispatch();
  return {
    getPartnersItems: () => dispatch(getPartnersItems()),
    getPartnersItem: (navItemId: string) =>
      dispatch(getPartnersItem(navItemId)),
    deletePartnersItem: (navItemId: string) =>
      dispatch(deletePartnersItem(navItemId)),
    reorderPartnersItems: (items: NavigationItem[]) =>
      dispatch(reorderPartnersItems(items)),
    setPartnersItemPublish: (itemId: string, state: boolean) =>
      dispatch(setPartnersItemPublish(itemId, state)),
    addPartnersItem: (item: CreateNavigationItemContract) =>
      dispatch(addPartnersItem(item)),
    updatePartnersItem: (item: UpdateNavigationItemContract) =>
      dispatch(updatePartnersItem(item)),
    updatePartnersBackgroundImage: (picture: File) =>
      dispatch(updatePartnersBackgroundImage(picture)),
  };
};

export const getPartnersItems =
  (): AppActionThunk<Promise<NavigationItem[] | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({ type: AdminPartnersActionTypes.GET_PARTNERS_ITEMS });
      const result = await AdminPartnersService.getPartnersItems();
      dispatch({
        type: AdminPartnersActionTypes.GET_PARTNERS_ITEMS_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getPartnersItem =
  (itemId: string) => async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({ type: AdminPartnersActionTypes.GET_PARTNERS_ITEM });
      dispatch({
        type: AdminPartnersActionTypes.GET_PARTNERS_ITEM_SUCCEEDED,
        payload: itemId,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const deletePartnersItem =
  (itemId: string) =>
  async (dispatch: Dispatch<AppAction>, getState: () => ApplicationState) => {
    try {
      dispatch({ type: AdminPartnersActionTypes.DELETE_PARTNERS_ITEM });
      const result = await AdminPartnersService.deletePartnersItem(itemId);
      dispatch({
        type: AdminPartnersActionTypes.DELETE_PARTNERS_ITEM_SUCCEEDED,
        payload: result,
      });

      // reorder navigation items after delete
      const partnersItems = getState().adminPartners.partnersItems;
      dispatch({ type: AdminPartnersActionTypes.REORDER_PARTNERS_ITEMS });
      const reorderedItems = await AdminPartnersService.reorderPartnersItems(
        partnersItems
      );
      dispatch({
        type: AdminPartnersActionTypes.REORDER_PARTNERS_ITEMS_SUCCEEDED,
        payload: reorderedItems,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const reorderPartnersItems =
  (
    items: NavigationItem[]
  ): AppActionThunk<Promise<NavigationItem[] | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({ type: AdminPartnersActionTypes.REORDER_PARTNERS_ITEMS });
      const result = await AdminPartnersService.reorderPartnersItems(items);
      dispatch({
        type: AdminPartnersActionTypes.REORDER_PARTNERS_ITEMS_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const setPartnersItemPublish =
  (
    itemId: string,
    state: boolean
  ): AppActionThunk<Promise<NavigationItem | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({ type: AdminPartnersActionTypes.SET_PARTNERS_ITEM_PUBLISH });
      const result = await AdminPartnersService.setPartnersItemPublish(
        itemId,
        state
      );
      dispatch({
        type: AdminPartnersActionTypes.SET_PARTNERS_ITEM_PUBLISH_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const addPartnersItem =
  (
    item: CreateNavigationItemContract
  ): AppActionThunk<Promise<NavigationItem | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({ type: AdminPartnersActionTypes.CREATE_PARTNERS_ITEM });
      const result = await AdminPartnersService.addPartnersItem(item);
      dispatch({
        type: AdminPartnersActionTypes.CREATE_PARTNERS_ITEM_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const updatePartnersItem =
  (
    item: UpdateNavigationItemContract
  ): AppActionThunk<Promise<NavigationItem | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({ type: AdminPartnersActionTypes.UPDATE_PARTNERS_ITEM });
      const result = await AdminPartnersService.updatePartnersItem(item);
      dispatch({
        type: AdminPartnersActionTypes.UPDATE_PARTNERS_ITEM_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const updatePartnersBackgroundImage =
  (picture: File): AppActionThunk<Promise<string | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: AdminPartnersActionTypes.UPDLOAD_PARTNERS_ITEM_BACKGROUND,
      });
      const result = await AdminPartnersService.updatePartnersBackgroundImage(
        picture
      );
      dispatch({
        type: AdminPartnersActionTypes.UPDLOAD_PARTNERS_ITEM_BACKGROUND_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };
