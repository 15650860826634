import React, { useState } from "react";
import { Grid, TextField, InputAdornment, Typography, Tooltip, IconButton, styled, Checkbox } from "@mui/material";
import { Launch, Attachment } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LandingMetaModel } from "../../../../../definitions/Landing";
import { useAlertContext } from "../../../../../components/Base";
import FileInput from "../../../../../components/Base/FileInput";
import { ControllerConnector, TextFieldConnector } from "../../../../../components/Base/FormConnector";
import CircularProgressButton from "../../../../../components/Base/CircularProgressButton";
import MyhouseCheckbox from "../../../../../components/Base/MyhouseCheckbox";

const FacebookImage = styled("img")(() => ({
  height: 200,
  width: "auto",
  display: "block",
}));

type UpdateLandingFormData = {
  Title: string;
  UniqueName: string;
  IsPublished: boolean;
  FacebookTitle: string;
  FacebookDescription: string;
  Theme: string;
  ShowFooter: boolean;
  ShowTopMenu: boolean;
};

type LandingFormProps = {
  landing: LandingMetaModel;
  updateLanding: (landing: LandingMetaModel) => Promise<LandingMetaModel | null>;
  updateFacebookPicture: (landingId: string, picture: File) => Promise<string | null | undefined>;
};

const LandingForm = ({ landing, updateLanding, updateFacebookPicture }: LandingFormProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const methods = useForm<UpdateLandingFormData>({
    defaultValues: {
      Title: landing?.Title ?? "",
      UniqueName: landing?.UniqueName ?? "",
      IsPublished: landing.IsPublished ?? false,
      FacebookTitle: landing?.FacebookTitle ?? "",
      FacebookDescription: landing?.FacebookDescription ?? "",
      Theme: landing.Theme ?? "",
      ShowFooter: landing?.ShowFooter ?? true,
      ShowTopMenu: landing?.ShowTopMenu ?? true,
    },
  });

  const { showAlert } = useAlertContext();

  const [isUpdating, setIsUpdating] = useState(false);
  const [facebookImageUrl, setFacebookImageUrl] = useState(landing?.FacebookImageUrl);

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
  } = methods;
 const copyToClipboard = () => {
    const textarea = document.createElement("textarea");
    textarea.textContent = `${document.location.origin}/landing/${
      getValues().UniqueName
    }`;
    textarea.style.position = "fixed";
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  const togglePublish = () => {
    setIsUpdating(true);
    updateLanding({
      ...landing,
      IsPublished: !landing.IsPublished,
    }).finally(() => setIsUpdating(false));
  };

  const onSumbit = (formData: UpdateLandingFormData) => {
    setIsUpdating(true);
    updateLanding({
      ...landing,
      ...formData,
      Theme: landing.Theme,
    })
      .then(() => {
        showAlert({ severity: "success", text: "Landing updated" });
      })
      .catch((error) => showAlert({ severity: "error", text: error }))
      .finally(() => setIsUpdating(false));
  };

  const updatePictureHandler = (picture: File) => {
    setIsUpdating(true);
    updateFacebookPicture(landing.Id, picture)
      .then((imageUrl) => {
        if (imageUrl) setFacebookImageUrl(imageUrl);
      })
      .catch((error) => {
        showAlert({ severity: "error", text: error });
      })
      .finally(() => setIsUpdating(false));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSumbit)} style={{ width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextFieldConnector register={register("Title", { required: true })}>
              <TextField variant="outlined" fullWidth label="Title" margin="dense" error={!!errors.Title} />
            </TextFieldConnector>
          </Grid>
          <Grid item xs={12}>
            <TextFieldConnector
              register={register("UniqueName", {
                required: true,
                validate: (value) => /[a-z0-9_-]+$/gi.test(value),
              })}
            >
              <TextField
                variant="outlined"
                fullWidth
                label="Unique name"
                margin="dense"
                InputProps={{
                  startAdornment: <InputAdornment position="start">lp/</InputAdornment>,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Copy url">
                        <IconButton size="small" onClick={copyToClipboard}>
                          <Attachment />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Open landing in new window">
                        <IconButton
                        size="small"
                        disabled={!landing.UniqueName}
                        href={`/landing/${landing.UniqueName}`}
                        target="_blank"
                      >
                          <Launch />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.UniqueName}
              />
            </TextFieldConnector>
            {errors.UniqueName && <Typography>please use only allowed symbols (a-z 0-9 _ -)</Typography>}
          </Grid>
          <Grid item xs={12}>
            <TextFieldConnector register={register("FacebookTitle", { required: true })}>
              <TextField
                variant="outlined"
                fullWidth
                label="Facebook title"
                margin="dense"
                error={!!errors.FacebookTitle}
              />
            </TextFieldConnector>
          </Grid>
          <Grid item xs={12}>
            <TextFieldConnector register={register("FacebookDescription", { required: true })}>
              <TextField
                variant="outlined"
                fullWidth
                label="Facebook description"
                margin="dense"
                error={!!errors.FacebookDescription}
              />
            </TextFieldConnector>
          </Grid>

          <Grid item xs={12} display="flex" alignItems="center">
            <ControllerConnector name="ShowTopMenu" type="checkbox">
              <MyhouseCheckbox sx={{ marginLeft: "5px" }} />
            </ControllerConnector>

            <Grid container>
              <Typography variant="body1" display="inline">
                Show top menu
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} display="flex" alignItems="center">
            <ControllerConnector name="ShowFooter" type="checkbox">
              <MyhouseCheckbox sx={{ marginLeft: "5px" }} />
            </ControllerConnector>

            <Grid container>
              <Typography variant="body1" display="inline">
                Show footer
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FacebookImage src={facebookImageUrl} />
            <FileInput getPicture={updatePictureHandler}>
              <span>{t("Admin.Landing.NewFacebookPicture")}</span>
            </FileInput>
          </Grid>

          <Grid container justifyContent="flex-end" item spacing={2}>
            <Grid item>
              <CircularProgressButton style={{ minWidth: 180 }} onClick={togglePublish} inProgress={isUpdating}>
                {landing.IsPublished ? "Unpublish landing" : "Publish landing"}
              </CircularProgressButton>
            </Grid>
            <Grid item>
              <CircularProgressButton type="submit" inProgress={isUpdating}>
                Update
              </CircularProgressButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default LandingForm;
