import React, { useEffect } from "react";
import { Container } from "@mui/material";
import { useLanding } from "../../actions/landing.actions";
import MainContainer from "../../components/Layout/MainContainer";
import ArticleList from "./components/ArticleList";
import { WidgetPage } from "../../constants/enums";
import { LanguageType } from "../../definitions/Menu";
import { useClubs } from "../../actions";

const ClubsStartPage = () => {
  const [{ loading: loadingClub, articles }, { getArticles }] = useClubs();
  const [{ widgets, widgetsCount, loading: loadingLanding }, { getWidgetsByPage }] = useLanding();

  useEffect(() => {
    /*if (props.subscriptions) {
      const subsIndex = props.subscriptions.findIndex(
        (s) =>
          s.IsActive === true &&
          s.ProductId.toLocaleLowerCase() ==
            process.env.REACT_APP_BENEFIT_CLUB_PRODUCT_ID?.toLocaleLowerCase()
      );
      if (subsIndex === -1) {
        navigate("/clubs-subscribe");
        return;
      }*/
    if (articles.length === 0) {
      getArticles();
    }
    return () => {};
  }, [articles]);

  const getWidgets = (skip: number, take: number, languageId?: LanguageType) =>
    getWidgetsByPage(WidgetPage.Fordelsklubs, skip, take, "", null, undefined, languageId);

  return (
    <MainContainer>
      <Container maxWidth="md" sx={{ marginBottom: "20px" }}>
        <ArticleList
          loading={loadingClub || loadingLanding}
          getWidgets={getWidgets}
          widgets={widgets}
          widgetsCount={widgetsCount}
          articles={articles.filter((article) => !!article.Categories.length)}
        />
      </Container>
    </MainContainer>
  );
};

export default ClubsStartPage;
