import React, { useState, useEffect } from "react";
import {
  List,
  Toolbar,
  IconButton,
  Typography,
  Divider,
  Box,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import MenuItem, { MenuItemModel } from "./components/MenuItem";
import { DrawerHeader, AppBar, Drawer } from "./components/LayoutComponents";
import User from "../../definitions/model/User";
import { useLocation } from "react-router-dom";
import UserInfo from "../../components/User/UserInfo";
import HamburgerMenu from "../../components/Layout/HamburgerMenu";

type LayoutProps = {
  itemsList: MenuItemModel[];
  user: User | null;
  children: React.ReactNode;
};

const AdminPanelBasicLayout = ({
  itemsList,
  user,
  children,
}: LayoutProps
 ): JSX.Element => {
  
  const { pathname } = useLocation();

  const getItemHref = (item: MenuItemModel): string =>
    item.subItems ? getItemHref(item) : item.href ? item.href : "";

  const selectedItem = itemsList.find((item) =>
    pathname.includes(getItemHref(item))
  );

  const theme = useTheme();

  const isMdDown = useMediaQuery(theme.breakpoints.down("lg"));
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(true);

  const toggleDrawerOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(!isMdDown);
  }, [isMdDown]);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          {!open && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawerOpen}
              edge="start"
              size="large"
              sx={{ marginRight: 5 }}
            >
              <ChevronRight />
            </IconButton>
          )}
          <Typography
            variant="h6"
            noWrap
            component="div"
            color="white"
            mr="auto"
          >
            {selectedItem ? selectedItem.text : ""}
          </Typography>
          {isXs && <HamburgerMenu isAdmin />}
          {!isXs && user && (
            <UserInfo avatarUrl={user.AvatarUrl} />
          )}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Typography variant="h6" ml={1} mr="auto">
            Admin panel
          </Typography>
          <IconButton onClick={toggleDrawerOpen} size="large">
            <ChevronLeft />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {itemsList.map((item, index) => (
            <MenuItem key={index} item={item} selectedItem={selectedItem} />
          ))}
        </List>
        <Divider />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 4 }}>
        <DrawerHeader />
        <Grid container justifyContent="center">
          <Grid container>{children}</Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AdminPanelBasicLayout;
