import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProvider, StyledEngineProvider, Button } from "@mui/material";

import store from "../../../../reducers/store";
import { getOptionalTheme } from "../../../../constants/theme";
import { isReactComponent } from ".";
import { GrapesEditor, GrapesTypes, LoadComponentOptions } from "../../defenitions";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type BackButtonProps = {
  variant: "text" | "outlined" | "contained";
  color: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
};

export const BackButton = (props: BackButtonProps): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");

  const goBack = () => {
    if (window.history.state.idx === 0 && new URLSearchParams(window.location.search).get("closeOnBack")) {
      window.close();
    }
    navigate(-1);
  };

  return (
    <>
      <Button onClick={goBack} variant={props.variant} color={props.color}>
        <ArrowBack fontSize="small" />
        {t("General.Buttons.Back")}
      </Button>
    </>
  );
};

export default function (editor: GrapesEditor, options: LoadComponentOptions, landingTheme?: string): void {
  const domComponents = editor.DomComponents;
  const blockManager = editor.BlockManager;
  const defaultType = domComponents.getType("default");
  const { model, view } = defaultType;
  const compName = "BackButton";

  blockManager.add(GrapesTypes.BackButtonComponent, {
    label: "Back button",
    category: options.categoryLabel,
    attributes: { class: "fa fa-arrow-left" },
    content: `<div data-gjs-type="${GrapesTypes.BackButtonComponent}"></div>`,
  });
  const traits = [
    {
      type: "select",
      label: "Variant",
      changeProp: 1,
      name: "variant",
      typeid: 0,
      options: [
        { id: "text", name: "text" },
        { id: "outlined", name: "outlined" },
        { id: "contained", name: "contained" },
      ],
    },
    {
      type: "select",
      label: "Color",
      changeProp: 1,
      name: "color",
      typeid: 0,
      options: [
        { id: "inherit", name: "inherit" },
        { id: "primary", name: "primary" },
        { id: "secondary", name: "secondary" },
        { id: "default", name: "default" },
      ],
    },
  ];
  const subscriptions = traits.map((trait) => trait.name);

  domComponents.addType(GrapesTypes.BackButtonComponent, {
    isComponent(el: HTMLElement) {
      if (
        (el.getAttribute && el.getAttribute("data-gjs-type") === GrapesTypes.BackButtonComponent) ||
        (el.attributes && (el.attributes as any)["data-gjs-type"] === GrapesTypes.BackButtonComponent) ||
        isReactComponent(el, compName)
      ) {
        return {
          type: GrapesTypes.BackButtonComponent,
        };
      }
    },
    model: {
      defaults: {
        ...model.prototype.defaults,
        droppable: false,
        variant: "outlined",
        color: "primary",
        traits: [...model.prototype.defaults.traits, ...traits],
      },
    },
    view: view.extend({
      init() {
        subscriptions.forEach((subscription) => {
          this.listenTo(this.model, `change:${subscription}`, this.handleChanges);
        });
      },
      handleChanges() {
        const root = ReactDOM.createRoot(this.el);
        root.unmount();
        this.render();
      },
      onRender() {
        const { el } = this;
        const comps = this.model.get("components");
        const { variant, color } = this.model.attributes;
        comps.reset();
        const compString = `<${compName} variant=${variant} color=${color}></${compName}>`;
        comps.add(compString);
        const root = ReactDOM.createRoot(el);

        root.render(
          <>
            <StyledEngineProvider>
              <ThemeProvider theme={getOptionalTheme(landingTheme)}>
                <Provider store={store}>
                  <BackButton variant={variant} color={color} />
                </Provider>
              </ThemeProvider>
            </StyledEngineProvider>
          </>
        );
      },
    }),
  });
}
