import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import {
  Grid,
  IconButton,
  CircularProgress,
  Theme,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
} from "@mui/material";
import {
  Delete,
  ThumbUp,
} from "@mui/icons-material";
import { format } from "date-fns";

import DeleteDialog from "./components/DeleteDialog";
import { ApplicationState } from "../../../../reducers/store";
import { AppThunkDispatch } from "../../../../definitions/Action";
import {
  getNotApprovedComments,
  approvedComment,
} from "../../../../actions/admin/admin.actions";
import { deleteComment } from "../../../../actions/comment.actions";
import { CommentModel } from "../../../../definitions/NewsStream";
import { TablePaginationActions } from "../../../../components/Base";
const CommentsModerationPage = (
  props: CommentsModerationPageConnectedProps
) => {
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [commentToDelete, setCommentToDeletion] = useState<CommentModel | null>(
    null
  );
  const startDeleteFlow = (comment: CommentModel) => {
    setCommentToDeletion(comment);
    setShowDeleteDialog(true);
  };
  const handleDeleteModalClose = (confirmed?: boolean) => {
    setShowDeleteDialog(false);
    if (confirmed && commentToDelete) {
      props.deleteComment(commentToDelete);
    }
    setCommentToDeletion(null);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageNumber(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(0); // reset page number, because there migh be no results otherwise
  };

  useEffect(() => {
    const skip = pageNumber * pageSize;
    props.getNotApprovedComments(skip, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize]);

  // load more comments when all visible were approved/removed
  useEffect(() => {
    if(props.comments.length === 0 && props.totalCount > 0){
      const skip = pageNumber * pageSize;
      props.getNotApprovedComments(skip, pageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.comments]);
  return (
    <>
      <Grid container justifyContent="center">
        {props.comments.length ? (
          <TableContainer sx={{
            overflowY: "auto",
            width: "100%",
            height: "80vh",
            padding: 0,
          }}>
          <Table size="small" stickyHeader style={{ position: "relative" }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  Date
                </TableCell>
                <TableCell>
                  Text
                </TableCell>
                <TableCell>
                  User
                </TableCell>
                <TableCell align="right">
                  Approve
                </TableCell>
                <TableCell align="right">
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.comments.map((comment) => (
                <TableRow hover key={comment.Id}>
                  <TableCell>{format(new Date(comment.Created), "dd/MM/yyyy")}</TableCell>
                  <TableCell>{comment.Text}</TableCell>
                  <TableCell>{comment.User.Name}</TableCell>
                  <TableCell align="right">
                  <IconButton
                      onClick={() => props.approvedComment(comment.Id)}
                      size="large"
                    >
                      <ThumbUp />
                    </IconButton>
                    </TableCell>
                    <TableCell align="right">
                    <IconButton
                      onClick={() => startDeleteFlow(comment)}
                      size="large"
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    15,
                    20,
                    30,
                    50,
                  ]}
                  count={props.totalCount}
                  rowsPerPage={pageSize}
                  page={pageNumber}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        ) : (
          <Grid
            container
            sx={{
              overflowY: "auto",
              width: "100%",
              height: "70vh",
              padding: 0,
            }}
            justifyContent="center"
            alignItems="center"
          >
            {props.loading && <CircularProgress />}
          </Grid>
        )}
      </Grid>
      <DeleteDialog
        open={showDeleteDialog}
        handleClose={handleDeleteModalClose}
      />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  loading: state.admin.loading,
  comments: state.admin.commentsToModerate,
  totalCount: state.admin.commentsToModerateCount,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) =>
  bindActionCreators(
    {
      getNotApprovedComments,
      approvedComment,
      deleteComment,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type CommentsModerationPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(CommentsModerationPage);
