import React, { useState } from "react";
import BaseDialog from "../../../../components/Dialogs/BaseDialog";
import { LandingMetaModel } from "../../../../definitions/Landing";
import { Button, Grid, Typography } from "@mui/material";
import CircularProgressButton from "../../../../components/Base/CircularProgressButton";

type CreateTermsDialogProps = {
  open: boolean;
  handleClose: () => void;
  createTerms: () => Promise<LandingMetaModel | null>;
};

const CreateTermsDialog = (props: CreateTermsDialogProps) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const createTerms = async () => {
    try {
      setIsUpdating(true);
      await props.createTerms();
      props.handleClose();
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <BaseDialog
      open={props.open}
      closeDialog={props.handleClose}
      titleComponent="Create new Data policy and Terms of use"
    >
      <Typography>
        Do you want to create a new Data policy and Terms of use?
      </Typography>
      <Grid container justifyContent="flex-end" item marginTop="20px" marginBottom="-20px">
        <Button
          variant="contained"
          onClick={props.handleClose}
          color="secondary"
          style={{ marginRight: 20, minWidth: 120 }}
        >
          Close
        </Button>

        <CircularProgressButton
          style={{ minWidth: 120 }}
          type="submit"
          inProgress={isUpdating}
          onClick={() => createTerms()}
        >
          Create
        </CircularProgressButton>
      </Grid>
    </BaseDialog>
  );
};

export default CreateTermsDialog;
