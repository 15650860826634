import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import { Badge, Box, Grid, Typography, Button, useTheme, useMediaQuery, Fade, styled } from "@mui/material";
import MCard from "../../../../components/Base/MyhouseCard";
import HouseInfoTitle from "./HouseInfoTitle";
import Unit from "../../../../definitions/model/unit/Unit";
import WrongInformationDialog from "./WrongInformationDialog";
import NoPhoto from "../../../../assets/img/no_house.jpg";
import Carousel from "../../../../components/Base/Carousel";
import MissingHousePhotoDialog from "./MissingPhotoDialog";
import User from "../../../../definitions/model/User";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import { UserConstants } from "../../../../constants";
import { useUnitActions } from "../../../../actions/unit.actions";

const ImagesContainerGridStyled = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    marginBottom: 20,
  },
}));

const NoHousePhotoGridStyled = styled(Grid)(() => ({
  width: "100%",
  height: 220,
  backgroundImage: `url(${NoPhoto})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  opacity: 0.4,
}));

type PhotosQuality = "600x400" | "960x640";

type CachedPhotos = {
  [key in PhotosQuality]: string[];
};

type HouseInfoProps = {
  unit: Unit | null;
  user: User | null;
  forceMoveIn?: boolean;
  getOwnedHousesCount?: () => Promise<number>;
  subscribeOnHousePhotoUpdate: (unitId: string) => Promise<void>;
  isSubscribedToHousePhotoUpdate: (unitId: string) => Promise<boolean>;
  trackMoveInClick: () => void;
};

const HouseInfo = ({
  unit,
  user,
  forceMoveIn,
  getOwnedHousesCount,
  trackMoveInClick,
  isSubscribedToHousePhotoUpdate,
  subscribeOnHousePhotoUpdate,
}: HouseInfoProps): JSX.Element => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"), {
    noSsr: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");
  const [isOpenedWrongInformation, setOpenWrongInformation] = useState(false);
  const [isOpenedMissingPhotoDialog, setMissingPhotoDialog] = useState(false);

  const [ownedHousesCount, setOwnedHousesCount] = useState(0);
  const isMovedInUnit = user && unit ? user.MovedInUnits.includes(unit.Id) : false;
  const [isSubscribedToPhotoUpdate, setIsSubscribeToHousePhotoUpdate] = useState(false);

  const { unitPhotos, loaded, hasPhotos } = useUnitPhotos(isXs, unit);

  useEffect(() => {
    let isSubscribed = true;
    if (user && getOwnedHousesCount) {
      getOwnedHousesCount().then((data) => {
        if (isSubscribed) {
          setOwnedHousesCount(data);
        }
      });
      return () => {
        isSubscribed = false;
      };
    }
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    if (unit && user) {
      isSubscribedToHousePhotoUpdate(unit?.Id).then((data) => {
        if (isSubscribed) {
          setIsSubscribeToHousePhotoUpdate(data);
        }
      });
    }

    return () => {
      isSubscribed = false;
    };
  }, [unit?.Id]);

  useEffect(() => {
    if (forceMoveIn && !isMovedInUnit) {
      openMoveInDialog();
    }
  }, [isMovedInUnit, forceMoveIn]);

  const toggleWrongInformationPopup = () => setOpenWrongInformation(!isOpenedWrongInformation);
  const toggleMissingPhotoDialog = () => setMissingPhotoDialog(!isOpenedMissingPhotoDialog);
  const handleMissingPhotoClick = () => {
    if (user) {
      toggleMissingPhotoDialog();
    } else {
      openAuthDialog();
    }
  };

  const openMoveInDialog = () => {
    dispatch({ type: UserConstants.SHOW_MOVE_IN_DIALOG });
    trackMoveInClick();
  };

  const openAuthDialog = () => {
    dispatch({ type: UserConstants.OPEN_CREATE_DIALOG });
    dispatch({
      type: UserConstants.SET_REGISTRATION_COMPLETE,
      payload: subscribeOnHousePhotoUpdate,
    });
    trackMoveInClick();
  };

  return (
    <MCard type="main" title={t("CO2Calculator.EditPersonsLabel1")}>
      <>
        <Box px={2} pb={1} pt={isXs ? 0 : 2}>
          <Grid container justifyContent="space-between" direction={isSm ? "column-reverse" : "row"} component={Box}>
            <Grid item container direction="column" md={6} pr={isXs ? 0 : 3}>
              <Grid container>
                <Grid item xs={5} container direction={"column"} justifyContent={"space-around"}>
                  <Typography variant="subtitle1">{t("Base.Popovers.Type")}</Typography>
                  <Typography variant="subtitle1">{t("Base.Popovers.Address")}</Typography>
                  <Typography variant="subtitle1">{t("Base.Popovers.ZipCode")}</Typography>
                  <Typography variant="subtitle1">{t("Base.ContactMeBox.Town")}</Typography>
                  <Typography variant="subtitle1">{t("Base.Popovers.Commune")}</Typography>
                  <Typography variant="subtitle1">{t("Base.Popovers.Room")}</Typography>
                  <Typography variant="subtitle1">{t("Base.Popovers.AreaM2")}</Typography>
                  <Typography variant="subtitle1">{t("Base.Popovers.BuiltYear")}</Typography>
                  <Typography variant="subtitle1">{t("Base.Popovers.Energy")}</Typography>
                </Grid>
                <Grid item container direction="column" alignItems="flex-end" xs={7} justifyContent={"space-around"}>
                  {unit ? (
                    <HouseInfoTitle unit={unit}></HouseInfoTitle>
                  ) : (
                    <Box>
                      <Skeleton width={70} height={26} />
                      <Skeleton width={70} height={26} />
                      <Skeleton width={70} height={26} />
                      <Skeleton width={70} height={26} />
                    </Box>
                  )}
                  <Typography variant="body1">
                    {unit ? unit.Address.Commune.CommuneName : <Skeleton width={70} height={26} />}
                  </Typography>
                  <Typography variant="body1">
                    {unit ? unit.UnitData.Rooms : <Skeleton width={30} height={26} />}
                  </Typography>
                  <Typography variant="body1">
                    {unit ? unit.UnitData.Area : <Skeleton width={30} height={26} />}
                  </Typography>
                  <Typography variant="body1">
                    {unit ? unit.UnitData.BuildYear : <Skeleton width={30} height={26} />}
                  </Typography>
                  <Typography variant="body1">
                    {unit ? (
                      unit.UnitData?.EnergyLabel || t("Pages.House.NotInformed")
                    ) : (
                      <Skeleton width={30} height={26} />
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Box>
                <Grid>
                  <Grid container direction="column" spacing={1} mt={2}>
                    {user && isMovedInUnit && (
                      <Grid item>
                        <Badge
                          badgeContent={ownedHousesCount}
                          color="primary"
                          sx={{
                            width: "200px",
                            "& .MuiButton-label": {
                              width: "auto",
                            },
                          }}
                        >
                          <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={() => navigate("/house-management")}
                            disabled={!unit}
                            fullWidth
                          >
                            {t("HouseManagement.SeeHouses")}
                          </Button>
                        </Badge>
                      </Grid>
                    )}
                    {user && (
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="large"
                          onClick={toggleWrongInformationPopup}
                          disabled={!unit}
                          sx={{
                            width: "200px",
                            "& .MuiButton-label": {
                              width: "auto",
                            },
                          }}
                        >
                          {t("HouseManagement.WhatDataSourceIsUsed")}
                        </Button>
                      </Grid>
                    )}
                    {user && !isMovedInUnit && (
                      <Grid item>
                        <Button
                          onClick={openMoveInDialog}
                          disabled={!unit}
                          variant="contained"
                          size="large"
                          color="secondary"
                          sx={{
                            width: "200px",
                            "& .MuiButton-label": {
                              width: "auto",
                            },
                          }}
                        >
                          {t("HouseManagement.MoveIn")}
                        </Button>
                      </Grid>
                    )}
                    {!hasPhotos && loaded && !isSubscribedToPhotoUpdate && (
                      <Grid item>
                        <Button
                          onClick={handleMissingPhotoClick}
                          disabled={!unit}
                          variant="contained"
                          size="large"
                          sx={{
                            width: "200px",
                            "& .MuiButton-label": {
                              width: "auto",
                            },
                          }}
                          color="secondary"
                        >
                          {t("HouseManagement.IsHousePhotoMissing")}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {unit && (
                  <>
                    <MissingHousePhotoDialog
                      unitId={unit.Id}
                      open={isOpenedMissingPhotoDialog}
                      closeDialog={toggleMissingPhotoDialog}
                    />
                  </>
                )}

                <WrongInformationDialog open={isOpenedWrongInformation} closeDialog={toggleWrongInformationPopup} />
              </Box>
            </Grid>
            <ImagesContainerGridStyled container item md={6} direction={isXs ? "column-reverse" : "row"}>
              <Grid container item xs={12}>
                <Typography>{t("HouseManagement.ObliquePhotoOfHome")}</Typography>
                {loaded ? (
                  hasPhotos ? (
                    <Carousel qualityPhotos={unitPhotos} />
                  ) : (
                    <NoHousePhotoGridStyled></NoHousePhotoGridStyled>
                  )
                ) : (
                  <Skeleton
                    sx={{
                      width: "100%",
                      height: "300px",
                      transform: "none",
                      borderRadius: 0,
                    }}
                  />
                )}
                {!hasPhotos && isSubscribedToPhotoUpdate && (
                  <Typography>{t("HouseManagement.SubscribedToPhotoUpdates")}</Typography>
                )}
              </Grid>
            </ImagesContainerGridStyled>
          </Grid>
        </Box>
        {!user && (
          <Fade in={!hasPhotos && loaded && !user} mountOnEnter unmountOnExit>
            <Box pb={2} px={2}>
              <Alert severity="warning">
                <Typography>{t("HouseManagement.UnitTypesWithoutPhotos")}</Typography>
              </Alert>
            </Box>
          </Fade>
        )}
      </>
    </MCard>
  );
};

export default HouseInfo;

const useUnitPhotos = (
  isXs: boolean,
  unit: Unit | null
): { unitPhotos: string[]; hasPhotos: boolean; cachedPhotos: CachedPhotos; loading: boolean; loaded: boolean } => {
  const { getUnitPhotos } = useUnitActions();
  const [currentUnit, setUnit] = useState(unit);
  const [cachedPhotos, setCachedPhotos] = useState<CachedPhotos>({
    "600x400": [],
    "960x640": [],
  });

  const [loaded, setIsLoaded] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [unitPhotos, setUnitPhotos] = useState<string[]>([]);
  const hasPhotos = useMemo(() => unitPhotos.length > 0, [unitPhotos]);

  useEffect(() => {
    if (unit?.Id !== currentUnit?.Id) {
      setCachedPhotos({
        "600x400": [],
        "960x640": [],
      });
      setUnitPhotos([]);
      setIsLoaded(false);
      setUnit(unit);
    }
  }, [unit]);

  useEffect(() => {
    const resolution = isXs ? "600x400" : "960x640";

    if (currentUnit && cachedPhotos[resolution].length === 0) {
      setIsLoading(true);
      getUnitPhotos(currentUnit, resolution)
        .then((photos) => {
          const newPhotos = Object.assign({}, cachedPhotos);
          newPhotos[resolution] = photos;
          setCachedPhotos(newPhotos);
          setUnitPhotos(photos);
        })
        .finally(() => {
          setIsLoaded(true);
          setIsLoading(false);
        });
    } else {
      setUnitPhotos(cachedPhotos[resolution]);
    }
  }, [isXs, currentUnit]);

  return { unitPhotos, hasPhotos, cachedPhotos, loading, loaded };
};
