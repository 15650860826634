import React from "react";
import {styled, Typography, useTheme} from "@mui/material";
import MainContainer from "../../../components/Layout/MainContainer";
import flower from "../../../assets/club/flower.png";
import { useTranslation } from "react-i18next";

const ClubTitle = styled(Typography)(({theme})=>({
  color: "#033f23",
  fontWeight: 500,
  lineHeight: 0.6,
  padding: "9px 0 0",
  "& img": {
    width: 17,
    marginBottom: "-3px",
  },
}))

const ClubSubtitle = styled(Typography)(({theme})=>({
  color: "#033f23",
}))

// eslint-disable-next-line react/prop-types
const ClubContainer = ({ children }: React.PropsWithChildren) => {
  const { t } = useTranslation("translation");
  const theme = useTheme();
  return (
    <MainContainer
      title={
        <ClubTitle variant="h1">
          {t("Pages.Clubs.MyhouseClub", { hostname: window.location.hostname })} <img src={flower} />
        </ClubTitle>
      }
      subTitle={
        <ClubSubtitle variant="h3">
          {t("Pages.Clubs.MoreValue")}
        </ClubSubtitle>
      }
      menuColor={theme.palette.yellow.main}
    >
      {children}
    </MainContainer>
  );
};

export default ClubContainer;
