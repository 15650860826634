import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';

type DeleteFolderDialogProps = {
    open: boolean;
    handleClose: () => void;
    handleDelete: () => Promise<void>;
    folderName: string;
}

const DeleteFolderDialog = (props: DeleteFolderDialogProps) => {
    const { t} = useTranslation("translation");
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only("xs"));
    
  return (
    <Dialog open={props.open} onClose={props.handleClose} fullScreen={isXs}>
    <DialogTitle>{t("Documents.DeleteFolder") + " " + props.folderName}</DialogTitle>
      <DialogContent>
      <Grid container pt={2}>
        <Typography>{t("Documents.AreYouSureYouWantToDelete")}</Typography>
      </Grid>
        <Grid container pt={2} justifyContent={isXs ? "center" : "flex-end"} spacing={1}>
          <Grid item pt={1}>
            <Button
              sx={{
                minWidth: "112px",
              }}
              variant="contained"
              color="secondary"
              size="large"
              onClick={props.handleDelete}
            >
              {t("General.Buttons.Delete")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              sx={{
                minWidth: "112px",
              }}
              variant="contained"
              color="secondary"
              size="large"
              onClick={props.handleClose}
            >
              {t("General.Buttons.Cancel1")}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
  </Dialog>
  )
}

export default DeleteFolderDialog