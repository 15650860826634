import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import UnitHelper from "../../../../helpers/unit-helper";
import Unit from "../../../../definitions/model/unit/Unit";

type HouseTitleProps = {
  unit: Unit;
};

const HouseInfoTitle = ({ unit }: HouseTitleProps): JSX.Element => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Typography variant="body1">{UnitHelper.GetPropertyTypeDisplayString(unit.PropertyType)}</Typography>
      <Typography variant="body1">{UnitHelper.GetFullAddress(unit.Address, false, false)}</Typography>
      <Typography variant="body1">{`${unit.Address.PostalCode}`}</Typography>
      <Typography variant="body1">{`${unit.Address.City}`}</Typography>
    </>
  );
};

export default HouseInfoTitle;
