import {
  NavigationItem,
  CreateNavigationItemContract,
  UpdateNavigationItemContract,
  ReorderNavigationItemsContract,
} from "../../../../definitions/Menu";
import ApiService from "../../../../services/api.service";
import { headers } from "../../../../services/http.service";

const AdminPartnersService = {
  addPartnersItem: async (
    item: CreateNavigationItemContract
  ): Promise<NavigationItem> => {
    const url = `/api/admin/partner-nav-items`;
    const result = await ApiService.put<NavigationItem>(url, item);

    return result;
  },
  getPartnersItems: async (): Promise<NavigationItem[]> => {
    const url = `/api/admin/partner-nav-items`;
    const result = await ApiService.get<NavigationItem[]>(url);

    return result;
  },
  deletePartnersItem: async (itemId: string): Promise<string> => {
    const url = `/api/admin/partner-nav-items/${itemId}`;
    const result = await ApiService.delete<string>(url);

    return result;
  },
  reorderPartnersItems: async (
    items: NavigationItem[]
  ): Promise<NavigationItem[]> => {
    const url = `/api/admin/partner-nav-items/reorder`;
    const data = <ReorderNavigationItemsContract>{ NavItems: items };
    const result = await ApiService.post<NavigationItem[]>(url, data);

    return result;
  },
  setPartnersItemPublish: async (
    itemId: string,
    state: boolean
  ): Promise<NavigationItem> => {
    const url = `/api/admin/partner-nav-items/${itemId}/publish/${state}`;
    const result = await ApiService.post<NavigationItem>(url, null);

    return result;
  },
  updatePartnersItem: async (
    item: UpdateNavigationItemContract
  ): Promise<NavigationItem> => {
    const url = `/api/admin/partner-nav-items/${item.Id}`;
    const result = await ApiService.post<NavigationItem>(url, item);

    return result;
  },
  updatePartnersBackgroundImage: async (picture: File): Promise<string> => {
    const formData = new FormData();
    const blob = new Blob([picture], { type: picture.type });
    formData.append("File", blob);

    const headersNoContentType = await headers();
    delete headersNoContentType["Content-Type"];

    const url = `/api/admin/partner-nav-items/uploadbackground`;
    const response = await fetch(url, {
      method: "POST",
      body: formData,
      headers: headersNoContentType,
    });
    const result = await response.text();
    if (response.status === 200) {
      return result;
    } else {
      throw result;
    }
  },
};

export default AdminPartnersService;
