/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {Button, Grid, styled, Typography} from "@mui/material";
import { useTranslation } from "react-i18next";
import User from "../../../definitions/model/User";
import ChangesDialog from "./ChangesDialog";

const Container = styled(Grid)(({theme})=>({
  background: theme.palette.primary.main,
  marginBottom: 30,
  padding: "24px",
  "& h3, & p": {
    color: "#fff",
  },
}));

const StyledButton = styled(Button)(({theme})=>({
  color: "#fff",
  borderColor: "#fff",
  height: 24,
}))

type ChangesAlertProps = {
  user: User;
  hideAlert: () => void;
  children: React.ReactNode;
};

const ChangesAlert = ({
  hideAlert,
  user,
  children,
}: ChangesAlertProps): JSX.Element => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation("translation");
  const showDialog = () => setDialogOpen(true);
  const closeDialog = () => {
    setDialogOpen(false);
  };
  const acceptDialog = () => {
    hideAlert();
  };
  return (
    <Container container direction="column">
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="h3">
          {t("CO2Calculator.Dear")} {user.Name},
        </Typography>
        <StyledButton
          variant="contained"
          color="secondary"
          onClick={showDialog}
        >
          {t("General.Buttons.Close")}
        </StyledButton>
      </Grid>
      <Typography paragraph> sendt. 08.10.2020</Typography>

      <Grid container>{children}</Grid>
      <ChangesDialog
        open={isDialogOpen}
        closeDialog={closeDialog}
        okClick={acceptDialog}
      />
    </Container>
  );
};

export default ChangesAlert;
