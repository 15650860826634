import React from "react";
import { IosShare, AddBoxOutlined, Close } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  handleClose: () => void;
};

export const PwaDialog = ({ open, handleClose }: Props) => {
  const { t } = useTranslation("translation");
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        {t("ClimatePlan.PwaDialog.Title")}
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {t("ClimatePlan.PwaDialog.Paragraph1")}
        <List>
          <StyledListItem>
            <IosShare />
            <ListItemText primary={t("ClimatePlan.PwaDialog.Paragraph2")} />
          </StyledListItem>
          <StyledListItem>
            <AddBoxOutlined />
            <ListItemText primary={t("ClimatePlan.PwaDialog.Paragraph3")} />
          </StyledListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
};

const StyledListItem = styled(ListItem)({
  paddingLeft: 0,
  "& svg": {
    marginRight: 10,
  },
});
