import { Dialog, DialogTitle, LinearProgress } from "@mui/material";
import React from "react";
import { CompanyTestimonialModel, CompanyModel } from "../../../../../definitions/Company";
import CompanyTestimonialDataForm from "./CompanyTestimonialDataForm";

type CompanyTestimonialEditFormProps = {
  testimonial: CompanyTestimonialModel | null;
  companies: CompanyModel[];
  domains: string[];
  open: boolean;
  loading: boolean;
  handleClose: () => void;
  editTestimonial: (testimonial: CompanyTestimonialModel) => Promise<void>;
};

const CompanyTestimonialEditDialog = (props: CompanyTestimonialEditFormProps): JSX.Element => {
  const submit = (testimonial: CompanyTestimonialModel) => {
    if (props.testimonial) {
      props
        .editTestimonial({
          ...testimonial,
          CompanyId: props.testimonial.CompanyId,
          Id: props.testimonial.Id,
        })
        .then(() => props.handleClose());
    }
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit company testimonial</DialogTitle>
      {!!props.testimonial && (
        <CompanyTestimonialDataForm
          submit={submit}
          id={props.testimonial?.Id ?? undefined}
          existingTestimonial={props.testimonial}
          companies={props.companies}
          domains={props.domains}
          handleClose={props.handleClose}
        />
      )}
      {props.loading && <LinearProgress />}
    </Dialog>
  );
};

export default CompanyTestimonialEditDialog;
