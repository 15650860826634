import React, { useMemo } from "react";
import BaseDialog from "../BaseDialog";
import BaseMoveOutPart from "./BaseMoveOutPart";
import ChangeFamilyOwnerPart from "./ChangeFamilyOwnerPart";
import RemoveDocumentsPart from "./RemoveDocumentsPart";
import { GroupMembersDto, GroupMemberDto } from "../../../definitions/model/Group";
import { useDefaultReducer } from "../../../helpers/hooks";
import { GroupMemberRole } from "../../../constants/enums";
import { useTranslation } from "react-i18next";

export type MoveOutDialogSx = {
  button: {
    root: string;
    label: string;
  };
};

type MoveOutDialogProps = {
  open: boolean;
  from: string;
  unitId: string;
  unitWithCo2Plan: boolean;
  userId: string;
  isOwnerChangeRequired: boolean;
  hasDocuments: boolean;
  family?: GroupMembersDto;
  closeDialog: () => void;
  sendMoveOutRequests: (unitId: string, removeAllDocuments?: boolean, newFamilyOwner?: GroupMemberDto) => Promise<void>;
};

type MoveOutDialogState = {
  newFamilyOwner?: GroupMemberDto;
  removeAll?: boolean;
  documentIds?: string[];
  stage: number;
  loading: boolean;
};

const initialState: MoveOutDialogState = {
  newFamilyOwner: undefined,
  removeAll: undefined,
  documentIds: undefined,
  stage: 0,
  loading: false,
};

const MoveOutDialog = (props: MoveOutDialogProps): JSX.Element => {
  const [state, dispatch] = useDefaultReducer(initialState);
  const { t } = useTranslation("translation");

  const isMovedIn = useMemo(
    () =>
      !!props.family?.Members.find(
        (x) =>
          x.UserId === props.userId && x.Role !== GroupMemberRole.NotValidMember && x.Role !== GroupMemberRole.Undefined
      ),
    [props.family, props.userId]
  );

  const handleMoveOutStages = async () => {
    if (props.isOwnerChangeRequired) {
      dispatch({
        type: "stage",
        payload: 1,
      });
    } else if (props.hasDocuments && isMovedIn) {
      dispatch({
        type: "stage",
        payload: 2,
      });
    } else {
      await props.sendMoveOutRequests(props.unitId, state.removeAll, state.newFamilyOwner);
      props.closeDialog();
    }
  };

  const handleRemoveAll = async () => {
    dispatch({
      type: "removeAll",
      payload: true,
    });
    dispatch({
      type: "loading",
      payload: true,
    });
    await props.sendMoveOutRequests(props.unitId, true, state.newFamilyOwner);

    handleCloseClick();
  };

  const handleLeaveAll = async () => {
    dispatch({
      type: "loading",
      payload: true,
    });
    await props.sendMoveOutRequests(props.unitId, state.removeAll, state.newFamilyOwner);

    handleCloseClick();
  };

  const handleCloseClick = () => {
    props.closeDialog();
    dispatch({
      type: "stage",
      payload: 0,
    });
    dispatch({
      type: "loading",
      payload: false,
    });
  };

  const handleChangeFamilyOwner = async (newOwner: GroupMemberDto) => {
    dispatch({
      type: "newFamilyOwner",
      payload: newOwner,
    });

    if (props.hasDocuments && isMovedIn) {
      dispatch({
        type: "stage",
        payload: 2,
      });
    } else {
      dispatch({
        type: "loading",
        payload: true,
      });
      await props.sendMoveOutRequests(props.unitId, state.removeAll, newOwner);
      handleCloseClick();
    }
  };

  return (
    <>
      <BaseDialog
        titleComponent={t("Base.Dialog.MoveOutTitle") ?? ""}
        closeDialog={handleCloseClick}
        open={props.open}
        loading={state.loading}
      >
        {state.stage === 0 && (
          <BaseMoveOutPart
            family={props.family}
            hasCo2={props.unitWithCo2Plan}
            onCloseClick={handleCloseClick}
            onOkClick={handleMoveOutStages}
          />
        )}

        {state.stage === 1 && (
          <ChangeFamilyOwnerPart
            userId={props.userId}
            userFamily={props.family}
            changeFamilyOwner={handleChangeFamilyOwner}
            onCloseClick={handleCloseClick}
          />
        )}

        {state.stage === 2 && (
          <RemoveDocumentsPart
            from={props.from}
            unitId={props.unitId}
            state={state}
            dialogType="moveout"
            hasDocuments={props.hasDocuments}
            onCloseClick={handleCloseClick}
            onRemoveAll={handleRemoveAll}
            onLeaveAll={handleLeaveAll}
          />
        )}
      </BaseDialog>
    </>
  );
};

export default MoveOutDialog;
