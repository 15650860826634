import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField, TextFieldProps } from "@mui/material";
import { Autocomplete } from "@mui/material";

import { AutocompleteChangeDetails } from "@mui/material";

import { AutocompleteChangeReason, AutocompleteInputChangeReason } from "@mui/material/useAutocomplete";

import User from "../../../../../definitions/model/User";
import { SystemStringModel } from "../../../../../definitions/model/SystemString";

type AddRecipientDialogProps = {
  open: boolean;
  selectUsers: User[];
  selectUtmSources: SystemStringModel[];
  handleClose: () => void;
  handleSave: (id: string, landingName: string) => Promise<User>;
  onAutocompleteChange: (emailPart: string) => Promise<void>;
};

const AddRecipientDialog = (props: AddRecipientDialogProps) => {
  const [id, setId] = useState("");
  const [user, setUser] = useState<User | null>(null);
  const [utmSource, setUtmSource] = useState<SystemStringModel | null>(null);

  const handleAutocompleteChange = (
    event: React.ChangeEvent<unknown>,
    value: User | null | string,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<User> | undefined
  ) => {
    setId((value as User)?.Id ?? "");
    setUser(value as User);
  };

  const handleAutocompleteInputChange = async (
    event: React.ChangeEvent<unknown>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (value.length < 3) {
      return;
    }
    props.onAutocompleteChange(value);
  };

  return (
    <Dialog open={props.open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add alarm recipient</DialogTitle>
      <DialogContent>
        <Autocomplete
          id="user"
          style={{ minWidth: 300, marginBottom: 40 }}
          onChange={handleAutocompleteChange}
          onInputChange={handleAutocompleteInputChange}
          value={user}
          options={props.selectUsers}
          getOptionLabel={(option) => option.Email}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...(params as TextFieldProps)}
              variant="outlined"
              label="Enter email"
              InputProps={{
                ...params.InputProps,
                endAdornment: <></>,
              }}
            />
          )}
        />
        <Autocomplete
          id="urmSource"
          value={utmSource}
          onChange={(event, value) => setUtmSource(value)}
          options={props.selectUtmSources}
          getOptionLabel={(option) => option.Value}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...(params as TextFieldProps)}
              variant="outlined"
              label="Enter utm source"
              InputProps={{
                ...params.InputProps,
                endAdornment: <></>,
              }}
            />
          )}
        ></Autocomplete>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => props.handleClose()} color="secondary">
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!id}
          onClick={() => props.handleSave(id, utmSource?.Value || "").then(props.handleClose)}
          color="secondary"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddRecipientDialog;
