import React, { useState } from "react";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import {
  BenefitClub,
  BenefitClubType,
  UpdateBenefitClubContract,
} from "../../../../../definitions/Landing";
import { useAlertContext } from "../../../../../components/Base";
import { ClubModel } from "../../../../../definitions/model/Club";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";
import CircularProgressButton from "../../../../../components/Base/CircularProgressButton";
type UpdateBenefitClubFormData = {
  Title: string;
  Type: BenefitClubType;
  LinkUrl: string;
  ClubId: string;
};

type BenefitClubFormProps = {
  benefitClub: BenefitClub;
  clubsList: Array<ClubModel>;
  updateBenefitClub: (
    benefitClub: UpdateBenefitClubContract
  ) => Promise<BenefitClub | undefined>;
};

const BenefitClubForm = ({
  clubsList,
  benefitClub,
  updateBenefitClub,
}: BenefitClubFormProps): JSX.Element => {
  const methods = useForm<UpdateBenefitClubFormData>({
    defaultValues: {
      Title: benefitClub?.Title ?? "",
      Type: benefitClub?.Type ?? BenefitClubType.ClubsPage,
      LinkUrl: benefitClub?.LinkUrl ?? "",
      ClubId: benefitClub?.ClubId ?? "",
    },
  });
  const { showAlert } = useAlertContext();
  const [isUpdating, setIsUpdating] = useState(false);
  const [showLink, setShowLink] = useState(
    benefitClub?.Type === BenefitClubType.Link
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = methods;

  const onSumbit = (formData: UpdateBenefitClubFormData) => {
    setIsUpdating(true);

    const data: UpdateBenefitClubContract = {
      ClubId: formData.ClubId,
      Id: benefitClub.Id,
      LinkUrl: formData.LinkUrl,
      Title: formData.Title,
      Type: formData.Type,
    };
    updateBenefitClub(data)
      .then(() => {
        showAlert({ severity: "success", text: "Fordelsklub updated" });
      })
      .catch(() =>
        showAlert({ severity: "error", text: "Fordelsklub not updated" })
      )
      .finally(() => setIsUpdating(false));
  };

  const selectItems = Object.entries(BenefitClubType).filter(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([key, val]) => !Number.isInteger(val)
  );

  return (
    <form onSubmit={handleSubmit(onSumbit)} style={{width: "100%"}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFieldConnector register={register("Title", { required: true })}>
            <TextField
              variant="outlined"
              fullWidth
              label="Title"
              margin="dense"
              error={!!errors.Title}
            />
          </TextFieldConnector>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel>Fordelsklub type</InputLabel>
            <Controller
              name="Type"
              render={({ field }) => (
                <Select
                  {...field}
                  onChange={(e) => {
                    field.onChange(+e.target.value);
                    setShowLink(+e.target.value === BenefitClubType.Link);
                  }}
                >
                  {selectItems.map(([key, val]) => (
                    <MenuItem
                      key={key}
                      value={key}
                      style={{ padding: "10px 20px" }}
                    >
                      {val}
                    </MenuItem>
                  ))}
                </Select>
              )}
              control={control}
            />
          </FormControl>
        </Grid>
        {showLink && (
          <Grid item xs={12}>
            <TextFieldConnector register={register("LinkUrl")}>
              <TextField
                variant="outlined"
                fullWidth
                label="Link Url"
                margin="dense"
                error={!!errors.LinkUrl}
              />
            </TextFieldConnector>
          </Grid>
        )}
        {!showLink && (
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth margin="dense">
              <InputLabel>Club</InputLabel>
              <Controller
                name="ClubId"
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={(e) => field.onChange(e.target.value)}
                  >
                    {clubsList.map((item) => (
                      <MenuItem
                        key={item.Id}
                        value={item.Id}
                        style={{ padding: "10px 20px" }}
                      >
                        {item.Name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                control={control}
              />
            </FormControl>
          </Grid>
        )}
        <Grid container justifyContent="flex-end" item>
          <CircularProgressButton            
            type="submit"
            inProgress={isUpdating}
          >
            Update
          </CircularProgressButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default BenefitClubForm;
