import { PermissionTarget } from "../constants/enums";

export enum DocumentSourceType {
  Unit = 0,
  Building = 1,
}

export type UnitFoldersType = {
  UnitId: string;
  Folders: FolderType[];
  Address: string;
};

export type FolderType = {
  CanEdit: boolean;
  Description: string;
  Documents: DocumentType[];
  Id: string;
  ParentFolderId: string | null;
  Name: string;
  isDirty: boolean;
  Permission: PermissionTarget;
};

export type DocumentType = {
  Description: string;
  Files: FileType[];
  Id: string;
  Name: string;
  Submitted: string;
  isDirty: boolean;
  Permission: PermissionTarget;
  RelatedSourceIds: string[];
};

export type FileType = {
  Id: string;
  Name: string;
  FileExtension: string;
  Url?: string;
  AbsoluteUrl?: string;
  File?: File;
  Published?: boolean;
};

export type CreateFolderRequest = {
  Name: string;
  Description: string;
  SourceItemId: string;
  SourceType: DocumentSourceType;
  Permission: PermissionTarget;
};

export type CreateBasicFoldersRequest = {
  SourceItemId: string;
  SourceType: DocumentSourceType;
  Permission: PermissionTarget;
};

export type CreateFolderHierarchyRequest = {
    Folders: FolderHierarchyRequest[];
    SourceItemId: string;
    SourceType: DocumentSourceType;
}
export type FolderHierarchyRequest = {
    Name: string;
    Description: string;
    Permission: PermissionTarget;
    SubFolders: FolderHierarchyRequest[];
}

export type RemoveFolderRequest = {
  SourceItemId: string;
  FolderId: string;
};

export type ReorderFoldersRequest = {
  SourceItemId: string;
  SourceType: DocumentSourceType;
  FolderId: string;
  ParentFolderId: string | null;
  DestinationIndex: number;
};

export type CreateDocumentRequest = {
  Name: string;
  Description: string;
  SourceItemId: string;
  SourceType: DocumentSourceType;
  ChildSourceItemId?: string;
  Permission: PermissionTarget;
  FolderId: string;
  FileIds: string[];
};

export type RemoveDocumentRequest = {
  SourceItemId: string;
  FolderId: string;
  DocumentId: string;
};

export type UpdateDocumentRequest = {
  Name: string;
  Description: string;
  SourceItemId: string;
  SourceType: DocumentSourceType;
  RelatedSourceIds: string[];
  Permission: PermissionTarget;
  FolderId: string;
  DocumentId: string;
  FileIds: string[];
};

export type ReorderDocumentsRequest = {
  SourceItemId: string;
  SourceType: DocumentSourceType;
  FolderId: string;
  DocumentId: string;
  DestinationIndex: number;
};

export type UpdateDocumentFolderRequest = {
  SourceItemId: string;
  SourceType: DocumentSourceType;
  Permission: PermissionTarget;
  Name: string;
  Description: string;
  FolderId: string;
};
