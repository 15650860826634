import React, { useEffect, ChangeEvent, useReducer, useMemo } from "react";
import { Grid, Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import MTextField from "../../../../components/Base/MyhouseTextField";
import { useNavigate } from "react-router-dom";
import { ClubGroupModel, ClubModel } from "../../../../definitions/model/Club";
import { useAlertContext } from "../../../../components/Base";
import ClubCreationDialog from "./components/ClubCreationDialog";
import { AdminTable, Column, Icon } from "../../components/AdminTable/AdminTable";
import { useClubs } from "../../../../actions";

type ClubsPageState = {
  searchString: string;
  addClubModalShown: boolean;
};

const initialState: ClubsPageState = {
  searchString: "",
  addClubModalShown: false,
};

const reducer = (state: ClubsPageState, action: { type: string; payload: unknown }) => {
  return { ...state, [action.type]: action.payload };
};

const ClubsPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const [club, actions] = useClubs();

  const columns: Column<ClubModel>[] = useMemo(
    () => [
      {
        name: "Name",
        accessor: "Name",
      },
      {
        name: "Created",
        accessor: "Created",
        format: (data) => new Date(data).toLocaleString("da-dk"),
      },
      {
        name: "Edit",
        callback: (data) => navigate(`/admin/clubs/${data.Id}`, { state: { club: data } }),
        icon: Icon.Edit,
      },
    ],
    []
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: "searchString", payload: event.currentTarget.value });

  const { showAlert } = useAlertContext();

  useEffect(() => {
    const getAsync = async () => {
      actions.getClubs(0, 50);
    };
    getAsync();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showAddClubModal = () => {
    dispatch({ type: "addClubModalShown", payload: true });
  };

  const handleModalClose = (group?: ClubGroupModel) => {
    dispatch({ type: "addClubModalShown", payload: false });
  };

  const handleDeleteClick = (groupId: string) => {
    actions
      .deleteClub(groupId)
      .then(() => {
        showAlert({
          severity: "success",
          text: "Club deleted",
        });
      })
      .catch(() => {
        showAlert({
          severity: "error",
          text: "Club not deleted",
        });
      });
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid container spacing={3} item sm={10} xs={12}>
          <Grid item container>
            <MTextField fullWidth placeholder="Search by title" onChange={handleChange} />
          </Grid>

          <Grid item container>
            <AdminTable
              data={club.clubs.filter((club) =>
                club.Name.toLocaleLowerCase().includes(state.searchString.toLowerCase())
              )}
              columns={columns}
              loading={club.loading}
              pagination={"client"}
              headerButton={
                <Grid container item justifyContent="start">
                  <Button title="Add group" color="primary" variant="contained" onClick={showAddClubModal}>
                    <Add />
                  </Button>
                </Grid>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <ClubCreationDialog open={state.addClubModalShown} createClub={actions.addClub} handleClose={handleModalClose} />
    </>
  );
};

export default ClubsPage;
