import React from "react";
import Alert from '@mui/material/Alert';
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import { DashboardUpdatingFrequency } from "../../../../constants/enums";
import { DashboardEntryModel } from "../../../../definitions/model/Dashboard";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import { TextFieldConnector } from "../../../../components/Base/FormConnector";
type DashboardEntryFormProps = {
  entry?: DashboardEntryModel;
  onSubmit: (data: DashboardEntryFormFields) => Promise<DashboardEntryModel>;
  submitText: string;
};

export type DashboardEntryFormFields = {
  Name: string;
  UpdatingFrequency: DashboardUpdatingFrequency;
  Environment: string;
};

const DashboardEntryForm = (props: DashboardEntryFormProps): JSX.Element => {
  const { handleSubmit, register, watch, control } =
    useForm<DashboardEntryFormFields>({
      defaultValues: {
        Name: props.entry?.Name ?? "",
        Environment: props.entry?.Environment ?? "common",
        UpdatingFrequency:
          props.entry?.UpdatingFrequency ?? DashboardUpdatingFrequency.Daily,
      },
    });

  const frequencyValues = Object.entries(DashboardUpdatingFrequency).filter(
    ([k, v]) => Number.isInteger(v)
  );

  return (
    <form
      onSubmit={handleSubmit((data) => {
        props.onSubmit(data);
      })}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <TextFieldConnector register={register("Name")}>
            <TextField
              label="Name"
              fullWidth
              variant="outlined"
              margin="dense"
            />
          </TextFieldConnector>
          <Fade
            in={props.entry && props.entry.Name !== watch().Name}
            mountOnEnter
            unmountOnExit
          >
            <Alert severity="warning">
              Make sure the entry name is changed in the service itself
            </Alert>
          </Fade>
        </Grid>
        <Grid item xs={10}>
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel id="frequency">Frequency</InputLabel>
            <Controller
              name="UpdatingFrequency"
              render={({ field }) => (
                <Select {...field} labelId="frequency" label="Frequency">
                  {frequencyValues.map(([k, v]) => (
                    <MenuItem key={k} value={v}>
                      {k}
                    </MenuItem>
                  ))}
                </Select>
              )}
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item xs={10}>
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel id="environment">Environment</InputLabel>
            <Controller
              name="Environment"
              render={({ field }) => (
                <Select {...field} labelId="environment" label="Environment">
                  <MenuItem value="0">Common</MenuItem>
                  <MenuItem value="2">Test</MenuItem>
                  <MenuItem value="1">Prod</MenuItem>
                </Select>
              )}
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item xs={10}>
          <Button variant="contained" color="secondary" type="submit">
            {props.submitText}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default DashboardEntryForm;
