import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from 'react-i18next';

type NewsTemplateDialogProps = {
  open: boolean;
  handleClose: (confirmed?: boolean) => void;
};

const DeleteDialog = (props: NewsTemplateDialogProps) => {

  const { t } = useTranslation("translation");

  const handleClose = (confirmed = false) => {
      props.handleClose(confirmed);
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => handleClose(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Confirm Deleting
      </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button  variant="contained" onClick={() => handleClose(false)} color="secondary">
          {t("General.Buttons.Undo")}
        </Button>
          <Button  variant="contained" onClick={() => handleClose(true)} color="secondary">
          {t("General.Buttons.Ok")}
        </Button>
        </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
