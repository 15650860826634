import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
import { DashboardEntryModel } from "../../../../definitions/model/Dashboard";
import HistoryView from "./HistoryView";
import Subscriptions from "./Subscriptions";
import DashboardEntryForm, {
  DashboardEntryFormFields,
} from "./DashboardEntryForm";
import { DashboardUpdatingFrequency } from "../../../../constants/enums";
import ConfirmDialog from "../../../../components/Dialogs/ConfirmDialog";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {styled} from "@mui/material";

type EntryManagerProps = {
  entry: DashboardEntryModel;
  onEntryUpdate: (
    id: string,
    name: string,
    frequency: DashboardUpdatingFrequency,
    environment: string
  ) => Promise<DashboardEntryModel>;
  onEntryRemove: (id: string) => Promise<void>;
};

const StyledTabs = styled(Tabs)(({theme})=>({
  "& button>span": {
    fontSize: "0.875rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.625rem",
    },
  },
}))

const EntryManager = (props: EntryManagerProps): JSX.Element => {
  const [value, setValue] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const tabsRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  useEffect(() => {
    return () => {
      setValue(0);
    };
  }, [props.entry.Id]);

  const height = isSm
    ? "400px"
    : containerRef.current && tabsRef.current
    ? containerRef.current.clientHeight - tabsRef.current.clientHeight + "px"
    : "100%";

  const onEntryUpdate = (data: DashboardEntryFormFields) =>
    props.onEntryUpdate(
      props.entry.Id,
      data.Name,
      data.UpdatingFrequency,
      data.Environment
    );

  return (
    <Paper
      square
      elevation={2}
      sx={{
        width: "100%",
        flexGrow: 1
      }}
      ref={containerRef}
    >
      <Grid container direction="column">
        <Grid item ref={tabsRef} xs={12}>
          <StyledTabs
            centered
            value={value}
            textColor="primary"
            indicatorColor="primary"
            onChange={handleChange}
          >
            <Tab label="History" />
            <Tab label="Subscriptions" />
            <Tab label="Edit" />
          </StyledTabs>
        </Grid>
        <Grid item xs={12}>
          {value === 0 && (
            <HistoryView
              history={props.entry.History}
              height={height}
            />
          )}
          {value === 1 && <Subscriptions entry={props.entry} />}
          {value === 2 && (
            <>
              <DashboardEntryForm
                entry={props.entry}
                onSubmit={onEntryUpdate}
                submitText="Save"
              />
              <Box my={2}>
                <Divider />
              </Box>
              <Box m={2}>
                <ConfirmDialog
                  OkClickHandler={() => props.onEntryRemove(props.entry.Id)}
                  text="Are you shure want to remove this entry?"
                >
                  <Button
                    sx={(theme) => ({
                      backgroundColor: theme.palette.error.main,
                      color: theme.palette.error.contrastText,
                      "&:hover": {
                        backgroundColor: theme.palette.error.dark,
                        color: theme.palette.error.contrastText,
                      },
                    })}
                    variant="contained"
                  >
                    Delete {props.entry.Name}
                  </Button>
                </ConfirmDialog>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EntryManager;
