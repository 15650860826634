import React, { useEffect } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";
import { useAlertContext } from "../../../../../components/Base";
import { LoadingButton } from "@mui/lab";
import { useAdmin } from "../../../../../actions/admin/admin.actions";
import { SystemStringModel, SystemStringType } from "../../../../../definitions/model/SystemString";

export type SystemStringFormData = {
  Id: string | null;
  Type: SystemStringType;
  Value: string;
};

type SystemMetaEditorProps = {
  open: boolean;
  systemString: SystemStringModel | null;
  handleClose: () => void;
};

const SystemMetaEditor = (props: SystemMetaEditorProps) => {
  const { t } = useTranslation("translation");
  const [{ error, loading }, { addSystemStrings,  }] = useAdmin();
  const options = Object.entries(SystemStringType)
    .filter(([key, val]) => !Number.isInteger(val))
    .map(([key, val]) => {
      return { key: key, value: val as SystemStringType };
    });

  useEffect(() => {
    if (props.systemString) {
      setValue("Type", props.systemString.Type);
      setValue("Value", props.systemString.Value);
    }
  }, [props.systemString]);

  const { showAlert } = useAlertContext();

  const methods = useForm<SystemStringFormData>({
    defaultValues: {
      // Type: null,
      Value: "",
    },
  });

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (error) {
      showAlert({ text: error, severity: "error" });
    }
  }, [error]);

  const onSubmit = async (data: SystemStringFormData) => {
    if (!props.systemString) {
      await addSystemStrings(data.Value, data.Type);
      props.handleClose();
    } else {
      await 
      // await updateSystemMeta(props.systemMeta.Id, data.Hostname, data.Path, data.Replacements);
      props.handleClose();
    }
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">System string</DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container justifyContent="flex-center" xs={12} gap={2}>
              <Grid item xs={12}>
                <Autocomplete
                  id="systemStringType"
                  // value={getValue("Type") ?? null}
                  onChange={(event, value) => {
                    if (value) setValue("Type", value.value);
                  }}
                  options={options}
                  getOptionLabel={(option) => option.value.toString()}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...(params as TextFieldProps)}
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Type"
                      error={!!errors.Type}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <></>,
                      }}
                    />
                  )}
                ></Autocomplete>
              </Grid>
              <Grid item xs={12}>
                <TextFieldConnector register={register("Value", { required: true })}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Value"
                    error={!!errors.Value}
                    InputLabelProps={{ shrink: true }}
                  />
                </TextFieldConnector>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" type="button" onClick={props.handleClose} color="primary">
              {t("General.Buttons.Close")}
            </Button>
            <LoadingButton type="submit" variant="contained" color="secondary" loading={loading}>
              {t("General.Buttons.Save")}
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default SystemMetaEditor;
