import { Dispatch } from "redux";
import { AdminSystemMmessageActionTypes } from "../../constants/admin/admin.system-message.actiontypes";
import { AppAction } from "../../definitions/Action";
import SystemMessageService from "../../services/admin/admin.system-message.service";
import {
  SystemMessageModel,
  SystemMessageReplacements,
  SystemMessageType,
} from "../../definitions/model/SystemMessage";
import { AdminActionTypes } from "../../constants/admin/admin.actiontypes";
import { LandingModel, LandingType } from "../../definitions/Landing";
import { useAppDispatch } from "..";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../reducers/store";

const operationFailedAction = (payload: unknown): AppAction => ({
  type: AdminActionTypes.ADMIN_OPERATION_FAILED,
  payload,
});

const getMessageTemplate =
  (messageId: string, withGrapesData = false) =>
  async (dispatch: Dispatch<AppAction>): Promise<LandingModel | undefined> => {
    try {
      dispatch({
        type: AdminSystemMmessageActionTypes.GET_MESSAGE_TEMPLATE,
      });
      const result = await SystemMessageService.getMessageTemplate(messageId, withGrapesData);
      dispatch({
        type: AdminSystemMmessageActionTypes.GET_MESSAGE_TEMPLATE_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getMessageEditor =
  (editorId: string) =>
  async (dispatch: Dispatch<AppAction>): Promise<any> => {
    try {
      dispatch({ type: AdminSystemMmessageActionTypes.GET_MESSAGE_EDITOR });
      const result = await SystemMessageService.getMessageEditor(editorId, LandingType.Email);
      dispatch({ type: AdminSystemMmessageActionTypes.GET_MESSAGE_EDITOR_SUCCEEDED });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const getSystemMessages =
  () =>
  async (dispatch: Dispatch<AppAction>): Promise<SystemMessageModel[] | undefined> => {
    try {
      dispatch({
        type: AdminSystemMmessageActionTypes.GET_SYSTEM_MESSAGES,
      });
      const result = await SystemMessageService.getSystemMessages();
      dispatch({
        type: AdminSystemMmessageActionTypes.GET_SYSTEM_MESSAGES_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const getSystemMessage =
  (id: string) =>
  async (dispatch: Dispatch<AppAction>): Promise<SystemMessageModel | undefined> => {
    try {
      dispatch({
        type: AdminSystemMmessageActionTypes.GET_SYSTEM_MESSAGE,
      });
      const result = await SystemMessageService.getSystemMessage(id);
      dispatch({
        type: AdminSystemMmessageActionTypes.GET_SYSTEM_MESSAGE_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const getSystemMessageTypes =
  () =>
  async (dispatch: Dispatch<AppAction>): Promise<SystemMessageType[] | undefined> => {
    try {
      dispatch({
        type: AdminSystemMmessageActionTypes.GET_SYSTEM_MESSAGE_TYPES,
      });
      const result = await SystemMessageService.getSystemMessageTypes();
      dispatch({
        type: AdminSystemMmessageActionTypes.GET_SYSTEM_MESSAGE_TYPES_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const getSystemMessageMissingTypes =
  () =>
  async (dispatch: Dispatch<AppAction>): Promise<SystemMessageType[] | undefined> => {
    try {
      dispatch({
        type: AdminSystemMmessageActionTypes.GET_SYSTEM_MESSAGE_MISSING_TYPES,
      });
      const result = await SystemMessageService.getSystemMessageMissingTypes();
      dispatch({
        type: AdminSystemMmessageActionTypes.GET_SYSTEM_MESSAGE_MISSING_TYPES_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const addSystemMessage =
  (name: string, messageSubject: string, type: number, domains: string[], toUser: boolean) =>
  async (dispatch: Dispatch<AppAction>): Promise<SystemMessageModel | undefined> => {
    try {
      dispatch({
        type: AdminSystemMmessageActionTypes.ADD_SYSTEM_MESSAGE,
      });
      const result = await SystemMessageService.addSystemMessage(name, messageSubject, type, domains, toUser);
      dispatch({
        type: AdminSystemMmessageActionTypes.ADD_SYSTEM_MESSAGE_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const editSystemMessage =
  (messageId: string, name: string, messageSubject: string, domains: string[], toUser: boolean) =>
  async (dispatch: Dispatch<AppAction>): Promise<SystemMessageModel | undefined> => {
    try {
      dispatch({
        type: AdminSystemMmessageActionTypes.EDIT_SYSTEM_MESSAGE,
      });
      const result = await SystemMessageService.editSystemMessage(messageId, name, messageSubject, domains, toUser);
      dispatch({
        type: AdminSystemMmessageActionTypes.EDIT_SYSTEM_MESSAGE_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const deleteSystemMessage =
  (id: string) =>
  async (dispatch: Dispatch<AppAction>): Promise<boolean> => {
    try {
      dispatch({
        type: AdminSystemMmessageActionTypes.DELETE_SYSTEM_MESSAGE,
      });
      const result = await SystemMessageService.deleteSystemMessage(id);
      dispatch({
        type: AdminSystemMmessageActionTypes.DELETE_SYSTEM_MESSAGE_SUCCEEDED,
        payload: id,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
    return false;
  };

const sendTestSystemMessage =
  (recipient: string, type: SystemMessageType, replacemnts: SystemMessageReplacements[]) =>
  async (dispatch: Dispatch<AppAction>): Promise<boolean> => {
    try {
      dispatch({
        type: AdminSystemMmessageActionTypes.SEND_TEST_SYSTEM_MESSAGE,
      });
      const result = await SystemMessageService.sendTestMessage(recipient, type, replacemnts);
      dispatch({
        type: AdminSystemMmessageActionTypes.SEND_TEST_SYSTEM_MESSAGE_SUCCEEDED,
      });
      return true;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
    return false;
  };

const useSystemMessageState = () =>
  useSelector((state: ApplicationState) => ({
    error: state.admin.error,
  }));

export const useSystemMessageActions = () => {
  const dispatch = useAppDispatch();
  return {
    getMessageTemplate: (messageId: string, withGrapesData = false) =>
      dispatch(getMessageTemplate(messageId, withGrapesData)),
    getMessageEditor: (editorId: string) => dispatch(getMessageEditor(editorId)),
    getSystemMessages: () => dispatch(getSystemMessages()),
    getSystemMessage: (id: string) => dispatch(getSystemMessage(id)),
    getSystemMessageTypes: () => dispatch(getSystemMessageTypes()),
    getSystemMessageMissingTypes: () => dispatch(getSystemMessageMissingTypes()),
    addSystemMessage: (name: string, messageSubject: string, type: number, domains: string[], toUser: boolean) =>
      dispatch(addSystemMessage(name, messageSubject, type, domains, toUser)),
    editSystemMessage: (messageId: string, name: string, messageSubject: string, domains: string[], toUser: boolean) =>
      dispatch(editSystemMessage(messageId, name, messageSubject, domains, toUser)),
    deleteSystemMessage: (id: string) => dispatch(deleteSystemMessage(id)),
    sendTestSystemMessage: (recipient: string, type: SystemMessageType, replacemnts: SystemMessageReplacements[]) =>
      dispatch(sendTestSystemMessage(recipient, type, replacemnts)),
  };
};

export const useSystemMessage = (): [
  ReturnType<typeof useSystemMessageState>,
  ReturnType<typeof useSystemMessageActions>
] => {
  const state = useSystemMessageState();
  const actions = useSystemMessageActions();

  return [state, actions];
};
