import React from "react";
import {Box, Grid, Divider, TextField, Typography, Button, styled} from "@mui/material";
import { ArrowUpward } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";

export type ProductFormData = {
  Name: string;
  Description: string;
};

type ProductFormProps = {
  defaultValues: ProductFormData;
  onSubmit: (values: ProductFormData) => void;
};

const ProductImage = styled(Grid)(({theme})=>({
  width: 150,
  height: 150,
  border: "1px solid rgba(0, 0, 0, 0.23)",
  borderRadius: 2,
  "&:hover": {
    cursor: "pointer",
    background: "rgba(0, 0, 0, 0.04)",
    transition: "background 0.2s ease-in",
  },
}))

const ProductForm = ({
  defaultValues,
  onSubmit,
}: ProductFormProps): JSX.Element => {
  const methods = useForm<ProductFormData>({ defaultValues });

  const { handleSubmit, register } = methods;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={3}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Edit Product</Typography>
          <Button type="submit" variant="contained" color="secondary">
            Save product
          </Button>
        </Grid>
        <Divider />
      </Box>
      <Grid container justifyContent="space-between">
        <Grid container item direction="column" sm={6} xs={12} spacing={3}>
          <Grid item>
            <TextFieldConnector register={register("Name", {required: true})}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Name"
              />
            </TextFieldConnector>
          </Grid>
          <Grid item>
            <TextFieldConnector register={register("Description")}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                multiline
                minRows={4}
                label="Description"
              />
            </TextFieldConnector>
          </Grid>
        </Grid>
        <ProductImage
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid container alignItems="center" justifyContent="center">
            <ArrowUpward style={{ fontSize: 16 }} color="primary" />
            <Typography variant="body2">Upload</Typography>
          </Grid>
        </ProductImage>
      </Grid>
    </form>
  );
};

export default ProductForm;
