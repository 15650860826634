import React, { useEffect, useReducer, useRef } from "react";
import { CircularProgress, styled } from "@mui/material";
import { Room } from "@mui/icons-material";
import { markerColor } from "../../../constants/theme";
import GoogleMapReact from "google-maps-react-markers";
import { House, LocalPlan, LocalPlanData, MapHousesData, MarkerParams, WindowPosition } from "../../../definitions/Map";
import { PolygonLayer } from "../../House/Map/map-layer";
import { getColor, createFeatureFromGeoJson, mapStyles } from "./../../House/Map/utils";
import { PlanPopover } from "../../../components/Base/Popovers";
import Unit from "../../../definitions/model/unit/Unit";
import MyhouseCard from "../../../components/Base/MyhouseCard";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

const MapContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: 612,
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    height: 400,
  },
}));

const MapContainerSkeleton = styled(Skeleton)(({ theme }) => ({
  width: "100%",
  height: 612,
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    height: 400,
  },
}));

const StyledMarker = styled("div")(({ theme }) => ({
  height: 4,
  "& svg": {
    width: 42,
    height: 56,
    color: markerColor.house,
    transform: "translate(-50%, -42px)",
    position: "relative",
    zIndex: 1,
  },
}));

declare const google: any;

type MapLocalState = {
  ownUnit: MapHousesData | null;
  popupHouses: House[];
  anchorHouse: HTMLDivElement | null;
  plansLayer: PolygonLayer | null;
  circle: typeof google.maps.Circle;
  lines: [];
  mapParams: MarkerParams | null;
  bounds: number[];
  zoom: number;
};

const initialState: MapLocalState = {
  ownUnit: null,
  popupHouses: [],
  anchorHouse: null,
  plansLayer: null,
  circle: null,
  lines: [],
  mapParams: null,
  bounds: [],
  zoom: 10,
};

const reducer = (state: any, action: { type: string; payload: any }) => {
  return { ...state, [action.type]: action.payload };
};

const Marker = ({ children }: any) => children;

type LocalPlanMapProps = {
  unit: Unit | null;
  loading: boolean;
  localPlans: LocalPlan[];
  planInfo: LocalPlan | null;
  getPlanInfo: (data: LocalPlanData, windowPosition: WindowPosition) => void;
};

const LocalPlanMap = (props: LocalPlanMapProps) => {
  const mapRef = useRef<any>();
  const mapsRef = useRef<any>();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { t } = useTranslation("translation");

  const handleMapParams = (bounds: any, zoom: number) => {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    dispatch({
      type: "mapParams",
      payload: {
        nelat: ne.lat(),
        nelon: ne.lng(),
        swlat: sw.lat(),
        swlon: sw.lng(),
        selat: sw.lat(),
        selon: ne.lng(),
        nwlat: ne.lat(),
        nwlon: sw.lng(),
        zoomLevel: zoom,
        w: Math.round(Math.max(document.documentElement.clientWidth / 1.5, window.innerWidth / 1.5 || 0)),
        h: Math.round(Math.max(document.documentElement.clientHeight, window.innerHeight || 0)),
      },
    });
  };

  const handleCloseLocalPlan = () => dispatch({ type: "localPlan", payload: null });

  const showInfoHouse = (event: React.MouseEvent<HTMLDivElement>, houses: House[]) => {
    dispatch({ type: "anchorHouse", payload: event.currentTarget });
    dispatch({ type: "popupHouses", payload: houses });
  };

  const initPlans = (map: any, maps: any) => {
    const layer = new PolygonLayer(mapRef.current, t("Base.MapFilter.LocalPlans"), 12, props.getPlanInfo);
    props.localPlans.map((plan: any) => {
      layer.Objects.push(createFeatureFromGeoJson(map, maps, getColor, plan.Description, plan, layer.MessageText));
    });
    layer.ToggleLayer(true);
    dispatch({ type: "plansLayer", payload: layer });
  };

  useEffect(() => {
    if (props.unit) {
      const data = new MapHousesData();
      data.House = [
        {
          Address: `${props.unit.Address.Street} ${props.unit.Address.HouseNo} ${props.unit.Address.Floor}`,
          PostalCode: props.unit.Address.PostalCode,
          City: props.unit.Address.City,
          PropertyType: props.unit.PropertyType,
          PictureUrl: "",
          AddressUrl: "",
          Area: props.unit.UnitData.UnitArea,
          GroundArea: "-",
          Rooms: props.unit.UnitData.Rooms,
          BuildYear: props.unit.UnitData.BuildYear,
        },
      ];
      data.Latitude = props.unit.Address.Latitude;
      data.Longitude = props.unit.Address.Longitude;

      dispatch({ type: "ownUnit", payload: data });
      //reset map center on new unit
      if (mapRef.current) {
        mapRef.current.setCenter({ lat: data.Latitude, lng: data.Longitude });
      }
    }
  }, [props.unit, mapRef.current]);

  useEffect(() => {
    dispatch({ type: "localPlan", payload: props.planInfo });
  }, [props.planInfo]);

  return (
    <MyhouseCard title={t("Subscriptions.MapOfLocalPlan")} type="narrow" contentType="narrow" sx={{ marginTop: 0 }}>
      {props.unit && props.localPlans.length > 1 ? (
        <>
          <PlanPopover
            position={state.localPlan?.position}
            data={state.localPlan?.data}
            handleClose={handleCloseLocalPlan}
          />
          <MapContainer>
            {props.loading && (
              <CircularProgress
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  zIndex: 100,
                }}
              />
            )}

            {state.ownUnit && (
              <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY ?? "" }}
                defaultCenter={{
                  lat: state.ownUnit.Latitude,
                  lng: state.ownUnit.Longitude,
                }}
                defaultZoom={16}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({ map, maps }: any) => {
                  mapRef.current = map;
                  mapsRef.current = maps;
                  initPlans(map, maps);
                }}
                options={{
                  streetViewControl: false,
                  mapTypeControl: true,
                  styles: mapStyles,
                }}
                onChange={({ zoom, bounds }: any) => {
                  dispatch({ type: "zoom", payload: zoom });
                  const ne = bounds.getNorthEast();
                  const sw = bounds.getSouthWest();
                  dispatch({
                    type: "bounds",
                    payload: [sw.lng(), sw.lat(), ne.lng(), ne.lat()],
                  });
                  handleMapParams(bounds, zoom);
                }}
              >
                <Marker lat={state.ownUnit.Latitude} lng={state.ownUnit.Longitude}>
                  <StyledMarker onClick={(event) => showInfoHouse(event, state.ownUnit.House)}>
                    <Room />
                  </StyledMarker>
                </Marker>
              </GoogleMapReact>
            )}
          </MapContainer>
        </>
      ) : (
        <MapContainerSkeleton variant="rectangular" />
      )}
    </MyhouseCard>
  );
};

export default React.memo(LocalPlanMap);
