import { DocumentActions } from "../constants/document.actiontypes";
import { AppThunkDispatch } from "../definitions/Action";
import {
  CreateFolderRequest,
  RemoveFolderRequest,
  CreateDocumentRequest,
  RemoveDocumentRequest,
  UpdateDocumentRequest,
  UpdateDocumentFolderRequest,
  DocumentSourceType,
  ReorderDocumentsRequest,
  ReorderFoldersRequest,
  CreateBasicFoldersRequest,
  CreateFolderHierarchyRequest
} from "../definitions/Document";
import DocumentService from "../services/document.service";
import { operationFailedActionGeneral, useAppDispatch } from ".";
import { useSelector } from "react-redux";
import { ApplicationState } from "../reducers/store";
import { v4 as uuid } from "uuid";
import { Dictionary } from "lodash";
import { PermissionTarget } from "../constants/enums";

const operationFailedAction = (payload: unknown) =>
  operationFailedActionGeneral(payload, DocumentActions.DOCUMENT_OPERATION_FAILED);

const getFolders =
  (sourceItemId: string, sourceType: DocumentSourceType, childSourceItemId?: string) =>
  async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({
        type: DocumentActions.GET_FOLDERS,
      });

      const result = await DocumentService.getFolders(sourceItemId, childSourceItemId);
      dispatch({
        type: DocumentActions.GET_FOLDERS_SUCCEDED,
        payload: { result, SourceType: sourceType },
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const createFolder = (data: CreateFolderRequest) => async (dispatch: AppThunkDispatch) => {
  try {
    const tempId = uuid();
    dispatch({
      type: DocumentActions.CREATE_FOLDER,
      payload: {
        ...data,
        Id: tempId,
        isDirty: true,
        Documents: [],
        Submitted: new Date(),
      },
    });
    const result = await DocumentService.createFolder(data);

    dispatch({
      type: DocumentActions.CREATE_FOLDER_SUCCEDED,
      payload: { ...result, tempId: tempId, SourceType: data.SourceType },
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const createBasicFolders = (data: CreateBasicFoldersRequest) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: DocumentActions.CREATE_BASIC_FOLDERS,
    });

      const folders: CreateFolderHierarchyRequest = {
          SourceItemId: data.SourceItemId,
          SourceType: data.SourceType,
          Folders:
          [
              {
                  Name: "Selskab",
                  Description: "",
                  Permission: data.Permission,
                  SubFolders: [
                      {
                          Name: "Selskabsdokumenter",
                          Description: "",
                          Permission: data.Permission,
                          SubFolders: []
                      }
                  ]
              },
              {
                  Name: "Ejendomme",
                  Description: "",
                  Permission: data.Permission,
                  SubFolders: [
                      {
                          Name: "Handel",
                          Description: "",
                          Permission: data.Permission,
                          SubFolders: []
                      },
                      {
                          Name: "Tilstandsrapport & vedligeholdelsesplaner",
                          Description: "",
                          Permission: data.Permission,
                          SubFolders: []
                      },
                      {
                          Name: "Serviceaftaler",
                          Description: "",
                          Permission: data.Permission,
                          SubFolders: []
                      },
                      {
                          Name: "Skatter & afgifter",
                          Description: "",
                          Permission: data.Permission,
                          SubFolders: []
                      },
                      {
                          Name: "Forsyning",
                          Description: "",
                          Permission: data.Permission,
                          SubFolders: []
                      },
                      {
                          Name: "Forbrugsregnskaber",
                          Description: "",
                          Permission: data.Permission,
                          SubFolders: []
                      },
                      {
                          Name: "Byggesager Underopdeles pr. sag",
                          Description: "",
                          Permission: data.Permission,
                          SubFolders: [
                              {
                                  Name: "Byggesag 1",
                                  Description: "",
                                  Permission: data.Permission,
                                  SubFolders: []
                              },
                              {
                                  Name: "Byggesag n",
                                  Description: "",
                                  Permission: data.Permission,
                                  SubFolders: []
                              },
                          ]
                      },

                  ]
              },
              {
                  Name: "Lejemål",
                  Description: "",
                  Permission: data.Permission,
                  SubFolders: [
                      {
                          Name: "Lejer",
                          Description: "",
                          Permission: data.Permission,
                          SubFolders: []
                      },
                      {
                          Name: "Lejekontrakt",
                          Description: "",
                          Permission: data.Permission,
                          SubFolders: []
                      },
                      {
                          Name: "Indflytning",
                          Description: "",
                          Permission: data.Permission,
                          SubFolders: []
                      },
                      {
                          Name: "Fraflytning",
                          Description: "",
                          Permission: data.Permission,
                          SubFolders: []
                      },
                      {
                          Name: "Rekvireret håndværkere",
                          Description: "",
                          Permission: data.Permission,
                          SubFolders: []
                      },
                      {
                          Name: "Huslejenævnssager",
                          Description: "",
                          Permission: data.Permission,
                          SubFolders: []
                      },
                  ]
              },
        ]
      };

    const result = await DocumentService.createFolders(folders);


    dispatch({
      type: DocumentActions.CREATE_BASIC_FOLDERS_SUCCEDED,
      payload: { result, SourceType: data.SourceType },
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};
const createBR18Folder = (SourceItemId: string, sourceType: DocumentSourceType, ) => async (dispatch: AppThunkDispatch) => {
    try {
        dispatch({
            type: DocumentActions.CREATE_BASIC_FOLDERS,
        });

        const folders: CreateFolderHierarchyRequest =
        {
            SourceItemId: SourceItemId,
            SourceType: sourceType,
            Folders: [
                {
                    Name: "BR18",
                    Description: "",
                    Permission: PermissionTarget.Member,
                    SubFolders: [
                        {
                            Name: "Projektdokumentation",
                            Description: "",
                            Permission: PermissionTarget.Member,
                            SubFolders: [
                                {
                                    Name: "Projektbeskrivelse",
                                    Description: "Beskrivelse af byggeriets omfang og formål.",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                },
                                {
                                    Name: "Tegninger og plantegninger",
                                    Description: "Bygningens konstruktion og design",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                },
                                {
                                    Name: "Materialevalg",
                                    Description: "Materialevalg og specifikationer for anvendte byggematerialer",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                },
                            ]
                        },
                        {
                            Name: "Energirammeberegning",
                            Description: "",
                            Permission: PermissionTarget.Member,
                            SubFolders: [
                                {
                                    Name: "Energiforbrug",
                                    Description: "Beregning af bygningens energiforbrug og energiramme i henhold til kravene i BR18",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                },
                                {
                                    Name: "Energiforbedring",
                                    Description: "Dokumentation af energiforbedrende foranstaltninger, f.eks. isolering, vinduer og energikilder",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                }
                            ]
                        },
                        {
                            Name: "Konstruktionsdokumentation",
                            Description: "",
                            Permission: PermissionTarget.Member,
                            SubFolders: [
                                {
                                    Name: "Bærende konstruktioner.",
                                    Description: "Beregninger og dokumentation for bygningens bærende konstruktioner.",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                },
                                {
                                    Name: "Statisk dokumentation",
                                    Description: "Oplysninger om belastninger, styrkeberegninger og dimensionering.",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                }
                            ]
                        },
                        {
                            Name: "Brandsikkerhed",
                            Description: "",
                            Permission: PermissionTarget.Member,
                            SubFolders: [
                                {
                                    Name: "Brandteknisk dokumentation og analyser",
                                    Description: "Brandteknisk dokumentation og analyser",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                },
                                {
                                    Name: "Flugtveje og nødudgange",
                                    Description: "Flugtveje og nødudgange samt materialer, der er anvendt i forhold til brandsikring.",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                }
                            ]
                        },
                        {
                            Name: "Indeklima og ventilation",
                            Description: "",
                            Permission: PermissionTarget.Member,
                            SubFolders: [
                                {
                                    Name: "Ventilation og luftkvalitetskrav",
                                    Description: "Dokumentation af ventilation og luftkvalitetskrav",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                },
                                {
                                    Name: "Lydisolering og lysforhold",
                                    Description: "Oplysninger om lydisolering og lysforhold i bygningen",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                }
                            ]
                        },
                        {
                            Name: "Tilgængelighed",
                            Description: "",
                            Permission: PermissionTarget.Member,
                            SubFolders: [
                                {
                                    Name: "Tilgængelighed for personer med nedsat mobilitet",
                                    Description: "Dokumentation for tilgængelighed for personer med nedsat mobilitet, som fx ramper, elevatorer og brede døre.",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                },
                            ]
                        },
                        {
                            Name: "Vand og afløb",
                            Description: "",
                            Permission: PermissionTarget.Member,
                            SubFolders: [
                                {
                                    Name: "Vandforsyning og afløbssystemer",
                                    Description: "Dokumentation af installationer til vandforsyning og afløbssystemer",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                },
                                {
                                    Name: "Foranstaltninger til at undgå fugtskader og sikre korrekt vandafledning",
                                    Description: "",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                }
                            ]
                        },
                        {
                            Name: "Installationer",
                            Description: "",
                            Permission: PermissionTarget.Member,
                            SubFolders: [
                                {
                                    Name: "Dokumentation af tekniske installationer som el, varme, køling og VVS-systemer",
                                    Description: "",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                },
                                {
                                    Name: "Certifikater og godkendelser for installerede systemer",
                                    Description: "",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                }
                            ]
                        },
                        {
                            Name: "Miljø og bæredygtighed",
                            Description: "",
                            Permission: PermissionTarget.Member,
                            SubFolders: [
                                {
                                    Name: "Bæredygtige tiltag",
                                    Description: "Dokumentation af bæredygtige tiltag, herunder brug af miljøvenlige materialer og teknologier",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                },
                                {
                                    Name: "Overholdelse af krav om affaldshåndtering og ressourceudnyttelse",
                                    Description: "",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                }
                            ]
                        },
                        {
                            Name: "Drifts- og vedligeholdelsesplan",
                            Description: "",
                            Permission: PermissionTarget.Member,
                            SubFolders: [
                                {
                                    Name: "Plan for vedligeholdelse af bygningen",
                                    Description: "En plan for vedligeholdelse af bygningen og dens installationer.",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                },
                                {
                                    Name: "Vejledning til brugere og ejere om driften af bygningen for at sikre energieffektivitet og lang levetid",
                                    Description: "",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                }
                            ]
                        },
                        {
                            Name: "Kontrolplaner og kvalitetsstyring",
                            Description: "",
                            Permission: PermissionTarget.Member,
                            SubFolders: [
                                {
                                    Name: "Planer for intern og ekstern kontrol under byggeriets udførelse",
                                    Description: "",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                },
                                {
                                    Name: "Dokumentation for de kontrolprocedurer, der er gennemført, og eventuelle tilsynsrapporter",
                                    Description: ".",
                                    Permission: PermissionTarget.Member,
                                    SubFolders: []
                                }
                            ]
                        }
                    ]
                }]
        };

        const result = await DocumentService.createFolders(folders);

        dispatch({
            type: DocumentActions.CREATE_BASIC_FOLDERS_SUCCEDED,
            payload: { result, SourceType: sourceType },
        });
        return result;
    } catch (error) {
        dispatch(operationFailedAction(error));
    }
};

const deleteFolder =
  (data: RemoveFolderRequest, sourceType: DocumentSourceType) => async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({
        type: DocumentActions.DELETE_FOLDER,
        payload: { FolderId: data.FolderId, SourceType: sourceType },
      });
      const folderIds = await DocumentService.deleteFolder(data);

      dispatch({
        type: DocumentActions.DELETE_FOLDER_SUCCEDED,
        payload: { FolderIds: folderIds, SourceType: sourceType },
      });
    } catch (error) {
      dispatch({
        type: DocumentActions.DOCUMENT_OPERATION_FAILED,
        payload: { document: { folderId: data.FolderId }, error, SourceType: sourceType },
      });
      throw error;
    }
  };

const reorderFolders = (data: ReorderFoldersRequest) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: DocumentActions.REORDER_FOLDERS,
      payload: data,
    });
    const result = await DocumentService.reorderFolders(data);

    dispatch({
      type: DocumentActions.REORDER_FOLDERS_SUCCEDED,
      payload: { result, SourceType: data.SourceType },
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
    // dispatch({
    //   type: DocumentActions.DOCUMENT_OPERATION_FAILED,
    //   payload: { document: { id: data.DocumentId, folderId: data.FolderId }, error, SourceType: data.SourceType },
    // });
    throw error;
  }
};

const createDocument = (data: CreateDocumentRequest) => async (dispatch: AppThunkDispatch) => {
  const tempId = uuid();
  try {
    dispatch({
      type: DocumentActions.CREATE_DOCUMENT,
      payload: {
        ...data,
        Id: tempId,
        isDirty: true,
        Submitted: new Date(),
        RelatedSourceIds: data.ChildSourceItemId ? [data.ChildSourceItemId] : [],
      },
    });
    const result = await DocumentService.createDocument(data);

    dispatch({
      type: DocumentActions.CREATE_DOCUMENT_SUCCEDED,
      payload: { folderId: data.FolderId, document: result, tempId: tempId, SourceType: data.SourceType },
    });
  } catch (error) {
    dispatch({
      type: DocumentActions.CREATE_DOCUMENT_ERROR,
      payload: { FolderId: data.FolderId, DocumentId: tempId, SourceType: data.SourceType },
    });
    dispatch(operationFailedAction(error));
    throw error;
  }
};

const deleteDocument =
  (data: RemoveDocumentRequest, sourceType: DocumentSourceType) => async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({ type: DocumentActions.DELETE_DOCUMENT });
      await DocumentService.deleteDocument(data);

      dispatch({
        type: DocumentActions.DELETE_DOCUMENT_SUCCEDED,
        payload: { folderId: data.FolderId, documentId: data.DocumentId, SourceType: sourceType },
      });
    } catch (error) {
      dispatch({
        type: DocumentActions.DOCUMENT_OPERATION_FAILED,
        payload: {
          document: { id: data.DocumentId, folderId: data.FolderId, SourceType: sourceType },
          error,
        },
      });
      throw error;
    }
  };

const updateDocument =
  (data: UpdateDocumentRequest) => async (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
    const folders = getState().document.documentFolders[data.SourceType];
    const doc = folders.flatMap((x) => x.Documents)?.find((x) => x.Id === data.DocumentId);
    const documentData = {
      SourceItemId: data.SourceItemId,
      FolderId: data.FolderId,
      DocumentId: data.DocumentId,
    };
    if (!doc) return;

    const folderId = folders.find((x) => x.Documents.some((d) => d.Id === data.DocumentId))?.Id;
    try {
      dispatch({
        type: DocumentActions.UPDATE_DOCUMENT,
        payload: { ...doc, ...documentData, isDirty: true, SourceType: data.SourceType },
      });
      const result = await DocumentService.updateDocument(data);

      dispatch({
        type: DocumentActions.UPDATE_DOCUMENT_SUCCEDED,
        payload: { folderId: data.FolderId, document: result, SourceType: data.SourceType },
      });
    } catch (error) {
      dispatch({
        type: DocumentActions.DOCUMENT_OPERATION_FAILED,
        payload: { document: { id: doc.Id, folderId }, error, SourceType: data.SourceType },
      });
      throw error;
    }
  };

const reorderDocuments = (data: ReorderDocumentsRequest) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: DocumentActions.REORDER_DOCUMENTS,
      payload: data,
    });
    const result = await DocumentService.reorderDocuments(data);

    dispatch({
      type: DocumentActions.REORDER_DOCUMENTS_SUCCEDED,
      payload: { ...result, SourceType: data.SourceType },
    });
  } catch (error) {
    dispatch({
      type: DocumentActions.DOCUMENT_OPERATION_FAILED,
      payload: { document: { id: data.DocumentId, folderId: data.FolderId }, error, SourceType: data.SourceType },
    });
    throw error;
  }
};

const uploadFile = (file: File, metadata?: Dictionary<string>) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: DocumentActions.UPLOAD_FILE });
    const result = await DocumentService.uploadFile(file, metadata);

    dispatch({
      type: DocumentActions.UPLOAD_FILE_SUCCEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const updateFile = (file: File, data: UpdateDocumentRequest) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: DocumentActions.UPDATE_FILE, payload: data.DocumentId });
    const uploadedFile = await DocumentService.uploadFile(file);
    data.FileIds.push(uploadedFile.Id);

    const document = await DocumentService.updateDocument(data);

    dispatch({
      type: DocumentActions.UPDATE_FILE_SUCCEDED,
      payload: data.DocumentId,
    });
    dispatch({
      type: DocumentActions.UPDATE_DOCUMENT_SUCCEDED,
      payload: { folderId: data.FolderId, document: document, SourceType: data.SourceType },
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const updateFolder =
  (data: UpdateDocumentFolderRequest) => async (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
    try {
      const folder = getState().document.documentFolders[data.SourceType].find((x) => x.Id === data.FolderId);

      dispatch({
        type: DocumentActions.UPDATE_DOCUMENT_FOLDER,
        payload: { ...folder, ...data, isDirty: true },
      });
      const updatedDocumentFolder = await DocumentService.updateDocumentFolder(data);
      dispatch({
        type: DocumentActions.UPDATE_DOCUMENT_FOLDER_SUCCEEDED,
        payload: { updatedDocumentFolder, SourceType: data.SourceType },
      });
    } catch (error) {
      dispatch({
        type: DocumentActions.DOCUMENT_OPERATION_FAILED,
        payload: { document: { folderId: data.FolderId }, error, SourceType: data.SourceType },
      });
      throw error;
    }
  };

const deleteDocuments =
  (sourceItemId: string, documentIds: string[], removeAll = false) =>
  async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({ type: DocumentActions.DELETE_DOCUMENTS });
      await DocumentService.deleteDocuments(sourceItemId, documentIds, removeAll);
      dispatch({ type: DocumentActions.DELETE_DOCUMENTS_SUCCEEDED });
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const deleteDocumentsFromUnits =
  (unitIds: string[], documentIds: string[], removeAll = false) =>
  async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({ type: DocumentActions.DELETE_DOCUMENTS });
      await DocumentService.deleteDocumentsFromUnits(unitIds, documentIds, removeAll);

      dispatch({ type: DocumentActions.DELETE_DOCUMENTS_SUCCEEDED });
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const getUserFolders = (userId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: DocumentActions.GET_USER_FOLDERS });
    const result = await DocumentService.getUserFolders(userId);
    dispatch({
      type: DocumentActions.GET_USER_FOLDERS_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

const deleteFile = (fileId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: DocumentActions.DELETE_FILE, payload: fileId });
    await DocumentService.deleteFile(fileId);

    dispatch({
      type: DocumentActions.DELETE_FILE_SUCCEDED,
      payload: fileId,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const useDocumentState = () => useSelector((state: ApplicationState) => state.document);

const useDocumentActions = () => {
  const dispatch = useAppDispatch();

  return {
    getUserFolders: (userId: string) => dispatch(getUserFolders(userId)),
    createFolder: (data: CreateFolderRequest) => dispatch(createFolder(data)),
    createBasicFolders: (data: CreateBasicFoldersRequest) => dispatch(createBasicFolders(data)),
    createBR18Folder: (SourceItemId: string, sourceType: DocumentSourceType) => dispatch(createBR18Folder(SourceItemId, sourceType)),
    updateFolder: (data: UpdateDocumentFolderRequest) => dispatch(updateFolder(data)),
    deleteFolder: (data: RemoveFolderRequest, sourceType: DocumentSourceType) =>
      dispatch(deleteFolder(data, sourceType)),
    reorderFolders: (data: ReorderFoldersRequest) => dispatch(reorderFolders(data)),
    createDocument: (data: CreateDocumentRequest) => dispatch(createDocument(data)),
    deleteDocument: (data: RemoveDocumentRequest, sourceType: DocumentSourceType) =>
      dispatch(deleteDocument(data, sourceType)),
    deleteDocuments: (sourceItemId: string, documentIds: string[], removeAll = false) =>
      dispatch(deleteDocuments(sourceItemId, documentIds, removeAll)),
    deleteDocumentsFromUnits: (unitIds: string[], documentIds: string[], removeAll = false) =>
      dispatch(deleteDocumentsFromUnits(unitIds, documentIds, removeAll)),
    updateDocument: (data: UpdateDocumentRequest) => dispatch(updateDocument(data)),
    reorderDocuments: (data: ReorderDocumentsRequest) => dispatch(reorderDocuments(data)),
    uploadFile: (file: File, metadata?: Dictionary<string>) => dispatch(uploadFile(file, metadata)),
    updateFile: (file: File, data: UpdateDocumentRequest) => dispatch(updateFile(file, data)),
    deleteFile: (fileId: string) => dispatch(deleteFile(fileId)),
    getFolders: (sourceItemId: string, sourceType: DocumentSourceType, childSourceItemId?: string) =>
      dispatch(getFolders(sourceItemId, sourceType, childSourceItemId)),
  };
};

export const useDocument = (): [ReturnType<typeof useDocumentState>, ReturnType<typeof useDocumentActions>] => {
  const state = useDocumentState();
  const actions = useDocumentActions();
  return [state, actions];
};
