import {
  CreateNavigationItemContract,
  NavigationItem,
  ReorderNavigationItemsContract,
  UpdateNavigationItemContract,
} from "../../../../definitions/Menu";
import ApiService from "../../../../services/api.service";
import { headers } from "../../../../services/http.service";

const AdminNavigationService = {
  addNavigationItem: async (
    item: CreateNavigationItemContract
  ): Promise<NavigationItem> => {
    const url = `/api/admin/navigation-items`;
    const result = await ApiService.put<NavigationItem>(url, item);

    return result;
  },
  getNavigationItems: async (): Promise<NavigationItem[]> => {
    const url = `/api/admin/navigation-items`;
    const result = await ApiService.get<NavigationItem[]>(url);

    return result;
  },
  deleteNavigationItem: async (itemId: string): Promise<string> => {
    const url = `/api/admin/navigation-items/${itemId}`;
    const result = await ApiService.delete<string>(url);

    return result;
  },
  reorderNavigationItems: async (
    items: NavigationItem[]
  ): Promise<NavigationItem[]> => {
    const url = `/api/admin/navigation-items/reorder`;
    const data = <ReorderNavigationItemsContract>{ NavItems: items };
    const result = await ApiService.post<NavigationItem[]>(url, data);

    return result;
  },
  setNavigationItemPublish: async (
    itemId: string,
    state: boolean
  ): Promise<NavigationItem> => {
    const url = `/api/admin/navigation-items/${itemId}/publish/${state}`;
    const result = await ApiService.post<NavigationItem>(url, null);

    return result;
  },
  updateNavigationItem: async (
    item: UpdateNavigationItemContract
  ): Promise<NavigationItem> => {
    const url = `/api/admin/navigation-items/${item.Id}`;
    const result = await ApiService.post<NavigationItem>(url, item);

    return result;
  },
  updateBackgroundImage: async (picture: File): Promise<string> => {
    const formData = new FormData();
    const blob = new Blob([picture], { type: picture.type });
    formData.append("File", blob);

    const headersNoContentType = await headers();
    delete headersNoContentType["Content-Type"];

    const url = `/api/admin/navigation-items/uploadbackground`;
    const response = await fetch(url, {
      method: "POST",
      body: formData,
      headers: headersNoContentType,
    });
    const result = await response.text();
    if (response.status === 200) {
      return result;
    } else {
      throw result;
    }
  },
};

export default AdminNavigationService;
