import { Dispatch } from "redux";
import { AppAction } from "../../definitions/Action";

import { AdminActionTypes } from "../../constants/admin/admin.actiontypes";
import { operationFailedActionGeneral, useAppDispatch } from "..";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../reducers/store";
import { CompanyTestimonialModel } from "../../definitions/Company";
import { AdminCompanyTestimonialActionTypes } from "../../constants/admin/admin.company-testimonial.actiontypes";
import CompanyTestimonialService from "../../services/company-testimonial.service";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, AdminActionTypes.ADMIN_OPERATION_FAILED);
};

export const getCompanyTestimonials =
  (companies: string[], skip: number, take: number) => async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({
        type: AdminCompanyTestimonialActionTypes.GET_COMPANY_TESTIMONIALS,
      });
      const result = await CompanyTestimonialService.getCompanyTestimonials(companies, skip, take);
      dispatch({
        type: AdminCompanyTestimonialActionTypes.GET_COMPANY_TESTIMONIALS_SUCCEEDED,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const addCompanyTestimonial =
  (testimonial: CompanyTestimonialModel) => async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({
        type: AdminCompanyTestimonialActionTypes.ADD_COMPANY_TESTIMONIAL,
      });
      const result = await CompanyTestimonialService.addCompanyTestimonial(testimonial);
      dispatch({
        type: AdminCompanyTestimonialActionTypes.ADD_COMPANY_TESTIMONIAL_SUCCEEDED,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const editCompanyTestimonial =
  (testimonial: CompanyTestimonialModel) => async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({
        type: AdminCompanyTestimonialActionTypes.EDIT_COMPANY_TESTIMONIAL,
      });
      const result = await CompanyTestimonialService.editCompanyTestimonial(testimonial);
      dispatch({
        type: AdminCompanyTestimonialActionTypes.EDIT_COMPANY_TESTIMONIAL_SUCCEEDED,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const deleteCompanyTestimonial =
  (companyId: string, testimonialId: string) => async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({
        type: AdminCompanyTestimonialActionTypes.DELETE_COMPANY_TESTIMONIAL,
      });
      await CompanyTestimonialService.deleteCompanyTestimonial(companyId, testimonialId);
      dispatch({
        type: AdminCompanyTestimonialActionTypes.DELETE_COMPANY_TESTIMONIAL_SUCCEEDED,
        payload: testimonialId,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const useCompanyTestimonials = () => {
  const dispatch = useAppDispatch();
  const testimonials = useSelector((state: ApplicationState) => state.admin.companyTestimonials);

  const actions = {
    getCompanyTestimonials: (companies: string[], skip: number, take: number) =>
      dispatch(getCompanyTestimonials(companies, skip, take)),
    addCompanyTestimonial: (testimonial: CompanyTestimonialModel) => dispatch(addCompanyTestimonial(testimonial)),
    editCompanyTestimonial: (testimonial: CompanyTestimonialModel) => dispatch(editCompanyTestimonial(testimonial)),
    deleteCompanyTestimonial: (companyId: string, testimonialId: string) => dispatch(deleteCompanyTestimonial(companyId, testimonialId)),
  };

  return { testimonials, actions };
};
