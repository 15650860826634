import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { usePrevious } from "../helpers/hooks";
import ReactGA from "react-ga4";

interface Location<T> {
  hash: string;
  key?: string;
  pathname: string;
  search: string;
  state: T;
}

type PreviousLocationState = {
  previousLocation?: Location<unknown>;
  startLocation: Location<unknown>;
};

const PreviousRouteContext = React.createContext<
  Partial<PreviousLocationState>
>({});

export const usePreviousRoute = () => React.useContext(PreviousRouteContext);

export const PreviousRouteProvider= ({
  children 
} : {children: React.ReactNode}) => {
  const location = useLocation();
  const previousLocation = usePrevious(location);
  const [startLocation] = useState(location);

  React.useEffect(() => {
    ReactGA.set({ page: location.pathname });
  }, [location]);

  return (
    <PreviousRouteContext.Provider value={{ previousLocation, startLocation }}>
      {children}
    </PreviousRouteContext.Provider>
  );
};
