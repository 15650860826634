import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import { matchIsValidTel } from "mui-tel-input";
import { DeleteForever, Photo } from "@mui/icons-material";

import { ControllerConnector, TextFieldConnector } from "../../../../../components/Base/FormConnector";
import { PhoneRegexp } from "../../../../../services/validation.service";
import { PhoneField } from "../../../../../components/Base/FormattedFields";
import FileInput from "../../../../../components/Base/FileInput";
import { CompanyAdModel, CompanyModel } from "../../../../../definitions/Company";
import { formatDateToString } from "../../../../House/Map/utils";
import SingleTransferList from "../../../../../components/Base/SingleTransferList";
import { useDocument } from "../../../../../actions/document.actions";

type CompanyAdDataFormProps = {
  id?: string;
  existingAd?: CompanyAdModel;
  companies: CompanyModel[];
  submit: (ad: CompanyAdModel) => void;
  handleClose: () => void;
};

const CompanyAdDataForm = (props: CompanyAdDataFormProps): JSX.Element => {
  const methods = useForm<CompanyAdModel>({
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = methods;

  const [, documentActions] = useDocument();
  const [focusedField, setFocusedField] = useState("Text");
  const { t } = useTranslation("translation");

  useEffect(() => {
    if (props.existingAd) {
      // for some reason all values are undefined on sumbit when reset is used. Use setValue instead
      setValue("CompanyId", props.existingAd.CompanyId);
      setValue("Text", props.existingAd.Text);
      setValue("LogoUrl", props.existingAd.LogoUrl);
      setValue("Phone", props.existingAd?.Phone ?? "");
      setValue("WebSite", props.existingAd.WebSite);
      setValue("ActiveFrom", formatDateToString(props.existingAd.ActiveFrom) as any);
      setValue("ActiveTo", formatDateToString(props.existingAd.ActiveTo) as any);
    }
  }, [props.existingAd]);

  type FormTextBoxProps = {
    fieldName: keyof CompanyAdModel;
    required?: boolean;
    autofocus?: boolean;
    multiline?: boolean;
    label?: string;
    pattern?: RegExp;
    defaultValue?: string;
  };

  const FormTextBox = (props: FormTextBoxProps): JSX.Element => {
    return (
      <TextFieldConnector
        register={register(props.fieldName, {
          required: props.required,
          pattern: props.pattern,
        })}
      >
        <TextField
          variant="outlined"
          autoFocus={props.autofocus || props.fieldName === focusedField}
          margin="dense"
          label={props.label ?? props.fieldName}
          multiline={props.multiline}
          fullWidth
          error={!!errors[props.fieldName]}
          onClick={() => setFocusedField(props.fieldName)}
        />
      </TextFieldConnector>
    );
  };

  const FormPhoneField = (props: FormTextBoxProps): JSX.Element => {
    return (
      <ControllerConnector
        name="Phone"
        rules={{
          pattern: props.pattern,
          validate: (e: any) => {
            if (e) {
              return matchIsValidTel(e);
            }
          },
        }}
      >
        <PhoneField
          variant="outlined"
          autoFocus={props.autofocus || props.fieldName === focusedField}
          margin="dense"
          label={props.label ?? props.fieldName}
          multiline={props.multiline}
          fullWidth
          error={!!errors[props.fieldName]}
          defaultValue={props.defaultValue}
          onClick={() => setFocusedField(props.fieldName)}
        />
      </ControllerConnector>
    );
  };

  const FormLogoBox = (props: FormTextBoxProps): JSX.Element => {
    const [src, setSrc] = useState<string | undefined>(props.defaultValue);

    useEffect(() => {
      setSrc(getValues()[props.fieldName]?.toString() ?? undefined);
    }, [watch()[props.fieldName]]);

    return (
      <Grid container direction="column" item md={6}>
        <ImageList sx={{ width: 500, height: 250 }}>
          <ImageListItem
            key={props.fieldName}
            cols={2}
            rows={1}
            sx={{ "& img.MuiImageListItem-img ": { height: 250, width: "fit-content" } }}
          >
            {src && <img src={src} loading="lazy" />}
            <ImageListItemBar
              title="Logo"
              // subtitle={item.author}
              actionIcon={
                <>
                  <FileInput getPicture={updatePictureHandler}>
                    <IconButton color="secondary" size="large">
                      <Photo></Photo>
                    </IconButton>
                  </FileInput>
                  <IconButton onClick={deletePictureHandler}>
                    <DeleteForever />
                  </IconButton>
                </>
              }
            />
          </ImageListItem>
        </ImageList>
      </Grid>
    );
  };

  const DatePicker = (props: FormTextBoxProps) => (
    <TextFieldConnector
      register={register(props.fieldName, {
        required: props.required,
        pattern: props.pattern,
      })}
    >
      <TextField
        {...props}
        fullWidth
        variant="outlined"
        type="date"
        InputProps={{ notched: true }}
        InputLabelProps={{ disableAnimation: true, shrink: true }}
      />
    </TextFieldConnector>
  );

  const updatePictureHandler = async (image: File) => {
    const uploadResult = await documentActions.uploadFile(image, {
      CompanyAdId: props.existingAd?.Id ?? "",
    });
    if (uploadResult?.AbsoluteUrl) {
      setValue("LogoUrl", uploadResult.AbsoluteUrl);
    }
  };

  const deletePictureHandler = async () => {
    setValue("LogoUrl", null);
    //TODO: delete picture from server
  };

  const submit = (values: CompanyAdModel) => {
    props.submit(values);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)}>
        <DialogContent>
          <SingleTransferList
            disabled={!!props.existingAd?.CompanyId}
            options={props.companies}
            selected={props.companies?.filter((company) => company.Id === getValues("CompanyId"))[0] ?? null}
            fieldLabel="Company"
            placeholder="Company"
            onChanges={(selected: CompanyModel | null) => {
              const newSelectedCompanyId = selected?.Id ?? "";
              const existingSelectedCompanyId = getValues("CompanyId");
              if (newSelectedCompanyId !== existingSelectedCompanyId) {
                setValue("CompanyId", newSelectedCompanyId);
              }
            }}
            getOptionLabel={(o: CompanyModel | null) => o?.Name ?? ""}
            getOptionSelected={(s: CompanyModel, o: CompanyModel) => s.Id === o.Id}
          />
          <FormTextBox fieldName="Text" required={true} multiline={true} />
          <FormLogoBox fieldName="LogoUrl" defaultValue={getValues("LogoUrl") ?? undefined}></FormLogoBox>
          <FormTextBox fieldName="WebSite" />
          <DatePicker fieldName="ActiveFrom" label="Active from" />
          <DatePicker fieldName="ActiveTo" label="Active to" />
          <FormPhoneField fieldName="Phone" pattern={PhoneRegexp} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="button" onClick={props.handleClose} color="secondary">
            {t("General.Buttons.Close")}
          </Button>
          <Button variant="contained" type="submit" color="secondary">
            {t("General.Buttons.Save")}
          </Button>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default CompanyAdDataForm;
