import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardContent, useTheme, styled, IconButton, Button, LinearProgress } from "@mui/material";
import { DocumentSourceType, FolderType } from "../../../definitions/Document";
import { useTranslation } from "react-i18next";
import { HelpOutline } from "@mui/icons-material";
import { useDocument } from "../../../actions/document.actions";
import { PermissionTarget } from "../../../constants/enums";
import Documents from "./components/Documents";
import FoldersComponent from "./components/FoldersComponent";
import DocumentGuideDialog from "./DocumentsGuideDialog";

const StyledCardHeader = styled(CardHeader, {
  shouldForwardProp: (props) => props !== "customColor",
})<{ customColor?: string }>(({ theme, customColor }) => ({
  "&.MuiCardHeader-root": {
    padding: "0 10px 5px",
    background: theme.palette[customColor ? customColor : "primary"].main,
  },
  "& .MuiCardHeader-subheader": {
    color: theme.palette[customColor ? customColor : "primary"].contrastText,
    paddingBottom: 5,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export type Documents = { uri: string }[] | undefined;
export type DocumentCildSourceItem = {
  id: string;
  name: string;
};

type FoldersType = {
  sourceItemId: string;
  sourceType: DocumentSourceType;
  childSourceItemId?: string;
  usePermissions?: boolean;
  readonly?: boolean;
  hideHeader?: boolean;
  childSourceItems?: DocumentCildSourceItem[];
  color?: "primary" | "secondary" | "error" | "info" | "success" | "warning" | "inherit" | undefined;
  addBasicFolders?: boolean;
  folders?: FolderType[];
};

const Folders = (props: FoldersType) => {
  const theme = useTheme();
  const [{ documentFolders, loading }, actions] = useDocument();
  const folders = props.folders ?? documentFolders[props.sourceType];
  const { t } = useTranslation("translation");

  const [guideShown, setGuideShown] = useState(false);
  const permissionsList = [
    PermissionTarget.Owner,
    PermissionTarget.Group,
    PermissionTarget.Member,
    PermissionTarget.Public,
  ];

  useEffect(() => {
    if (!props.folders && props.sourceItemId) {
      actions.getFolders(props.sourceItemId, props.sourceType, props.childSourceItemId);
    }
  }, [props.sourceItemId]);

  return (
    <>
      {(!props.readonly || (props.readonly && folders.length > 0)) && (
        <Card
          square
          sx={{
            border: "solid 1px #e4e4e4",
            marginBottom: "20px",
          }}
        >
          {!props.hideHeader && (
            <StyledCardHeader
              customColor={props.color}
              subheader={
                <>
                  {props.readonly
                    ? t("Documents.MyHousingDocuments", {
                        destination:
                          props.sourceType === DocumentSourceType.Unit
                            ? t("Documents.DocumentsDestination.House")
                            : t("Documents.DocumentsDestination.Building"),
                      })
                    : t("Documents.YouCanCreateFolders", {
                        destination:
                          props.sourceType === DocumentSourceType.Unit
                            ? t("Documents.DocumentsDestination.House")
                            : t("Documents.DocumentsDestination.Building"),
                      })}
                  <IconButton onClick={() => setGuideShown(true)}>
                    <HelpOutline sx={{ color: theme.palette.primary.contrastText }} />
                  </IconButton>
                </>
              }
              action={
                  <div>
                      {
                          props.addBasicFolders &&
                          !props.readonly &&
                          folders.length === 0 && (

                              <Button
                                  color="secondary"
                                  size="small"
                                  variant="contained"
                                  sx={{ marginTop: 1 }}
                                  disabled={loading}
                                  onClick={() =>
                                      actions.createBasicFolders({
                                          SourceItemId: props.sourceItemId,
                                          SourceType: props.sourceType,
                                          Permission: PermissionTarget.Member,
                                      })
                                  }
                              >
                                  {t("Documents.AddBasicFolders")}
                              </Button>
                          )
                      }
                      {
                          !props.readonly &&
                          (

                              <Button
                                  color="secondary"
                                  size="small"
                                  variant="contained"
                                  sx={{ marginTop: 1, marginLeft: 1 }}
                                  disabled={loading}
                                  onClick={() =>
                                      actions.createBR18Folder(
                                          props.sourceItemId,
                                          props.sourceType,
                                      )
                                  }
                              >
                                  {t("Documents.AddBR18Folders")}
                              </Button>
                          )
                      }
                  </div>
              }
            />
          )}
          <CardContent
            sx={{
              padding: 0,
              "&:last-child": {
                paddingBottom: 0,
              },
            }}
          >
            {loading && <LinearProgress color="secondary" sx={{ height: 5 }} />}
            <FoldersComponent
              folders={folders}
              sourceItemId={props.sourceItemId}
              sourceType={props.sourceType}
              childSourceItemId={props.childSourceItemId}
              childSourceItems={props.childSourceItems}
              readonly={props.readonly ?? false}
              usePermissions={props.usePermissions}
              permissionsList={permissionsList}
              color={props.color}
            />
          </CardContent>
        </Card>
      )}
      <DocumentGuideDialog open={guideShown} handleClose={() => setGuideShown(false)} />
    </>
  );
};

export default Folders;
