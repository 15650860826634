import React, { useEffect, useState } from "react";
import {
  Box,
  LinearProgress,
  Grid,
  ThemeProvider,
  CssBaseline,
  styled,
} from "@mui/material";
import { FormBuilderDTO, LandingType } from "../../definitions/Landing";
import { GrapesEditor, GrapesJs, GrapesTypes } from "./defenitions";
import loadReactComponentsPlugin from "./ReactComponentsPlugin";
import loadCKEditorPlugin from "./CKEditorPlugin";
import { theme, communeTheme } from "../../constants/theme";
import { useUserTheme } from "../../helpers/hooks";

declare let createLandingEditor: (
  containerId: string,
  pluginsList: any[],
  additionalOpts: any,
  fonts: FontType[]
) => GrapesEditor;
declare let createEmailEditor: (
  containerId: string,
  pluginsList: any[],
  additionalOpts: any
) => GrapesEditor;
declare let createSMSEditor: (
  containerId: string,
  pluginsList: any[],
  additionalOpts: any
) => GrapesEditor;
declare const grapesjs: GrapesJs;
declare const CKEDITOR: any;

const RootGrid = styled(Grid, {
  shouldForwardProp: (propName: string) => propName !== "type",
})<{ type: LandingType }>(({ theme, type }) => ({
  "& .grape-container": {
    float: "none",
    margin: "0 auto",
    border: `solid ${theme.palette.primary.dark} 1px`,
  },
}));

type GrapeJsEditorProps = {
  id: string;
  builder: FormBuilderDTO;
  type: LandingType;
  externalId?: string;
  themeToUse?: string;
};

type FontType = {
  value: string;
  name: string;
};

let scripts: HTMLScriptElement[] = [];
let links: HTMLLinkElement[] = [];

const loadScipts = ([firstUrl, ...urls]: string[]) =>
  new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = firstUrl;
    script.onload = () =>
      urls.length > 0 ? loadScipts(urls).then(resolve) : resolve(null);
    document.body.appendChild(script);
    scripts.push(script);
  });
const loadStyles = ([firstUrl, ...urls]: string[]) =>
  new Promise((resolve) => {
    const link = document.createElement("link");
    link.href = firstUrl;
    link.rel = "stylesheet";
    link.onload = () =>
      urls.length > 0 ? loadStyles(urls).then(resolve) : resolve(null);
    document.head.appendChild(link);
    links.push(link);
  });

const loadGrapeScripts = (builder: FormBuilderDTO) => {
  const notloadedScripts = builder.JsScriptUrls.filter(
    (url) => !document.querySelector(`script[src="${url}]"`)
  );
  const notloadedStyles = builder.CssStyleUrls.filter(
    (url) => !document.querySelector(`link[href="${url}]"`)
  );

  return loadStyles(notloadedStyles)
    .then(() => loadScipts(notloadedScripts))
    .then(() => {
      if (!document.querySelector("script[data-grapeJs]")) {
        const script = document.createElement("script");
        script.setAttribute("data-grapeJs", "true");
        script.innerHTML = builder.JsInitBody;
        document.body.appendChild(script);
        scripts.push(script);
      }
      return Promise.resolve();
    });
};

const fonts = [{ value: "Quicksand, sans-serif", name: "Quicksand" }];

const GrapesJsEditor = ({
  builder,
  type,
  themeToUse,
}: GrapeJsEditorProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [editor, setEditor] = useState<GrapesEditor | null>(null);
  const userTheme = useUserTheme();

  useEffect(() => {
    if (editor) {
      editor.on("run:grapesjs-load-data-complete", () => {
        document.body.style.backgroundColor =
          userTheme.palette.background.default;
        setIsLoading(false);
      });
    }

    return () => {
      document.body.style.backgroundColor = "";
    };
  }, [editor]);

  useEffect(() => {
    loadGrapeScripts(builder).then(() => {
      loadCKEditorPlugin(CKEDITOR);
      loadReactComponentsPlugin(grapesjs, type, themeToUse);

      switch (type) {
        case LandingType.SMS:
          setEditor(
            createSMSEditor("#gjs-sms", [GrapesTypes.ReactComponentsPlugin], {})
          );
          break;
        case LandingType.Email:
          setEditor(
            createEmailEditor(
              "#gjs-email",
              [GrapesTypes.ReactComponentsPlugin],
              {}
            )
          );
          break;
        default:
          setEditor(
            createLandingEditor(
              "#gjs",
              [GrapesTypes.ReactComponentsPlugin],
              {},
              fonts
            )
          );
          break;
      }
    });
    return () => {
      if (editor) editor.destroy();
      scripts.forEach((script: HTMLScriptElement) =>
        document.body.removeChild(script)
      );
      links.forEach((link: HTMLLinkElement) => document.head.removeChild(link));
      scripts = [];
      links = [];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <RootGrid
        container
        justifyContent="center"
        alignContent="center"
        type={type}
      >
        {isLoading && (
          <LinearProgress sx={{ width: "100%" }} color="secondary" />
        )}
        <ThemeProvider theme={themeToUse === "communeTheme" ? communeTheme : theme}>
          <CssBaseline />
          <Grid
            container
            dangerouslySetInnerHTML={{ __html: builder.HtmlBody }}
          ></Grid>
        </ThemeProvider>
      </RootGrid>
    </Box>
  );
};
export default GrapesJsEditor;
