import React, { useContext } from "react";
import {
  MenuItem,
  Select,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CO2Context } from "../CO2ContextWrapper";
import { useTranslation } from "react-i18next";

type Option = {
  Id: string;
  Code: number;
  Name: string;
};

type CO2TargetOptionsSourceProps = {
  options: Option[];
  selectedOption: string;
  personId: string | null;
  sourceId: string;
  disabled: boolean;
};

export const StyledMenuItem = styled(MenuItem)(() => ({
  paddingLeft: 10,
  paddingRight: 10,
  textTransform: "lowercase",
}));

export const StyledSelect = styled(Select)({
  textTransform: "lowercase",
  "& .MuiSelect-select.MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#000",
    borderBottom: "unset",
    paddingRight: 0
  },
  "& .MuiSelect-icon.Mui-disabled": {
    opacity: 0,
  },
});

const CO2TargetOptionsSourceComponent = (
  props: CO2TargetOptionsSourceProps
): JSX.Element => {
  const context = useContext(CO2Context);
  const { t } = useTranslation("translation");
  const handleSelectionChange = (optionId: string) => {
    context.addOptionSource(props.sourceId, props.personId, optionId);
  };

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledSelect
     sx={{pl: 0.5 }}
      disabled={props.disabled}
      fullWidth={isXs}
      value={props.selectedOption}
      onChange={(e) => handleSelectionChange(e.target.value as string)}
    >
      {props.options.map((option) => (
        <StyledMenuItem value={option.Id} key={option.Id}>
           {t(option.Name)}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
};

export default CO2TargetOptionsSourceComponent;
