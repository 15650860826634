import { useDispatch, useSelector } from "react-redux";
import { AdminActionTypes } from "../../constants/admin/admin.actiontypes";
import { AppThunkDispatch } from "../../definitions/Action";
import AdminService from "../../services/admin/admin.service";
import { ApplicationState } from "../../reducers/store";
import { operationFailedActionGeneral } from "..";

const operationFailedAction = (payload: unknown) => {
  return operationFailedActionGeneral(payload, AdminActionTypes.ADMIN_OPERATION_FAILED);
};

const getUsers =
  (filter: string, companyId: string | null, skip: number, take: number) => async (dispatch: AppThunkDispatch) => {
    dispatch({
      type: AdminActionTypes.ADMIN_GET_ALL_USERS,
    });
    try {
      const result = await AdminService.getAllUsers(filter, companyId, skip, take);
      dispatch({
        type: AdminActionTypes.ADMIN_GET_ALL_USERS_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error: any) {
      dispatch(operationFailedAction(error));
    }
  };

const getRemovedUsersLog = (filter: string, skip: number, take: number) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: AdminActionTypes.GET_REMOVED_USERS_LOG,
    });
    const result = await AdminService.getRemovedUsersLog(filter, skip, take);
    dispatch({
      type: AdminActionTypes.GET_REMOVED_USERS_LOG_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const removeUser = (id: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: AdminActionTypes.ADMIN_REMOVE_USER,
    });
    const result = await AdminService.removeUser(id);
    dispatch({
      type: AdminActionTypes.ADMIN_REMOVE_USER_SUCCEEDED,
      payload: { Id: id },
    });
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const changeUserRating = (userId: string, rating: number) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: AdminActionTypes.ADMIN_SET_USER_RATING,
    });
    const result = await AdminService.changeUserRating(userId, rating);
    dispatch({
      type: AdminActionTypes.ADMIN_SET_USER_RATING_SUCCEEDED,
      payload: { Id: userId, Rating: rating },
    });

    return result;
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const joinUserToCompany = (userId: string, companyId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: AdminActionTypes.ADMIN_JOIN_USER_TO_COMPANY_RATING,
    });
    const result = await AdminService.joinUserToCompany(userId, companyId);
    dispatch({
      type: AdminActionTypes.ADMIN_JOIN_USER_TO_COMPANY_SUCCEEDED,
      payload: result,
    });

    return result;
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const removeUserFromCompany = (userId: string, companyId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: AdminActionTypes.ADMIN_REMOVE_USER_FROM_COMPANY,
    });
    const result = await AdminService.removeUserFromCompany(userId, companyId);
    dispatch({
      type: AdminActionTypes.ADMIN_REMOVE_USER_FROM_COMPANY_SUCCEEDED,
      payload: result,
    });

    return result;
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const useAdminUserState = () =>
  useSelector((state: ApplicationState) => ({
    users: state.admin.allUsers,
    log: state.admin.removedUsersLog,
    loading: state.admin.loading,
  }));

const useAdminUserActions = () => {
  const dispatch: AppThunkDispatch = useDispatch();
  return {
    getUsers: (filter: string, companyId: string | null, skip: number, take: number) =>
      dispatch(getUsers(filter, companyId, skip, take)),
    getRemovedUsersLog: (filter: string, skip: number, take: number) =>
      dispatch(getRemovedUsersLog(filter, skip, take)),
    removeUser: (id: string) => dispatch(removeUser(id)),
    changeUserRating: (userId: string, rating: number) => dispatch(changeUserRating(userId, rating)),
    joinUserToCompany: (userId: string, companyId: string) => dispatch(joinUserToCompany(userId, companyId)),
    removeUserFromCompany: (userId: string, companyId: string) => dispatch(removeUserFromCompany(userId, companyId)),
  };
};

export const useAdminUser = (): [ReturnType<typeof useAdminUserState>, ReturnType<typeof useAdminUserActions>] => {
  const state = useAdminUserState();
  const actions = useAdminUserActions();
  return [state, actions];
};
