import React, { useEffect } from "react";
import { Card, styled, useTheme } from "@mui/material";
import { WidgetModel } from "../../../definitions/Landing";
import GrapesJsxRenderer from "../../../components/Grapes/GrapesJsxRenderer";
import { UserType } from "../../../constants/enums";
import { useAlertContext } from "../../../components/Base/MyhouseAlert";
import { useUser } from "../../../actions/user.actions";

const WidgetCard = styled(Card)(({ theme }) => ({
  "& .mh-product": {
    paddingBottom: 20,
    textAlign: "center",
    "&__title": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      fontWeight: "bold",
      padding: "20px 0",
      marginRight: 8,
    },
    "&__image": {
      height: "auto",
      width: "100%",
    },
    "&__price": {
      display: "block",
      margin: "10px 0",
    },
    "&__link": {
      padding: "8px 0",
      display: "block",
      margin: "auto",
      textDecoration: "none",
      width: "90%",
      borderRadius: 25,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

const InStreamWidgetCard = styled(Card)(({ theme }) => ({
  "& .mh-product": {
    paddingBottom: 20,
    textAlign: "center",
    "&__title": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      fontWeight: "bold",
      padding: "20px 0",
      marginRight: 8,
    },
    "&__image": {
      height: "auto",
      width: "100%",
    },
    "&__price": {
      display: "block",
      margin: "10px 0",
    },
    "&__link": {
      padding: "8px 0",
      display: "block",
      margin: "auto",
      textDecoration: "none",
      width: "90%",
      borderRadius: 25,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

type WidgetProps = {
  model: WidgetModel;
  inStream?: true;
  isBorder?: boolean;
  isTransparentBackground?: boolean;
};

const Widget = ({
  model,
  inStream,
  isBorder = true,
  isTransparentBackground = false,
}: WidgetProps): JSX.Element => {
  const { showAlert } = useAlertContext();
  const [{user}] = useUser();
  const theme = useTheme();

  useEffect(() => {
    const style = document.createElement("style");
    style.setAttribute("data-meta", "widget-" + model.Id);
    style.innerHTML = model.Css;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, [model]);

  const handleError = (e: Error) => {
    if (user?.Role !== UserType.User) {
      showAlert({
        severity: "error",
        text: `${e.name}: ${e.message}`,
      });
    }
  };

  return inStream ? (
    <InStreamWidgetCard
      square
      elevation={0}
      style={{
        border: `${isBorder ? "1px solid" : "none"}`,
        borderColor: `${isBorder ? theme.palette.divider : "none"}`,
        fontSize: 14,
        backgroundColor: isTransparentBackground ? "transparent" : undefined,
      }}
    >
      <GrapesJsxRenderer jsx={model.Html} onError={handleError} />
    </InStreamWidgetCard>
  ) : (
    <WidgetCard
      square
      elevation={0}
      style={{
        border: `${isBorder ? "1px solid" : "none"}`,
        borderColor: `${isBorder ? theme.palette.divider : "none"}`,
        fontSize: 14,
        backgroundColor: isTransparentBackground ? "transparent" : undefined,
      }}
    >
      <GrapesJsxRenderer jsx={model.Html} onError={handleError} />
    </WidgetCard>
  );
};

export default Widget;
