import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { connect, ConnectedProps } from "react-redux";
import {
  DashboardEntryModel,
  DashboardActionRequest,
  SubscriptionChangeActionPayload,
} from "../../../../definitions/model/Dashboard";

import { ApplicationState } from "../../../../reducers/store";
import { AppThunkDispatch } from "../../../../definitions/Action";
import DashboardTable from "./DashboardTable";
import EntryManagement from "./EntryManagement";
import {
  changeDashboardSubscription,
  changeLocalSubscription,
  createEntry,
  updateEntry,
  getDashboardEntries,
  deleteEntry,
} from "../../../../actions/admin/admin.actions";
import { useDefaultReducer } from "../../../../helpers/hooks";
import { DashboardUpdatingFrequency } from "../../../../constants/enums";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import DashboardEntryForm, {
  DashboardEntryFormFields,
} from "./DashboardEntryForm";
import Collapse from "@mui/material/Collapse";

type DashboardState = {
  selectedEntry?: DashboardEntryModel;
  openCreateEntry: boolean;
};

const initialState: DashboardState = {
  selectedEntry: undefined,
  openCreateEntry: false,
};

const DashboardPage = (props: DashboardConnectedProps): JSX.Element => {
  const [state, dispatch] = useDefaultReducer(initialState);

  useEffect(() => {
    props.getDashboardEntries();
  }, []);

  const selectRow = (e: DashboardEntryModel) => {
    if (e.Id === state.selectedEntry?.Id) {
      dispatch({ type: "selectedEntry", payload: undefined });
    } else {
      dispatch({ type: "selectedEntry", payload: e });
    }
  };

  const createEntry = async (data: DashboardEntryFormFields) => {
    const result = await props.createDashboardEntry(
      data.Name,
      data.UpdatingFrequency,
      data.Environment
    );
    dispatch({ type: "openCreateEntry", payload: false });
    return result;
  };

  return (
    <Grid container spacing={4}>
      <Grid item container spacing={2}>
        <Grid item container sm={state.selectedEntry ? 9 : 12}>
          <Grid item xs={12}>
            <Typography variant="h3">Dashboard</Typography>
            <DashboardTable
              entries={props.entries}
              onRowSelect={selectRow}
              selectedEntry={state.selectedEntry}
            />
          </Grid>
        </Grid>
        {state.selectedEntry && (
          <Grid item container sm={3}>
            <Grid item container direction="column">
              <Typography variant="h3">
                {state.selectedEntry?.Name} Management
              </Typography>
              <EntryManagement
                entry={state.selectedEntry}
                onEntryUpdate={props.updateDashboardEntry}
                onEntryRemove={props.removeDashboardEntry}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item container>
        <Grid item xs={12}>
          <Typography variant="h3">Dashboard management</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper square elevation={2}>
            <Box p={2}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      dispatch({
                        type: "openCreateEntry",
                        payload: !state.openCreateEntry,
                      });
                    }}
                  >
                    {state.openCreateEntry ? "Close" : "Create new entry"}
                  </Button>
                </Grid>
              </Grid>
              <Grid item container>
                <Collapse in={state.openCreateEntry} mountOnEnter unmountOnExit>
                  <Box my={2}>
                    <DashboardEntryForm
                      submitText="Create"
                      onSubmit={createEntry}
                    />
                  </Box>
                </Collapse>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  entries: state.admin.entries,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getDashboardEntries: () => dispatch(getDashboardEntries()),
  changeDashboardSubscription: (data: DashboardActionRequest) =>
    dispatch(changeDashboardSubscription(data)),
  changeLocalSubscription: (data: SubscriptionChangeActionPayload) =>
    dispatch(changeLocalSubscription(data)),
  createDashboardEntry: (
    name: string,
    frequency: DashboardUpdatingFrequency,
    environment: string
  ) => dispatch(createEntry(name, frequency, environment)),
  updateDashboardEntry: (
    id: string,
    name: string,
    frequency: DashboardUpdatingFrequency,
    environment: string
  ) => dispatch(updateEntry(id, name, frequency, environment)),
  removeDashboardEntry: (id: string) => dispatch(deleteEntry(id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type DashboardConnectedProps = ConnectedProps<typeof connector>;

export default connector(DashboardPage);
