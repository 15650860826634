import React, { useEffect, useMemo, useState } from "react";
import { Grid, Typography, useMediaQuery, CardContent, useTheme, Card, styled } from "@mui/material";

import { CO2Plan, CO2PlanPerson, MergePersons } from "./CO2Plan";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { Trans } from "react-i18next";
import { CO2PropertyDescription } from "./CO2Plan";
import CO2PlanComponent from "./components/CO2PlanComponent";
import EditPersons from "./components/EditPersons";
import { useAlertContext } from "../../../components/Base";
import { convertType } from "./co2-helpers";
import { useCO2 } from "./co2plan.actions";

type ClimatePlanProps = {
  widgets: ClimatPlanWidgetModel[] | null;
  renderWidget?: (model: ClimatPlanWidgetModel, isBorder?: boolean) => JSX.Element;
  renderTopBar?: () => JSX.Element;
  renderUserNotificationAlert?: () => JSX.Element;
  renderMoveinAutocomplete?: () => JSX.Element;
  sendShortcutHandler?: () => Promise<void>;
  withTopBarOnStartPage?: boolean;
  historyNavigate: (route: string) => void;
  onExitActions: () => void;
  loading: boolean;
  unit: ClimatPlanUnit | null;
  user: ClimatPlanUser | null;
  family?: ClimatPlanFamily | null;
  error: string | null;
  WidgetPage: number;
  showEconomyItems: boolean;
  forceUserToMember?: boolean;
  isMultiSelect?: boolean;
  readonly?: boolean;
  ignorePlanPersons?: boolean;
  showPropertyDescription: boolean;
  ga4Handler?: (hasCo2Plan?: boolean, isSurveyFilled?: boolean, isItFirstSesion?: boolean) => void;
};

export type ClimatPlanUser = {
  Name: string;
  Id: string;
};

export type ClimatPlanUnit = {
  Id: string;
  PlanId: string;
  CommuneNumber: number;
  UnitArea?: number;
};

export type ClimatPlanWidgetModel = {
  Id: string;
  Css: string;
  Html: string;
  Pages: number[];
  Position: number;
  Tag: string;
  Communes: number[];
};

type ClimatPlanGroupMember = {
  UserId: string;
  Name: string;
};

export type ContextProps = {
  widgets: ClimatPlanWidgetModel[] | null;
  renderWidget?: (model: ClimatPlanWidgetModel, isBorder?: boolean) => JSX.Element;
  historyNavigate: (route: string) => void;
  onExitActions: () => void;
  renderTopBar?: () => JSX.Element;
  renderUserNotificationAlert?: () => JSX.Element;
  renderMoveinAutocomplete?: () => JSX.Element;
  sendShortcutHandler?: () => Promise<void>;
  unit: ClimatPlanUnit | null;
  user: ClimatPlanUser | null;
  family?: ClimatPlanFamily | null;
  WidgetPage: number;
  showEconomyItems: boolean;
  forceUserToMember?: boolean;
  isMultiSelect?: boolean;
  ignorePlanPersons?: boolean;
};

export type ClimatPlanFamily = {
  Members: ClimatPlanGroupMember[];
};

const ClimatePlanPage = (props: ClimatePlanProps): JSX.Element => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const [state, actions] = useCO2();
  const { showAlert } = useAlertContext();
  const [isItFirstVisit, setIsItFirstVisit] = useState(true);

  useEffect(() => {
    setIsItFirstVisit(!state.hasCO2Plan);
  }, []);

  const contextData: ContextProps = useMemo(() => {
    return {
      showEconomyItems: props.showEconomyItems,
      WidgetPage: props.WidgetPage,
      historyNavigate: props.historyNavigate,
      onExitActions: props.onExitActions,
      renderWidget: props.renderWidget,
      family: props.family,
      unit: props.unit,
      user: props.user,
      widgets: props.widgets,
      forceUserToMember: props.forceUserToMember,
      isMultiSelect: props.isMultiSelect,
      renderTopBar: props.renderTopBar,
      renderUserNotificationAlert: props.renderUserNotificationAlert,
      renderMoveinAutocomplete: props.renderMoveinAutocomplete,
      sendShortcutHandler: props.sendShortcutHandler,
    };
  }, [props]);

  useEffect(() => {
    if (props.error) {
      showAlert({
        text: props.error,
        severity: "error",
        duration: 3000,
      });
    }
  }, [props.error]);

  useEffect(() => {
    const updateAsync = async () => {
      if (state.co2Plan && state.co2Plan.IsMultiSelect !== convertType(props.isMultiSelect) && !props.readonly) {
        await actions.updatePlanPersons(
          [],
          [],
          [],
          state.co2Plan.IsSinglePersonPlan,
          [],
          [],
          convertType(props.isMultiSelect),
          props.unit?.Id
        );
      }
    };
    updateAsync();
  }, [state.co2Plan]);

  const saveHandler = async (
    personNamesToAdd: CO2PlanPerson[],
    _: any,
    __: any,
    isSinglePersonPlan: boolean,
    planPersonsToIgnore: string[],
    personToMerge?: MergePersons[],
    propertyDescription?: CO2PropertyDescription
  ) => {
    if (props.unit) {
      // user is moved in, register co2 plan for his main unit
      await actions.registerPlan(
        props.unit.Id,
        personNamesToAdd,
        isSinglePersonPlan,
        planPersonsToIgnore,
        convertType(props.isMultiSelect)
      );
    } else {
      // user is not moved in, register temp co2 plan
      if (propertyDescription) {
        await actions.registerTempPlan(propertyDescription, personNamesToAdd, isSinglePersonPlan);
      }
    }
  };

  useEffect(() => {
    const sendAnalyticData = (e: BeforeUnloadEvent) => {
      if (props.ga4Handler) {
        props.ga4Handler(!!state.hasCO2Plan, state.co2Plan?.InitialSurvey.IsFilledIn, isItFirstVisit);
      }
    };

    window.addEventListener("beforeunload", sendAnalyticData);

    if (props.ga4Handler && state.co2Plan?.InitialSurvey.IsFilledIn) {
      props.ga4Handler(!!state.hasCO2Plan, state.co2Plan.InitialSurvey.IsFilledIn, isItFirstVisit);
    }

    return () => {
      window.removeEventListener("beforeunload", sendAnalyticData);
      if (props.ga4Handler) {
        props.ga4Handler(!!state.hasCO2Plan, state.co2Plan?.InitialSurvey.IsFilledIn, isItFirstVisit);
      }
    };
  }, [state.hasCO2Plan, state.co2Plan?.InitialSurvey.IsFilledIn]);

  return (
    <StyledGrid item container alignItems="center" justifyContent="center">
      <LoadingOverlay loading={props.loading || state.loading} />
      {!props.loading && !state.loading && !state.hasCO2Plan && (
        <Grid
          container
          sx={{
            minHeight: props.withTopBarOnStartPage ? "Calc(100vh - 60px)" : "100vh",
          }}
          justifyContent="center"
        >
          {props.withTopBarOnStartPage && (
            <Grid item width="100%" maxHeight="60px">
              {props.renderTopBar && props.renderTopBar()}
            </Grid>
          )}
          <Grid item container xs={12} sm={7} md={6} alignContent="center">
            <Card variant="elevation" elevation={0} sx={{ width: "100%" }} square={isXs}>
              <StyledCardContent>
                <Grid container direction="column" alignItems="center" justifyContent="center">
                  <Grid item>
                    <Typography align="center" variant="h2" paragraph>
                      <Trans
                        components={{
                          span: <span style={{ display: "inline-flex" }} />,
                        }}
                        i18nKey="ClimatePlan.MembersDialogHeaderwithSpan"
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <EditPersons
                  existingPersons={[]}
                  isSinglePersonPlan={false}
                  showPropertyDescription={props.showPropertyDescription}
                  user={props.user}
                  unit={props.unit}
                  family={props.family || null}
                  planPersonsToIgnore={state.co2Plan?.PlanPersonsToIgnore || []}
                  handleSave={saveHandler}
                  ignorePlanPersons={props.ignorePlanPersons}
                ></EditPersons>
              </StyledCardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      {!props.loading && !state.loading && state.hasCO2Plan && state.co2Plan && (
        <CO2PlanComponent
          co2Plan={state.co2Plan as CO2Plan}
          lastStep={state.lastStep}
          contextData={contextData}
          readOnly={props.readonly}
          ignorePlanPersons={props.ignorePlanPersons}
        ></CO2PlanComponent>
      )}
    </StyledGrid>
  );
};

export default React.memo(ClimatePlanPage);

const StyledGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.typography.h1.color,
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    padding: 50,
    "&:last-child": {
      paddingBottom: 50,
    },
  },
}));
