import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";

type AddUtmSourceDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleSave: (value: string) => Promise<void>;
};

const AddUtmSourceDialog = (props: AddUtmSourceDialogProps) => {
  const [value, setValue] = useState("");

  return (
    <Dialog open={props.open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add utm source</DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          placeholder="Type utm source value"
          label="Utm Source"
          fullWidth
          onChange={(e) => setValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => props.handleClose()} color="secondary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => props.handleSave(value).then(props.handleClose)}
          color="secondary"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUtmSourceDialog;
