import React, { useEffect, useMemo, useReducer } from "react";
import { Button, CircularProgress, Grid, styled } from "@mui/material";
import { AdminTable, Column, Icon } from "../../../components/AdminTable/AdminTable";
import { Add } from "@mui/icons-material";
import { useAdmin } from "../../../../../actions/admin/admin.actions";
import { SystemStringModel } from "../../../../../definitions/model/SystemString";
import SystemStringEditor from "./SystemStringEditor";

const ListContainer = styled(Grid)({
  overflowY: "auto",
  width: "100%",
  height: "calc(100vh - 250px)",
  padding: 0,
  marginTop: 20,
});

type State = {
  showEditDialog: boolean;
  systemStrings: SystemStringModel[];
  systemStringToEdit: SystemStringModel | null;
};

const initialState: State = {
  showEditDialog: false,
  systemStrings: [],
  systemStringToEdit: null,
};

const reducer = (state: State, action: { type: string; payload: unknown }) => {
  return { ...state, [action.type]: action.payload };
};

const SystemMetaPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [{ loading }, { getSystemStrings }] = useAdmin();

  useEffect(() => {
    if (!state.systemStrings || state.systemStrings.length === 0) {
      getSystemStrings().then((data) => dispatch({ type: "systemStrings", payload: data }));
    }
  }, [state.systemStrings]);

  const handleCreate = () => dispatch({ type: "showEditDialog", payload: true });

  const columns: Column<SystemStringModel>[] = useMemo(
    () => [
      {
        name: "Type",
        accessor: "TypeString",
      },
      {
        name: "Value",
        accessor: "Value",
      },
      {
        name: "Edit",
        icon: Icon.Edit,
        callback: (data) => {
          dispatch({ type: "systemStringToEdit", payload: data });
          dispatch({ type: "showEditDialog", payload: true });
        },
      },
    ],
    []
  );

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid container item md={6} xs={10}>
        {loading ? (
          <ListContainer container justifyContent="center" alignItems="center">
            <CircularProgress />
          </ListContainer>
        ) : (
          <AdminTable
            data={state.systemStrings}
            columns={columns}
            pagination="client"
            headerButton={
              <Grid container item justifyContent="flex-end">
                <Button color="primary" variant="contained" onClick={handleCreate}>
                  <Add />
                </Button>
              </Grid>
            }
          />
        )}
      </Grid>
      <SystemStringEditor
        open={state.showEditDialog}
        systemString={state.systemStringToEdit}
        handleClose={() => {
          dispatch({ type: "showEditDialog", payload: false });
          dispatch({ type: "systemStringToEdit", payload: null });
        }}
      />
    </Grid>
  );
};

export default SystemMetaPage;
