import React, { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import FamilyMembersList from "../../../pages/Family/components/FamilyMembersList";
import { GroupMemberRole } from "../../../constants/enums";
import {
  GroupMembersDto,
  GroupMemberDto,
} from "../../../definitions/model/Group";
import { MoveOutDialogSx } from "./MoveOutDialog";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

type ChangeFamilyOwnerPartProps = {
  userId: string;
  userFamily?: GroupMembersDto;
  onCloseClick: () => void;
  changeFamilyOwner: (newOwner: GroupMemberDto) => Promise<void> | void;
  onOwnerChanged?: () => Promise<void>;
};

const ChangeFamilyOwnerPart = (
  props: ChangeFamilyOwnerPartProps
): JSX.Element => {
  const [selectedMember, selectMember] = useState<GroupMemberDto>();
  const { t } = useTranslation("translation");
  const changeFamilyOwner = async () => {
    if (selectedMember) {
      await props.changeFamilyOwner(selectedMember);
      if (props.onOwnerChanged) await props.onOwnerChanged();
    }
  };

  const familyMembers =
    props.userFamily && props.userFamily.Members
      ? props.userFamily.Members.filter(
          (m) =>
            m.UserId !== props.userId &&
            m.Role !== GroupMemberRole.NotValidMember
        )
      : [];

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item sm={10} xs={12}>
        <Typography align="justify" paragraph>
          {t("Family.ChangeFamilyOwner.Body")}
        </Typography>
        <FamilyMembersList
          members={familyMembers}
          unitId={props.userFamily?.UnitId || ""}
          selectedMember={selectedMember}
          listItemClickAction={selectMember}
        />
      </Grid>
      <Grid item container justifyContent="center">
        <Button
          variant="contained"
          color="secondary"
          disabled={!selectedMember}
          onClick={changeFamilyOwner}
          sx={{maxWidth: "none"}}
        >
          {t("General.Buttons.Choose")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ChangeFamilyOwnerPart;
