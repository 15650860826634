import { Dispatch, AnyAction } from "redux";
import { PostBoxActions } from "../constants/postbox.actiontypes";
import PostBoxService from "../services/postbox.service";
import { UserMailContract } from "../definitions/model/UserMail";
import { operationFailedActionGeneral } from ".";
import { AppAction } from "../definitions/Action";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(
    payload,
    PostBoxActions.POSTBOX_OPERATION_FAILED
  );
};

export const getMessages =
  (skip: number, take: number) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({ type: PostBoxActions.GET_MESSAGES });
      const result = await PostBoxService.getMessages(skip, take);
      dispatch({
        type: PostBoxActions.GET_MESSAGES_SUCCEEDED,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getMessage =
  (messageId: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({ type: PostBoxActions.GET_MESSAGE });
      const message = await PostBoxService.getMessage(messageId);
      dispatch({
        type: PostBoxActions.GET_MESSAGE_SUCCEEDED,
        payload: message,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getThread =
  (threadId: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({ type: PostBoxActions.GET_THREAD });
      const messages = await PostBoxService.getThread(threadId);
      dispatch({
        type: PostBoxActions.GET_THREAD_SUCCEEDED,
        payload: messages,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const createMessage =
  (message: UserMailContract) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({ type: PostBoxActions.CREATE_MESSAGE });
      const result = await PostBoxService.createMessage(message);
      dispatch({
        type: PostBoxActions.CREATE_MESSAGE_SUCCEEDED,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const replyToMessage =
  (message: UserMailContract) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({ type: PostBoxActions.REPLY_TO_MESSAGE });
      const result = await PostBoxService.replyToMessage(message);
      dispatch({
        type: PostBoxActions.REPLY_TO_MESSAGE_SUCCEEDED,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const moveMessageToTrash =
  (threadId: string, messageId: string) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({ type: PostBoxActions.MOVE_MESSAGE_TO_TRASH });
      await PostBoxService.moveMessageToTrash(threadId, messageId);
      dispatch({
        type: PostBoxActions.MOVE_MESSAGE_TO_TRASH_SUCCEEDED,
        payload: messageId,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const removeMessage =
  (messageId: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({ type: PostBoxActions.REMOVE_MESSAGE });
      const result = await PostBoxService.removeMessage(messageId);

      dispatch({
        type: PostBoxActions.REMOVE_MESSAGE_SUCCEEDED,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getRecipients =
  (filter: string) => async (dispatch: Dispatch<AnyAction>) => {
    //debounce(async (dispatch, filter) => {
    try {
      dispatch({ type: PostBoxActions.GET_RECIPIENTS });

      const recipients = await PostBoxService.getRecipients(filter);
      dispatch({
        type: PostBoxActions.GET_RECIPIENTS_SUCCEEDED,
        payload: recipients,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
    //}, 100);
  };
