import { Button, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isReactComponent } from ".";
import { getOptionalTheme } from "../../../../constants/theme";
import { useUnit } from "../../../../actions/unit.actions";
import store from "../../../../reducers/store";
import { GrapesEditor, GrapesTypes, LoadComponentOptions } from "../../defenitions";

type HouseDataButtonProps = {
  variant: "text" | "outlined" | "contained";
  color: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  text: string;
  href: string;
  unitId: boolean;
  commune: boolean;
};

export const HouseDataButton = (props: HouseDataButtonProps): JSX.Element => {
  const navigate = useNavigate();
  const [{ unit }] = useUnit();

  const onButtonClick = () => {
    if (props.href) {
      let url = props.href;
      let query = false;

      if (props.unitId) {
        url += `?unitId=${props.unitId}`;
        query = true;
      }

      if (props.commune && unit) {
        url += query ? "&" : "?";
        url += `commune=${unit.Address.Commune.CommuneNumber}`;
      }

      navigate(url);
    }
  };

  return (
    <Button onClick={onButtonClick} variant={props.variant} color={props.color}>
      {props.text}
    </Button>
  );
};

export default function (editor: GrapesEditor, options: LoadComponentOptions, landingTheme?: string): void {
  const domComponents = editor.DomComponents;
  const blockManager = editor.BlockManager;
  const defaultType = domComponents.getType("default");
  const { model, view } = defaultType;
  const compName = "HouseDataButton";

  blockManager.add(GrapesTypes.HouseDataButtonComponent, {
    label: "House data button",
    category: options.categoryLabel,
    attributes: { class: "fa fa-home" },
    content: `<div data-gjs-type="${GrapesTypes.HouseDataButtonComponent}"></div>`,
  });
  const traits = [
    {
      type: "select",
      label: "Variant",
      changeProp: 1,
      name: "variant",
      typeid: 0,
      options: [
        { id: "text", name: "text" },
        { id: "outlined", name: "outlined" },
        { id: "contained", name: "contained" },
      ],
    },
    {
      type: "select",
      label: "Color",
      changeProp: 1,
      name: "color",
      typeid: 0,
      options: [
        { id: "inherit", name: "inherit" },
        { id: "primary", name: "primary" },
        { id: "secondary", name: "secondary" },
        { id: "default", name: "default" },
        { id: "error", name: "error" },
        { id: "warning", name: "warning" },
        { id: "info", name: "info" },
        { id: "success", name: "success" },
      ],
    },
    {
      label: "Button text",
      name: "text",
      changeProp: 1,
    },
    {
      label: "Link",
      name: "href",
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Include Unit Id",
      name: "unitId",
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Include commune number",
      name: "commune",
      changeProp: 1,
    },
  ];
  const subscriptions = traits.map((trait) => trait.name);

  domComponents.addType(GrapesTypes.HouseDataButtonComponent, {
    isComponent(el: HTMLElement) {
      if (
        (el.getAttribute && el.getAttribute("data-gjs-type") === GrapesTypes.HouseDataButtonComponent) ||
        (el.attributes && (el.attributes as any)["data-gjs-type"] === GrapesTypes.HouseDataButtonComponent) ||
        isReactComponent(el, compName)
      ) {
        return {
          type: GrapesTypes.HouseDataButtonComponent,
        };
      }
    },
    model: {
      defaults: {
        ...model.prototype.defaults,
        droppable: false,
        variant: "outlined",
        color: "secondary",
        href: "",
        text: "",
        unitId: false,
        commune: false,
        traits: [...model.prototype.defaults.traits, ...traits],
      },
    },
    view: view.extend({
      init() {
        subscriptions.forEach((subscription) => {
          this.listenTo(this.model, `change:${subscription}`, this.handleChanges);
        });
      },
      handleChanges() {
        const root = ReactDOM.createRoot(this.el);
        root.unmount();
        this.render();
      },
      onRender() {
        const { el } = this;
        const comps = this.model.get("components");
        const { variant, color, text, href, unitId, commune } = this.model.attributes;
        comps.reset();
        const compString = `<${compName} variant=${variant} color=${color} text="${text}" href="${href}" unitId=${unitId} commune=${commune}></${compName}>`;
        comps.add(compString);
        const root = ReactDOM.createRoot(el);

        root.render(
          <>
            <ThemeProvider theme={getOptionalTheme(landingTheme)}>
              <Provider store={store}>
                <HouseDataButton
                  variant={variant}
                  color={color}
                  text={text}
                  href={href}
                  unitId={unitId}
                  commune={commune}
                />
                {href || <Typography color="error">please set the value for link in the settings</Typography>}
              </Provider>
            </ThemeProvider>
          </>
        );
      },
    }),
  });
}
