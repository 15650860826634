import React from "react";
import { Grid, Container, Link } from "@mui/material";
import { Alert, AlertTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

import MainContainer from "../../components/Layout/MainContainer";
import OverviewContent from "../Overview/Components/OverviewContent"

const NotFoundPage = (): JSX.Element => {
  const { t } = useTranslation("translation");

  return (
    <MainContainer hideMenu title={t("General.Errors.ErrorPageTitle") ?? ""}>
      <Container maxWidth="md">
        <Grid item style={{ marginBottom: 20}}>
          <Alert severity="error">
            <AlertTitle>{t("General.Errors.AlertTitle")}</AlertTitle>
              {t("General.Errors.TryingToAccess")}, <strong>{window.location.href}</strong>, {t("General.Errors.PageNotFound")} <br />
              {t("General.Errors.Contact")} <Link href="mailto:info@myhouse.dk">info@myhouse.dk</Link>
          </Alert>
        </Grid>
        <OverviewContent />
      </Container>
    </MainContainer>
  );
};

export default NotFoundPage;
