import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useUnit } from "../../../../../actions";
import { ExpandMore } from "@mui/icons-material";
import { Control, UseFormSetValue } from "react-hook-form";
import { WidgetFormData } from "./WidgetEditForm";
import { Controller } from "react-hook-form";
import { Delete, Add, Close } from "@mui/icons-material";
import { useCO2 } from "../../../../ClimatePlan/shared/co2plan.actions";

type DataSensitiveInputPorps = {
  setValue: UseFormSetValue<WidgetFormData>;
  control: Control<WidgetFormData, any>;
  SensitiveAction: string;
  index: number;
  removeHandler: () => void;
  SensitiveAmount?: number;
  SensitivePath: string;
};

const initialOptions = ["Climatplan", "Unit"];

const actionOptions = ["==", "!=", ">", "<", ">=", "<="];

const getInnerKeys = (obj: any, keys: string[], setIsItemNumeric: React.Dispatch<React.SetStateAction<boolean>>) => {
  let object: any = obj;

  for (const key of keys) {
    if (object && object[key]) {
      object = object[key];
    }
  }

  setIsItemNumeric(typeof object === "number");
  if (object && typeof object === "object" && !Array.isArray(object)) {
    return Object.keys(object);
  }

  return [];
};

const DataSensitiveInput = ({
  setValue,
  control,
  SensitiveAction,
  SensitiveAmount,
  index,
  removeHandler,
  SensitivePath,
}: DataSensitiveInputPorps) => {
  const [path, setPath] = useState<string | null>(SensitivePath);
  const [selectOptions, setSelectOptions] = useState<string[]>(initialOptions);
  const [state, actions] = useCO2();
  const [unit] = useUnit();
  const [isItemNumeric, setIsItemNumeric] = useState(false);
  const { co2Plan } = state;

  useEffect(() => {
    if (unit.unit) {
      actions.loadPlan(unit.unit?.Id);
    }
  }, [unit.unit]);

  const selectChangeHandler = (event: SelectChangeEvent<string>) => {
    setPath((prevPath) => {
      if (prevPath) {
        const keys = prevPath.split(".");

        if (selectOptions.includes(keys[keys.length - 1])) {
          keys.pop();
          return [...keys, event.target.value].join(".");
        }
        return prevPath + "." + event.target.value;
      } else {
        return event.target.value;
      }
    });
  };

  useEffect(() => {
    if (!path) {
      setSelectOptions(initialOptions);
    } else {
      const essence = path.split(".")[0];

      if (essence === "Climatplan") {
        setSelectOptions(getInnerKeys(co2Plan, path.split(".").slice(1), setIsItemNumeric).sort());
      }

      if (essence === "Unit") {
        setSelectOptions(getInnerKeys(unit.unit, path.split(".").slice(1), setIsItemNumeric).sort());
      }

      setValue(`SensitiveList.${index}.Path`, path);
    }
  }, [path, co2Plan, unit]);

  const removeKeyFromPath = (index: number) => {
    setPath((prevPath) => {
      if (prevPath) {
        return prevPath?.split(".").slice(0, index).join(".");
      }

      return prevPath;
    });
  };

  return (
    <Accordion defaultExpanded={!SensitivePath}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        {SensitivePath ? SensitivePath.split(".")[SensitivePath.split(".").length - 1] : "Data Sensetive"}
        <IconButton onClick={removeHandler} style={{ marginLeft: "auto" }}>
          <Delete />
        </IconButton>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid container justifyContent="space-between" direction="row" marginBottom={4}>
            <Typography style={{ display: "flex", alignItems: "center" }} variant="body1">
              Show this widget if
            </Typography>
            <KeysStyledContainer>
              {path?.split(".").map((key, index) => (
                <KeyBlock item key={key} onClick={() => removeKeyFromPath(index)}>
                  <StyledCloseIcon />
                  {index ? `.${key}` : `${key}`}
                </KeyBlock>
              ))}
              <Select
                onChange={selectChangeHandler}
                IconComponent={() => <StyledAddIcon />}
                style={{ minWidth: "50px", marginLeft: "8px" }}
                disableUnderline
                value=""
              >
                {selectOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
                {!selectOptions.length && (
                  <MenuItem disabled value="">
                    {"(empty)"}
                  </MenuItem>
                )}
              </Select>
            </KeysStyledContainer>
            {!isItemNumeric ? (
              <FormControlLabel
                control={
                  <Controller
                    name={`SensitiveList.${index}.SensitiveAction`}
                    control={control}
                    render={() => (
                      <Checkbox
                        defaultChecked={SensitiveAction === "true"}
                        onChange={(e) => {
                          setValue(`SensitiveList.${index}.SensitiveAction`, `${e.target.checked}`);
                        }}
                      />
                    )}
                  />
                }
                label="exist"
              />
            ) : (
              <Grid>
                <Select
                  variant="outlined"
                  style={{ marginRight: "6px" }}
                  defaultValue={SensitiveAction}
                  onChange={(e) => setValue(`SensitiveList.${index}.SensitiveAction`, e.target.value as string)}
                >
                  {actionOptions.map((action) => (
                    <MenuItem key={action} value={action}>
                      {action}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  variant="outlined"
                  defaultValue={SensitiveAmount}
                  style={{ width: "80px" }}
                  size="medium"
                  type="number"
                  onChange={(e) => setValue(`SensitiveList.${index}.SensitiveAmount`, Number(e.target.value))}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const StyledCloseIcon = styled(Close)(() => ({
  width: "12xp",
  height: "12px",
  position: "absolute",
  top: "20%",
  left: "85%",
  transform: "translateX(-50%) translateY(-50%)",
  display: "none",
}));

const StyledAddIcon = styled(Add)(() => ({
  position: "absolute",
  right: "25%",
  pointerEvents: "none",
}));

const KeysStyledContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  border: "1px solid rgba(0, 0, 0, 0.38)",
  borderRadius: theme.shape.borderRadius,
  padding: "0 8px",
  "&:hover": {
    border: "1px solid rgba(0, 0, 0, 1)",
    cursor: "pointer",
  },
}));

const KeyBlock = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "relative",

  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: theme.palette.grey[300],
    cursor: "pointer",
    "& .MuiSvgIcon-root": {
      display: "block",
    },
  },
}));

export default DataSensitiveInput;
