import React, { useContext, useMemo } from "react";
import { Grid, Button, useTheme, useMediaQuery, styled, Paper } from "@mui/material";
import { CO2EmissionSourceGroup, CO2PersonEmissionSourceGroup, CO2PlanPerson } from "../../CO2Plan";
import CO2EmissionPersonComponent from "./CO2EmissionPersonComponent";
import CO2EmissionSourcesFlattenedComponent from "./CO2EmissionSourcesFlattenedComponent";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CO2Context } from "../CO2ContextWrapper";

type CO2EmissionSourceGroupProps = {
  group: CO2EmissionSourceGroup;
  absoluteIndex: number;
  hideNextButton: boolean;
  planPersons: CO2PlanPerson[];
};

const CO2EmissionSourceGroupComponent = (props: CO2EmissionSourceGroupProps): JSX.Element => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const context = useContext(CO2Context);
  
  const planPersonsToRender = useMemo(() => {
    const personEmissionSources = props.group.PersonEmissionSources.filter((person) => {
      const currentPerson = props.planPersons.find((listPerson) => {
        return listPerson.Id === person.PersonId;
      });

      if (currentPerson?.BirthYear) {
        const currentYear = new Date().getFullYear();
        if (currentYear - currentPerson.BirthYear <= 6) return false;
      }
      return true;
    });

    // to get personEmissionSources sorted by date of creation of planPerson
    // we need to sort personEmissionSources in the same order as the planPersons
    // because planPersons have been gotten from DB sorted by creation date.
    
    const sortedPersonEmissionSources = props.planPersons.slice(0).reverse().map((p)=> {
      const currentEmission = personEmissionSources.find(x=>x.PersonId === p.Id)
      if (currentEmission ) return currentEmission;
    });   
   
    return sortedPersonEmissionSources.filter(Boolean) as CO2PersonEmissionSourceGroup[];

  }, [props.group.PersonEmissionSources, props.planPersons]);

  return (
    <Grid container direction="column" alignItems="center" spacing={1}>
      {props.group.IsPerPerson &&
        planPersonsToRender.map((person, index) => (
          <React.Fragment key={index}>
            <CO2EmissionPersonComponent
              person={person}
              groupId={props.group.Id}
              key={props.group.Id + person.PersonId}
            ></CO2EmissionPersonComponent>
            {isXs && !props.hideNextButton && props.group.PersonEmissionSources.length - 1 > index && (
              <Grid
                item
                container
                direction="row"
                justifyContent="flex-end"
                key={`next_${props.group.Id + person.PersonId}`}
              >
                <StyledArrowButton
                  variant="outlined"
                  onClick={() => context.setActiveGroupIndex(props.absoluteIndex + 1)}
                >
                  <ArrowForwardIosIcon />
                </StyledArrowButton>
              </Grid>
            )}
          </React.Fragment>
        ))}
      {!props.group.IsPerPerson && (
        <Grid item container>
        <Paper sx={{ padding: isXs ? "40px 8px" : "60px 80px", width: "100%" }}> 
        <CO2EmissionSourcesFlattenedComponent
          highLevelSources={props.group.HouseHoldEmissionSources}
          personId={null}
          isFieldsInvalid={props.hideNextButton}
        ></CO2EmissionSourcesFlattenedComponent>
        </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default CO2EmissionSourceGroupComponent;

const StyledArrowButton = styled(Button)(({ theme }) => ({
  padding: "5px",
  minWidth: 40,
  minHeight: 40,
  margin: 5,
  backgroundColor: theme.palette.background.grayPaper,
  color: theme.palette.primary.contrastText,
  border: "none",
}));
