import React, { MouseEvent } from "react";
import { Button, Grid, Tooltip, useTheme } from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { NewsCategoriesFilter } from "../../../constants/enums";
import { RiPoliceCarFill } from "react-icons/ri";
import { GiNewspaper } from "react-icons/gi";
import { AiTwotoneBank } from "react-icons/ai";
import { BsHouseDoorFill } from "react-icons/bs";
import { Close, Museum } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type NewsCategoriesBarProps = {
  value: NewsCategoriesFilter | null;
  onChange: (value: NewsCategoriesFilter | null) => void;
};

const NewsCategoriesBar = (props: NewsCategoriesBarProps): JSX.Element => {
  const theme = useTheme();
  const isSmDown = theme.breakpoints.down("md");
  const { t } = useTranslation("translation");
  const onChange = (
    e: MouseEvent<HTMLElement, globalThis.MouseEvent>,
    value: NewsCategoriesFilter
  ) => props.onChange(value);

  return (
    <>
      <ToggleButtonGroup
        value={props.value}
        exclusive
        onChange={onChange}
        size={isSmDown ? "small" : "medium"}
      >
        <ToggleButton
          value={NewsCategoriesFilter.LocalNews}
          aria-label={t("NewsStream.LocalNews") ?? ""}
        >
          <Tooltip title={t("NewsStream.LocalNews") ?? ""}>
            <span>
              <GiNewspaper fontSize={30} />
            </span>
          </Tooltip>
        </ToggleButton>
        <ToggleButton
          value={NewsCategoriesFilter.CommuneSite}
          aria-label={t("Base.Popovers.Commune") ?? ""}
        >
          <Tooltip title={t("Base.Popovers.Commune") ?? ""}>
            <span>
              <AiTwotoneBank fontSize={30} />
            </span>
          </Tooltip>
        </ToggleButton>

        <ToggleButton
          value={NewsCategoriesFilter.PoliceReports}
          aria-label={t("NewsStream.Police") ?? ""}
        >
          <Tooltip title={t("NewsStream.Police")}>
            <span>
              <RiPoliceCarFill fontSize={30} />
            </span>
          </Tooltip>
        </ToggleButton>
        <Tooltip title={t("NewsStream.MyhouseNews", { hostname: window.location.hostname })}>
          <ToggleButton
            value={NewsCategoriesFilter.MyhouseNews}
            aria-label={t("NewsStream.MyhouseNews", { hostname: window.location.hostname }            ) ?? ""}
          >
            <span>
              <BsHouseDoorFill fontSize={30} />
            </span>
          </ToggleButton>
        </Tooltip>
        <ToggleButton
          value={NewsCategoriesFilter.CulturalNews}
          aria-label={t("NewsStream.CulturalNewsLabel") ?? ""}
        >
          <Tooltip title={t("NewsStream.CulturalNews") ?? ""}>
            <span>
              <Museum fontSize="large" />
            </span>
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
      {Boolean(props.value) && (
        <Grid container>
          <Button
            type="submit"
            color="secondary"
            onClick={() => props.onChange(null)}
          >
            <Close fontSize="small" />
            {t("General.Buttons.ResetFilter")}
          </Button>
        </Grid>
      )}
    </>
  );
};

export default NewsCategoriesBar;
