import React, { ReactNode } from "react";
import {
  Card,
  CardProps,
  CardContent,
  CardHeader,
  CardActions,
  LinearProgress,
  styled,
  SxProps,
  Theme,
} from "@mui/material";

const StyledCard = styled(
  Card,
  {}
)(() => ({
  marginBottom: 20,
  padding: 0,
  "&:last-child": {
    padding: 0,
  },
}));

const Header = styled(CardHeader, {
  shouldForwardProp: (propName: string) => propName !== "type",
})<CardClassesProps>(({ theme, type }) => ({
  "&.MuiCardHeader-root": {
    backgroundColor: theme.palette.primary.main,
    padding: "0 10px",
    fontSize: 12,
  },
  "& .MuiCardHeader-title": {
    color: theme.palette.primary.contrastText,
  },
  "& .MuiCardHeader-subheader": {
    fontSize: 12,
    color: theme.palette.primary.contrastText,
    lineHeight: 1.2,
    paddingBottom: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: type === "main" ? 14 : 12,
    },
  },
  "& .MuiCardHeader-action": {
    marginTop: "4px",
    marginRight: "-4px",
  },
}));

const NestedCardContent = styled(CardContent, {
  shouldForwardProp: (propName: string) => propName !== "type",
})<CardClassesProps>(({ type }) => ({
  marginBottom: 20,
  padding: type === "narrow" ? 0 : 16,
  "&:last-child": {
    padding: type === "narrow" ? 0 : 16,
  },
}));

interface StyledCardProps extends CardProps {
  type: "main" | "secondary" | "narrow";
  contentType?: string;
  title?: string;
  subheader?: string | JSX.Element;
  children: ReactNode;
  actions?: ReactNode;
  showProgress?: boolean;
  headerAction?: React.ReactNode;
  sx?: SxProps<Theme>;
}

type CardClassesProps = Pick<StyledCardProps, "type" | "contentType">;

const MyhouseCard = (props: StyledCardProps): JSX.Element => {
  return (
    <>
      <StyledCard variant="outlined" square sx={props.sx}>
        <Header type={props.type} title={props.title} subheader={props.subheader} action={props.headerAction} />
        <NestedCardContent type={props.type}>{props.children}</NestedCardContent>
        {props.actions && <CardActions>{props.actions}</CardActions>}
      </StyledCard>
      {props.showProgress && <LinearProgress sx={{ height: 6 }} />}
    </>
  );
};

export default MyhouseCard;
