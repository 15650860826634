import React from "react";
import { useMediaQuery, useTheme, Grid, Typography, List, ListItem, styled } from "@mui/material";
import { GroupToShow } from "./CO2EmissionChartComponent";
import ExtendedPieChart from "./ExtendedPieChart";
import { useTranslation } from "react-i18next";

type CO2PersonsChartProps = {
  showLegend?: boolean;
  showDescription?: boolean;
  persons: GroupToShow[];
};

const CO2PersonsChartComponent = (props: CO2PersonsChartProps): JSX.Element => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation("translation");

  return (
    <Grid item container spacing={2}>
      {/* {persons.length > 1 && ( */}
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="h3">{t("ClimatePlan.General.My")}</Typography>
          <Typography variant="h3" color="secondary" paragraph>
            {t("ClimatePlan.PersonsChart.Household")}
          </Typography>
        </Grid>
        <Grid item>
          <div style={{ width: 260 }}>
            <ExtendedPieChart
              groups={props.persons}
              radius={isXs ? 60 : 80}
              withToolTip
              height={isXs ? 200 + props.persons.length * 10 : 300}
              width="100%"
              pieData={{
                outerRadius: "50%",
                cy: "50%",
              }}
            />
          </div>
        </Grid>
        <Grid item container justifyContent="flex-start">
          <StyledList>
            {props.persons.map((item, index) => (
              <ListItem key={item.Id} sx={{ width: "max-content", paddingLeft: 0 }}>
                <div
                  style={{ height: 30, width: 30, marginRight: 5, backgroundColor: `${item.color}`, borderRadius: 10 }}
                  key={index}
                />
                {item.ShortName}
              </ListItem>
            ))}
          </StyledList>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CO2PersonsChartComponent;

const StyledList = styled(List)(() => ({
  display: "flex",
  flexDirection: "column",
  maxHeight: 200,
  flexWrap: "wrap",
  minWidth: 260,
}));
