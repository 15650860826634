export const AdminSystemMmessageActionTypes = {
    GET_MESSAGE_TEMPLATE: 'GET_MESSAGE_TEMPLATE',
    GET_MESSAGE_TEMPLATE_SUCCEEDED: 'GET_MESSAGE_TEMPLATE_SUCCEEDED',
    GET_MESSAGE_EDITOR: "GET_MESSAGE_EDITOR",
    GET_MESSAGE_EDITOR_SUCCEEDED: "GET_MESSAGE_EDITOR_SUCCEEDED",
    GET_SYSTEM_MESSAGES: "GET_SYSTEM_MESSAGES",
    GET_SYSTEM_MESSAGES_SUCCEEDED: "GET_SYSTEM_MESSAGES_SUCCEEDED",
    GET_SYSTEM_MESSAGE: "GET_SYSTEM_MESSAGE",
    GET_SYSTEM_MESSAGE_SUCCEEDED: "GET_SYSTEM_MESSAGE_SUCCEEDED",
    GET_SYSTEM_MESSAGE_TYPES: "GET_SYSTEM_MESSAGE_TYPES",
    GET_SYSTEM_MESSAGE_TYPES_SUCCEEDED: "GET_SYSTEM_MESSAGE_TYPES_SUCCEEDED",
    GET_SYSTEM_MESSAGE_MISSING_TYPES: "GET_SYSTEM_MESSAGE_MISSING_TYPES",
    GET_SYSTEM_MESSAGE_MISSING_TYPES_SUCCEEDED: "GET_SYSTEM_MESSAGE_MISSING_TYPES_SUCCEEDED",
    ADD_SYSTEM_MESSAGE: "ADD_SYSTEM_MESSAGE",
    ADD_SYSTEM_MESSAGE_SUCCEEDED: "ADD_SYSTEM_MESSAGE_SUCCEEDED",
    EDIT_SYSTEM_MESSAGE: "EDIT_SYSTEM_MESSAGE",
    EDIT_SYSTEM_MESSAGE_SUCCEEDED: "EDIT_SYSTEM_MESSAGE_SUCCEEDED",
    DELETE_SYSTEM_MESSAGE: "DELETE_SYSTEM_MESSAGE",
    DELETE_SYSTEM_MESSAGE_SUCCEEDED: "DELETE_SYSTEM_MESSAGE_SUCCEEDED",
    SEND_TEST_SYSTEM_MESSAGE: "SEND_TEST_SYSTEM_MESSAGE",
    SEND_TEST_SYSTEM_MESSAGE_SUCCEEDED: "SEND_TEST_SYSTEM_MESSAGE_SUCCEEDED"
}