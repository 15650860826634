import React from "react";
import { Grid, Button, CircularProgress, styled, useTheme, Alert, IconButton, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

type ButtonsComponentProps = {
  back: (() => void) | null;
  forth: (() => void) | null;
  children?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
};

const ButtonsComponent = (props: ButtonsComponentProps) => {
  const theme = useTheme();
  return (
    <StyledGrid item container direction="column">
      <Grid item container justifyContent="center">
        {props.back && (
          <Grid item>
            <StyledArrowButton
              variant="contained"
              color="success"
              onClick={props.back}
            >
              <ArrowBackIosNewIcon />
            </StyledArrowButton>
          </Grid>
        )}
        {props.forth && (
          <Grid item>
            <StyledArrowButton
              disabled={props.disabled}
              variant="contained"
              color="success"
              onClick={props.forth}
            >
              {props.loading ? (
                <CircularProgress size={16} sx={{color: theme.palette.primary.contrastText}} />
              ) : (
                <ArrowForwardIosIcon />
              )}
            </StyledArrowButton>
          </Grid>
        )}
      </Grid>
      {props.children}
    </StyledGrid>
  );
};

export default ButtonsComponent;

const StyledGrid = styled(Grid)(({ theme }) => ({
  "@media print": {
    display: "none",
  },
}));

const StyledArrowButton = styled(Button)(({ theme }) => ({
  padding: "5px",
  minWidth: 40,
  minHeight: 40,
  margin: 5,
  // backgroundColor: theme.palette.background.grayPaper,
  // color: theme.palette.primary.contrastText,
  // border: "1px solid" + theme.palette.secondary.main,
  // "&:hover": {
  //   color: theme.palette.primary.contrastText,
  //   backgroundColor: theme.palette.background.grayPaper,
  // },
  "&.Mui-disabled": {
    // border: "none"
  }
}));
