import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  // Link,
  styled,
  useTheme,
  Skeleton,
  IconButton,
  Box,
  useMediaQuery,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { UserConstants } from "../../../constants";
// import { useToggler } from "../../../helpers/hooks";
import { convertToCurency } from "../../../services/converter.service";
import Unit, { PriceEstimation, Broker } from "../../../definitions/model/unit/Unit";
import MCard from "../../../components/Base/MyhouseCard";
import { useUser, useUnitActions } from "../../../actions";
// import ComplaintDialog from "../PropertyFacts/components/ComplaintDialog";
// import HelpDialog from "../PropertyFacts/components/HelpDialog";
// import NeighborsDialog from "../PropertyFacts/components/NeighborsDialog";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const PriceValueGridStyled = styled(Grid)(() => ({
  "& .MuiTypography-h4": {
    padding: 0,
  },
  "& .MuiTypography-paragraph": {
    padding: 0,
  },
}));

const PriceButtonStyled = styled(Button)(() => ({
  minWidth: "160px",
  margin: 10,
}));

type HousePricesProps = {
  brokers?: Broker[];
  isLoading: boolean;
  // lastEstimation: PriceEstimation | null;
  unit: Unit | null;
  subscribeOnHousePriceChanges?: (unitId: string) => Promise<void>;
};

const HousePrices = ({
  brokers,
  unit,
  isLoading,
  // lastEstimation,
  subscribeOnHousePriceChanges,
}: HousePricesProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const [{ user }] = useUser();
  const unitActions = useUnitActions();
  const dispatch = useDispatch();
  // const [openHelpDialog, toggleHelpDialog] = useToggler(false);
  // const [openComplainDialog, toggleComplainDialog] = useToggler(false);
  // const [openNeighborsDialog, toggleNeighborsDialog] = useToggler(false);
  const [vurderingsPortalenPrice, setVurderingsPortalenPrice] = useState<number | null>(null);
  const [otherPrices, setOtherPrices] = useState("");
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  useEffect(() => {
    brokers?.forEach((broker) => {
      if (broker.BrokerName.toLowerCase() === "vurderingsportalen") {
        setVurderingsPortalenPrice(broker.Estimation);
      } else {
        setOtherPrices((prevValue) => {
          return prevValue + `${broker.BrokerName}: ${broker.Estimation}; `;
        });
      }
    });
  }, [brokers]);

  return (
    <MCard
      type="main"
      title={t("HouseManagement.ValueOfHome")}
      contentType="narrow"
      sx={{
        padding: "0 16px 0 0",
      }}
    >
      <>
        <Grid container mt={2} justifyContent="space-between">
          <Grid item container xs={12} md={6.5} direction="column" pl={isXs ? 1.5 : 2} pr={isXs ? 1 : 3}>
            {!!brokers?.length &&
              !isLoading &&
              brokers.map((broker: Broker, index: number) => (
                <Box key={broker.BrokerName + index}>
                  <Grid
                    container
                    sx={{ flexWrap: "nowrap" }}
                    item
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item display="flex" alignItems="baseline" flexWrap="wrap">
                      <Typography variant="subtitle1" sx={{ marginRight: "5px" }}>
                        {t("HouseManagement.PriceAccordingTo")}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ whiteSpace: "nowrap" }}>
                        {t("HouseManagement.Vurderingsportalen")}
                        {broker.BrokerFaqUrl && (
                          <IconButton
                            sx={{ marginBottom: 1, marginLeft: "-3px" }}
                            href={broker.BrokerFaqUrl}
                            target="_blank"
                            size="small"
                          >
                            <HelpOutlineIcon color="secondary" />
                          </IconButton>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        {broker.Estimation == -1 && t("General.Management.ComingSoon")}
                        {broker.Estimation == 0 && t("HouseManagement.NoCalculation")}
                        {broker.Estimation > 0 && convertToCurency(broker.Estimation, ".")}
                      </Typography>
                    </Grid>
                  </Grid>
                  {unit && broker.Estimation > 0 && unit?.UnitData.Area > 0 && (
                    <Grid
                      container
                      item
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap="nowrap"
                      key={index}
                    >
                      <Grid item>
                        <Typography variant="subtitle1">
                          {t("HouseManagement.PropertyValue", { hostname: window.location.hostname })}{" "}
                          <Trans components={{ sup: <sup /> }} i18nKey="HouseManagement.PricePerSquareMeter" />
                        </Typography>
                      </Grid>
                      {unit && (
                        <Grid item>{convertToCurency(Math.round(broker.Estimation / unit.UnitData.Area), ".")} </Grid>
                      )}
                    </Grid>
                  )}
                </Box>
              ))}
            {isLoading && (
              <>
                <Skeleton width={250} height={25} />
                <Skeleton width={250} height={25} />
              </>
            )}
            {!brokers?.length && !isLoading && (
              <Typography variant="subtitle1">{t("HouseManagement.NoCalculation")}</Typography>
            )}
          </Grid>
          {/* <Grid item container direction="column" justifyContent="center" alignContent={"center"} xs={12} md={3}>
            <PriceButtonStyled variant="contained" color="secondary" onClick={toggleComplainDialog}>
              {t("HouseManagement.Complain")}
            </PriceButtonStyled>
            <PriceButtonStyled variant="contained" color="secondary" onClick={toggleHelpDialog}>
              {t("HouseManagement.AskHelp")}
            </PriceButtonStyled>
            <PriceButtonStyled variant="contained" color="secondary" onClick={toggleNeighborsDialog}>
              {t("HouseManagement.SeeNeighbors")}
            </PriceButtonStyled>
          </Grid>  */}

          {/* {!user && ( 
                <WrongPriceButtonStyled variant="contained" onClick={openAuthDialog}>
                  {t("HouseManagement.SubscribePriceChanges")}
                </WrongPriceButtonStyled>
              )} */}
        </Grid>

        {/* <ComplaintDialog
          EstimatedSalePrice={Math.round(lastEstimation?.EstimatedSalePrice || 0)}
          VurderingsPortalenPrice={vurderingsPortalenPrice || undefined}
          OtherPrices={otherPrices}
          open={openComplainDialog}
          closeDialog={toggleComplainDialog}
        />
        <HelpDialog
          open={openHelpDialog}
          closeDialog={toggleHelpDialog}
          name={user?.Name}
          phone={user?.Phone}
          userId={user?.Id}
          EstimatedSalePrice={Math.round(lastEstimation?.EstimatedSalePrice || 0)}
          VurderingsPortalenPrice={vurderingsPortalenPrice || undefined}
          OtherPrices={otherPrices}
        />
        <NeighborsDialog open={openNeighborsDialog} closeDialog={toggleNeighborsDialog} /> */}
      </>
    </MCard>
  );
};

export default HousePrices;
