import React, { useState } from "react";
import { Button, TextField, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import BaseDialog from "../../../../../components/Dialogs/BaseDialog";
import {
  TemplateModel,
  LandingMetaModel,
} from "../../../../../definitions/Landing";
import { useAlertContext } from "../../../../../components/Base";
import TemplatesSelector from "../../Templates/components/TemplatesSelector";
import { TemplateType } from "../../../../../constants/enums";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";
import CircularProgressButton from "../../../../../components/Base/CircularProgressButton";

type CreateLandingDialogProps = {
  open: boolean;
  templates: TemplateModel[];
  handleClose: () => void;
  getLandingTemplates: (skip: number, take: number) => Promise<void>;
  createLanding: (
    title: string,
    uniqueName: string,
    templateId?: string
  ) => Promise<LandingMetaModel | null>;
  clearTemplates: () => void;
};

export type LandingFormData = {
  Title: string;
  UniqueName: string;
  TemplateId: string;
};

const TemplateCreateDialog = (props: CreateLandingDialogProps): JSX.Element => {
  const methods = useForm<LandingFormData>({
    defaultValues: {
      Title: "",
      UniqueName: "",
      TemplateId: "",
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;
  const [isUpdating, setIsUpdating] = useState(false);
  const { showAlert } = useAlertContext();

  const handleClose = () => {
    props.handleClose();
  };

  const submit = (data: LandingFormData) => {
    setIsUpdating(true);
    props
      .createLanding(data.Title, data.UniqueName, data.TemplateId)
      .then((landing) => {
        if (landing)
          showAlert({
            severity: "success",
            text: "Landing succesfuly created",
          });
        else showAlert({ severity: "error", text: "Landing was not created" });
      })
      .finally(() => {
        setIsUpdating(false);
        props.handleClose();
      });
  };

  return (
    <>
      <BaseDialog
        open={props.open}
        closeDialog={handleClose}
        titleComponent="Create Landing"
      >
        <form onSubmit={handleSubmit(submit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldConnector
                register={register("Title", { required: true })}
              >
                <TextField
                  autoFocus
                  margin="dense"
                  variant="outlined"
                  label="Title"
                  type="text"
                  fullWidth
                  error={!!errors.Title}
                />
              </TextFieldConnector>
            </Grid>
            <Grid item xs={12}>
              <TextFieldConnector
                register={register("UniqueName", { required: true })}
              >
                <TextField
                  autoFocus
                  margin="dense"
                  variant="outlined"
                  label="Unique name"
                  type="text"
                  fullWidth
                  error={!!errors.UniqueName}
                />
              </TextFieldConnector>
            </Grid>
            <Grid item xs={12}>
              <TemplatesSelector
                name="TemplateId"
                control={control}
                templatesType={TemplateType.Landing}
              />
            </Grid>
            <Grid container justifyContent="flex-end" item>
              <Button
                variant="contained"
                onClick={handleClose}
                color="secondary"
                style={{ marginRight: 20, minWidth: 120 }}
              >
                Close
              </Button>

              <CircularProgressButton
                style={{ minWidth: 120 }}
                type="submit"                
                inProgress={isUpdating}               
              >
                Create
              </CircularProgressButton>
            </Grid>
          </Grid>
        </form>
      </BaseDialog>
    </>
  );
};

export default TemplateCreateDialog;
