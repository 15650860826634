import React from "react";
import { Typography, Box, styled } from "@mui/material";
import {
  TimelineItem,
  AssesmentItem,
  BuildingItem,
  SaleItem,
} from "../../../definitions/Timeline";
import { TimelineItemType } from "../../../constants/enums";
import {
  convertToDate,
  convertToCurency,
} from "../../../services/converter.service";
import { useTranslation } from "react-i18next";

const BoxStyled = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.dark,
  height: 125,
  "& >p": {
    [theme.breakpoints.only("xs")]: {
      fontSize: 11,
    },
  },
}));

type TimelineBlockItemProps = {
  item: TimelineItem;
  children: React.ReactNode;
};

const TimelineBlockItem = ({
  item,
  children,
}: TimelineBlockItemProps): JSX.Element => {
  return (
    <BoxStyled>
      {children ? children : null}
      <Typography>{getItemTextContent(item)}</Typography>
    </BoxStyled>
  );
};

const getItemTextContent = (item: TimelineItem) => {
  const { t } = useTranslation("translation");

  switch (item.Type) {
    case TimelineItemType.Assesment: {
      const data = item.Data as AssesmentItem;
      return (
        <>
          {data.Created && (
            <>
              {t("Pages.HouseTimeline.PublicEstateAppraisal")}
              {new Date(data.Created).getUTCFullYear()}
              <br />
            </>
          )}
          {data.Created && (
            <>
              {t("Pages.HouseTimeline.ChangedDate")}:{" "}
              {convertToDate(data.Created)} <br />
            </>
          )}
          {data.PublicPricing && data.PublicPricing > 0 && (
            <>
              {t("Pages.HouseTimeline.PropertyValue")}:{" "}
              {convertToCurency(data.PublicPricing)}{" "}
              {t("Subscriptions.Currency")}
              <br />
            </>
          )}
          {data.BasicValue && data.BasicValue > 0 && (
            <>
              {t("Pages.HouseTimeline.BasicValue")}:{" "}
              {convertToCurency(data.BasicValue)} {t("Subscriptions.Currency")}{" "}
              <br />
            </>
          )}
          {data.Plot && data.Plot > 0 && (
            <>
              {t("Pages.HouseTimeline.GroundArea")}: {data.Plot} ㎡<br />
            </>
          )}
        </>
      );
    }
    case TimelineItemType.Building: {
      const data = item.Data as BuildingItem;
      return (
        <>
          {data.Type && (
            <>
              {data.Type} <br />
            </>
          )}
          {data.BuildYear && (
            <>
              {t("Pages.HouseTimeline.Listed")}: {data.BuildYear} <br />
            </>
          )}
          {data.GroundArea && data.GroundArea > 0 && (
            <>
              {t("Pages.HouseTimeline.Built")}: {data.GroundArea} ㎡<br />
            </>
          )}
          {data.WallText && (
            <>
              {t("Pages.HouseTimeline.ExternalWall")}: {data.WallText} <br />
            </>
          )}
          {data.RoofText && (
            <>
              {t("Pages.House.Roof")}: {data.RoofText} <br />
            </>
          )}
        </>
      );
    }

    case TimelineItemType.MainBuilding: {
      const data = item.Data as BuildingItem;
      return (
        <>
          {data.Type && (
            <>
              {data.Type}
              <br />
            </>
          )}
          {data.BuildYear && (
            <>
              {t("Pages.HouseTimeline.Listed")}: {data.BuildYear} <br />
            </>
          )}
          {data.GroundArea && data.GroundArea > 0 && (
            <>
              {t("Pages.HouseTimeline.Built")}: {data.GroundArea} ㎡
              <br />
            </>
          )}
          {data.WallText && (
            <>
              {t("Pages.HouseTimeline.ExternalWall")}: {data.WallText}
              <br />
            </>
          )}
          {data.RoofText && (
            <>
              {t("Pages.House.Roof")}: {data.RoofText}
              <br />
            </>
          )}
        </>
      );
    }

    case TimelineItemType.Sale: {
      const data = item.Data as SaleItem;
      return (
        <>
          {data.TypeOfBuyingText && (
            <>
              {data.TypeOfBuyingText} <br />
            </>
          )}
          {data.DateOfSales && (
            <>
              {t("Pages.HouseTimeline.ConversionDate")}:{" "}
              {convertToDate(data.DateOfSales)} <br />
            </>
          )}
          {data.SalesPrice && data.SalesPrice > 0 && (
            <>
              {t("Pages.HouseTimeline.PurchaseAmount")}:{" "}
              {convertToCurency(data.SalesPrice)} {t("Subscriptions.Currency")}
              <br />
            </>
          )}
          {data.TypeOfBuyingText && (
            <>
              {t("Pages.HouseTimeline.TransferCode")}: {data.TypeOfBuyingText}
              <br />
            </>
          )}
        </>
      );
    }
  }
};

export default TimelineBlockItem;
