import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";

import { TemplateModel } from "../../../../../definitions/Landing";
import TemplatesSelector from "../../Templates/components/TemplatesSelector";
import { TemplateType } from "../../../../../constants/enums";
import { useTranslation } from 'react-i18next';
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";

type NewsCreateDialogProps = {
  open: boolean;
  handleClose: (club?: NewsFormData) => void;
  newsTemplates: TemplateModel[];
  getNewsTemplates: (skip: number, take: number) => void;
};

export type NewsFormData = {
  Name: string;
  TemplateId: string | null;
};

const NewsCreateDialog = (props: NewsCreateDialogProps) => {

  const { t } = useTranslation("translation");

  const methods = useForm<NewsFormData>({
    defaultValues: {
      Name: "",
      TemplateId: null,
    },
  });
  const { register, handleSubmit, formState: {errors}, control } = methods;

  const handleClose = () => {
    props.handleClose();
  };

  const submit = (data: NewsFormData) => {
    props.handleClose(data);
  };

  return (
    <Dialog open={props.open} onClose={handleClose} maxWidth="sm" aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Create News</DialogTitle>
      <form onSubmit={handleSubmit(submit)}>
        <DialogContent>
          <TextFieldConnector register={register("Name", {required: true})}>
            <TextField
              autoFocus
              margin="dense"
              label="Name"
              type="text"
              fullWidth
              error={!!errors.Name}
            />
          </TextFieldConnector>
          <TemplatesSelector name="TemplateId" templatesType={TemplateType.News} control={control} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="secondary">
            {t("General.Buttons.Undo")}
          </Button>
          <Button type="submit" variant="contained" color="secondary">
            {t("General.Buttons.Create")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewsCreateDialog;
