export const DocumentActions = {
    DOCUMENT_OPERATION_FAILED: "DOCUMENT_OPERATION_FAILED",
    GET_FOLDERS: "GET_FOLDERS",
    GET_FOLDERS_SUCCEDED: "GET_FOLDERS_SUCCEDED",
    GET_USER_FOLDERS: "GET_USER_FOLDERS",
    GET_USER_FOLDERS_SUCCEEDED: "GET_USER_FOLDERS_SUCCEEDED",
    CREATE_FOLDER: "CREATE_FOLDER",
    CREATE_FOLDER_SUCCEDED: "CREATE_FOLDER_SUCCEDED",
    CREATE_BASIC_FOLDERS: "CREATE_BASIC_FOLDERS",
    CREATE_BASIC_FOLDERS_SUCCEDED: "CREATE_BASIC_FOLDERS_SUCCEDED",
    DELETE_FOLDER: "DELETE_FOLDER",
    DELETE_FOLDER_SUCCEDED: "DELETE_FOLDER_SUCCEDED",
    REORDER_FOLDERS: "REORDER_FOLDERS",
    REORDER_FOLDERS_SUCCEDED: "REORDER_FOLDERS_SUCCEDED",
    CREATE_DOCUMENT: "CREATE_DOCUMENT",
    CREATE_DOCUMENT_SUCCEDED: "CREATE_DOCUMENT_SUCCEDED",
    CREATE_DOCUMENT_ERROR: "CREATE_DOCUMENT_ERROR",
    DELETE_DOCUMENT: "DELETE_DOCUMENT",
    DELETE_DOCUMENT_SUCCEDED: "DELETE_DOCUMENT_SUCCEDED",
    UPDATE_DOCUMENT: "UPDATE_DOCUMENT",
    UPDATE_DOCUMENT_SUCCEDED: "UPDATE_DOCUMENT_SUCCEDED",
    REORDER_DOCUMENTS: "REORDER_DOCUMENTS",
    REORDER_DOCUMENTS_SUCCEDED: "REORDER_DOCUMENTS_SUCCEDED",
    UPLOAD_FILE: "UPLOAD_FILE",
    UPLOAD_FILE_SUCCEDED: "UPLOAD_FILE_SUCCEDED",
    UPDATE_FILE: "UPDATE_FILE",
    UPDATE_FILE_SUCCEDED: "UPDATE_FILE_SUCCEDED",
    UPDATE_DOCUMENT_FOLDER: "UPDATE_DOCUMENT_FOLDER",
    UPDATE_DOCUMENT_FOLDER_SUCCEEDED: "UPDATE_DOCUMENT_FOLDER_SUCCEEDED",
    DELETE_DOCUMENTS: "DELETE_DOCUMENTS",
    DELETE_DOCUMENTS_SUCCEEDED: "DELETE_DOCUMENTS_SUCCEEDED",
    DELETE_FILE: "DELETE_FILE",
    DELETE_FILE_SUCCEDED: "DELETE_FILE_SUCCEDED",
  };
  