import React, { useState } from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@mui/material";
import Clear from "@mui/icons-material/Clear";
import Add from "@mui/icons-material/Add";
import Email from "@mui/icons-material/Email";
import User from "../../../../../definitions/model/User";
import { LoadWrapper } from "../../../../../components/Base";
import { useUser } from "../../../../../actions/user.actions";

type AlarmRecipientsListProps = {
  recipients: User[];
  loading: boolean;
  showAddRecipient: () => void;
  subscribeToAlarm: (userId: string, landingName: string) => Promise<User>;
  unsubscribeFromAlarm: (userId: string) => Promise<User>;
};

const AlarmRecipientsList = (props: AlarmRecipientsListProps): JSX.Element => {
  const [{user}] = useUser();

  const [filter, setFilter] = useState("");
  const isSubscribed = user ? props.recipients.find((x) => x.Id === user.Id) !== null : false;

  const handleSubscribeClick = () => {
    if(!user) return;

    if (isSubscribed) {
      props.unsubscribeFromAlarm(user.Id);
    } else {
      props.subscribeToAlarm(user.Id, "");
    }
  };

  return (
    <Grid container justifyContent="center" spacing={2} style={{ margin: "24px 0" }}>
      <Grid item sm={8} xs={12} container justifyContent="space-between">
        <TextField
          margin="dense"
          fullWidth
          label="Search by email"
          variant="outlined"
          onChange={(e) => setFilter(e.currentTarget.value)}
        />
      </Grid>
      <Grid item sm={8} xs={12}>
        <Divider />
      </Grid>
      <Grid item sm={8} xs={12}>
        <LoadWrapper isLoading={props.loading} result={props.recipients}>
          <List style={{ width: "100%" }}>
            {props.recipients &&
              props.recipients
                .filter((x) => (x ? x.Email.includes(filter) : true))
                .map((user) => (
                  <React.Fragment key={user.Id}>
                    <ListItem>
                      <ListItemText
                        primary={`${user.Name} ${user.Email}`}
                        secondary={user.AlarmTarget}
                      />

                      <ListItemSecondaryAction>
                        <IconButton onClick={() => props.unsubscribeFromAlarm(user.Id)} size="large">
                          <Clear />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
          </List>
        </LoadWrapper>
      </Grid>
      <Grid item container justifyContent="flex-end" sm={8} xs={12}>
        <Grid item>
          <Button variant="contained" color="secondary" startIcon={<Add />} onClick={props.showAddRecipient}>
            Add recipient
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" startIcon={<Email />} onClick={handleSubscribeClick}>
            {isSubscribed ? "Unsubscribe from alarm" : "Subscribe to alarm"}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AlarmRecipientsList;
