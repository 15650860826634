import React from "react";
import BaseDialog from "../../../components/Dialogs/BaseDialog";
import { Button, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

type NeighborGroupsGuideDialogProps = {
  open: boolean;
  handleClose: (confirm?: boolean) => void;
};

const DocumentGuideDialog = (props: NeighborGroupsGuideDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <BaseDialog closeDialog={() => props.handleClose()} open={props.open}>
      <DialogTitle>
        <Typography sx={{ fontWeight: 600 }}>{t("Documents.GuideTitle")}</Typography>
      </DialogTitle>
      <DialogContent>
        {isXs ? (
          <Trans components={{ u: <u />, h3: <h3 />, ul: <ul />, li: <li /> }}>{t("Documents.GuideMobile")}</Trans>
        ) : (
          <Trans components={{ u: <u />, h3: <h3 />, ul: <ul />, li: <li /> }}>{t("Documents.GuideDesktop")}</Trans>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", marginBottom: "18px" }}>
        <Button onClick={() => props.handleClose()} color="primary" variant="outlined">
          {t("General.Buttons.Close")}
        </Button>
      </DialogActions>
    </BaseDialog>
  );
};

export default DocumentGuideDialog;
