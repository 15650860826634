import React from "react";
import {
  Grid,
  CardContent,
  Button,
  Alert,
  styled,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import PasswordField from "../../../components/Base/PasswordField";
import MCard from "../../../components/Base/MyhouseCard";
import { useTranslation } from "react-i18next";
import { TextFieldConnector } from "../../../components/Base/FormConnector";

type FormData = {
  newPassword: string;
  repeatedPassword: string;
};

type UserPasswordCardProps = {
  submit: (password: string) => void;
  loading: boolean;
  isChanged: boolean;
};

const ErrorMessageStyled = styled(Typography)(({ theme }) => ({
  marginBottom: "-27px",
  marginTop: "3px",
  fontSize: 13,

  [theme.breakpoints.down("md")]: {
    marginBottom: "-24px",
    marginTop: "2px",
    fontSize: 12,
  },
}));

const GridStyled = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    marginBottom: "10px",
  },
}));

const UserPasswordCard = (props: UserPasswordCardProps): JSX.Element => {
  const { t } = useTranslation("translation");

  const methods = useForm<FormData>({
    defaultValues: {
      newPassword: "",
      repeatedPassword: "",
    },
  });

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = methods;

  const submitHandler = (data: FormData) => {
    props.submit(data.newPassword);
  };

  return (
    <MCard
      type="secondary"
      title={t("UserProfile.NewPassword")}
      showProgress={props.loading}
    >
      <CardContent>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Grid container justifyContent="space-between">
            <GridStyled item xs={12} sm={5}>
              <TextFieldConnector
                register={register("newPassword", {
                  required: {
                    value: true,
                    message: t("General.Errors.ErrorRequired"),
                  },
                  minLength: {
                    value: 5,
                    message: t(
                      "Authorization.Errors.NecessaryQuantityOfCharacters"
                    ),
                  },
                })}
              >
                <PasswordField
                  sx={{ marginTop: 2 }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  label={t("UserProfile.NewCode")}
                  error={!!errors.newPassword}
                />
              </TextFieldConnector>
              {errors.newPassword && (
                <ErrorMessageStyled color="error">
                  {errors.newPassword.message}
                </ErrorMessageStyled>
              )}
            </GridStyled>
            <Grid item xs={12} sm={5}>
              <TextFieldConnector
                register={register("repeatedPassword", {
                  validate: (value) =>
                    value === getValues().newPassword ||
                    !!t("General.Errors.ErrorDoNotMatch"),
                  required: {
                    value: true,
                    message: t("General.Errors.ErrorRequired"),
                  },
                  minLength: {
                    value: 5,
                    message: t(
                      "Authorization.Errors.NecessaryQuantityOfCharacters"
                    ),
                  },
                })}
              >
                <PasswordField
                  sx={{ marginTop: 2 }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  label={t("UserProfile.RepeatCode")}
                  error={!!errors.repeatedPassword}
                  disabled={props.loading}
                />
              </TextFieldConnector>

              {errors.repeatedPassword && (
                <ErrorMessageStyled color="error">
                  {errors.repeatedPassword.message}
                </ErrorMessageStyled>
              )}
            </Grid>
          </Grid>
          <Grid
            sx={{ marginTop: 4 }}
            item
            container
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Button
              sx={{ maxWidth: "300px" }}
              fullWidth
              variant="contained"
              color="secondary"
              type="submit"
            >
              {t("General.Buttons.Save")}
            </Button>
          </Grid>
        </form>
        {props.isChanged && (
          <Alert
            sx={{
              marginBottom: "-55px",
              marginTop: "7px",
              justifyContent: "center",
            }}
            severity="success"
          >
            {t("Base.Dialog.PasswordChanged")}
          </Alert>
        )}
      </CardContent>
    </MCard>
  );
};

export default UserPasswordCard;
