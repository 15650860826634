import React, { useState, useEffect, useContext } from "react";
import { Chip, Grid, IconButton, Typography, useMediaQuery, useTheme, styled } from "@mui/material";
import { CO2EmissionSourceGroup, CO2EconomyItem } from "../../CO2Plan";
import { GridColDef, GridRenderCellParams, GridRowModel, GridValueGetterParams } from "@mui/x-data-grid";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import { GetColor, GetIcon } from "../../co2-helpers";
import { useTranslation } from "react-i18next";
import StyledOneClickDataGrid from "./OneClickStyledDataGrid";
import { GetEconomyData } from "../../co2-helpers";
import CircularProgressButton from "../../../../../components/Base/CircularProgressButton";
import { CO2Context } from "../CO2ContextWrapper";
import { useCO2 } from "../../co2plan.actions";

const StyledChip = styled(Chip)(() => ({
  backgroundColor: "transparent",
  width: "100%",
  color: "unset",
  justifyContent: "flex-start",
  fontSize: "inherit",
  "& > span": {
    padding: 0,
    minWidth: "fit-content",
  },
}));

type CO2TargetSurveyEconomyTableProps = {
  targetGroups: CO2EmissionSourceGroup[];
  initialGroups: CO2EmissionSourceGroup[];
  editable?: boolean;
};

type CO2EconomyRow = {
  id: string;
  personId: string | null;
  name: string;
  initialCost: number;
  targetCost: number;
  savedCost: number;
  economyItems: CO2EconomyItem[];
};

export default function CO2TargetSurveyEconomyTable(props: CO2TargetSurveyEconomyTableProps): JSX.Element {
  const context = useContext(CO2Context);
  const [{ co2Plan }] = useCO2();
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const [economyRows, setEconomyRows] = useState<Array<CO2EconomyRow>>([]);
  const [loadingItemId, setLoadingItemId] = useState("");

  function getChipProps(params: GridRenderCellParams) {
    const chipBody = {
      icon:
        economyRows.length > 1 ? (
          <img
            src={GetIcon(params.value)}
            key={`eco_${params.id}`}
            alt=""
            style={{
              maxWidth: 20,
              margin: "0 4px 0 0",
              borderRadius: 5,
              backgroundColor: GetColor(params.value),
            }}
          />
        ) : undefined,
      label: !isXs ? t(params.value) : undefined,
    };
    return chipBody;
  }

  const valueFixer = (id: string, checkId: string, cost: any): any => {
    return id === checkId || !cost ? "" : Number(cost.toFixed(0)) === 0 ? "" : Number(cost.toFixed(0));
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("ClimatePlan.DKK")}`,
      minWidth: isXs ? 32 : 80,
      width: isXs ? 36 : 80,
      maxWidth: isXs ? 40 : 130,
      flex: 1,
      hideable: economyRows.length < 2,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <StyledChip {...getChipProps(params)} />;
      },
    },
    {
      field: "initialCost",
      headerName: `${t("ClimatePlan.General.Today")}`,
      minWidth: 70,
      type: "number",
      flex: 1,
      editable: props.editable,
      sortable: false,
      valueGetter: (p: GridValueGetterParams) => valueFixer(p.row.id, "total-row-id", p.row.initialCost),
    },
    {
      field: "targetCost",
      headerName: `${t("ClimatePlan.General.Future")}`,
      minWidth: 70,
      type: "number",
      flex: 1,
      sortable: false,
      editable: props.editable,
      valueGetter: (p: GridValueGetterParams) =>
        //p.row.targetCost could be null
        valueFixer(p.row.id, "total-row-id", p.row.targetCost),
    },
    {
      field: "savedCost",
      headerName: `${t("ClimatePlan.General.Savings")}`,
      minWidth: 80,
      type: "number",
      flex: 1,
      sortable: false,
      valueGetter: (p: GridValueGetterParams) => (p.row.savedCost > 0 ? Number(p.row.savedCost.toFixed(0)) : "~"),
    },
    {
      field: "economyItems",
      minWidth: 70,
      type: "actions",
      headerName: "",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<CO2EconomyRow>) => {
        return params.value?.map(
          (item: CO2EconomyItem) =>
            item.ExternalLink?.length > 0 && (
              <CircularProgressButton
                style={{ padding: "2px 8px" }}
                size="small"
                key={`eco_${item.Id}`}
                variant="contained"
                inProgress={loadingItemId === item.Id}
                color="success"
                onClick={() => externalClick(item, params.row.personId)}
                strictWidth={true}
              >
                {/* {item.DisplayName} */}
                {t("ClimatePlan.Buttons.GetStarted")}
              </CircularProgressButton>
            )
        );
      },
    },
  ];

  // we can not use async method because it can cause errors in Safari

  const externalClick = (item: CO2EconomyItem, personId: string | null) => {
    setLoadingItemId(item.Id);
    const newWindow = window.open("/landing-loader", "_blank");

    // check if table has total row and save emission survey only then it is not and current table is not summary table
    const saveEmissionSurveyHandler = new Promise((resolve, reject) => {
      resolve(!economyRows.find((x) => x.id === "total-row-id"));
    });

    saveEmissionSurveyHandler
      .then((value) => {
        if (value) {
          const saveEmission = context.saveEmissionSurvey(
            co2Plan?.InitialSurvey || null,
            co2Plan?.TargetSurvey || null,
            co2Plan?.CustomQuestions || null,
            co2Plan?.Id,
            context.unit?.Id,
            context.isMultiSelect
          );
          return saveEmission;
        }
      })
      .then(() => {
        if (context.user) {
          context.registerCO2EconomyItemLinkClick(item.EmissionSourceGroupId, item.Id, context.planId);

          let link = `${item.ExternalLink}?closeOnBack=true`;

          if (personId) {
            link = `${link}&co2plan_personid=${personId}`;
          }

          if (newWindow) {
            newWindow.location.replace(link);
          }
        }
      })
      .catch(() => {
        newWindow?.close();
      })
      .finally(() => {
        setLoadingItemId("");
      });
  };

  useEffect(() => {
    let index = 0;
    const rows: Array<CO2EconomyRow> = [];
    for (const targetGroup of props.targetGroups) {
      const row = GetEconomyRow(targetGroup, props.initialGroups[index++]);
      if (row) rows.push(row);
    }

    if (rows.length > 1) {
      let sum = 0;

      for (const row of rows) {
        sum += row.savedCost;
      }

      rows.push({
        id: "total-row-id",
        personId: null,
        name: "Total",
        initialCost: 0,
        targetCost: 0,
        savedCost: sum > 0 ? sum : 0,
        economyItems: [],
      });
    }

    setEconomyRows(rows);
  }, [props.targetGroups, props.initialGroups]);

  const GetEconomyRow = (
    activeGroup: CO2EmissionSourceGroup,
    initialGroup: CO2EmissionSourceGroup
  ): CO2EconomyRow | null => {
    const { personsActiveSourceCost, personsInitialSourceCost, economyItems } = GetEconomyData(
      activeGroup,
      initialGroup
    );

    const savedCost = personsInitialSourceCost - personsActiveSourceCost;

    //if activeGroup.EconomyItems is empty - we should hide item for current commune
    if (activeGroup.EconomyItems?.length > 0 && (personsInitialSourceCost || personsActiveSourceCost))
      return {
        id: activeGroup.Id,
        personId: activeGroup.PersonEmissionSources.length === 1 ? activeGroup.PersonEmissionSources[0].PersonId : null,
        name: activeGroup.ShortName,
        initialCost: personsInitialSourceCost,
        targetCost: personsActiveSourceCost,
        savedCost: savedCost > 0 ? savedCost : 0,
        economyItems: savedCost > 0 ? economyItems : [],
      };

    return null;
  };

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    if (newRow.targetCost !== oldRow.targetCost) {
      context.setEconomyCost(newRow.id.toString(), newRow.personId, newRow.targetCost, true, true);
    }

    if (newRow.initialCost !== oldRow.initialCost) {
      context.setEconomyCost(newRow.id.toString(), newRow.personId, newRow.initialCost, true, false);
    }

    return newRow;
  };

  return (
    <Grid item container justifyContent="center">
      <Grid item container xs={12} spacing={"auto"}>
        {economyRows && economyRows.length > 0 && (
          <>
            <Grid container alignItems="center">
              <Grid item ml={-0.8}>
                <IconButton size="small" href="/validation" target="_blank">
                  <InfoTwoToneIcon fontSize="medium" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {economyRows.length > 1 ? t("ClimatePlan.EconomyHousehold") : t("ClimatePlan.DKK")}
                </Typography>
              </Grid>
            </Grid>
            <StyledOneClickDataGrid
              rows={economyRows}
              columns={columns}
              isCellEditable={(params) => params.row.id !== "total-row-id"}
              hideFooter
              autoHeight
              disableColumnMenu
              density="compact"
              sx={{
                "& .MuiDataGrid-columnHeaderTitle, & [data-id='total-row-id']": {
                  fontFamily: "CircularXX-Bold",
                },
              }}
              processRowUpdate={processRowUpdate}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
}
