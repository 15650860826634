import { Dispatch } from "redux";
import { AppAction, AppActionThunk } from "../definitions/Action";
import { MapCoords, WindowPosition, LocalPlanData } from "../definitions/Map";
import { MapActionTypes } from "../constants/map.actiontypes";
import MapService from "../services/map.service";
import { RiskModel } from "../definitions/model/unit";
import { operationFailedActionGeneral, useAppDispatch } from ".";
import { useSelector } from "react-redux";
import { ApplicationState } from "../reducers/store";
import useSupercluster from "use-supercluster";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, MapActionTypes.MAP_OPERATION_FAILED);
};

export const getLocalPlans = (unitId: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: MapActionTypes.GET_PLANS,
    });
    const result = await MapService.getPlans(unitId);
    dispatch({
      type: MapActionTypes.GET_PLANS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getCrimeInfo =
  (coords: MapCoords, windowPosition: WindowPosition) => async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({
        type: MapActionTypes.GET_CRIME_INFO,
      });
      const result = await MapService.getLayerInfo(coords.lat(), coords.lng(), "stats/crime");
      dispatch({
        type: MapActionTypes.GET_CRIME_INFO_SUCCEEDED,
        payload: { data: result, position: windowPosition },
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getFloodInfo = (floodLevel: number, windowPosition: WindowPosition) => (dispatch: Dispatch<AppAction>) => {
  dispatch({
    type: MapActionTypes.GET_FLOOD_INFO_SUCCEEDED,
    payload: { data: floodLevel, position: windowPosition },
  });
};

export const getPlanInfo = (data: LocalPlanData, windowPosition: WindowPosition) => (dispatch: Dispatch<AppAction>) => {
  dispatch({
    type: MapActionTypes.GET_PLAN_INFO_SUCCEEDED,
    payload: { data: data, position: windowPosition },
  });
};

export const getNoiseInfo =
  (coords: MapCoords, windowPosition: WindowPosition) => async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({
        type: MapActionTypes.GET_NOISE_INFO,
      });
      const result = await MapService.getLayerInfo(coords.lat(), coords.lng(), "noise");
      dispatch({
        type: MapActionTypes.GET_NOISE_INFO_SUCCEEDED,
        payload: { data: result, position: windowPosition },
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getGroundInfo =
  (coords: MapCoords, windowPosition: WindowPosition) => async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({
        type: MapActionTypes.GET_GROUND_INFO,
      });
      const result = await MapService.getLayerInfo(coords.lat(), coords.lng(), "ground");
      dispatch({
        type: MapActionTypes.GET_GROUND_INFO_SUCCEEDED,
        payload: { data: result, position: windowPosition },
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getRadonInfo =
  (coords: MapCoords, windowPosition: WindowPosition) => async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({
        type: MapActionTypes.GET_RADON_INFO,
      });
      const result = await MapService.getLayerInfo(coords.lat(), coords.lng(), "radon");
      dispatch({
        type: MapActionTypes.GET_RADON_INFO_SUCCEEDED,
        payload: { data: result, position: windowPosition },
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getSoldUnits = (unitId: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: MapActionTypes.GET_SOLD_UNITS,
    });
    const result = await MapService.getSoldUnits(unitId);
    dispatch({
      type: MapActionTypes.GET_SOLD_UNITS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getEstimationUnits = (unitId: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: MapActionTypes.GET_ESTIMATION_UNITS,
    });
    const result = await MapService.getEstimationUnits(unitId);
    dispatch({
      type: MapActionTypes.GET_ESTIMATION_UNITS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getBusStations = (params: any) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: MapActionTypes.GET_BUS_STATIONS,
    });
    const result = await MapService.getBusStations(params);
    dispatch({
      type: MapActionTypes.GET_BUS_STATIONS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getMobileTowers = (params: any) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: MapActionTypes.GET_MOBILE_TOWERS,
    });
    const result = await MapService.getMobileTowers(params);
    dispatch({
      type: MapActionTypes.GET_MOBILE_TOWERS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getGymnasiums = (params: any) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: MapActionTypes.GET_GYMNASIUMS,
    });
    const result = await MapService.getGymnasiums(params);
    dispatch({
      type: MapActionTypes.GET_GYMNASIUMS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getSchools = (params: any) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: MapActionTypes.GET_SCHOOLS,
    });
    const result = await MapService.getGymnasiums(params);
    dispatch({
      type: MapActionTypes.GET_SCHOOLS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getEcoBusinesses = (params: any) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: MapActionTypes.GET_ECOBUSINESSES,
    });
    const response = await MapService.getEcoBusinesses(params);
    dispatch({
      type: MapActionTypes.GET_ECOBUSINESSES_SUCCEEDED,
      payload: response,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const hideEcoBusinessAds = (params: any) => async (dispatch: Dispatch<AppAction>) => {
  dispatch({
    type: MapActionTypes.HIDE_ECOBUSINESS_ADS,
  });
};

export const getMobileTowerInfo = (id: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: MapActionTypes.GET_MOBILE_TOWER_INFO,
    });
    const result = await MapService.getMobileTowerInfo(id);
    dispatch({
      type: MapActionTypes.GET_MOBILE_TOWER_INFO_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getGymnasiumInfo = (id: string, unitId: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: MapActionTypes.GET_GYMNASIUM_INFO,
    });
    const result = await MapService.getGymnasiumInfo(id, unitId);
    dispatch({
      type: MapActionTypes.GET_GYMNASIUM_INFO_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getEcoBusinessInfo = (id: string,  longitude: number, latitude: number) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: MapActionTypes.GET_ECOBUSINESS_INFO,
    });
    const result = await MapService.getEcoBusinessInfo(id, longitude, latitude);
    dispatch({
      type: MapActionTypes.GET_ECOBUSINESS_INFO_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getNeighbors = (id: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: MapActionTypes.GET_NEIGHBORS,
    });
    const result = await MapService.getNeighbors(id);
    dispatch({
      type: MapActionTypes.GET_NEIGHBORS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getRoadPlans = (id: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: MapActionTypes.GET_ROAD_PLANS,
    });
    const result = await MapService.getRoadPlans(id);
    dispatch({
      type: MapActionTypes.GET_ROAD_PLANS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getUnitRisks =
  (unitId: string): AppActionThunk<Promise<RiskModel>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: MapActionTypes.GET_UNIT_RISK_FLAGS,
      });

      const result = await MapService.getUnitRisks(unitId);
      dispatch({
        type: MapActionTypes.GET_UNIT_RISK_FLAGS_SUCCEEDE,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const useMapState = () => useSelector((state: ApplicationState) => state.map);

const useMapActions = () => {
  const dispatch = useAppDispatch();

  return {
    getCrimeInfo: (coords: MapCoords, windowPosition: WindowPosition) => dispatch(getCrimeInfo(coords, windowPosition)),
    getFloodInfo: (floodLevel: number, windowPosition: WindowPosition) =>
      dispatch(getFloodInfo(floodLevel, windowPosition)),
    getPlanInfo: (data: LocalPlanData, windowPosition: WindowPosition) => dispatch(getPlanInfo(data, windowPosition)),
    getNoiseInfo: (coords: MapCoords, windowPosition: WindowPosition) => dispatch(getNoiseInfo(coords, windowPosition)),
    getGroundInfo: (coords: MapCoords, windowPosition: WindowPosition) =>
      dispatch(getGroundInfo(coords, windowPosition)),
    getRadonInfo: (coords: MapCoords, windowPosition: WindowPosition) => dispatch(getRadonInfo(coords, windowPosition)),
    getLocalPlans: (unitId: string) => dispatch(getLocalPlans(unitId)),
    getSoldUnits: (unitId: string) => dispatch(getSoldUnits(unitId)),
    getEstimationUnits: (unitId: string) => dispatch(getEstimationUnits(unitId)),
    getNeighbors: (id: string) => dispatch(getNeighbors(id)),
    getRoadPlans: (id: string) => dispatch(getRoadPlans(id)),
    getBusStations: (params: any) => dispatch(getBusStations(params)),
    getMobileTowers: (params: any) => dispatch(getMobileTowers(params)),
    getGymnasiums: (params: any) => dispatch(getGymnasiums(params)),
    getSchools: (params: any) => dispatch(getSchools(params)),
    getEcoBusinesses: (params: any) => dispatch(getEcoBusinesses(params)),
    hideEcoBusinessesAds: (params: any) => dispatch(hideEcoBusinessAds(params)),
    getMobileTowerInfo: (id: string) => dispatch(getMobileTowerInfo(id)),
    getGymnasiumInfo: (id: string, unitId: string) => dispatch(getGymnasiumInfo(id, unitId)),
    getEcoBusinessInfo: (id: string,  longitude: number, latitude: number) => dispatch(getEcoBusinessInfo(id, longitude, latitude)),
  };
};

export const useMap = (): [ReturnType<typeof useMapState>, ReturnType<typeof useMapActions>] => {
  const state = useMapState();
  const actions = useMapActions();

  return [state, actions];
};

const clusterOptions = {
  radius: 135,
  maxZoom: 17,
  map: (props: any) => ({
    count: props.point_count,
  }),
  reduce: (acc: any, props: any) => {
    acc.count += props.count;
    return acc;
  },
};

export const useCustomClusters = (items: any, bounds: any, zoom: number, metadata?: any) => {
  const points = items.map((item: any, i: number) => ({
    type: "Feature",
    id: i,
    properties: {
      cluster: item.Count > 1,
      point_count: item.Count,
      point_count_abbreviated: item.Count,
      item, //: { ...item, C: item.Count, I: item.MarkerId },
      metadata,
    },
    geometry: {
      type: "Point",
      coordinates: [item.X, item.Y],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds: bounds,
    zoom: zoom,
    options: clusterOptions,
  });

  return { clusters, supercluster };
};
