import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { UpdatePreditcionContract, PricePredictionModel } from "../defenitions";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";
import {styled} from "@mui/material";

const Form = styled("form")(({theme})=>({
  width: "100%",
  border: `solid ${theme.palette.grey[300]} 1px`,
  padding: theme.spacing(1, 0),
  boxSizing: "border-box",
}));

const DatePicker = (props: TextFieldProps) => (
  <TextField
    {...props}
    fullWidth
    variant="outlined"
    size="small"
    type="date"
    InputProps={{ notched: true }}
    InputLabelProps={{ disableAnimation: true, shrink: true }}
  />
);

const formatDate = (date: Date | string | number | null) =>
  date ? format(new Date(date), "yyyy-MM-dd") : "";

type EditPredictionFormProps = {
  prediction: PricePredictionModel | null;
  onSubmit: (
    data: UpdatePreditcionContract
  ) => Promise<PricePredictionModel | null>;
  onHide: () => void;
};

const EditPredictionForm = (props: EditPredictionFormProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const methods = useForm<UpdatePreditcionContract>({
    defaultValues: {
      Id: props.prediction?.Id,
      Name: props.prediction?.Name,
      Created: formatDate(props.prediction?.Created ?? null),
    },
  });
  const { handleSubmit, register, control, reset } = methods;

  const submit = (data: UpdatePreditcionContract) => {
    props.onSubmit(data).then(() => {
      reset();
    });
  };

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={11}>
          <Typography variant="h5">Edit Prediction model</Typography>
        </Grid>
        <Grid item xs={11}>
          <TextFieldConnector register={register("Id", {required: true})}>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              label="Id"
              disabled={true}
            />
          </TextFieldConnector>
        </Grid>
        <Grid item xs={11}>
          <TextFieldConnector register={register("Name", {required: true})}>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              label="Group Name"
            />
          </TextFieldConnector>
        </Grid>
        <Grid item xs={11}>
          <Controller
            name="Created"
            control={control}
            render={({ field: {ref, ...rest} }) => (
              <DatePicker 
                label="Created:" 
                {...rest}
              />
            )}
          />
        </Grid>
        <Grid item xs={11} container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={props.onHide}
            >
              {t("General.Buttons.Close")}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" type="submit">
              {t("General.Buttons.Save")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default EditPredictionForm;
