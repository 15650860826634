import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import Autocomplete, { AutocompleteChangeReason, AutocompleteChangeDetails } from "@mui/material/Autocomplete";
import {
  TextField,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
  Card,
  styled,
  Theme,
  GridProps,
  TextFieldProps,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { AutocompleteItem, AutocompleteResultType } from "../../definitions/AutocompleteItem";
import AddressHelper from "../../helpers/address-helper";
import ApiService from "../../services/api.service";
import { useNavigate } from "react-router-dom";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import { SxProps } from "@mui/system";

const autocompleteSx = (theme: Theme) => ({
  "& input::placeholder": {
    opacity: 0.8,
  },
  width: "100%",
  "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
    paddingRight: "10px",
    paddingTop: "4px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "5px",
    },
  },
});

type DisabledChildrensProps = GridProps & {
  loading: boolean;
};

const DisabledChildrensGrid = styled(Grid, {
  shouldForwardProp: (propName) => propName !== "loading",
})<DisabledChildrensProps>(({ theme, loading }) => ({
  paddingTop: "15px",
  ["& button, & button:hover"]: {
    cursor: loading ? "not-allowed" : "pointer",
    backgroundColor: loading ? theme.palette.grey[100] : theme.palette.primary,
  },
}));

export type AutocompleteStyles = {
  color: string;
  background: string;
};

type AutoCompleteProps = {
  id?: string;
  placeholder: string;
  className?: string;
  gjsStyles?: AutocompleteStyles;
  size?: "small" | "medium";
  setValueCallback?(value: string): void;
  children?: React.ReactNode;
  inputValue?: string;
  loading?: boolean;
  sx?: SxProps<Theme>;
  variant?: "outlined" | "filled" | "standard";
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  isTouchedHandler?: React.Dispatch<React.SetStateAction<boolean>>;
  buildingSearch?: boolean;
  searchTargetType?: AutocompleteResultType;
};

type AutoCompleteFormData = {
  Property: AutocompleteItem;
};

const AutoCompleteComponent = (props: AutoCompleteProps): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const targetType = props.searchTargetType ?? AutocompleteResultType.House;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<AutocompleteItem[]>([]);
  const [value, setAutoCompleteValue] = useState<AutocompleteItem | null | undefined>();
  const [inputValue, setInputValue] = useState<string>(props.inputValue ?? "");
  const [loading, setLoading] = useState(false);
  const [slowLoading, setSlowLoading] = useState(false);
  const [slowLoadingTimer, setSlowLoadingTimer] = useState<NodeJS.Timeout | null>(null);
  const slowLoadingTimeout = 10000;

  const controllerRef = useRef<AbortController | null>();
  const formMethods = useForm<AutoCompleteFormData>({
    defaultValues: {},
  });
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = formMethods;

  React.useEffect(() => {
    if (value?.Type === targetType) {
      setValue("Property", value);
    } else {
      reset();
    }
  }, [value, setValue]);

  React.useEffect(() => {
    register("Property", { required: true });
    return () => unregister("Property"); // unregister input after component unmount
  }, [register, unregister]);

  React.useEffect(() => {
    if (props.inputValue) {
      fetchOptions(props.inputValue, props.inputValue?.length, false);
    }
  }, [props.inputValue]);

  React.useEffect(() => {
    setSlowLoading(false);
    if (loading) {
      if (slowLoadingTimer) {
        clearTimeout(slowLoadingTimer);
      }
      const timer = setTimeout(() => setSlowLoading(true), slowLoadingTimeout);
      setSlowLoadingTimer(timer);
    } else {
      if (slowLoadingTimer) {
        clearTimeout(slowLoadingTimer);
      }
    }
  }, [loading]);

  const submit = (data: AutoCompleteFormData) => {
    if (data && data.Property) {
      ProcessResultProperty(data.Property, true);
    }
  };

  async function fetchOptions(val: string, cursorPosition: number, startFromBuildings: boolean) {
    if (val.length <= 3) {
      setAutoCompleteValue(null);
      setOptions([]);
      setOpen(false);
      return;
    }

    if (props.isTouchedHandler) {
      props.isTouchedHandler(true);
    }

    try {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = new AbortController();

      setLoading(true);
      const request = `${
        process.env.REACT_APP_MYHOUSE_API_URL
      }api/autocomplete?text=${val}&cursorPosition=${cursorPosition}&startFromBuildings=${startFromBuildings}&searchBuilding=${
        props.buildingSearch ?? false
      }`;

      const parsedOptions = await ApiService.get<AutocompleteItem[]>(request, null, controllerRef.current?.signal);
      setOptions(parsedOptions);

      const housesFound = parsedOptions.filter((option: AutocompleteItem) => option.Type === targetType);
      if (housesFound.length === 1 && parsedOptions.length === 1) {
        //!housesFound[0].Text.includes(val) - prevent autoselection when user try to edit seach text
        // optimize the flow and save a click for user in cases when there is 1 option left
        if (!housesFound[0].Text.includes(val) || housesFound[0].Text === val) {
          setInputValue(housesFound[0].Text);
          ProcessAutocompleteChange(housesFound[0]);
          setOpen(false);
        }
      }

      setLoading(false);
      controllerRef.current = null;
    } catch (e) {
      console.log(e, "error");
      setLoading(false);
    }
  }

  function MarkOptionSelected(option: AutocompleteItem, oldValue: AutocompleteItem) {
    if (!(option instanceof AutocompleteItem) || !(oldValue instanceof AutocompleteItem)) return false;

    return option.Text === oldValue.Text;
  }

  const getOptionLabel = (o: AutocompleteItem | string) => {
    if (typeof o === "object") {
      return o.Text;
    }
    return o;
  };

  const RenderOption = (props: any, option: AutocompleteItem | string) => {
    if (typeof option === "string") {
      return;
    }

    return (
      <Grid container direction="row" {...props}>
        <Grid item xs={11}>
          <Typography noWrap>{option.Text}</Typography>
        </Grid>
        <Grid item xs={1}>
          {option.Type !== targetType && <ArrowDownward color="action" fontSize="small" />}
        </Grid>
      </Grid>
    );
  };

  function OnEnterPress(e: any) {
    if (e.key.toLowerCase() === "enter" && value) {
      ProcessResultProperty(value!, true);
    }
  }

  function OnAutocompleteChange(
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    val: string | AutocompleteItem,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<AutocompleteItem> | undefined
  ) {
    // this function is called when user clicks on an item in option list, that's why val is always an AutocompleteItem
    ProcessAutocompleteChange(val as AutocompleteItem);
  }

  function ProcessAutocompleteChange(item: AutocompleteItem) {
    // this function is triggered when user pick item form autocomlete list
    if (item && item.Text) {
      setAutoCompleteValue(item);

      if (item.Type === targetType) {
        setOpen(false);
        if (!props.children) {
          ProcessResultProperty(item);
        }
      } else {
        if (typeof props.setValueCallback === "function") {
          //reset value
          props.setValueCallback("");
        }
        fetchOptions(item.Text, item.CursorPosition, true);
      }
    }
  }

  function OnInput(event: any, val: string, reason: string) {
    //reason === 'reset' - when user pick item form autocomlete list
    if (reason !== "reset") {
      if (value && typeof props.setValueCallback === "function") {
        //reset value on parent component
        props.setValueCallback("");
      }
      //reset existing value to mark input as invalid
      setAutoCompleteValue(null);
      fetchOptions(val, val.length, false);
    }
  }

  async function ProcessResultProperty(val: AutocompleteItem, redirect = false) {
    if (typeof props.setValueCallback === "function") {
      //reset value to trigger work of handlers
      props.setValueCallback("");
      setTimeout(() => {
        let value = val.UnitId;
        if (targetType === AutocompleteResultType.Building) value = val.BuildingId;
        if (props.setValueCallback) props.setValueCallback(value);
      }, 100);
    } else if (!props.children || redirect) {
      const url = AddressHelper.GetAutocompleteAddressUrl(val) + "/search";
      navigate(url);
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(submit)} id="AutocompleteFormId">
        <Grid item container>
          <Autocomplete
            PaperComponent={({ children }) => (
              <Card
                sx={(theme) => ({
                  borderRadius: theme.shape.borderRadius + "px",
                  marginTop: "2px",
                })}
              >
                {children as React.ReactNode}
              </Card>
            )}
            id={props.id}
            freeSolo
            forcePopupIcon={false}
            disableCloseOnSelect={true}
            disableClearable
            sx={autocompleteSx}
            open={open}
            value={inputValue}
            onChange={OnAutocompleteChange}
            onKeyPress={OnEnterPress}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onInputChange={OnInput}
            isOptionEqualToValue={MarkOptionSelected}
            getOptionLabel={getOptionLabel}
            filterOptions={(x) => x}
            options={options}
            renderOption={RenderOption}
            loading={loading}
            loadingText={slowLoading ? t("Autocomplete.SlowLoading") : t("Autocomplete.Loading")}
            renderInput={(params) => (
              <TextField
                {...(params as TextFieldProps)}
                placeholder={props.placeholder}
                fullWidth
                variant={props.variant ? props.variant : "outlined"}
                color={props.color}
                // variant='outlined'
                size={props.size || "small"}
                InputProps={{
                  sx: {
                    ...(props.sx ? props.sx : {}),
                    ".MuiOutlinedInput-notchedOutline": {
                      width: "100%",
                      paddingLeft: "0 !important",
                      boxSizing: "border-box",
                      border: "solid 1px",
                    },
                  },
                  ...params.InputProps,
                  style: props.gjsStyles,
                  className: `${errors.Property ? "Mui-error Mui-error" : ""} ${props.className} ${
                    params.InputProps.className
                  }`,
                  endAdornment: (
                    <React.Fragment>
                      {loading && (
                        <CircularProgress style={{ position: "absolute", right: 5 }} color="inherit" size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <DisabledChildrensGrid loading={!!props.loading} item>
          {props.children}
        </DisabledChildrensGrid>
        {props.loading && <LinearProgress color="secondary" sx={{ marginTop: 1 }} />}
      </form>
    </>
  );
};

export default AutoCompleteComponent;
