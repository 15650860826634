import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton,
  Tooltip,
  Fab,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { CO2SponsorSolution } from "../../../../ClimatePlan/shared/CO2Plan";
import { Add, Delete, Edit } from "@mui/icons-material";
import ConfirmDialog from "../../../../../components/Dialogs/ConfirmDialog";
import SponsorSolutionCreateEditDialog from "./SponsorSolutionCreateEditDialog";
import { useTranslation } from "react-i18next";
import { useAdminCO2 } from "../../../../../actions/admin/admin.co2plan.actions";

const SponsorSolutions = () => {
  const [{ loading, CO2SponsorSolutions: solutions, CO2SponsorSolutionsCount: solutionsCount }, adminCo2Actions] =
    useAdminCO2();
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(0);
  const [solutionToEdit, setSolutionToEdit] = useState<CO2SponsorSolution | null>(null);
  const [showCreationDialog, setShowCreationDialog] = useState(false);

  const { t } = useTranslation("translation");

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(0); // reset page number, because there migh be no results otherwise
  };

  useEffect(() => {
    const skip = pageNumber * pageSize;
    adminCo2Actions.getCO2SponsorSolutions(skip, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize]);

  useEffect(() => {
    if (solutions.length === 0 && solutionsCount > 0) {
      const skip = pageNumber * pageSize;
      adminCo2Actions.getCO2SponsorSolutions(skip, pageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solutions]);

  return (
    <>
      {!loading && (
        <Grid container>
          <TableContainer
            sx={{
              overflowY: "auto",
              width: "100%",
              height: "70vh",
              padding: 0,
            }}
          >
            <Table size="small" stickyHeader style={{ position: "relative" }}>
              <TableHead>
                <TableRow>
                  <TableCell>Solution</TableCell>
                  <TableCell>Group</TableCell>
                  <TableCell>Company</TableCell>
                  <TableCell align="right">Edit</TableCell>
                  <TableCell align="right">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {solutions.map((solution) => (
                  <TableRow hover key={solution.Id}>
                    <TableCell>{solution.DisplayName}</TableCell>
                    <TableCell>{t(solution.GroupDescription?.ShortName) ?? solution.EmissionSourceGroupId}</TableCell>
                    <TableCell>{solution.CompanyName ?? solution.CompanyId}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => {
                          setSolutionToEdit(solution);
                        }}
                        size="large"
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                      <ConfirmDialog
                        text="Are you shure you want to delete this solution? If users used it in their climate plans it will be removed from their plans"
                        OkClickHandler={() => adminCo2Actions.deleteCO2SponsorSolutions(solution.Id)}
                      >
                        <Tooltip title="Delete solution">
                          <IconButton size="large">
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </ConfirmDialog>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 30, 50]}
                    count={solutionsCount}
                    rowsPerPage={pageSize}
                    page={pageNumber}
                    SelectProps={{
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

          <Grid container item justifyContent="flex-end">
            <Tooltip title="Add company">
              <Fab
                color="secondary"
                onClick={() => {
                  setShowCreationDialog(true);
                  setSolutionToEdit(null);
                }}
              >
                <Add />
              </Fab>
            </Tooltip>
          </Grid>
        </Grid>
      )}
      {loading && (
        <Grid
          container
          sx={{
            overflowY: "auto",
            width: "100%",
            height: "60vh",
            padding: 0,
          }}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      )}
      <SponsorSolutionCreateEditDialog
        open={!!solutionToEdit || showCreationDialog}
        solution={solutionToEdit}
        handleClose={() => {
          setSolutionToEdit(null);
          setShowCreationDialog(false);
        }}
      />
    </>
  );
};

export default SponsorSolutions;
