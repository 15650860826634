import React, { useEffect, useState, useMemo } from "react";
import { Grid, CircularProgress, Button, Chip } from "@mui/material";
import { CO2PlanMeta } from "../../../../ClimatePlan/shared/CO2Plan";
import { Add } from "@mui/icons-material";
import { AdminTable, Column, Icon } from "../../../components/AdminTable/AdminTable";
import CO2PlansMetaItemCreation from "../CO2PlansMetaItemCreation";
import { useAdminCO2 } from "../../../../../actions/admin/admin.co2plan.actions";
import { useAdmin } from "../../../../../actions/admin/admin.actions";

const CO2PlanMetaItems = () => {
  const [co2PlanMetaItemToEdit, setCO2PlanItemToEdit] = useState<CO2PlanMeta | null>(null);
  const [showCreationDialog, setShowCreationDialog] = useState(false);
  const [{ loading, CO2PlanMetaItems, CO2EmissionSourceGroups: groups }, adminCo2Actions] = useAdminCO2();
  const [adminState, { getCommunes }] = useAdmin();
  const columns: Column<CO2PlanMeta>[] = useMemo(
    () => [
      {
        name: "Name",
        accessor: "DisplayName",
      },
      {
        name: "Companies",
        accessor: "Companies",
        format: (companies: string[]) => {
          return companies.map((companyId, index) => {
            const company = adminState.companies.find((c) => c.Id == companyId);

            return (
              <Chip size="small" color="secondary" label={company?.Name ?? ""} key={`company_chip_key_${index}`} />
            );
          });
        },
      },
      {
        name: "Communes",
        accessor: "Communes",
        format: (communes: number[]) => {
          return communes.map((communeNumber, index) => {
            const commune = adminState.communes.find((c) => c.Commune.CommuneNumber == communeNumber);

            return (
              <Chip
                size="small"
                color="secondary"
                label={commune?.Commune.CommuneName ?? ""}
                key={`commune_chip_key_${index}`}
              />
            );
          });
        },
      },
      {
        name: "Edit",
        callback: (data) => {
          setCO2PlanItemToEdit(data);
        },
        icon: Icon.Edit,
      },
      {
        name: "Delete",
        callback: (data) => adminCo2Actions.deleteCO2PlanMetaItem(data.Id),
        icon: Icon.Delete,
      },
    ],
    [adminState.communes, adminState.companies]
  );

  useEffect(() => {
    if (!CO2PlanMetaItems || CO2PlanMetaItems.length === 0) adminCo2Actions.getCO2PlanMetaItems(0, 250);
  }, []);

  useEffect(() => {
    if (!adminState.communes || adminState.communes.length === 0) getCommunes();
  }, [adminState.communes]);

  return (
    <>
      {!loading && (
        <Grid container>
          {showCreationDialog || !!co2PlanMetaItemToEdit ? (
            <CO2PlansMetaItemCreation
              co2PlanMetaItem={co2PlanMetaItemToEdit}
              communes={adminState.communes}
              companies={adminState.companies}
              groups={groups}
              backToTable={() => {
                setCO2PlanItemToEdit(null);
                setShowCreationDialog(false);
              }}
              editCO2PlanMetaItem={adminCo2Actions.editCO2PlanMetaItem}
              createCO2PlanMetaItem={adminCo2Actions.createCO2PlanMetaItem}
            />
          ) : (
            <AdminTable
              data={CO2PlanMetaItems}
              columns={columns}
              pagination={"server"}
              headerButton={
                <Grid container item justifyContent="flex-start">
                  <Button color="primary" variant="contained">
                    <Add
                      onClick={() => {
                        setShowCreationDialog(true);
                        setCO2PlanItemToEdit(null);
                      }}
                    />
                  </Button>
                </Grid>
              }
            />
          )}
        </Grid>
      )}
      {loading && (
        <Grid
          container
          sx={{
            overflowY: "auto",
            width: "100%",
            height: "60vh",
            padding: 0,
          }}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      )}
    </>
  );
};

export default CO2PlanMetaItems;
