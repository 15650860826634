import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AutoComplete, { AutocompleteStyles } from "./AutoComplete";
import AuthDialog, { Step } from "../Dialogs/AuthDialog";
import UnitService from "../../services/unit.service";
import { useUser } from "../../actions/user.actions";
import { UserRating } from "../../definitions/model/User";
import NavigationHelper from "../../helpers/navigation-helper";
import { SxProps } from "@mui/system";
import { Button, Divider, Grid, Theme, Typography, styled } from "@mui/material";
import { CO2PlanActionTypes } from "../../pages/ClimatePlan/shared/co2plan.actiontypes";
import { useAppDispatch } from "../../actions";
import { useCO2State } from "../../helpers/hooks";
import { UserConstants } from "../../constants";
import { Trans, useTranslation } from "react-i18next";

const StyledAutoComplete = styled(AutoComplete)(() => ({
  color: "inherit",
  "&: hover": {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "inherit",
    },
  },
}));

type AutocompleteRegistrationProps = {
  children?: React.ReactNode;
  id?: string;
  placeholder: string;
  className?: string;
  gjsStyles?: AutocompleteStyles;
  size?: "small" | "medium";
  simpleRegistration?: boolean;
  registrationDestination: string;
  registrationStep?: Step;
  inputValue?: string;
  userRating: UserRating;
  ignoreNotifications?: boolean;
  loading?: boolean;
  sx?: SxProps<Theme>;
  variant?: "outlined" | "filled" | "standard";
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  employerId?: string;
  communeNumber?: number;
  isCO2Multiselect?: boolean;
  isTouchedHandler?: React.Dispatch<React.SetStateAction<boolean>>;
  isAutocompleteWasTouched?: boolean;
  withLogInButton?: boolean;
  buttonColor?: "primary" | "secondary" | "success" | "error" | "info" | "warning" | "inherit";
  buttonVariant?: "text" | "outlined" | "contained";
  nextButtonText?: string;
  loginOnly?: boolean;
  loginAndStay?: boolean;
};

const AutocompleteRegistration = ({ withLogInButton = true, ...props }: AutocompleteRegistrationProps): JSX.Element => {
  const [step, setStep] = useState<Step>(props.registrationStep || "register");
  const [unitData, setUnitData] = useState("");
  const [openRegister, setOpenRegister] = useState(false);
  const [terms, setTerms] = useState(false);
  const [notifications, setNotifications] = useState(false);
  const [phoneNotifications, setPhoneNotifications] = useState<boolean | undefined>(undefined);
  // const [searchedUnitId, setSearchedUnitId] = useState("");
  const [loading, setLoading] = useState(false);
  const [
    { user, authDestination, authUserRating },
    { updateUserProfile, moveInToProperty, openLoginDialog, openRegisterDialog, setAuthDestination, logout },
  ] = useUser();
  const { multiSelectMode } = useCO2State();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string | null>();
  const { t } = useTranslation("translation");

  useEffect(() => {
    if (props.loading !== undefined) setLoading(props.loading);
  }, [props.loading]);

  useEffect(() => {
    if (multiSelectMode != props.isCO2Multiselect) {
      dispatch({
        type: CO2PlanActionTypes.SET_MULTISELECT,
        payload: props.isCO2Multiselect,
      });
    }

    return () => {};
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("property") && unitData !== params.get("property")) setUnitData(params.get("property") as string);

    if (params.has("terms")) setTerms(JSON.parse(params.get("terms") || "false"));
    if (params.has("notifications")) setNotifications(JSON.parse(params.get("notifications") || "false"));
    if (params.has("phoneNotifications"))
      setPhoneNotifications(JSON.parse(params.get("phoneNotifications") || "false"));

    setEmail(params.get("uid"));

    if (unitData) {
      if (user) {
        const updateUser = async () => {
          if (props.employerId || props.communeNumber) {
            await updateUserProfile({
              Name: user.Name,
              Email: user.Email,
              Phone: user.Phone,
              EmployerId: props.employerId,
              CommuneNumber: props.communeNumber,
              Language: user.Language,
            });
          }

          await redirectUser(unitData);
        };

        updateUser();
        return;
      }

      setTimeout(() => {
        openRegisterDialog(unitData, props.employerId, props.communeNumber);
        // setOpenRegister(true);
      }, 1500);
    }
    //don't watch on "user" changes from state, we need it's value only at begining
  }, [unitData]);

  const redirectUser = async (unitData: string) => {
    setLoading(true);
    try {
      const unit = await UnitService.getUnit(unitData);
      if (props.isTouchedHandler) {
        props.isTouchedHandler(false);
      }
      // const isOwner = await UnitService.getMovedInStatus(unit.Id);
      // const isHouseHaveFamily =
      //   unit.FamilyGroupId !== "00000000-0000-0000-0000-000000000000";

      // if (!isOwner && isHouseHaveFamily) {
      //   const isBreakMoveIn = searchedUnitId !== unit.Id;
      //   setSearchedUnitId(unit.Id);

      //   if (isBreakMoveIn) {
      //     setLoading(false);
      //     return;
      //   }
      //   dispatch(sendFamilyJoinRequest(unit.Id));
      // } else if (!isHouseHaveFamily) {
      moveInToProperty(unit.Id, props.ignoreNotifications || false);
      // }

      if (props.registrationDestination) {
        NavigationHelper.GoByRoute(navigate, unit, null, props.registrationDestination);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const showAlertHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setOpenRegister(true);
  };

  useEffect(() => {
    if (props.registrationDestination !== authDestination || props.userRating !== authUserRating)
      setAuthDestination(true, props.registrationDestination, props.userRating);
  }, []);

  useEffect(() => {
    const leaveHandler = (e: BeforeUnloadEvent) => {
      if (!user && props.isAutocompleteWasTouched && openRegister) {
        dispatch({
          type: UserConstants.USER_TYPPED_ADDRESS_FAILED_TO_REGISTER,
        });
      }
    };

    window.addEventListener("beforeunload", leaveHandler);

    return () => {
      window.removeEventListener("beforeunload", leaveHandler);
    };
  }, [user, props.isAutocompleteWasTouched, openRegister]);

  const loginClick = async () => {
    await openLoginDialog(!!props.loginAndStay, !!props.loginOnly, props.nextButtonText, props.employerId, props.communeNumber);
  };

  const changeAccount = async () => {
    try {
      logout();
      await loginClick();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {withLogInButton && (
        <>
          <Grid container marginBottom={2} alignItems="center">
            {!user ? (
              <>
                <Typography variant="body1" marginRight={3} color="inherit">
                  {t("Authorization.Buttons.AlreadyHave")}
                </Typography>
                <Button
                  variant={props.buttonVariant || "contained"}
                  color={props.buttonColor || "secondary"}
                  onClick={loginClick}
                >
                  {t("Authorization.Buttons.LogIn")}
                </Button>
              </>
            ) : (
              <>
                <Typography variant="body1" marginRight={3} color="inherit">
                  {`${t("Authorization.Buttons.AlreadyLogin")} ${user.Name}`}
                </Typography>
                <Button
                  variant={props.buttonVariant || "contained"}
                  color={props.buttonColor || "secondary"}
                  onClick={changeAccount}
                >
                  {t("Authorization.Buttons.ChangeAccount")}
                </Button>
              </>
            )}
          </Grid>
          <Divider />
          <Grid container marginBottom={2} marginTop={2} alignItems="center">
            <Typography variant="body1" marginRight={3} color="inherit">
              <Trans
                values={{ buttonText: props.nextButtonText ?? t("General.Buttons.Next") }}
                i18nKey="Authorization.Buttons.ClickNextText"
              />
            </Typography>
          </Grid>
        </>
      )}
      <StyledAutoComplete
        placeholder={props.placeholder}
        size={props.size}
        id={props.id}
        sx={props.sx}
        className={props.className}
        gjsStyles={props.gjsStyles}
        setValueCallback={setUnitData}
        inputValue={props.inputValue}
        loading={loading}
        variant={props.variant}
        color={props.color}
        isTouchedHandler={props.isTouchedHandler}
      >
        {/* {!!searchedUnitId && (
          <Alert severity="warning" style={{ margin: "-5px 0 10px" }}>
            {t("Authorization.Forms.AutocompleteText")}
          </Alert>
        )} */}
        {props.children && props.children}
      </StyledAutoComplete>
      <AuthDialog
        setStep={setStep}
        step={step}
        open={openRegister}
        TermsOfUse={terms}
        PhoneNotifications={phoneNotifications}
        Notifications={notifications}
        closeDialog={() => {
          setOpenRegister(false);
        }}
        propertyid={unitData}
        simpleRegistration={props.simpleRegistration}
        ignoreNotifications={props.ignoreNotifications}
        showAlertHandler={showAlertHandler}
        Email={email || ""}
      />
    </>
  );
};

export default AutocompleteRegistration;
