import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Button,
  LinearProgress,
} from "@mui/material";
import BaseDialog from "../../../../../components/Dialogs/BaseDialog";
import { useDefaultReducer } from "../../../../../helpers/hooks";
import {
  ProductModel,
  SubscriptionModel,
} from "../../../../../definitions/model/Subscriptions";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";

type CreateSubscriptionDialogProps = {
  open: boolean;
  closeDialog: () => void;
  product: ProductModel;
  createTestSubscription: (
    email: string,
    productId: string,
    priceId: string
  ) => Promise<SubscriptionModel>;
};

type SubscriptionDialogState = {
  isLoading: boolean;
  error: string;
};

const initialState: SubscriptionDialogState = {
  isLoading: false,
  error: "",
};

const CreateSubscriptionDialog = (
  props: CreateSubscriptionDialogProps
): JSX.Element => {
  const [state, dispatch] = useDefaultReducer(initialState);
  const { t } = useTranslation("translation");

  const methods = useForm({
    defaultValues: {
      Price: "",
      Email: ""
    }
  });
  const { handleSubmit, register, control, formState: {errors} } = methods;

  const submit = async (data: any) => {
    dispatch({ type: "isLoading", payload: true });
    props
      .createTestSubscription(data.Email, props.product.Id, data.Price)
      .then(() => props.closeDialog())
      .catch((e) => {
        dispatch({ type: "error", payload: e.statusText });
      })
      .finally(() => {
        dispatch({ type: "isLoading", payload: false });
      });
  };

  const monthPrice = props.product.Prices.find(
    (price) => price.RecurringInterval === "month"
  );
  const yearPrice = props.product.Prices.find(
    (price) => price.RecurringInterval === "year"
  );

  return (
    <BaseDialog
      titleComponent="Create test subscription"
      open={props.open}
      closeDialog={props.closeDialog}
    >
      <Grid container>
        <form onSubmit={handleSubmit(submit)} style={{ width: "100%" }}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              {t("Subscriptions.Payment")}
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup {...field}>
                  {monthPrice && (
                    <FormControlLabel
                      value={monthPrice.Id}
                      control={<Radio />}
                      label={`${t("Subscriptions.PriceLabel")} ${
                        monthPrice.Amount
                      } ${t("Subscriptions.PerMonth")}`}
                    />
                  )}
                  {yearPrice && (
                    <FormControlLabel
                      value={yearPrice.Id}
                      control={<Radio />}
                      label={`${t("Subscriptions.PriceLabel")} ${
                        yearPrice.Amount
                      } kr. årligt`}
                    />
                  )}
                </RadioGroup>
              )}
              name="Price"
              control={control}
              rules={{required: true}}
            />
            <TextFieldConnector register={register("Email", {required: true})}>
              <TextField
                variant="outlined"
                placeholder="Email"
                margin="dense"
              />
            </TextFieldConnector>
            <Button type="submit" variant="contained" color="secondary">
              {t("General.Buttons.Create")}
            </Button>
            {errors.Price && (
              <Typography color="error">
                {t("Subscriptions.ChoosePayment")}
              </Typography>
            )}
            {state.error && (
              <Typography color="error">{state.error}</Typography>
            )}
            {state.isLoading && <LinearProgress />}
          </FormControl>
        </form>
      </Grid>
    </BaseDialog>
  );
};

export default CreateSubscriptionDialog;
