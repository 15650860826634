import React from "react";
import { CssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./reducers/store";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { PreviousRouteProvider } from "./routes/PreviouseRouteContext";
import RoutesMap from "./routes";
import "./App.css";
import { AlertProvider } from "./components/Base/MyhouseAlert";
import { MAlert } from "./components/Base";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LinkedInTag from "react-linkedin-insight";
// import { IntercomProvider } from "react-use-intercom";
import AppThemeProvider from "./AppThemeProvider";
import { ErrorBoundary } from "./components/ErrorBoundary";
import SystemUpdateAlert from "./components/ServiceWorker/SystemUpdateAlert";
import { IBeforeInstallPromptEvent } from "./helpers/useAddToHomescreenPrompt";

declare global {
  interface Window {
    deferredPrompt: IBeforeInstallPromptEvent | undefined;
  }
}

const App = (): JSX.Element => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS as string, {});

  const facebookPixelOptions = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };
  ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL as string, undefined, facebookPixelOptions);
  ReactPixel.pageView();

  if (process.env.REACT_APP_LINKEDIN_TAG) LinkedInTag.init(process.env.REACT_APP_LINKEDIN_TAG);

  return (
    <Provider store={store}>
      {/* <IntercomProvider
        appId={process.env.REACT_APP_INTERCOM_APP_ID || ""}
        autoBoot={true}
      > */}
      <AppThemeProvider>
        <CssBaseline />
        <AlertProvider>
          <ErrorBoundary>
            <BrowserRouter>
              <PreviousRouteProvider>
                <RoutesMap />
              </PreviousRouteProvider>
            </BrowserRouter>
          </ErrorBoundary>
          <MAlert />
        </AlertProvider>
        <SystemUpdateAlert />
      </AppThemeProvider>
      {/* </IntercomProvider> */}
    </Provider>
  );
};

export default App;
