import React, { useEffect, useReducer } from "react";
import { Grid, FormControlLabel, Switch, Button, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import GrapesJsEditor from "../../../../components/Grapes/GrapesJsEditor";
import { FormBuilderDTO, LandingMetaModel, LandingPageDto, LandingType } from "../../../../definitions/Landing";
import { useLandingActions, useAdminLandings, useUser } from "../../../../actions";
import CircularProgressButton from "../../../../components/Base/CircularProgressButton";
import GrapesJsxRenderer from "../../../../components/Grapes/GrapesJsxRenderer";
import { useAlertContext } from "../../../../components/Base";
import { UserType } from "../../../../constants/enums";
import LanguageChangeMenu from "../../../../components/Layout/LanguageChangeMenu";
import { LanguageType } from "../../../../definitions/Menu";
import { RandomLineTermsSkeleton } from "../../../../components/Base/DetailsSkeleton";
import { ArrowBack } from "@mui/icons-material";

type EditLandingPageState = {
  accordionOpen: boolean;
  landing: LandingMetaModel | null;
  editor: FormBuilderDTO | null;
  landingLoading: boolean;
  editorLoading: boolean;
  languageId: LanguageType;
  landingContent: LandingPageDto | null;
  themeChecked: boolean;
};

const initialState: EditLandingPageState = {
  accordionOpen: false,
  landing: null,
  editor: null,
  landingLoading: false,
  editorLoading: false,
  languageId: "da",
  landingContent: null,
  themeChecked: false,
};

const reducer = (state: EditLandingPageState, action: { type: string; payload?: unknown }) => {
  switch (action.type) {
    case "toggleAccordion":
      return { ...state, accordionOpen: !state.accordionOpen };
    default:
      return {
        ...state,
        [action.type]: action.payload,
      };
  }
};

const EditTermsPage = () => {
  const [, { getTerms, getEditor, updateTerms, addLocalization, activateTerms }] = useAdminLandings();
  const { getLandingPage } = useLandingActions();
  const { showAlert, closeAlert, alertState } = useAlertContext();
  const [{ user }] = useUser();
  const { id } = useParams<{ id: string }>();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [languageId, setLanguageId] = React.useState<LanguageType>("da");

  const onFullfilled = (type: keyof EditLandingPageState) => (result: unknown) => dispatch({ type, payload: result });

  const setIsLoading = (name: string, value: boolean) => dispatch({ type: name + "Loading", payload: value });

  useEffect(() => {
    if (languageId && state.languageId !== languageId) {
      getLocalization(languageId);
    }
  }, [languageId]);

  useEffect(() => {
    const getTermsAsync = async () => {
      if (!state.landing && id) {
        setIsLoading("landing", true);
        try {
          const terms = await getTerms(id);
          onFullfilled("landing")(terms);
          onFullfilled("themeChecked")(terms?.Theme == "communeTheme" ? true : false);
        } finally {
          setIsLoading("landing", false);
        }
      }
      await getContentAsync(languageId || state.languageId);
    };

    getTermsAsync();
    return () => {
      if (!state.landing?.IsPublished && alertState.open) {
        closeAlert();
      }
    };
  }, [state.landing]);

  const getContentAsync = async (languageId: string) => {
    if (state.landing) {
      setIsLoading("editor", true);

      let contentId = "";
      if (state.landing.LocalizedContent) {
        contentId = state.landing.LocalizedContent[languageId];
      } else {
        contentId = state.landing.ExternalLandingId;
      }

      try {
        if (!state.landing?.IsPublished) {
          const editor = await getEditor(contentId);
          onFullfilled("editor")(editor);
        } else {
          const landingContent = await getLandingPage(state.landing.UniqueName, languageId || state.languageId);

          onFullfilled("landingContent")(landingContent);
        }
      } finally {
        setIsLoading("editor", false);
      }
    }
  };

  const getLocalization = async (languageId: string) => {
    setIsLoading("landing", true);

    dispatch({ type: "languageId", payload: languageId });
    if (state.landing?.Id && !state.landing?.LocalizedContent[languageId]) {
      const landing = await addLocalization(state.landing!.Id, languageId);
      onFullfilled("landing")(landing);
    }

    await getContentAsync(languageId);
    setIsLoading("landing", false);
  };

  const updateTheme = (themeChecked: boolean) => {
    if (state.landing) {
      setIsLoading("landing", true);
      handleUpdateLanding({
        ...state.landing,
        Theme: themeChecked ? "communeTheme" : "theme",
      }).finally(() => {
        setIsLoading("landing", false);
      });
    }
  };

  const handleUpdateLanding = async (landing: LandingMetaModel) => {
    const result = await updateTerms(landing);
    dispatch({ type: "landing", payload: landing });
    return result;
  };

  const togglePublish = async () => {
    if (state.landing) {
      setIsLoading("landing", true);
      try {
        const landing = await activateTerms({
          ...state.landing,
          IsPublished: !state.landing.IsPublished,
        });
        onFullfilled("landing")(landing);
        await getContentAsync(state.languageId);
      } finally {
        setIsLoading("landing", false);
      }
    }
  };

  const handleError = (e: Error) => {
    if (!state.landing?.IsPublished || user?.Role !== UserType.User) {
      showAlert({
        severity: "error",
        text: `${e.name}: ${e.message}`,
      });
    }
  };

  const handleChangeTheme = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFullfilled("themeChecked")(event.target.checked);
    updateTheme(event.target.checked);
  };

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item display={"flex"} xs={12} alignItems={"flex-start"}>
        <Grid item justifyContent={"flex-start"} display={"flex"} alignItems={"center"}>
          <Button component={Link} to={"/admin/terms-editor"} type="submit" variant="outlined" color="secondary">
            <ArrowBack fontSize="small" />
            Back <Typography></Typography>
          </Button>
        </Grid>

        {state.landing && (
          <Grid
            item
            container
            justifyContent="center"
            spacing={3}
            alignItems="center"
            paddingBottom={"16px"}
            marginLeft={"-80px"}
          >
            <Grid item>
              {state.landing && (
                <Typography sx={{ marginLeft: "20px", whiteSpace: "nowrap" }}>
                  {state.landing.Title} {state.landing?.IsPublished ? " - is published" : " - edit form"}{" "}
                </Typography>
              )}
            </Grid>

            <Grid item sx={{ zIndex: 500 }}>
              <LanguageChangeMenu
                loading={state.landingLoading || state.editorLoading}
                languageId={languageId}
                setLanguageId={setLanguageId}
              />
            </Grid>

            {!state.landing.IsPublished && (
              <>
                <Grid item>
                  <FormControlLabel
                    style={{
                      marginLeft: 0,
                    }}
                    control={
                      // eslint-disable-next-line react/jsx-no-undef
                      <Switch
                        color="secondary"
                        size="small"
                        aria-label="ccxcxc"
                        checked={state.themeChecked}
                        onChange={handleChangeTheme}
                        disabled={state.landingLoading || state.editorLoading}
                      />
                    }
                    label="Theme switch"
                    labelPlacement="start"
                  />
                </Grid>

                <Grid item>
                  <CircularProgressButton
                    style={{ minWidth: 180 }}
                    onClick={togglePublish}
                    inProgress={state.landingLoading || state.editorLoading}
                  >
                    {"Publish terms"}
                  </CircularProgressButton>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        {!state.landing?.IsPublished && !state.editorLoading && state.editor && (
          <GrapesJsEditor
            id="landingEditor"
            type={LandingType.Terms}
            builder={state.editor}
            themeToUse={state.landing?.Theme}
          />
        )}

        {state.landing?.IsPublished && state.landingContent && !state.landingLoading && (
          <Grid container justifyContent="center" alignItems="center" marginLeft={"-10px"}>
            <Grid xs={12} item>
              <GrapesJsxRenderer jsx={state.landingContent.Html} onError={handleError} />
            </Grid>
          </Grid>
        )}

        {state.landing?.IsPublished && (!state.landingContent || state.landingLoading) && (
          <Grid container justifyContent="center">
            <Grid item container direction="column" justifyContent="left" xs={12} marginTop={"25px"}>
              <RandomLineTermsSkeleton />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default EditTermsPage;
