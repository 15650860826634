export interface WorkerModel {
  Id: string;
  Created: Date;
  Email: string;
  FirstName: string;
  LastName: string;
  Organization: string;
  CVR: string;
  Phone: string;
  Mobile: string;
  Address: string;
  PostCode: number;
  City: string;
  TravelRange: number;
  Competencies: Array<number>;
  MunicipalCodes: Array<number>;
  Rating: number | null;
  RatingFromCurrentUser: number | null;
  CommentFromCurrentUser: string | null;
  GreenTag: boolean;  
  PhoneNumber: string;
  WebSite:string;
  AuthNumber:number;
  Comment:string;
  ContactPerson:string;
}

export interface WorkerRatingDetails {
  WorkerId: string;
  Rating: number | null;
  RatingFromCurrentUser: number | null;
  CommentFromCurrentUser: string | null;
}

export enum LinkRequestState {
  Any = 0,
  Pending = 1,
  Accepted = 2,
  Cancelled = 3,
}

export interface WorkerLinkRequest {
  CraftsmanId: string;
  HouseId: string;
  CraftsmanName: string;
  Id: string;
  Created: Date;
  Modified: Date;
}

export interface CreateCustomWorker {
  Name: string;
  Email: string;
  CVR: string;
  Types: number[];
  PhoneNumber: string;
  Address: string;
  PostCode: number;
  City: string;
  WebSite:string;
  AuthNumber:number;
  Comment:string;
  ContactPerson:string;
  Mobile: string;
}

export interface CustomWorkerRequest {
  SearchString: string; 
  PostCode: number;
  Types: number[];
  FilterByType:boolean;
  CommuneCode: number[];
}

export interface WorkerCompany {
  Name: string;
  Email: string;
  Phone: string;
  Address: string;
  PostCode: number;
  City: string;
  CVR: string
  Id: string
}

export type CompanyAutocompleteItem = {
  Name: string,
  Id: string
}

export const CraftsmanType = [
  { key: 1, value: "ServiceBook.Workers.Electrician"},
  { key: 2, value: "ServiceBook.Workers.Glazier"},
  { key: 3, value: "ServiceBook.Workers.Painter"},
  { key: 4, value: "ServiceBook.Workers.Bricklayer"},
  { key: 5, value: "ServiceBook.Workers.Carpenter" },
  { key: 6, value: "ServiceBook.Workers.Woodworker" },
  { key: 7, value: "ServiceBook.Workers.PlumbingCompanies" },
  { key: 8, value: "ServiceBook.Workers.Blacksmith" },
  { key: 9, value: "ServiceBook.Workers.SewerMaster" },
  { key: 10, value: "ServiceBook.Workers.LandscapeGardener" },
  { key: 11, value: "ServiceBook.Workers.Demolition" },
  { key: 12, value: "ServiceBook.Workers.Plumber" },
  { key: 13, value: "ServiceBook.Workers.LandAndConcrete" },
  { key: 14, value: "ServiceBook.Workers.Architect" },
  { key: 15, value: "ServiceBook.Workers.Engineer" },
  { key: 16, value: "ServiceBook.Workers.Scaffolding" },
  { key: 17, value: "ServiceBook.Workers.ConstructionConsultant" },
  { key: 100, value: "ServiceBook.Workers.Other" },
  { key: 200, value: "ServiceBook.Workers.HouseOwner" },
];

export const Communes = [
  { key: 101, value: "København" },
  { key: 147, value: "Frederiksberg" },
  { key: 151, value: "Ballerup" },
  { key: 153, value: "Brøndby" },
  { key: 155, value: "Dragør" },
  { key: 157, value: "Gentofte" },
  { key: 159, value: "Gladsaxe" },
  { key: 161, value: "Glostrup" },
  { key: 163, value: "Herlev" },
  { key: 165, value: "Albertslund" },
  { key: 167, value: "Hvidovre" },
  { key: 169, value: "Høje-Taastrup" },
  { key: 173, value: "Lyngby-Taarbæk" },
  { key: 175, value: "Rødovre" },
  { key: 183, value: "Ishøj" },
  { key: 185, value: "Tårnby" },
  { key: 187, value: "Vallensbæk" },
  { key: 190, value: "Furesø" },
  { key: 201, value: "Allerød" },
  { key: 210, value: "Fredensborg" },
  { key: 217, value: "Helsingør" },
  { key: 219, value: "Hillerød" },
  { key: 223, value: "Hørsholm" },
  { key: 230, value: "Rudersdal" },
  { key: 240, value: "Egedal" },
  { key: 250, value: "Frederikssund" },
  { key: 253, value: "Greve" },
  { key: 259, value: "Køge" },
  { key: 260, value: "Halsnæs" },
  { key: 265, value: "Roskilde" },
  { key: 269, value: "Solrød" },
  { key: 270, value: "Gribskov" },
  { key: 306, value: "Odsherred" },
  { key: 316, value: "Holbæk" },
  { key: 320, value: "Faxe" },
  { key: 326, value: "Kalundborg" },
  { key: 329, value: "Ringsted" },
  { key: 330, value: "Slagelse" },
  { key: 336, value: "Stevns" },
  { key: 340, value: "Sorø" },
  { key: 350, value: "Lejre" },
  { key: 360, value: "Lolland" },
  { key: 370, value: "Næstved" },
  { key: 376, value: "Guldborgsund" },
  { key: 390, value: "Vordingborg" },
  { key: 400, value: "Bornholm" },
  { key: 410, value: "Middelfart" },
  { key: 411, value: "Christiansø" },
  { key: 420, value: "Assens" },
  { key: 430, value: "Faaborg-Midtfyn" },
  { key: 440, value: "Kerteminde" },
  { key: 450, value: "Nyborg" },
  { key: 461, value: "Odense" },
  { key: 479, value: "Svendborg" },
  { key: 480, value: "Nordfyns" },
  { key: 482, value: "Langeland" },
  { key: 492, value: "Ærø" },
  { key: 510, value: "Haderslev" },
  { key: 530, value: "Billund" },
  { key: 540, value: "Sønderborg" },
  { key: 550, value: "Tønder" },
  { key: 561, value: "Esbjerg" },
  { key: 563, value: "Fanø" },
  { key: 573, value: "Varde" },
  { key: 575, value: "Vejen" },
  { key: 580, value: "Aabenraa" },
  { key: 607, value: "Fredericia" },
  { key: 615, value: "Horsens" },
  { key: 621, value: "Kolding" },
  { key: 630, value: "Vejle" },
  { key: 657, value: "Herning" },
  { key: 661, value: "Holstebro" },
  { key: 665, value: "Lemvig" },
  { key: 671, value: "Struer" },
  { key: 706, value: "Syddjurs" },
  { key: 707, value: "Norddjurs" },
  { key: 710, value: "Favrskov" },
  { key: 727, value: "Odder" },
  { key: 730, value: "Randers" },
  { key: 740, value: "Silkeborg" },
  { key: 741, value: "Samsø" },
  { key: 746, value: "Skanderborg" },
  { key: 751, value: "Århus" },
  { key: 756, value: "Ikast-Brande" },
  { key: 760, value: "Ringkøbing-Skjern" },
  { key: 766, value: "Hedensted" },
  { key: 773, value: "Morsø" },
  { key: 779, value: "Skive" },
  { key: 787, value: "Thisted" },
  { key: 791, value: "Viborg" },
  { key: 810, value: "Brønderslev" },
  { key: 813, value: "Frederikshavn" },
  { key: 820, value: "Vesthimmerlands" },
  { key: 825, value: "Læsø" },
  { key: 840, value: "Rebild" },
  { key: 846, value: "Mariagerfjord" },
  { key: 849, value: "Jammerbugt" },
  { key: 851, value: "Aalborg" },
  { key: 860, value: "Hjørring" },
  { key: 890, value: "Beredskabsstyrelsen" },
  { key: 912, value: "Forpas" },
];
