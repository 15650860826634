import React, { useContext, useEffect, useState } from "react";
import { FormLabel, Grid, useMediaQuery, useTheme } from "@mui/material";
import { CO2Context } from "../CO2ContextWrapper";
import {
  StyledMenuItem,
  StyledSelect,
} from "./CO2TargetOptionsSourceComponent";
import { CO2EmissionSource } from "../../CO2Plan";
import _ from "lodash";
import { useTranslation } from "react-i18next";

type Option = {
  Id: string;
  Code: number;
  Name: string;
  value?: number | null;
  CombinedValue?: number | null;
  CombinedValueInitial?: number | null;
};

type CO2TargetOptionsSourceProps = {
  options: Option[];
  selectedOption: string;
  sourceValue: number | null;
  personId: string | null;
  disabled: boolean;
};

const Co2TargetSubOptionsSourceComponent = (
  props: CO2TargetOptionsSourceProps
): JSX.Element => {
  const context = useContext(CO2Context);
  const [selectedId, setSelectedId] = useState(props.selectedOption);
  const [sourceValue, setSourceValue] = useState(props.sourceValue);
  const { t } = useTranslation("translation");

  const handleSelectionChange = (optionId: string) => {
    const option = props.options.find((x) => x.Id == optionId);

    if (!option) return;

    setSelectedId(optionId);
    context.addValueSource(
      optionId,
      props.personId,
      sourceValue,
      option.CombinedValue ?? null,
      true
    );
  };

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (props.selectedOption !== selectedId) {
      handleSelectionChange(props.selectedOption);
    }
  }, [props.selectedOption]);

  return (
    <>
      <Grid item>
        <StyledSelect
          sx={{ mx: 1 }}
          disabled={props.disabled}
          fullWidth={isXs}
          value={selectedId}
          onChange={(e) => handleSelectionChange(e.target.value as string)}
        >
          {props.options.map((option) => (
            <StyledMenuItem value={option.Id} key={option.Id}>
              {t(option.Name)}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </Grid>
    </>
  );
};

type CO2TargetPerDayOptionsProps = {
  personId: string | null;
  source: CO2EmissionSource;
  sources: CO2EmissionSource[];
  isTarget: boolean;
  disabled: boolean;
  value: number;
};

export const Co2TargetPerdaySelectionComponent = (
  props: CO2TargetPerDayOptionsProps
) => {
  const context = useContext(CO2Context);
  const [value, setValue] = useState(props.value);
  const { t } = useTranslation("translation");

  //needs to set value from props.value after source has been changed
  useEffect(() => {
    if (props.value !== value) setValue(props.value);
  }, [props.value, props.source]);

  const calculateCombinedRemain = () => {
    let combinedRemain = 0;
    if (props.sources[0]?.CombinedMaxAmount != null) {
      let combinedAmount = 0;
      let combinedMaxAmount = 0;
      for (const source of props.sources.filter((s) => s.IsSelected)) {
        if (source.CombinedMaxAmount)
          combinedMaxAmount = source.CombinedMaxAmount;
        combinedAmount += source.CombinedValue ?? 0;
      }
      combinedRemain = combinedMaxAmount - combinedAmount;
    }

    combinedRemain = combinedRemain < 0 ? 0 : combinedRemain;
    const combinedValue = props.source.CombinedValue ?? 0;

    // we adding 1 to result to get a full range of options
    return _.range(
      0,
      (props.isTarget
        ? combinedRemain + combinedValue
        : props.source.CombinedMaxAmount ?? 0) + 1
    );
  };

  //for some reason useEffect not fires on CombinedValue changes inside props.sources.
  //combinedAmountsWatcher - to fire on CombinedValue changes
  const combinedAmountsWatcher = props.sources
    .map((s) => `${s.Id}_${s.CombinedValue ?? ""}`)
    .join("_");
  useEffect(() => {
    setOptions(calculateCombinedRemain());
  }, [props.source, props.sources, combinedAmountsWatcher]);

  const [options, setOptions] = useState<number[]>(calculateCombinedRemain());
  const handleDayChange = (perDayString: string) => {
    const perDay = Number.parseInt(perDayString);
    context.addValueSource(
      props.source.Id,
      props.personId,
      props.source.Value,
      perDay ?? null,
      false
    );
    setValue(perDay);
  };

  //needs to change Combined Value of source to 0 if selected value = 0 but Combined Value from source is null (Combined Value is null by default)
  useEffect(() => {
    if (props.source.CombinedValue == null && value == 0) {
      context.addValueSource(
        props.source.Id,
        props.personId,
        props.source.Value,
        0,
        false
      );
    }
  }, [value, props.source.CombinedValue]);

  return (
    <>
      <StyledSelect
        sx={{ maxWidth: "4rem", mx: 0.5 }}
        disabled={props.disabled}
        size={"small"}
        onChange={(e) => handleDayChange(e.target.value as string)}
        value={value ?? ""}
      >
        {options.map((option) => (
          <StyledMenuItem value={option} key={option}>
            {option}
          </StyledMenuItem>
        ))}
      </StyledSelect>
      <FormLabel>{t("ClimatePlan.InitialSurvey.NumberOfDays")}</FormLabel>
    </>
  );
};

export default Co2TargetSubOptionsSourceComponent;
