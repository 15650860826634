import React, { useEffect, useReducer } from "react";
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { AppThunkDispatch } from "../../../../definitions/Action";
import { connect, ConnectedProps } from "react-redux";
import { getEditor } from "../../../../actions";
import BenefitClubForm from "./components/BenefitClubForm";
import { ExpandMore } from "@mui/icons-material";
import GrapesJsEditor from "../../../../components/Grapes/GrapesJsEditor";
import {
  FormBuilderDTO,
  LandingType,
  BenefitClub,
  UpdateBenefitClubContract,
} from "../../../../definitions/Landing";
import {
  getBenefitClub,
  updateBenefitClub,
  getClubs,
} from "../../../../actions/admin/admin.actions";
import { ClubModel } from "../../../../definitions/model/Club";
import { ApplicationState } from "../../../../reducers/store";

type EditBenefitClubPageState = {
  accordionOpen: boolean;
  benefitClub: BenefitClub | null;
  editor: FormBuilderDTO | null;
  benefitClubLoading: boolean;
  editorLoading: boolean;
  clubs: Array<ClubModel> | null;
};

const initialState: EditBenefitClubPageState = {
  accordionOpen: false,
  benefitClub: null,
  editor: null,
  benefitClubLoading: false,
  editorLoading: false,
  clubs: [],
};

const reducer = (
  state: EditBenefitClubPageState,
  action: { type: string; payload?: unknown }
) => {
  switch (action.type) {
    case "toggleAccordion":
      return { ...state, accordionOpen: !state.accordionOpen };
    default:
      return {
        ...state,
        [action.type]: action.payload,
      };
  }
};

const EditBenefitClubPage = (props: EditBenefitClubConnectedProps) => {
  const { id } = useParams<{id: string}>();
  const [state, dispatch] = useReducer(reducer, initialState);
  const onFullfilled = (type: string) => (result: unknown) =>
    dispatch({ type, payload: result });
  const toggleAccordion = () => dispatch({ type: "toggleAccordion" });
  const setIsLoading = (name: string, value: boolean) =>
    dispatch({ type: name + "Loading", payload: value });

  useEffect(() => {
    if (!state.benefitClub && id) {
      setIsLoading("benefitClub", true);
      props.getBenefitClub(id)
        .then(onFullfilled("benefitClub"))
        .finally(() => setIsLoading("benefitClub", false));
    }

    if (state.benefitClub) {
      setIsLoading("editor", true);
      props.getEditor(state.benefitClub.ExternalLandingId)
        .then(onFullfilled("editor"))
        .finally(() => setIsLoading("editor", false));
    }
  }, [state.benefitClub]);

  useEffect(()=>{
    if(props.clubsList.length === 0) props.getClubs(0);
  }, []);

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={8}>
        <Accordion expanded={state.accordionOpen} onChange={toggleAccordion}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            {state.accordionOpen ? "Hide " : "Expand "}
            {state.benefitClub?.Title} edit form
          </AccordionSummary>
          <AccordionDetails>
            {!state.benefitClubLoading ? (
              state.benefitClub && (
                <BenefitClubForm
                  clubsList={props.clubsList}
                  benefitClub={state.benefitClub}
                  updateBenefitClub={props.updateBenefitClub}
                />
              )
            ) : (
              <Grid container justifyContent="center">
                <CircularProgress />
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        {!state.editorLoading && state.editor && (
          <GrapesJsEditor
            id="benefitClubEditor"
            type={LandingType.Landing}
            builder={state.editor}
            externalId={id}
          />
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  clubsList: state.admin.clubsList,
  loading: state.admin.loading,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getEditor: (editorId: string) => dispatch(getEditor(editorId)),
  getBenefitClub: (id: string) => dispatch(getBenefitClub(id)),
  getClubs: (skip: number) => dispatch(getClubs(skip)),
  updateBenefitClub: (data: UpdateBenefitClubContract) =>
    dispatch(updateBenefitClub(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type EditBenefitClubConnectedProps = ConnectedProps<typeof connector>;

export default connector(EditBenefitClubPage);
