import React, { useState, useEffect } from "react";
import { Typography, Grid, Box, FormControlLabel, LinearProgress, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import AutoComplete from "../../../components/Autocomplete/AutoComplete";
import BaseDialog from "../../../components/Dialogs/BaseDialog";
import MCheckbox from "../../../components/Base/MyhouseCheckbox";
import AddressHelper from "../../../helpers/address-helper";
import { useTranslation } from "react-i18next";
import { ControllerConnector } from "../../../components/Base/FormConnector";
import { Building } from "../../../definitions/model/Building";
import { useBuilding, useUser } from "../../../actions";

type MoveInBuildingDialogProps = {
  open: boolean;
  presetBuilding: Building | null;
  closeDialog: () => void;
};

type MoveInBuildingFormData = {
  BuildingId: string;
  IsResident: boolean;
};

const MoveInBuildingDialog = (props: MoveInBuildingDialogProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const [valueCallback, setValueCallback] = useState("");
  const [autocompletePreset, setAutocompletePreset] = useState("");
  const [{ loading }, buildingActions] = useBuilding();
  const [, { _getBasicData }] = useUser();
  const methods = useForm<MoveInBuildingFormData>({
    defaultValues: {
      BuildingId: "",
      IsResident: false,
    },
  });
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
  } = methods;

  useEffect(() => {
    setValue("BuildingId", valueCallback);
  }, [valueCallback, setValue]);

  useEffect(() => {
    register("BuildingId", { required: true });
    register("IsResident", { required: true });
    return () => {
      // unregister input after component unmount
      unregister("BuildingId");
      unregister("IsResident");
    };
  }, [register, unregister]);

  useEffect(() => {
    if (props.presetBuilding) {
      const autocompleteValue = AddressHelper.GetAutocompleteInput(props.presetBuilding.Address);
      setAutocompletePreset(autocompleteValue);
      setValue("BuildingId", props.presetBuilding.Id);
    }
  }, [props.presetBuilding]);

  const submit = (data: MoveInBuildingFormData) => {
    buildingActions.moveInToBuilding(data.BuildingId).then(async (data) => {
      if (data?.Building) {
        await _getBasicData();
        navigate(`/buildings/${AddressHelper.GetBuildingAddressUrl(data.Building)}`);
      }
      props.closeDialog();
    });
  };

  return (
    <BaseDialog titleComponent={t("Building.MoveIntoTitle") ?? ""} closeDialog={props.closeDialog} open={props.open}>
      <Grid container justifyContent="center">
        <Grid
          item
          sx={{
            width: "511px",
          }}
        >
          {loading && <LinearProgress color="primary" />}
          <Box mb={4}>
            <Typography>{t("Building.RequireVirtuallyMoveIntoOwnHome")}</Typography>
          </Box>
          <AutoComplete
            placeholder={t("Autocomplete.Placeholder")}
            setValueCallback={setValueCallback}
            className={`${errors.BuildingId ? "Mui-error Mui-error" : ""}`}
            inputValue={autocompletePreset}
            buildingSearch
          />
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submit)}>
              <FormControlLabel
                control={
                  <ControllerConnector name="IsResident" type="checkbox">
                    <MCheckbox name="IsResident" error={!!errors.IsResident} />
                  </ControllerConnector>
                }
                label={<Typography variant="caption">{t("BuildingManagement.AreYouBuildingAdmin")}</Typography>}
                sx={{
                  "& .MuiFormControlLabel-root": {
                    margin: 0,
                  },
                }}
              />
              <Grid container justifyContent="flex-end">
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  sx={{
                    width: "170px",
                    "& span": {
                      width: "auto",
                    },
                  }}
                >
                  {t("Pages.PropertyFacts.MoveIn")}
                </Button>
              </Grid>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </BaseDialog>
  );
};

export default MoveInBuildingDialog;
