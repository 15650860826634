import { Dispatch } from "redux";
import { AppAction } from "../definitions/Action";
import { CommentConstants } from "../constants/comment.constants";
import { CommentModel } from "../definitions/NewsStream";
import CommentService from "../services/comment.service";
import { operationFailedActionGeneral, useAppDispatch } from ".";
import { useSelector } from "react-redux";
import { ApplicationState } from "../reducers/store";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, CommentConstants.COMMENT_OPERATION_FAILED);
};

export const getComments = (parentId: string, skip: number, take: number) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: CommentConstants.GET_COMMENTS });
    const result = await CommentService.getComments(parentId, skip, take);
    dispatch({
      type: CommentConstants.GET_COMMENTS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const postComment = (comment: Partial<CommentModel>) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: CommentConstants.POST_COMMENT });
    const result = await CommentService.postComment(comment);
    if (result.NestingLevel > 0) {
      const parent = await CommentService.getComment(result.ParentId);
      dispatch({
        type: CommentConstants.UPDATE_COMMENT_SUCCEEDED,
        payload: parent,
      });
    } else {
      // const parent = await NewsService.getNewsStream(result.ParentId);
      // if(parent){
      //   dispatch({ type: NewsStreamConstants.UPDATE_NEWSITEM_SUCCEEDED, payload: parent });
      // }
    }
    dispatch({
      type: CommentConstants.POST_COMMENT_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const editComment = (comment: CommentModel) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: CommentConstants.UPDATE_COMMENT });
    const result = await CommentService.putComment(comment);
    if (comment.NestingLevel > 0) {
      const parent = await CommentService.getComment(comment.ParentId);
      dispatch({
        type: CommentConstants.UPDATE_COMMENT_SUCCEEDED,
        payload: parent,
      });
    } else {
      // const parent = await NewsService.getNewsStream(comment.Id);
      // if(parent){
      //   dispatch({ type: NewsStreamConstants.UPDATE_NEWSITEM_SUCCEEDED, payload: parent });
      // }
    }

    dispatch({
      type: CommentConstants.UPDATE_COMMENT_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const deleteComment = (comment: CommentModel) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: CommentConstants.DELETE_COMMENT });
    const result = await CommentService.deleteComment(comment.Id);

    if (comment.NestingLevel > 0) {
      const parent = await CommentService.getComment(comment.ParentId);
      dispatch({
        type: CommentConstants.UPDATE_COMMENT_SUCCEEDED,
        payload: parent,
      });
    } else {
      // const parent = await NewsService.getNewsStream(comment.Id);
      // if(parent){
      //   dispatch({ type: NewsStreamConstants.UPDATE_NEWSITEM_SUCCEEDED, payload: parent });
      // }
    }

    dispatch({
      type: CommentConstants.DELETE_COMMENT_SUCCEEDED,
      payload: { Id: result },
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const useCommentState = () => useSelector((state: ApplicationState) => state.comment);

const useCommentActions = () => {
  const dispatch = useAppDispatch();
  return {
    getComments: (parentId: string, skip: number, take: number) => dispatch(getComments(parentId, skip, take)),
    postComment: (comment: Partial<CommentModel>) => dispatch(postComment(comment)),
    editComment: (comment: CommentModel) => dispatch(editComment(comment)),
    deleteComment: (comment: CommentModel) => dispatch(deleteComment(comment)),
  };
};

export const useComments = (): [ReturnType<typeof useCommentState>, ReturnType<typeof useCommentActions>] => {
  const state = useCommentState();
  const actions = useCommentActions();

  return [state, actions];
};
