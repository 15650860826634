import React from "react";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";

type ThemeChangeProps = {
    themeChecked: string | null;
    handleThemeChange: (event: React.MouseEvent<HTMLElement>, newTheme: string | null) => void; 
}

const ThemeChangeComponent = (props: ThemeChangeProps) => {
  return (
    <ToggleButtonGroup sx={{ marginBottom: 3 }} value={props.themeChecked} exclusive onChange={props.handleThemeChange}>
      <ToggleButton value="greenTheme">
        <Typography variant="caption">myhouse</Typography>
      </ToggleButton>
      <ToggleButton value="commercialTheme">
        <Typography variant="caption">commercial klimaplan</Typography>
      </ToggleButton>
      <ToggleButton value="communeTheme">
        <Typography variant="caption"> kommune klimaplan</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ThemeChangeComponent;
