import React, { useEffect, useState } from "react";
import { Grid, Tab } from "@mui/material";

import {
  useAdminNavigationActions,
  useAdminNavigationState,
} from "./admin.navigation.actions";
import {
  useAdminPartnersActions,
  useAdminPartnersState,
} from "./admin.partners.actions";
import { NavigationItem } from "../../../../definitions/Menu";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import NavigationItemsList from "./components/NavigationItemsList";

type DroppableType = {
  index: number;
  droppableId: string;
};

type DroppableResult = {
  [index: string]: NavigationItem[];
};

export const reorder = (
  list: NavigationItem[],
  startIndex: number,
  endIndex: number
) => {
  const [removed] = list.splice(startIndex, 1);
  list.splice(endIndex, 0, removed);

  return list;
};

export const move = (
  source: NavigationItem[],
  destination: NavigationItem[],
  droppableSource: DroppableType,
  droppableDestination: DroppableType
) => {
  const [removed] = source.splice(droppableSource.index, 1);
  destination.splice(droppableDestination.index, 0, removed);

  const result: DroppableResult = {};
  result[droppableSource.droppableId] = source;
  result[droppableDestination.droppableId] = destination;

  const leftColumn = result["0"];
  const rightColumn = result["1"];

  if (leftColumn.length - rightColumn.length > 1) {
    const lastElement = leftColumn.pop();
    rightColumn.push(lastElement as NavigationItem);
  } else if (rightColumn.length - leftColumn.length > 0) {
    const lastElement = rightColumn.pop();
    leftColumn.push(lastElement as NavigationItem);
  }

  return result;
};

const OverviewNavigationPage = () => {
  const [activeTab, setActiveTab] = useState<"overview" | "partner">(
    "overview"
  );

  const {
    getNavigationItem,
    getNavigationItems,
    deleteNavigationItem,
    reorderNavigationItems,
    setNavigationItemPublish,
    addNavigationItem,
    updateBackgroundImage,
    updateNavigationItem,
  } = useAdminNavigationActions();

  const { navigationItems, error } = useAdminNavigationState();

  const {
    getPartnersItem,
    getPartnersItems,
    deletePartnersItem,
    reorderPartnersItems,
    setPartnersItemPublish,
    addPartnersItem,
    updatePartnersBackgroundImage,
    updatePartnersItem,
  } = useAdminPartnersActions();

  const { partnersItems, error: partnersError } = useAdminPartnersState();

  useEffect(() => {
    if (!navigationItems.length) {
      getNavigationItems();
    }
  }, []);

  useEffect(() => {
    if (!partnersItems.length) {
      getPartnersItems();
    }
  }, []);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: "overview" | "partner"
  ) => {
    setActiveTab(newValue);
  };

  return (
    <Grid container justifyContent="center">
      <TabContext value={activeTab}>
        <Grid container justifyContent="center">
          <TabList onChange={handleChange}>
            <Tab value="overview" label="overview" />
            <Tab value="partner" label="partner" />
          </TabList>
        </Grid>
        <TabPanel value="overview">
          <NavigationItemsList
            headerText="Navigation blocks"
            type="overview"
            withMenu
            navigationItems={navigationItems}
            error={error}
            reorderNavigationItems={reorderNavigationItems}
            getNavigationItem={getNavigationItem}
            getNavigationItems={getNavigationItems}
            deleteNavigationItem={deleteNavigationItem}
            setNavigationItemPublish={setNavigationItemPublish}
            addNavigationItem={addNavigationItem}
            updateBackgroundImage={updateBackgroundImage}
            updateNavigationItem={updateNavigationItem}
          />
        </TabPanel>
        <TabPanel value="partner">
          <NavigationItemsList
            type="partner"
            headerText="Partners login"
            navigationItems={partnersItems}
            error={partnersError}
            reorderNavigationItems={reorderPartnersItems}
            getNavigationItem={getPartnersItem}
            getNavigationItems={getPartnersItems}
            deleteNavigationItem={deletePartnersItem}
            setNavigationItemPublish={setPartnersItemPublish}
            addNavigationItem={addPartnersItem}
            updateBackgroundImage={updatePartnersBackgroundImage}
            updateNavigationItem={updatePartnersItem}
          />
        </TabPanel>
      </TabContext>
    </Grid>
  );
};

export default OverviewNavigationPage;
