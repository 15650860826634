import { Dispatch, AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { ApplicationState } from "../../reducers/store";
import { AppAction } from "../../definitions/Action";
import { AdminCommuneActionTypes } from "../../constants/admin/admin.commune.actiontypes";
import { AdminActionTypes } from "../../constants/admin/admin.actiontypes";
import CommuneService from "../../services/admin/admin.commune.service";
import { CommuneLocation } from "../../definitions/model/unit/Commune";
import { operationFailedActionGeneral } from "..";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, AdminActionTypes.ADMIN_OPERATION_FAILED);
};

export const getCommunes =
  () =>
  async (dispatch: Dispatch<AppAction>): Promise<CommuneLocation[] | undefined> => {
    try {
      dispatch({
        type: AdminCommuneActionTypes.GET_COMMUNES,
      });
      const result = await CommuneService.getCommunes();
      dispatch({
        type: AdminCommuneActionTypes.GET_COMMUNES_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error: any) {
      dispatch(operationFailedAction(error));
    }
  };

const useCO2PlanActions = () => {
  const dispatch = useDispatch<ThunkDispatch<ApplicationState, any, AnyAction>>();
  return {
    getCommunes: () => dispatch(getCommunes()),
  };
};

export const useAdminCommunes = (): [CommuneLocation[], ReturnType<typeof useCO2PlanActions>] => {
  const state = useSelector((state: ApplicationState) => state.admin.communes);
  const actions = useCO2PlanActions();

  return [state, actions];
};
