import React, { useEffect, useMemo, useReducer } from "react";
import { Button, CircularProgress, Grid, styled } from "@mui/material";
import { AdminTable, Column, Icon } from "../../../components/AdminTable/AdminTable";
import { Add } from "@mui/icons-material";
import { SystemMetaModel } from "../../../../../definitions/model/SystemMetaModel";
import { useAdmin } from "../../../../../actions/admin/admin.actions";
import SystemMetaEditor from "./SystemMetaEditor";

const ListContainer = styled(Grid)({
  overflowY: "auto",
  width: "100%",
  height: "calc(100vh - 250px)",
  padding: 0,
  marginTop: 20,
});

type State = {
  showEditDialog: boolean;
  metaToEdit: SystemMetaModel | null;
};

const initialState: State = {
  showEditDialog: false,
  metaToEdit: null,
};

const reducer = (state: State, action: { type: string; payload: unknown }) => {
  return { ...state, [action.type]: action.payload };
};

const SystemMetaPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [{ systemMeta: systemMetaList, loading }, { deleteSystemMeta, getSystemMetaList }] = useAdmin();

  const handleDeleteSystemMessage = async (id: string) => {
    await deleteSystemMeta(id);
  };

  useEffect(() => {
    if (!systemMetaList || systemMetaList.length === 0) {
      getSystemMetaList();
    }
  }, [systemMetaList]);

  const handleCreate = () => dispatch({ type: "showEditDialog", payload: true });

  const columns: Column<SystemMetaModel>[] = useMemo(
    () => [
      {
        name: "Hostname",
        accessor: "Hostname",
      },
      {
        name: "Path",
        accessor: "Path",
      },
      {
        name: "Edit",
        icon: Icon.Edit,
        callback: (data) => {
          dispatch({ type: "metaToEdit", payload: data });
          dispatch({ type: "showEditDialog", payload: true });
        },
      },
      {
        name: "Delete",
        icon: Icon.Delete,
        callback: (data) => handleDeleteSystemMessage(data.Id),
      },
    ],
    []
  );

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid container item md={6} xs={10}>
        {loading ? (
          <ListContainer container justifyContent="center" alignItems="center">
            <CircularProgress />
          </ListContainer>
        ) : (
          <AdminTable
            data={systemMetaList}
            columns={columns}
            pagination="client"
            headerButton={
              <Grid container item justifyContent="flex-end">
                <Button color="primary" variant="contained" onClick={handleCreate}>
                  <Add />
                </Button>
              </Grid>
            }
          />
        )}
      </Grid>
      <SystemMetaEditor
        key={"system_meta_editor_" + (state.metaToEdit?.Id ?? "new")}
        open={state.showEditDialog}
        systemMeta={state.metaToEdit}
        handleClose={() => {
          dispatch({ type: "showEditDialog", payload: false });
          dispatch({ type: "metaToEdit", payload: null });
        }}
      />
    </Grid>
  );
};

export default SystemMetaPage;
