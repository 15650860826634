import {ListItemButton, styled } from "@mui/material";
export const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  cursor: "pointer",
  background: theme.palette.background.lightPaper,
  border: "1px solid" + theme.palette.background.grayPaper,
  minHeight: 80,
  height: "100%",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.background.grayPaper,
      color: theme.palette.primary.contrastText,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.background.grayPaper,
      color: theme.palette.primary.contrastText,
    },
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.background.grayPaper,
    color: theme.palette.primary.contrastText,
  },
}));
