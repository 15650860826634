import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { format } from "date-fns";
import PathAutocompleteField from "./PathAutocompleteField";
import { EditRuleInfoFormData, EditRuleInfoProps } from "../defenitions";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";
import {styled} from "@mui/material";

const ChipsContainer = styled(Grid)(({theme})=>({
  "& > *": {
    margin: theme.spacing(0.5),
  },
}))

const EditRuleInfo = (props: EditRuleInfoProps): JSX.Element => {
  const methods = useForm<EditRuleInfoFormData>({
    defaultValues: {
      fields: props.rule.Fields,
      path: props.rule.Path,
      from: props.rule.From
        ? format(new Date(props.rule.From), "yyyy-MM-dd")
        : "",
      to: props.rule.To ? format(new Date(props.rule.To), "yyyy-MM-dd") : "",
      name: props.rule.Name,
    },
  });
  const { handleSubmit, register, unregister, control, setValue, watch } =
    methods;
  useEffect(() => {
    register("fields");
    return () => unregister("fields");
  }, [register]);

  const fields = watch().fields;

  const handleFieldsChange = (value: string, checked: boolean) => {
    if (checked) {
      setValue("fields", [...fields, value]);
    } else {
      setValue(
        "fields",
        fields.filter((x) => x !== value)
      );
    }
  };

  const submit = (data: EditRuleInfoFormData) => {
    props.onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(submit)} id={props.formId}>
      <Grid item container alignItems="center">
        <PathAutocompleteField
          onLoad={props.onAutocompleteLoad}
          loading={props.autocompleteLoading}
          label="Path"
          name="path"
          control={control}
          defaultValue={props.rule.Path}
        />
      </Grid>
      <Grid item container alignItems="center">
        <Grid item>
          <Typography variant="h5">Fields:</Typography>
        </Grid>
        <Grid item container>
          <ChipsContainer item>
            <FormControl>
              <Grid container>
                {props.inputs.map((x) => (
                  <Grid item key={x}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!fields.find((f) => f === x)}
                          onChange={(e, checked) =>
                            handleFieldsChange(x, checked)
                          }
                        />
                      }
                      label={x}
                    />
                  </Grid>
                ))}
              </Grid>
            </FormControl>
          </ChipsContainer>
        </Grid>
        <Grid item xs={11} container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5">Period: </Typography>
          </Grid>
          <Grid item>
            <TextFieldConnector register={register("from")}>
              <TextField
                InputProps={{ notched: true }}
                InputLabelProps={{ shrink: true }}
                margin="dense"
                variant="outlined"
                type="date"
                label="From"
              />
            </TextFieldConnector>
          </Grid>
          <Grid item>
            <TextFieldConnector register={register("to")}>
              <TextField
                InputProps={{ notched: true }}
                InputLabelProps={{ shrink: true }}
                margin="dense"
                variant="outlined"
                type="date"
                label="To"
              />
            </TextFieldConnector>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditRuleInfo;
