import React, { useState, useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Delete, Add, Refresh } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { format } from "date-fns";
import AddTestUnitForm from "./AddTestUnitForm";

import { PredictionTestUnitModel, PricePredictionModel } from "../defenitions";
import {Collapse, IconButton, styled} from "@mui/material";
import { CellLabel } from "../../../../../components/Base/Table";
import { convertToCurency } from "../../../../../services/converter.service";

const SpinnerContainer = styled(Grid)<{
  component: React.ReactNode
}>(({theme})=>({
  position: "absolute",
  background: "rgba(0,0,0,0.3)",
  height: "calc(100% - 53px)",
  top: 0,
}))

const Spinner = styled(CircularProgress)(({theme})=>({
  position: "absolute",
  top: "50%",
  left: "calc(50% - 20px)",
}))

const StyledTableContainer = styled(TableContainer)(({theme})=>({
  overflowY: "auto",
  width: "100%",
  height: "80vh",
  padding: 0,
}))

const StyledTableRow = styled(TableRow)(({theme})=>({
  height: 59,
  "& button, & a": {
    display: "inline-flex",
    visibility: "hidden",
  },
  "&:hover": {
    background: theme.palette.grey["200"],
    "& button, & a": {
      visibility: "visible",
    },
  },
}))

const StyledTableHead = styled(TableHead)(({theme})=>({
  "& button": {
    margin: theme.spacing(1),
  },
}))

const AccuracySpan = styled("span", {
  shouldForwardProp:(propName: PropertyKey) => propName !== "accuracy"
})<{accuracy: "OK" | "Norm" | "Bad"}>(({theme, accuracy})=>({
  color: accuracy == "OK" ? theme.palette.success["main"] : accuracy === "Norm" ? theme.palette.success["main"] : theme.palette.error["main"],
}))

type Accuracy = {
  predictionName: string;
  total: number;
  totalOk: number;
  totalNorm: number;
  totalBad: number;
  count: number;
  countOk: number;
  countNorm: number;
  countBad: number;
  average: number;
  averageOk: number;
  averageNorm: number;
  averageBad: number;
};
type PredictionAccuracy = {
  propertyType: string;
  predictions: Accuracy[];
};

type PredictionTestUnitsProps = {
  testUnits: PredictionTestUnitModel[];
  predictions: PricePredictionModel[];
  loading: boolean;
  expandAddUnitRule: boolean;
  addNewTestUnit: (unitId: string) => Promise<PredictionTestUnitModel | null>;
  onAddClick: () => void;
  hideAddTestUnitBlock: () => void;
  onDeleteClick: (testUnitId: string) => void;
  onRenewClick: (testUnitId: string) => void;
  renewPredictions: () => Promise<PredictionTestUnitModel[]>;
};

const PredictionTestUnits = (props: PredictionTestUnitsProps): JSX.Element => {
  const basicLabels = ["Created", "Type", "Address", "Sold", "Competitor"];
  const [labels, setLabels] = useState<string[]>(basicLabels);
  const [accuracyLabels, setAccuracyLabels] = useState<string[]>([]);
  const [unitsPredictions, setUnitsPredictions] = useState<
    PredictionTestUnitModel[]
  >([]);
  const [accuracies, setAccuracies] = useState<PredictionAccuracy[]>([]);

  useEffect(() => {
    if (props.predictions) {
      const predictionsLabels: string[] = [];
      props.predictions.map((p) => {
        if (predictionsLabels.indexOf(p.Name) === -1)
          predictionsLabels.push(p.Name);
      });

      setLabels([...basicLabels, ...predictionsLabels]);
      setAccuracyLabels(["Type", ...predictionsLabels]);

      const uPredictions: PredictionTestUnitModel[] = [];
      const pAccuracies: PredictionAccuracy[] = [];
      props.testUnits.map((u) => {
        let typedAccuracy = pAccuracies.find(
          (pa) => pa.propertyType === u.PropertyType
        );
        if (!typedAccuracy) {
          pAccuracies.unshift({
            propertyType: u.PropertyType,
            predictions: [],
          });
          typedAccuracy = pAccuracies[0];
        }

        const currentUnitPrediction = Object.assign({}, u);
        currentUnitPrediction.Predictions = [];
        uPredictions.push(currentUnitPrediction);

        props.predictions.map((p) => {
          const pred = u.Predictions.find(
            (up) => up.PredictionModelId === p.Id
          );
          let predAccuracy = typedAccuracy?.predictions.find(
            (ta) => ta.predictionName === p.Name
          );
          if (!predAccuracy && typedAccuracy) {
            typedAccuracy.predictions.push({
              predictionName: p.Name,
              average: 0,
              averageOk: 0,
              averageNorm: 0,
              averageBad: 0,
              count: 0,
              countOk: 0,
              countNorm: 0,
              countBad: 0,
              total: 0,
              totalOk: 0,
              totalNorm: 0,
              totalBad: 0,
            });
            predAccuracy =
              typedAccuracy.predictions[typedAccuracy.predictions.length - 1];
          }

          const prevUnitPrediction = currentUnitPrediction.Predictions.find(
            (up) => up.PredictionLabel === p.Name
          );
          let accuracy = undefined;
          if (u.SoldPrice > 0 && pred && pred.Value > 0) {
            accuracy =
              u.SoldPrice > pred.Value
                ? 100 - Math.round((pred.Value * 100) / u.SoldPrice)
                : 100 - Math.round((u.SoldPrice * 100) / pred.Value);

            if (predAccuracy) {
              predAccuracy.count += accuracy;
              predAccuracy.total++;
              predAccuracy.average =
                Math.round((predAccuracy.count * 100) / predAccuracy.total) /
                100;

              if (accuracy < 10) {
                predAccuracy.countOk += accuracy;
                predAccuracy.totalOk++;
                predAccuracy.averageOk =
                  Math.round(
                    (predAccuracy.countOk * 100) / predAccuracy.totalOk
                  ) / 100;
              } else if (accuracy < 20) {
                predAccuracy.countNorm += accuracy;
                predAccuracy.totalNorm++;
                predAccuracy.averageNorm =
                  Math.round(
                    (predAccuracy.countNorm * 100) / predAccuracy.totalNorm
                  ) / 100;
              } else {
                predAccuracy.countBad += accuracy;
                predAccuracy.totalBad++;
                predAccuracy.averageBad =
                  Math.round(
                    (predAccuracy.countBad * 100) / predAccuracy.totalBad
                  ) / 100;
              }
            }
          }

          if (!prevUnitPrediction) {
            currentUnitPrediction.Predictions.push({
              PredictionLabel: p.Name,
              PredictionModelId: p.Id,
              Value: pred ? pred.Value : 0,
              Accuracy: accuracy,
            });
          } else if (prevUnitPrediction.Value === 0) {
            prevUnitPrediction.PredictionModelId = p.Id;
            prevUnitPrediction.Value = pred ? pred.Value : 0;
            prevUnitPrediction.Accuracy = accuracy;
          }
        });
      });

      setUnitsPredictions(uPredictions);
      setAccuracies(pAccuracies);
    }
  }, [props.predictions, props.testUnits]);

  return (
    <>
      <Collapse in={props.expandAddUnitRule} unmountOnExit mountOnEnter>
        <AddTestUnitForm
          onSubmit={props.addNewTestUnit}
          onHide={props.hideAddTestUnitBlock}
        />
      </Collapse>
      <StyledTableContainer>
        <Table stickyHeader style={{ position: "relative" }}>
          <StyledTableHead>
            <TableRow>
              <TableCell align="right" colSpan={labels.length + 1}>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Add />}
                  onClick={props.onAddClick}
                >
                  Add Unit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Refresh />}
                  onClick={props.renewPredictions}
                >
                  Renew Estimations
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              {accuracyLabels.map((label) => (
                <CellLabel
                  key={label}
                  cellKey={label}
                  cellName={label}
                  direction="asc"
                  currentCellName={label}
                  onClick={(name: string) => () => {
                    /* */
                  }}
                />
              ))}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {accuracies.map((acc) => (
              <StyledTableRow key={acc.propertyType}>
                <TableCell>{acc.propertyType}</TableCell>
                {acc.predictions.map((p) => (
                  <TableCell key={acc.propertyType + p.predictionName}>
                    Average: ({p.total}){p.average}%
                    <br />({p.totalOk})
                    <AccuracySpan accuracy={"OK"}>{p.averageOk}% </AccuracySpan>(
                    {p.totalNorm})
                    <AccuracySpan accuracy={"Norm"}>
                      {p.averageNorm}%{" "}
                    </AccuracySpan>
                    ({p.totalBad})
                    <AccuracySpan accuracy={"Bad"}>{p.averageBad}%</AccuracySpan>
                  </TableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <Table stickyHeader style={{ position: "relative" }}>
          <StyledTableHead>
            <TableRow>
              {labels.map((label) => (
                <CellLabel
                  key={label}
                  cellKey={label}
                  cellName={label}
                  direction="asc"
                  currentCellName={label}
                  onClick={(name: string) => () => {
                    /* */
                  }}
                />
              ))}
              <TableCell></TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {unitsPredictions.map((testUnit) => (
              <StyledTableRow key={testUnit.Id}>
                <TableCell>
                  {format(new Date(testUnit.Created), "dd/MM/yyyy")}
                </TableCell>
                <TableCell>{testUnit.PropertyType}</TableCell>
                <TableCell>{testUnit.Address}</TableCell>
                <TableCell>
                  {testUnit.SoldDate && (
                    <>
                      {format(new Date(testUnit.SoldDate), "dd/MM/yyyy")}
                      <br />
                      {convertToCurency(+testUnit.SoldPrice)}
                    </>
                  )}
                </TableCell>
                <TableCell>
                  {convertToCurency(+testUnit.CompetitorPrice)}
                </TableCell>
                {testUnit.Predictions.map((pred) => (
                  <TableCell key={pred.PredictionModelId}>
                    {convertToCurency(Math.round(pred.Value))}
                    {(pred.Accuracy || pred.Accuracy === 0) && (
                      <AccuracySpan accuracy={pred.Accuracy < 10 ? "OK" : pred.Accuracy ? "Norm" : "Bad"}
                      >
                        {" "}
                        {pred.Accuracy}
                        {"%"}
                      </AccuracySpan>
                    )}
                  </TableCell>
                ))}
                <TableCell align="right">
                  <IconButton
                    size="small"
                    onClick={() => props.onRenewClick(testUnit.Id)}
                  >
                    <Refresh fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => props.onDeleteClick(testUnit.Id)}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </TableCell>
              </StyledTableRow>
            ))}
            {props.loading && (
              <SpinnerContainer
                component={<TableRow/>}
                container
                justifyContent="center"
                alignItems="center"
              >
                <TableCell>
                  <Spinner />
                </TableCell>
              </SpinnerContainer>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};

export default PredictionTestUnits;
