import React, { useEffect, useState, useMemo } from "react";
import { Grid, CircularProgress, Chip, Button } from "@mui/material";
import { CO2EconomyItem } from "../../../../ClimatePlan/shared/CO2Plan";
import { Add } from "@mui/icons-material";
import EconomyItemCreateEditDialog from "./EconomyItemCreateEditDialog";
import { useTranslation } from "react-i18next";
import { AdminTable, Column, Icon } from "../../../components/AdminTable/AdminTable";
import { useAdminCO2 } from "../../../../../actions/admin/admin.co2plan.actions";
import { useAdminCommunes } from "../../../../../actions/admin/admin.commune.actions";

const EconomyItems = () => {
  const [{ CO2EconomyItems, CO2EmissionSourceGroups: groups, loading }, adminCo2Actions] = useAdminCO2();
  const [communes, adminCommunesActions] = useAdminCommunes();
  const [economyItemToEdit, setEconomyItemToEdit] = useState<CO2EconomyItem | null>(null);
  const [showCreationDialog, setShowCreationDialog] = useState(false);
  const { t } = useTranslation("translation");

  const columns: Column<CO2EconomyItem>[] = useMemo(
    () => [
      {
        name: "Group",
        accessor: "GroupDescription",
        format: (item) => t(item.ShortName) ?? item.EmissionSourceGroupId,
      },
      {
        name: "Show Communes",
        accessor: "ShowCommunes",
        format: (showCommunes: number[]) => {
          return showCommunes.map((communeNumber, index) => {
            const commune = communes.filter((c) => c.Commune.CommuneNumber == communeNumber)[0];

            return commune ? (
              <Chip size="small" color="success" label={commune.Commune.CommuneName} key={`chip_key_${index}`} />
            ) : (
              ""
            );
          });
        },
      },
      {
        name: "Hide Communes",
        accessor: "HideCommunes",
        format: (hideCommunes: number[]) => {
          return hideCommunes.map((communeNumber, index) => {
            const commune = communes.filter((c) => c.Commune.CommuneNumber == communeNumber)[0];

            return communes.filter((c) => c.Commune.CommuneNumber == communeNumber)[0] ? (
              <Chip size="small" color="error" label={commune.Commune.CommuneName} key={`chip_key_${index}`} />
            ) : (
              ""
            );
          });
        },
      },
      {
        name: "Clicks",
        accessor: "LinkClicks",
      },
      {
        name: "Edit",
        callback: (data) => {
          setEconomyItemToEdit(data);
        },
        icon: Icon.Edit,
      },
      {
        name: "Delete",
        callback: (data) => adminCo2Actions.deleteCO2EconomyItem(data.Id),
        icon: Icon.Delete,
      },
    ],
    []
  );

  useEffect(() => {
    if (!CO2EconomyItems || CO2EconomyItems.length === 0) {
      adminCo2Actions.getCO2EconomyItems(0, 250);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CO2EconomyItems]);

  useEffect(() => {
    if (!communes || communes.length === 0) adminCommunesActions.getCommunes();
  }, [communes]);

  return (
    <>
      {!loading && (
        <Grid container>
          <AdminTable
            data={CO2EconomyItems}
            columns={columns}
            pagination={"server"}
            headerButton={
              <Grid container item justifyContent="flex-start">
                <Button color="primary" variant="contained">
                  <Add
                    onClick={() => {
                      setShowCreationDialog(true);
                      setEconomyItemToEdit(null);
                    }}
                  />
                </Button>
              </Grid>
            }
          />
        </Grid>
      )}
      {loading && (
        <Grid
          container
          sx={{
            overflowY: "auto",
            width: "100%",
            height: "60vh",
            padding: 0,
          }}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      )}
      <EconomyItemCreateEditDialog
        open={showCreationDialog || !!economyItemToEdit}
        economyItem={economyItemToEdit}
        groups={groups}
        communes={communes}
        handleClose={() => {
          setEconomyItemToEdit(null);
          setShowCreationDialog(false);
        }}
        editCO2EconomyItem={adminCo2Actions.editCO2EconomyItem}
        createCO2EconomyItem={adminCo2Actions.createCO2EconomyItem}
      />
    </>
  );
};

export default EconomyItems;
