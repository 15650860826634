import React, { useState, useEffect } from "react";
import { Grid, Collapse, Button, CardContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import MCard from "../../../components/Base/MyhouseCard";
import format from "date-fns/format";
import { SettingsHistoryValues } from "../../../constants/settingshistory";
import { UserSettingsHistoryModel } from "../../../definitions/model/UserSettings";

type EventData = {
  ChangedOn: string;
  NewValue: boolean | null;
  OldValue: boolean;
  SettingName: string;
};

type JournalProps = {
  history: UserSettingsHistoryModel[];
  getUserSettingsHistory: (signal: AbortSignal) => Promise<void>;
};

const UserJournal = (props: JournalProps): JSX.Element => {
  const { history, getUserSettingsHistory } = props;
  const { t } = useTranslation("translation");

  const [events, setEvents] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const collapsedHeight = 150;

  const toggleOpen = () => setOpen(!open);

  useEffect(() => {
    const abortController = new AbortController();
    const getHistoryAsync = async () => {
      try {
        setLoading(true);
        await getUserSettingsHistory(abortController.signal);
      } finally {
        setLoading(false);
      }
    };
    getHistoryAsync();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (history) {
      const formattedEvents: string[] = [];
      history.forEach((event: UserSettingsHistoryModel) =>
        event.History.forEach((item: EventData) => {
          const time = format(new Date(item.ChangedOn), "dd-MM-yyyy HH:mm");
          const response = item.NewValue ? "General.Enabled" : "General.Disabled";
          formattedEvents.push(
            item.SettingName == "LastLogin"
              ? `${time} ${SettingsHistoryValues[item.SettingName]}`
              : `${time} ${response} ${SettingsHistoryValues[item.SettingName]}`
          );
        })
      );

      setEvents(formattedEvents.reverse());
    }
  }, [history]);

  const stringTranslate = (str: string) => {
    const elements = str.split(" ");
    if (elements.length > 3) {
      const element = elements[2];
      str = str.replace(element, t(element));
    }

    const lastElement = str.split(" ").pop();

    if (lastElement) {
      let translatedText = t(lastElement);
      if (translatedText.includes("{{hostname}}")) {
        translatedText = t(lastElement, { hostname: window.location.hostname });
      }
      return str.replace(lastElement, t(translatedText));
    } else return str;
  };

  return (
    <MCard
      type="secondary"
      title={t("UserProfile.MyDigitalLog")}
      subheader={t("UserProfile.AboutLog") ?? ""}
      showProgress={loading}
    >
      <CardContent>
        {events && events.length > 0 && (
          <Collapse in={open} collapsedSize={collapsedHeight} timeout={{ enter: 500, exit: 500 }}>
            {events.map((event, index) => (
              <Grid xs={12} sx={{ fontSize: 12, padding: "2px 0" }} item key={index}>
                <Typography variant="body2">{stringTranslate(event)}</Typography>
              </Grid>
            ))}
          </Collapse>
        )}
        <Grid container justifyContent="center">
          <Button
            sx={{
              maxWidth: "300px",
              marginTop: "30px",
            }}
            fullWidth
            variant="contained"
            color="secondary"
            onClick={toggleOpen}
            disabled={events.length < 7}
          >
            {open && events.length > 6 ? t("General.Buttons.FoldIn") : t("General.Buttons.FoldOut")}
          </Button>
        </Grid>
      </CardContent>
    </MCard>
  );
};

export default UserJournal;
