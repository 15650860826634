import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  CreateBehaviourRuleFormProps,
  EditRuleInfoFormData,
} from "../defenitions";
import PathAutocompleteField from "./PathAutocompleteField";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";
import {styled} from "@mui/material";

const RuleForm = styled("form")(({theme})=>({
  width: "100%",
  border: `solid ${theme.palette.grey[300]} 1px`,
  padding: theme.spacing(1, 0),
  boxSizing: "border-box",
}))

const CreateBehaviourRuleForm = (
  props: CreateBehaviourRuleFormProps
): JSX.Element => {
  const methods = useForm<EditRuleInfoFormData>({
    defaultValues: {
      fields: [],
      from: "",
      name: "",
      path: "",
      to: "",
    },
  });
  const { handleSubmit, register, unregister, control, setValue, watch } =
    methods;
  useEffect(() => {
    register("fields");
    return () => unregister("fields");
  }, [register]);

  const fields = watch().fields;

  const handleFieldsChange = (value: string, checked: boolean) => {
    if (checked) {
      setValue("fields", [...fields, value]);
    } else {
      setValue(
        "fields",
        fields.filter((x) => x !== value)
      );
    }
  };

  const submit = (data: EditRuleInfoFormData) => {
    props.onSubmit(data);
  };

  return (
    <RuleForm onSubmit={handleSubmit(submit)}>
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={11}>
          <Typography variant="h5">Create new rule</Typography>
        </Grid>
        <Grid item xs={11}>
          <TextFieldConnector register={register("name", { required: true })}>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              label="Name"
            />
          </TextFieldConnector>
        </Grid>
        <Grid item xs={11}>
          <PathAutocompleteField
            onLoad={props.onAutocompleteLoad}
            loading={props.autocompleteLoading}
            label="Path"
            name="path"
            control={control}
          />
        </Grid>
        <Grid item container xs={11} alignItems="center">
          <Grid item xs={12}>
            <Typography>Fields:</Typography>
          </Grid>
          {props.inputs.map((x) => (
            <Grid item key={x}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!fields.find((f) => f === x)}
                    onChange={(e, checked) => handleFieldsChange(x, checked)}
                  />
                }
                label={x}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item xs={11} container spacing={1}>
          <Grid item xs={12}>
            <Typography>Period: </Typography>
          </Grid>
          <Grid item>
            <TextFieldConnector register={register("from")}>
              <TextField
                InputProps={{ notched: true }}
                InputLabelProps={{ shrink: true }}
                margin="dense"
                variant="outlined"
                type="date"
                label="From"
              />
            </TextFieldConnector>
          </Grid>
          <Grid item>
            <TextFieldConnector register={register("to")}>
              <TextField
                InputProps={{ notched: true }}
                InputLabelProps={{ shrink: true }}
                margin="dense"
                variant="outlined"
                type="date"
                label="To"
              />
            </TextFieldConnector>
          </Grid>
        </Grid>
        <Grid item xs={11} container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={props.onHide}
            >
              Close
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" type="submit">
              Create
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </RuleForm>
  );
};

export default CreateBehaviourRuleForm;
