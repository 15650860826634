import React, { useState, useEffect, SyntheticEvent } from "react";
import { useForm } from "react-hook-form";
import { Typography, Box, Grid, Link, useMediaQuery, TextField, useTheme, Container } from "@mui/material";
import PasswordField from "../components/Base/PasswordField";
import { emailRegexp } from "../services/validation.service";
import DataPoliticDialog from "../components/Dialogs/DataPoliticDialog";
import { UserInterest } from "../constants/enums";
import { CompleteRegistrationData, UserInterestsFormData } from "../definitions/model/User";
import { useUser } from "../actions";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TextFieldConnector } from "../components/Base/FormConnector";
import CircularProgressButton from "../components/Base/CircularProgressButton";
import { styled } from "@mui/material/styles";
import ClimateImage from "./../assets/img/seashore.jpg";
import ClimateIcons from "./../assets/img/icons-row.png";
import TopBar from "../components/Layout/TopBar";

const ClimateIconsTop = styled("img")(({ theme }) => ({
  maxWidth: 200,
  [theme.breakpoints.down("sm")]: {
    maxWidth: 150,
  },
}));

const Banner = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${ClimateImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  width: "100%",
  color: theme.palette.background.paper,
  flex: 1,
  position: "relative",
  flexWrap: "nowrap",
  minHeight: "calc(100vh - 50px)",
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 32px)",
    marginRight: 16,
    marginLeft: 16,
  },
}));

const AboutPartContainer = styled(Grid)(({ theme }) => ({
  padding: "20px 0",
  [theme.breakpoints.only("sm")]: {
    marginTop: 30,
    padding: "20px 0",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  color: theme.palette.background.paper,
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.background.paper + "!important",
  },
  ".MuiInputBase-input.MuiOutlinedInput-input": {
    color: theme.palette.background.paper,
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.background.paper,
  },
  ".MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled": {
    WebkitTextFillColor: theme.palette.background.paper,
  },
  ".MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled": {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.background.paper,
    },
  },
}));
const StyledPasswordField = styled(PasswordField)(({ theme }) => ({
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.background.paper + "!important",
  },
  ".MuiInputBase-input.MuiOutlinedInput-input": {
    color: theme.palette.background.paper,
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.background.paper,
  },
  ".MuiSvgIcon-root": {
    fill: theme.palette.background.paper,
  },
}));

interface Indexable {
  [key: string]: any;
}

type CompleteRegistrationFormData = {
  Name: string;
  Email: string;
  Password: string;
  UnitId: string;
};

const CommercialCompleteUserRegistration = (): JSX.Element => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { state } = useLocation();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const [openPolitic, setOpenPolitic] = useState(false);
  const [loading, setLoading] = useState(false);
  const [{ user }, { completeRegistration }] = useUser();
  const { t } = useTranslation("translation");

  const methods = useForm<CompleteRegistrationFormData & UserInterestsFormData>({
    defaultValues: {
      Name: state?.Name || user?.Name,
      Email: state?.Email || user?.Email,
      Password: state?.Password,
      UnitId: state?.UnitId,
      DIY: false,
      Culture: false,
      Accommodation: false,
      Garden: false,
    },
  });
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    register("UnitId");
    return () => unregister("UnitId"); // unregister input after component unmount
  }, [register, unregister]);

  const submit = (formData: CompleteRegistrationFormData & UserInterestsFormData) => {
    const data: CompleteRegistrationData = {
      Name: formData.Name,
      Email: formData.Email,
      Password: formData.Password,
      UnitId: formData.UnitId ?? null,
      Interests: [],
      IgnoreNotifications: false,
    };

    Object.entries(formData)
      .filter(([key, value]) => typeof value === "boolean" && value)
      .map(([key, value]) => key)
      .forEach((key) => {
        data.Interests.push((UserInterest as Indexable)[key]);
      });

    setLoading(true);
    completeRegistration(data)
      .then(() => navigate("/co2calculator"))
      .finally(() => setLoading(false));
  };
  const onPoliticClick = (e: SyntheticEvent) => {
    e.preventDefault();
    setOpenPolitic(true);
  };

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        overflow: "hidden",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        style={{
          minHeight: "100vh",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <TopBar hideAuth />
        <Banner item container alignItems={"center"} direction="column">
          <Grid item container py={2} justifyContent={"center"} alignItems="flex-start" sx={{ flexGrow: 1 }}>
            <Grid item pt={4} justifyContent="center" container>
              <ClimateIconsTop alt="" src={ClimateIcons} />
            </Grid>
            <Grid item container direction="column" alignItems="center" sx={{ flex: 1, flexWrap: "nowrap" }}>
              <Grid pt={3} container item direction="column" justifyContent="center" alignItems="center">
                <Typography sx={{ paddingBottom: isXs ? 2 : 4 }} align="center" variant="h1">
                  {t("Pages.StartPage.WelcomeToVoreklimaplan")}
                </Typography>
                <Grid container justifyContent="center">
                  <Grid item xs={11} sm={7} md={5} lg={4} xl={3}>
                    <form onSubmit={handleSubmit(submit)}>
                      <Grid item xs={12}>
                        <Box mb={2}>
                          <Typography>{t("Authorization.Forms.Name")}</Typography>
                          <TextFieldConnector
                            register={register("Name", {
                              required: true,
                              minLength: 3,
                            })}
                          >
                            <StyledTextField
                              variant="outlined"
                              fullWidth
                              type="text"
                              size="small"
                              error={!!errors.Name}
                            />
                          </TextFieldConnector>
                        </Box>
                        <Box mb={2}>
                          <Typography>{t("Authorization.Forms.EnterName")}</Typography>
                          <TextFieldConnector
                            register={register("Email", {
                              required: true,
                              pattern: emailRegexp,
                            })}
                          >
                            <StyledTextField
                              disabled={!user?.FaceBookId}
                              variant="outlined"
                              fullWidth
                              type="email"
                              size="small"
                              error={!!errors.Email}
                            />
                          </TextFieldConnector>
                        </Box>
                        {!user?.FaceBookId && (
                          <Box mb={1}>
                            <Typography>{t("Authorization.Forms.Password")}</Typography>
                            <TextFieldConnector
                              register={register("Password", {
                                required: true,
                                minLength: 6,
                              })}
                            >
                              <StyledPasswordField
                                variant="outlined"
                                fullWidth
                                size="small"
                                error={!!errors.Password}
                              />
                            </TextFieldConnector>
                          </Box>
                        )}
                      </Grid>
                      <Grid container mb={2} item alignItems="center" justifyContent="center">
                        <Link
                          variant="body1"
                          sx={{ color: "inherit", cursor: "pointer", textDecoration: "underline" }}
                          onClick={onPoliticClick}
                        >
                          {t("ServiceBook.Workers.OurPersonalDataPolicy")}
                        </Link>
                      </Grid>
                      <Grid item container justifyContent="center">
                        <CircularProgressButton
                          type="submit"
                          sx={{ width: "150px" }}
                          color="success"
                          inProgress={loading}
                        >
                          {t("General.Buttons.Next")}
                        </CircularProgressButton>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <AboutPartContainer container item justifyContent="center">
            <Grid item xs={11} sm={8}>
              <Typography align="center" variant="body1" paragraph>
                {t("CO2Calculator.StartPage.MoreAboutClimatePlan")}
              </Typography>
              <Typography align="center" variant="body1">
                {t("CO2Calculator.StartPage.ModelsDev")}
              </Typography>
            </Grid>
          </AboutPartContainer>
          <DataPoliticDialog open={openPolitic} closeDialog={() => setOpenPolitic(false)} />
        </Banner>
      </Grid>
    </Container>
  );
};

export default CommercialCompleteUserRegistration;
