import { CompanyAdModel } from "../definitions/Company";
import { formatDateToString, formatStringToDate } from "../pages/House/Map/utils";
import ApiService from "./api.service";


const CompanyAdService = {
  getCompanyAds: async (skip: number, take: number): Promise<CompanyAdModel[]> => {
    const url = `/api/admin/company/ads?filter=&skip=${skip}&take=${take}`;
    let result = await ApiService.get<CompanyAdModel[]>(url);
    result = result.map((ad) => ({
      ...ad,
      ActiveFrom: formatStringToDate(ad.ActiveFrom),
      ActiveTo: formatStringToDate(ad.ActiveTo),
    }));
    return result;
  },

  addCompanyAd: async (ad: CompanyAdModel): Promise<CompanyAdModel> => {
    const mappedAd = {
      ...ad,
      ActiveFrom: formatDateToString(ad.ActiveFrom),
      ActiveTo: formatDateToString(ad.ActiveTo),
    };
    const url = `/api/admin/company/ads/`;
    let result = await ApiService.post<CompanyAdModel>(url, mappedAd);
    result = {
      ...result,
      ActiveFrom: formatStringToDate(ad.ActiveFrom),
      ActiveTo: formatStringToDate(ad.ActiveTo),
    };
    return result;
  },

  editCompanyAd: async (ad: CompanyAdModel): Promise<CompanyAdModel> => {
    const mappedAd = {
      ...ad,
      ActiveFrom: formatDateToString(ad.ActiveFrom),
      ActiveTo: formatDateToString(ad.ActiveTo),
    };
    const url = `/api/admin/company/ads/`;
    let  result = await ApiService.put<CompanyAdModel>(url, mappedAd);
    result = {
      ...result,
      ActiveFrom: formatStringToDate(ad.ActiveFrom),
      ActiveTo: formatStringToDate(ad.ActiveTo),
    };
    return result;
  },

  deleteCompanyAd: async (companyId: string, adId: string): Promise<boolean> => {
    const url = `/api/admin/company/ads/${companyId}/${adId}`;
    await ApiService.delete(url);
    return true;
  },
};

export default CompanyAdService;
