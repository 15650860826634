import React, { useState } from "react";
import { Grid, CardActions, Box, Collapse, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import Timeline from "./Timeline";
import { TimelineModel, TimelineItem } from "../../../definitions/Timeline";
import MCard from "../../../components/Base/MyhouseCard";

type HouseTimeline = {
  timelineItems: TimelineModel;
};

const HouseTimeline = ({ timelineItems }: HouseTimeline): JSX.Element => {
  const toggleOpen = () => setOpen(!open);
  const itemsArray = Object.entries(timelineItems).reverse() as [
    [string, TimelineItem[]]
  ];
  const itemsLength = itemsArray.reduce((sum, item) => sum + item[1].length, 0);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("translation");

  const collapsedHeight = 252;

  return (
    <MCard type="main" title={t("Pages.HouseTimeline.HousingTimeline")}>
      <>
        <Grid container justifyContent="center">
          <Grid item md={10} sm={12}>
            <Box width="100%" my={2}>
              {itemsArray && itemsArray.length > 0 && (
                <Collapse
                  in={open}
                  collapsedSize={collapsedHeight}
                  timeout={{ enter: 1500, exit: 500 }}
                >
                  <Timeline items={itemsArray} />
                </Collapse>
              )}
            </Box>
          </Grid>
        </Grid>
        <CardActions>
          <Box mb={2} my={1.2} width="100%">
            <Grid container justifyContent="center">
              <Button
                color="secondary"
                variant="contained"
                onClick={toggleOpen}
                disabled={itemsLength < 3}
              >
                {open && itemsLength > 2
                  ? t("General.Buttons.FoldIn")
                  : t("General.Buttons.FoldOut")}
              </Button>
            </Grid>
          </Box>
        </CardActions>
      </>
    </MCard>
  );
};
export default HouseTimeline;
