import React from "react";
import { Container } from "@mui/material";
import MainContainer from "../../../components/Layout/MainContainer";
import Folders from "./Folders";
import { useUnit } from "../../../actions/unit.actions";
import { DocumentSourceType } from "../../../definitions/Document";

const DocumentsPage = () => {
  const [{ unit }] = useUnit();
  return (
    <MainContainer title="Mine boligdokumenter">
      <Container maxWidth="md">
        <Folders sourceItemId={unit?.Id ?? ""} sourceType={DocumentSourceType.Unit} />
        {unit && (
          <Folders
            sourceItemId={unit.BuildingId ?? ""}
            sourceType={DocumentSourceType.Building}
            readonly
            childSourceItemId={unit.Id}
            color="info"
          />
        )}
      </Container>
    </MainContainer>
  );
};
export default DocumentsPage;
