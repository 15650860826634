import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Button,
  DialogActions,
  DialogContent,
  FormLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  styled,
  Typography,
} from "@mui/material";
import { matchIsValidTel } from "mui-tel-input";
import { DeleteForever, Photo } from "@mui/icons-material";
import { ControllerConnector, TextFieldConnector } from "../../../../../components/Base/FormConnector";
import { PhoneRegexp } from "../../../../../services/validation.service";
import { PhoneField } from "../../../../../components/Base/FormattedFields";
import { CompanyGeneralInfo, CompanySustainabilityInfo } from "../../../../../definitions/Company";
import FileInput from "../../../../../components/Base/FileInput";
import { useCompanies } from "../../../../../actions/admin/admin.company.actions";
import MyhouseCheckbox from "../../../../../components/Base/MyhouseCheckbox";

type CompanyFormData = CompanySustainabilityInfo & CompanyGeneralInfo;

type CompanyDataFormProps = {
  id?: string;
  existingGeneralInfo?: CompanyGeneralInfo;
  existingSustainabilityInfo?: CompanySustainabilityInfo;
  submit: (generalInfo: CompanyGeneralInfo, sustainanilityInfo: CompanySustainabilityInfo) => void;
  handleClose: () => void;
};

const CompanyDataForm = (props: CompanyDataFormProps): JSX.Element => {
  const methods = useForm<CompanyFormData>({
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
    control,
  } = methods;

  const {
    fields: addresses,
    append: appendAddress,
    remove: removeAddress,
  } = useFieldArray({
    control,
    name: "Addresses",
  });

  const [{ companies }, companiesActions] = useCompanies();
  const [focusedField, setFocusedField] = useState("Name");
  const { t } = useTranslation("translation");
  const DigitsRegexp = /^[0-9]*$/;

  useEffect(() => {
    if (props.existingGeneralInfo) {
      // for some reason all values are undefined on sumbit when reset is used. Use setValue instead
      setValue("Name", props.existingGeneralInfo.Name);
      setValue("LogoUrl", props.existingGeneralInfo.LogoUrl);
      setValue("WebSite", props.existingGeneralInfo.WebSite);
      setValue("OfficialName", props.existingGeneralInfo.OfficialName);
      setValue("Phone", props.existingGeneralInfo?.Phone ?? "");
      setValue("Addresses", props.existingGeneralInfo.Addresses);
      setValue("CvrNumber", props.existingGeneralInfo.CvrNumber);
      setValue("Country", props.existingGeneralInfo.Country);
      setValue("EmployeesNumber", props.existingGeneralInfo.EmployeesNumber);
      setValue("IsOurClient", props.existingGeneralInfo.IsOurClient ?? false);
      setValue("IsActiveClient", props.existingGeneralInfo.IsActiveClient ?? false);
      setValue("RightMaterials", props.existingSustainabilityInfo?.RightMaterials ?? null);
      setValue("Optimization", props.existingSustainabilityInfo?.Optimization ?? null);
      setValue("LongLife", props.existingSustainabilityInfo?.LongLife ?? null);
      setValue("UseAgain", props.existingSustainabilityInfo?.UseAgain ?? null);
      setValue("Innovation", props.existingSustainabilityInfo?.Innovation ?? null);
    }
  }, [props.existingGeneralInfo, props.existingSustainabilityInfo]);

  type FormTextBoxProps = {
    fieldName: keyof CompanyFormData;
    required?: boolean;
    autofocus?: boolean;
    multiline?: boolean;
    label?: string;
    pattern?: RegExp;
    defaultValue?: string;
  };

  const FormTextBox = (props: FormTextBoxProps): JSX.Element => {
    return (
      <TextFieldConnector
        register={register(props.fieldName, {
          required: props.required,
          pattern: props.pattern,
        })}
      >
        <TextField
          variant="outlined"
          autoFocus={props.autofocus || props.fieldName === focusedField}
          margin="dense"
          label={props.label ?? props.fieldName}
          multiline={props.multiline}
          fullWidth
          error={!!errors[props.fieldName]}
          onClick={() => setFocusedField(props.fieldName)}
        />
      </TextFieldConnector>
    );
  };

  const FormAddressesList = (): JSX.Element => {
    return (
      <>
        Addresses
        {addresses.map((_, index) => (
          <Grid container xs={12} key={index}>
            <Grid item xs={10}>
              <TextFieldConnector
                register={register(`Addresses.${index}.Address`, {
                  required: false,
                })}
              >
                <TextField type="text" fullWidth variant="outlined" />
              </TextFieldConnector>
            </Grid>
            <Grid item xs={2}>
              <Button variant="text" type="button" onClick={() => removeAddress(index)}>
                Remove
              </Button>
            </Grid>
          </Grid>
        ))}
        <Button
          variant="text"
          type="button"
          onClick={() => appendAddress({ Address: "", Latitude: null, Longitude: null })}
        >
          Add Address
        </Button>
      </>
    );
  };

  const FormPhoneField = (props: FormTextBoxProps): JSX.Element => {
    return (
      <ControllerConnector
        name="Phone"
        rules={{
          pattern: props.pattern,
          validate: (e: any) => {
            if (e) {
              return matchIsValidTel(e);
            }
          },
        }}
      >
        <PhoneField
          variant="outlined"
          autoFocus={props.autofocus || props.fieldName === focusedField}
          margin="dense"
          label={props.label ?? props.fieldName}
          multiline={props.multiline}
          fullWidth
          error={!!errors[props.fieldName]}
          defaultValue={props.defaultValue}
          onClick={() => setFocusedField(props.fieldName)}
        />
      </ControllerConnector>
    );
  };

  const StyledFormLabel = styled(FormLabel)(() => ({
    "&.MuiFormLabel-root": {
      display: "inline-flex",
      alignItems: "flex-start",
      "& .MuiFormControlLabel-root": {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  }));

  const FormCheckBox = (props: FormTextBoxProps) => {
    return (
      <Grid container item>
        <StyledFormLabel>
          <ControllerConnector name={props.fieldName} rules={{ required: props.required }} type="checkbox">
            <MyhouseCheckbox error={!!errors[props.fieldName]} onClick={() => setFocusedField(props.fieldName)} />
          </ControllerConnector>
          {props.label ?? (
            <Grid container>
              <Typography variant="body1">{props.label}</Typography>
            </Grid>
          )}
        </StyledFormLabel>
      </Grid>
    );
  };

  const FormLogoBox = (props: FormTextBoxProps): JSX.Element => {
    const [src, setSrc] = useState<string | undefined>(props.defaultValue);

    useEffect(() => {
      setSrc(getValues()[props.fieldName]?.toString() ?? undefined);
    }, [watch()[props.fieldName]]);

    return (
      <Grid container direction="column" item md={6}>
        <ImageList sx={{ width: 500, height: 250 }}>
          <ImageListItem
            key={props.fieldName}
            cols={2}
            rows={1}
            sx={{ "& img.MuiImageListItem-img ": { height: 250, width: "fit-content" } }}
          >
            {src && <img src={src} loading="lazy" />}
            <ImageListItemBar
              title="Logo"
              // subtitle={item.author}
              actionIcon={
                <>
                  <FileInput getPicture={updatePictureHandler}>
                    <IconButton color="secondary" size="large">
                      <Photo></Photo>
                    </IconButton>
                  </FileInput>
                  <IconButton onClick={deletePictureHandler}>
                    <DeleteForever />
                  </IconButton>
                </>
              }
            />
          </ImageListItem>
        </ImageList>
      </Grid>
    );
  };

  const updatePictureHandler = async (image: File) => {
    if (props.id) {
      const logoUrl = await companiesActions.updateCompanyLogo(props.id, image);
      if (logoUrl) {
        setValue("LogoUrl", logoUrl);
      }
    }
  };

  const deletePictureHandler = async () => {
    setValue("LogoUrl", null);
    //TODO: delete picture from server
  };

  const submit = (values: CompanyFormData) => {
    const generalInfo = {
      Name: values.Name,
      LogoUrl: values.LogoUrl,
      WebSite: values.WebSite,
      OfficialName: values.OfficialName,
      Phone: values.Phone || "",
      Addresses: values.Addresses,
      CvrNumber: values.CvrNumber,
      Country: values.Country,
      EmployeesNumber: values.EmployeesNumber,
      IsOurClient: values.IsOurClient,
      IsActiveClient: values.IsActiveClient,
    };

    const sustainabilityInfo = {
      RightMaterials: values.RightMaterials,
      Optimization: values.Optimization,
      LongLife: values.LongLife,
      UseAgain: values.UseAgain,
      Innovation: values.Innovation,
    };
    props.submit(generalInfo, sustainabilityInfo);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)}>
        <DialogContent>
          <FormTextBox fieldName="Name" required={true} />
          <FormLogoBox fieldName="LogoUrl" defaultValue={getValues("LogoUrl") ?? undefined}></FormLogoBox>
          <FormTextBox fieldName="WebSite" />
          <FormTextBox fieldName="OfficialName" />
          <FormPhoneField fieldName="Phone" pattern={PhoneRegexp} />
          <FormTextBox fieldName="CvrNumber" label="CVR Number" />
          <FormTextBox fieldName="Country" />
          <FormTextBox fieldName="EmployeesNumber" label="Number of employees" pattern={DigitsRegexp} />
          <FormCheckBox fieldName="IsOurClient" label="CDM client" />
          <FormCheckBox fieldName="IsActiveClient" label="Our climate client" />
          <FormAddressesList />
          <Typography variant="body1">{t("Admin.Company.RightMaterials1")}</Typography>
          <Typography variant="body2">{t("Admin.Company.RightMaterials2")}</Typography>
          <FormTextBox fieldName="RightMaterials" label="Clean" multiline={true} />
          <Typography variant="body1">{t("Admin.Company.Optimization1")}</Typography>
          <Typography variant="body2">{t("Admin.Company.Optimization2")}</Typography>
          <FormTextBox fieldName="Optimization" label="Less" multiline={true} />
          <Typography variant="body1">{t("Admin.Company.LongLife1")}</Typography>
          <Typography variant="body2">{t("Admin.Company.LongLife2")}</Typography>
          <FormTextBox fieldName="LongLife" label="Slow" multiline={true} />
          <Typography variant="body1">{t("Admin.Company.UseAgain1")}</Typography>
          <Typography variant="body2">{t("Admin.Company.UseAgain2")}</Typography>
          <FormTextBox fieldName="UseAgain" label="Use again" multiline={true} />
          <Typography variant="body1">{t("Admin.Company.Innovation1")}</Typography>
          <Typography variant="body2">{t("Admin.Company.Innovation2")}</Typography>
          <FormTextBox fieldName="Innovation" multiline={true} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="button" onClick={props.handleClose} color="secondary">
            {t("General.Buttons.Close")}
          </Button>
          <Button variant="contained" type="submit" color="secondary">
            {t("General.Buttons.Save")}
          </Button>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default CompanyDataForm;
