import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useBuilding } from "../../actions/building.actions";
import BuildingInfo from "./components/BuildingInfo";
import RelatedBuildings from "./components/RelatedBuildings";

const BuildingPage = (): JSX.Element => {
  const { addressURL } = useParams<{ addressURL: string }>();

  const [{ building }, buidingActions] = useBuilding();

  useEffect(() => {
    if (addressURL) {
      const addressParts = addressURL.split("_");
      const id = addressParts[addressParts.length - 1];
      if (building?.Id !== id) {
        buidingActions.getBuilding(id);
      }
    }
  }, [addressURL]);

  return (
    <>
      <BuildingInfo />
      {building && building.OtherBuildings?.length > 0 && <RelatedBuildings buildings={building.OtherBuildings} />}
    </>
  );
};

export default BuildingPage;
