import React, { useEffect, useReducer } from "react";
import { Grid, Hidden, Slide, Box, useMediaQuery, useTheme, styled } from "@mui/material";
import CarIcon from "./../../ClimatePlan/shared/components/img/klima-icons/Transport-Icon.svg";
import ElectricityIcon from "./../../ClimatePlan/shared/components/img/klima-icons/Appliances-Icon.svg";
import FoodIcon from "./../../ClimatePlan/shared/components/img/klima-icons/Food-Icon.svg";
import HomeIcon from "./../../ClimatePlan/shared/components/img/klima-icons/Home-Icon.svg";
import MobileIcon from "./../../ClimatePlan/shared/components/img/klima-icons/Mobile-Icon.svg";
import ShoppingIcon from "./../../ClimatePlan/shared/components/img/klima-icons/Clothing-Icon.svg";
import VacationIcon from "./../../ClimatePlan/shared/components/img/klima-icons/Travel-Icon.svg";
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Button } from "@mui/material";
import { ArrowBack, Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import LandingArticle from "../../../components/Base/LandingArticle";
import { LandingArticleDto } from "../../../definitions/Landing";
import Widget from "../../NewsStream/components/Widget";
import { ArticleCategory, WidgetPosition } from "../../../constants/enums";
import { useWindowInfiniteScroll } from "../../../helpers/hooks";
import { WidgetModel } from "../../../definitions/Landing";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { LanguageType } from "../../../definitions/Menu";

type ArticleListProps = {
  getWidgets: (skip: number, take: number, languageId?: LanguageType ) => void;
  widgets: WidgetModel[];
  widgetsCount: number;
  articles: LandingArticleDto[];
  loading: boolean;
};

type ClubPageState = {
  filterCategory: ArticleCategory | null;
  renderedStream: JSX.Element[];
};

const initialState: ClubPageState = {
  filterCategory: null,
  renderedStream: [],
};

const reducer = (
  state: ClubPageState,
  action: { type: string; payload: any }
) => {
  return { ...state, [action.type]: action.payload };
};

const ArticleList = (props: ArticleListProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation("translation");

  useWindowInfiniteScroll(() => {
    if (!props.loading && props.widgets.length !== props.widgetsCount) {
      props.getWidgets(props.widgets.length, 10, i18n.language as LanguageType);
    }
  });

  useEffect(() => {
    if (props.widgets.length === 0) {
      props.getWidgets(0, 10, i18n.language as LanguageType);
    }
  }, [props.widgets, i18n.language]);

  useEffect(() => {
    const stream = renderClubArticlesStream();
    dispatch({ type: "renderedStream", payload: stream });
  }, [props.widgets, props.articles, state.filterCategory]);

  const renderClubArticlesStream = () => {
    const stream = [];
    const widgets = isSmDown
      ? props.widgets
      : props.widgets.filter((w) => w.Position === WidgetPosition.Stream);
    if (!props.articles || !props.articles.length) {
      return [];
    }

    const filteredArticles = props.articles.filter(
      (article) =>
        !state.filterCategory ||
        article.Categories.indexOf(state.filterCategory) >= 0
    );
    for (let i = 0; i < filteredArticles.length; i++) {
      const widgetIndex = i / 3 - 1; //not show as first item
      if (i % 3 === 0 && widgetIndex >= 0 && widgetIndex < widgets.length) {
        stream.push(
          <Widget model={widgets[widgetIndex]} inStream></Widget>
        );
      }

      stream.push(
        <LandingArticle categorized={true} model={filteredArticles[i]} type="preview" />
      );
    }
    return stream;
  };

  const handleFilterChange = (
    _: React.MouseEvent<HTMLElement>,
    newFilter: ArticleCategory | null
  ) => {
    dispatch({ type: "filterCategory", payload: newFilter });
  };

  return (
    <>
      <Grid container>
        {(window.history.state.idx !== 0) && (
          <Button type="submit" onClick={() => navigate("/overview")}>
            <ArrowBack fontSize="small" />
            {t("General.Buttons.GoBack")}
          </Button>
        )}
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent={isSmDown ? "center" : "flex-start"}
        style={{ marginBottom: 10 }}
      >
        <ToggleButtonGroup
          value={state.filterCategory}
          exclusive
          onChange={handleFilterChange}
          size={isSmDown ? "small" : "medium"}
        >
          <ToggleButton
            value={ArticleCategory.Transport}
            aria-label="Transport"
          >
            <CategoryIcon
            src={CarIcon}
            key="1"
            alt=""
          ></CategoryIcon>
          </ToggleButton>
          <ToggleButton value={ArticleCategory.Vacation} aria-label="Ferie">
          <CategoryIcon
            src={VacationIcon}
            key="2"
            alt=""
          ></CategoryIcon>
          </ToggleButton>
          <ToggleButton value={ArticleCategory.Clothes} aria-label="Tøj">
           <CategoryIcon
            src={ShoppingIcon}
            key="3"
            alt=""
          ></CategoryIcon>
          </ToggleButton>
          <ToggleButton value={ArticleCategory.Food} aria-label="Mad">
          <CategoryIcon
            src={FoodIcon}
            key="4"
            alt=""
          ></CategoryIcon>
          </ToggleButton>
          <ToggleButton value={ArticleCategory.Gadgets} aria-label="Telefon">
          <CategoryIcon
            src={MobileIcon}
            key="5"
            alt=""
          ></CategoryIcon>
          </ToggleButton>
          <ToggleButton value={ArticleCategory.Electricity} aria-label="Strøm">
          <CategoryIcon
            src={ElectricityIcon}
            key="6"
            alt=""
          ></CategoryIcon>
          </ToggleButton>
          <ToggleButton value={ArticleCategory.Heating} aria-label="Varme">
          <CategoryIcon
            src={HomeIcon}
            key="7"
            alt=""
          ></CategoryIcon>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {state.filterCategory && (
        <Grid container>
          <Button
            type="submit"
            color="secondary"
            onClick={() => dispatch({ type: "filterCategory", payload: null })}
          >
            <Close fontSize="small" />
            {t("General.Buttons.ResetFilter")}
          </Button>
        </Grid>
      )}
      <Grid container spacing={1} justifyContent="space-between">
        <Grid container item sm={12} md={8}>
          {state.renderedStream.map((item, index) => (
            <Grid item xs={12} key={index} mb={2.25}>
              <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                <Grid container>{item}</Grid>
              </Slide>
            </Grid>
          ))}
        </Grid>
        <Hidden smDown>
          <Grid container item direction="column" xs={4} spacing={3}>
            {props.widgets &&
              props.widgets
                .filter(
                  (widget) => widget.Position === WidgetPosition.RightSide
                )
                .map((widget, index) => (
                  <Grid item key={widget.Id}>
                    <Slide
                      key={widget.Id}
                      direction="up"
                      in={true}
                      mountOnEnter
                      unmountOnExit
                    >
                      <Box minWidth={275} maxWidth={300}>
                        <Widget
                          model={widget}
                        />
                      </Box>
                    </Slide>
                  </Grid>
                ))}
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export default ArticleList;

const CategoryIcon = styled("img")({
  width: 30
});