import {InputLabel, Select, MenuItem, SelectChangeEvent, styled} from "@mui/material";
import MergeIcon from "@mui/icons-material/Merge";
import React from "react";
import { EditablePerson } from "./EditPersons";
import { ClimatPlanUser } from "../ClimatePlanPage";

type MergeUserSelectProps = {
  user: ClimatPlanUser | null;
  persons: EditablePerson[];
  inButton?: boolean;
  mergeHandler: (
    e: SelectChangeEvent<string>,
    personId: string | undefined
  ) => void;
};

const MergeUsersSelect = (props: MergeUserSelectProps) => {
  const { persons, mergeHandler, inButton } = props;
  return (
    <StyledInputLabel
      id={props.user?.Id}
      style={{
        right: inButton ? "-25%" : "",
      }}
    >
      <Select
        id={props.user?.Id}
        value={""}
        style={{ position: "absolute", opacity: 0 }}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) =>
          mergeHandler(e as SelectChangeEvent<string>, props.user?.Id)
        }
      >
        {persons.map((personInPlan) => (
          <MenuItem key={personInPlan.Id} value={personInPlan.Id}>
            {personInPlan.Name}
          </MenuItem>
        ))}
      </Select>
      <MergeIcon />
    </StyledInputLabel>
  );
};

const StyledInputLabel = styled(InputLabel)({
    position: "relative",
    height: "24px",
});

export default MergeUsersSelect;
