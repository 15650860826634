import React, { useEffect, useMemo, useReducer, useState } from "react";
import {
  Box,
  Card,
  CircularProgress,
  Container,
  Grid,
  Grow,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import MainContainer from "../../components/Layout/MainContainer";
import { LoadWrapper } from "../../components/Base";
import { OwnedUnitDto } from "../../definitions/model/unit/Unit";
import HouseCard from "./components/HouseCard";
import { useUnit, useUser } from "../../actions";
import { useLocation, useNavigate, replace } from "react-router-dom";
import MoveOutDialog from "../../components/Dialogs/MoveOutDialog/MoveOutDialog";
import { GroupMembersDto, GroupMemberDto } from "../../definitions/model/Group";
import { GroupMemberRole } from "../../constants/enums";
import SelectCurrentHouseDialog from "./components/ChangeCurrentHouseDialog";
import { useTranslation } from "react-i18next";
import { useDocument } from "../../actions/document.actions";
import ChangePropertyDialog, { ChangePropertyData } from "./components/ChangePropertyDialog";
import UnitHelper from "../../helpers/unit-helper";

type HousesListState = {
  houses: OwnedUnitDto[];
  fadebleItems: number[];
  loading: boolean;
  openMoveOut: boolean;
  openOwnerChange: boolean;
  openSelectNewHouse: boolean;
  openRemoveDocuments: boolean;
  moveOutProperty: { Id: string; HasCO2Plan: boolean } | null;
  moveOutPropertyFamily: GroupMembersDto | null;
};

const StyledCard = styled(Card)(({ theme }) => ({
  transition: "border-color 0.3s",
  cursor: "pointer",
  height: "100%",
  "&:hover": {
    borderColor: theme.palette.grey[600],
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
}));

const StyledAddCircleOutline = styled(AddCircleOutline)(({ theme }) => ({
  fontSize: "6rem",
  width: "6rem",
  height: "6rem",
  transition: "color 0.3s",
  color: theme.palette.grey[500],
}));

const reducer = (
  state: HousesListState,
  action: {
    type:
      | keyof HousesListState
      | "changeCurrentHouse"
      | "changeCurrentHouseSucceded"
      | "openMoveOut"
      | "closeMoveOut"
      | "openChangeOwner"
      | "closeChangeOwner"
      | "openSelectNew"
      | "closeSelectNew"
      | "openRemoveDocuments"
      | "closeRemoveDocuments";
    payload?: unknown;
  }
): HousesListState => {
  switch (action.type) {
    case "changeCurrentHouse":
      return {
        ...state,
        fadebleItems: action.payload as number[],
        loading: true,
      };
    case "changeCurrentHouseSucceded":
      return {
        ...state,
        fadebleItems: [],
        houses: action.payload as OwnedUnitDto[],
        loading: false,
      };
    case "openMoveOut":
      return {
        ...state,
        openMoveOut: true,
      };
    case "closeMoveOut":
      return { ...state, openMoveOut: false };
    case "openChangeOwner":
      return {
        ...state,
        openOwnerChange: true,
      };
    case "closeChangeOwner":
      return { ...state, openOwnerChange: false };
    case "openSelectNew":
      return { ...state, openSelectNewHouse: true };
    case "closeSelectNew":
      return { ...state, openSelectNewHouse: false };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

const initialState: HousesListState = {
  houses: [],
  fadebleItems: [],
  loading: false,
  openMoveOut: false,
  openOwnerChange: false,
  openSelectNewHouse: false,
  openRemoveDocuments: false,
  moveOutProperty: null,
  moveOutPropertyFamily: null,
};

const HousesList = (): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("md"));
  const [changePropertyData, setChangePropertyData] = useState<ChangePropertyData | null>(null);
  const [isChangePropertyDialogOpen, setIsChangePropertyDilaogOpen] = useState(false);
  const [{ user }, userActions] = useUser();
  const [, unitActions] = useUnit();
  const [, documentActions] = useDocument();

  const { t } = useTranslation("translation");
  useEffect(() => {
    if (state.houses.length < 1) {
      dispatch({ type: "loading", payload: true });
      unitActions
        .getOwnedHouses()
        .then((h) => {
          if (h.length > 0) dispatch({ type: "houses", payload: h });
        })
        .finally(() => dispatch({ type: "loading", payload: false }));
    }
  }, [state.houses]);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const isFromDocumentsRemove = Boolean(params.get("fromDocumentsRemove"));
      if (isFromDocumentsRemove && location.state?.selectedUnitId) {
        if (isFromDocumentsRemove) {
          const externalState = location.state.externalState;
          sendMoveOutRequests(location.state.selectedUnitId, externalState.removeAll, externalState.newFamilyOwner);
        }
      }
      replace(location.pathname);
    }
  }, [location.search]);

  const handleChangeHouse = async (id: string) => {
    const { houses } = state;
    const newInd = houses.findIndex((x) => x.Id === id);
    const oldInd = houses.findIndex((x) => x.IsCurrentHouse);
    if (newInd === oldInd) return;

    const changeData: ChangePropertyData = {
      adressIn: UnitHelper.GetFullAddress(houses[newInd].Address),
      adressOut: UnitHelper.GetFullAddress(houses[oldInd]?.Address),
    };

    setChangePropertyData(changeData);
    dispatch({ type: "changeCurrentHouse", payload: [newInd, oldInd] });
    await userActions.changeCurrentHouse(id);
    houses[newInd].IsCurrentHouse = true;
    if(houses[oldInd]) houses[oldInd].IsCurrentHouse = false;

    dispatch({ type: "changeCurrentHouseSucceded", payload: houses });
    setIsChangePropertyDilaogOpen(true);
  };

  const handleChangeHouseAfterUpdate = async (id: string) => {
    const { houses } = state;
    const newSelectedHouseIndex = houses.findIndex((x) => x.Id === id);
    dispatch({
      type: "changeCurrentHouse",
      payload: [newSelectedHouseIndex],
    });

    userActions.changeCurrentHouse(id);
    houses[newSelectedHouseIndex].IsCurrentHouse = true;
    dispatch({ type: "changeCurrentHouseSucceded", payload: houses });
  };

  const closeMoveOut = () => dispatch({ type: "closeMoveOut" });
  const openSelectNew = () => dispatch({ type: "openSelectNew" });
  const closeSelectNew = () => dispatch({ type: "closeSelectNew" });
  const getFilteredHouses = () =>
    state.houses.filter(
      (x) => x.Id !== state.moveOutProperty?.Id && x.CurrentUserRole !== GroupMemberRole.NotValidMember
    );

  const isNewOwnerRequired = (family: GroupMembersDto) => {
    const approvedMembers = family.Members.filter((m) => m.Role !== GroupMemberRole.NotValidMember);
    const currentUser = approvedMembers.find((x) => x.UserId === user?.Id);

    return approvedMembers.length > 1 && currentUser?.Role === GroupMemberRole.Administrator;
  };

  const handleMoveOut = async (unitId: string) => {
    dispatch({ type: "loading", payload: true });
    await userActions.moveOutUser(unitId);
    const { houses } = state;

    if (state.houses.find((x) => x.Id === unitId)?.IsCurrentHouse) {
      const filteredHouses = getFilteredHouses();
      if (filteredHouses.length === 1) {
        const newUnitId = filteredHouses.find((x) => x.Id !== unitId)?.Id ?? "";
        if (newUnitId) {
          const newSelectedHouseIndex = houses.findIndex((x) => x.Id === newUnitId);
          dispatch({
            type: "changeCurrentHouse",
            payload: [newSelectedHouseIndex],
          });

          await userActions.changeCurrentHouse(newUnitId);
          houses[newSelectedHouseIndex].IsCurrentHouse = true;
        }
      }
      if (filteredHouses.length > 1) openSelectNew();
    }

    dispatch({
      type: "changeCurrentHouseSucceded",
      payload: houses.filter((x) => x.Id !== unitId),
    });
  };

  const hasDocuments = useMemo(() => {
    const house = state.houses.find((x) => x.Id === state.moveOutProperty?.Id);
    return house ? house.DocumentsCount > 0 : false;
  }, [state.houses, state.moveOutProperty]);

  const handleMoveoutClick = async (unitId: string, hasCo2Plan: boolean) => {
    const family = (await userActions.getUserFamilies(unitId))[0];
    if (family) {
      dispatch({ type: "openMoveOut" });
      dispatch({
        type: "moveOutPropertyFamily",
        payload: family,
      });
      dispatch({
        type: "moveOutProperty",
        payload: { Id: unitId, HasCO2Plan: hasCo2Plan },
      });
    }
  };

  const sendMoveOutRequests = async (unitId: string, removeAllDocuments?: boolean, newFamilyOwner?: GroupMemberDto) => {
    if (removeAllDocuments && state.moveOutProperty !== null) {
      await documentActions.deleteDocuments(state.moveOutProperty.Id, [], true);
    }
    if (newFamilyOwner) {
      await userActions.assignNewFamilyOwner(newFamilyOwner, unitId);
    }
    await handleMoveOut(unitId);
  };

  const navigateToFamily = (id: string) => {
    navigate("/family/" + id);
  };

  const navigateToHouseSearch = () => {
    navigate("/search");
  };

  return (
    <MainContainer title={t("HouseManagement.HousesList") ?? ""}>
      <Container maxWidth="md">
        <LoadWrapper
          isLoading={state.loading}
          result={state.houses}
          emptyResultComponent={
            <Grid container justifyContent="center">
              <Grid item md={4} sm={6} xs={12}>
                <Grow in>
                  <Box height={400}>
                    <StyledCard variant="outlined" onClick={navigateToHouseSearch}>
                      <Grid container alignItems="center" justifyContent="center" direction="column">
                        <StyledAddCircleOutline />
                        <Typography variant="h5">{t("HouseManagement.AddNewHouse")}</Typography>
                      </Grid>
                    </StyledCard>
                  </Box>
                </Grow>
              </Grid>
            </Grid>
          }
          loadingComponent={
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          }
        >
          <Box mb={2}>
            <Grid container spacing={2} alignContent="stretch" justifyContent={isSmDown ? "center" : "flex-start"}>
              <Grid item md={4} sm={6} xs={12}>
                <Grow in>
                  <Box height="100%">
                    <StyledCard variant="outlined" onClick={navigateToHouseSearch}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        direction="column"
                        sx={{ height: "100%" }}
                      >
                        <StyledAddCircleOutline />
                        <Typography variant="h5">{t("HouseManagement.AddNewHouse")}</Typography>
                      </Grid>
                    </StyledCard>
                  </Box>
                </Grow>
              </Grid>
              {state.houses?.map((u, index) => (
                <Grow
                  key={u.Id}
                  in={(Boolean(u) && !state.loading) || (!state.fadebleItems.includes(index) && state.loading)}
                >
                  <Grid item md={4} sm={6} xs={12}>
                    <HouseCard
                      unit={u}
                      onChnageCurrentHouse={handleChangeHouse}
                      onFamilyClick={navigateToFamily}
                      onMoveOut={handleMoveoutClick}
                    />
                  </Grid>
                </Grow>
              ))}
            </Grid>
          </Box>
          {state.moveOutPropertyFamily && user && state.moveOutProperty !== null && (
            <MoveOutDialog
              open={state.openMoveOut}
              unitId={state.moveOutProperty.Id}
              unitWithCo2Plan={state.moveOutProperty.HasCO2Plan}
              from={location.pathname}
              hasDocuments={hasDocuments}
              isOwnerChangeRequired={isNewOwnerRequired(state.moveOutPropertyFamily)}
              sendMoveOutRequests={sendMoveOutRequests}
              userId={user.Id}
              family={state.moveOutPropertyFamily}
              closeDialog={closeMoveOut}
            />
          )}

          <SelectCurrentHouseDialog
            open={state.openSelectNewHouse}
            closeDialog={closeSelectNew}
            setCurrentHouse={handleChangeHouseAfterUpdate}
            houses={getFilteredHouses()}
          />
          {changePropertyData && (
            <ChangePropertyDialog
              open={isChangePropertyDialogOpen}
              closeDialog={() => setIsChangePropertyDilaogOpen(false)}
              changePropertyData={changePropertyData}
            />
          )}
        </LoadWrapper>
      </Container>
    </MainContainer>
  );
};

export default HousesList;
