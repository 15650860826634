import React, { ChangeEvent, useEffect, useReducer } from "react";
import { Box, Grid, styled, Tab, Tabs, useTheme } from "@mui/material";
import AlarmRecipientsList from "./components/AlarmRecipientsList";
import AddRecipientDialog from "./components/AddRecipientDialog";
import User from "../../../../definitions/model/User";
import UtmSources from "./components/UtmSources";
import { SystemStringModel, SystemStringType } from "../../../../definitions/model/SystemString";
import AddUtmSourceDialog from "./components/AddUtmSourceDialog";
import { useAdmin } from "../../../../actions/admin/admin.actions";

const TabPanelContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  border: `${theme.palette.grey[300]} solid 1px`,
  borderTop: "none",
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    boxSizing: "border-box",
    height: "100%",
    border: `${theme.palette.grey[300]} solid 1px`,
    borderBottom: `#fff solid 1px`,
    backgroundColor: "inherit",
  },
}));

const BorderedTab = styled(Tab)(({ theme }) => ({
  borderBottom: `${theme.palette.grey[300]} solid 1px`,
}));

type LeadPageState = {
  currentTab: number;
  leadAlarmSubscribers: User[];
  openAddRecipient: boolean;
  selectOptions: User[];
  recipientsLoading: boolean;
  utmSources: SystemStringModel[];
  openAddUtmSource: boolean;
  utmSourcesLoading: boolean;
};

const initialState: LeadPageState = {
  currentTab: 0,
  leadAlarmSubscribers: [],
  openAddRecipient: false,
  selectOptions: [],
  recipientsLoading: false,
  utmSources: [],
  openAddUtmSource: false,
  utmSourcesLoading: false,
};

const reducer = (
  state: LeadPageState,
  action: {
    type: keyof LeadPageState | "addRecipient" | "removeRecipient" | "addUtmSource";
    payload: any;
  }
) => {
  switch (action.type) {
    case "addRecipient":
      if (state.leadAlarmSubscribers.find((x) => x.Id === action.payload.Id)) {
        return {
          ...state,
          leadAlarmSubscribers: state.leadAlarmSubscribers.map((s) => (s.Id != action.payload.Id ? s : action.payload)),
        };
      }

      return {
        ...state,
        leadAlarmSubscribers: [...state.leadAlarmSubscribers, action.payload],
      };
    case "removeRecipient":
      return {
        ...state,
        leadAlarmSubscribers: state.leadAlarmSubscribers.filter((x) => x.Id !== action.payload.Id),
      };
    case "addUtmSource":
      return {
        ...state,
        utmSources: [...state.utmSources, action.payload],
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

const LeadsPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const theme = useTheme();
  const [, adminActions] = useAdmin();

  const handleChangeTab = (e: ChangeEvent<unknown>, value: number) => {
    dispatch({ type: "currentTab", payload: value });
  };

  const handleAutocompleteChange = async (emailPart: string) => {
    const options = await adminActions.getNotSubsribedToLeadAlarm(emailPart);
    dispatch({ type: "selectOptions", payload: options });
  };

  const handleSubscribe = async (userId: string, landingName: string) => {
    dispatch({ type: "recipientsLoading", payload: true });
    const result = await adminActions.subscribeToLeadAlarm(userId, landingName);
    dispatch({ type: "addRecipient", payload: result });
    dispatch({ type: "recipientsLoading", payload: false });
    return result;
  };

  const handleUnsubscribe = async (userId: string) => {
    dispatch({ type: "recipientsLoading", payload: true });
    const result = await adminActions.unsubscribrFromLeadAlarm(userId);
    dispatch({ type: "removeRecipient", payload: result });
    dispatch({ type: "recipientsLoading", payload: false });
    return result;
  };

  const showAddRecipientDialog = () => dispatch({ type: "openAddRecipient", payload: true });
  const showAddUtmSourceDialog = () => dispatch({ type: "openAddUtmSource", payload: true });

  const handleAddUtmSource = async (value: string) => {
    dispatch({ type: "utmSourcesLoading", payload: true });
    try {
      const result = await adminActions.addSystemStrings(value, SystemStringType.UtmSource);
      dispatch({ type: "addUtmSource", payload: result });
    } catch (e) {
      console.log(e);
    } finally {
      dispatch({ type: "utmSourcesLoading", payload: false });
    }
  };

  useEffect(() => {
    dispatch({ type: "recipientsLoading", payload: true });
    dispatch({ type: "utmSourcesLoading", payload: true });
    adminActions
      .getSubscribedToLeadAlarm()
      .then((x) => dispatch({ type: "leadAlarmSubscribers", payload: x }))
      .finally(() => dispatch({ type: "recipientsLoading", payload: false }));

    adminActions
      .getSystemStrings(SystemStringType.UtmSource)
      .then((x) => dispatch({ type: "utmSources", payload: x }))
      .finally(() => dispatch({ type: "utmSourcesLoading", payload: false }));

    return () => {};
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid container item xs={10}>
        <StyledTabs value={state.currentTab} onChange={handleChangeTab}>
          <BorderedTab disableRipple label="Alarm recipients"></BorderedTab>
          <BorderedTab disableRipple label="Utm Sources"></BorderedTab>
        </StyledTabs>
        <Box display="flex" flexGrow={1} borderBottom={`${theme.palette.grey[300]} solid 1px`} />
        <TabPanelContainer width="100%">
          <TabPanel index={0} value={state.currentTab}>
            <AlarmRecipientsList
              subscribeToAlarm={handleSubscribe}
              unsubscribeFromAlarm={handleUnsubscribe}
              loading={state.recipientsLoading}
              recipients={state.leadAlarmSubscribers}
              showAddRecipient={showAddRecipientDialog}
            />
          </TabPanel>
          <TabPanel index={1} value={state.currentTab}>
            <UtmSources
              loading={state.utmSourcesLoading}
              utmSources={state.utmSources}
              showAddUtmSource={showAddUtmSourceDialog}
            ></UtmSources>
          </TabPanel>
        </TabPanelContainer>
      </Grid>
      <AddRecipientDialog
        open={state.openAddRecipient}
        selectUsers={state.selectOptions}
        selectUtmSources={state.utmSources}
        onAutocompleteChange={handleAutocompleteChange}
        handleSave={handleSubscribe}
        handleClose={() => dispatch({ type: "openAddRecipient", payload: false })}
      />
      <AddUtmSourceDialog
        open={state.openAddUtmSource}
        handleClose={() => dispatch({ type: "openAddUtmSource", payload: false })}
        handleSave={handleAddUtmSource}
      ></AddUtmSourceDialog>
    </Grid>
  );
};

export default LeadsPage;

type TabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;

  return (
    <Box width="100%" role="tabpanel" hidden={value !== index}>
      {value === index && (
        <Grid container justifyContent="center">
          {children}
        </Grid>
      )}
    </Box>
  );
};
