import { Container, Grid, styled } from "@mui/material";
import UserPassword from "./components/UserPassword";
import MainContainer from "./../../components/Layout/MainContainer";
import React, { useEffect } from "react";
import { Alert, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import UserService from "../../services/user.service";
import ForgotPasswordDialog from "../../components/Dialogs/ForgotPasswordDialog/ForgotPasswordDialog";
import { useDefaultReducer } from "../../helpers/hooks";
import { useUser } from "../../actions";
import NavigationHelper from "../../helpers/navigation-helper";

const GridStyled = styled(Grid)(() => ({
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  justifyContent: "center",
  textAlign: "justify",
}));

type ResetPasswordState = {
  loading: boolean;
  isChanged: boolean;
  validationToken: string | null;
  openForgotPassword: boolean;
  errorMessage: string;
};

const initialState: ResetPasswordState = {
  loading: false,
  isChanged: false,
  validationToken: null,
  openForgotPassword: false,
  errorMessage: "",
};

const ResetPasswordPage = () => {
  const { t } = useTranslation("translation");
  const [state, dispatch] = useDefaultReducer(initialState);
  const [, userActions] = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const klimaplan = NavigationHelper.IsClimateSite();
  

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    if (!token) {
      navigate("/");
    } else {
      stateActionHandler("validationToken", token);
    }
  }, [location]);

  const stateActionHandler = (type: keyof ResetPasswordState, payload: any) => {
    dispatch({
      type: type,
      payload: payload,
    });
  };

  const tryLogin = (Email: string, Password: string) => {
    stateActionHandler("loading", true);

    userActions
      .authorize(Email, Password)
      .then((result) => {
        result && (klimaplan ? navigate("/co2calculator") : navigate("/overview"));
      })
      .catch((error) => {
        stateActionHandler("errorMessage", "statusText" in error ? error.statusText : error);
      })
      .finally(() => {
        stateActionHandler("loading", false);
      });
  };

  const childrenProps: any = {
    state: {},
    closeDialog: () => {
      stateActionHandler("openForgotPassword", false);
    },
    open: state.openForgotPassword,
  };

  const submit = (password: string) => {
    stateActionHandler("errorMessage", "");
    stateActionHandler("isChanged", false);
    stateActionHandler("loading", false);

    if (state.validationToken) {
      UserService.tokenValidate(state.validationToken)
        .then((result) => {
          if (result) {
            UserService.confirmPassword(state.validationToken!, password).then((result) => {
              if (result) {
                stateActionHandler("isChanged", result);
                result.email && tryLogin(result.email, password);
              }
            });
          } else {
            stateActionHandler("errorMessage", t("Base.Dialog.TokenIsNotValid"));
          }
        })
        .catch((error) => {
          stateActionHandler("errorMessage", "statusText" in error ? error.statusText : error);
        })
        .finally(() => {
          stateActionHandler("loading", false);
        });
    }
  };
  return (
    <MainContainer>
      <Container maxWidth="md">
        <Grid mt={3}>
          <UserPassword submit={submit} loading={state.loading} isChanged={state.isChanged} />
          {state.errorMessage && (
            <Alert severity="error" sx={{ marginTop: "20px" }}>
              <GridStyled item>
                {state.errorMessage}
                {t("Base.Dialog.ResetPasswordAgain")}{" "}
                <Link
                  underline="hover"
                  sx={{ marginLeft: { xs: "-20px", sm: "5px" } }}
                  onClick={() => {
                    stateActionHandler("openForgotPassword", true);
                    stateActionHandler("errorMessage", "");
                  }}
                >
                  <Typography variant="body2">{t("Authorization.Forms.ForgetPassword")}</Typography>
                </Link>
              </GridStyled>
            </Alert>
          )}
          {state.openForgotPassword && <ForgotPasswordDialog {...childrenProps} />}
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default ResetPasswordPage;
