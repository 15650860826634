import React, { useState } from "react";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl, styled,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { TemplateModel } from "../../../../../definitions/Landing";
import { useAlertContext } from "../../../../../components/Base";
import { TemplateType } from "../../../../../constants/enums";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";
import CircularProgressButton from "../../../../../components/Base/CircularProgressButton";

const Form = styled('form')({
  width: "100%",
})

type UpdateTemplateFormData = {
  Title: string;
  Type: TemplateType;
};

type LandingFormProps = {
  template: TemplateModel;
  updateTemplate: (template: TemplateModel) => Promise<TemplateModel | null>;
};

const LandingForm = ({
  template,
  updateTemplate,
}: LandingFormProps): JSX.Element => {
  const methods = useForm<UpdateTemplateFormData>({
    defaultValues: {
      Title: template?.Title ?? "",
      Type: template?.Type ?? TemplateType.Landing,
    },
  });

  const { showAlert } = useAlertContext();

  const [isUpdating, setIsUpdating] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = (formData: UpdateTemplateFormData) => {
    setIsUpdating(true);
    updateTemplate({
      ...template,
      ...formData,
    })
      .then(() => {
        showAlert({ severity: "success", text: "Template updated" });
      })
      .catch(() =>
        showAlert({ severity: "error", text: "Template not updated" })
      )
      .finally(() => setIsUpdating(false));
  };

  const selectItems = Object.entries(TemplateType).filter(
    ([key, val]) => !Number.isInteger(val)
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFieldConnector register={register("Title", { required: true })}>
            <TextField
              variant="outlined"
              fullWidth
              label="Title"
              margin="dense"
              error={!!errors.Title}
            />
          </TextFieldConnector>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="Type"
            render={({ field }) => (
              <FormControl variant="outlined" fullWidth margin="dense">
                <InputLabel>Template type</InputLabel>
                <Select
                  {...field}
                  onChange={(e) => field.onChange(+e.target.value)}
                >
                  {selectItems.map(([key, val]) => (
                    <MenuItem
                      key={key}
                      value={key}
                      style={{ padding: "10px 20px" }}
                    >
                      {val}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            control={control}
          />
        </Grid>
        <Grid container justifyContent="flex-end" item>
          <CircularProgressButton
            type="submit"                        
            inProgress={isUpdating}
          >
            Update
          </CircularProgressButton>
        </Grid>
      </Grid>
    </Form>
  );
};

export default LandingForm;
