import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useUser } from "../../actions/user.actions";

const PropertyLayout = (): JSX.Element => {
  const { pathname } = useLocation();
  const [{ uiMode }, userActions] = useUser();

  React.useEffect(() => {
    if (uiMode !== "unit") {
      userActions.setUiMode("unit");
    }
  }, [pathname]);

  return <Outlet />;
};

export default PropertyLayout;
