import React, { useEffect, useState } from "react";
import {LinearProgress, MenuItem, Select, FormControl, FormLabel, styled} from "@mui/material";
import { Controller } from "react-hook-form";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "../../../../../reducers/store";
import { getTemplates, clearTemplates } from "../../../../../actions";
import { TemplateType } from "../../../../../constants/enums";
import { AppThunkDispatch } from "../../../../../definitions/Action";
import _ from "lodash";

const  StyledMenuItem = styled(MenuItem)({
  padding: "10px 20px",
})

const StyledSelect = styled(Select)({
  "& 	.MuiMenu-paper":{
    maxHeight: 150,
  }
})

type TemplatesSelectorProps = {
  name: string;
  templatesType: TemplateType;
  control: any;
};

const TemplatesSelector = ({
  name,
  templatesType,
  control,
  loading,
  templates,
  templatesCount,
  getTemplates,
  clearTemplates,
}: TemplatesSelectorProps & TemplatesSelectorConnectedProps) => {
  const takeAmount = 5;
  const [isLoading, setIsLoading] = useState(false);

  const fetchTemplates = (skip: number, take: number) => {
    setIsLoading(true);
    getTemplates("", skip, take, templatesType).finally(() => setIsLoading(false));
  };

  const loadTemplates = () => {
    if (templatesCount && templates.length < templatesCount && !loading) {
      fetchTemplates(templates.length, takeAmount);
    } else if (!templatesCount && !loading) {
      fetchTemplates(0, takeAmount);
    }
  };

  const debouncedLoadTemplates = _.debounce(loadTemplates, 500);

  useEffect(() => {
    clearTemplates();
    fetchTemplates(0, takeAmount);
    return () => {
      clearTemplates();
    };
  }, []);

  return (
    <FormControl fullWidth margin="dense" variant="outlined">
      <FormLabel>Select template</FormLabel>
      {isLoading && <LinearProgress  />}
      <Controller
        name={name}
        defaultValue=""
        render={({field}) => (
          <StyledSelect
            {...field}
            fullWidth
            MenuProps={{
              onScroll: debouncedLoadTemplates,
            }}
          >
            {templates.map((t, i) => (
              <StyledMenuItem key={t.Id + i} value={t.Id}>
                {t.Title}
              </StyledMenuItem>
            ))}
            {/* {isLoading && (
              <MenuItem value={undefined} disabled className={classes.spinnerItem}>
                <CircularProgress size={24} />
              </MenuItem>
            )} */}
          </StyledSelect>
        )}
        control={control}
      />
    </FormControl>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  templates: state.adminLanding.templates,
  templatesCount: state.adminLanding.templatesCount,
  loading: state.adminLanding.loading,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getTemplates: (searchTerm: string, skip: number, take: number, type: TemplateType) =>
    dispatch(getTemplates(searchTerm, skip, take, type)),
  clearTemplates: () => dispatch(clearTemplates()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type TemplatesSelectorConnectedProps = ConnectedProps<typeof connector>;

export default connector(TemplatesSelector);
