type ToolBarButton = {
  command: string;
  label: string;
  toolbar: string;
  icon: string;
  buttonName: string;
  dialogName?: string;
  externalId?: string;
  buttonClick?: (e: any) => void;
};

export default function (CKEDITOR: any) {
  CKEDITOR.dtd["domainstring"] = CKEDITOR.dtd;
  CKEDITOR.dtd.$blockLimit["domainstring"] = 1;
  CKEDITOR.dtd.$inline["domainstring"] = 1;
  CKEDITOR.dtd.$nonEditable["domainstring"] = 1;
  CKEDITOR.config.allowContent = true;

  CKEDITOR.dtd["p"]["domainstring"] = 1;

  let selectedElement: any = null;

  const controls: Array<ToolBarButton> = [
    {
      command: "unsubcribeLink",
      label: "Insert Unsubscribe Link",
      icon: "Anchor",
      toolbar: "template",
      buttonName: "unsubscibeLinkButton",
      buttonClick: (e: any) => {
        e.insertHtml(
          ` <a href="${window.location.origin}/unsubscribe?email=<%[?(@.TypeName === 'UserModel')].Email%>">Unsubscribe</a> `
        );
      },
    },
    {
      command: "domainString",
      label: "Insert Domain String",
      icon: "Blockquote",
      toolbar: "template",
      buttonName: "domainString",
      buttonClick: (e: any) => {
        e.insertHtml(`<domainstring type="string">[domain string]</domainstring>`);
      },
    },
    {
      command: "domainLink",
      label: "Insert Domain Link",
      icon: "Link",
      toolbar: "template",
      buttonName: "domainLink",
      buttonClick: (e: any) => {
        e.insertHtml(`<domainstring type="link">[{currentDomain}]</domainstring>`);
      },
    },
    {
      command: "domainEmail",
      label: "Insert Domain Email",
      icon: "About",
      toolbar: "template",
      buttonName: "domainEmail",
      buttonClick: (e: any) => {
        e.insertHtml(`<domainstring type="email">[no-reply@{currentDomain}]</domainstring>`);
      },
    },
  ];

  //add "template" plugin once
  if (!CKEDITOR.plugins.get("template")) {
    CKEDITOR.plugins.add("template", {
      init: function (editor: any) {
        editor.filter.allow("domainstring[!*]", "domainstring", true);
        CKEDITOR.dtd.$object["domainstring"] = 1;

        controls.forEach((c) => {
          if (c.dialogName) editor.addCommand(c.command, new CKEDITOR.dialogCommand(c.dialogName));

          editor.ui.addButton(c.buttonName, {
            label: c.label,
            command: c.command,
            toolbar: c.toolbar,
            icon: c.icon,
          });

          if (c.buttonClick) editor.addCommand(c.command, { exec: c.buttonClick });
        });

        editor.on("doubleclick", function (evt: any) {
          const element = CKEDITOR.plugins.link.getSelectedLink(editor) || evt.data.element;

          if (element.$.getAttribute("type") === "email") {
            selectedElement = element;
            editor.openDialog("emailDialog");
          }

          if (element.$.getAttribute("type") === "link") {
            selectedElement = element;
            editor.openDialog("linkDialog");
          }

          controls.forEach((c) => {
            if (element.is(c.command)) {
              editor.execCommand(c.command);
            }
          });
        });
      },
    });
  }

  if (!CKEDITOR.plugins.get("linkDialog")) {
    CKEDITOR.plugins.add("linkDialog", {
      init: function (editor: any) {
        editor.addCommand("linkDialog", {
          exec: function (editor: any) {
            editor.openDialog("linkDialog");
          },
        });

        editor.on("dialogShow", function (e: any) {
          const dialog = e.data;
          if (dialog.getName() === "linkDialog") {
            if (
              (selectedElement && selectedElement.getAttribute("route")) ||
              selectedElement.getAttribute("linkName")
            ) {
              const route = selectedElement.getAttribute("route");
              const linkName = selectedElement.getAttribute("linkName");

              if (route) {
                dialog.setValueOf("link-data", "linkString", route);
              }
              if (linkName) {
                dialog.setValueOf("link-data", "linkName", linkName);
              }
            } else {
              dialog.setValueOf("link-data", "linkString", "/");
            }
          }
        });
      },
    });
  }

  CKEDITOR.dialog.add("linkDialog", function (editor: any) {
    return {
      title: "Domain sensitive link settings",
      minWidth: 400,
      minHeight: 200,
      contents: [
        {
          id: "link-data",
          label: "Link string settings",
          elements: [
            {
              type: "html",
              html: "<p>Please enter route to use:</p>",
            },
            {
              type: "text",
              id: "linkString",
              label: "{current domain}",
              style: "display: flex; justify-content: flex-start; align-items: center",
            },
            {
              type: "html",
              html: "<p>Please enter link name:</p>",
            },
            {
              type: "text",
              id: "linkName",
            },
          ],
        },
      ],
      onOk: function (e: any) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const dialog: any = this;
        const route = dialog.getValueOf("link-data", "linkString");
        const linkName = dialog.getValueOf("link-data", "linkName");
        if (selectedElement) {
          if (route) {
            selectedElement.$.innerText = `[{${linkName ? linkName : "currentDomain"}}/${route}]`;
            selectedElement.setAttribute("route", route);
            selectedElement.setAttribute("linkName", linkName);
          } else {
            selectedElement.$.innerText = "[domain link]";
            selectedElement.setAttribute("route", "/");
          }
        }
      },
    };
  });

  if (!CKEDITOR.plugins.get("emailDialog")) {
    CKEDITOR.plugins.add("emailDialog", {
      init: function (editor: any) {
        editor.addCommand("emailDialog", {
          exec: function (editor: any) {
            editor.openDialog("emailDialog");
          },
        });

        editor.on("dialogShow", function (e: any) {
          const dialog = e.data;
          if (dialog.getName() === "emailDialog") {
            if (selectedElement && selectedElement.getAttribute("email")) {
              const emailName = selectedElement.getAttribute("email");

              dialog.setValueOf("email-data", "emailString", emailName);
            } else {
              dialog.setValueOf("email-data", "emailString", "no-reply");
            }
          }
        });
      },
    });
  }

  CKEDITOR.dialog.add("emailDialog", function (editor: any) {
    return {
      title: "Domain sensitive email settings",
      minWidth: 400,
      minHeight: 200,
      contents: [
        {
          id: "email-data",
          label: "Email string settings",
          style: "display: flex",
          elements: [
            {
              type: "html",
              html: "<p>Please enter email name to use:</p>",
            },
            {
              type: "text",
              id: "emailString",
              label: "@{current domain}",
              default: "no-reply",
              style: "display: flex; flex-flow: row-reverse; justify-content: flex-end; align-items: center",
            },
          ],
        },
      ],
      onOk: function (e: any) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const dialog: any = this;
        const emailName = dialog.getValueOf("email-data", "emailString");
        if (selectedElement) {
          if (emailName) {
            selectedElement.$.innerText = `[${emailName}@{currentDomain}]`;
            selectedElement.setAttribute("email", emailName);
          } else {
            selectedElement.$.innerText = "[domain email]";
            selectedElement.setAttribute("email", "");
          }
        }
      },
    };
  });
}
