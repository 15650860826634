import React from "react";
import { Typography, Grid, Link, Box } from "@mui/material";
import BaseDialog from "../../../../components/Dialogs/BaseDialog";
import { useTranslation } from "react-i18next";

type WrongInformationDialogProps = {
  open: boolean;
  closeDialog: () => void;
};

const WrongInformationDialog = (props: WrongInformationDialogProps) => {
  const { t } = useTranslation("translation");
  return (
    <BaseDialog
      titleComponent={
        <Typography variant="h4">
          {t("Pages.PropertyFacts.IsInformationWrong")}
        </Typography>
      }
      {...props}
    >
      <Grid container justifyContent="center">
        <Grid item lg={8} md={9} sm={10} xs={12}>
          <Typography paragraph component="div">
            <Box mb={2}>
              {t("Pages.PropertyFacts.AboutWhereInformationFrom")}
            </Box>
            <Box mb={2} fontWeight={600}>
              {t("Pages.PropertyFacts.IfInformationIncorrect")}&ensp;
              <Link
                href="https://bbr.dk/ret"
                target="_blank"
                variant="inherit"
                underline="always"
                rel="noopener noreferrer"
              >
                https://bbr.dk/ret
              </Link>
              {t("Pages.PropertyFacts.WhereYouMustLogIn")}
            </Box>
            <Box>
              {t("Pages.PropertyFacts.DataIsBasedOnDataFromPlansystemDK")}
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </BaseDialog>
  );
};

export default WrongInformationDialog;
