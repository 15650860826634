import React from "react";
import { Typography, Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseDialog from "../../../../components/Dialogs/BaseDialog";
import UserService from "../../../../services/user.service";

type MissingHousePhotoDialogProps = {
  open: boolean;
  unitId: string;
  closeDialog: () => void;
};

const MissingHousePhotoDialog = ({
  unitId,
  ...other
}: MissingHousePhotoDialogProps) => {
  const { t } = useTranslation("translation");
  const handleClick = () => {
    UserService.subscribeOnHousePhotosUpdate(unitId);
    other.closeDialog();
  };

  return (
    <BaseDialog
      titleComponent={
        <Typography variant="h4">
          {t("Pages.PropertyFacts.ObliquePhotoOfHome")}
        </Typography>
      }
      actionsComponent={
        <>
          <Button
            variant="contained"
            color="secondary"
            onClick={other.closeDialog}
          >
            {t("General.Buttons.Close")}
          </Button>
          <Button variant="contained" color="secondary" onClick={handleClick}>
            {t("Pages.PropertyFacts.YesSendMeEmail")}
          </Button>
        </>
      }
      {...other}
    >
      <Grid container justifyContent="center">
        <Grid item lg={8} md={9} sm={10} xs={12}>
          <Typography paragraph>
            {t("Pages.PropertyFacts.AboutObliquePhotosOfDenmark")}
          </Typography>

          <Typography paragraph>
            {t("Pages.PropertyFacts.AboutPhotographedDanishLandscape")}
          </Typography>
        </Grid>
      </Grid>
    </BaseDialog>
  );
};

export default MissingHousePhotoDialog;
