import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Grid, Button, ThemeProvider, StyledEngineProvider, LinearProgress } from "@mui/material";
import { connect, ConnectedProps, Provider } from "react-redux";
import { GrapesEditor, GrapesTypes, LoadComponentOptions } from "../../defenitions";
import store from "../../../../reducers/store";
import { getOptionalTheme } from "../../../../constants/theme";
import { isReactComponent } from ".";
import AutoCompleteComponent from "../../../Autocomplete/AutoComplete";
import AddressHelper from "../../../../helpers/address-helper";
import { AppThunkDispatch } from "../../../../definitions/Action";
import { trackSearchAutocompleteFilledIn, getUnit } from "../../../../actions/unit.actions";
import { PreviousRouteProvider } from "../../../../routes/PreviouseRouteContext";
import Unit from "../../../../definitions/model/unit/Unit";
import { useTranslation } from "react-i18next";

type SearchAutocompleteFormProps = {
  redirectUrl?: string;
  forceRedirect: boolean;
  btnText: string;
  buttonVariant: "text" | "outlined" | "contained";
  buttonColor: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  inputVariant: "filled" | "outlined" | "standard";
  inputColor: "error" | "success" | "info" | "warning" | "primary" | "secondary";
  btnPosition: "flex-start" | "center" | "flex-end";
};

export type AutocompleteStyles = {
  color: string;
  background: string;
};

const SearchAutocompleteFormComponent = (props: SearchAutocompleteFormConnectedProps): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const [value, setValue] = useState("");
  const [initLoader, setInitLoader] = useState(false);

  useEffect(() => {
    async function loadUnit() {
      const params = new URLSearchParams(window.location.search);
      if (params.has("property")) {
        setInitLoader(true);
        const unitId = params.get("property") as string;
        if (unitId) {
          try {
            const unit = await props.getUnit(unitId);
            if (unit) {
              const autocompleteValue = AddressHelper.GetAutocompleteInput(unit.Address);
              setValue(autocompleteValue);

              if (props.forceRedirect) navigateTargetUrl(unit);
            }
          } catch (error) {
            console.error(error);
          }
        }

        setInitLoader(false);
      }
    }

    loadUnit();
  }, []);

  const navigateTargetUrl = (unit: Unit) => {
    const target = props.redirectUrl || `${AddressHelper.GetUnitAddressUrl(unit)}/search`;

    let url: URL;

    try {
      url = new URL(target);
    } catch {
      if (target.indexOf("http") !== 0) url = new URL(`${window.location.origin}${target}`);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (!url) return;

    //Production could have host myhouse.dk or www.myhouse.dk
    const destinationHost = url.hostname.replace("www.", "");
    const currentHost = window.location.hostname.replace("www.", "");
    if (destinationHost === currentHost) {
      navigate(url.pathname);
      return;
    }

    window.location.href = target;
  };

  const redirectToUnit = (unitId: string) => {
    if (unitId) {
      props.trackSearchAutocompleteFilledIn();
      props
        .getUnit(unitId)
        .then((unit) => {
          if (unit) navigateTargetUrl(unit);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {initLoader && <LinearProgress color="secondary"></LinearProgress>}
        <AutoCompleteComponent
          key={`search_autocomplete_${value}`}
          placeholder={t("Autocomplete.Placeholder")}
          size="small"
          setValueCallback={redirectToUnit}
          inputValue={value}
          variant={props.inputVariant}
          color={props.inputColor}
        >
          <Grid container justifyContent={props.btnPosition}>
            <Button
              style={{
                width: 150,
              }}
              variant={props.buttonVariant}
              color={props.buttonColor}
              type="submit"
            >
              {props.btnText}
            </Button>
          </Grid>
        </AutoCompleteComponent>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  trackSearchAutocompleteFilledIn: () => dispatch(trackSearchAutocompleteFilledIn()),
  getUnit: (id: string) => dispatch(getUnit(id)),
});

const connector = connect(null, mapDispatchToProps);

type SearchAutocompleteFormConnectedProps = ConnectedProps<typeof connector> & SearchAutocompleteFormProps;

export const SearchAutocompleteForm = connector(SearchAutocompleteFormComponent);

export default function (editor: GrapesEditor, options: LoadComponentOptions, landingTheme?: string): void {
  const domComponents = editor.DomComponents;
  const blockManager = editor.BlockManager;
  const defaultType = domComponents.getType("default");
  const { model, view } = defaultType;
  const compName = "SearchAutocompleteForm";
  blockManager.add(GrapesTypes.SearchFormComponent, {
    label: "Search autocomplete",
    category: options.categoryLabel,
    attributes: { class: "fa fa-search" },
    content: `<div data-gjs-type="${GrapesTypes.SearchFormComponent}"></div>`,
  });

  const traits = [
    {
      label: "Redirect url",
      name: "redirectUrl",
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Force redirect",
      name: "forceRedirect",
      changeProp: 1,
    },
    {
      label: "Button title",
      name: "btnText",
      changeProp: 1,
    },
    {
      type: "number",
      label: "Border radius",
      changeProp: 1,
      name: "btnBorderRadius",
      min: 0,
      max: 30,
      step: 1,
    },
    {
      type: "select",
      label: "Button Variant",
      changeProp: 1,
      name: "buttonVariant",
      typeid: 0,
      options: [
        { id: "outlined", name: "outlined" },
        { id: "contained", name: "contained" },
      ],
    },
    {
      type: "select",
      label: "Button Color",
      changeProp: 1,
      name: "buttonColor",
      typeid: 0,
      options: [
        { id: "inherit", name: "inherit" },
        { id: "primary", name: "primary" },
        { id: "secondary", name: "secondary" },
        { id: "default", name: "default" },
      ],
    },
    {
      type: "select",
      label: "Input Variant",
      changeProp: 1,
      name: "inputVariant",
      typeid: 0,
      options: [
        { id: "outlined", name: "outlined" },
        { id: "filled", name: "filled" },
        { id: "standard", name: "standard" },
      ],
    },
    {
      type: "select",
      label: "Input Color",
      changeProp: 1,
      name: "inputColor",
      typeid: 0,
      options: [
        { id: "inherit", name: "inherit" },
        { id: "primary", name: "primary" },
        { id: "secondary", name: "secondary" },
        { id: "default", name: "default" },
      ],
    },
    {
      type: "select",
      label: "Button position",
      changeProp: 1,
      name: "btnPosition",
      typeid: 0,
      options: [
        { id: "flex-start", name: "start" },
        { id: "center", name: "center" },
        { id: "flex-end", name: "end" },
      ],
    },
  ];

  const subscriptions = traits.map((trait) => trait.name);

  domComponents.addType(GrapesTypes.SearchFormComponent, {
    isComponent(el: HTMLElement) {
      if (
        (el.getAttribute && el.getAttribute("data-gjs-type") === GrapesTypes.SearchFormComponent) ||
        (el.attributes && (el.attributes as any)["data-gjs-type"] === GrapesTypes.SearchFormComponent) ||
        isReactComponent(el, compName)
      ) {
        return {
          type: GrapesTypes.SearchFormComponent,
        };
      }
    },
    model: {
      defaults: {
        ...model.prototype.defaults,
        droppable: false,
        redirectUrl: "",
        forceRedirect: false,
        btnText: "Næste",
        btnPosition: "flex-start",
        inputColor: "primary",
        inputVariant: "outlined",
        buttonVariant: "outlined",
        buttonColor: "primary",
        traits: [...model.prototype.defaults.traits, ...traits],
      },
    },
    view: view.extend({
      init() {
        subscriptions.forEach((subscription) => {
          this.listenTo(this.model, `change:${subscription}`, this.handleChanges);
        });
      },

      handleChanges() {
        const root = ReactDOM.createRoot(this.el);
        root.unmount();
        this.render();
      },

      onRender() {
        const { el } = this;
        const comps = this.model.get("components");
        const {
          btnText,
          btnPosition,
          redirectUrl,
          forceRedirect,
          inputColor,
          inputVariant,
          buttonVariant,
          buttonColor,
        } = this.model.attributes;
        comps.reset();
        const compString = `
          <${compName} 
            buttonVariant=${buttonVariant}
            inputColor=${inputColor}
            buttonColor=${buttonColor}
            inputVariant=${inputVariant}
            btnText=${btnText}
            btnPosition=${btnPosition}
            redirectUrl=${redirectUrl}
            forceRedirect=${forceRedirect}
          ></${compName}>
        `;
        comps.add(compString);
        const root = ReactDOM.createRoot(el);

        root.render(
          <>
            <StyledEngineProvider>
              <ThemeProvider theme={getOptionalTheme(landingTheme)}>
                <Provider store={store}>
                  <BrowserRouter>
                    <PreviousRouteProvider>
                      <SearchAutocompleteForm
                        btnText={btnText}
                        buttonVariant={buttonVariant}
                        buttonColor={buttonColor}
                        inputColor={inputColor}
                        inputVariant={inputVariant}
                        btnPosition={btnPosition}
                        redirectUrl={redirectUrl}
                        forceRedirect={forceRedirect}
                      />
                    </PreviousRouteProvider>
                  </BrowserRouter>
                </Provider>
              </ThemeProvider>
            </StyledEngineProvider>
          </>
        );
      },
    }),
  });
}
