import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Paper,
  IconButton, styled,
} from "@mui/material";
import { Add, MoreVert } from "@mui/icons-material";
import { format } from "date-fns";

import {
  CreatePriceRequest,
  ProductPriceDTO,
} from "../../../../../definitions/model/Subscriptions";
import PriceForm, { PriceFormData } from "./PriceForm";

type PriceTableProps = {
  getProductPrices: (productId: string) => Promise<ProductPriceDTO[]>;
  createProductPrice: (data: CreatePriceRequest) => Promise<ProductPriceDTO>;
  productId: string;
};

const ToolbarTitle = styled(Toolbar)(({theme})=>({
  padding: "0 16px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}))

const PriceTable = ({
  getProductPrices,
  createProductPrice,
  productId,
}: PriceTableProps): JSX.Element => {
  const [isVisibleForm, setIsVisibleForm] = useState(false);
  const [prices, setPrices] = useState<ProductPriceDTO[]>([]);
  const handleCloseForm = () => setIsVisibleForm(false);
  const handleOpenForm = () => setIsVisibleForm(true);

  const onSubmit = (data: PriceFormData) => {
    createProductPrice({
      ...data,
      ProductId: productId,
      Currency: process.env.REACT_APP_PRODUCT_CURRENCY || "",
    }).then((data) => {
      setPrices((prev) => [...prev, data]);
    });
  };

  useEffect(() => {
    getProductPrices(productId).then((data) => setPrices(data));
  }, []);

  return (
    <Grid>
      <Box my={5}>
        <Paper>
          <ToolbarTitle>
            <Typography variant="h6" component="div">
              Pricing
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Add />}
              onClick={handleOpenForm}
            >
              Add another price
            </Button>
          </ToolbarTitle>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>PRICE</TableCell>
                  <TableCell>SUBSCRIPTIONS</TableCell>
                  <TableCell>CREATED</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prices.map((price) => (
                  <TableRow hover key={price?.Id}>
                    <TableCell>
                      {price?.Amount}kr {price?.Currency.toUpperCase()} /{" "}
                      {price?.RecurringInterval}
                    </TableCell>
                    <TableCell>
                      0 {price?.Active ? "active" : "inactive"}
                    </TableCell>
                    <TableCell>
                      {format(new Date(price?.Created), "dd/MM/yyyy")}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton size="large">
                        <MoreVert />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      {isVisibleForm && (
        <PriceForm onSubmit={onSubmit} handleCloseForm={handleCloseForm} />
      )}
    </Grid>
  );
};

export default PriceTable;
