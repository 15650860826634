import React from "react";
import { Button, Dialog, DialogTitle, DialogActions } from "@mui/material";
import { useTranslation } from "react-i18next";

type WorkerDialogProps = {
  open: boolean;
  handleClose: () => void;
  openCreateDialog: () => void;
};

const WorkerConfirmDialog = (props: WorkerDialogProps): JSX.Element => {
  const { t } = useTranslation("translation");

  const openCreateDialog = () => {
    props.handleClose();
    props.openCreateDialog();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogTitle sx={{textAlign: "center"}}>
        {t("ServiceBook.Workers.DialogTitle")}
      </DialogTitle>
      <DialogActions sx={{ justifyContent: "center", marginBottom: "18px"}}>
        <Button onClick={openCreateDialog} color="primary" variant="contained">
          {t("General.Buttons.Yes")}
        </Button>
        <Button onClick={props.handleClose} color="primary" variant="outlined">
          {t("General.Buttons.No")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkerConfirmDialog;
