import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useForm, FormProvider, Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, DialogActions, DialogContent, FormControlLabel, Grid, Rating, Typography } from "@mui/material";

import { ControllerConnector, TextFieldConnector } from "../../../../../components/Base/FormConnector";
import { CompanyModel, CompanyTestimonialModel } from "../../../../../definitions/Company";
import SingleTransferList from "../../../../../components/Base/SingleTransferList";
import { MCheckbox, TransferList } from "../../../../../components/Base";

type CompanyTestimonialDataFormProps = {
  id?: string;
  existingTestimonial?: CompanyTestimonialModel;
  companies: CompanyModel[];
  domains: string[];
  submit: (testimonial: CompanyTestimonialModel) => void;
  handleClose: () => void;
};

const CompanyTestimonialDataForm = (props: CompanyTestimonialDataFormProps): JSX.Element => {
  const methods = useForm<CompanyTestimonialModel>({
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = methods;

  const [focusedField, setFocusedField] = useState("Text");
  const { t } = useTranslation("translation");

  useEffect(() => {
    if (props.existingTestimonial) {
      // for some reason all values are undefined on sumbit when reset is used. Use setValue instead
      setValue("CompanyId", props.existingTestimonial.CompanyId);
      setValue("Rating", props.existingTestimonial.Rating);
      setValue("Title", props.existingTestimonial.Title);
      setValue("Text", props.existingTestimonial.Text);
      setValue("ShowAtStartPage", props.existingTestimonial.ShowAtStartPage);
      setValue("Domains", props.existingTestimonial.Domains);
    }
  }, [props.existingTestimonial]);

  type FormTextBoxProps = {
    fieldName: keyof CompanyTestimonialModel;
    required?: boolean;
    autofocus?: boolean;
    multiline?: boolean;
    label?: string;
    pattern?: RegExp;
    defaultValue?: string;
  };

  const FormTextBox = (props: FormTextBoxProps): JSX.Element => {
    return (
      <TextFieldConnector
        register={register(props.fieldName, {
          required: props.required,
          pattern: props.pattern,
        })}
      >
        <TextField
          variant="outlined"
          autoFocus={props.autofocus || props.fieldName === focusedField}
          margin="dense"
          label={props.label ?? props.fieldName}
          multiline={props.multiline}
          fullWidth
          error={!!errors[props.fieldName]}
          onClick={() => setFocusedField(props.fieldName)}
        />
      </TextFieldConnector>
    );
  };

  const FormCheckBox = (props: FormTextBoxProps): JSX.Element => {
    return (
      <Grid item>
        <FormControlLabel
          control={
            <ControllerConnector name={props.fieldName} type="checkbox">
              <MCheckbox error={!!errors[props.fieldName]} />
            </ControllerConnector>
          }
          label={<Typography variant="body2">{props.label}</Typography>}
          sx={{
            margin: 0,
            "& .MuiFormControlLabel-root": {
              margin: 0,
            },
          }}
        />
      </Grid>
    );
  };

  type FormRatingProps = {
    fieldName: keyof CompanyTestimonialModel;
    required?: boolean;
    label?: string;
    defaultValue?: number;
    precision?: number;
  };
  const FormRatingField = (props: FormRatingProps): JSX.Element => {
    const { control } = useFormContext();

    return (
      <Controller
        render={({ field }) => (
          <Rating
            {...field}
            onChange={(e, value) => field.onChange(value ?? 0)}
            size="large"
            name="rating"
            color="secondary"
            precision={props.precision}
          ></Rating>
        )}
        name={props.fieldName}
        control={control}
      />
    );
  };

  const submit = (values: CompanyTestimonialModel) => {
    props.submit(values);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)}>
        <DialogContent>
          <SingleTransferList
            disabled={!!props.existingTestimonial?.CompanyId}
            options={props.companies}
            selected={props.companies?.filter((company) => company.Id === getValues("CompanyId"))[0] ?? null}
            fieldLabel="Company"
            placeholder="Company"
            onChanges={(selected: CompanyModel | null) => {
              const newSelectedCompanyId = selected?.Id ?? "";
              const existingSelectedCompanyId = getValues("CompanyId");
              if (newSelectedCompanyId !== existingSelectedCompanyId) {
                setValue("CompanyId", newSelectedCompanyId);
              }
            }}
            getOptionLabel={(o: CompanyModel | null) => o?.Name ?? ""}
            getOptionSelected={(s: CompanyModel, o: CompanyModel) => s.Id === o.Id}
          />
          <FormRatingField fieldName="Rating" precision={0.5} />
          <FormCheckBox fieldName="ShowAtStartPage" label="Show at start page" />
          <FormTextBox fieldName="Title" required />
          <FormTextBox fieldName="Text" required multiline />
          <TransferList
            options={props.domains}
            selected={getValues("Domains")?.length ? getValues("Domains").split(",") : []}
            fieldLabel="Domains to show"
            placeholder="Domains to show"
            onChanges={(s) => setValue("Domains", s.join(","))}
            getOptionLabel={(o) => o}
            getOptionSelected={(s, o) => s === o}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" type="button" onClick={props.handleClose} color="primary">
            {t("General.Buttons.Close")}
          </Button>
          <Button variant="contained" type="submit" color="secondary">
            {t("General.Buttons.Save")}
          </Button>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default CompanyTestimonialDataForm;
