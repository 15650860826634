import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography, LinearProgress, styled } from "@mui/material";
import GoogleMapReact from "google-maps-react-markers";
import useSupercluster from "use-supercluster";
import { Room } from "@mui/icons-material";
import { connect, ConnectedProps } from "react-redux";

import { getMovedIn } from "../../../../actions/admin/admin.actions";
import { ApplicationState } from "../../../../reducers/store";
import { AppThunkDispatch } from "../../../../definitions/Action";
import { convertToCurency } from "../../../../services/converter.service";
import { mapStyles } from "../../../House/Map/utils";

const MapGrid = styled(Grid)(() => ({
  height: 600,
  "& p": {
    fontSize: 26,
    paddingBottom: 6,
  },
}));

const RoomMarker = styled(Room)(({ theme }) => ({
  width: 25,
  height: 25,
  color: theme.palette.primary.main,
  transform: "translate(-50%, -22px)",
}));

const ClusterMarker = styled("div")(({ theme }) => ({
  color: theme.palette.background.default,
  background: theme.palette.primary.main,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transform: "translate(-50%, -50%)",
  width: 30,
  height: 30,
}));

const Marker = ({ children }: any) => children;

const MapPage = (props: MapConnectedProps): JSX.Element => {
  const mapRef = useRef<any>();
  const { movedIn, loading, getMovedIn } = props;

  const [bounds, setBounds] = useState<number[]>([]);
  const [zoom, setZoom] = useState(7);

  useEffect(() => {
    if (!movedIn.length) getMovedIn();
  }, []);

  const points = movedIn.map((marker: any) => ({
    type: "Feature",
    properties: { cluster: false },
    geometry: {
      type: "Point",
      coordinates: [marker.Longitude, marker.Latitude],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 80, maxZoom: 17 },
  });

  return (
    <Grid container justifyContent="center">
      <MapGrid container spacing={3} item sm={10} xs={12}>
        <Typography>Number of users in Denmark: {convertToCurency(movedIn.length, ".")}</Typography>
        {loading && <LinearProgress sx={{ width: "100%" }} />}
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY ?? "" }}
          defaultCenter={{ lat: 55.676098, lng: 12.568337 }}
          defaultZoom={zoom}
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map }: any) => {
            mapRef.current = map;
          }}
          options={{
            streetViewControl: false,
            mapTypeControl: true,
            styles: mapStyles,
          }}
          onChange={({ zoom, bounds }: any) => {
            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();
            setZoom(zoom);
            setBounds([sw.lng(), sw.lat(), ne.lng(), ne.lat()]);
          }}
        >
          {clusters.map((cluster: any, index: number) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } = cluster.properties;

            if (isCluster) {
              return (
                <Marker key={`cluster-${cluster.id}`} lat={latitude} lng={longitude}>
                  <ClusterMarker
                    onClick={() => {
                      const zoom = supercluster.getClusterExpansionZoom(cluster.id);
                      mapRef.current.setZoom(zoom);
                      mapRef.current.panTo({ lat: latitude, lng: longitude });
                    }}
                  >
                    {pointCount}
                  </ClusterMarker>
                </Marker>
              );
            }

            return (
              <Marker key={index} lat={latitude} lng={longitude}>
                <RoomMarker />
              </Marker>
            );
          })}
        </GoogleMapReact>
      </MapGrid>
    </Grid>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  movedIn: state.admin.movedIn,
  loading: state.admin.loading,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getMovedIn: () => dispatch(getMovedIn()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type MapConnectedProps = ConnectedProps<typeof connector>;

export default connector(MapPage);
