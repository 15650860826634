import React, { useEffect, useState } from "react";
import { Button, TextField, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import BaseDialog from "../../../../../components/Dialogs/BaseDialog";
import { useAlertContext } from "../../../../../components/Base";
import TemplatesSelector from "../../Templates/components/TemplatesSelector";
import { TemplateType, ArticleCategory } from "../../../../../constants/enums";
import { ArticleModel } from "../../../../../definitions/model/Club";
import TransferList, {
  TransferListItem,
} from "../../../../../components/Base/TransferList";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";
import CircularProgressButton from "../../../../../components/Base/CircularProgressButton";

type ArticleCreationDialogProps = {
  open: boolean;
  handleClose: () => void;
  createArticle: (
    title: string,
    uniqueName: string,
    templateId: string,
    categories: ArticleCategory[],
    tag: string
  ) => Promise<ArticleModel | undefined>;
};

export type LandingFormData = {
  Title: string;
  UniqueName: string;
  TemplateId: string;
  Categories: ArticleCategory[];
  Tag: string;
};

const ArticleCreationDialog = (
  props: ArticleCreationDialogProps
): JSX.Element => {
  const methods = useForm<LandingFormData>({
    defaultValues: {
      Title: "",
      UniqueName: "",
      TemplateId: "",
      Categories: [],
      Tag: "",
    },
  });
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = methods;
  const [isUpdating, setIsUpdating] = useState(false);
  const { showAlert } = useAlertContext();

  const allCategories: Array<TransferListItem> = Object.entries(ArticleCategory)
    .filter(([key, value]) => {
      return typeof value === "string";
    })
    .map(([key, value]) => {
      const listItem: TransferListItem = {
        name: value.toString(),
        pageValue: "",
        subPageValue: value.toString(),
      };
      return listItem;
    });

  useEffect(() => {
    register("Categories");
    return () => {
      unregister("Categories");
    };
  }, [register, unregister]);

  const handleClose = () => {
    props.handleClose();
  };

  const submit = (data: LandingFormData) => {
    setIsUpdating(true);
    props
      .createArticle(
        data.Title,
        data.UniqueName,
        data.TemplateId,
        data.Categories,
        data.Tag
      )
      .then((landing) => {
        if (landing)
          showAlert({
            severity: "success",
            text: "Landing succesfuly created",
          });
        else showAlert({ severity: "error", text: "Landing was not created" });
      })
      .finally(() => {
        setIsUpdating(false);
        props.handleClose();
      });
  };

  const setCategories = (selected: TransferListItem[]) => {
    const categories = selected.map(
      (item) => ArticleCategory[item.name as keyof typeof ArticleCategory]
    );

    setValue("Categories", categories);
  };

  return (
    <>
      <BaseDialog
        open={props.open}
        closeDialog={handleClose}
        titleComponent="Create article"
      >
        <form onSubmit={handleSubmit(submit)}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <TextFieldConnector
                register={register("Title", { required: true })}
              >
                <TextField
                  autoFocus
                  margin="dense"
                  variant="outlined"
                  label="Title"
                  type="text"
                  fullWidth
                  error={!!errors.Title}
                />
              </TextFieldConnector>
            </Grid>
            <Grid item xs={12}>
              <TextFieldConnector
                register={register("UniqueName", { required: true })}
              >
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Unique name"
                  type="text"
                  fullWidth
                  error={!!errors.UniqueName}
                />
              </TextFieldConnector>
            </Grid>
            <Grid item xs={12}>
              <TemplatesSelector
                name="TemplateId"
                control={control}
                templatesType={TemplateType.ClubArticle}
              />
            </Grid>
            <Grid item xs={12}>
              <TransferList
                options={allCategories}
                selected={[]}
                fieldLabel="Categories"
                placeholder="Select categories"
                onChanges={setCategories}
                getOptionLabel={(o: TransferListItem) => o.name}
                getOptionSelected={(
                  s: TransferListItem,
                  o: TransferListItem
                ) => {
                  return (
                    s.pageValue === o.pageValue &&
                    s.subPageValue === o.subPageValue
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldConnector register={register("Tag")}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Tag"
                  type="text"
                  fullWidth
                  error={!!errors.Tag}
                />
              </TextFieldConnector>
            </Grid>
            <Grid container justifyContent="flex-end" item>
              <Button
                variant="outlined"
                onClick={handleClose}
                color="secondary"
                style={{ minWidth: 120, marginRight: 20 }}
              >
                Close
              </Button>

              <CircularProgressButton
                style={{ minWidth: 120 }}                
                type="submit"                
                inProgress={isUpdating}
              >
                Create
              </CircularProgressButton>
            </Grid>
          </Grid>
        </form>
      </BaseDialog>
    </>
  );
};

export default ArticleCreationDialog;
