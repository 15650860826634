import React, { RefObject, SyntheticEvent, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardMedia,
  CardHeader,
  CardContent,
  CircularProgress,
  Collapse,
  useMediaQuery,
  useTheme,
  styled,
  Skeleton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { convertToCurency } from "../../../services/converter.service";
import { useToggler } from "../../../helpers/hooks";
import { UnitNeighbourHouse } from "../../../definitions/model/unit";
import AddressHelper from "../../../helpers/address-helper";
import NoPhoto from "../../../assets/img/no_house.jpg";
import { MCard } from "../../../components/Base";

const CardStyled = styled(Card)(({ theme }) => ({
  margin: 5,
  border: `1px solid ${theme.palette.background.gray}`,
}));

type NeighborHouseProps = {
  unit: UnitNeighbourHouse;
};

const SCardHeader = styled(CardHeader)(() => ({
  "&.MuiCardHeader-root": {
    backgroundColor: "#fff",
    paddingBottom: 0,
  },
  "& .MuiCardHeader-title": {
    fontSize: 12,
  },
  "& .MuiCardHeader-content": {
    display: "flex",
    flexDirection: "column-reverse",
  },
}));

const NeighborHouse = ({ unit }: NeighborHouseProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const handleClick = () => {
    navigate(`${AddressHelper.GetUnitNeighbourAddressUrl(unit)}/profile`);
  };

  return (
    <CardStyled onClick={handleClick}>
      <HousePhoto photo={unit.Photo} />
      <SCardHeader
        subheader={`${unit.Address.Street} ${unit.Address.HouseNo}`}
        title={`${unit.Address.PostalCode} ${unit.Address.Commune.CommuneName}`}
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item>
            <Typography variant="body2">
              {convertToCurency(unit.Price, ".")} {t("Subscriptions.Currency")}
            </Typography>
            <Typography variant="body2">{t("Unit.Neighbourhood.SalePrice")}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">
              {convertToCurency(unit.PricePerKvm, ".")} {t("Subscriptions.Currency")}
            </Typography>
            <Typography variant="body2">{t("Unit.Neighbourhood.PricePerM2")}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </CardStyled>
  );
};

type PhotoProps = {
  photo: string;
};

const HousePhoto = ({ photo }: PhotoProps) => {
  const [isPhotoAvailable, setIsPhotoAvailable] = useState(false);
  const [isPhotoLoading, setIsPhotoLoading] = useState(false);
  const photoRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    if (photoRef.current) {
      const io = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) return;
          const image = new Image();
          setIsPhotoLoading(true);
          image.src = photo;

          if (image.complete) {
            setIsPhotoAvailable(true);
            setIsPhotoLoading(false);
          }

          image.onload = () => {
            setIsPhotoAvailable(true);
            setIsPhotoLoading(false);
          };

          image.onerror = () => {
            setIsPhotoAvailable(false);
            setIsPhotoLoading(false);
          };
          io.unobserve(entry.target);
        });
      });

      io.observe(photoRef.current);
    }
  }, [photoRef.current]);

  return (
    <div ref={photoRef}>
      {isPhotoLoading ? (
        <Skeleton style={{ transform: "scale(1.05)", width: "100%", height: "155px" }} />
      ) : (
        <CardMedia
          component="img"
          image={isPhotoAvailable ? photo : NoPhoto}
          style={{ opacity: isPhotoAvailable ? 1 : 0.5, height: "155px" }}
        ></CardMedia>
      )}
    </div>
  );
};

type HouseNeighbourhoodProps = {
  neighbourHouses: UnitNeighbourHouse[];
  isLoading: boolean;
};

const HouseNeighbourhood = (props: HouseNeighbourhoodProps): JSX.Element => {
  const [open, toggleOpen] = useToggler(false);
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const [isLoading, setIsLoading] = useState(props.isLoading);
  const [neighbourHouses, setNeighbourHouses] = useState(props.neighbourHouses);

  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props.isLoading]);

  useEffect(() => {
    setNeighbourHouses(props.neighbourHouses);
  }, [props.neighbourHouses]);

  return (
    <MCard
      type="narrow"
      title={t("Unit.Neighbourhood.ReferenceHomesTitle")}
      contentType="narrow"
      sx={{
        padding: "0 16px 0 0",
      }}
    >
      <>
        <Box px={4.75}>
          {isLoading ? (
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          ) : (
            neighbourHouses &&
            neighbourHouses.length > 0 && (
              <>
                <Box>
                  <Collapse collapsedSize={isXs ? 695 * 2 : 675} in={open}>
                    <Grid container spacing={4}>
                      {neighbourHouses.map((u) => (
                        <Grid item sm={6} xs={12} key={u.Id}>
                          <NeighborHouse unit={u} />
                        </Grid>
                      ))}
                    </Grid>
                  </Collapse>
                </Box>
                <Grid container justifyContent="center">
                  <Box mt={6} mb={2.75}>
                    <Button size="large" color="secondary" variant="contained" onClick={toggleOpen}>
                      {open ? t("General.Buttons.FoldIn") : t("General.Buttons.FoldOut")}
                    </Button>
                  </Box>
                </Grid>
              </>
            )
          )}
        </Box>
      </>
    </MCard>
  );
};

export default HouseNeighbourhood;
