import {
  Select,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  Chip,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  IconButton,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { Add } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { theme } from "../../../../../constants/theme";
import CloseIcon from "@mui/icons-material/Close";
import {
  FieldArrayWithId,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import {
  EconomyItemFormData,
  SubCategoryLink,
} from "./EconomyItemCreateEditDialog";
import {EmissionSourceDto} from "../../../../ClimatePlan/shared/CO2Plan";
import { useTranslation } from "react-i18next";

type Props = {
  economyLayers: EmissionSourceDto[][];
  sources: EmissionSourceDto[];
  link: FieldArrayWithId<EconomyItemFormData, "SubCategoryLinks", "id">;
  setValue: UseFormSetValue<EconomyItemFormData>;
  getValues: UseFormGetValues<EconomyItemFormData>;
  index: number;
};

interface Entity {
  Id: string;
}

const normalizeData = <T extends Entity>(data: Array<T>) => {
  const stub: [Record<string, T>, Entity["Id"][]] = [{}, []];
  const newRes = data.reduce((acc, element) => {
    acc[0][element.Id] = element;
    acc[1].push(element.Id);
    return acc;
  }, stub);
  return newRes;
};

const GetFlatSources = (sources: Array<EmissionSourceDto>) => {
  let result: Array<EmissionSourceDto> = [];
  for (const source of sources.sort((a, b) => a.Code - b.Code)) {
    result.push({ ...source });

    if (source.ChildSources.length > 0)
      result = result.concat(GetFlatSources(source.ChildSources));
  }

  return result;
};

const EconomyItemsGroupsInput = ({
  economyLayers,
  link,
  sources,
  setValue,
  getValues,
  index,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectValues, setSelectValues] = useState<{ [key: string]: string }>(
    {}
  );
  const { t } = useTranslation("translation");

  const selectHandler = (layerIndex: string, value: string) => {
    setSelectValues((preState) => {
      if (!value) {
        const newState = { ...preState };
        delete newState[layerIndex];

        return newState;
      } else {
        return {
          ...preState,
          [layerIndex]: value,
        };
      }
    });
  };

  const normalizedData = useMemo(() => {
    return normalizeData(sources);
  }, [sources]);

  const [normalizedSources, normalizedIds] = normalizedData;

  const flatSources = useMemo(() => {
    return GetFlatSources(economyLayers[0]);
  }, [economyLayers]);

  const preparedSources = useMemo(() => {
    const obj: { [key: string]: EmissionSourceDto[] } = {};
    flatSources.forEach((source) => {
      if (!obj[source.TargetName ?? source.Name]) {
        obj[source.TargetName ?? source.Name] = [source];
      } else {
        obj[source.TargetName ?? source.Name].push(source);
      }
    });

    return obj;
  }, [flatSources]);

  const buttonHandler = () => {
    const values = Object.values(selectValues);

    let allEntries: EmissionSourceDto[] = [];
    let result: EmissionSourceDto[] = [];

    for (const group of values) {
      allEntries = allEntries.concat(preparedSources[group]);
    }

    const isParentsInList = (
      parent: EmissionSourceDto,
      howManyParrents: number
    ): boolean => {
      const parentsCount = howManyParrents;

      if (!howManyParrents) return true;
      if (
        values.find(
          (value) => value === parent.Name || value === parent.TargetName
        )
      ) {
        if (parent.EmissionSourceHighLevelId) {
          return isParentsInList(
            normalizedSources[parent.EmissionSourceHighLevelId],
            parentsCount - 1
          );
        } else {
          if (parentsCount === 1) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        if (parent.EmissionSourceHighLevelId) {
          return isParentsInList(
            normalizedSources[parent.EmissionSourceHighLevelId],
            parentsCount
          );
        } else {
          return false;
        }
      }
    };

    const isChildrenInList = (sources: EmissionSourceDto[]): boolean => {
      for (const child of sources) {
        if (
          values.find(
            (value) => child.Name === value || child.TargetName === value
          )
        ) {
          return true;
        } else if (child.ChildSources.length) {
          return isChildrenInList(child.ChildSources);
        }
      }

      return false;
    };

    result = allEntries.filter((entrie) => {
      if (isChildrenInList(entrie.ChildSources)) {
        return false;
      }

      if (
        entrie.EmissionSourceHighLevelId &&
        !isParentsInList(
          normalizedSources[entrie.EmissionSourceHighLevelId],
          values.length - 1
        )
      ) {
        return false;
      }

      return true;
    });

    const formValues = getValues("SubCategoryLinks");
    const newLink: SubCategoryLink = {
      ...formValues[index],
      Categories: [
        ...formValues[index].Categories.filter(
          (category) => !result.find((item) => item.Id === category.CategoryId)
        ),
        ...result.map((item) => ({
          CategoryId: item.Id,
          CategoryName: item.Name,
        })),
      ],
    };

    setValue("SubCategoryLinks", [
      ...formValues.map((category) => {
        if (category.Id === newLink.Id) {
          return newLink;
        } else {
          return category;
        }
      }),
    ]);
    setIsOpen(false);
  };

  return (
    <StyledAccordion expanded={isOpen}>
      <AccordionSummary expandIcon={<Add />} onClick={() => setIsOpen(!isOpen)}>
        <Grid container wrap="wrap">
          {link.Categories.map((category) => (
            <StyledChip
              key={category.CategoryId}
              label={t(normalizedSources[category.CategoryId].TargetName)}
              onDelete={() => {
                setValue("SubCategoryLinks", [
                  ...getValues("SubCategoryLinks").map((c) => {
                    if (c.Id === link.Id) {
                      return {
                        ...c,
                        Categories: c.Categories.filter(
                          (CurrentCategory) =>
                            CurrentCategory.CategoryId !== category.CategoryId
                        ),
                      };
                    } else {
                      return c;
                    }
                  }),
                ]);
              }}
            />
          ))}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid direction="column" container alignItems="center">
          {economyLayers.map((layer, index) => (
            <FormControl key={index} fullWidth style={{ marginBottom: "4px" }}>
              <InputLabel
                style={{ backgroundColor: theme.palette.background.default }}
                htmlFor="outlined-adornment"
              >
                Layer
              </InputLabel>
              <Select
                variant="outlined"
                id="outlined-adornment"
                value={selectValues[index] || ""}
                defaultValue=""
                onChange={(e) =>
                  selectHandler(`${index}`, e.target.value as string)
                }
                IconComponent={() => {
                  if (selectValues[index]) {
                    return null;
                  } else {
                    return <KeyboardArrowDownIcon />;
                  }
                }}
                endAdornment={
                  <IconButton
                    style={
                      selectValues[index]
                        ? { display: "block", width: "40px", height: "40px" }
                        : { display: "none", width: "40px", height: "40px" }
                    }
                    onClick={() => {
                      selectHandler(`${index}`, "");
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                }
              >
                {layer.map((group) => (
                  <MenuItem
                    key={group.Id}
                    value={group.TargetName ?? group.Name}
                  >
                   {group.TargetName? t(group.TargetName) : t(group.Name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}
          <Button variant="contained" color="primary" onClick={buttonHandler}>
            Add items
          </Button>
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  );
};

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: "none",
  border: "solid 1px rgba(0, 0, 0, 0.23)",
  borderTop: "none",
  "&.Mui-expanded": {
    marginTop: 0,
  },
  "&.MuiAccordion-root.Mui-expanded:before": {
    opacity: 1,
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: "4px",
  marginBottom: "4px",
}));

export default EconomyItemsGroupsInput;
