import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Grid, CircularProgress } from "@mui/material";
import { ProductForm, ProductFormData, ProductSubscriptions } from "./components";
import { useParams } from "react-router-dom";
import { getProduct, createProductPrice, getProductPrices } from "../../../../actions/subscriptions.actions";
import { ApplicationState } from "../../../../reducers/store";
import { AppThunkDispatch } from "../../../../definitions/Action";
import { ProductModel, CreatePriceRequest } from "../../../../definitions/model/Subscriptions";
import { LoadWrapper } from "../../../../components/Base";
import { useDefaultReducer } from "../../../../helpers/hooks";
import {
  clearSubscription,
  getProductSubscriptions,
  cancelSubscription,
  createTestSubscription,
} from "../../../../actions/admin/admin.actions";
import PriceTable from "./components/PriceTable";

type ProductEditorState = {
  product: ProductModel | null;
  loading: boolean;
};

const initialState: ProductEditorState = {
  product: null,
  loading: false,
};

const ProductEditor = ({
  getProduct,
  getProductSubscriptions,
  updateProduct,
  productSubscriptions,
  subscriptionsCount,
  cancelSubscription,
  loading,
  getProductPrices,
  createProductPrice,
  createTestSubscription,
}: ProductEditorConnectedProps) => {
  const { id } = useParams<{ id: string }>();
  const [state, dispatch] = useDefaultReducer(initialState);

  useEffect(() => {
    if (!id) return;
    dispatch({ type: "loading", payload: false });
    getProduct(id)
      .then((product) => dispatch({ type: "product", payload: product }))
      .finally(() => dispatch({ type: "loading", payload: false }));
  }, []);

  const defaultProductValues: ProductFormData = {
    Name: state.product?.Name ?? "",
    Description: state.product?.Description ?? "",
  };

  const onSubmit = (data: ProductFormData) => {
    updateProduct({ ...state.product, ...data });
  };

  const getSubscriptions = (
    skip: number,
    take: number,
    isDescending: boolean,
    sortingColumn: string,
    searchTerm: string
  ) => getProductSubscriptions(id ?? "", skip, take, isDescending, sortingColumn, searchTerm);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10}>
        <LoadWrapper
          result={state.product}
          isLoading={state.loading}
          loadingComponent={
            <Grid container justifyContent="center" alignItems="center" style={{ height: "100%" }}>
              <CircularProgress />
            </Grid>
          }
        >
          <ProductForm defaultValues={defaultProductValues} onSubmit={onSubmit} />
          <PriceTable
            createProductPrice={createProductPrice}
            getProductPrices={getProductPrices}
            productId={state.product?.Id || ""}
          />
          <ProductSubscriptions
            count={subscriptionsCount}
            subscriptions={productSubscriptions}
            loading={loading}
            product={state.product}
            getProductSubscriptions={getSubscriptions}
            clearSubscriptions={clearSubscription}
            cancelSubscription={cancelSubscription}
            createTestSubscription={createTestSubscription}
          />
        </LoadWrapper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  subscriptionsCount: state.admin.subscriptionsCount,
  productSubscriptions: state.admin.productSubscriptions,
  loading: state.admin.loading,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  updateProduct: (product: any) => console.log(product),
  getProductSubscriptions: (
    productId: string,
    skip: number,
    take: number,
    isDescending: boolean,
    sortingColumn: string,
    searchTerm: string
  ) => dispatch(getProductSubscriptions(productId, skip, take, isDescending, sortingColumn, searchTerm)),
  clearSubscription: () => dispatch(clearSubscription()),
  cancelSubscription: (subscriptionId: string) => dispatch(cancelSubscription(subscriptionId)),
  getProduct: (productId: string) => dispatch(getProduct(productId)),
  getProductPrices: (productId: string) => dispatch(getProductPrices(productId)),
  createProductPrice: (data: CreatePriceRequest) => dispatch(createProductPrice(data)),
  createTestSubscription: (email: string, productId: string, priceId: string) =>
    dispatch(createTestSubscription(email, productId, priceId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type ProductEditorConnectedProps = ConnectedProps<typeof connector>;

export default connector(ProductEditor);
