import React, { useState } from "react";
import {
  Grid,
  CardContent,
  Typography,
  List,
  Card,
  Box,
  ListItemButton,
  ListItemText,
  useTheme,
  ListItemIcon,
  useMediaQuery,
  Button,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatRelative } from "date-fns";
import daLocale from "date-fns/locale/da";
import MCard from "../../../components/Base/MyhouseCard";
import { UserLogMessage } from "../../../definitions/model/UserMail";
import { CiInboxOut } from "react-icons/ci";
import { CiInboxIn } from "react-icons/ci";
import { ArrowBack } from "@mui/icons-material";

const StyledGrid = styled(Grid)(({ theme }) => ({
  table: {
    [theme.breakpoints.down("md")]: {
      maxWidth: 400,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 280,
    },
  },
}));

type UserLogMessagesProps = {
  logs: UserLogMessage[];
  loading: boolean;
};

const UserLogMessages = (props: UserLogMessagesProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const [viewMessageId, setViewMessageId] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [showMessageList, setShowMessageList] = useState(true);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("md"));



  const handleMessage = (logMessage: UserLogMessage) => {
    if (isXs) {
      setShowMessage(true);
      setShowMessageList(false);
    }
    setViewMessageId(viewMessageId === logMessage.Id ? "" : logMessage.Id);
  };
  const returnToMessageList = () => {
    {
      setShowMessage(false);
      setShowMessageList(true);
      setViewMessageId("");
    }
  };
  return (
    <MCard type="secondary" title={t("UserProfile.MessageLogHeader")} showProgress={props.loading}>
      {props.logs && props.logs.length > 0 && (
        <Grid container>
          {(!isXs || showMessageList) && (
            <Grid item xs={12} md={4}>
              <List
                disablePadding
                sx={{
                  width: "100%",
                }}
              >
                {props.logs.map((logMessage) => (
                  <ListItemButton
                    sx={{ display: "flex", alignItems: "flex-start" }}
                    divider
                    key={logMessage.Id}
                    selected={viewMessageId === logMessage.Id}
                    role={undefined}
                    onClick={() => {
                      handleMessage(logMessage);
                    }}
                    dense
                  >
                    <ListItemIcon sx={{ mt: 0.5, minWidth: 40, color: theme.palette.secondary.main }}>
                      {logMessage.ToUser ? <CiInboxOut fontSize={26} /> : <CiInboxIn fontSize={26} />}
                    </ListItemIcon>
                    <ListItemText>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Typography variant="caption">{logMessage.RecipientEmail}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption">
                            {formatRelative(new Date(logMessage.Created), new Date(), { locale: daLocale })}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography
                        variant="body2"
                        sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
                      >
                        {logMessage.Subject}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                ))}
              </List>
            </Grid>
          )}
          {(showMessage || !isXs) && (
            <Grid item xs={12} md={8}>
              <Card sx={{ background: theme.palette.grey[50], height: "100%", borderRadius: 0 }}>
                <CardContent sx={{ height: "100%" }}>
                  {isXs && (
                    <Button
                      sx={{ position: "sticky", top: "5px" }}
                      variant="outlined"
                      color="secondary"
                      onClick={() => returnToMessageList()}
                    >
                      <ArrowBack fontSize="small" />
                      {t("General.Buttons.Back")}
                    </Button>
                  )}
                  {props.logs.map((logMessage, index) => (
                    <Box key={index}>
                      {viewMessageId === logMessage.Id && (
                        <StyledGrid dangerouslySetInnerHTML={{ __html: logMessage.Message }}></StyledGrid>
                      )}
                    </Box>
                  ))}
                  {viewMessageId === "" && (
                    <Grid container direction="column" justifyContent={"center"} sx={{ height: "100%" }}>
                      <Typography align="center" variant="subtitle1">
                        {t("UserProfile.SelectItem")}
                      </Typography>
                      <Typography align="center" variant="body2">
                        {t("UserProfile.NothingRead")}
                      </Typography>
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      )}
    </MCard>
  );
};

export default UserLogMessages;
