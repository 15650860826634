import React, { useEffect, useReducer } from "react";
import { Grid, Accordion, AccordionDetails, AccordionSummary, CircularProgress, Button } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAdminLandings } from "../../../../actions";
import LandingForm from "./components/LandingForm";
import { ExpandMore } from "@mui/icons-material";
import GrapesJsEditor from "../../../../components/Grapes/GrapesJsEditor";
import { FormBuilderDTO, LandingMetaModel, LandingType } from "../../../../definitions/Landing";
import { LanguageType } from "../../../../definitions/Menu";
import LanguageChangeMenu from "../../../../components/Layout/LanguageChangeMenu";
import { ArrowBack } from "@mui/icons-material";
import ThemeChangeComponent from "../../../../components/Base/ThemeChangeComponent";

type EditLandingPageState = {
  accordionOpen: boolean;
  landing: LandingMetaModel | null;
  editor: FormBuilderDTO | null;
  landingLoading: boolean;
  editorLoading: boolean;
  languageId: LanguageType;
  themeChecked: string;
};

const initialState: EditLandingPageState = {
  accordionOpen: false,
  landing: null,
  editor: null,
  landingLoading: false,
  editorLoading: false,
  languageId: "da",
  themeChecked: "greenTheme",
};

const reducer = (state: EditLandingPageState, action: { type: string; payload?: unknown }) => {
  switch (action.type) {
    case "toggleAccordion":
      return { ...state, accordionOpen: !state.accordionOpen };
    default:
      return {
        ...state,
        [action.type]: action.payload,
      };
  }
};

const EditLandingPage = () => {
  const { id, langId } = useParams<{ id: string; langId: string }>();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const onFullfilled = (type: string) => (result: unknown) => dispatch({ type, payload: result });
  const toggleAccordion = () => dispatch({ type: "toggleAccordion" });
  const setIsLoading = (name: string, value: boolean) => dispatch({ type: name + "Loading", payload: value });

  const [languageId, setLanguageId] = React.useState<LanguageType>((langId as LanguageType) || "da");

  const [, { addLocalization, getLanding, updateLanding, getEditor, updateFacebookPicture }] = useAdminLandings();

  useEffect(() => {
    if (languageId && state.languageId !== languageId) {
      getLocalization(languageId);
      if (languageId !== langId) {
        navigate(`/admin/landings/${id}/${languageId}`);
      }
    }
  }, [languageId]);

  const getLocalization = async (languageId: string) => {
    setIsLoading("landing", true);
    dispatch({ type: "languageId", payload: languageId });
    if (state.landing?.Id && !state.landing?.LocalizedContent[languageId]) {
      const landing = await addLocalization(state.landing!.Id, languageId);
      onFullfilled("landing")(landing);
    }
    setIsLoading("landing", false);
  };

  const getLandingAsync = async () => {
    if (!state.landing) {
      setIsLoading("landing", true);
      try {
        if (!id) return;
        const landing = await getLanding(id);
        onFullfilled("landing")(landing);
        onFullfilled("themeChecked")(landing?.Theme ?? state.themeChecked);
      } finally {
        setIsLoading("landing", false);
      }
    }

    if (state.landing) {
      setIsLoading("editor", true);

      let contentId = "";
      if (state.landing.LocalizedContent[state.languageId]) {
        contentId = state.landing.LocalizedContent[state.languageId];
      } else {
        contentId = state.landing.ExternalLandingId;
      }

      try {
        const editor = await getEditor(contentId);
        onFullfilled("editor")(editor);
      } finally {
        setIsLoading("editor", false);
      }
    }
  };

  useEffect(() => {
    getLandingAsync();
  }, [state.landing, state.languageId]);

  const handleUpdateLanding = async (landing: LandingMetaModel) => {
    setIsLoading("landing", true);
    const result = await updateLanding(landing);
    dispatch({ type: "landing", payload: landing });
    setIsLoading("landing", false);
    return result;
  };

  const handleThemeChange = (event: React.MouseEvent<HTMLElement>, newTheme: string | null) => {
    if (state.landing && newTheme) {
      setIsLoading("landing", true);
      dispatch({ type: "themeChecked", payload: newTheme });
      handleUpdateLanding({
        ...state.landing,
        Theme: newTheme,
      }).finally(() => {
        setIsLoading("landing", false);
      });
    }
  };

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid
        container
        item
        display={"flex"}
        xs={12}
        justifyContent={"space-between"}
        wrap={"nowrap"}
        alignItems={"flex-start"}
      >
        <Grid item justifyContent={"flex-start"} display={"flex"} alignItems={"center"}>
          <Button component={Link} to={"/admin/landings"} type="submit" variant="outlined" color="secondary">
            <ArrowBack fontSize="small" />
            Back
          </Button>
        </Grid>

        <Grid item md={6} sm={9} xs={12} style={{ width: 400 }}>
          <Accordion expanded={state.accordionOpen} onChange={toggleAccordion}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              {state.accordionOpen ? "Hide " : "Expand "}
              {state.landing?.Title} edit form
            </AccordionSummary>

            <AccordionDetails>
              {!state.landingLoading ? (
                state.landing && (
                  <LandingForm
                    landing={state.landing}
                    updateLanding={handleUpdateLanding}
                    updateFacebookPicture={updateFacebookPicture}
                  />
                )
              ) : (
                <Grid container justifyContent="center">
                  <CircularProgress />
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid
          container
          item
          spacing={2}
          alignItems={"center"}
          justifyContent={"flex-end"}
          sx={{ maxWidth: "200px", marginTop: "-8px" }}
        >
          <Grid item>
            <ThemeChangeComponent themeChecked={state.themeChecked} handleThemeChange={handleThemeChange} />
          </Grid>

          <Grid item sx={{ zIndex: 500 }}>
            <LanguageChangeMenu
              loading={state.landingLoading || state.editorLoading}
              languageId={languageId}
              setLanguageId={setLanguageId}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {!state.landingLoading ? (
          <>
            {!state.editorLoading && state.editor && (
              <GrapesJsEditor
                id="landingEditor"
                type={LandingType.Landing}
                builder={state.editor}
                themeToUse={state.landing?.Theme}
              />
            )}
          </>
        ) : (
          <>
            {!state.accordionOpen && (
              <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
                <CircularProgress />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default EditLandingPage;
