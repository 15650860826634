import React, { useEffect, useMemo, useReducer, useState } from "react";
import { Box, Card, CircularProgress, Grid, Grow, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import { LoadWrapper } from "../../components/Base";
import { useUser, useBuilding } from "../../actions";
import { replace, useLocation, useNavigate } from "react-router-dom";
import MoveOutDialog from "../../components/Dialogs/MoveOutDialog/MoveOutDialog";
import { GroupMembersDto, GroupMemberDto } from "../../definitions/model/Group";
import { GroupMemberRole } from "../../constants/enums";
import { useTranslation } from "react-i18next";
import { useDocument } from "../../actions/document.actions";
import ChangePropertyDialog, { ChangePropertyData } from "../Family/components/ChangePropertyDialog";
import UnitHelper from "../../helpers/unit-helper";
import BuildingCard from "./components/BuildingCard";
import { OwnedBuildingDto } from "../../definitions/model/Building";
import SelectCurrentBuildingDialog from "./components/ChangeCurrentBuildingDialog";
import AddressHelper from "../../helpers/address-helper";

type BuildingsListState = {
  buildings: OwnedBuildingDto[];
  fadebleItems: number[];
  loading: boolean;
  openMoveOut: boolean;
  openOwnerChange: boolean;
  openSelectNewHouse: boolean;
  openRemoveDocuments: boolean;
  moveOutBuildingId: string | null;
  moveOutBuildingGroup: GroupMembersDto | null;
};

const StyledCard = styled(Card)(({ theme }) => ({
  transition: "border-color 0.3s",
  cursor: "pointer",
  height: "100%",
  "&:hover": {
    borderColor: theme.palette.grey[600],
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
}));

const StyledAddCircleOutline = styled(AddCircleOutline)(({ theme }) => ({
  fontSize: "6rem",
  width: "6rem",
  height: "6rem",
  transition: "color 0.3s",
  color: theme.palette.grey[500],
}));

const reducer = (
  state: BuildingsListState,
  action: {
    type:
      | keyof BuildingsListState
      | "changeCurrentBuilding"
      | "changeCurrentBuildingSucceded"
      | "openMoveOut"
      | "closeMoveOut"
      | "openChangeOwner"
      | "closeChangeOwner"
      | "openSelectNew"
      | "closeSelectNew"
      | "openRemoveDocuments"
      | "closeRemoveDocuments";
    payload?: unknown;
  }
): BuildingsListState => {
  switch (action.type) {
    case "changeCurrentBuilding":
      return {
        ...state,
        fadebleItems: action.payload as number[],
        loading: true,
      };
    case "changeCurrentBuildingSucceded":
      return {
        ...state,
        fadebleItems: [],
        buildings: action.payload as OwnedBuildingDto[],
        loading: false,
      };
    case "openMoveOut":
      return {
        ...state,
        openMoveOut: true,
      };
    case "closeMoveOut":
      return { ...state, openMoveOut: false };
    case "openChangeOwner":
      return {
        ...state,
        openOwnerChange: true,
      };
    case "closeChangeOwner":
      return { ...state, openOwnerChange: false };
    case "openSelectNew":
      return { ...state, openSelectNewHouse: true };
    case "closeSelectNew":
      return { ...state, openSelectNewHouse: false };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

const initialState: BuildingsListState = {
  buildings: [],
  fadebleItems: [],
  loading: false,
  openMoveOut: false,
  openOwnerChange: false,
  openSelectNewHouse: false,
  openRemoveDocuments: false,
  moveOutBuildingId: null,
  moveOutBuildingGroup: null,
};

const BuildingsManagement = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("md"));
  const [changeBuildingData, setChangeBuildingData] = useState<ChangePropertyData | null>(null);
  const [isChangeBuildingDialogOpen, setIsChangeBuildingDilaogOpen] = useState(false);
  const [{ user }, userActions] = useUser();
  const [, buildingActions] = useBuilding();
  const [, documentActions] = useDocument();

  const { t } = useTranslation("translation");
  useEffect(() => {
    if (state.buildings.length < 1) {
      dispatch({ type: "loading", payload: true });
      buildingActions
        .getOwnedBuildings()
        .then((h) => {
          if (h.length > 0) dispatch({ type: "buildings", payload: h });
        })
        .finally(() => dispatch({ type: "loading", payload: false }));
    }
  }, [state.buildings]);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const isFromDocumentsRemove = Boolean(params.get("fromDocumentsRemove"));
      if (isFromDocumentsRemove && location.state?.selectedUnitId) {
        if (isFromDocumentsRemove) {
          const externalState = location.state.externalState;
          sendMoveOutRequests(location.state.selectedUnitId, externalState.removeAll, externalState.newFamilyOwner);
        }
      }
      replace(location.pathname);
    }
  }, [location.search]);

  const handleChangeBuilding = async (id: string) => {
    const { buildings } = state;
    const newInd = buildings.findIndex((x) => x.Id === id);
    const oldInd = buildings.findIndex((x) => x.IsCurrentBuilding);
    if (newInd === oldInd) return;

    const changeData: ChangePropertyData = {
      adressIn: UnitHelper.GetFullAddress(buildings[newInd].Address),
      adressOut: UnitHelper.GetFullAddress(buildings[oldInd].Address),
    };

    setChangeBuildingData(changeData);
    dispatch({ type: "changeCurrentBuilding", payload: [newInd, oldInd] });
    await userActions.changeCurrentBuilding(id);
    buildings[newInd].IsCurrentBuilding = true;
    buildings[oldInd].IsCurrentBuilding = false;

    dispatch({ type: "changeCurrentBuildingSucceded", payload: buildings });
    setIsChangeBuildingDilaogOpen(true);
  };

  const handleChangeHouseAfterUpdate = async (id: string) => {
    const { buildings } = state;
    const newSelectedHouseIndex = buildings.findIndex((x) => x.Id === id);
    dispatch({
      type: "changeCurrentBuilding",
      payload: [newSelectedHouseIndex],
    });

    userActions.changeCurrentBuilding(id);
    buildings[newSelectedHouseIndex].IsCurrentBuilding = true;
    dispatch({ type: "changeCurrentBuildingSucceded", payload: buildings });
  };

  const closeMoveOut = () => dispatch({ type: "closeMoveOut" });
  const openSelectNew = () => dispatch({ type: "openSelectNew" });
  const closeSelectNew = () => dispatch({ type: "closeSelectNew" });
  const getFilteredHouses = () =>
    state.buildings.filter(
      (x) => x.Id !== state.moveOutBuildingId && x.CurrentUserRole !== GroupMemberRole.NotValidMember
    );

  const isNewOwnerRequired = (family: GroupMembersDto) => {
    const approvedMembers = family.Members.filter((m) => m.Role !== GroupMemberRole.NotValidMember);
    const currentUser = approvedMembers.find((x) => x.UserId === user?.Id);

    return approvedMembers.length > 1 && currentUser?.Role === GroupMemberRole.Administrator;
  };

  const handleMoveOut = async (buildingId: string) => {
    dispatch({ type: "loading", payload: true });
    await buildingActions.moveOutBuilding(buildingId);
    const { buildings: houses } = state;

    if (state.buildings.find((x) => x.Id === buildingId)?.IsCurrentBuilding) {
      const filteredHouses = getFilteredHouses();
      if (filteredHouses.length === 1) {
        const newBuildingId = filteredHouses.find((x) => x.Id !== buildingId)?.Id ?? "";
        if (newBuildingId) {
          const newSelectedHouseIndex = houses.findIndex((x) => x.Id === newBuildingId);
          dispatch({
            type: "changeCurrentBuilding",
            payload: [newSelectedHouseIndex],
          });

          await userActions.changeCurrentBuilding(newBuildingId);
          houses[newSelectedHouseIndex].IsCurrentBuilding = true;
        }
      }
      if (filteredHouses.length > 1) openSelectNew();
    }

    dispatch({
      type: "changeCurrentBuildingSucceded",
      payload: houses.filter((x) => x.Id !== buildingId),
    });
  };

  const hasDocuments = useMemo(() => {
    const house = state.buildings.find((x) => x.Id === state.moveOutBuildingId);
    return house ? house.DocumentsCount > 0 : false;
  }, [state.buildings, state.moveOutBuildingId]);

  const handleMoveoutClick = async (buildingId: string) => {
    const buildingGroup = await buildingActions.getBuildingMembers(buildingId);
    if (buildingGroup) {
      dispatch({ type: "openMoveOut" });
      dispatch({
        type: "moveOutBuildingGroup",
        payload: buildingGroup,
      });
      dispatch({
        type: "moveOutBuildingId",
        payload: buildingId,
      });
    }
  };

  const sendMoveOutRequests = async (
    buildingId: string,
    removeAllDocuments?: boolean,
    newBuildingGroupOwner?: GroupMemberDto
  ) => {
    if (removeAllDocuments && state.moveOutBuildingId !== null) {
      await documentActions.deleteDocuments(state.moveOutBuildingId, [], true);
    }
    if (newBuildingGroupOwner) {
      //await buildingActions.assignNewFamilyOwner(newBuildingGroupOwner, unitId);
    }
    await handleMoveOut(buildingId);
  };

  const navigateToBuildingGroup = (id: string) => {
    navigate(`/buildings/${id}/members`);
  };

  const navigateToBuilding = (building: OwnedBuildingDto) => {
    const buildingUrl = AddressHelper.GetBuildingAddressUrl(building);
    navigate("/buildings/" + buildingUrl);
  };

  const navigateToHouseSearch = () => {
    navigate("/buildings/search");
  };

  return (
    <LoadWrapper
      isLoading={state.loading}
      result={state.buildings}
      emptyResultComponent={
        <Grid container justifyContent="center">
          <Grid item md={4} sm={6} xs={12}>
            <Grow in>
              <Box height={400}>
                <StyledCard variant="outlined" onClick={navigateToHouseSearch}>
                  <Grid container alignItems="center" justifyContent="center" direction="column">
                    <StyledAddCircleOutline />
                    <Typography variant="h5">{t("BuildingManagement.AddNewBuilding")}</Typography>
                  </Grid>
                </StyledCard>
              </Box>
            </Grow>
          </Grid>
        </Grid>
      }
      loadingComponent={
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      }
    >
      <Box mb={2}>
        <Grid container spacing={2} alignContent="stretch" justifyContent={isSmDown ? "center" : "flex-start"}>
          <Grid item md={4} sm={6} xs={12}>
            <Grow in>
              <Box height="100%">
                <StyledCard variant="outlined" onClick={navigateToHouseSearch}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                    sx={{ height: "100%" }}
                  >
                    <StyledAddCircleOutline />
                    <Typography variant="h5">{t("BuildingManagement.AddNewBuilding")}</Typography>
                  </Grid>
                </StyledCard>
              </Box>
            </Grow>
          </Grid>
          {state.buildings
            ?.sort((a, b) => {
              if (!a.PropertyNumber && !b.PropertyNumber) return 0;
              if (!a.PropertyNumber && b.PropertyNumber) return 1;
              if (a.PropertyNumber && !b.PropertyNumber) return -1;
              return a.PropertyNumber > b.PropertyNumber ? 1 : -1;
            })
            .map((u, index) => (
              <Grow
                key={u.Id}
                in={(Boolean(u) && !state.loading) || (!state.fadebleItems.includes(index) && state.loading)}
              >
                <Grid item md={4} sm={6} xs={12}>
                  <BuildingCard
                    building={u}
                    onChnageCurrentBuilding={handleChangeBuilding}
                    onBuildingClick={navigateToBuilding}
                    onMembersClick={navigateToBuildingGroup}
                    onMoveOut={handleMoveoutClick}
                  />
                </Grid>
              </Grow>
            ))}
        </Grid>
      </Box>
      {state.moveOutBuildingGroup && user && state.moveOutBuildingId !== null && (
        <MoveOutDialog
          open={state.openMoveOut}
          unitId={state.moveOutBuildingId}
          unitWithCo2Plan={false}
          from={location.pathname}
          hasDocuments={hasDocuments}
          isOwnerChangeRequired={isNewOwnerRequired(state.moveOutBuildingGroup)}
          sendMoveOutRequests={sendMoveOutRequests}
          userId={user.Id}
          family={state.moveOutBuildingGroup}
          closeDialog={closeMoveOut}
        />
      )}
      <SelectCurrentBuildingDialog
        open={state.openSelectNewHouse}
        closeDialog={closeSelectNew}
        setCurrentBuilding={handleChangeHouseAfterUpdate}
        buildings={getFilteredHouses()}
      />
      {changeBuildingData && (
        <ChangePropertyDialog
          open={isChangeBuildingDialogOpen}
          closeDialog={() => setIsChangeBuildingDilaogOpen(false)}
          changePropertyData={changeBuildingData}
        />
      )}
    </LoadWrapper>
  );
};

export default BuildingsManagement;
