import React from "react";
import { Button, Typography } from "@mui/material";
import BaseDialog from "../../../components/Dialogs/BaseDialog";
import { useTranslation } from "react-i18next";

type ChangesDialogProps = {
  open: boolean;
  okClick: () => void;
  closeDialog: () => void;
};

const ChangesDialog = (props: ChangesDialogProps) => {
  const { t } = useTranslation("translation");
  const onClick = () => {
    props.okClick();
    props.closeDialog();
  };

  return (
    <BaseDialog
      open={props.open}
      closeDialog={props.closeDialog}
      titleComponent="Du har valgt at lukke denne meddelse"
      actionsComponent={
        <Button color="secondary" variant="contained" onClick={onClick}>
          {t("General.Buttons.Ok")}
        </Button>
      }
    >
      <Typography>{t("Subscriptions.FindPreviousMessages")}</Typography>
    </BaseDialog>
  );
};

export default ChangesDialog;
