import { Box, Grid } from "@mui/material";
import React from "react";

type TabPanelProps = {
    children?: React.ReactNode;
    index: number;
    value: number;
  };
  
  export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index } = props;
  
    return (
      <Box mt={4} width="100%" role="tabpanel" hidden={value !== index}>
        {value === index && (
          <Grid container justifyContent="center">
            {children}
          </Grid>
        )}
      </Box>
    );
  };