import React, { useContext, useEffect, useMemo, useState } from "react";
import { Grid, Box } from "@mui/material";


import { ClimatPlanWidgetModel } from "../ClimatePlanPage";
import { CO2Context } from "./CO2ContextWrapper";
type CO2WidgetsProps = {
  tabNumber: number;
};

const CO2WidgetsComponent = (props: CO2WidgetsProps): JSX.Element => {
  const { widgets, renderWidget, WidgetPage } = useContext(CO2Context);

  const widgetsToUse = useMemo(() => {
    return widgets?.filter((c) =>
    c.Pages.includes(WidgetPage))

  }, [widgets]);
  const [targetWidgets, setTargetWidgets] = useState<ClimatPlanWidgetModel[]>([]);

  useEffect(() => {
    if (widgetsToUse) {
      const maxWidgetCount = 1;
      const widgetsToShow = widgetsToUse
        .filter(
          (widget) =>
            widget.Position === 1 && // 1 is a bottom position
            (widget.Tag.includes(props.tabNumber.toString()) || !widget.Tag)
        )
        .slice(0, maxWidgetCount);

      setTargetWidgets(widgetsToShow);
    }
  }, [widgets, props.tabNumber]);

  return (
    <Grid
      container
      item
      direction="column"
      spacing={1}
      style={{ paddingTop: 20 }}
    >
      {targetWidgets.map((widget, index) => (
        <Grid item key={widget.Id}>
          <Box minWidth={275}>
            {renderWidget &&
              renderWidget(widget)}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default CO2WidgetsComponent;
