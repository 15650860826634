import React from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import { TimelineItem } from "../../../definitions/Timeline";
import TimelineSkeleton from "./TimelineSkeleton";
import TimelineBlockItem from "./TimelineItem";

const LineStyled = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "calc(100% + 40px)",
  width: 6,
  top: "-10px",
  left: "calc(50% - 3px)",
  position: "absolute",
  backgroundColor: "#d8d8d8",
  borderRadius: "5px",
  "& div": {
    backgroundColor: "inherit",
    height: 6,
    flex: "0 0 6px",
    borderRadius: "4px",

    [theme.breakpoints.only("xs")]: {
      width: 16,
    },
  },
  [theme.breakpoints.only("xs")]: {
    width: 5,
  },
}));

const ListGridStyled = styled(Grid)(() => ({
  "&>div": {
    width: "50%",
    display: "flex",
    "&:nth-child(odd)": {
      justifyContent: "flex-end",
      paddingRight: 40,
      textAlign: "right",
    },
    "&:nth-child(even)": {
      alignSelf: "flex-end",
      paddingLeft: 40,
      textAlign: "left",
    },
  },
}));

const TransitionStyled = styled("div")(() => ({
  transition: "width 1s",
}));

type TimelineProps = {
  items: [string, TimelineItem[]][];
};

const Timeline = ({ items }: TimelineProps): JSX.Element => {
  return (
    <Box position="relative">
      {items.length > 0 ? (
        <TransitionStyled>
          <Line listItems={items} />
          <ListGridStyled container direction="column">
            {items.map(([year, items], parentIndex) =>
              items.map((item, childIndex) => (
                <Grid item xs={6} key={parentIndex * 10 + childIndex}>
                  <TimelineBlockItem item={item}>
                    {childIndex === 0 ? (
                      <Typography variant="body1">{year}</Typography>
                    ) : null}
                  </TimelineBlockItem>
                </Grid>
              ))
            )}
          </ListGridStyled>
        </TransitionStyled>
      ) : (
        <>
          <Line
            listItems={[
              ["", ""],
              ["", ""],
            ]}
          />
          <TimelineSkeleton />
        </>
      )}
    </Box>
  );
};

type LineProps = {
  listItems: [string, any][];
};

const Line = ({ listItems }: LineProps): JSX.Element => {
  const dashes = [
    <Box
      key={0}
      visibility="hidden"
      mt={2.125}
      sx={{ width: "40px", marginBottom: "119px" }}
    />,
  ];
  let inc = 0;
  for (let i = 0; i < listItems.length; i++) {
    const item = listItems[i][1];
    if (i > 0)
      dashes.push(
        <Box key={i} sx={{ width: "40px", marginBottom: "119px" }} />
      );
    for (let j = 0; j < item.length - 1; j++) {
      dashes.push(
        <Box
          key={"inner_" + inc++ + (i * 10 + j)}
          sx={{ width: "26px", marginBottom: "119px" }}
        />
      );
    }
  }
  return <LineStyled>{dashes}</LineStyled>;
};

export default Timeline;
