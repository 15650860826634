import React from "react";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { useUserTheme } from "../../../helpers/hooks";
import { BuildingUnitDto, InviteTenantDto } from "../../../definitions/model/Building";
import Address from "../../../definitions/model/unit/Address";
import { useTranslation } from "react-i18next";
import { RawData } from "react-spreadsheet-import/types/types";
import { useBuilding } from "../../../actions/building.actions";
import { GroupMemberDto } from "../../../definitions/model/Group";
import { emailRegexp } from "../../../services/validation.service";

type ImportMembersProps = {
  isOpen: boolean;
  onClose: () => void;
  units: BuildingUnitDto[];
  buildingId: string;
  alredyTenants: GroupMemberDto[];
};

type ImportRowDataType = {
  name: string;
  email: string;
  tenantNumber: string;
  debitorNumber: string;
  address: string;
};

const ImportMembers = (props: ImportMembersProps) => {
  const userTheme = useUserTheme();
  const { i18n } = useTranslation("translations");
  const [, buildingActions] = useBuilding();
  const debitorNrPattern = /^D[0-9]{5}$/g;
  const getAddressString = (a: Address) => {
    let address = `${a.Street} ${a.HouseNo}`;
    if (a.Floor) address += `, ${a.Floor}.`;
    if (a.DoorNumber) address += ` ${a.DoorNumber}.`;

    return address;
  };

  const addressOptions = props.units.map((u) => ({
    label: getAddressString(u.Address),
    value: u.Id,
  }));

  const translations: any = { ...i18n.t("SpreadsheetImport", { returnObjects: true }) };
  translations.uploadStep.maxRecordsExceeded = (maxRecords: string) =>
    i18n.t("SpreadsheetImport.uploadStep.maxRecordsExceeded", { maxRecords: maxRecords });

  const fields = [
    {
      label: i18n.t("Building.Excel.Name"),
      key: "name",
      alternateMatches: ["Navn", "Navn 2", "first name"],
      fieldType: {
        type: "input",
      },
      example: "Stephanie",
      validations: [
        {
          rule: "required",
          errorMessage: i18n.t("Building.Excel.NameError"),
          level: "error",
        },
      ],
    },
    {
      label: i18n.t("Building.Excel.Email"),
      key: "email",
      alternateMatches: ["Email", "email", "Mail"],
      fieldType: {
        type: "input",
      },
      example: "email@email.dk",
      validations: [
        {
          rule: "required",
          errorMessage: i18n.t("Building.Excel.EmailError"),
          level: "error",
        },
      ],
    },
    {
      label: i18n.t("Building.Excel.TenantNumber"),
      key: "tenantNumber",
      alternateMatches: ["Nummer"],
      fieldType: {
        type: "input",
      },
      example: "000001",
      validations: [
        {
          rule: "regex",
          regex: /^[0-9]{6}$/g,
          errorMessage: i18n.t("Building.Excel.TenantNumberError"),
          level: "error",
        },
      ],
    },
    {
      label: i18n.t("Building.Excel.DebitorNumber"),
      key: "debitorNumber",
      alternateMatches: ["Debitornr", "Debitornr."],
      fieldType: {
        type: "input",
      },
      example: "D00001",
      validations: [
        {
          rule: "regex",
          regex: debitorNrPattern,
          errorMessage: i18n.t("Building.Excel.DebitorNumberError"),
          level: "error",
        },
      ],
    },
    {
      label: i18n.t("Building.Excel.Address"),
      key: "address",
      alternateMatches: ["Adresse"],
      fieldType: {
        type: "select",
        options: addressOptions,
      },
      example: "D00001",
      validations: [
        {
          rule: "required",
          errorMessage: i18n.t("Building.Excel.AddressError"),
          level: "error",
        },
      ],
    },
  ];

  const onUpload = async (data: RawData[]) => {
    const addresses: string[] = [];
    props.units.forEach((u) => {
      const buildingAddress = `${u.Address.Street} ${u.Address.HouseNo}`;
      if (!addresses.includes(buildingAddress)) addresses.push(buildingAddress);
    });

    const filteredData = data.filter((item, index) => {
      if (index === 0) {
        return true;
      }
      if (
        props.alredyTenants.some((tenant) =>
          Object.keys(item).some((key) => {
            const value = (item as any)[key]?.toString();
            return (
              (emailRegexp.test(tenant.Email) && value.includes(tenant.Email)) ||
              (debitorNrPattern.test(tenant.DebitorNumber) && value.includes(tenant.DebitorNumber))
            );
          })
        )
      ) {
        return false;
      }
      return addresses.some((address) =>
        Object.keys(item).some((key) => (item as any)[key]?.toString().startsWith(address))
      );
    });

    return filteredData.length > 0 ? filteredData : data;
  };

  const onSubmit = async (data: any) => {
    const tenantsList: InviteTenantDto[] = data.validData
      .map((item: ImportRowDataType) => {
        const emails = item.email.split(",");
        const result: InviteTenantDto[] = [];
        for (const email of emails)
          result.push({
            Name: item.name,
            Email: email,
            Source: item.address,
            TenantNumber: item.tenantNumber,
            DebitorNumber: item.debitorNumber,
          });

        return result;
      })
      .flat();

    await buildingActions.inviteTenant(props.buildingId, tenantsList);
  };

  return (
    <ReactSpreadsheetImport
      isOpen={props.isOpen}
      onClose={props.onClose}
      uploadStepHook={onUpload}
      onSubmit={onSubmit}
      translations={translations}
      autoMapSelectValues={true}
      fields={fields}
      customTheme={{
        components: {
          Button: {
            baseStyle: {
              borderRadius: userTheme.shape.borderRadius,
            },
            defaultProps: {
              colorScheme: "green",
            },
          },
        },
      }}
    />
  );
};

export default ImportMembers;
