import React, { useEffect, useReducer } from "react";
import {
  Container,
  Box,
  Typography,
  Card,
  Grid,
  List,
  TextField,
  Button,
  CircularProgress,
  Autocomplete,
  Pagination, styled, TextFieldProps,
} from "@mui/material";
import PostBoxMessage from "./components/PostBoxMessage";
import { ApplicationState } from "../../reducers/store";
import { AppThunkDispatch } from "../../definitions/Action";
import { bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import {
  getMessages,
  getMessage,
  getThread,
  createMessage,
  replyToMessage,
  moveMessageToTrash,
  removeMessage,
  getRecipients,
} from "../../actions/postbox.actions";
import {
  UserMailContract,
  RecipientModel,
} from "../../definitions/model/UserMail";
import * as _ from "lodash";
import { NIL as NIL_UUID } from "uuid";
import MCard from "../../components/Base/MyhouseCard";
import MainContainer from "./../../components/Layout/MainContainer";
import { differenceInMilliseconds } from "date-fns/esm";
import DetailsSkeleton from "../../components/Base/DetailsSkeleton";
import { useTranslation } from "react-i18next";

const StyledCard = styled(Card)(({theme})=>({ 
  border: "solid 1px #e4e4e4", marginBottom: 20}))


type PostBoxPageState = {
  subject: string;
  text: string;
  currentPage: number;
  sendTo: string[];
  autocompleteValue: RecipientModel[];
};

const initialState: PostBoxPageState = {
  subject: "",
  text: "",
  currentPage: 1,
  sendTo: [],
  autocompleteValue: [],
};

const reducer = (
  state: PostBoxPageState,
  action: { type: string; payload: any }
) => {
  if (action.type === "clear") {
    return {
      ...state,
      subject: "",
      text: "",
      sendTo: [],
      autocompleteValue: [],
    };
  }
  return { ...state, [action.type]: action.payload };
};

const PostBoxPage = (props: PostBoxPageConnectedProps) => {
  const { messages, loading, getMessages, unreadMessages, getMessage } = props;
  const { t } = useTranslation("translation");
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!messages.length) {
      getMessages(0, 10);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user?.Id]);

  useEffect(() => {
    if (unreadMessages.length) {
      if (state.currentPage === 1) {
        if (unreadMessages.length > 5) getMessages(0, 10);
        else {
          getMessage(unreadMessages[0]);
        }
      }
    }
    return () => {};
  }, [unreadMessages, state.currentPage]);

  const reply = (message: UserMailContract) => {
    props.replyToMessage(message);
  };

  const createNewMessage = () => {
    const message: UserMailContract = {
      Recipients: state.sendTo,
      Subject: state.subject,
      Html: "",
      Text: state.text,
      ThreadId: NIL_UUID,
      ImageUrl: "",
    };
    dispatch({ type: "clear", payload: null });
    props.createMessage(message);
  };

  const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: event.currentTarget.id,
      payload: event.currentTarget.value,
    });
  };

  const handlePageChange = (event: any, page: number) => {
    if (state.currentPage !== page) {
      getMessages((page - 1) * 10, 10);
      dispatch({ type: "currentPage", payload: page });
    }
  };

  const handleAutocompleteChange = (
    event: React.SyntheticEvent,
    value: RecipientModel[]
  ) => {
    dispatch({
      type: "autocompleteValue",
      payload: value,
    });
    dispatch({
      type: "sendTo",
      payload: value.reduce<string[]>((a, c) => [...a, c.Id], []),
    });
  };

  const handleAutocompleteInputChange = async (
    event: React.SyntheticEvent,
    value: string
  ) => {
    if (value.length < 3) {
      dispatch({
        type: "autocompleteError",
        payload: t("Pages.PostBox.EnterAtLeastThreeCharacters"),
      });
      return;
    }

    props.getRecipients(value);
  };

  return (
    <MainContainer title="Boligens postkasse">
      <Container maxWidth="md">
        <Typography variant="h3">{t("Pages.PostBox.MyMailbox")}</Typography>
        <Typography variant="body1">
          {t("Pages.PostBox.AboutWritingNewLetter", { hostname: window.location.hostname })}
        </Typography>
        <MCard type="main" title={t("Pages.PostBox.MyMailbox")}>
          <>
            {loading ? (
              <List disablePadding>
                {new Array(10).fill(0).map((_, idx) => (
                  <DetailsSkeleton key={idx} />
                ))}
              </List>
            ) : (
              <>
                {!!messages.length && (
                  <List disablePadding>
                    {_.map(_.groupBy(messages, "ThreadId")).map((m, i) => (
                      <PostBoxMessage
                        key={i}
                        messages={m.sort((a, b) =>
                          differenceInMilliseconds(
                            new Date(a.Created),
                            new Date(b.Created)
                          )
                        )}
                        replyToMessage={reply}
                        removeMessage={props.removeMessage}
                        currentuserId={props.user?.Id}
                      />
                    ))}
                  </List>
                )}
                {!messages.length && (
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="body1">
                        {t("Pages.PostBox.YourMailboxEmpty")}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            {Math.ceil(props.count / 10) > 1 && (
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Pagination
                    style={{ marginTop: 10 }}
                    count={Math.ceil(props.count / 10)}
                    page={state.currentPage}
                    onChange={handlePageChange}
                    hideNextButton={
                      state.currentPage === Math.ceil(props.count / 10)
                    }
                    hidePrevButton={state.currentPage === 1}
                  />
                </Grid>
              </Grid>
            )}
          </>
        </MCard>
        <StyledCard elevation={0}>
          <Grid container direction="column" spacing={2}>
            <Box p={3}>
              <Typography variant="body1">
                {t("Pages.PostBox.WriteNewLetter")}
              </Typography>
              <Typography variant="body2">
                {t("Pages.PostBox.EnterHomesHistory")}
              </Typography>
            </Box>
            <Box p={3} sx={(theme)=>({
              backgroundColor: theme.palette.background.grayPaper,
            })}>
              <Grid item>
                <Typography variant="body1">{t("Documents.Title")}</Typography>
                <TextField
                  id="subject"
                  variant="outlined"
                  placeholder={t("Documents.WriteYourTitle") ?? ""}
                  fullWidth
                  value={state.subject}
                  onChange={changeValue}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {t("Pages.PostBox.Message")}
                </Typography>
                <TextField
                  id="text"
                  variant="outlined"
                  multiline
                  minRows={5}
                  fullWidth
                  placeholder="..."
                  value={state.text}
                  onChange={changeValue}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {t("Pages.PostBox.AddRecipient")}{" "}
                </Typography>

                <Autocomplete
                  multiple
                  id="recipient"
                  onChange={handleAutocompleteChange}
                  onInputChange={handleAutocompleteInputChange}
                  value={state.autocompleteValue}
                  options={props.recipients}
                  getOptionLabel={(option) => option.Name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params as TextFieldProps}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {props.loadingRecipients ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid container direction="row-reverse">
                <Button
                  variant="contained"
                  disabled={
                    props.updating ||
                    !(state.sendTo.length && state.subject.length)
                  }
                  onClick={createNewMessage}
                  style={{ marginTop: 10 }}
                >
                  {t("General.Buttons.Send")}
                </Button>
              </Grid>
            </Box>
          </Grid>
        </StyledCard>
      </Container>
    </MainContainer>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  messages: state.postbox.messages,
  user: state.user.user,
  loading: state.postbox.loading,
  updating: state.postbox.updating,
  loadingRecipients: state.postbox.loadingRecipients,
  error: state.postbox.error,
  count: state.postbox.count,
  recipients: state.postbox.recipients,
  unreadMessages: state.postbox.unreadMessages,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) =>
  bindActionCreators(
    {
      getMessages,
      getMessage,
      getThread,
      createMessage,
      replyToMessage,
      moveMessageToTrash,
      removeMessage,
      getRecipients,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type PostBoxPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(PostBoxPage);