import React from "react";
import { Box, Typography, Card, CardHeader, CardMedia, CardContent, Grid, Link, styled } from "@mui/material";
import { formatDistanceStrict } from "date-fns";
import { da } from "date-fns/locale";
import { NewsStreamDTO } from "../../../definitions/NewsStream";
import { useTranslation } from "react-i18next";

const Content = styled(Card)(({ theme }) => ({
  color: theme.palette.primary.dark,
  width: "100%",
  border: "1px #efefef solid",
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  padding: "0 16px",
  "& .MuiTypography-root": {
    padding: 0,
  },
  "& .MuiCardHeader-content": {
    width: "100%",
  },
}));

type NewsItemProps = {
  model: NewsStreamDTO;
};

const NewsItem = ({ model }: NewsItemProps) => {
  const { t } = useTranslation("translation");
  const { Header, Image, Text, Source, Link: href } = model.ActionModel.Action;

  const publishedTimeDistance = formatDistanceStrict(new Date(model.LastUpdate), new Date(), {
    locale: da,
  });

  return (
    <Content elevation={0}>
      <Box p={1}>
        {Image ? <CardMedia title={Header} image={Image} sx={{ height: "200px" }} /> : null}

        <StyledCardHeader
          titleTypographyProps={{ variant: "h6" }}
          title={
            <Grid container wrap="nowrap" alignItems="center">
              <Link
                noWrap
                href={href}
                sx={{
                  flexGrow: 1,
                  minWidth: 0,
                  display: "block",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {decodeURI(Header)}
              </Link>
            </Grid>
          }
        />

        <CardContent style={{ padding: "8px 16px 0px" }}>
          <Typography variant="body1" align="justify">
            {Text}
          </Typography>
          <Box px={2.5} pt={2} pb={0.5}>
            <Typography variant="caption" component="div">
              <Grid container>
                <Box flexGrow={1}></Box>
                {publishedTimeDistance} {t("NewsStream.SinceSource")} {Source}
              </Grid>
            </Typography>
          </Box>
        </CardContent>
      </Box>
    </Content>
  );
};

export default NewsItem;
