import { Dispatch } from "redux";
import { AdminNavigationActionTypes } from "./admin.navigation.actiontypes";
import { AppAction, AppActionThunk } from "../../../../definitions/Action";
import {
  CreateNavigationItemContract,
  NavigationItem,
  UpdateNavigationItemContract,
} from "../../../../definitions/Menu";
import AdminNavigationService from "./admin.navigation.service";
import { ApplicationState } from "../../../../reducers/store";
import { useSelector } from "react-redux";
import {
  operationFailedActionGeneral,
  useAppDispatch,
} from "../../../../actions";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(
    payload,
    AdminNavigationActionTypes.NAVIGATION_OPERATION_FAILED
  );
};

export const useAdminNavigationState = () =>
  useSelector((state: ApplicationState) => state.adminNavigation);

export const useAdminNavigationActions = () => {
  const dispatch = useAppDispatch();

  return {
    getNavigationItems: () => dispatch(getNavigationItems()),
    getNavigationItem: (navItemId: string) =>
      dispatch(getNavigationItem(navItemId)),
    deleteNavigationItem: (navItemId: string) =>
      dispatch(deleteNavigationItem(navItemId)),
    reorderNavigationItems: (items: NavigationItem[]) =>
      dispatch(reorderNavigationItems(items)),
    setNavigationItemPublish: (itemId: string, state: boolean) =>
      dispatch(setNavigationItemPublish(itemId, state)),
    addNavigationItem: (item: CreateNavigationItemContract) =>
      dispatch(addNavigationItem(item)),
    updateNavigationItem: (item: UpdateNavigationItemContract) =>
      dispatch(updateNavigationItem(item)),
    updateBackgroundImage: (picture: File) =>
      dispatch(updateBackgroundImage(picture)),
  };
};

export const getNavigationItems =
  (): AppActionThunk<Promise<NavigationItem[] | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({ type: AdminNavigationActionTypes.GET_NAVIGATION_ITEMS });
      const result = await AdminNavigationService.getNavigationItems();
      dispatch({
        type: AdminNavigationActionTypes.GET_NAVIGATION_ITEMS_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getNavigationItem =
  (itemId: string) => async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({ type: AdminNavigationActionTypes.GET_NAVIGATION_ITEM });
      dispatch({
        type: AdminNavigationActionTypes.GET_NAVIGATION_ITEM_SUCCEEDED,
        payload: itemId,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const deleteNavigationItem =
  (itemId: string) =>
  async (dispatch: Dispatch<AppAction>, getState: () => ApplicationState) => {
    try {
      dispatch({ type: AdminNavigationActionTypes.DELETE_NAVIGATION_ITEM });
      const result = await AdminNavigationService.deleteNavigationItem(itemId);
      dispatch({
        type: AdminNavigationActionTypes.DELETE_NAVIGATION_ITEM_SUCCEEDED,
        payload: result,
      });

      // reorder navigation items after delete
      const navigationItems = getState().adminNavigation.navigationItems;
      dispatch({ type: AdminNavigationActionTypes.REORDER_NAVIGATION_ITEMS });
      const reorderedItems =
        await AdminNavigationService.reorderNavigationItems(navigationItems);
      dispatch({
        type: AdminNavigationActionTypes.REORDER_NAVIGATION_ITEMS_SUCCEEDED,
        payload: reorderedItems,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const reorderNavigationItems =
  (
    items: NavigationItem[]
  ): AppActionThunk<Promise<NavigationItem[] | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({ type: AdminNavigationActionTypes.REORDER_NAVIGATION_ITEMS });
      const result = await AdminNavigationService.reorderNavigationItems(items);
      dispatch({
        type: AdminNavigationActionTypes.REORDER_NAVIGATION_ITEMS_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const setNavigationItemPublish =
  (
    itemId: string,
    state: boolean
  ): AppActionThunk<Promise<NavigationItem | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: AdminNavigationActionTypes.SET_NAVIGATION_ITEM_PUBLISH,
      });
      const result = await AdminNavigationService.setNavigationItemPublish(
        itemId,
        state
      );
      dispatch({
        type: AdminNavigationActionTypes.SET_NAVIGATION_ITEM_PUBLISH_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const addNavigationItem =
  (
    item: CreateNavigationItemContract
  ): AppActionThunk<Promise<NavigationItem | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({ type: AdminNavigationActionTypes.CREATE_NAVIGATION_ITEM });
      const result = await AdminNavigationService.addNavigationItem(item);
      dispatch({
        type: AdminNavigationActionTypes.CREATE_NAVIGATION_ITEM_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const updateNavigationItem =
  (
    item: UpdateNavigationItemContract
  ): AppActionThunk<Promise<NavigationItem | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({ type: AdminNavigationActionTypes.UPDATE_NAVIGATION_ITEM });
      const result = await AdminNavigationService.updateNavigationItem(item);
      dispatch({
        type: AdminNavigationActionTypes.UPDATE_NAVIGATION_ITEM_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const updateBackgroundImage =
  (picture: File): AppActionThunk<Promise<string | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: AdminNavigationActionTypes.UPDLOAD_NAVIGATION_ITEM_BACKGROUND,
      });
      const result = await AdminNavigationService.updateBackgroundImage(
        picture
      );
      dispatch({
        type: AdminNavigationActionTypes.UPDLOAD_NAVIGATION_ITEM_BACKGROUND_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };
