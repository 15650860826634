import React, { ReactElement, useState } from "react";
import {
  ListItemText,
  ListItemIcon,
  IconProps,
  SvgIconProps,
  Collapse,
  List,
  Box,
  styled,
  ListItemButton,
} from "@mui/material";

import { NavLink } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export type MenuItemProps = {
  item: MenuItemModel;
  selectedItem?: MenuItemModel;
};

type ListItemLinkProps = {
  component?: any;
  to?: string;
};

export type MenuItemModel = {
  text: string;
  icon: ReactElement<SvgIconProps | IconProps>;
  subItems?: MenuItemModel[];
  handler?: any;
  href?: string;
};

const MenuItem = ({ item, selectedItem }: MenuItemProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const onClickHandler = () => {
    if (item.subItems) setOpen(!open);
  };

  let listItemLinkProps: ListItemLinkProps = {};

  if (!item.subItems) {
    listItemLinkProps = {
      component: NavLink,
      to: item.href,
    };
  }

  const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "rgba(0, 0, 2, 0.14)",

      "& svg": {
        color: theme.palette.primary.dark,
      },
      "& span": {
        color: theme.palette.primary.dark,
        fontWeight: "bold",
      },
    },
  }));

  return (
    <>
      <ListItemButtonStyled
        selected={selectedItem?.href === item.href}
        onClick={onClickHandler}
        {...listItemLinkProps}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.text} />
        {item.subItems && <>{open ? <ExpandLess /> : <ExpandMore />}</>}
      </ListItemButtonStyled>
      {item.subItems && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.subItems.map((item, index) => (
              <Box pl={1} key={index}>
                <MenuItem item={item} />
              </Box>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default MenuItem;
