import React, { useReducer, useEffect, useMemo, useState } from "react";
import { Grid, TextField, Fab, Tabs, Tab, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { ApplicationState } from "../../../../reducers/store";
import { AppThunkDispatch } from "../../../../definitions/Action";
import {
  getTemplates,
  deleteTemplate,
  clearTemplates,
  addTemplate,
} from "../../../../actions";
import { connect, ConnectedProps } from "react-redux";
import { useDebounce } from "../../../../helpers/hooks";
import { TemplateType } from "../../../../constants/enums";
import TemplateCreateDialog from "./components/TemplateCreateDialog";
import { useAlertContext } from "../../../../components/Base";
import format from "date-fns/format";
import { TemplateModel } from "../../../../definitions/Landing";
import {
  AdminTable,
  Column,
  Icon,
} from "../../components/AdminTable/AdminTable";

type TemplatesPageState = {
  searchTerm: string;
  takeAmount: number;
  templatesType: number | undefined;
  currentTab: number;
  createDialogOpen: boolean;
};

const initialState: TemplatesPageState = {
  searchTerm: "",
  takeAmount: 10,
  templatesType: undefined,
  currentTab: 0,
  createDialogOpen: false,
};

const reducer = (
  state: TemplatesPageState,
  action: { type: string; payload: unknown }
) => {
  return { ...state, [action.type]: action.payload };
};

const TemplatesPage = ({
  templates,
  templatesCount,
  loading,
  error,
  getTemplates,
  deleteTemplate,
  createTemplate,
  clearTemplates,
}: TemplatesPageConnectedProps): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const debouncedSearchTerm = useDebounce(state.searchTerm, 500);
  const navigate = useNavigate();
  const { showAlert } = useAlertContext();
  const [pagination, setPagination] = useState([0, 10]);

  const columns: Column<TemplateModel>[] = useMemo(
    () => [
      {
        name: "Title",
        accessor: "Title",
      },
      {
        name: "Created",
        accessor: "Created",
        format: (data) => format(new Date(data), "yyyy-MM-dd hh:mm"),
      },
      {
        name: "Edit",
        icon: Icon.Edit,
        callback: (data) => navigate("templates/" + data.Id),
      },
      {
        name: "Delete",
        icon: Icon.Delete,
        callback: (data) => handleDeleteTemplate(data.Id),
      },
    ],
    []
  );

  const setCurrentTab = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    dispatch({
      type: "currentTab",
      payload: newValue,
    });
    clearTemplates();
    dispatch({
      type: "templatesType",
      payload: newValue !== 0 ? newValue - 1 : undefined,
    });
  };

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      dispatch({ type: "searchTerm", payload: e.target.value });
    }
  };

  const handleDeleteTemplate = (templateId: string) => {
    deleteTemplate(templateId)
      .then(() => {
        showAlert({ severity: "success", text: "Template succesfuly deleted" });
      })
      .catch(() => {
        const message =
          typeof error === "string" ? error : "Template was not deleted";
        showAlert({ severity: "error", text: message });
      });
  };

  const toggleCreateDialog = () => {
    dispatch({ type: "createDialogOpen", payload: !state.createDialogOpen });
  };

  useEffect(() => {
    getTemplates(
      debouncedSearchTerm,
      pagination[0] * pagination[1],
      pagination[1],
      state.templatesType
    );
    return () => {
      clearTemplates();
    };
  }, [debouncedSearchTerm, state.templatesType, pagination]);

  const tabs = Object.entries(TemplateType)
    .filter(([key, val]) => Number.isInteger(val))
    .map(([key, value]) => ({ key, value }));

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid container justifyContent="center" item sm={8} xs={12}>
        <Grid item>
          <Tabs
            value={state.currentTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={setCurrentTab}
          >
            <Tab label="All"></Tab>
            {tabs.map(({ key }) => (
              <Tab key={key} label={key}></Tab>
            ))}
          </Tabs>
        </Grid>
      </Grid>
      <Grid item sm={8} xs={12}>
        <TextField
          placeholder="Search by template title"
          fullWidth
          onChange={handleSearchTermChange}
        />
      </Grid>

      <Grid item sm={8} xs={12}>
        <AdminTable
          data={templates.filter(
            (x) =>
              x.Type === state.templatesType ||
              !Number.isInteger(state.templatesType)
          )}
          columns={columns}
          loading={loading}
          pagination={"server"}
          count={templatesCount ?? 0}
          onPaginationChange={({ page, rows }) => setPagination([page, rows])}
          headerButton={
            <Grid
              container
              justifyContent="flex-start"
              spacing={2}
              item
              sm={8}
              xs={12}
            >
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={toggleCreateDialog}
                >
                  <Add />
                </Button>
              </Grid>
            </Grid>
          }
        />
      </Grid>

      <TemplateCreateDialog
        open={state.createDialogOpen}
        handleClose={toggleCreateDialog}
        createTemplate={createTemplate}
      />
    </Grid>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  templates: state.adminLanding.templates,
  templatesCount: state.adminLanding.templatesCount,
  loading: state.adminLanding.loading,
  error: state.adminLanding.error,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  createTemplate: (name: string, type: TemplateType) =>
    dispatch(addTemplate(name, type)),
  getTemplates: (
    searchTerm = "",
    skip: number,
    take: number,
    type?: TemplateType
  ) => dispatch(getTemplates(searchTerm, skip, take, type)),
  deleteTemplate: (templateId: string) => dispatch(deleteTemplate(templateId)),
  clearTemplates: () => dispatch(clearTemplates()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type TemplatesPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(TemplatesPage);
