import React, { useEffect, useState } from "react";
import { Grid, Tabs, Tab } from "@mui/material";
import UnitsWithPlan from "./components/UnitsWithPlan";
import SponsorSolutions from "./components/SponsorSolutions";
import { useAlertContext } from "../../../../components/Base";
import EconomyItems from "./components/EconomyItems";
import CO2PlanMetaItems from "./components/CO2PlanMetaItems";
import { TabPanel } from "../../components/TabPanel";
import AgeGroupedItem from "./components/AgeGroupedItem";
import { useAdminCO2 } from "../../../../actions/admin/admin.co2plan.actions";

const CO2PlansPage = () => {
  const { showAlert } = useAlertContext();
  const [{ error }] = useAdminCO2();
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    if (error) {
      showAlert({
        severity: "error",
        text: error,
      });
    }
  }, [error]);

  const handleChange = (event: React.ChangeEvent<any>, newTab: number) => {
    setCurrentTab(newTab);
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item md={6} sm={9} xs={12}>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            sx={{ width: "100%" }}
          >
            <Tab label="Properties with plans" />
            <Tab label="Sponsored solutions" />
            <Tab label="CO2 Plans meta" />
            <Tab label="Economy" />
            <Tab label="Grouped by age" />
          </Tabs>
        </Grid>
        <Grid item container>
          <TabPanel value={currentTab} index={0}>
            <UnitsWithPlan />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <SponsorSolutions />
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            <CO2PlanMetaItems></CO2PlanMetaItems>
          </TabPanel>
          <TabPanel value={currentTab} index={3}>
            <EconomyItems></EconomyItems>
          </TabPanel>
          <TabPanel value={currentTab} index={4}>
            <AgeGroupedItem></AgeGroupedItem>
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default CO2PlansPage;
