import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";

import GrapesJsEditor from "../../../../components/Grapes/GrapesJsEditor";
import { ApplicationState } from "../../../../reducers/store";
import { AppThunkDispatch } from "../../../../definitions/Action";
import { getNewsEditor } from "../../../../actions/admin/admin.actions";
import { LandingType } from "../../../../definitions/Landing";

const EditNewsPage = (props: EditNewsPageConnectedProps) => {
  const { id } = useParams<{id: string}>();


  useEffect(() => {
    if (id) {
      props.getNewsEditor(id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs={12}>
      {props.editingLanding && (
        <GrapesJsEditor
          id="newsEditor"
          builder={props.editingLanding}
          type={LandingType.News}
        />
      )}
    </Grid>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  editingLanding: state.admin.editingLanding,
  loading: state.admin.loading,
  error: state.admin.error,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getNewsEditor: (id: string) => dispatch(getNewsEditor(id))
})

const connector = connect(mapStateToProps, mapDispatchToProps);

type EditNewsPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(EditNewsPage);