import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { useForm } from "react-hook-form";
import { AddTestUnitFormData, PredictionTestUnitModel } from "../defenitions";
import AutoComplete from "../../../../../components/Autocomplete/AutoComplete";
import { useTranslation } from "react-i18next";
import {styled} from "@mui/material";

const RuleContainer = styled(Grid)(({theme})=>({
  width: "100%",
  border: `solid ${theme.palette.grey[300]} 1px`,
  padding: theme.spacing(1, 0),
  boxSizing: "border-box",
}))

type AddTestUnitFormProps = {
  onSubmit: (unitId: string) => Promise<PredictionTestUnitModel | null>;
  onHide: () => void;
};

const AddTestUnitForm = (props: AddTestUnitFormProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const methods = useForm<AddTestUnitFormData>({
    defaultValues: {
      unitId: "",
    },
  });
  const { handleSubmit, register, unregister, reset, setValue, formState: {errors} } =
    methods;

  React.useEffect(() => {
    register("unitId", { required: true });
    return () => unregister("unitId"); // unregister input after component unmount
  }, [register, unregister]);

  const setUnitCallback = (newUnitId: string) => {
    setValue("unitId", newUnitId);
  };

  const submit = (data: AddTestUnitFormData) => {
    if (props.onSubmit(data.unitId) === null) return;

    reset();
  };

  return (
    <RuleContainer onSubmit={handleSubmit(submit)}>
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={11}>
          <Typography variant="h5">Add new Unit for test</Typography>
        </Grid>
        <Grid item xs={11}>
          <AutoComplete
            placeholder={t("Autocomplete.Placeholder")}
            setValueCallback={setUnitCallback}
            inputValue={""}
            className={`${errors.unitId ? "Mui-error Mui-error" : ""}`}
          />
        </Grid>
        <Grid item xs={11} container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={props.onHide}
            >
              Close
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" type="submit">
              Add
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </RuleContainer>
  );
};

export default AddTestUnitForm;
