import React, { Fragment, useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import Head from "@mui/material/TableHead";
import Body from "@mui/material/TableBody";
import Cell from "@mui/material/TableCell";
import TabelSortLabel from "@mui/material/TableSortLabel";
import Row from "@mui/material/TableRow";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import _ from "lodash";
import { DashboardEntryModel } from "../../../../definitions/model/Dashboard";
import { DashboardActivityStatus } from "../../../../constants/enums";
import format from "date-fns/format";
import Chip from "@mui/material/Chip";
import { styled, useMediaQuery, useTheme } from "@mui/material";

const formatDate = (date: string) => {
  const d = new Date(date);
  if (d.getFullYear() === 1) return "null";
  return format(d, "dd MMM yy HH:mm");
};

const getStatusText = (entry: DashboardEntryModel) => {
  switch (entry.ActivityStatus) {
    case DashboardActivityStatus.OK:
      return "Updated";
    case DashboardActivityStatus.ShouldStartSoon:
      return "Should start soon";
    case DashboardActivityStatus.InProgress:
      return "In progress";
    case DashboardActivityStatus.Failed:
      return "Failed";
  }
};

const getFrequencyText = (entry: DashboardEntryModel) => {
  switch (entry.UpdatingFrequency) {
    case 1:
      return "Daily";
    case 7:
      return "Weekly";
    case 30:
      return "Monthly";
    case 90:
      return "Quarterly";
    case 360:
      return "Yearly";
  }
};

const getStatusColor = (entry: DashboardEntryModel) => {
  switch (entry.ActivityStatus) {
    case DashboardActivityStatus.OK:
      return "#99cc33";
    case DashboardActivityStatus.ShouldStartSoon:
      return "#ffcc00";
    case DashboardActivityStatus.InProgress:
      return "#d4faf4";
    case DashboardActivityStatus.Failed:
      return "#ff9966";
  }
};

const StyledLinearProgress = styled(LinearProgress)(() => ({
  position: "absolute",
  width: "100%",
  height: "0.2rem",
  left: 0,
  bottom: 0,
  backgroundColor: "#d4faf444",
  "& 	.MuiLinearProgress-bar": {
    backgroundColor: "#d4faff",
  },
}));

type Direction = "asc" | "desc";

type SortLabelProps = {
  fieldName: string;
  selectedColumn: string;
  onSelect: (fieldName: string) => void;
  children: React.ReactNode;
  direction: Direction;
};

const SortLabel = (props: SortLabelProps): JSX.Element => (
  <TabelSortLabel
    active={props.fieldName === props.selectedColumn}
    direction={props.direction}
    onClick={() => props.onSelect(props.fieldName)}
  >
    {props.children}
  </TabelSortLabel>
);

type Indexable = {
  [key: string]: unknown;
};

type DashboardTableProps = {
  entries: DashboardEntryModel[];
  selectedEntry?: DashboardEntryModel;
  onRowSelect: (entry: DashboardEntryModel) => void;
};

const DashboardTable = (props: DashboardTableProps): JSX.Element => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedColumn, setSelectedColumn] = useState("Name");
  const [sortDirection, setSortDireciton] = useState<Direction>("asc");
  const [entries, setEntries] = useState<DashboardEntryModel[]>([]);

  const selectColumn = (fieldName: string) => {
    if (selectedColumn === fieldName) {
      setSortDireciton(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSelectedColumn(fieldName);
      setSortDireciton("asc");
    }
  };

  const createStatusCell = (x: DashboardEntryModel) => (
    <Cell align={isSm ? "left" : "center"}>
      <Chip
        label={getStatusText(x)}
        style={{ backgroundColor: getStatusColor(x) }}
      />
    </Cell>
  );

  useEffect(() => {
    if (selectedColumn === "LastUpdated" || selectedColumn === "NextUpdate") {
      setEntries(
        _.orderBy(
          props.entries,
          [
            (obj) =>
              new Date((obj as unknown as Indexable)[selectedColumn] as string),
          ],
          [sortDirection]
        )
      );
    } else {
      setEntries(
        _.chain(props.entries).orderBy(selectedColumn, sortDirection).value()
      );
    }
  }, [props.entries, selectedColumn, sortDirection]);

  return (
    <Paper square elevation={2} sx={{ width: "100%" }}>
      <TableContainer>
        <Table size="small">
          <Head>
            <Row>
              <Cell>
                <SortLabel
                  fieldName="Name"
                  selectedColumn={selectedColumn}
                  direction={sortDirection}
                  onSelect={selectColumn}
                >
                  Name
                </SortLabel>
              </Cell>
              {isSm && (
                <Cell align="left">
                  <Cell align="center">
                    <SortLabel
                      fieldName="ActivityStatus"
                      selectedColumn={selectedColumn}
                      direction={sortDirection}
                      onSelect={selectColumn}
                    >
                      Status
                    </SortLabel>
                  </Cell>
                </Cell>
              )}
              <Cell align="center">
                <SortLabel
                  fieldName="LastUpdated"
                  selectedColumn={selectedColumn}
                  direction={sortDirection}
                  onSelect={selectColumn}
                >
                  Last completed
                </SortLabel>
              </Cell>
              <Cell align="center">
                <SortLabel
                  fieldName="NextUpdate"
                  selectedColumn={selectedColumn}
                  direction={sortDirection}
                  onSelect={selectColumn}
                >
                  Estimated start
                </SortLabel>
              </Cell>
              <Cell align="center">
                <SortLabel
                  fieldName="UpdatingFrequency"
                  selectedColumn={selectedColumn}
                  direction={sortDirection}
                  onSelect={selectColumn}
                >
                  Frequency
                </SortLabel>
              </Cell>
              {!isSm && (
                <Cell align="center">
                  <SortLabel
                    fieldName="ActivityStatus"
                    selectedColumn={selectedColumn}
                    direction={sortDirection}
                    onSelect={selectColumn}
                  >
                    Status
                  </SortLabel>
                </Cell>
              )}
            </Row>
          </Head>
          <Body>
            {entries.map((x: DashboardEntryModel) => (
              <Fragment key={x.Id}>
                <Row
                  sx={{ position: "relative" }}
                  selected={props.selectedEntry?.Id === x.Id}
                  onClick={() => props.onRowSelect(x)}
                  hover
                >
                  <Cell>{x.Name}</Cell>
                  {isSm && createStatusCell(x)}
                  <Cell align="center">{formatDate(x.LastUpdated)}</Cell>
                  <Cell align="center">{formatDate(x.NextUpdate)}</Cell>
                  <Cell align="center">{getFrequencyText(x)}</Cell>
                  {!isSm && createStatusCell(x)}
                  {x.ActivityStatus === DashboardActivityStatus.InProgress &&
                    x.Progress && (
                      <StyledLinearProgress
                        variant="determinate"
                        value={x.Progress}
                      />
                    )}
                </Row>
              </Fragment>
            ))}
          </Body>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default DashboardTable;
