import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  styled,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Carousel from "../../../components/Base/Carousel";
import MCard from "../../../components/Base/MyhouseCard";
import NoPhoto from "../../../assets/img/no_house.jpg";
import { useTranslation } from "react-i18next";
import { repeatSkeleton } from "../../../components/Base/DetailsSkeleton";
import { useNavigate } from "react-router-dom";
import AddressHelper from "../../../helpers/address-helper";
import { useBuilding, useUser } from "../../../actions";
import MoveInBuildingDialog from "./MoveInBuildingDialog";
import { convertToCurency } from "../../../services/converter.service";
import { LoadingButton } from "@mui/lab";

type BuildingInfoProps = {
  showOpenBuildingButton?: boolean;
};

const NoHousePhotoGridStyled = styled(Grid)(() => ({
  width: "100%",
  height: "100%",
  backgroundImage: `url(${NoPhoto})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  opacity: 0.4,
}));

const DataGridStyledRow = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

const SliderGridStyled = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    margin: "20px 0",
  },
}));

const TypographyStyledBold = styled(Typography)(() => ({
  fontWeight: "bold",
}));

const BuildingInfo = ({ showOpenBuildingButton }: BuildingInfoProps): JSX.Element => {
  const [photos, setPhotos] = useState<string[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editBuilding, setEditBuilding] = useState(false);
  const [propertyNumber, setPropertyNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const [{ building }, buildingActions] = useBuilding();
  const [{ user }] = useUser();
  const isMovedInBuilding = building && user ? user.MovedInBuildings.includes(building.Id) : false;

  useEffect(() => {
    if (building) {
      setPropertyNumber(building.PropertyNumber ?? "");
      buildingActions
        .getBuildingPhotos(parseInt(building.Address.HouseNo), building.Address.Street, building.Address.PostalCode)
        .then((photo) => {
          if (photo) {
            setPhotos(photo);
          }
        });
    }
  }, [building]);

  const updatePropertyNumber = async () => {
    setLoading(true);
    try {
      if (building && propertyNumber) await buildingActions.setPropertyNumber(building.Id, propertyNumber);
      setEditBuilding(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MCard
      type="main"
      title={t("Pages.House.Building")}
      headerAction={
        <FormControl>
          <FormControlLabel
            value="start"
            control={
              <Switch color="secondary" checked={editBuilding} onChange={(e) => setEditBuilding(e.target.checked)} />
            }
            label={t("Building.Manage")}
            labelPlacement="start"
            sx={{ marginRight: 0, "& .MuiTypography-root": { color: "white" } }}
          />
        </FormControl>
      }
    >
      <Grid container justifyContent="space-between">
        <Grid item sm={6} xs={12}>
          {building ? (
            <>
              <TypographyStyledBold>{building.MainBuilding.Type}</TypographyStyledBold>
              <Typography sx={{ textAlign: "end" }}>
                {building.MainBuilding.Street} {building.MainBuilding.HouseNumber}, {building.MainBuilding.PostalCode},{" "}
                {building.MainBuilding.City}
              </Typography>
            </>
          ) : (
            repeatSkeleton({ width: 200, height: 20 }, 2)
          )}
          <Grid container marginTop={2} marginBottom={2}>
            <Grid item container direction={"column"} justifyContent={"space-around"}>
              <DataGridStyledRow>
                {building && building.PropertyNumber && !editBuilding && (
                  <>
                    <Typography variant="subtitle1">{t("Building.PropertyNumber")}</Typography>
                    <Typography sx={{ textAlign: "end" }}>{building.PropertyNumber}</Typography>
                  </>
                )}
                {building && editBuilding && (
                  <>
                    <TextField
                      label={t("Building.PropertyNumber")}
                      fullWidth
                      variant="outlined"
                      value={propertyNumber}
                      onChange={(e) => setPropertyNumber(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <LoadingButton
                            loading={loading}
                            variant="contained"
                            color="secondary"
                            disabled={loading}
                            onClick={updatePropertyNumber}
                          >
                            {t("General.Buttons.Save")}
                          </LoadingButton>
                        ),
                      }}
                    />
                  </>
                )}
              </DataGridStyledRow>
              <DataGridStyledRow>
                <Typography variant="subtitle1">{t("Base.Popovers.Commune")}</Typography>
                {building ? (
                  <Typography sx={{ textAlign: "end" }}>{building.MainBuilding.Commune}</Typography>
                ) : (
                  <Skeleton />
                )}
              </DataGridStyledRow>
              <DataGridStyledRow>
                <Typography variant="subtitle1">{t("Pages.House.Area")}</Typography>
                {building ? (
                  <Typography sx={{ textAlign: "end" }}>
                    {convertToCurency(building.MainBuilding.BuildingArea, ".")}
                  </Typography>
                ) : (
                  <Skeleton />
                )}
              </DataGridStyledRow>
              <DataGridStyledRow>
                <Typography variant="subtitle1">{t("Base.Popovers.BuiltYear")}</Typography>
                {building ? (
                  <Typography sx={{ textAlign: "end" }}>{building.MainBuilding.BuildYear}</Typography>
                ) : (
                  <Skeleton />
                )}
              </DataGridStyledRow>
              <DataGridStyledRow>
                <Typography variant="subtitle1">{t("Pages.House.Roof")}</Typography>
                {building ? (
                  <Typography sx={{ textAlign: "end" }}>{building.MainBuilding.RoofText}</Typography>
                ) : (
                  <Skeleton width={300} height={20} />
                )}
              </DataGridStyledRow>
              <DataGridStyledRow>
                <Typography variant="subtitle1">{t("Pages.House.HeatingInstallation")}</Typography>
                {building ? (
                  <Typography sx={{ textAlign: "end" }}>{building.MainBuilding.HeatingInstallationText}</Typography>
                ) : (
                  <Skeleton width={300} height={20} />
                )}
              </DataGridStyledRow>
              <DataGridStyledRow>
                <Typography variant="subtitle1">{t("Pages.House.Wall")}</Typography>
                {building ? (
                  <Typography sx={{ textAlign: "end" }}>{building.MainBuilding.WallText}</Typography>
                ) : (
                  <Skeleton width={300} height={20} />
                )}
              </DataGridStyledRow>
              <DataGridStyledRow>
                <Typography variant="subtitle1">{t("Pages.House.HeatingType")}</Typography>
                {building ? (
                  <Typography sx={{ textAlign: "end" }}>{building.MainBuilding.TypeOfHeating}</Typography>
                ) : (
                  <Skeleton width={300} height={20} />
                )}
              </DataGridStyledRow>
            </Grid>
          </Grid>
        </Grid>

        <SliderGridStyled item md={6} sm={6} xs={12} paddingLeft={2}>
          {photos?.length ? <Carousel qualityPhotos={photos} /> : <NoHousePhotoGridStyled></NoHousePhotoGridStyled>}
        </SliderGridStyled>
      </Grid>
      <Grid container direction="column" spacing={1} mt={2}>
        {building && isMovedInBuilding && (
          <>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => navigate(`/buildings/${building.Id}/members`)}
                disabled={!building}
                fullWidth
              >
                {t("BuildingManagement.EditMembers")}
              </Button>
            </Grid>
          </>
        )}
        {building && !isMovedInBuilding && (
          <Grid item>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              sx={{
                width: "200px",
                "& .MuiButton-label": {
                  width: "auto",
                },
              }}
              onClick={() => setOpenDialog(true)}
            >
              {t("Building.MoveIn")}
            </Button>
          </Grid>
        )}
        {building && showOpenBuildingButton && (
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              sx={{
                marginLeft: 1,
                width: "200px",
                "& .MuiButton-label": {
                  width: "auto",
                },
              }}
              onClick={() => navigate(`/buildings/${AddressHelper.GetBuildingAddressUrl(building)}`)}
            >
              {t("Building.OpenPage")}
            </Button>
          </Grid>
        )}
      </Grid>
      <MoveInBuildingDialog presetBuilding={building} open={openDialog} closeDialog={() => setOpenDialog(false)} />
    </MCard>
  );
};

export default BuildingInfo;
