import React, { useState } from "react";
import { Grid, Typography, CardContent } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";

import MCard from "../../../components/Base/MyhouseCard";
import MCheckbox from "../../../components/Base/MyhouseCheckbox";
import { UserSettingsDTO } from "../../../definitions/model/User";
import { useTranslation } from "react-i18next";
import { ControllerConnector } from "../../../components/Base/FormConnector";
import { useUser } from "../../../actions";

type RegisterFormData = {
  notifications: boolean;
  receiveCalls: boolean;
  facebookNotifications: boolean;
  cookies: boolean;
  usagePolicy: boolean;
  bids: boolean;
};

type UserNotificationComponentProps = {
  values: UserSettingsDTO;
  userId: string | null;
  updateNotificationSettigns: (
    userId: string | null,
    sendNotifications: boolean,
    receiveCalls: boolean,
    sendFacebookNotifications: boolean
  ) => Promise<UserSettingsDTO | undefined>;
  getUserSettingsHistory?: () => Promise<void>;
};

const UserNotificationComponent = (props: UserNotificationComponentProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("translation");

  const [{ user }] = useUser();

  const { values } = props;
  const [initialized, setInitialized] = useState(false);

  const methods = useForm<RegisterFormData>({
    defaultValues: {
      notifications: values.ReceiveNotifications,
      receiveCalls: values.ReceiveCalls,
      facebookNotifications: values.ReceiveFacebookNotifications,
      cookies: !!values.RegisterHistoryModel,
      usagePolicy: values.IsTermsAccepted,
      bids: values.IsBidOnHouseAllowed,
    },
  });

  const { handleSubmit, watch, getValues } = methods;

  const submit = (data: RegisterFormData) => {
    setIsLoading(true);
    props
      .updateNotificationSettigns(props.userId, data.notifications, data.receiveCalls, data.facebookNotifications)
      .finally(() => {
        setIsLoading(false);
        if (props.getUserSettingsHistory) props.getUserSettingsHistory();
      });
  };

  React.useEffect(() => {
    if (initialized) submit(getValues());

    //set initialized at first load
    if (watch().notifications === values.ReceiveNotifications && watch().receiveCalls === values.ReceiveCalls)
      setInitialized(true);
  }, [watch().notifications, watch().receiveCalls]);

  return (
    <MCard
      type="secondary"
      title={t("UserProfile.ConsentAndNotifications")}
      subheader={t("UserProfile.RespectForUsersPrivacy", { hostname: window.location.hostname }) ?? ""}
      showProgress={isLoading}
    >
      <CardContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submit)}>
            <Grid container justifyContent="center">
              <Grid container item alignItems="center" justifyContent="space-between">
                <Typography variant="body1" sx={{ width: "80%", marginBottom: 1.25 }}>
                  {t("UserProfile.MailPermission", { hostname: window.location.hostname })}
                </Typography>
                <ControllerConnector name="notifications" type="checkbox">
                  <MCheckbox disabled={isLoading} />
                </ControllerConnector>
              </Grid>
              <Grid container item alignItems="center" justifyContent="space-between">
                <Typography variant="body1" sx={{ width: "80%", marginBottom: 1.25 }}>
                  {t("UserProfile.PhonePermission", { hostname: window.location.hostname })}
                </Typography>
                <ControllerConnector name="receiveCalls" type="checkbox">
                  <MCheckbox disabled={isLoading} />
                </ControllerConnector>
              </Grid>
              {/* <Grid container item alignItems="center" justifyContent="space-between">
                <Typography variant="body1">
                  {t("UserProfile.FacebookPermission", { hostname: window.location.hostname })}
                </Typography>
                <Controller
                  name="facebookNotifications"
                  as={<MCheckbox />}
                  control={control}
                />
              </Grid> 
              <Grid container item alignItems="center" justifyContent="space-between">
                <Typography variant="body1">
                  Jeg accepterer Brugervilkår og Datapolitik samt vores brug af
                  cookies dato:{" "}
                  {format(
                    new Date(values.RegisterHistoryModel.Created),
                    "dd-MM-yyyy hh:mm:ss"
                  )}
                </Typography>
                <Controller
                  name="cookies"
                  as={<MCheckbox disabled />}
                  control={control}
                />
              </Grid>*/}
              <Grid container item alignItems="center" justifyContent="space-between">
                <Typography variant="body1" sx={{ width: "80%", marginBottom: 1.25 }}>
                  {t("UserProfile.DateOfCreationAndApproval")}{" "}
                  {values &&
                    values.TermsAcceptedModel &&
                    new Date(values.TermsAcceptedModel.Created).toLocaleString(user?.Language || "da", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })}
                  , Terms version - {values?.TermsAcceptedModel?.TermsVersion}
                </Typography>
                <ControllerConnector name="usagePolicy" type="checkbox">
                  <MCheckbox disabled />
                </ControllerConnector>
              </Grid>
              {/* {defaultValues.IsBidOnHouseAllowed && (
              <Grid container item alignItems="center" justifyContent="space-between">
                <Typography>Tillad bud på min bolig</Typography>
                <Controller name="bids" as={<MCheckbox />} control={control} />
              </Grid>
            )} */}
            </Grid>
            {/* <Grid className={classes.btn} container justifyContent="center">
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                type="submit"
              >
                {t("General.Buttons.Save")}
              </Button>
            </Grid> */}
          </form>
        </FormProvider>
      </CardContent>
    </MCard>
  );
};

export default UserNotificationComponent;
