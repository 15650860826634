import React, { Suspense, useEffect, useLayoutEffect, lazy } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import MyHouseStartPage from "../pages/MyHouseStartPage/MyHouseStartPage";
import CompleteUserRegistration from "../pages/CompleteRegistration";
import CommercialCompleteUserRegistration from "../pages/CommercialCompleteRegistration";
import FacebookAuthCallback from "../pages/User/FacebookAuthCallback";
import LoadingOverlay from "../components/LoadingOverlay";
import { useUser } from "../actions";
import RemoveDocumentsPage from "../pages/House/Documents/RemoveDocumentsPage";
import OfflinePage from "../pages/OfflinePage";
import { Dialog } from "@mui/material";
import ContactPage from "../pages/Contact/ContactPage";
import AnnouncementEditorPage from "../pages/NeighborGroups/AnnouncementEditorPage";
import NavigationHelper from "../helpers/navigation-helper";
import { UserConstants } from "../constants/user.constants";
import CommuneClimatePlanLayout, { klimaplanRoutes } from "../pages/MyClimatePlanPage";
import CommercialClimatePlan from "../pages/MyClimatePlanPage/pages/ClimatePlanCommercialStartPage";
import NotFoundPage from "../pages/NotFound/NotFoundPage";
const CustomLandingPageLazy = lazy(() => import("../pages/LandingPage/CustomLandingPage"));
const LandingPageLazy = lazy(() => import("../pages/LandingPage/LandingPage"));
const LandingLoaderLazy = lazy(() => import("../pages/LandingPage/LandingLoader"));
const UnsubscribeLazy = lazy(() => import("../pages/LandingPage/Unsubscribe"));
const OverviewPageLazy = lazy(() => import("../pages/Overview/OverviewPage"));
const PartnerLoginLazy = lazy(() => import("../pages/PartnerLogin/PartnerLoginPage"));
const SustainabilityCheckPageLazy = lazy(() => import("../pages/SustainabilityCheck/SustainabilityCheckPage"));
const AboutUsPageLazy = lazy(() => import("../pages/AboutUs/AboutMyHousePage"));
const AboutClimatePlanPageLazy = lazy(() => import("../pages/AboutUs/AboutOurClimatePlanPage"));
const ValidationLazy = lazy(() => import("../pages/AboutUs/ValidationPage"));
const FamilyPageLazy = lazy(() => import("../pages/Family/FamilyPage"));
const HousesPageLazy = lazy(() => import("../pages/Family/HousesPage"));
const ContactPageLazy = lazy(() => import("../pages/Contact/ContactPage"));
const DesignPollLazy = lazy(() => import("../pages/DesignPoll/DesignPollPage"));
const NeighborGroupsPageLazy = lazy(() => import("../pages/NeighborGroups/NeighborGroupsPage"));
import WidgetsPage from "../pages/AdminPanel/pages/Widgets/Widgets";
import WidgetEditor from "../pages/AdminPanel/pages/Widgets/WidgetEditor";
import AdministratorsPage from "../pages/AdminPanel/pages/Administrators/Administrators";
import ClubsPage from "../pages/AdminPanel/pages/Clubs/ClubsPage";
import ClubPageEditor from "../pages/AdminPanel/pages/Clubs/ClubPageEditor";
import ArticleEditorPage from "../pages/AdminPanel/pages/Clubs/ArticleEditorPage";
import MapPage from "../pages/AdminPanel/pages/Map/MapPage";
import NewsPage from "../pages/AdminPanel/pages/News/NewsPage";
import EditNewsPage from "../pages/AdminPanel/pages/News/EditNewsPage";
import DashboardPage from "../pages/AdminPanel/pages/Dashboard/DashboardPage";
import LeadAlarmsPage from "../pages/AdminPanel/pages/Leads/LeadsPage";
import LandingsPage from "../pages/AdminPanel/pages/Landing/LandingsPage";
import EditLandingPage from "../pages/AdminPanel/pages/Landing/EditLandingPage";
import TemplatesPage from "../pages/AdminPanel/pages/Templates/TemplatesPage";
import ProductsPage from "../pages/AdminPanel/pages/Products/ProductsPage";
import ProductEditorPage from "../pages/AdminPanel/pages/Products/ProductEditor";
import EditTemplatePage from "../pages/AdminPanel/pages/Templates/EditTemplatePage";
import BenefitClubsPage from "../pages/AdminPanel/pages/BenefitClubs/BenefitClubsPage";
import EditBenefitClubPage from "../pages/AdminPanel/pages/BenefitClubs/EditBenefitClubPage";
import CommentsModerationPage from "../pages/AdminPanel/pages/CommentsModeration/CommentsModerationPage";
import CO2PlansPage from "../pages/AdminPanel/pages/CO2Plans/CO2PlansPage";
import CompaniesPage from "../pages/AdminPanel/pages/Companies/CompaniesPage";
import OverviewNavigationPage from "../pages/AdminPanel/pages/OverviewNavigation/OverviewNavigationPage";
import BehaviourRulesPage from "../pages/AdminPanel/pages/BehaviourRules/BehaviourRulesPage";
import PricePredictionsPage from "../pages/AdminPanel/pages/PricePredictions/PricePredictionsPage";
import SystemMessages from "../pages/AdminPanel/pages/SystemMessage/SystemMessages";
import SystemMessageEditor from "../pages/AdminPanel/pages/SystemMessage/SystemMessageEditor";
import AggregateViewPage from "../pages/AdminPanel/pages/AggregateViewPage/AggregateViewPage";
import CraftsmenPage from "../pages/AdminPanel/pages/Craftsmen/CraftsmenPage";
import UsersPage from "../pages/AdminPanel/pages/Users/UsersPage";
import SystemSettingsPage from "../pages/AdminPanel/pages/SystemSettings";
import TermsPage from "../pages/AdminPanel/pages/Terms/Terms";
import EditTermsPage from "../pages/AdminPanel/pages/Terms/EditTermsPage";
import AdminLayout from "../pages/AdminPanel";
import ProfilePage from "../pages/User/ProfilePage";
import NewsStreamPage from "../pages/NewsStream/NewsStreamPage";
import NewsDetailsPage from "../pages/NewsStream/NewsDetailsPage";
import PropertyPage from "../pages/House/PropertyFacts/PropertyPage";
import PropertyJoinPage from "../pages/House/PropertyFacts/PropertyJoinPage";
import BuildingJoinPage from "../pages/Building/BuildingJoinPage";
import DocumentsPage from "../pages/House/Documents/DocumentsPage";
import ClubsStartPage from "../pages/Clubs/ClubsStartPage";
import ArticlePage from "../pages/Clubs/ArticlePage";
import ClubsSubscribeIntroPage from "../pages/Clubs/ClubsSubscribeIntroPage";
import ClubsChristmasBox from "../pages/Clubs/ClubsChristmasBox";
import ClubsPaymantPage from "../pages/Clubs/ClubsPaymentPage";
import PostBoxPage from "../pages/PostBox/PostBoxPage";
import UnitWorkersPage from "../pages/Workers/UnitWorkersPage";
import FindUnitWorkersPage from "../pages/Workers/FindUnitWorkersPage";
import HouseModelPage from "../pages/House/HouseModel/HouseModelPage";
import { PriceSubscriptionPage, LocalPlansSubsciptionPage } from "../pages/Subscriptions";
import FamilyPage from "../pages/Family/FamilyPage";
import HousesPage from "../pages/Family/HousesPage";
import ResetPasswordPage from "../pages/User/ResetPasswordPage";

const CO2CalculatorPageLazy = React.lazy(() => import("../pages/ClimatePlan/MyHouseClimatePlan"));
const ServiceBookStartPageLazy = React.lazy(() => import("../pages/House/ServiceBook/ServiceBookStartPage"));
const TasksPageLazy = React.lazy(() => import("../pages/House/Tasks/TasksPage"));
const AboutPropertyLazy = React.lazy(() => import("../pages/House/AboutProperty/about-property-page"));
const MapPageLazy = React.lazy(() => import("../pages/House/Map/MapPage"));
const SearchUnitPageLazy = React.lazy(() => import("../pages/Search/SearchUnitPage"));

const ServiceReportViewPageLazy = React.lazy(() => import("../pages/House/ServiceBook/ServiceReportViewPage"));
import { UserType } from "../constants/enums";
import BuildingLayout, { buildingRoutes, OldInviteBuildingRedirect } from "../pages/Building/BuildingLayout";
import PropertyLayout from "../components/Layout/PropertyLayout";

export type RouteType = {
  title: string;
  path: string;
  element: React.LazyExoticComponent<() => React.JSX.Element> | (() => React.JSX.Element);
  allowAnonymous?: boolean;
};

const RoutesMap = (): JSX.Element => {
  const [{ user, isMaintenance, needToAcceptTerms }, userActions] = useUser();
  const isAdmin = !!user?.Role && user?.Role >= UserType.ExternalManager;
  const isAuthorized = user?.Role !== undefined;

  const { pathname } = useLocation();
  useEffect(() => {
    userActions._getBasicData();

    const beforeInstallPromptHandler = (e: Event) => {
      e.preventDefault();
      userActions.setPwaOptions(e);
    };

    window.addEventListener("beforeinstallprompt", beforeInstallPromptHandler);

    return () => {
      window.removeEventListener("beforeinstallprompt", beforeInstallPromptHandler);
    };
  }, []);

  useEffect(() => {
    const sendAnalyticData = (e: BeforeUnloadEvent) => {
      userActions.dispatchMessage(UserConstants.USER_REGISTERED_AND_STOP);
    };
    if (user) {
      const currentDate = new Date();
      const isNewUser = currentDate.getTime() / 3600000 - new Date(user.Created).getTime() / 3600000 <= 24;

      if (isNewUser && pathname === "/overview") {
        window.addEventListener("beforeunload", sendAnalyticData);
      } else {
        window.removeEventListener("beforeunload", sendAnalyticData);
      }
    }
  });

  useLayoutEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      left: 0,
      top: 0,
    });
  }, [pathname]);

  return (
    <Suspense fallback={<LoadingOverlay loading />}>
      <Dialog open={isMaintenance || false} fullScreen fullWidth>
        <OfflinePage />
      </Dialog>
      <Dialog
        key={pathname}
        open={(pathname != "/profile" && needToAcceptTerms && user !== null) || false}
        fullScreen
        fullWidth
      >
        <ContactPage />
      </Dialog>
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/" element={<PropertyLayout />}>
          <Route path="overview" element={<OverviewPageLazy />} />
          <Route path="landing/:name/:languageId" element={<CustomLandingPageLazy />} />
          <Route path="landing/:name" element={<CustomLandingPageLazy />} />
          <Route path="landing" element={<LandingPageLazy />} />
          <Route path="landing-loader" element={<LandingLoaderLazy />} />
          <Route path="unsubscribe/:uniqueName" element={<UnsubscribeLazy />} />
          <Route
            path=""
            element={NavigationHelper.IsClimateSite() ? <CommercialClimatePlan /> : <MyHouseStartPage />}
          />
          <Route path="about-us" element={<AboutUsPageLazy />} />
          <Route path="about-our-climate" element={<AboutClimatePlanPageLazy />} />
          <Route path="validation" element={<ValidationLazy />} />

          <Route path="sustainabilitycheck" element={<SustainabilityCheckPageLazy />} />
          <Route path="partner" element={<PartnerLoginLazy />} />
          <Route path="facebook-authenticated-callback/:facebookKey" element={<FacebookAuthCallback />} />
          <Route path="completeregistration" element={<CompleteUserRegistration />} />
          <Route path="commercial-completeregistration" element={<CommercialCompleteUserRegistration />} />
          <Route path="contact" element={<ContactPageLazy />} />
          <Route path="privacy" element={<ContactPageLazy />} />
          <Route path="family/:id" element={<FamilyPageLazy />} />
          <Route path="house-managment" element={<HousesPageLazy />} />
          <Route path="remove-documents/" element={<RemoveDocumentsPage />} />
          <Route path="remove-documents/:id" element={<RemoveDocumentsPage />} />
          <Route path="styles" element={<DesignPollLazy />} />
          <Route path="neighbor-groups/:groupId/edit/:id" element={<AnnouncementEditorPage />} />
          <Route path="neighbor-groups/:id" element={<NeighborGroupsPageLazy />} />
          <Route path="neighbor-groups" element={<NeighborGroupsPageLazy />} />
        </Route>
        <Route path="klimaplan" element={<CommuneClimatePlanLayout />}>
          {klimaplanRoutes?.map((route, i) => (
            <Route key={"klimaplan_" + i} path={route.path} element={<route.element />} />
          ))}
        </Route>
        <Route path="/admin" element={<AdminLayout />}>
          {isAdmin && (
            <>
              <Route path="administrators" element={<AdministratorsPage />} />
              <Route path="users" element={<UsersPage />} />
              <Route path="widgets" element={<WidgetsPage />} />
              <Route path="widgets/:id" element={<WidgetEditor />} />
              <Route path="clubs/:id" element={<ClubPageEditor />} />
              <Route path="clubs/:id/article/:articleId" element={<ArticleEditorPage />} />
              <Route path="products/:id" element={<ProductEditorPage />} />
              <Route path="landings/:id/:langId?" element={<EditLandingPage />} />
              <Route path="templates/:id" element={<EditTemplatePage />} />
              <Route path="news/:id" element={<EditNewsPage />} />
              <Route path="benefitclubs/:id" element={<EditBenefitClubPage />} />
              <Route path="terms-editor/:id" element={<EditTermsPage />} />
              {/* <Route path="neighbor-groups/:id"} element={<EditNeighborGroupPage/>} />
        <Route path="neighbor-groups"} element={<NeighborGroupsPage/>} /> */}
              <Route path="articleGroups" element={<ClubsPage />} />
              <Route path="companies" element={<CompaniesPage />} />
              <Route path="lead-alarms" element={<LeadAlarmsPage />} />
              <Route path="landings" element={<LandingsPage />} />
              <Route path="map" element={<MapPage />} />
              <Route path="news" element={<NewsPage />} />
              <Route path="benefitclubs" element={<BenefitClubsPage />} />
              <Route path="templates" element={<TemplatesPage />} />
              <Route path="products" element={<ProductsPage />} />
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="comments" element={<CommentsModerationPage />} />
              <Route path="co2" element={<CO2PlansPage />} />
              <Route path="navigation" element={<OverviewNavigationPage />} />
              <Route path="behaviourrules" element={<BehaviourRulesPage />} />
              <Route path="aggregate-view" element={<AggregateViewPage />} />
              <Route path="green-craftsmen" element={<CraftsmenPage />} />
              <Route path="terms-editor" element={<TermsPage />} />
              <Route path="price-predictions" element={<PricePredictionsPage />} />
              <Route path="system-messages/:id" element={<SystemMessageEditor />} />
              <Route path="system-settings" element={<SystemSettingsPage />} />
              <Route path="system-messages" element={<SystemMessages />} />
              <Route path="" element={<Navigate to="dashboard" replace />} />
            </>
          )}
          {!isAdmin && <Route path="*" element={<Navigate to="/" replace />} />}
        </Route>
        <Route element={<PropertyLayout />}>
          <Route path="/search" element={<SearchUnitPageLazy />} />
          <Route path="/search/move-in" element={<SearchUnitPageLazy />} />
          <Route path="/search/bank" element={<SearchUnitPageLazy />} />
          <Route path="/:addressURL/search" element={<SearchUnitPageLazy />} />
          <Route path="/:addressURL/profile/:token/join" element={<PropertyJoinPage />} />
          <Route path="/:addressURL/profile" element={<PropertyPage />} />
          <Route path="/:addressURL/reset-password" element={<PropertyPage />} />
          <Route
            path="/:addressURL/servicebook"
            element={isAuthorized ? <ServiceBookStartPageLazy /> : <Navigate to="/" />}
          />
          <Route path="/:addressURL/tasks" element={isAuthorized ? <TasksPageLazy /> : <Navigate to="/" />} />
          <Route path="/:addressURL/model" element={<HouseModelPage />} />
          <Route path="/aboutproperty" element={<AboutPropertyLazy />} />
          <Route path="/documents" element={<DocumentsPage />} />
          <Route path="/co2calculator" element={<CO2CalculatorPageLazy />} />
          <Route path="/servicereport/:id" element={<ServiceReportViewPageLazy />} />
          <Route path="/map" element={<MapPageLazy />} />
          <Route path="/news/:newsId" element={<NewsDetailsPage />} />
          <Route path="/news" element={<NewsStreamPage />} />
          <Route path="/favorite-news" element={isAuthorized ? <NewsStreamPage /> : <Navigate to="/" />} />
          <Route path="/articles/:articleName" element={<ArticlePage />} />
          <Route path="/articles" element={<ClubsStartPage />} />
          <Route path="/clubs-subscribe" element={isAuthorized ? <ClubsSubscribeIntroPage /> : <Navigate to="/" />} />
          <Route path="/christmas-box" element={isAuthorized ? <ClubsChristmasBox /> : <Navigate to="/" />} />
          <Route path="/clubs-payment" element={isAuthorized ? <ClubsPaymantPage /> : <Navigate to="/" />} />
          <Route path="/postbox" element={isAuthorized ? <PostBoxPage /> : <Navigate to="/" />} />
          <Route path="/style" element={isAuthorized ? <ClubsStartPage /> : <Navigate to="/" />} />
          <Route path="/community" element={isAuthorized ? <ClubsStartPage /> : <Navigate to="/" />} />
          <Route path="/profile" element={isAuthorized ? <ProfilePage /> : <Navigate to="/" />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/workers" element={isAuthorized ? <UnitWorkersPage /> : <Navigate to="/" />} />
          <Route path="/workers/find" element={isAuthorized ? <FindUnitWorkersPage /> : <Navigate to="/" />} />
          <Route path="/subscriptions/houseprice" element={<PriceSubscriptionPage />} />
          <Route path="/subscriptions/localplans" element={<LocalPlansSubsciptionPage />} />
          <Route path="/family/:id" element={isAuthorized ? <FamilyPage /> : <Navigate to="/" />} />
          <Route path="/house-management" element={isAuthorized ? <HousesPage /> : <Navigate to="/" />} />
        </Route>
        <Route path="/buildings" element={<BuildingLayout />}>
          {isAuthorized && (
            <React.Fragment>
              {buildingRoutes?.map((route, i) => (
                <Route key={"building_" + i} path={route.path} element={<route.element />} />
              ))}
            </React.Fragment>
          )}
          {!isAuthorized && (
            <React.Fragment>
              <Route path="*" element={<Navigate to="/" replace />} />
              {buildingRoutes
                ?.filter((x) => x.allowAnonymous)
                .map((route, i) => (
                  <Route key={"building_" + i} path={route.path} element={<route.element />} />
                ))}
            </React.Fragment>
          )}
        </Route>
        {/* TODO: remove this route when make changes to the system message */}
        <Route path="/:addressURL/building/:token/join" element={<OldInviteBuildingRedirect />} />
      </Routes>
    </Suspense>
  );
};

export default RoutesMap;
