import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { MenuItem, Select, FormControl, InputLabel, Box, Grid, Button, styled, TextField } from "@mui/material";
import MTextField from "../../../../../components/Base/MyhouseTextField";
import TransferList, { TransferListItem } from "../../../../../components/Base/TransferList";
import MButton from "../../../../../components/Base/MyhouseButton";
import {
  WidgetMetaModel,
  WidgetCreateModel,
  WidgetSubPage,
  WidgetSensetiveData,
} from "../../../../../definitions/Landing";
import { WidgetPage, WidgetPosition, TemplateType } from "../../../../../constants/enums";
import { useAlertContext } from "../../../../../components/Base/MyhouseAlert";
import { ClubModel } from "../../../../../definitions/model/Club";
import TemplatesSelector from "../../Templates/components/TemplatesSelector";
import { useTranslation } from "react-i18next";
import { CommuneLocation } from "../../../../../definitions/model/unit/Commune";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";
import DataSensitiveInput from "./DataSensitiveInput";
import { AddLinkOutlined } from "@mui/icons-material";

const StyledSelect = styled(TextField)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));

type WidgetEditFormProps = {
  item?: WidgetMetaModel;
  clubs: Array<ClubModel>;
  communes: Array<CommuneLocation>;
  addWidget?: (widget: WidgetCreateModel) => void;
  updateWidget?: (widget: WidgetMetaModel) => void;
  getCommunes: () => Promise<Array<CommuneLocation> | undefined>;
};

export type WidgetFormData = {
  Title: string;
  TemplateId: string;
  Position: WidgetPosition;
  Pages: Array<WidgetPage>;
  SubPages: Array<WidgetSubPage>;
  Communes: Array<CommuneLocation>;
  Tag: string;
  SensitiveList: WidgetSensetiveData[];
};

const WidgetEditForm = (props: WidgetEditFormProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const { showAlert } = useAlertContext();
  const widgetsPosition = Object.entries(WidgetPosition).filter(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([key, value]) => typeof value === "number"
  );

  const currentWidgetPages: Array<TransferListItem> = props.item?.Pages
    ? props.item.Pages.map((page) => ({
        name: WidgetPage[page],
        pageValue: page,
        subPageValue: "",
      }))
    : [];
  const currentWidgetSubPages: Array<TransferListItem> = props.item?.SubPages
    ? props.item.SubPages.map((subPage) => {
        const clubName = props.clubs.find((c) => c.Id === subPage.SubPageId)?.Name;
        return {
          name: `${WidgetPage[subPage.Page]}: ${clubName}`,
          pageValue: subPage.Page,
          subPageValue: subPage.SubPageId,
        };
      })
    : [];
  const currentWidgetPagesCommon = [...currentWidgetPages, ...currentWidgetSubPages];

  const widgetPages: Array<TransferListItem> = Object.entries(WidgetPage)
    .filter(([key, value]) => {
      return typeof value === "number";
    })
    .map(([key, value]) => {
      const listItem: TransferListItem = {
        name: key,
        pageValue: value as WidgetPage,
        subPageValue: "",
      };
      return listItem;
    });
  const clubPages: Array<TransferListItem> = props.clubs.map((c) => {
    const listItem: TransferListItem = {
      name: `Club: ${c.Name}`,
      pageValue: WidgetPage.Clubs,
      subPageValue: c.Id,
    };
    return listItem;
  });
  const widgetsPagesCommon: Array<TransferListItem> = [...widgetPages, ...clubPages];
  const [selectedCommunes, setSelectedCommunes] = useState<Array<CommuneLocation>>([]);

  const methods = useForm<WidgetFormData>({
    defaultValues: {
      Title: props.item?.Title || "",
      TemplateId: "",
      Position: props.item?.Position || 0,
      Pages: props.item?.Pages || [],
      SubPages: props.item?.SubPages || [],
      Communes: [],
      Tag: props.item?.Tag || "",
      SensitiveList: props.item?.SensitiveList || [],
    },
  });
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = methods;

  const { append, remove, fields } = useFieldArray({
    control: control,
    name: "SensitiveList",
  });

  useEffect(() => {
    register("Position");
    register("Pages");
    register("SubPages");
    register("Communes");
    register("SensitiveList");
    return () => {
      unregister("Position");
      unregister("Pages");
      unregister("SubPages");
      unregister("Communes");
      unregister("SensitiveList");
    };
  }, [register, unregister]);

  useEffect(() => {
    if (!props.communes || props.communes.length === 0) props.getCommunes();

    if (props.item && props.item.Communes && props.communes && props.communes.length > 0) {
      setSelectedCommunes(
        props.item.Communes.map((sc) => {
          return props.communes.filter((c) => c.Commune.CommuneNumber === sc)[0];
        })
      );
    }
  }, [props.communes]);

  const handlerWrapper = (formData: WidgetFormData) => {
    if (!!props.item && props.updateWidget) {
      const data: WidgetMetaModel = {
        Id: props.item.Id,
        Pages: formData.Pages,
        Position: formData.Position,
        Title: formData.Title,
        SubPages: formData.SubPages,
        Tag: formData.Tag,
        Communes: formData.Communes.map((c) => c.Commune.CommuneNumber),
        SensitiveList: formData.SensitiveList,
      };

      props.updateWidget({ ...data });
      return;
    }

    if (props.addWidget) {
      props.addWidget({
        ...formData,
        Communes: formData.Communes.map((c) => c.Commune.CommuneNumber),
      });
    }
  };

  const onSubmit = (values: WidgetFormData) => {
    handlerWrapper(values);
    showAlert({
      severity: "success",
      duration: 2000,
      text: `Widget ${props.item ? "updated" : "created"}`,
    });
  };

  const setPages = (selected: TransferListItem[]) => {
    const pages = selected.filter((p) => !p.subPageValue).map((p) => p.pageValue as WidgetPage);
    const subPages = selected
      .filter((p) => p.subPageValue)
      .map((p) => ({
        Page: p.pageValue as WidgetPage,
        SubPageId: p.subPageValue,
      }));

    setValue("Pages", pages);
    setValue("SubPages", subPages);
  };

  const addsSensitiveItemHandler = () => {
    append({
      Path: "",
      SensitiveAction: "true",
      SensitiveAmount: undefined,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box my={4}>
        <TextFieldConnector register={register("Title", { required: true })}>
          <MTextField label="Title" error={!!errors.Title} fullWidth />
        </TextFieldConnector>
      </Box>
      {props.addWidget && (
        <Box my={4}>
          <TemplatesSelector name="TemplateId" control={control} templatesType={TemplateType.Widget} />
        </Box>
      )}
      <Box my={4}>
        <FormControl fullWidth>
          <StyledSelect
            defaultValue={props.item?.Position || 0}
            value={watch("Position")}
            variant="outlined"
            name="Position"
            fullWidth
            label="Position"
            select
            onChange={(event) => setValue("Position", parseInt(event.target.value as any))}
          >
            {widgetsPosition.map(([key, value]) => (
              <MenuItem key={value} value={value}>
                {key}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      </Box>
      <Box my={4}>
        <TransferList
          options={widgetsPagesCommon}
          selected={currentWidgetPagesCommon}
          fieldLabel="Pages"
          placeholder="Select pages"
          onChanges={setPages}
          getOptionLabel={(o: TransferListItem) => o.name}
          getOptionSelected={(s: TransferListItem, o: TransferListItem) => {
            return s.pageValue === o.pageValue && s.subPageValue === o.subPageValue;
          }}
        />
      </Box>
      <Box my={4}>
        <TransferList
          options={props.communes}
          selected={selectedCommunes}
          fieldLabel="Communes"
          placeholder="Select communes"
          onChanges={(selected: Array<CommuneLocation>) => setValue("Communes", selected)}
          getOptionLabel={(o: CommuneLocation) => o.Commune.CommuneName}
          getOptionSelected={(s: CommuneLocation, o: CommuneLocation) =>
            s.Commune.CommuneNumber === o.Commune.CommuneNumber
          }
        />
      </Box>
      <Box my={4}>
        {fields.map((sensitiveItem, index) => (
          <DataSensitiveInput
            key={sensitiveItem.Path}
            setValue={setValue}
            control={control}
            index={index}
            SensitiveAction={sensitiveItem.SensitiveAction}
            SensitiveAmount={sensitiveItem.SensitiveAmount}
            removeHandler={() => remove(index)}
            SensitivePath={sensitiveItem.Path}
          />
        ))}
        <Button variant="text" startIcon={<AddLinkOutlined />} onClick={addsSensitiveItemHandler}>
          Add Data Sensitive Item
        </Button>
      </Box>
      <Box my={4}>
        <TextFieldConnector register={register("Tag", { required: false })}>
          <MTextField label="Tag" error={!!errors.Tag} fullWidth variant="outlined" />
        </TextFieldConnector>
      </Box>
      {props.item ? (
        <Box my={4}>
          <Grid container justifyContent="center">
            <MButton type="submit">{t("General.Buttons.Save")}</MButton>
          </Grid>
        </Box>
      ) : (
        <Grid container justifyContent="flex-end">
          <Button type="submit" variant="contained" color="secondary">
            Opret
          </Button>
        </Grid>
      )}
    </form>
  );
};

export default WidgetEditForm;
