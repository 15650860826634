import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { TextFieldConnector } from "../../../components/Base/FormConnector";
import { PermissionTarget } from "../../../constants/enums";

type UpdateDocumentFolderFormValues = {
  name: string;
  description: string;
  permission: PermissionTarget;
};

type UpdateDocumentFolderDialogProps = {
  open: boolean;
  name: string;
  description: string;
  permission: PermissionTarget;
  permissions: PermissionTarget[];
  usePermissions: boolean;
  updateDocumentFolder: (name: string, description: string, permission: PermissionTarget) => void;
  onClose: () => void;
};

const EditDocumentFolderDialog = (props: UpdateDocumentFolderDialogProps) => {
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  const methods = useForm<UpdateDocumentFolderFormValues>({
    defaultValues: {
      name: props.name,
      description: props.description,
      permission: props.permission,
    },
  });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = methods;

  const submit = (values: UpdateDocumentFolderFormValues) => {
    props.updateDocumentFolder(values.name, values.description, values.permission);
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{t("Documents.DocumentFolderUpdateTitle")}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(submit)}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={10}>
              <TextFieldConnector register={register("name")}>
                <TextField variant="outlined" fullWidth label={t("Documents.Title")} margin="dense" />
              </TextFieldConnector>
            </Grid>
            <Grid item xs={10}>
              <TextFieldConnector register={register("description")}>
                <TextField variant="outlined" fullWidth label={t("Documents.Description")} margin="dense" />
              </TextFieldConnector>
            </Grid>
            {props.usePermissions && (
              <Grid item xs={10}>
                <FormControl
                  fullWidth
                  margin="dense"
                  sx={{ "& .MuiSelect-select": { paddingTop: 1, paddingBottom: 1 } }}
                >
                  <InputLabel id="permission-field-label">{t("Documents.Permission")}</InputLabel>
                  <Controller
                    name="permission"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        variant="outlined"
                        label={t("Documents.Permission")}
                        labelId="permission-field-label"
                        error={!!errors.permission}
                        onChange={(e) => {
                          setValue("permission", e.target.value as PermissionTarget);
                        }}
                      >
                        {props.permissions.map((permission) => (
                          <MenuItem key={permission} value={permission} style={{ padding: "10px 20px" }}>
                            {t(`Documents.BuildingPermissions.${PermissionTarget[permission]}`)}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    control={control}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={10} container justifyContent={isXs ? "center" : "flex-end"} spacing={1}>
              <Grid item>
                <Button type="submit" variant="contained" color="secondary">
                  {t("General.Buttons.Save")}
                </Button>
              </Grid>
              <Grid item>
                <Button type="button" onClick={props.onClose} variant="outlined" color="secondary">
                  {t("General.Buttons.Undo")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditDocumentFolderDialog;
