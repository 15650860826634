import React, { useReducer, useEffect, useRef, useMemo, useState } from "react";
import { Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add, CheckCircle } from "@mui/icons-material";
import { useDebounce, useInfiniteScroll } from "../../../../helpers/hooks";
import { LandingMetaModel } from "../../../../definitions/Landing";
import {
  AdminTable,
  Column,
  Icon,
} from "../../components/AdminTable/AdminTable";
import { useAdminLandings } from "../../../../actions";
import CreateTermsDialog from "./CreateTermsDialog";

const initialState = {
  searchTerm: "",
  takeAmount: 10,
  openCreateDialog: false,
  page: 0,
};

const reducer = (
  state: typeof initialState,
  action: { type: string; payload: any }
) => {
  return { ...state, [action.type]: action.payload };
};

const TermsPage = () => {
  const [
    { landingsCount, landings, loading },
    {
      deleteTerms,
      cloneTerms,
      getTermsList,
      clearLandings,
      updateTerms,
      createTerms,
    },
  ] = useAdminLandings();

  const [state, dispatch] = useReducer(reducer, initialState);
  const debouncedSearchTerm = useDebounce(state.searchTerm, 500);
  const listRef = useRef<HTMLUListElement | null>(null);
  const navigate = useNavigate();
  const [landingsTableData, setLandingsTableData] = useState<
    LandingMetaModel[]
  >([]);

  const handleDeleteLanding = (landingId: string) => {
    deleteTerms(landingId);
  };

  const handleCloneLanding = (landingId: string) => {
    cloneTerms(landingId);
  };

  useInfiniteScroll<HTMLUListElement>(listRef, () => {
    if (landingsCount > landings.length && !loading) {
      getTermsList(landings.length, state.takeAmount);
    }
  });

  const changePagination = ({ page, rows }: { page: number; rows: number }) => {
    dispatch({ type: "page", payload: page });
    dispatch({ type: "takeAmount", payload: rows });
  };

  useEffect(() => {
    clearLandings();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const fetchItem = state.page * state.takeAmount + state.takeAmount;
    const currentCount =
      fetchItem > landingsCount && landingsCount !== 0
        ? landingsCount
        : fetchItem;
    if (landings.length < currentCount) {
      getTermsList(landings.length, currentCount - landings.length);
    }
  }, [state.page, state.takeAmount, landings.length, state.searchTerm]);

  useEffect(() => {
    setLandingsTableData(
      landings?.slice(
        state.page * state.takeAmount,
        state.page * state.takeAmount + state.takeAmount
      )
    );
  }, [state.page, state.takeAmount, landings]);

  const toggleCreateDialog = () => {
    dispatch({
      type: "openCreateDialog",
      payload: !state.openCreateDialog,
    });
  };

  const handleUpdate = (landing: LandingMetaModel) => {
    const newLanding: LandingMetaModel = {
      ...landing,
      IsPublished: !landing.IsPublished,
    };
    updateTerms(newLanding);
  };

  const columns: Column<LandingMetaModel>[] = useMemo(
    () => [
      {
        name: "Active",
        accessor: "IsPublished",
        format: (data) => (data ? <CheckCircle color="secondary" /> : ""),
      },
      {
        name: "Title",
        accessor: "Title",
        // format: (data) => `${data.Title}\n (${data.UniqueName})`
      },
      {
        name: "Copy",
        icon: Icon.Copy,
        callback: (data) => handleCloneLanding(data.Id),
      },
      {
        name: "Open",
        icon: Icon.Link,
        callback: (data) => {
          window.open(`/landing/${data.UniqueName}`, "_blank");
          // navigate(`/landing/${data.UniqueName}`)},
        },
      },
      {
        name: "Edit/Preview",
        icon: Icon.Edit,
        format: (data) => data.IsPublished ? Icon.Pageview : Icon.Edit,
        callback: (data) => navigate("terms-editor/" + data.Id),
      },
      {
        name: "Delete",
        icon: Icon.Delete,
        format: (data) => data.IsPublished ? Icon.Invisible : Icon.Delete,
        callback: (data) => handleDeleteLanding(data.Id),
      },     
    ],
    []
  );

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item md={8} sm={10} xs={12}>
        <AdminTable
          count={landingsCount}
          data={landingsTableData}
          columns={columns}
          loading={loading}
          pagination="server"
          onPaginationChange={changePagination}
          headerButton={
            <Grid container justifyContent="flex-center" item 
            xs={5}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={toggleCreateDialog}
              >
                <Add />
              </Button>
            </Grid>
          }
        />
      </Grid>

      <CreateTermsDialog
        open={state.openCreateDialog}
        handleClose={toggleCreateDialog}
        createTerms={createTerms}
      />
    </Grid>
  );
};

export default TermsPage;
