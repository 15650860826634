import React from "react";
import {
  Grid,
  CardHeader,
  CardContent,
  Card,
  Typography,
  styled,
  CardActions,
  IconButton,
  Collapse,
  Link,
  Divider,
  AvatarGroup,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  LinearProgress,
  Button,
  FormControlLabel,
  Switch,
  FormControl,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Checkroom,
  Forum,
  NavigateNext,
  NoteAdd,
  PersonAdd,
  PersonRemove,
  Edit,
  PersonAddAlt,
  GroupAdd,
  Archive,
} from "@mui/icons-material";
import AddressHelper from "../../../helpers/address-helper";
import Folders from "../../House/Documents/Folders";
import { DocumentSourceType } from "../../../definitions/Document";
import { Building, BuildingUnitDto } from "../../../definitions/model/Building";
import { useTranslatedGroupRoles } from "../../../helpers/hooks";
import UserAvatar from "../../../components/Layout/UserAvatar";
import { GroupMemberDto, PendingGroupMemberDto } from "../../../definitions/model/Group";
import { useFamily } from "../../../actions/family.actions";
import { MCard, useAlertContext } from "../../../components/Base";
import ConfirmDialog from "../../../components/Dialogs/ConfirmDialog";
import EditBuildingTenantDialog from "./EditBuildingTenantDialog";
import { useBuilding, useUser } from "../../../actions";
import ImportMembers from "./ImportMembers";
import { GroupMemberRole } from "../../../constants/enums";

type BuildingUnitsProps = {
  building: Building;
  members?: GroupMemberDto[];
};

const DataGridStyledRow = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: 2,
}));

const PendingUserAvatar = styled(UserAvatar)(() => ({
  opacity: 0.5,
}));

const BuildingUnits = ({ building, members }: BuildingUnitsProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const alert = useAlertContext();
  const [, familyActions] = useFamily();
  const [, buildingActions] = useBuilding();
  const [{ user }, userActions] = useUser();
  const { getDisplayGroupRole } = useTranslatedGroupRoles();
  const [expandedDocumentsUnitId, setExpandedDocumentsUnitId] = React.useState<string>();
  const [expandedMembersUnitId, setExpandedMembersUnitId] = React.useState<string>();
  const [changedUserId, setChangedUserId] = React.useState<string>("");
  const [tenantToEdit, setTenantToEdit] = React.useState<GroupMemberDto | null>();
  const [manageTenants, setManageTenants] = React.useState(false);
  const [editUnit, setEditUnit] = React.useState<BuildingUnitDto | null>(null);
  const [openImportMembers, setOpenImportMembers] = React.useState(false);
  const userRole = members?.find((x) => x.UserId === user?.Id)?.Role ?? GroupMemberRole.NotValidMember;
  const isAdministration =
    userRole === GroupMemberRole.Owner ||
    userRole === GroupMemberRole.Administrator ||
    userRole === GroupMemberRole.Manager ||
    userRole === GroupMemberRole.Caretacker;

  const handleExpandDocumentsClick = (unit: BuildingUnitDto) => {
    if (expandedDocumentsUnitId === unit.Id) {
      setExpandedDocumentsUnitId(undefined);
      return;
    }
    setExpandedDocumentsUnitId(unit.Id);
  };

  const handleExpandMembersClick = (unit: BuildingUnitDto) => {
    if (expandedMembersUnitId === unit.Id) {
      setExpandedMembersUnitId(undefined);
      return;
    }
    setExpandedMembersUnitId(unit.Id);
  };

  const navigateUnitClick = (unit: BuildingUnitDto) => {
    const unitUrl = AddressHelper.GetUnitAddressUrl(unit) + "/profile";
    navigate(unitUrl, { state: { fromBuilding: true } });
  };

  const tryUser = async (userId: string, unit: BuildingUnitDto) => {
    setChangedUserId(userId);
    await userActions.tryUser(userId);
    navigateUnitClick(unit);
  };

  const navigateUserLogClick = (userId: string, unit: BuildingUnitDto) => {
    const buildingUrl = `/buildings/${AddressHelper.GetBuildingAddressUrl(building)}/users-log`;
    navigate(buildingUrl, { state: { userId: userId, unitId: unit.Id, groupId: building.BuildingGroupId } });
  };

  const onEditClickHandler = (member: GroupMemberDto, unit: BuildingUnitDto) => {
    setTenantToEdit(member);
    setEditUnit(unit);
  };

  const onRemoveClickHandler = (unitId: string, member: GroupMemberDto) => {
    setChangedUserId(member.UserId);
    familyActions
      .removeFamilyMember(member.UserId, unitId, building.BuildingGroupId)
      .catch((e) => alert.showAlert({ text: e, severity: "error" }))
      .finally(() => setChangedUserId(""));
  };

  const onRemoveAndArchiveClickHandler = async (unitId: string, member: GroupMemberDto) => {
    try {
      setChangedUserId(member.UserId);
      await buildingActions.archiveTenant(building.Id, unitId, member.UserId);
      await familyActions.removeFamilyMember(member.UserId, unitId, building.BuildingGroupId);
    } catch (e) {
      alert.showAlert({ text: `${e}`, severity: "error" });
    } finally {
      setChangedUserId("");
    }
  };

  const onRemovePendingClickHandler = (unitId: string, member: PendingGroupMemberDto) => {
    setChangedUserId(member.UserId);
    buildingActions
      .removePendingTenant(building.Id, unitId, member.UserId, member.Token)
      .catch((e) => alert.showAlert({ text: e, severity: "error" }))
      .finally(() => setChangedUserId(""));
  };
  return (
    <>
      {building.Units.length > 0 && (
        <MCard
          type="main"
          title={t("Building.BuildingResidences")}
          headerAction={
            <>
              {isAdministration && (
                <>
                  <FormControl>
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch
                          color="secondary"
                          value={manageTenants}
                          onChange={(e) => setManageTenants(e.target.checked)}
                        />
                      }
                      label={t("Building.Manage")}
                      labelPlacement="start"
                      sx={{ marginRight: 0, "& .MuiTypography-root": { color: "white" } }}
                    />
                  </FormControl>
                  <Button
                    color="secondary"
                    size="small"
                    variant="outlined"
                    title={t("Building.AddTenant")}
                    sx={{ borderRadius: "50%", minWidth: "unset", padding: 1, margin: 0, marginRight: 0.5 }}
                    onClick={() => {
                      setTenantToEdit(null);
                      setEditUnit(null);
                    }}
                  >
                    <PersonAddAlt />
                  </Button>
                  <Button
                    color="secondary"
                    size="small"
                    variant="outlined"
                    title={t("Building.ImportTenants")}
                    sx={{ borderRadius: "50%", minWidth: "unset", padding: 1, margin: 0, marginRight: 0.5 }}
                    onClick={() => {
                      setOpenImportMembers(true);
                    }}
                  >
                    <GroupAdd />
                  </Button>
                  <Button
                    color="secondary"
                    size="small"
                    variant="outlined"
                    title={t("Building.ImportTenants")}
                    sx={{ borderRadius: "50%", minWidth: "unset", padding: 1, margin: 0, marginRight: 0.5 }}
                    onClick={() => navigate(`/buildings/${AddressHelper.GetBuildingAddressUrl(building)}/archive`)}
                  >
                    <Archive />
                  </Button>
                </>
              )}
            </>
          }
        >
          <CardContent>
            <Grid container spacing={4}>
              {building.Units.map((unit: BuildingUnitDto) => (
                <Grid item sm={6} xs={12} key={unit.Id}>
                  <Card style={{ border: `1px solid #eee`, height: "100%" }}>
                    <SCardHeader
                      subheader={unit.PropertyType}
                      title={`${unit.Address.Street} ${unit.Address.HouseNo} ${unit.Address.Floor}-${unit.Address.DoorNumber}`}
                    />
                    <SCardContent>
                      <Grid container direction="column">
                        {unit.UnitData && (
                          <DataGridStyledRow item>
                            <Typography style={{ fontWeight: "bold" }}>{t("Base.Popovers.AreaM2")}</Typography>
                            <Typography>{unit.UnitData.Area}</Typography>
                          </DataGridStyledRow>
                        )}
                        {unit.UnitData && (
                          <DataGridStyledRow item>
                            <Typography style={{ fontWeight: "bold" }}>{t("Base.Popovers.Room")}</Typography>
                            <Typography>{unit.UnitData.Rooms}</Typography>
                          </DataGridStyledRow>
                        )}
                      </Grid>
                    </SCardContent>
                    <Divider />
                    <CardActions disableSpacing>
                      <AvatarGroup max={3} spacing="small" onClick={() => handleExpandMembersClick(unit)}>
                        {unit.Members.map((member) => (
                          <UserAvatar
                            key={member.UserId}
                            variant="circular"
                            user={{
                              Name: member.Name,
                              AvatarUrl: member.AvatarUrl,
                            }}
                          />
                        ))}
                        {unit.PendingMembers.map((member) => (
                          <PendingUserAvatar
                            key={member.UserId}
                            variant="circular"
                            user={{
                              Name: member.Name,
                              AvatarUrl: member.AvatarUrl,
                            }}
                          />
                        ))}
                      </AvatarGroup>
                      {unit.Id !== building.OfficeUnitId && (
                        <Button
                          color="secondary"
                          size="small"
                          variant="contained"
                          sx={{ borderRadius: "50%", minWidth: "unset", padding: 1, margin: 0 }}
                          onClick={() => {
                            setTenantToEdit(null);
                            setEditUnit(unit);
                          }}
                        >
                          <PersonAdd />
                        </Button>
                      )}

                      <IconButton size="large" color="default" onClick={() => handleExpandDocumentsClick(unit)}>
                        <NoteAdd />
                      </IconButton>
                      <IconButton
                        size="large"
                        color="default"
                        sx={{ marginLeft: "auto" }}
                        LinkComponent={Link}
                        onClick={() => navigateUnitClick(unit)}
                      >
                        <NavigateNext />
                      </IconButton>
                    </CardActions>
                    <Collapse in={expandedMembersUnitId === unit.Id} timeout="auto" unmountOnExit>
                      <Divider />
                      <CardContent key={`unit_${unit.Id}_members`}>
                        <List style={{ width: "100%" }}>
                          {unit.Members.map((member) => (
                            <div key={`member_${member.UserId}`}>
                              <ListItem alignItems="flex-start" disablePadding>
                                <ListItemAvatar>
                                  <UserAvatar
                                    variant="circular"
                                    user={{
                                      Name: member.Name,
                                      AvatarUrl: member.AvatarUrl,
                                    }}
                                  />
                                </ListItemAvatar>
                                <ListItemText primary={member.Name} secondary={getDisplayGroupRole(member.Role)} />
                                <ListItemSecondaryAction>
                                  {!manageTenants && (
                                    <Tooltip title={t("Building.SeeUserLog")}>
                                      <IconButton
                                        size="large"
                                        onClick={() => navigateUserLogClick(member.UserId, unit)}
                                      >
                                        <Forum />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  {manageTenants && (
                                    <>
                                      <Tooltip title={t("Building.TryUser")}>
                                        <IconButton size="large" onClick={() => onEditClickHandler(member, unit)}>
                                          <Edit />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title={t("Building.TryUser")}>
                                        <IconButton size="large" onClick={() => tryUser(member.UserId, unit)}>
                                          <Checkroom />
                                        </IconButton>
                                      </Tooltip>
                                      <ConfirmDialog
                                        text={t("Building.DeleteTenantText")}
                                        okButtonText={t("General.Buttons.Delete")}
                                        OkClickHandler={() => onRemoveClickHandler(unit.Id, member)}
                                        extraOkButtonText={t("General.Buttons.DeleteAndArchive")}
                                        extraOkClickHandler={() => onRemoveAndArchiveClickHandler(unit.Id, member)}
                                      >
                                        <Tooltip title={t("Building.DeleteTenant")}>
                                          <IconButton size="large">
                                            <PersonRemove />
                                          </IconButton>
                                        </Tooltip>
                                      </ConfirmDialog>
                                    </>
                                  )}
                                </ListItemSecondaryAction>
                              </ListItem>
                              {changedUserId === member.UserId && <LinearProgress color="secondary" />}
                              <Divider variant="fullWidth" component="li" />
                            </div>
                          ))}
                          {unit.PendingMembers.map((member) => (
                            <div key={`member_${member.UserId}`}>
                              <ListItem alignItems="flex-start" disablePadding>
                                <ListItemAvatar>
                                  <PendingUserAvatar
                                    variant="circular"
                                    user={{
                                      Name: member.Name,
                                      AvatarUrl: member.AvatarUrl,
                                    }}
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={`${member.Name}(${member.Email}), ${t("Family.Pending")}: ${new Date(
                                    member.JoinTime
                                  ).toLocaleDateString()}`}
                                  secondary={getDisplayGroupRole(member.Role)}
                                />
                                <ListItemSecondaryAction>
                                  <ConfirmDialog
                                    text={t("Building.DeleteTenantText")}
                                    OkClickHandler={() => onRemovePendingClickHandler(unit.Id, member)}
                                  >
                                    <Tooltip title={t("Building.DeleteTenant")}>
                                      <IconButton size="large">
                                        <PersonRemove />
                                      </IconButton>
                                    </Tooltip>
                                  </ConfirmDialog>
                                </ListItemSecondaryAction>
                              </ListItem>
                              {changedUserId === member.UserId && <LinearProgress color="secondary" />}
                              <Divider variant="fullWidth" component="li" />
                            </div>
                          ))}
                        </List>
                      </CardContent>
                    </Collapse>
                    <Collapse in={expandedDocumentsUnitId === unit.Id} timeout="auto" unmountOnExit>
                      <Divider />
                      <CardContent>
                        <Folders
                          sourceItemId={building.Id}
                          childSourceItemId={unit.Id}
                          sourceType={DocumentSourceType.Building}
                          usePermissions
                          hideHeader
                        />
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </MCard>
      )}
      {tenantToEdit !== undefined && (
        <EditBuildingTenantDialog
          buildingId={building.Id}
          member={tenantToEdit}
          unitId={editUnit?.Id}
          units={building.Units.filter((unit) => unit.Id !== building.OfficeUnitId)}
          open={true}
          handleClose={() => {
            setTenantToEdit(undefined);
            setEditUnit(null);
          }}
        />
      )}
      <ImportMembers
        isOpen={openImportMembers}
        onClose={() => setOpenImportMembers(false)}
        units={building.Units}
        buildingId={building.Id}
        alredyTenants={building.Units.flatMap((u) => [...u.Members, ...u.PendingMembers])}
      />
    </>
  );
};

export default BuildingUnits;

const SCardHeader = styled(CardHeader)(() => ({
  "&.MuiCardHeader-root": {
    backgroundColor: "#fff",
    paddingBottom: 0,
  },
  "& .MuiCardHeader-title": {
    fontSize: 12,
  },
  "& .MuiCardHeader-content": {
    display: "flex",
    flexDirection: "column-reverse",
  },
}));

const SCardContent = styled(CardContent)(() => ({
  "&.MuiCardContent-root": {
    paddingTop: 0,
    "&:last-child": {
      paddingBottom: 16,
    },
  },
}));
