import { Dialog, DialogTitle, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CompanyModel, CompanyGeneralInfo, CompanySustainabilityInfo } from "../../../../../definitions/Company";
import CompanyDataForm from "./CompanyDataForm";

type CompanyEditFormProps = {
  company: CompanyModel | null;
  open: boolean;
  loading: boolean;
  handleClose: () => void;
  editCompany: (company: CompanyModel) => Promise<void>;
};

const CompanyEditDialog = (props: CompanyEditFormProps): JSX.Element => {
  const [generalInfo, setGeneralInfo] = useState<CompanyGeneralInfo | null>(null);

  useEffect(() => {
    if (props.company) {
      setGeneralInfo({
        Name: props.company.Name,
        LogoUrl: props.company.LogoUrl,
        WebSite: props.company.WebSite,
        OfficialName: props.company.OfficialName,
        Phone: props.company.Phone,
        Addresses: props.company.Addresses,
        CvrNumber: props.company.CvrNumber,
        Country: props.company.Country,
        EmployeesNumber: props.company.EmployeesNumber,
        IsOurClient: props.company.IsOurClient,
        IsActiveClient: props.company.IsActiveClient,
      });
    }
  }, [props.company]);

  const submit = (generalInfo: CompanyGeneralInfo, sustainabilityInfo: CompanySustainabilityInfo) => {
    if (props.company) {
      props
        .editCompany({
          ...props.company,
          Name: generalInfo.Name,
          WebSite: generalInfo.WebSite,
          OfficialName: generalInfo.OfficialName,
          Phone: generalInfo.Phone,
          Addresses: generalInfo.Addresses,
          CvrNumber: generalInfo.CvrNumber,
          Country: generalInfo.Country,
          EmployeesNumber: generalInfo.EmployeesNumber,
          IsOurClient: generalInfo.IsOurClient,
          IsActiveClient: generalInfo.IsActiveClient,
          SustainabilityInfo: sustainabilityInfo,
        })
        .then(() => props.handleClose());
    }
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit company</DialogTitle>

      <CompanyDataForm
        submit={submit}
        id={props.company?.Id ?? undefined}
        existingGeneralInfo={generalInfo ?? undefined}
        existingSustainabilityInfo={props.company?.SustainabilityInfo ?? undefined}
        handleClose={props.handleClose}
      />
      {props.loading && <LinearProgress />}
    </Dialog>
  );
};

export default CompanyEditDialog;
