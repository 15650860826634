import React, { useState, useEffect, useReducer, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Grid,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TablePagination,
  TableRow,
  TableCell,
  IconButton,
  CircularProgress,
  Button,
  styled,
} from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import { format } from "date-fns";
import { Link } from "react-router-dom";

import { getProducts, clearProducts, createProduct, deleteProduct } from "../../../../actions/subscriptions.actions";
import { Direction, CellLabel, TablePaginationActions, useAlertContext } from "../../../../components/Base";
import { ApplicationState } from "../../../../reducers/store";
import { AppThunkDispatch } from "../../../../definitions/Action";
import { CreateProductDialog } from "./components/";
import ConfirmDialog from "../../../../components/Dialogs/ConfirmDialog";
import { CreateProductRequest } from "../../../../definitions/model/Subscriptions";

const SpinnerContainer = styled(Grid)<{ component: React.ReactNode }>(({ theme }) => ({
  position: "absolute",
  background: "rgba(0,0,0,0.3)",
  height: "calc(100% - 53px)",
  top: 0,
}));

const Spinner = styled(CircularProgress)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "calc(50% - 20px)",
}));

const ProductTableLables = [
  { key: "Created", value: "Created" },
  { key: "Name", value: "Name" },
];

type ProductPageState = {
  page: number;
  productsPerPage: number;
  productsFilter: string;
  orderDirection: Direction;
  searchTerm: string;
};

const initialState: ProductPageState = {
  page: 0,
  productsPerPage: 10,
  productsFilter: "Created",
  orderDirection: "asc",
  searchTerm: "",
};

const reducer = (state: ProductPageState, action: { type: string; payload: any }) => {
  if (action.type === "productsPerPage") {
    return { ...state, productsPerPage: action.payload, page: 0 };
  } else {
    return { ...state, [action.type]: action.payload };
  }
};

const ProductsPage = (props: ProductsPageConnectedProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [open, setOpen] = useState(false);
  const { showAlert } = useAlertContext();

  const closeCreateDialog = () => setOpen(false);
  const openCreateDialog = () => setOpen(true);

  const fetchProducts = useCallback(() => {
    props.getProducts(
      state.searchTerm,
      state.page * state.productsPerPage,
      state.productsPerPage,
      state.orderDirection === "desc",
      state.productsFilter
    );
  }, [state, props.getProducts]);

  const handleDelete = (productId: string) => {
    props
      .deleteProduct(productId)
      .then(() => fetchProducts())
      .catch((e) => showAlert({ severity: "error", text: e.statusText }));
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch({ type: "page", payload: newPage });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch({
      type: "productsPerPage",
      payload: parseInt(event.target.value, 10),
    });
  };

  const handleChangeOrder = (name: string) => () => {
    if (name !== state.productsFilter) {
      dispatch({ type: "orderDirection", payload: "asc" });
      dispatch({ type: "productsFilter", payload: name });
    } else {
      dispatch({
        type: "orderDirection",
        payload: state.orderDirection === "asc" ? "desc" : "asc",
      });
    }
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: "searchTerm", payload: event.target.value });
  };

  useEffect(() => {
    fetchProducts();

    return () => {
      props.clearProducts();
    };
  }, []);

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item container>
          <TextField fullWidth placeholder="Search by title" onChange={handleChangeInput} />
          {props.products.length > 0 || (props.count === 0 && !props.loading) ? (
            <TableContainer
              sx={{
                overflowY: "auto",
                width: "100%",
                height: "80vh",
                padding: 0,
              }}
            >
              <Table size="small" stickyHeader style={{ position: "relative" }}>
                <TableHead>
                  <TableRow>
                    {ProductTableLables.map((label) => (
                      <CellLabel
                        key={label.key}
                        cellName={label.value}
                        cellKey={label.key}
                        currentCellName={state.productsFilter}
                        direction={state.orderDirection}
                        onClick={handleChangeOrder}
                      />
                    ))}
                    <TableCell align="right">
                      <Button color="primary" variant="contained" onClick={openCreateDialog}>
                        <Add />
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.products.length
                    ? props.products.map((product) => (
                        <TableRow hover key={product.Id}>
                          <TableCell>{format(new Date(product.Created), "dd/MM/yyyy")}</TableCell>
                          <TableCell>{product.Name}</TableCell>
                          <TableCell align="right">
                            <IconButton component={Link} to={`products/${product.Id}`}>
                              <Edit />
                            </IconButton>
                            <ConfirmDialog
                              text="Are you sure want to delete this product?"
                              OkClickHandler={() => handleDelete(product.Id)}
                            >
                              <IconButton>
                                <Delete />
                              </IconButton>
                            </ConfirmDialog>
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                  {props.loading && (
                    <SpinnerContainer component={<TableRow />} container justifyContent="center" alignItems="center">
                      <TableCell>
                        <Spinner />
                      </TableCell>
                    </SpinnerContainer>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 15, 20, 25, { label: "All", value: props.count }]}
                      count={props.count}
                      rowsPerPage={state.productsPerPage}
                      page={state.page}
                      SelectProps={{
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          ) : (
            <Grid
              container
              sx={{
                overflowY: "auto",
                width: "100%",
                height: "80vh",
                padding: 0,
              }}
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Grid>
          )}
        </Grid>
      </Grid>
      <CreateProductDialog open={open} handleClose={closeCreateDialog} createProduct={props.createProduct} />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  products: state.subscriptions.products,
  count: state.subscriptions.count,
  loading: state.subscriptions.loading,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getProducts: (name: string, skip: number, take: number, isDescending: boolean, sortingColumn: string) =>
    dispatch(getProducts(name, skip, take, isDescending, sortingColumn)),
  createProduct: (data: CreateProductRequest) => dispatch(createProduct(data)),
  deleteProduct: (productId: string) => dispatch(deleteProduct(productId)),
  clearProducts: () => dispatch(clearProducts()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type ProductsPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(ProductsPage);
