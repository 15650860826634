import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import BuildingInfo from "./components/BuildingInfo";
import { useTranslation } from "react-i18next";
import UserLogMessages from "../User/components/UserLogMessages";
import { ArrowBack } from "@mui/icons-material";
import { useUser } from "../../actions/user.actions";
import { UserLogMessage } from "../../definitions/model/UserMail";

const BuildingUserLogMessages = (): JSX.Element => {
  const { addressURL } = useParams<{ addressURL: string }>();
  const location = useLocation();
  const [logSources, setLogSources] = useState<string[]>([]);
  const [logs, setLogs] = useState<UserLogMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const [, userActions] = useUser();

  useEffect(() => {
    if (addressURL) {
      const addressParts = addressURL.split("_");
      const id = addressParts[addressParts.length - 1];
      if (id) {
        const sources = [id, location.state.unitId];
        if (location.state.groupId) {
          sources.push(location.state.groupId);
        }

        setLogSources(sources);
      }
    }
  }, [addressURL]);

  useEffect(() => {
    const abortController = new AbortController();
    const getUserLogMessagesAsync = async () => {
      try {
        setLoading(true);
        const userLogMessages = await userActions.getUserLogMessages(
          location.state.userId,
          logSources,
          abortController.signal
        );
        setLogs(userLogMessages ?? []);
      } finally {
        setLoading(false);
      }
    };
    if (location.state.userId && logSources.length > 0) getUserLogMessagesAsync();
    return () => {
      abortController.abort();
    };
  }, [location.state.userId, logSources]);

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => {
          if (window.history.state.idx !== 0) {
            navigate(-1);
            return;
          }

          navigate("/buildings");
        }}
        type="submit"
        sx={{ marginBottom: "10px" }}
      >
        <ArrowBack fontSize="small" />
        {t("General.Buttons.Back")}
      </Button>
      <BuildingInfo />
      <UserLogMessages logs={logs} loading={loading} />
    </>
  );
};

export default BuildingUserLogMessages;
