import Unit from "../definitions/model/unit/Unit";
import AddressHelper from "./address-helper";
import { Building } from "../definitions/model/Building";
import { NavigateFunction } from "react-router-dom";

const NavigationHelper = {
  GoByRoute: (
    navigate: NavigateFunction,
    unit: Unit | null,
    building: Building | null,
    destinationUrl: string,
    options: any = {}
  ): void => {
    //Production could have host myhouse.dk or www.myhouse.dk
    destinationUrl = destinationUrl.replace("www.", "");
    const currentHost = window.location.host.replace("www.", "");

    if (destinationUrl.indexOf("unit-route") !== -1 && unit) {
      const target = destinationUrl.replace("unit-route", AddressHelper.GetUnitAddressUrl(unit));
      navigate(target, { state: options });
      return;
    }

    if (destinationUrl.indexOf("building-route") !== -1) {
      if (!building) {
        navigate("/buildings/search", { state: options });
        return;
      }

      const target = destinationUrl.replace("building-route", AddressHelper.GetBuildingAddressUrl(building));
      navigate(target, { state: options });
      return;
    }

    if (destinationUrl.indexOf(currentHost) !== -1) {
      const _url = new URL(destinationUrl);
      navigate(_url.pathname, { state: options });
      return;
    }

    try {
      //try if external url
      const _url = new URL(destinationUrl);
      navigate(_url.pathname, { state: options });
    } catch {
      navigate(destinationUrl, { state: options });
    }
  },
  IsClimateSite: (): boolean => {
    return (
      window.location.pathname.indexOf("klimaplan") === -1 && window.location.host.indexOf("voresklimaplan.dk") !== -1
    );
  },
};

export default NavigationHelper;
