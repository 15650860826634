import React, { useEffect, useReducer } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {Select, MenuItem, TextField, SelectChangeEvent, TextFieldProps} from "@mui/material";
import { UserType } from "../../../../constants/enums";
import { Autocomplete } from '@mui/material';

import { AutocompleteChangeDetails } from '@mui/material';

import { AutocompleteChangeReason, AutocompleteInputChangeReason } from '@mui/material/useAutocomplete';

import User from "../../../../definitions/model/User";
import { ApplicationState } from "../../../../reducers/store";
import { connect, ConnectedProps } from "react-redux";
import { getUsersByEmailPart } from "../../../../actions/admin/admin.actions";
import { AppThunkDispatch } from "../../../../definitions/Action";
import { getCommunes } from "../../../../actions/admin/admin.commune.actions";
import { CommuneLocation } from "../../../../definitions/model/unit";

type EditAdminDialogProps = {
  open: boolean;
  email: string;
  id: string;
  role: UserType;
  communeNumber: number;
  addAdminMode: boolean;
  handleClose: () => void;
  handleSave: (id: string, role: UserType, communeNumber: number) => void;
};

type EditAdminDialogState = {
  role: UserType;
  commune: CommuneLocation | null;
  id: string;
  selectedUser: User | null;
};

const initialState: EditAdminDialogState = {
  role: UserType.ExternalManager,
  commune: null,
  id: "",
  selectedUser: null,
};

const reducer = (
  state: EditAdminDialogState,
  action: { type: string; payload: any }
) => {
  return { ...state, [action.type]: action.payload };
};

const EditAdminDialog = (
  props: EditAdminDialogProps & EditAdminDialogConnectedProps
) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!props.communes || props.communes.length === 0) props.getCommunes();
  }, [props.communes]);

  //TODO: It is better not to use useEffect for such things.
  useEffect(() => {
    //TODO: It could easily be changed to one despatch call instead of three by changing a reducer a bit
    const commune = props.communes.filter(
      (c) => c.Commune.CommuneNumber === props.communeNumber
    );
    dispatch({ type: "commune", payload: commune[0] ?? null });
    dispatch({ type: "role", payload: props.role });
    dispatch({ type: "id", payload: props.id });
    dispatch({ type: "selectedUser", payload: null });
  }, [props.open]);

  const handleRoleChange = (event: SelectChangeEvent<UserType>) => {
    dispatch({ type: "role", payload: event.target.value });
  };

  const handleCommuneChange = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    value: CommuneLocation | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reason: AutocompleteChangeReason,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    details?: AutocompleteChangeDetails<CommuneLocation> | undefined
  ) => {
    dispatch({
      type: "commune",
      payload: value,
    });
  };

  const handleUserChange = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    value: User | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reason: AutocompleteChangeReason,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    details?: AutocompleteChangeDetails<User> | undefined
  ) => {
    dispatch({ type: "id", payload: value?.Id ?? "" });
    dispatch({ type: "selectedUser", payload: value });
  };

  const handleUserInputChange = async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reason: AutocompleteInputChangeReason
  ) => {
    if (value.length < 3) {
      return;
    }
    props.getUsersByEmailPart(value);
  };

  return (
    <Dialog open={props.open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {props.addAdminMode ? "Add admin" : "Edit admin"}
      </DialogTitle>
      <DialogContent style={{ overflowY: 'visible'  }}>
        {props.addAdminMode && (
          <Autocomplete
            id="user"
            style={{ minWidth: 250, marginBottom: 10 }}
            fullWidth
            onChange={handleUserChange}
            onInputChange={handleUserInputChange}
            value={state.selectedUser}
            options={props.selectOptions}
            getOptionLabel={(option) => option.Email}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                label="Email"
                {...params as TextFieldProps}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        )}
        {!props.addAdminMode && (
          <TextField
            variant="outlined"
            fullWidth
            style={{ minWidth: 250, marginBottom: 10 }}
            value={props.email}
            label="Email"
          ></TextField>
        )}
        <Select
          value={state.role}
          onChange={handleRoleChange}
          fullWidth
          style={{ marginBottom: 10 }}
          variant="outlined"
          label="Role"
        >
          <MenuItem value={UserType.ExternalManager}>External Manager</MenuItem>
          <MenuItem value={UserType.Manager}>Manager</MenuItem>
          <MenuItem value={UserType.Administrator}>Administrator</MenuItem>
        </Select>
        {state.role === UserType.ExternalManager && (
          <Autocomplete
            fullWidth
            style={{ marginBottom: 10 }}
            onChange={handleCommuneChange}
            value={state.commune}
            options={props.communes}
            getOptionLabel={(o) => o.Commune.CommuneShortName}
            renderInput={(params) => (
              <TextField
                {...params as TextFieldProps}
                label="Commune"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          ></Autocomplete>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()} color="secondary">
          Cancel
        </Button>
        <Button
          disabled={!state.id}
          onClick={() =>
            props.handleSave(
              state.id,
              state.role,
              state.commune?.Commune.CommuneNumber ?? 0
            )
          }
          color="secondary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  selectOptions: state.admin.addAdminAutocompleteOptions,
  communes: state.admin.communes,
  error: state.admin.error,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getUsersByEmailPart: (filter: string) =>
    dispatch(getUsersByEmailPart(filter)),
  getCommunes: () => dispatch(getCommunes()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type EditAdminDialogConnectedProps = ConnectedProps<typeof connector>;

export default connector(EditAdminDialog);
