import React, { SyntheticEvent, useEffect, useState } from "react";
import {
  Grid,
  Link,
  Popover,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
  SelectChangeEvent,
} from "@mui/material";
import { Skeleton } from "@mui/lab";
import format from "date-fns/format";
import { convertToCurency } from "../../services/converter.service";
import { House } from "../../definitions/Map";
import { DashboardSubscriptionFrequency } from "../../constants/enums";
import NoPhoto from "../../assets/img/no_house.jpg";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";

const PopoverContainer = styled(Grid)(({ theme }) => ({
  maxWidth: "450px",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "400px",
  },
  "& > div": {
    minHeight: "104px",
    overflow: "hidden",
  },
}));

const ImageWrapper = styled(Grid)(() => ({
  "& *": {
    display: "block",
    width: 136,
    height: "100%",
    transform: "none",
    objectFit: "cover",
  },
}));

const InfoWrapper = styled(Grid)(() => ({
  padding: "10px",
  overflow: "hidden",
}));

const ImageContainer = styled(Grid)(() => ({
  position: "relative",
  marginRight: "5px",
}));

const MarkerPopover = styled(Grid)(({ theme }) => ({
  width: "350px",
  background: theme.palette.background.grayPaper,
  "& table": {
    "& h4": {
      padding: 0,
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.light,
    },
    "& th": {
      paddingLeft: 35,
    },
    "& td": {
      width: "50%",
    },
  },
}));

const PopoverBody = styled(Grid)(({ theme }) => ({
  padding: "10px",
  "& h4, & h5": {
    padding: 0,
  },
  "& h4": {
    color: theme.palette.primary.main,
  },
  "& h5": {
    lineHeight: 1.5,
    color: theme.palette.primary.dark,
    marginBottom: "5px",
  },
}));

const DashboardFrequencyBlock = styled(Grid)(() => ({
  padding: "0 20px",
  display: "flex",
  "flex-direction": "column",
  "justify-content": "center",
}));

const PlanImage = styled("img")(() => ({
  width: "300px",
  height: "170px",
  margin: "-10px",
}));

const CircleGrid = styled(Grid)(() => ({
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  marginLeft: "10px",
}));

type HouseProps = {
  anchorEl: null | HTMLDivElement;
  popupHouses: House[];
  handleClose: () => void;
};

type LayoutProps = {
  position: any;
  data: any;
  handleClose: () => void;
};

type MarkerProps = {
  anchorEl: null | HTMLDivElement;
  data: any;
  isSchool?: boolean;
  handleClose: () => void;
};

type DashboardProps = {
  position: any;
  data: any;
  handleClose: (frequency: DashboardSubscriptionFrequency | null) => void;
};

const addDefaultSrc = (ev: SyntheticEvent<HTMLImageElement, Event>) => {
  ev.currentTarget.onerror = null;
  ev.currentTarget.src = NoPhoto;
};

export const HousePopover = ({ anchorEl, handleClose, popupHouses }: HouseProps): JSX.Element => {
  const open = !!anchorEl;
  const { t } = useTranslation("translation");

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
    >
      <PopoverContainer>
        {popupHouses.map((house: House, index: number) => (
          <Grid container direction="row" wrap="nowrap" key={index}>
            <HouseImageWithLoader house={house} />
            <InfoWrapper container>
              <Grid item xs={12}>
                <Tooltip
                  title={`${house.Address}`}
                  placement="top-start"
                  enterDelay={1000}
                  enterTouchDelay={0}
                  leaveTouchDelay={3000}
                >
                  <Typography variant="h4" noWrap>
                    {house.Address}{" "}
                  </Typography>
                </Tooltip>
                <Typography variant="subtitle1" noWrap>
                  {house.PostalCode} {house.City} {house.PropertyType}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {house.Price && <TableCell padding="none">{t("Base.Popovers.Value")}</TableCell>}
                      <TableCell padding="none">{t("Base.Popovers.Sqm")}</TableCell>
                      <TableCell padding="none">{t("Base.Popovers.Land")}</TableCell>
                      <TableCell padding="none">{t("Base.Popovers.Room")}</TableCell>
                      <TableCell padding="none">{t("Base.Popovers.BuiltYear")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {house.Price && <TableCell padding="none">{convertToCurency(+house.Price, ".")} kr</TableCell>}
                      <TableCell padding="none">{house.Area}</TableCell>
                      <TableCell padding="none">{house.GroundArea}</TableCell>
                      <TableCell padding="none">{house.Rooms}</TableCell>
                      <TableCell padding="none">{house.BuildYear}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </InfoWrapper>
          </Grid>
        ))}
      </PopoverContainer>
    </Popover>
  );
};

type ImageProps = {
  house: House;
};

const HouseImageWithLoader = (props: ImageProps) => {
  const { house } = props;
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    const image = new Image();
    setImageLoading(true);
    image.src = house.PictureUrl;

    if (image.complete) {
      setImageLoading(false);
    }

    image.onload = () => {
      setImageLoading(false);
    };
  }, []);

  return (
    <ImageWrapper item>
      {house.PictureUrl ? (
        imageLoading ? (
          <Skeleton height="100%" style={{ transform: "scale(1.05)" }} />
        ) : (
          <a href={`${house.AddressUrl}/profile`} target="_blank" rel="noopener noreferrer">
            <img src={house.PictureUrl} alt="" onError={addDefaultSrc} />
          </a>
        )
      ) : (
        <Skeleton />
      )}
    </ImageWrapper>
  );
};

export const RoadPopover = ({ position, handleClose, data }: LayoutProps): JSX.Element => {
  const open = !!position;
  const { t } = useTranslation("translation");
  let month;
  if (data?.FinishedInMonth) {
    month = format(new Date(data?.FinishedInMonth), "MMMM");
  }

  return (
    <Popover
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: position ? position.y : 0,
        left: position ? position.x : 0,
      }}
      onClose={handleClose}
    >
      <PopoverContainer>
        <Grid container direction="row" wrap="nowrap">
          <ImageContainer item>
            {data?.StaticMapUrl ? <img src={data?.StaticMapUrl} alt="" /> : <Skeleton />}
          </ImageContainer>
          <InfoWrapper container>
            <Grid item>
              <Typography variant="h4">{t("Base.Popovers.RoadProject")}</Typography>
              <Typography variant="h5">{data?.Name}</Typography>
              <Typography variant="h5">
                {data?.Name}
                {t("Base.Popovers.ToBeFinished")} {month} {data?.FinishedInYear}
              </Typography>
            </Grid>
          </InfoWrapper>
        </Grid>
      </PopoverContainer>
    </Popover>
  );
};

export const TowerPopover = ({ anchorEl, handleClose, data }: MarkerProps): JSX.Element => {
  const open = !!anchorEl;
  const { t } = useTranslation("translation");

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: 21 }}
    >
      <MarkerPopover container>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography variant="h4">{data?.name}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{t("Base.Popovers.Address")}</TableCell>
                <TableCell align="center">
                  {data?.postalCode}, {data?.street} {data?.houseNo}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Base.Popovers.AntennaType")}</TableCell>
                <TableCell align="center">{data?.type.join(", ")}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </MarkerPopover>
    </Popover>
  );
};

export const GymnasiumPopover = ({ anchorEl, handleClose, data, isSchool }: MarkerProps): JSX.Element => {
  const open = !!anchorEl;
  const { t } = useTranslation("translation");

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: 21 }}
    >
      <MarkerPopover container>
        <Grid item xs={12}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography variant="h4">{data?.institutionName}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{t("Base.Popovers.Address")}</TableCell>
                <TableCell align="center">{data?.address}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Base.Popovers.Commune")}</TableCell>
                <TableCell align="center">{data?.communeName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Base.Popovers.StudentsNumber")}</TableCell>
                <TableCell align="center">{data?.studentsNumber}</TableCell>
              </TableRow>
              {isSchool && (
                <>
                  <TableRow>
                    <TableCell>{t("Base.Popovers.GradesDistribution")}</TableCell>
                    <TableCell align="center">
                      <a
                        href="https://uddannelsesstatistik.dk/Pages/Reports/1766.aspx"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("Base.Popovers.SeeGrades")}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Base.Popovers.TeachingLessons")}</TableCell>
                    <TableCell align="center">
                      {/* TODO: change to correct link */}
                      <a
                        href="http://statweb.uni-c.dk/uvmvejltimetal/Default.aspx?skolenr=101247&periode=2013/2014"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("Base.Popovers.SeeTimetable")}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("Base.Popovers.Grades")}</TableCell>
                    <TableCell align="center">-</TableCell>
                  </TableRow>
                </>
              )}
              {!isSchool && (
                <TableRow>
                  <TableCell>{t("Base.Popovers.Grades")}</TableCell>
                  <TableCell align="center">{data?.grade}</TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>{t("Base.Popovers.CompletionRate")}</TableCell>
                <TableCell align="center">{data?.complete * 100} %</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Base.Popovers.Telephone")}</TableCell>
                <TableCell align="center">{data?.number}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>E-mail</TableCell>
                <TableCell align="center">
                  <a href={`mailto:${data?.email}`}>{t("Base.Popovers.WriteEmail")}</a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Website</TableCell>
                <TableCell align="center">
                  <a href={data?.url} target="_blank" rel="noopener noreferrer">
                    {t("Base.Popovers.VisitWebsite")}
                  </a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </MarkerPopover>
    </Popover>
  );
};

export const CrimePopover = ({ position, handleClose, data }: LayoutProps): JSX.Element => {
  const open = !!position;
  const { t } = useTranslation("translation");

  return (
    <Popover
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: position ? position.y : 0,
        left: position ? position.x : 0,
      }}
      onClose={handleClose}
    >
      <PopoverContainer>
        <PopoverBody>
          <Typography variant="h4">{t("Base.MapFilter.BurglaryRisk")}</Typography>
          <Typography variant="h5">
            {t("Base.Popovers.BurglaryReport", {
              communeFullName: data?.communeFullName,
              lastQuarterValue: data?.lastQuarterValue,
              communeRiskPlace: data?.communeRiskPlace,
            })}
          </Typography>
        </PopoverBody>
      </PopoverContainer>
    </Popover>
  );
};

export const FloodPopover = ({ position, handleClose, data }: LayoutProps): JSX.Element => {
  const open = !!position;
  const { t } = useTranslation("translation");

  const setFloodLevel = (value: number) => {
    switch (value) {
      case 0:
        return t("Base.Popovers.PointOfRiskOfRainwaterCollecting");
      case 1:
        return `0,1 ${t("Base.Popovers.To")} 0,99 m.`;
      case 2:
        return `1 ${t("Base.Popovers.To")} 1,99 m.`;
      case 3:
        return `2 ${t("Base.Popovers.To")} 2,99 m.`;
      case 4:
        return `3 ${t("Base.Popovers.To")} 3,99 m.`;
      case 5:
        return `4 ${t("Base.Popovers.To")} 4,99 m.`;
      case 6:
        return "+ 5 m.";
      default:
        return "";
    }
  };

  return (
    <Popover
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: position ? position.y : 0,
        left: position ? position.x : 0,
      }}
      onClose={handleClose}
    >
      <PopoverContainer>
        <PopoverBody>
          <Typography variant="h4">{t("Base.Popovers.Floods")}</Typography>
          <Typography variant="h5">
            {t("Base.Popovers.WaterMax")} {setFloodLevel(data)}
          </Typography>
        </PopoverBody>
      </PopoverContainer>
    </Popover>
  );
};

export const PlanPopover = ({ position, handleClose, data }: LayoutProps): JSX.Element => {
  const open = !!position;
  const { t } = useTranslation("translation");

  return (
    <Popover
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: position ? position.y : 0,
        left: position ? position.x : 0,
      }}
      onClose={handleClose}
    >
      <PopoverBody container direction="column" style={{ width: 300 }}>
        <PlanImage src={data?.MapUrl} onError={addDefaultSrc} />
        <Typography variant="h4">{data?.Name}</Typography>
        <Typography variant="h5">{data?.Description}</Typography>
        <Grid item>
          <Link href={data?.DocumentUrl} target="_blank">
            {t("Base.Popovers.ReadMore")}
          </Link>
          <Grid container alignItems="center">
            {data?.IsDecided ? (
              <>
                <Typography> {t("Base.Popovers.Adopted")}</Typography>
                <CircleGrid sx={{ backgroundColor: "green" }} />
              </>
            ) : (
              <>
                <Typography>{t("Base.Popovers.Suggestion")}</Typography>
                <CircleGrid sx={{ backgroundColor: "orange" }} />
              </>
            )}
          </Grid>
        </Grid>
      </PopoverBody>
    </Popover>
  );
};

export const NoisePopover = ({ position, handleClose, data }: LayoutProps): JSX.Element => {
  const open = !!position;
  const { t } = useTranslation("translation");
  let noiseType;
  let noiseValue;

  switch (data?.value) {
    case "0":
      noiseType = t("Base.Popovers.Computer");
      noiseValue = 50;
      break;
    case "1":
      noiseType = t("Base.Popovers.WashingMachine");
      noiseValue = 55;
      break;
    case "2":
      noiseType = t("Base.Popovers.Dishwasher");
      noiseValue = 60;
      break;
    case "3":
      noiseType = t("Base.Popovers.Hood");
      noiseValue = 65;
      break;
    case "4":
      noiseType = t("Base.Popovers.HandMixer");
      noiseValue = 70;
      break;
    case "5":
      noiseType = t("Base.Popovers.VacuumCleaner");
      noiseValue = 75;
      break;
    case "6":
      noiseType = t("Base.Popovers.HairDryer");
      noiseValue = 75;
      break;
  }

  return (
    <Popover
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: position ? position.y : 0,
        left: position ? position.x : 0,
      }}
      onClose={handleClose}
    >
      <PopoverContainer>
        <PopoverBody>
          <Typography variant="h4">{t("Base.MapFilter.Noise")}</Typography>
          <Typography variant="h5">
            {t("Base.Popovers.NoiseMax", {
              noiseType: noiseType,
              noiseValue: noiseValue,
            })}
          </Typography>
        </PopoverBody>
      </PopoverContainer>
    </Popover>
  );
};

export const GroundPopover = ({ position, handleClose, data }: LayoutProps): JSX.Element => {
  const open = !!position;
  const { t } = useTranslation("translation");

  return (
    <Popover
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: position ? position.y : 0,
        left: position ? position.x : 0,
      }}
      onClose={handleClose}
    >
      <MarkerPopover container>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography variant="h4">{data?.temaNavn}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{t("Base.Popovers.Address")}</TableCell>
                <TableCell align="center">{data?.locationName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Base.Popovers.Region")}</TableCell>
                <TableCell align="center">{data?.regionName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Base.Popovers.Activity")}</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Base.Popovers.Created")}</TableCell>
                <TableCell align="center">{data?.submitted.substring(0, 10)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Base.Popovers.LocationNumber")}</TableCell>
                <TableCell align="center">{data?.locationId}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </MarkerPopover>
    </Popover>
  );
};

export const RadonPopover = ({ position, handleClose, data }: LayoutProps): JSX.Element => {
  const open = !!position;
  const { t } = useTranslation("translation");

  return (
    <Popover
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: position ? position.y : 0,
        left: position ? position.x : 0,
      }}
      onClose={handleClose}
    >
      <PopoverContainer>
        <PopoverBody>
          <Typography variant="h4">
            {data?.communeName} {t("Base.Popovers.Class")} {data?.assessment.class}
          </Typography>
          <Typography variant="h5">
            {t("Base.Popovers.RodonMax", {
              numberOfSingleFamilyHomes: data?.numberOfSingleFamilyHomes,
              communeName: data?.communeName,
              measurementMax: data?.measurement.maxBqM3,
              measurementMed: data?.measurement.mediumBqM3,
            })}
          </Typography>
        </PopoverBody>
      </PopoverContainer>
    </Popover>
  );
};

export const DashboardFrequencyPopover = ({ position, handleClose, data }: DashboardProps): JSX.Element => {
  const [value, setValue] = React.useState("");
  const open = !!position;

  const handleChange = (event: SelectChangeEvent<any>) => {
    handleClose(event.target.value as DashboardSubscriptionFrequency);
  };

  return (
    <Popover
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: position ? position.y : 0,
        left: position ? position.x : 0,
      }}
      onClose={() => handleClose(null)}
    >
      <PopoverContainer>
        <DashboardFrequencyBlock>
          <InputLabel id="choose-dashboard-frequency-label">Choose notification frequency</InputLabel>
          <Select labelId="choose-dashboard-frequency-label" value={value} onChange={handleChange}>
            <MenuItem value={DashboardSubscriptionFrequency.Daily}>Daily</MenuItem>
            <MenuItem value={DashboardSubscriptionFrequency.Weekly}>Weekly</MenuItem>
            <MenuItem value={DashboardSubscriptionFrequency.Monthly}>Monthly</MenuItem>
            <MenuItem value={DashboardSubscriptionFrequency.OnlyFailedOrInSprint}>Only failed or in sprint</MenuItem>
          </Select>
        </DashboardFrequencyBlock>
      </PopoverContainer>
    </Popover>
  );
};

export const EcoBusinessPopover = ({ anchorEl, handleClose, data }: MarkerProps): JSX.Element => {
  const open = !!anchorEl;
  const { t } = useTranslation("translation");

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: 21 }}
    >
      <MarkerPopover container>
        <Grid item xs={12}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h4">{data?.Name}</Typography>
                </TableCell>
                <TableCell>
                  <img style={{ maxWidth: "100%" }} src={data?.Photo} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Rating</TableCell>
                <TableCell align="center">{data?.Rating && <Rating value={data?.Rating} />}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Base.Popovers.Address")}</TableCell>
                <TableCell align="center">{data?.SelectedAddress.Address}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Base.Popovers.Telephone")}</TableCell>
                <TableCell align="center">{data?.Phone}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Base.Popovers.Website")}</TableCell>
                <TableCell align="center">
                  <a href={data?.WebSite} target="_blank" rel="noopener noreferrer">
                    {data?.WebSite}
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Base.Popovers.CvrNumber")}</TableCell>
                <TableCell align="center">{data?.CvrNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Base.Popovers.Innovation")}</TableCell>
                <TableCell align="center">{data?.SustainabilityInfo?.Innovation}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Base.Popovers.LongLife")}</TableCell>
                <TableCell align="center">{data?.SustainabilityInfo?.LongLife}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Base.Popovers.Optimization")}</TableCell>
                <TableCell align="center">{data?.SustainabilityInfo?.Optimization}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Base.Popovers.RightMaterials")}</TableCell>
                <TableCell align="center">{data?.SustainabilityInfo?.RightMaterials}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Base.Popovers.UseAgain")}</TableCell>
                <TableCell align="center">{data?.SustainabilityInfo?.UseAgain}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </MarkerPopover>
    </Popover>
  );
};
