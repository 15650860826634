import { AddCircleOutline } from "@mui/icons-material";
import {
  Collapse,
  Grid,
  ListItem,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd";
import { useAlertContext } from "../../../../../components/Base/MyhouseAlert";
import ConfirmDialog from "../../../../../components/Dialogs/ConfirmDialog";
import MainMenu from "../../../../../components/Layout/MainMenu";
import { useDefaultReducer } from "../../../../../helpers/hooks";
import {
  CreateNavigationItemContract,
  NavigationItem,
  UpdateNavigationItemContract,
} from "../../../../../definitions/Menu";
import { move, reorder } from "../OverviewNavigationPage";
import DraggableListItem from "./DraggableListItem";
import NavigationEditor from "./NavigationEditor";

const Container = styled(Grid)(() => ({
  width: 530,
  margin: "30px auto 0",
}));

const ListItemAddCard = styled(ListItem)(() => ({
  width: 265,
  height: 215,
  "&:hover": {
    cursor: "pointer",
  },
}));

const AddCircleOutlineStyled = styled(AddCircleOutline)(({ theme }) => ({
  fontSize: "6rem",
  width: "6rem",
  height: "6rem",
  transition: "color 0.3s",
  color: theme.palette.grey[500],
}));

const StyledPaper = styled(Paper)(() => ({
  margin: 8,
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "center",
}));

type OverviewNavigationPageState = {
  navigationItems: NavigationItem[][];
  currentNavItem: NavigationItem | null;
  deletedItemId: string;
};

const initialState: OverviewNavigationPageState = {
  navigationItems: [[], []],
  currentNavItem: null,
  deletedItemId: "",
};

type OverviewItemsProps = {
  navigationItems: NavigationItem[];
  error: string | null;
  type: "overview" | "partner";
  headerText: string;
  withMenu?: boolean;
  reorderNavigationItems: (
    items: NavigationItem[]
  ) => Promise<NavigationItem[] | undefined>;
  getNavigationItems: () => Promise<NavigationItem[] | undefined>;
  getNavigationItem: (navItemId: string) => Promise<void>;
  deleteNavigationItem: (navItemId: string) => Promise<void>;
  setNavigationItemPublish: (
    itemId: string,
    state: boolean
  ) => Promise<NavigationItem | undefined>;
  addNavigationItem: (
    item: CreateNavigationItemContract
  ) => Promise<NavigationItem | undefined>;
  updateNavigationItem: (
    item: UpdateNavigationItemContract
  ) => Promise<NavigationItem | undefined>;
  updateBackgroundImage: (picture: File) => Promise<string | undefined>;
};

const OverviewItemsList = (props: OverviewItemsProps) => {
  const [state, dispatch] = useDefaultReducer(initialState);
  const { showAlert } = useAlertContext();

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(
        state.navigationItems[sInd],
        source.index,
        destination.index
      );
      const newState = [...state.navigationItems];
      newState[sInd] = items;

      dispatch({ type: "navigationItems", payload: newState });
      props.reorderNavigationItems(combineArrays(newState));
    } else {
      const result = move(
        state.navigationItems[sInd],
        state.navigationItems[dInd],
        source,
        destination
      );
      const newState = [...state.navigationItems];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      dispatch({ type: "navigationItems", payload: newState });
      props.reorderNavigationItems(combineArrays(newState));
    }
  };

  const combineArrays = (arr: NavigationItem[][]) => {
    const combinedArr = [];
    let index = 0;

    for (let i = 0; i < props.navigationItems.length; i++) {
      if (i < arr[0].length) {
        combinedArr.push({ ...arr[0][i], OrderNumber: index });
        ++index;
      }
      if (i < arr[1].length) {
        combinedArr.push({ ...arr[1][i], OrderNumber: index });
        ++index;
      }
    }

    return combinedArr;
  };

  useEffect(() => {
    dispatch({
      type: "navigationItems",
      payload: divideArray(props.navigationItems),
    });
  }, [props.navigationItems]);

  const divideArray = (arr: NavigationItem[]) => {
    const dividedArray: NavigationItem[][] = [[], []];
    arr.forEach((item) => dividedArray[item.OrderNumber % 2].push(item));
    return dividedArray;
  };

  const updateMenuItem = (itemId: string) => {
    dispatch({
      type: "currentNavItem",
      payload: props.navigationItems.find((item) => item.Id === itemId),
    });
  };

  const addMenuItem = () => {
    dispatch({ type: "currentNavItem", payload: {} as NavigationItem });
  };

  const addNavigationItem = async (
    item: CreateNavigationItemContract
  ): Promise<NavigationItem | undefined> => {
    const result = await props.addNavigationItem(item);

    closeEditor();
    return result;
  };

  const openDeleteDialog = (itemId: string) => {
    dispatch({ type: "deletedItemId", payload: itemId });
  };

  const closeDeleteDialog = () => {
    dispatch({ type: "deletedItemId", payload: "" });
  };

  const deleteNavigationItem = () => {
    props.deleteNavigationItem(state.deletedItemId);
  };

  const closeEditor = () => {
    dispatch({ type: "currentNavItem", payload: null });
  };

  useEffect(() => {
    if (props.error)
      showAlert({
        severity: "error",
        text: props.error,
      });
  }, [props.error]);

  useEffect(() => {
    return () => {
      dispatch({ type: "navigationItems", payload: [[], []] });
    };
  }, []);

  return (
    <Grid container item direction="column">
      <Collapse in={!!state.currentNavItem} unmountOnExit mountOnEnter>
        <NavigationEditor
          type={props.type}
          navItem={state.currentNavItem}
          addNavigationItem={addNavigationItem}
          updateNavigationItem={props.updateNavigationItem}
          updateBackgroundImage={props.updateBackgroundImage}
          closeEditor={closeEditor}
        />
      </Collapse>
      <Typography variant="body1">{props.headerText}</Typography>
      {props.withMenu && (
        <Grid container>
          <MainMenu menuItems={props.navigationItems} preview></MainMenu>
        </Grid>
      )}
      <Container container>
        <DragDropContext onDragEnd={onDragEnd}>
          {state.navigationItems.map(
            (column: NavigationItem[], columnIndex: number) => (
              <Droppable droppableId={`${columnIndex}`} key={columnIndex}>
                {(provided, snapshot) => (
                  <Grid ref={provided.innerRef}>
                    {column.map((item: NavigationItem, itemIndex: number) => (
                      <DraggableListItem
                        key={item.Id}
                        index={itemIndex}
                        menuItem={item}
                        updateMenuItem={updateMenuItem}
                        deleteNavigationItem={openDeleteDialog}
                        setNavigationItemPublish={
                          props.setNavigationItemPublish
                        }
                      />
                    ))}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            )
          )}
        </DragDropContext>

        <ListItemAddCard disableGutters onClick={addMenuItem}>
          <StyledPaper elevation={3}>
            <AddCircleOutlineStyled fontSize="large" />
            <Typography variant="h5">Add box</Typography>
          </StyledPaper>
        </ListItemAddCard>
      </Container>
      <ConfirmDialog
        open={!!state.deletedItemId}
        text="Are you sure want to delete this menu item?"
        OkClickHandler={deleteNavigationItem}
        onClose={closeDeleteDialog}
      />
    </Grid>
  );
};

export default OverviewItemsList;
