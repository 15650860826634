import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Grid,
  ListItemButton,
  styled,
  MenuItem,
  Select,
  TextField,
  SelectChangeEvent,
} from "@mui/material";
import { CO2EmissionSource } from "../../CO2Plan";
import { CO2Context } from "../CO2ContextWrapper";
import { useTranslation } from "react-i18next";

type CO2EmissionSourceProps = {
  source: CO2EmissionSource;
  personId: string | null;
  combinedMaxAmount: number | null;
  value: number | null;
  calculateCombined?: () => void;
  isMultiSelect: boolean;
  isFieldsInvalid?: boolean;
};

const CO2EmissionSourceComponent = (props: CO2EmissionSourceProps) => {
  const { source, personId } = props;
  const [selectedSubSource, setSelectedSubSource] = useState<string>("");
  const context = useContext(CO2Context);
  const [optionsExpanded, expandOptions] = useState(source.IsSelected);
  const [combinedMaxAmount, setCombinedMaxAmount] = useState(props.combinedMaxAmount);
  const { t } = useTranslation("translation");

  useEffect(() => {
    if (props.combinedMaxAmount != null && source.IsSelected) {
      setCombinedMaxAmount(props.combinedMaxAmount + (source.CombinedValue ?? 1));
    }
  }, [props.combinedMaxAmount, source]);

  useEffect(() => {
    expandOptions(source.IsSelected);
    setSelectedSubSource(source.ChildSources?.find((c) => c.IsSelected)?.Id || "");
  }, [source]);

  const handleCheckBoxCheck = (combinedValue: number | null, isUpdateSelected: boolean) => {
    if (source.HasOptions) {
      expandOptions(true);
      context.addOptionSource(source.Id, personId, null);
    } else if (source.HasChildSources && combinedMaxAmount == null) {
      context.selectHighLevelSource(source.Id, personId, null);
    } else {
      context.addValueSource(source.Id, personId, props.value ?? source.Value, combinedValue, isUpdateSelected);

      if (props.calculateCombined) props.calculateCombined();
    }
  };

  const handleSubSourceChange = (event: SelectChangeEvent<unknown>) => {
    const subSourceId = event.target.value as string;
    const subSource = source.ChildSources.find((c) => c.Id === subSourceId);

    if (subSource) {
      context.addValueSource(subSource.Id, personId, source.Value, source.CombinedValue, true);
    }
  };

  return (
    <Grid item xs={12} sm={6}>
      <StyledListItem
        selected={source.IsSelected || !!source.IsInitiallySelected}
        onClick={() => handleCheckBoxCheck(source.CombinedValue, true)}
        key={source.Id}
      >
        <Grid container>
          <Grid container item xs={9}>
            <Grid item container direction="column" alignContent={"space-between"} justifyContent="space-around">
              <Typography color="inherit" variant="body1">
                {t(source.Name)}
              </Typography>
              {props.isMultiSelect &&
                source.IsSelected &&
                combinedMaxAmount != null &&
                source.ChildSources?.length > 0 && (
                  <StyledSelect
                    onClick={(e) => e.stopPropagation()}
                    onChange={handleSubSourceChange}
                    value={selectedSubSource}
                    key={"subSource_of_" + source.Id}
                    error={selectedSubSource.length == 0}
                  >
                    {source.ChildSources.map((cs) => (
                      <MenuItem dense value={cs.Id} key={source.Id + cs.Id}>
                        {t(cs.Name)}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                )}
            </Grid>
          </Grid>
          {combinedMaxAmount != null && props.isMultiSelect && (source.IsSelected || optionsExpanded) && (
            <StyledGrid item xs={3} onClick={(e) => e.stopPropagation()}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Typography align="center" sx={{ fontSize: 12, width: "70px", lineHeight: 1.2 }} color="inherit">
                    {t("ClimatePlan.InitialSurvey.DaysPerYear")}
                  </Typography>
                </Grid>
                <Grid item>
                  <StyledTextField
                    type="number"
                    variant="outlined"
                    size="small"
                    sx={{ width: "50px", mt: 1 }}
                    InputProps={{
                      inputProps: {
                        inputMode: "numeric",
                        step: 1,
                        max: combinedMaxAmount,
                      },
                    }}
                    error={
                      props.isFieldsInvalid || source.CombinedValue == null || source.CombinedValue > combinedMaxAmount
                    }
                    value={source.CombinedValue ? Number(source.CombinedValue).toString() : source.CombinedValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      event.stopPropagation();
                      let val = Number(event.target.value);
                      const max = Number(event.target.max);
                      const min = Number(event.target.min);

                      if (val > max) val = max;
                      if (val < min) val = min;

                      handleCheckBoxCheck(val, false);
                    }}
                  ></StyledTextField>
                </Grid>
              </Grid>
            </StyledGrid>
          )}
        </Grid>
      </StyledListItem>
    </Grid>
  );
};

export default CO2EmissionSourceComponent;

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  cursor: "pointer",
  background: theme.palette.background.lightPaper,
  minHeight: 80,
  height: "100%",
  display: "flex",
  alignItems: "center",
  border: "1px solid" + theme.palette.background.grayPaper,
  "&.Mui-selected": {
    backgroundColor: theme.palette.background.grayPaper,
    color: theme.palette.primary.contrastText,
    "&.Mui-focusVisible": {
      backgroundColor: theme.palette.background.grayPaper,
      color: theme.palette.primary.contrastText,
    },
  },
  "&:hover": {
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.background.grayPaper,
      color: theme.palette.primary.contrastText,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.background.grayPaper,
      color: theme.palette.primary.contrastText,
    },
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root.MuiOutlinedInput-root: hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      color: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.contrastText,
    },
  },
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    color: theme.palette.primary.contrastText,
    padding: "2px 8px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    color: theme.palette.primary.contrastText,
    border: "1px solid",
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .Mui-error.MuiSelect-select.MuiInput-input": {
    borderColor: theme.palette.error.main,
  },
  "& .MuiSelect-select.MuiInput-input": {
    borderColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.contrastText,
  },
  "& svg.MuiSvgIcon-root": {
    fill: theme.palette.divider,
  },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText + 20,
  padding: 5,
  borderRadius: 10,
  position: "relative",
  right: -8,
}));
