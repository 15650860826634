import Grid from "@mui/material/Grid";
import React, { useEffect, ChangeEvent } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  getPredictionModels,
  getPredictionTestUnits,
  addUnitForPredictionTests,
  deleteUnitForPredictionTests,
  activatePredictionModel,
  renewPredictions,
  renewOnePrediction,
  reinitPredictions,
  deletePredictionModel,
  editPredictionModel,
} from "./admin.price-prediction.actions";
// import LoadWrapper from "../../../../components/Base/LoadWrapper";
import { useAlertContext } from "../../../../components/Base/MyhouseAlert";
import { AppThunkDispatch } from "../../../../definitions/Action";
import { useDefaultReducer } from "../../../../helpers/hooks";
import { ApplicationState } from "../../../../reducers/store";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";

import {
  PricePredictionsPageState,
  UpdatePreditcionContract,
} from "./defenitions";
import PredictionsList from "./components/PredictionsList";
import PredictionTestUnits from "./components/PredictionTestUnits";

const mapStateToProps = (state: ApplicationState) => ({
  predictions: state.pricePredictions.predictions,
  testUnits: state.pricePredictions.testUnits,
  loading: state.pricePredictions.loading,
  error: state.pricePredictions.error,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getPredictionModels: () => dispatch(getPredictionModels()),
  activatePredictionModel: (id: string) =>
    dispatch(activatePredictionModel(id)),
  getPredictionTestUnits: () => dispatch(getPredictionTestUnits()),
  addUnitForPredictionTests: (unitId: string) =>
    dispatch(addUnitForPredictionTests(unitId)),
  deleteUnitForPredictionTests: (testUnitId: string) =>
    dispatch(deleteUnitForPredictionTests(testUnitId)),
  renewOnePrediction: (id: string) => dispatch(renewOnePrediction(id)),
  renewPredictions: () => dispatch(renewPredictions()),
  reinitPredictions: () => dispatch(reinitPredictions()),
  deletePrediction: (id: string) => dispatch(deletePredictionModel(id)),
  editPredictionModel: (data: UpdatePreditcionContract) =>
    dispatch(editPredictionModel(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PricePredictionsConnectedProps = ConnectedProps<typeof connector>;

const initialState: PricePredictionsPageState = {
  loading: false,
  currentTab: 0,
  expandAddUnitRule: false,
};

const PricePredictionsPage = (
  props: PricePredictionsConnectedProps
): JSX.Element => {
  const [state, dispatch] = useDefaultReducer(initialState);
  const { showAlert } = useAlertContext();

  useEffect(() => {
    async function loadDataAsync() {
      dispatch({ type: "loading", payload: true });
      await props.getPredictionModels();
      await props.getPredictionTestUnits();

      dispatch({ type: "loading", payload: false });
    }

    loadDataAsync();

    return () => {
      // props.getActiveBehaviourRules();
    };
  }, []);

  useEffect(() => {
    dispatch({ type: "loading", payload: props.loading });
  }, [props.loading]);

  useEffect(() => {
    if (props.error)
      showAlert({
        severity: "error",
        text: props.error,
      });
  }, [props.error]);

  const handleChangeTab = (e: ChangeEvent<unknown>, value: number) => {
    dispatch({ type: "currentTab", payload: value });
  };

  const onCreateClick = () => {
    dispatch({ type: "expandAddUnitRule", payload: true });
  };

  const hideCreateBlock = () => {
    dispatch({ type: "expandAddUnitRule", payload: false });
  };

  const addNewTestUnit = async (unitId: string) => {
    const result = await props.addUnitForPredictionTests(unitId);
    if (result) {
      dispatch({ type: "expandAddUnitRule", payload: false });
      return result;
    }

    return null;
  };

  const activatePrediction = async (id: string) => {
    dispatch({ type: "loading", payload: true });
    await props.activatePredictionModel(id);
    dispatch({ type: "loading", payload: false });
  };

  const deleteNewTestUnit = async (testUnitId: string) => {
    dispatch({ type: "loading", payload: true });
    await props.deleteUnitForPredictionTests(testUnitId);
    dispatch({ type: "loading", payload: false });
  };

  const renewTestUnitPrediction = async (id: string) => {
    dispatch({ type: "loading", payload: true });
    await props.renewOnePrediction(id);
    dispatch({ type: "loading", payload: false });
  };

  const reinitPredictions = async () => {
    dispatch({ type: "loading", payload: true });
    await props.reinitPredictions();
    dispatch({ type: "loading", payload: false });
  };

  return (
    <Grid container justifyContent="center">
      <Grid container item xs={10}>
        <Tabs
          value={state.currentTab}
          onChange={handleChangeTab}
          // classes={{
          //   indicator: classes.indicator,
          // }}
        >
          <Tab disableRipple label="Predictions list"></Tab>
          <Tab disableRipple label="Test Units"></Tab>
        </Tabs>
        <Box
          display="flex"
          flexGrow={1}
          // borderBottom={`${theme.palette.grey[300]} solid 1px`}
        />
        <Box width="100%" /*className={classes.tabPanelContainer}*/>
          <TabPanel index={0} value={state.currentTab}>
            <PredictionsList
              predictions={props.predictions}
              loading={state.loading}
              activatePrediction={activatePrediction}
              reinitPredictions={reinitPredictions}
              deletePrediction={props.deletePrediction}
              editPrediction={props.editPredictionModel}
            />
          </TabPanel>
          <TabPanel index={1} value={state.currentTab}>
            <PredictionTestUnits
              loading={state.loading}
              testUnits={props.testUnits}
              predictions={props.predictions}
              expandAddUnitRule={state.expandAddUnitRule}
              onAddClick={onCreateClick}
              addNewTestUnit={addNewTestUnit}
              hideAddTestUnitBlock={hideCreateBlock}
              onDeleteClick={deleteNewTestUnit}
              onRenewClick={renewTestUnitPrediction}
              renewPredictions={props.renewPredictions}
            />
          </TabPanel>
        </Box>
      </Grid>
    </Grid>
  );
};

export default connector(PricePredictionsPage);

type TabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;

  return (
    <Box width="100%" role="tabpanel" hidden={value !== index}>
      {value === index && (
        <Grid container justifyContent="center">
          {children}
        </Grid>
      )}
    </Box>
  );
};
