import React, { useEffect } from "react";
import {
  Grid,
  CircularProgress,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableFooter,
} from "@mui/material";
import { useAdminCO2 } from "../../../../../actions/admin/admin.co2plan.actions";

const AgeGroupedItem = () => {
  const [{ loading, ageGroupedItems }, adminCo2Actions] = useAdminCO2();
  useEffect(() => {
    const getItems = async () => {
      if (!ageGroupedItems) {
        await adminCo2Actions.getAgeGroupedItems();
      }
    };
    getItems();
  }, [ageGroupedItems]);

  return (
    <>
      {!loading && (
        <Grid container>
          <TableContainer
            sx={{
              overflowY: "auto",
              width: "100%",
              height: "70vh",
              padding: 0,
            }}
          >
            <Table size="small" stickyHeader style={{ position: "relative" }}>
              <TableHead>
                <TableRow>
                  <TableCell>Alder</TableCell>
                  <TableCell>Antal</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ageGroupedItems &&
                  Object.entries(ageGroupedItems)
                    .sort((a, b) => {
                      const partsa = a[0].split("-");
                      const partsb = b[0].split("-");
                      return parseInt(partsa[0]) > parseInt(partsb[0]) ? 1 : -1;
                    })
                    .map((item) => (
                      <TableRow hover key={item[0]}>
                        <TableCell>{item[0]}</TableCell>
                        <TableCell>{item[1]}</TableCell>
                      </TableRow>
                    ))}
              </TableBody>

              <TableFooter>
                <TableRow></TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      )}
      {loading && (
        <Grid
          container
          sx={{
            overflowY: "auto",
            width: "100%",
            height: "60vh",
            padding: 0,
          }}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      )}
    </>
  );
};

export default AgeGroupedItem;
