import React, { useReducer, useState, useEffect, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Grid,  TextField, Button } from "@mui/material";
import { Add, CheckCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import NewsCreateDialog, { NewsFormData } from "./components/NewsCreateDialog";
import DeleteDialog from "./components/DeleteDialog";
import { ApplicationState } from "../../../../reducers/store";
import { AppThunkDispatch } from "../../../../definitions/Action";
import {
  addNews,
  getNews,
  deleteNews,
  setNewsPublish,
} from "../../../../actions/admin/admin.actions";
import { getTemplates } from "../../../../actions/admin/admin.landing.actions";
import { LandingArticleDto } from "../../../../definitions/Landing";
import { TemplateType } from "../../../../constants/enums";
import {
  AdminTable,
  Column,
  Icon,
} from "../../components/AdminTable/AdminTable";

type NewsPageState = {
  newsList: Array<LandingArticleDto>;
  addNewsModalShown: boolean;
  deleteNewsModalShow: boolean;
};

const initialState: NewsPageState = {
  newsList: [],
  addNewsModalShown: false,
  deleteNewsModalShow: false,
};

const reducer = (state: any, action: { type: string; payload: any }) => {
  return { ...state, [action.type]: action.payload };
};

const NewsPage = (props: NewsPageConnectedProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [deleteNewsId, setNewsIdToDeletion] = useState("");
  const navigate = useNavigate();
  const { newsList } = props;

  useEffect(() => {
    if (props.newsList.length === 0) props.getNews("", 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showAddNewsModal = () => {
    dispatch({ type: "addNewsModalShown", payload: true });
  };

  const handleModalClose = (news?: NewsFormData) => {
    dispatch({ type: "addNewsModalShown", payload: false });
    if (news) {
      props.addNews(news.Name, news.TemplateId);
    }
  };

  const deleteNews = (news: LandingArticleDto) => {
    setNewsIdToDeletion(news.Id);
    dispatch({ type: "deleteNewsModalShow", payload: true });
  };

  const handleDeleteModalClose = (confirmed?: boolean) => {
    dispatch({ type: "deleteNewsModalShow", payload: false });

    if (confirmed) props.deleteNews(deleteNewsId);
    setNewsIdToDeletion("");
  };

  const newsPublishHandler = (news: LandingArticleDto) => {
    if (news.Published) {
      props.setNewsPublish(news.Id, false);
    } else {
      props.setNewsPublish(news.Id, true);
    }
  };

  const getNewsTemplates = (skip: number, take: number) => {
    if (
      props.newsTemplates.length !== props.newsTemplatesCount &&
      !props.loading
    )
      props.getTemplates("", skip, take, TemplateType.News);
  };

  const columns: Column<LandingArticleDto>[] = useMemo(
    () => [
      {
        name: "Published",
        accessor: "Published",
        format: (data) => (data ? <CheckCircle color="secondary" /> : ""),
      },
      {
        name: "Name",
        accessor: "Title",
      },
      {
        name: "Created",
        accessor: "Created",
        format: (data) => new Date(data).toLocaleString("da-dk"),
      },
      {
        name: "Status",
        icon: Icon.Disable,
        format: (data) => {
          return data.Published ? Icon.Disable : Icon.Public;
        },
        callback: (data) => {
          newsPublishHandler(data);
        },
      },
      {
        name: "Edit",
        icon: Icon.Edit,
        callback: (data) => navigate(`news/${data.ExternalId}`),
      },

      {
        name: "Delete",
        icon: Icon.Delete,
        callback: (data) => deleteNews(data),
      },
    ],
    []
  );

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item container>
          <TextField
            fullWidth
            placeholder="Search by title"
            // onChange={handleChange}
          />
        </Grid>

        <Grid item container>
          <AdminTable
            columns={columns}
            data={newsList}
            pagination="client"
            headerButton={
              <Grid container item justifyContent="start">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={showAddNewsModal}
                  sx={{ margin: 1 }}
                >
                  <Add />
                </Button>
              </Grid>
            }
          />
        </Grid>
      </Grid>
      <NewsCreateDialog
        open={state.addNewsModalShown}
        handleClose={handleModalClose}
        newsTemplates={props.newsTemplates}
        getNewsTemplates={getNewsTemplates}
      />
      <DeleteDialog
        open={state.deleteNewsModalShow}
        handleClose={handleDeleteModalClose}
      />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  newsList: state.admin.newsList,
  newsTemplates: state.adminLanding.templates,
  newsTemplatesCount: state.adminLanding.templatesCount,
  loading: state.admin.loading,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  addNews: (name: string, templateId: string | null) =>
    dispatch(addNews(name, templateId)),
  getNews: (filter: string, skip: number) => dispatch(getNews(filter, skip)),
  deleteNews: (id: string) => dispatch(deleteNews(id)),
  setNewsPublish: (id: string, publish: boolean) =>
    dispatch(setNewsPublish(id, publish)),
  getTemplates: (
    searchTerm: string,
    skip: number,
    take: number,
    type?: TemplateType | undefined
  ) => dispatch(getTemplates(searchTerm, skip, take, type)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type NewsPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(NewsPage);
