import React from "react";
import { Grid, ListItemIcon, styled } from "@mui/material";
import {
  Edit,
  Delete,
  NotInterested,
  Public,
  DragIndicator,
  Home,
  Person,
  Storefront,
} from "@mui/icons-material";
import {
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
} from "@hello-pangea/dnd";

import { MenuListItem } from "../../../../Overview/Components/MenuBlockList";
import { NavigationItem } from "../../../../../definitions/Menu";
import { Rating } from "@mui/material";
import { GetTranslatedText } from "../../../../../helpers/translation-helper";

const ActionsContainer = styled(Grid)(() => ({
  width: 249,
  height: 40,
  marginBottom: -40,
  position: "relative",
  top: 8,
  left: 8,
  borderRadius: "10px 10px 0 0",
  background: "rgba(0, 0, 0, 0.3)",
  zIndex: 1,
  "&:hover": {
    cursor: "pointer",
  },
  "& div": {
    minWidth: "auto",
  },
  "& svg": {
    color: "#fff",
    margin: "0 5px",
  },
}));

const StyledRating = styled(Rating)(({ theme }) => ({
  position: "relative",
  top: -30,
  background: "#ffffff80",
  color: theme.palette.secondary.dark,
  marginLeft: 85,
}));

const Sensitive = styled(Grid)(({ theme }) => ({
  position: "relative",
  top: -100,
  "& svg": {
    fontSize: 20,
    color: "#fff",
    marginBottom: 3,
    padding: 3,
    borderRadius: "50%",
    background: theme.palette.primary.main,
  },
}));

type DraggableListItemProps = {
  index: number;
  menuItem: NavigationItem;
  updateMenuItem: (itemId: string) => void;
  deleteNavigationItem: (itemId: string) => void;
  setNavigationItemPublish: (itemId: string, state: boolean) => void;
};

const DraggableListItem = (props: DraggableListItemProps): JSX.Element => {
  const {
    index,
    menuItem,
    updateMenuItem,
    deleteNavigationItem,
    setNavigationItemPublish,
  } = props;

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
  ): React.CSSProperties => ({
    ...draggableStyle,
    ...(isDragging && {}),
  });

  //const { i18n } = useTranslation("translation");
  return (
    <Draggable index={index} draggableId={menuItem.Id}>
      {(provided, snapshot) => (
        <Grid
          {...provided.draggableProps}
          ref={provided.innerRef}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <Grid height={"215px"}>
            <ActionsContainer
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              <Edit onClick={() => updateMenuItem(menuItem.Id)} />
              <ListItemIcon
                onClick={() =>
                  setNavigationItemPublish(menuItem.Id, !menuItem.IsPublished)
                }
              >
                {menuItem.IsPublished ? <NotInterested /> : <Public />}
              </ListItemIcon>
              <Delete onClick={() => deleteNavigationItem(menuItem.Id)} />
              <ListItemIcon {...provided.dragHandleProps}>
                <DragIndicator />
              </ListItemIcon>
            </ActionsContainer>
            <MenuListItem
              title={GetTranslatedText(menuItem.Titles)}
              background={menuItem.BackgroundImageUrl}
              isPublished={menuItem.IsPublished}
            />
            {menuItem.UserSensitive && (
              <StyledRating
                value={menuItem.Rating}
                size="small"
                name="rating"
                color="secondary"
                readOnly
              ></StyledRating>
            )}
            <Sensitive container direction="column" alignItems="flex-end">
              {menuItem.UserSensitive && <Person />}
              {menuItem.UnitSensitive && <Home />}
              {menuItem.ShowAtStartPage && <Storefront />}
            </Sensitive>
          </Grid>
        </Grid>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
