import React, { useCallback, useEffect, useReducer } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Grid, Fade, Box, CircularProgress, Container, Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useParams, useNavigate } from "react-router-dom";
import LandingArticle from "../../components/Base/LandingArticle";
import MainContainer from "../../components/Layout/MainContainer";
import { ApplicationState } from "../../reducers/store";
import { AppThunkDispatch } from "../../definitions/Action";
import { getWidgetsByPage, useClubs, useLanding } from "../../actions";
import { LandingArticleDto } from "../../definitions/Landing";
import { WidgetPage } from "../../constants/enums";
import { useTranslation } from "react-i18next";
import { LanguageType } from "../../definitions/Menu";

type ArticlePageState = {
  title: string;
  article: LandingArticleDto | null;
  articles: LandingArticleDto[];
  loading: boolean;
};

const initialState: ArticlePageState = {
  title: "",
  article: null,
  articles: [],
  loading: false,
};

const reducer = (state: typeof initialState, action: { type: string; payload: unknown }) => {
  return { ...state, [action.type]: action.payload };
};

const ArticlePage = (props: ArticlePagesConnectedProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { articleName } = useParams<{ articleName: string }>();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [landing] = useLanding();
  const { getWidget } = props;
  const [, { getArticlePage, getRecentArticles }] = useClubs();

  const loadArtiicles = useCallback(async () => {
    try {
      if (!articleName) return;
      dispatch({ type: "loading", payload: true });
      const article = await getArticlePage(articleName);
      dispatch({ type: "article", payload: article });
      const articles = await getRecentArticles(article.Id, 3);
      dispatch({ type: "articles", payload: articles.Results });
      if (landing.widgets?.length === 0) await getWidget(i18n.language as LanguageType);
    } finally {
      dispatch({ type: "loading", payload: false });
    }
  }, [articleName, i18n.language]);

  useEffect(() => {
    if (articleName) {
      loadArtiicles();
    }

    return () => {
      dispatch({ type: "article", payload: null });
      dispatch({ type: "articles", payload: [] });
    };
  }, [articleName]);

  useEffect(() => {
    if (state.article) {
      dispatch({ type: "title", payload: state.article.Title });
    }
  }, [state.article]);

  return (
    <MainContainer title={state.title}>
      <Container maxWidth="md">
        {state.loading ? (
          <Grid container justifyContent="center">
            <Fade in={state.loading}>
              <Box my={2.25}>
                <CircularProgress size={50} />
              </Box>
            </Fade>
          </Grid>
        ) : (
          <Grid container justifyContent="center" sx={(theme) => ({ color: theme.palette.primary.dark })}>
            <Grid container item>
              {(window.history.state.idx !== 0) && (
                <Button onClick={() => navigate(-1)} type="submit" sx={{ marginBottom: "10px" }}>
                  <ArrowBack fontSize="small" />
                  {t("General.Buttons.Back")}
                </Button>
              )}
              {state.article && <LandingArticle categorized={false} model={state.article} type="full" />}
            </Grid>
          </Grid>
        )}

        {/* <Typography variant="h3" sx={{marginY: 1}}>{t("Pages.Clubs.SimilarArticles")}</Typography>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} md={8}>
            {state.articles.map((a) => (
              <Box pb={3} key={a.Id}>
                <LandingArticle categorized={false}  model={a} type="preview" />
              </Box>
            ))}
          </Grid>
          <Grid item md={4}>
            <Hidden smDown>
              <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                <Box minWidth={275} maxWidth={300}>
                  {landing.widgets && landing.widgets[0] && <Widget model={landing.widgets[0]} />}
                </Box>
              </Slide>
            </Hidden>
          </Grid>
        </Grid> */}
      </Container>
    </MainContainer>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  club: state.club.club,
  loading: state.club.loading,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getWidget: (languageId?: LanguageType) =>
    dispatch(getWidgetsByPage(WidgetPage.NewsStream, 0, 1, "", null, undefined, languageId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type ArticlePagesConnectedProps = ConnectedProps<typeof connector>;

export default connector(ArticlePage);
