import {SystemMessageType, SystemMessageReplacements} from "../../../../definitions/model/SystemMessage";
import {useDefaultReducer} from "../../../../helpers/hooks";
import React, {useEffect} from "react";
import {Grid, Button, TextField} from "@mui/material";
import ReactJson, {InteractionProps} from "react-json-view";
import { useUser } from "../../../../actions";


type Model = {
    Type: string,
    Properties: { [key: string]: string }
}

type Property = {
    Type: string,
    Name: string
}

const groupBy = function (xs: any, key: any) {
    return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

const getReplacementModel = (text: string) => {
    const regex = new RegExp(/\${%(.*?)%}/g)
    const matches = text.match(regex);
    const mapped = matches?.map(x => {
        const type = x.match(/('|&#39;)\w.+('|&#39;)/)?.map(x => x.replaceAll("&#39;","").replaceAll("'", "")).join('');
        const name = x.match(/]\..+%/)?.map(x => x.replaceAll(/[\].%]/g, "")).join('');
        const property: Property = {
            Type: type ?? "",
            Name: name ?? ""
        }

        return property
    })

    if(!mapped || mapped?.length == 0) return []
    
    const group = groupBy(mapped, "Type");
    const keys = Object.keys(group);
    const models: Model[] = keys.map(x => ({
        Type: x,
        Properties: {}
    }))
    models.forEach(m=>
        group[m.Type].forEach((g:Property)=> {
            //Getter property needs address to work properly
            if(m.Type == "UnitModel" && g.Name == "AddressString")
                return {
                    AddressString: "",
                    Address: {
                        City: "",
                        Commune: {
                            CommuneName: "",
                            CommuneShortName: "",
                            CommuneNumber: 0
                        },
                        DoorNumber: "",
                        Floor: "",
                        HouseNo: "",
                        Latitude: 0,
                        Longitude: 0,
                        PostalCode: 0,
                        Street: ""
                    }               
                }
            
            return m.Properties[g.Name] = ""
        })
    )
    
    return models;
}

type Props = {
    MessageType: SystemMessageType,
    MessageText: string,
    MessageSubject: string,
    SendTestSystemMessage: (recipient: string, type: SystemMessageType, replacements: SystemMessageReplacements[])=>Promise<boolean>
}

type State = {
    replacements: Model[]
    email: string
}

const defaultState: State = {
    replacements: [],
    email: ""
}

const SystemMessageTestSender = (props: Props) => {
    const email = useUser()[0].user?.Email || "";
    defaultState.email = email ?? ""
    const [state, dispatch] = useDefaultReducer<State>(defaultState)
    const handleJsonEdit = (edit: InteractionProps) => dispatch({type: "replacements", payload: edit.updated_src})
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => dispatch({type: "email", payload: e.target.value})
    const handleSend = async () => {
        
        if(!state.email) return;
        
        const systemMessageReplacements: SystemMessageReplacements[] = state.replacements.map(x=> ({TypeName: x.Type, JsonValue: JSON.stringify(x.Properties)})) 
        
        await props.SendTestSystemMessage(state.email, props.MessageType, systemMessageReplacements);
    
    }
    
    useEffect(() => {
        dispatch({
            type: "replacements",
            payload: getReplacementModel(props.MessageSubject + "\n" + props.MessageText)
        });
    }, [props.MessageText, props.MessageSubject])

    return(
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField fullWidth variant="outlined" label="Email" value={state.email} onChange={handleEmailChange}/>
            </Grid>
            <Grid item xs={12}>
                <ReactJson src={state.replacements} enableClipboard onEdit={handleJsonEdit}/>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="secondary" onClick={handleSend}>Send</Button>
            </Grid>
        </Grid>)
}


export default React.memo(SystemMessageTestSender);