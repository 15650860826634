import React from "react";
import BaseDialog from "./BaseDialog";
import RemoveDocumentsPart from "./MoveOutDialog/RemoveDocumentsPart";
import { GroupMembersDto } from "../../definitions/model/Group";
import { MoveOutDialogSx } from "./MoveOutDialog/MoveOutDialog";
import { useDefaultReducer } from "../../helpers/hooks";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import SameSizeContainer from "../Base/SameSizeContainer";
import { UnitFoldersType } from "../../definitions/Document";
import { useNavigate } from "react-router-dom";

type RemoveProfileDialogProps = {
  open: boolean;
  from: string;
  userId: string;
  folders: UnitFoldersType[];
  families?: GroupMembersDto[];
  closeDialog: () => void;
  sendProfileRemoveRequest: (removeAllDocuments?: boolean) => Promise<void>;
};

type MoveOutDialogState = {
  removeAll?: boolean;
  stage: number;
};

const initialState: MoveOutDialogState = {
  removeAll: undefined,
  stage: 0,
};

const MoveOutDialog = (props: RemoveProfileDialogProps): JSX.Element => {
  const [state, dispatch] = useDefaultReducer(initialState);
  const navigate = useNavigate();
  const { t } = useTranslation("translation");

  const text =
    props.families && props.families.length > 1
      ? `${t("Base.Dialog.TextAboutDeletingProfilePart1")} ${props.families.length} ${t(
          "Base.Dialog.TextAboutDeletingProfilePart2"
        )}`
      : t("Base.Dialog.ProfileDeleteConfirmation");

  const handleCloseClick = () => {
    props.closeDialog();
    dispatch({
      type: "stage",
      payload: 0,
    });
  };

  const handleRemoveAll = async () => {
    dispatch({
      type: "removeAll",
      payload: true,
    });
    await props.sendProfileRemoveRequest(state.removeAll);
    handleCloseClick();
  };

  const handleLeaveAll = async () => {
    await props.sendProfileRemoveRequest(state.removeAll);
    handleCloseClick();
  };

  const handleMoveOutStages = async () => {
    if (props.folders.length > 0) {
      dispatch({
        type: "stage",
        payload: 1,
      });
    } else {
      await props.sendProfileRemoveRequest(state.removeAll);
      props.closeDialog();
      navigate("/");
    }
  };

  return (
    <>
      <BaseDialog titleComponent="" closeDialog={handleCloseClick} open={props.open}>
        {state.stage === 0 && (
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} container justifyContent="center">
              <Typography>{text}</Typography>
            </Grid>
            <Grid item>
              <SameSizeContainer gap={2}>
                <Button variant="contained" onClick={handleMoveOutStages} color="secondary" sx={{ maxWidth: "none" }}>
                  {t("General.Buttons.Ok")}
                </Button>
                <Button variant="contained" onClick={handleCloseClick} color="secondary" sx={{ maxWidth: "none" }}>
                  {t("General.Buttons.Undo")}
                </Button>
              </SameSizeContainer>
            </Grid>
          </Grid>
        )}

        {state.stage === 1 && (
          <RemoveDocumentsPart
            from={props.from}
            state={state}
            dialogType="remove-profile"
            hasDocuments={props.folders.length > 0}
            onCloseClick={handleCloseClick}
            onRemoveAll={handleRemoveAll}
            onLeaveAll={handleLeaveAll}
          />
        )}
      </BaseDialog>
    </>
  );
};

export default MoveOutDialog;
