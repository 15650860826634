import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React, { useEffect } from "react";
import { useDefaultReducer } from "../../../../../helpers/hooks";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import LinearProgress from "@mui/material/LinearProgress";
import { format } from "date-fns";
import {
  BehaviourRuleProps,
  EditRuleInfoFormData,
  RegistrationRuleState,
  RuleInfoProps,
} from "../defenitions";
import EditRuleInfo from "./EditRuleInfoComponent";
import {styled} from "@mui/material";

const ChipsContainer = styled(Grid)(({theme})=>({
    "& > *": {
      margin: theme.spacing(0.5),
    },
}))

const initialState: RegistrationRuleState = {
  isExpanded: false,
  isEdit: false,
  isNameEdit: false,
  loading: false,
  name: "",
};

const BehaviourRuleComponent = (props: BehaviourRuleProps): JSX.Element => {
  const [state, dispatch] = useDefaultReducer(initialState);

  useEffect(() => {
    dispatch({ type: "name", payload: props.rule.Name });
  }, [props.rule.Name]);

  const toggleExpand = () => {
    dispatch({ type: "isExpanded", payload: !state.isExpanded });
  };

  const onSubmit = async (data: EditRuleInfoFormData) => {
    if (state.isEdit) {
      dispatch({ type: "loading", payload: true });
      data.name = props.rule.Name;
      await props.onEdit(data);
      dispatch({ type: "loading", payload: false });
    }
    toggleEdit();
  };

  const onRemove = async () => {
    dispatch({ type: "loading", payload: true });
    await props.onDelete(props.rule.Id);
    dispatch({ type: "loading", payload: false });
  };

  const toggleEdit = () => {
    dispatch({ type: "isEdit", payload: !state.isEdit });
  };

  const updateName = async () => {
    dispatch({ type: "loading", payload: true });
    await props.onEdit({
      fields: props.rule.Fields,
      name: state.name,
      path: props.rule.Path,
      from: props.rule.From,
      to: props.rule.To,
    });
    dispatch({ type: "loading", payload: false });
    toggleChangeName();
  };

  const toggleChangeName = () => {
    dispatch({ type: "isNameEdit", payload: !state.isNameEdit });
  };

  return (
    <Box sx={(theme)=>({
      border: `solid ${theme.palette.grey[300]} 1px`
    })}>
      <Grid container sx={(theme)=>({
        padding: theme.spacing(1, 2)
      })} alignItems="center">
        <Grid item sx={{flexGrow: "1"}}>
          <Grid container>
            {state.isNameEdit ? (
              <>
                <Grid item>
                  <TextField
                    value={state.name}
                    margin="dense"
                    variant="outlined"
                    label="Name"
                    onChange={(e) => {
                      dispatch({
                        type: "name",
                        payload: e.currentTarget.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item>
                  <IconButton onClick={updateName} size="large">
                    <SaveIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton onClick={toggleChangeName} size="large">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid item>
                  <Typography variant="h5">{props.rule.Name}</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={toggleChangeName} size="large">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <IconButton onClick={onRemove} size="large">
            <DeleteIcon />
          </IconButton>
          <IconButton onClick={toggleExpand} size="large">
            {state.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={state.isExpanded}>
        <>
          <Divider />
          <Grid container sx={(theme)=>({
            padding: theme.spacing(1, 2)
          })}>
            {state.isEdit ? (
              <EditRuleInfo
                onSubmit={onSubmit}
                formId="edit-rule-form"
                inputs={props.inputs}
                rule={props.rule}
                autocompleteLoading={props.autocompleteLoading}
                onAutocompleteLoad={props.onAutocompleteLoad}
              />
            ) : (
              <RuleInfo
                rule={props.rule}
              />
            )}
          </Grid>
          <Divider />
          <Grid
            container
            sx={(theme)=>({
              padding: theme.spacing(1, 2)
            })}
            justifyContent="flex-end"
            spacing={1}
          >
            {state.isEdit && (
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={toggleEdit}
                >
                  Close
                </Button>
              </Grid>
            )}
            <Grid item>
              {!state.isEdit && (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={toggleEdit}
                  type="button"
                >
                  Edit
                </Button>
              )}
              {state.isEdit && (
                <Button
                  color="secondary"
                  variant="contained"
                  type="submit"
                  form="edit-rule-form"
                >
                  Save
                </Button>
              )}
            </Grid>
          </Grid>
        </>
      </Collapse>
      {state.loading && <LinearProgress />}
    </Box>
  );
};

const RuleInfo = (props: RuleInfoProps) => {
  return (
    <>
      <Grid item container alignItems="center">
        <Typography variant="h5">Path: {props.rule.Path}</Typography>
      </Grid>
      <Grid item container alignItems="center">
        <Grid item>
          <Typography variant="h5">Fileds:</Typography>
        </Grid>
        <ChipsContainer item>
          {props.rule.Fields.map((x) => (
            <Chip variant="outlined" key={x} label={x} color="primary" />
          ))}
        </ChipsContainer>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Typography variant="h5">Period</Typography>
          </Grid>
          {props.rule.From && (
            <Grid item>
              <TextField
                InputProps={{ notched: true }}
                InputLabelProps={{ shrink: true }}
                margin="dense"
                variant="outlined"
                type="date"
                label="From"
                disabled
                value={format(new Date(props.rule.From), "yyyy-MM-dd")}
              />
            </Grid>
          )}
          {props.rule.To && (
            <Grid item>
              <TextField
                InputProps={{ notched: true }}
                InputLabelProps={{ shrink: true }}
                margin="dense"
                variant="outlined"
                type="date"
                label="To"
                disabled
                value={format(new Date(props.rule.To), "yyyy-MM-dd")}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(BehaviourRuleComponent);
