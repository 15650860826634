import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import ContactMeBox from "../../../components/Base/ContactMeBox";
import { useTranslation } from "react-i18next";
import User from "../../../definitions/model/User";

const AskForPhoneNumberDialog = ({
  open,
  handleClose,
  user,
}: {
  open: boolean;
  handleClose: (phone?: string) => Promise<void>;
  user: User | null;
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {t("ServiceBook.NoPhoneTitle")}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="body1" paragraph>
              {t("ServiceBook.NoPhone")}
            </Typography>
          </Grid>
        </Grid>
        <ContactMeBox
          utmSource="myhouse.servicebook"
          initialValues={{
            name: user?.Name || "",
            phone: user?.Phone || "",
          }}
          loading={false}
          addLead={({ Phone }) => handleClose(Phone)}
          keepFormData
          contactButtonText="UserProfile.UpdateProfile"
          confirmationMessage="UserProfile.ProfileUpdated"
        />
      </DialogContent>
      <DialogActions>
        <Grid item px={2}>
          <Typography variant="body1" paragraph>
            {t("ServiceBook.Workers.PhonePermission", { hostname: window.location.hostname })}
          </Typography>
        </Grid>
        <Button
          onClick={() => handleClose()}
          variant="contained"
          color="secondary"
        >
          {t("General.Buttons.Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AskForPhoneNumberDialog;
