import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useBuilding } from "../../actions/building.actions";
import BuildingUnits from "./components/BuildingUnits";

const BuildingUnitsPage = (): JSX.Element => {
  const { addressURL } = useParams<{ addressURL: string }>();

  const [{ building, groupMembers }, buildingActions] = useBuilding();

  useEffect(() => {
    if (addressURL) {
      const addressParts = addressURL.split("_");
      const id = addressParts[addressParts.length - 1];
      if (building?.Id !== id) {
        buildingActions.getBuilding(id);
      }
      if (!groupMembers || groupMembers.BuildingId !== id) {
        buildingActions.getBuildingMembers(id);
      }
    }
  }, [addressURL]);

  return (
    <>
      {building && <BuildingUnits key={`units_${building.Id}`} building={building} members={groupMembers?.Members} />}
    </>
  );
};

export default BuildingUnitsPage;
