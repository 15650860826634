import React from "react";
import { Button, styled, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useTranslation } from "react-i18next";
import { CO2EmissionSourceGroup, CO2PlanPerson } from "../../CO2Plan";
import CO2EmissionSourceGroupComponent from "./CO2EmissionSourceGroupComponent";

type CO2InitialSurveyUpdateGroupProps = {
  group: CO2EmissionSourceGroup;
  planPersons: CO2PlanPerson[];
  onFinishEditing: () => void;
};

const CO2InitialSurveyUpdateGroupComponent = (props: CO2InitialSurveyUpdateGroupProps): JSX.Element => {
  const { t } = useTranslation("translation");

  return (
    <>
      <StyledArrowButton variant="outlined" onClick={props.onFinishEditing}>
        <KeyboardBackspaceIcon />
      </StyledArrowButton>
      {!props.group.IsCustom && (
        <>
          <Typography align="center" variant="h4">
            {t(props.group.Name)}
          </Typography>
          <CO2EmissionSourceGroupComponent
            group={props.group}
            absoluteIndex={0}
            hideNextButton={true}
            planPersons={props.planPersons}
          />
        </>
      )}
    </>
  );
};

const StyledArrowButton = styled(Button)(() => ({
  minWidth: 40,
  minHeight: 40,
  margin: 5,
}));

export default CO2InitialSurveyUpdateGroupComponent;
