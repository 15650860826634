/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useCallback, useState } from "react";
import { bindActionCreators } from "redux";
import {
  Box,
  Grid,
  Container,
  Typography,
  Slide,
  Fade,
  CircularProgress,
  Hidden,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import { getNews, getWidgetsByPage, clearNews } from "../../actions";
import { getComments, postComment, editComment, deleteComment } from "../../actions/comment.actions";
import { connect, ConnectedProps } from "react-redux";
import NewsItem from "./components/NewsItem";
import LandingArticle from "../../components/Base/LandingArticle";
import Widget from "./components/Widget";
import { WidgetPosition, WidgetPage, NewsCategoriesFilter } from "../../constants/enums";
import MainContainer from "./../../components/Layout/MainContainer";
import { ApplicationState } from "../../reducers/store";
import { AppThunkDispatch } from "../../definitions/Action";
import { CommentsContext } from "../../components/Comment/CommentContext";
import NewsCategoriesBar from "./components/NewsCategoriesBar";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { LanguageType } from "../../definitions/Menu";

const newsPageItemsFetchAmount = 10;

const NewsStreamPage = ({
  unit,
  widgets,
  news,
  comments,
  widgetsCount,
  loading,
  newsLoaded,
  getNews,
  clearNews,
  getWidgetsByPage,
  getComments,
  postComment,
  editComment,
  deleteComment,
}: NewsStreamPageConnectedProps) => {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("md"));
  const [firstRequestDate, setFirstRequestDate] = useState(new Date());
  const { t } = useTranslation("translation");

  const [categoryfillter, setCategoryFilter] = useState<NewsCategoriesFilter | null>(null);

  const handleScroll = useCallback(() => {
    const { scrollTop, offsetHeight } = document.documentElement;
    if (
      window.innerHeight + scrollTop > offsetHeight - 300 &&
      !newsLoaded &&
      !loading &&
      unit?.Address.Commune.CommuneNumber
    ) {
      getNews(        
        unit?.Address.Longitude,
        unit?.Address.Latitude,
        news.length,
        newsPageItemsFetchAmount,
        categoryfillter,
        firstRequestDate
      );

      if (widgetsCount < widgets.length)
        getWidgetsByPage(
          WidgetPage.NewsStream,
          widgets.length,
          newsPageItemsFetchAmount,
          "",
          null,
          undefined,
          i18n.language as LanguageType
        );
    }
  }, [
    newsLoaded,
    loading,
    getNews,
    news.length,
    widgetsCount,
    widgets.length,
    getWidgetsByPage,
    categoryfillter,
    unit?.Address.Commune,
    i18n.language,
  ]);

  const renderNewsStream = () => {
    const stream = [];
    //skips zero index and adds a widget after every 4 news
    for (let i = 0; i < news.length; i++) {
      if (i % 3 === 0 && i / 3 < widgets.length && isSmDown) {
        stream.push(<Widget model={widgets[i / 3]} inStream></Widget>);
      }

      if (news[i].CustomNews) {
        stream.push(<LandingArticle categorized={false}  model={news[i].CustomNews} type="preview" />);
      } else {
        stream.push(<NewsItem model={news[i]} />);
      }
    }
    return stream;
  };

  useEffect(() => {
    const handler = _.debounce(handleScroll, 300);
    window.addEventListener("scroll", handler);
    return () => {
      window.removeEventListener("scroll", handler);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (unit?.Address.Commune.CommuneNumber) {
      const requestTime = new Date();
      clearNews();
      getNews(unit?.Address.Longitude, unit?.Address.Latitude, 0, newsPageItemsFetchAmount, categoryfillter, requestTime);
      setFirstRequestDate(requestTime);

      if (!widgets.length) {
        getWidgetsByPage(
          WidgetPage.NewsStream,
          0,
          newsPageItemsFetchAmount,
          "",
          null,
          undefined,
          i18n.language as LanguageType
        );
      }
    }
  }, [unit?.Address.Commune, categoryfillter, i18n.language]);

  return (
    <CommentsContext.Provider
      value={{
        comments,
        getComments,
        postComment,
        editComment,
        deleteComment,
        loading,
      }}
    >
      <MainContainer title={t("NewsStream.MyLocalNews")!}>
        <Container maxWidth="md" sx={{ marginBottom: "20px" }}>
          <NewsCategoriesBar value={categoryfillter} onChange={setCategoryFilter} />
          <Typography variant="h6" component="h1" sx={{ marginBottom: "20px" }}>
            {t("NewsStream.LatestNews")}
          </Typography>
          <Grid container justifyContent="space-between">
            <Grid container item sm={12} md={8} alignContent="flex-start">
              {renderNewsStream().map((item, index) => (
                <Grid container key={index} sx={{ marginBottom: "20px" }}>
                  <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                    <Grid container>{item}</Grid>
                  </Slide>
                </Grid>
              ))}
              <Grid container justifyContent="center" sx={{ marginBottom: "20px" }}>
                <Fade in={loading && !newsLoaded} mountOnEnter unmountOnExit>
                  <CircularProgress size={50} />
                </Fade>
                <Fade in={!loading && newsLoaded && news.length < 1} mountOnEnter unmountOnExit>
                  <Typography variant="h5">{t("NewsStream.NoNews")}</Typography>
                </Fade>
              </Grid>
            </Grid>
            <Hidden mdDown>
              <Grid container item direction="column" xs={4} spacing={3}>
                {widgets &&
                  widgets
                    .filter((widget) => widget.Position === WidgetPosition.RightSide)
                    .map((widget, index) => (
                      <Grid item key={widget.Id}>
                        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                          <Box minWidth={275} maxWidth={300}>
                            <Widget model={widget}></Widget>
                          </Box>
                        </Slide>
                      </Grid>
                    ))}
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </MainContainer>
    </CommentsContext.Provider>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  news: state.newsstream.news,
  widgets: state.landing.widgets.filter((c) => c.Pages.includes(WidgetPage.NewsStream)),
  widgetsCount: state.landing.widgetsCount,
  comments: _.groupBy(state.comment.comments, (c) => c.ParentId),
  loading: state.newsstream.loading,
  newsLoaded: state.newsstream.newsLoaded,
  unit: state.unit.unit,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  ...bindActionCreators(
    {
      getNews,
      getWidgetsByPage,
      getComments,
      postComment,
      editComment,
      deleteComment,
      clearNews,
    },
    dispatch
  ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type NewsStreamPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(NewsStreamPage);
