import React, { useState } from "react";
import { Button, TextField, Grid, Select, MenuItem } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { TemplateType } from "../../../../../constants/enums";
import BaseDialog from "../../../../../components/Dialogs/BaseDialog";
import { TemplateModel } from "../../../../../definitions/Landing";
import { useAlertContext } from "../../../../../components/Base";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";
import CircularProgressButton from "../../../../../components/Base/CircularProgressButton";

type TemplateDialogProps = {
  open: boolean;
  handleClose: () => void;
  createTemplate: (
    name: string,
    type: TemplateType
  ) => Promise<TemplateModel | null>;
};

export type TemplateFormData = {
  Name: string;
  Type: TemplateType;
};

const TemplateCreateDialog = (props: TemplateDialogProps): JSX.Element => {
  const methods = useForm<TemplateFormData>({
    defaultValues: {
      Name: "",
      Type: TemplateType.News,
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;
  const [isUpdating, setIsUpdateing] = useState(false);
  const { showAlert } = useAlertContext();
  const handleClose = () => {
    props.handleClose();
  };

  const submit = (data: TemplateFormData) => {
    setIsUpdateing(true);
    props
      .createTemplate(data.Name, data.Type)
      .then(() => {
        showAlert({ severity: "success", text: "Template succesfuly created" });
      })
      .catch(() => {
        showAlert({ severity: "error", text: "Template was not created" });
      })
      .finally(() => {
        setIsUpdateing(false);
        props.handleClose();
      });
  };

  const selectItems = Object.entries(TemplateType).filter(
    ([key, val]) => !Number.isInteger(val)
  );

  return (
    <>
      <BaseDialog
        open={props.open}
        closeDialog={handleClose}
        titleComponent="Create Template"
      >
        <form onSubmit={handleSubmit(submit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldConnector
                register={register("Name", { required: true })}
              >
                <TextField
                  autoFocus
                  margin="dense"
                  variant="outlined"
                  label="Name"
                  type="text"
                  fullWidth
                  error={!!errors.Name}
                />
              </TextFieldConnector>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="Type"
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                  >
                    {selectItems.map(([key, val]) => (
                      <MenuItem
                        key={key}
                        value={key}
                        style={{ padding: "10px 20px" }}
                      >
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                control={control}
              />
            </Grid>
            <Grid container justifyContent="flex-end" item>
              <Button
                variant="contained"
                onClick={handleClose}
                color="secondary"
                style={{ marginRight: 20, minWidth: 120 }}
              >
                Close
              </Button>
              <CircularProgressButton                
                style={{ marginRight: 20, minWidth: 120 }}
                onClick={handleClose}                
                inProgress={isUpdating}
              >
                Create
              </CircularProgressButton>
            </Grid>
          </Grid>
        </form>
      </BaseDialog>
    </>
  );
};

export default TemplateCreateDialog;
