import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, CardContent, Collapse } from "@mui/material";
import { useTranslation } from "react-i18next";
import UserLogMessages from "../User/components/UserLogMessages";
import { ArrowBack, UnfoldLessDouble, UnfoldMoreDouble } from "@mui/icons-material";
import { GroupMemberArchive } from "../../definitions/model/Group";
import { useBuilding } from "../../actions/building.actions";
import { DocumentSourceType } from "../../definitions/Document";
import Folders from "../House/Documents/Folders";
import { MCard } from "../../components/Base";
import { formatRelative } from "date-fns";
import daLocale from "date-fns/locale/da";
import enLocale from "date-fns/locale/en-GB";
import { repeatSkeleton } from "../../components/Base/DetailsSkeleton";

const BuildingArchivePage = (): JSX.Element => {
  const { addressURL } = useParams<{ addressURL: string }>();
  const [buildingArchive, setBuildingArchive] = useState<GroupMemberArchive[]>([]);
  const [loading, setLoading] = useState(false);
  const [buildingId, setBuildingId] = useState<string>();
  const [expandedUserId, setExpandedUserId] = React.useState<string>();
  const [, buildingActions] = useBuilding();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("translation");

  useEffect(() => {
    if (addressURL) {
      const addressParts = addressURL.split("_");
      const id = addressParts[addressParts.length - 1];
      if (id) {
        setBuildingId(id);
        setLoading(true);
        buildingActions
          .getTenantsArchive(id)
          .then((archive) => setBuildingArchive(archive))
          .finally(() => setLoading(false));
      }
    }
  }, [addressURL]);

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => {
          if (window.history.state.idx !== 0) {
            navigate(-1);
            return;
          }

          navigate("/buildings");
        }}
        type="submit"
        sx={{ marginBottom: "10px" }}
      >
        <ArrowBack fontSize="small" />
        {t("General.Buttons.Back")}
      </Button>
      {!loading &&
        buildingArchive
          .sort((a, b) => (a.Created > b.Created ? -1 : 1))
          .map((archive) => (
            <MCard
              key={archive.UserId}
              type="secondary"
              title={`${archive.Name}(${archive.Email}): ${formatRelative(new Date(archive.Created), new Date(), {
                locale: i18n.language === "da" ? daLocale : enLocale,
              })}`}
              sx={{ "& .MuiCardContent-root": { padding: 1, marginBottom: 0 } }}
              headerAction={
                <Button
                  color="secondary"
                  size="small"
                  variant="outlined"
                  sx={{ borderRadius: "50%", minWidth: "unset", padding: 1, margin: 0, marginRight: 0.5 }}
                  onClick={() => setExpandedUserId(expandedUserId !== archive.UserId ? archive.UserId : undefined)}
                >
                  {expandedUserId === archive.UserId ? <UnfoldLessDouble /> : <UnfoldMoreDouble />}
                </Button>
              }
            >
              <Collapse in={expandedUserId === archive.UserId} timeout="auto" unmountOnExit>
                <CardContent>
                  {buildingId && (
                    <Folders
                      sourceItemId={buildingId}
                      sourceType={DocumentSourceType.Building}
                      folders={archive.Folders}
                      readonly
                    />
                  )}
                  <UserLogMessages loading={false} logs={archive.UserLog} />
                </CardContent>
              </Collapse>
            </MCard>
          ))}
      {loading && repeatSkeleton({ width: "100%", height: 120 }, 5)}
    </>
  );
};

export default BuildingArchivePage;
