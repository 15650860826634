import React, { useContext, useEffect, useState } from "react";
import { Grid, TextField, Typography, styled } from "@mui/material";

import { CO2Context } from "../CO2ContextWrapper";
import { CO2EmissionSource } from "../../CO2Plan";
import { useTranslation } from "react-i18next";

type CO2TargetValueSourceProps = {
  personId: string | null;
  source: CO2EmissionSource;
  sources: CO2EmissionSource[];
  itemsCount: number;
  disabled?: boolean;
  isInitial?: boolean;
  withBg: boolean;
};
export const StyledSourceComponentGrid = styled(Grid, {
  shouldForwardProp: (propName: string) => propName !== "withBackground",
})<{ withBackground: any }>(({ theme, withBackground }) => ({
  borderRadius: 10,
  padding: "4px 8px",
  backgroundColor: withBackground ? theme.palette.background.lightPaper : "unset",
  marginTop: withBackground ? 10 : 0,
}));

const CO2TargetValueSourceComponent = (props: CO2TargetValueSourceProps): JSX.Element => {
  const { sources, personId, source } = props;
  const context = useContext(CO2Context);
  const { t } = useTranslation("translation");
  const [value, setValue] = useState(props.isInitial ? source.ValueInitial : source.Value);
  const handleValueChange = (val: number) => {
    setValue(val);
    sources.forEach((s) => {
      context.addValueSource(s.Id, personId, val, s.CombinedValue, false);
    });
  };

  useEffect(() => {
    if (sources.some((src) => src.Value !== source.Value)) {
      sources.forEach((s) => {
        context.addValueSource(s.Id, personId, value, s.CombinedValue, false);
      });
    }
  }, []);

  return (
    <StyledSourceComponentGrid display="flex" withBackground={props.withBg && props.itemsCount % 2}>
      <TextField
        type="number"
        size="small"
        disabled={props.disabled}
        inputProps={{
          sx: { minHeight: "22px", textAlign: "center" },
        }}
        sx={{
          width: "50px",
          "& .MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before": {
            borderBottom: "unset",
          },
          "& .MuiInput-input.Mui-disabled": {
            WebkitTextFillColor: "#000",
            borderBottom: "unset",
            textAlign: "center",
            padding: 0,
          },
        }}
        defaultValue={value}
        onChange={(event) => {
          const val = parseInt(event.target.value) < 0 ? "0" : event.target.value;
          handleValueChange(parseInt(val) || 0);
        }}
      ></TextField>
      <Typography variant="body1">{t("ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.DayKm")}</Typography>
    </StyledSourceComponentGrid>
  );
};

export default CO2TargetValueSourceComponent;
