import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Skeleton,
  Autocomplete,
  AutocompleteRenderInputParams,
  TextFieldProps,
} from "@mui/material";
import PageviewIcon from "@mui/icons-material/Pageview";
import CheckIcon from "@mui/icons-material/Check";
import _ from "lodash";
import { CO2UnitAddress } from "../../../../ClimatePlan/shared/CO2Plan";
import AdminCO2PlanViewerDialog from "./AdminCO2PlanViewerDialog";
import { CellLabel, Direction, TransferList } from "../../../../../components/Base";
import { useDebounce } from "../../../../../helpers/hooks";
import { CommuneLocation } from "../../../../../definitions/model/unit";
import { CompanyModel } from "../../../../../definitions/Company";
import { useAdminCO2 } from "../../../../../actions/admin/admin.co2plan.actions";
import { useCO2 } from "../../../../ClimatePlan/shared/co2plan.actions";
import { useAdmin } from "../../../../../actions/admin/admin.actions";

const UnitsWithPlan = () => {
  const pageSize = 50;
  const [{ unitsWithCO2Plans: units, loading }, adminCo2Actions] = useAdminCO2();
  const [{ totalCO2Plans }] = useCO2();
  const [adminState, { getCommunes }] = useAdmin();
  const [pageNumber, setPageNumber] = useState(0);
  const [orderColumn, setOrderColumn] = useState("PostalCode");
  const [orderDirection, setOrderDirection] = useState<Direction>("asc");
  const [unitsToShow, setUnitsToShow] = useState<CO2UnitAddress[]>([]);
  const [unitToShowInDialog, setUnitToShowInDialog] = useState<CO2UnitAddress | null>(null);
  const [searchString, setSearchString] = useState<string>("");
  const [communes, setCommunes] = useState<Array<CommuneLocation>>([]);
  const [company, setCompany] = useState<CompanyModel | null>(null);
  const debouncedSearchString = useDebounce(searchString, 300);

  const handleNextPage = () => {
    const nextPage = pageNumber + 1;
    const skip = nextPage * pageSize;
    setPageNumber(nextPage);
    adminCo2Actions.getUnitsWithCO2Plans(
      skip,
      pageSize,
      communes.map((c) => c.Commune.CommuneNumber),
      debouncedSearchString,
      orderColumn,
      orderDirection == "desc",
      company?.Id
    );
  };

  useEffect(() => {
    if (orderColumn == "Created" || orderColumn == "Updated") return; //by Created or Updated fields we can sort only in client

    getData();
  }, [debouncedSearchString, communes, orderColumn, orderDirection]);

  useEffect(() => {
    getData();
  }, [company]);

  const getData = () => {
    if ((debouncedSearchString && debouncedSearchString.length > 2) || !debouncedSearchString) {
      setPageNumber(0);
      setSearchString(debouncedSearchString);
      adminCo2Actions.getUnitsWithCO2Plans(
        0,
        communes.length == 1 ? 0 : pageSize, //get all plans if one commune selected
        communes.map((c) => c.Commune.CommuneNumber),
        debouncedSearchString,
        orderColumn,
        orderDirection == "desc",
        company?.Id
      );
    }
  };

  useEffect(() => {
    let sortedUnits = _.sortBy(units, orderColumn);
    if (orderDirection === "desc") {
      sortedUnits = sortedUnits.reverse();
    }
    setUnitsToShow(sortedUnits as CO2UnitAddress[]);
  }, [units, orderDirection, orderColumn]);

  const handleChangeOrder = (name: string) => () => {
    if (name !== orderColumn) {
      setOrderDirection("asc");
      setOrderColumn(name);
    } else {
      setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    }
  };

  useEffect(() => {
    if (!adminState.communes || adminState.communes.length === 0) getCommunes();
  }, [adminState.communes]);

  useEffect(() => {
    const skip = pageNumber * pageSize;
    adminCo2Actions.getCO2SponsorSolutions(skip, pageSize);
  }, [pageNumber, pageSize]);

  return (
    <>
      <Grid container justifyContent={"center"}>
        <Grid item xs={4}>
          <TextField
            variant="outlined"
            placeholder={"Address"}
            onChange={(e) => {
              setOrderColumn("PostalCode");
              setSearchString(e.target.value);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TransferList
            options={adminState.communes}
            selected={communes}
            fieldLabel="Communes"
            placeholder="Communes"
            onChanges={(selected: Array<CommuneLocation>) => {
              setOrderColumn("PostalCode");
              setCommunes(selected);
            }}
            getOptionLabel={(o: CommuneLocation) => o.Commune.CommuneName}
            getOptionSelected={(s: CommuneLocation, o: CommuneLocation) =>
              s.Commune.CommuneNumber === o.Commune.CommuneNumber
            }
          ></TransferList>
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            value={company}
            onChange={(event, newValue) => setCompany(newValue)}
            options={adminState.companies}
            loading={loading}
            getOptionLabel={(o) => o.Name}
            isOptionEqualToValue={(o, value) => {
              return company?.Id === o.Id;
            }}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField {...(params as TextFieldProps)} label="Companies" variant="outlined" placeholder="Companies" />
            )}
          ></Autocomplete>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Typography>
          Total CO<sub>2</sub>e plans: {totalCO2Plans}
        </Typography>
        <Typography>
          <a href={process.env.REACT_APP_CO2_STATISTIC} target="blank">
            Statistics portal
          </a>
        </Typography>
        <Typography>
          Properties with registered CO<sub>2</sub>e plan:
        </Typography>
        <TableContainer
          sx={{
            overflowY: "auto",
            width: "100%",
            height: "75vh",
            padding: 0,
          }}
        >
          <Table size="small" stickyHeader style={{ position: "relative" }}>
            <TableHead>
              <TableRow>
                <CellLabel
                  key="#"
                  cellName="#"
                  cellKey="#"
                  disabled
                  currentCellName={orderColumn}
                  direction={orderDirection}
                  onClick={handleChangeOrder}
                />
                <CellLabel
                  key="Address"
                  cellName="Address"
                  cellKey="Address"
                  currentCellName={orderColumn}
                  direction={orderDirection}
                  onClick={handleChangeOrder}
                />
                <CellLabel
                  key="CommuneName"
                  cellName="Commune"
                  cellKey="CommuneName"
                  currentCellName={orderColumn}
                  direction={orderDirection}
                  onClick={handleChangeOrder}
                />
                <CellLabel
                  key="PostalCode"
                  cellName="PostalCode"
                  cellKey="PostalCode"
                  currentCellName={orderColumn}
                  direction={orderDirection}
                  onClick={handleChangeOrder}
                />
                <CellLabel
                  key="Created"
                  cellName="Created"
                  cellKey="Created"
                  currentCellName={orderColumn}
                  direction={orderDirection}
                  onClick={handleChangeOrder}
                  disabled={unitsToShow.length == (pageNumber + 1) * pageSize}
                />
                <CellLabel
                  key="Updated"
                  cellName="Updated"
                  cellKey="Updated"
                  currentCellName={orderColumn}
                  direction={orderDirection}
                  onClick={handleChangeOrder}
                  disabled={unitsToShow.length == (pageNumber + 1) * pageSize}
                />
                <TableCell align="center">{`"Analyse" completed`}</TableCell>
                <TableCell align="center">{`"Plan" completed`}</TableCell>
                <TableCell>View</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                unitsToShow.map((unit, index) => (
                  <TableRow hover key={unit.UnitId}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{unit.Address}</TableCell>
                    <TableCell>{unit.CommuneName}</TableCell>
                    <TableCell>{unit.PostalCode}</TableCell>
                    <TableCell>{(unit.Created ?? "").substring(0, 10)}</TableCell>
                    <TableCell>{(unit.Updated ?? "").substring(0, 10)}</TableCell>
                    <TableCell align="center">
                      <Grid container justifyContent={"space-evenly"} alignItems={"center"}>
                        {unit.InitialSurveyFilledInPercentage >= 100 && (
                          <>
                            <CheckIcon />
                            {getColorBox(
                              unit.InitialHouseholdCO2Emission ?? 0,
                              unit.GovernmentGoalCO2Emission,
                              unit.DefaultEmission
                            )}
                          </>
                        )}

                        {unit.InitialSurveyFilledInPercentage < 100 && (
                          <>{unit.InitialSurveyFilledInPercentage.toFixed(0) + "%"}</>
                        )}
                      </Grid>
                    </TableCell>
                    <TableCell align="center">
                      <Grid container justifyContent={"space-evenly"} alignItems={"center"}>
                        {unit.TargetSurveyFilledInPercentage >= 100 && (
                          <>
                            <CheckIcon />
                            {getColorBox(
                              unit.TargetHouseholdCO2Emission ?? 0,
                              unit.GovernmentGoalCO2Emission,
                              unit.DefaultEmission
                            )}
                          </>
                        )}

                        {unit.TargetSurveyFilledInPercentage < 100 && (
                          <>{unit.TargetSurveyFilledInPercentage.toFixed(0) + "%"}</>
                        )}
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          setUnitToShowInDialog(unit);
                        }}
                        style={{ padding: 0 }}
                      >
                        <PageviewIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              {loading &&
                [...Array(10).keys()].map((index) => (
                  <TableRow key={index}>
                    <TableCell colSpan={9}>
                      <Skeleton width="100%" height={30} animation="wave" />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Grid container justifyContent="center">
            {units.length < totalCO2Plans && (
              <Button
                variant="contained"
                size="large"
                style={{ marginTop: 10 }}
                onClick={() => handleNextPage()}
                disabled={loading}
              >
                Load more
              </Button>
            )}
          </Grid>
        </TableContainer>
      </Grid>
      <AdminCO2PlanViewerDialog
        open={!!unitToShowInDialog}
        unit={unitToShowInDialog}
        companyId={company?.Id}
        handleClose={() => {
          setUnitToShowInDialog(null);
        }}
      />
    </>
  );
};

export default UnitsWithPlan;

const getColorBox = (currentEmission: number, governmentGoalCO2Emission: number, defaultEmission: number) => {
  const a = (88 - 25) / (defaultEmission - governmentGoalCO2Emission);
  const b = 88 - a * defaultEmission;
  let result = a * currentEmission + b;
  result = Math.max(0, result);
  result = Math.min(100, result);

  const hue = ((1 - result / 100) * 120).toString(10);
  return (
    <div
      style={{
        backgroundColor: ["hsl(", hue, ",100%,50%)"].join(""),
        height: "1em",
        width: "1em",
      }}
    />
  );
};
