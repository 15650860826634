import React, { useReducer, useEffect, useRef, useMemo, useState } from "react";
import { Grid, TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add, CheckCircle } from "@mui/icons-material";
import { ApplicationState } from "../../../../reducers/store";
import { AppThunkDispatch } from "../../../../definitions/Action";
import {
  getLandings,
  clearLandings,
  deleteLanding,
  createLanding,
  updateLanding,
  getTemplates,
  clearTemplates,
  cloneLanding,
  useUser,
} from "../../../../actions";
import { connect, ConnectedProps } from "react-redux";
import { useDebounce, useInfiniteScroll } from "../../../../helpers/hooks";
import CreateLandingDialog from "./components/CreateLandingDialog";
import { TemplateType } from "../../../../constants/enums";
import { LandingMetaModel } from "../../../../definitions/Landing";
import {
  AdminTable,
  Column,
  Icon,
} from "../../components/AdminTable/AdminTable";

const initialState = {
  searchTerm: "",
  takeAmount: 10,
  openCreateDialog: false,
  page: 0,
};

const reducer = (
  state: typeof initialState,
  action: { type: string; payload: any }
) => {
  return { ...state, [action.type]: action.payload };
};

const LandingsPage = ({
  landings,
  landingsCount,
  templates,
  templatesCount,
  loading,
  createLanding,
  getLandings,
  updateLanding,
  deleteLanding,
  clearLandings,
  clearTemplates,
  getTemplates,
  cloneLanding,
}: LandingsPageConnectedProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const debouncedSearchTerm = useDebounce(state.searchTerm, 500);
  const listRef = useRef<HTMLUListElement | null>(null);
  const navigate = useNavigate();
  const [{ user }, userActions] = useUser();
  const [landingsTableData, setLandingsTableData] = useState<
    LandingMetaModel[]
  >([]);

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      dispatch({ type: "searchTerm", payload: e.target.value });
    }
  };

  const handleDeleteLanding = (landingId: string) => {
    deleteLanding(landingId);
  };

  const handleCloneLanding = (landingId: string) => {
    cloneLanding(landingId);
  };

  useInfiniteScroll<HTMLUListElement>(listRef, () => {
    if (landingsCount > landings.length && !loading) {
      getLandings(state.searchTerm, landings.length, state.takeAmount);
    }
  });

  const changePagination = ({ page, rows }: { page: number; rows: number }) => {
    dispatch({ type: "page", payload: page });
    dispatch({ type: "takeAmount", payload: rows });
  };

  useEffect(() => {
    clearLandings();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const fetchItem = state.page * state.takeAmount + state.takeAmount;
    const currentCount =
      fetchItem > landingsCount && landingsCount !== 0
        ? landingsCount
        : fetchItem;
    if (landings.length < currentCount) {
      getLandings(
        state.searchTerm,
        landings.length,
        currentCount - landings.length
      );
    }
  }, [state.page, state.takeAmount, landings.length, state.searchTerm]);

  useEffect(() => {
    setLandingsTableData(
      landings?.slice(
        state.page * state.takeAmount,
        state.page * state.takeAmount + state.takeAmount
      )
    );
  }, [state.page, state.takeAmount, landings]);

  const toggleCreateDialog = () => {
    dispatch({
      type: "openCreateDialog",
      payload: !state.openCreateDialog,
    });
  };

  const getLandingTemplates = async (skip: number, take: number) => {
    if (
      !templatesCount ||
      (state.openCreateDialog && templates.length < templatesCount && !loading)
    ) {
      await getTemplates("", skip, take, TemplateType.Landing);
    }
  };

  const handleUpdate = (landing: LandingMetaModel) => {
    const newLanding: LandingMetaModel = {
      ...landing,
      IsPublished: !landing.IsPublished,
    };
    updateLanding(newLanding);
  };

  const columns: Column<LandingMetaModel>[] = useMemo(
    () => [
      {
        name: "Active",
        accessor: "IsPublished",
        format: (data) => (data ? <CheckCircle color="secondary" /> : ""),
      },
      {
        name: "Title",
        accessor: "Title",
        // format: (data) => `${data.Title}\n (${data.UniqueName})`
      },
      {
        name: "Copy",
        icon: Icon.Copy,
        callback: (data) => handleCloneLanding(data.Id),
      },
      {
        name: "Status",
        icon: Icon.Disable,
        format: (data) => (data.IsPublished ? Icon.Disable : Icon.Public),
        callback: (data) => {
          handleUpdate(data);
        },
      },
      {
        name: "Open",
        icon: Icon.Link,
        callback: (data) => {
          window.open(`/landing/${data.UniqueName}/${user?.Language || userActions.getLanguage()}`, "_blank");          
        },
      },
      {
        name: "Edit",
        icon: Icon.Edit,
        callback: (data) => navigate(data.Id),
      },
      {
        name: "Delete",
        icon: Icon.Delete,
        callback: (data) => handleDeleteLanding(data.Id),
      },
    ],
    []
  );

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item sm={8} xs={12}>
        <TextField
          placeholder="Search by landing title"
          fullWidth
          onChange={handleSearchTermChange}
        />
      </Grid>
      <Grid item md={8} sm={10} xs={12}>
        <AdminTable
          count={landingsCount}
          data={landingsTableData}
          columns={columns}
          loading={loading}
          pagination="server"
          onPaginationChange={changePagination}
          headerButton={
            <Grid container justifyContent="flex-start" item sm={8} xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={toggleCreateDialog}
              >
                <Add />
              </Button>
            </Grid>
          }
        />
      </Grid>
      <CreateLandingDialog
        open={state.openCreateDialog}
        templates={templates}
        handleClose={toggleCreateDialog}
        createLanding={createLanding}
        getLandingTemplates={getLandingTemplates}
        clearTemplates={clearTemplates}
      />
    </Grid>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  landings: state.adminLanding.landings,
  landingsCount: state.adminLanding.landingsCount,
  templates: state.adminLanding.templates,
  templatesCount: state.adminLanding.templatesCount,
  loading: state.adminLanding.loading,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  createLanding: (title: string, uniqueName: string, templateId?: string) =>
    dispatch(createLanding(title, uniqueName, templateId)),
  getLandings: (searchTerm = "", skip?: number, take?: number) =>
    dispatch(getLandings(searchTerm, skip, take)),
  getTemplates: (
    searchTerm = "",
    skip: number,
    take: number,
    type?: TemplateType
  ) => dispatch(getTemplates(searchTerm, skip, take, type)),
  deleteLanding: (landingId: string) => dispatch(deleteLanding(landingId)),
  clearLandings: () => dispatch(clearLandings()),
  clearTemplates: () => dispatch(clearTemplates()),
  updateLanding: (landing: LandingMetaModel) =>
    dispatch(updateLanding(landing)),
  cloneLanding: (landingId: string) => dispatch(cloneLanding(landingId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type LandingsPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(LandingsPage);
