import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { GroupMembersDto } from "../../../definitions/model/Group";
import SameSizeContainer from "../../Base/SameSizeContainer";

type BaseMoveOutPartProps = {
  family?: GroupMembersDto;
  hasCo2: boolean;
  onCloseClick: () => void;
  onOkClick: () => void;
};

const BaseMoveOutPart = (props: BaseMoveOutPartProps): JSX.Element => {
  const { t } = useTranslation("translation");

  return (
    <Grid container justifyContent="center">
      <Grid item md={9} sm={10} xs={12}>
        <Typography paragraph>{t("MoveOutDialog.Header")}</Typography>
        {props.family && props.family?.Members.length == 1 && props.hasCo2 && (
          <Typography paragraph>
            <Trans
              components={{ sub: <sub /> }}
              i18nKey="MoveOutDialog.Co2PlanMessage"
            />
          </Typography>
        )}
        <Box mt={10}>
          <SameSizeContainer gap={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={props.onOkClick}
              sx={{ maxWidth: "none" }}
            >
              {t("General.Buttons.Yes")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={props.onCloseClick}
              sx={{ maxWidth: "none" }}
            >
              {t("General.Buttons.GoBack")}
            </Button>
          </SameSizeContainer>
        </Box>
      </Grid>
    </Grid>
  );
};

export default BaseMoveOutPart;
