import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { GrapesEditor, GrapesTypes, LoadComponentOptions } from "../../defenitions";
import { Grid, ThemeProvider, StyledEngineProvider } from "@mui/material";
import { Provider } from "react-redux";
import store from "../../../../reducers/store";
import { getOptionalTheme } from "../../../../constants/theme";
import ContactMeBox, { DatePickerType } from "../../../Base/ContactMeBox";
import { useLanding } from "../../../../actions/landing.actions";
import { isReactComponent } from ".";
import { PreviousRouteProvider } from "../../../../routes/PreviouseRouteContext";
import { BrowserRouter } from "react-router-dom";
import { Dictionary } from "lodash";
import { useUser } from "../../../../actions/user.actions";
import { convertType } from "../../../../pages/ClimatePlan/shared/co2-helpers";
import AdminService from "../../../../services/admin/admin.service";
import { SystemStringType } from "../../../../definitions/model/SystemString";

type LeadFormState = {
  name: string;
  phone: string;
  userId: string;
  loading: boolean;
  metadata?: Dictionary<unknown>;
  utmSource: string;
};

type LeadsFormProps = {
  utmSource: string;
  datePickerType?: DatePickerType;
  buttonText?: string;
  ignoreUser?: boolean;
};

export const LeadForm = (props: LeadsFormProps): JSX.Element => {
  const [landingState, landingActions] = useLanding();
  const initialValues: LeadFormState = {
    name: "",
    phone: "",
    userId: "",
    loading: true,
    utmSource: props.utmSource ?? `myhouse.landing.${landingState.landing?.UniqueName}`,
  };
  const [state, dispatch] = useState(initialValues);
  const [{ user }] = useUser();
  const datePickerType = convertType(props.datePickerType);
  const buttonText = convertType(props.buttonText);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const payload = { ...initialValues, loading: false, metadata: paramsToObject(params) };

    if (!props.ignoreUser) {
      payload.name = user?.Name ?? "";
      payload.phone = user?.Phone ?? "";
      payload.userId = user?.Id ?? "";
    }

    if (params.has("email")) {
      landingActions.getUserByEmail(params.get("email") as string).then((data) => {
        if (data) {
          payload.userId = data.Id;
          payload.name = data.Name;
          payload.phone = data.Phone;
        }

        dispatch(payload);
      });
    } else {
      dispatch(payload);
    }
    return () => {};
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        {!state.loading && (
          <ContactMeBox
            initialValues={state}
            utmSource={state.utmSource}
            contactButtonText={buttonText}
            datePickerType={datePickerType}
            addLead={landingActions.addLead}
            loading={landingState.loading}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default function (editor: GrapesEditor, options: LoadComponentOptions, landingTheme?: string): void {
  const domComponents = editor.DomComponents;
  const blockManager = editor.BlockManager;
  const defaultType = domComponents.getType("default");
  const { model, view } = defaultType;
  const compName = "LeadForm";

  blockManager.add(GrapesTypes.LeadFormComponent, {
    label: "Leads form",
    category: options.categoryLabel,
    attributes: { class: "fa fa-user-plus" },
    content: `<div data-gjs-type="${GrapesTypes.LeadFormComponent}"></div>`,
  });

  const traits = [
    {
      type: "select",
      label: "Utm Source",
      name: "utmSource",
      changeProp: 1,
      typeid: 0,
      options: [],
    },
    {
      label: "Button title",
      name: "btnText",
      changeProp: 1,
    },
    {
      type: "checkbox",
      label: "Ignore logged user",
      name: "ignoreUser",
      changeProp: 1,
    },
    {
      label: "Date Picker",
      name: "datePickerType",
      changeProp: 1,
      type: "select",
      options: [
        { id: "", name: "hide" },
        { id: "date", name: "date" },
        { id: "datetime-local", name: " date & time" },
      ],
    },
  ];

  const subscriptions = traits.map((trait) => trait.name);

  const getUtmSources = async () => {
    const communes = (await AdminService.getSystemStrings(SystemStringType.UtmSource)).map((utmSource) => ({
      id: utmSource.Value,
      name: utmSource.Value,
    }));

    return communes;
  };

  domComponents.addType(GrapesTypes.LeadFormComponent, {
    isComponent(el: HTMLElement) {
      if (
        (el.getAttribute && el.getAttribute("data-gjs-type") === GrapesTypes.LeadFormComponent) ||
        (el.attributes && (el.attributes as any)["data-gjs-type"] === GrapesTypes.LeadFormComponent) ||
        isReactComponent(el, compName)
      ) {
        return {
          type: GrapesTypes.LeadFormComponent,
        };
      }
    },
    model: {
      defaults: {
        ...model.prototype.defaults,
        droppable: false,
        utmSource: "",
        datePickerType: "",
        btnText: undefined,
        ignoreUser: false,
        traits: [...model.prototype.defaults.traits, ...traits],
      },
    },
    view: view.extend({
      init(e: any) {
        getUtmSources().then((res) => {
          e.model.getTrait("utmSource").set("options", res);
        });

        subscriptions.forEach((subscription) => {
          this.listenTo(this.model, `change:${subscription}`, this.handleChanges);
        });
      },

      handleChanges() {
        const root = ReactDOM.createRoot(this.el);
        root.unmount();
        this.render();
      },

      onRender() {
        const { el } = this;
        const comps = this.model.get("components");
        const { utmSource, datePickerType, btnText, ignoreUser } = this.model.attributes;
        comps.reset();
        const compString = `<${compName} 
          utmSource="${utmSource}" 
          datePickerType="${datePickerType}"
          buttonText="${btnText}"
          ignoreUser="${ignoreUser}"
        ></${compName}>`;
        comps.add(compString);
        const root = ReactDOM.createRoot(el);

        root.render(
          <>
            <StyledEngineProvider>
              <ThemeProvider theme={getOptionalTheme(landingTheme)}>
                <Provider store={store}>
                  <BrowserRouter>
                    <PreviousRouteProvider>
                      <LeadForm utmSource={utmSource} datePickerType={datePickerType} buttonText={btnText} ignoreUser={ignoreUser}></LeadForm>
                    </PreviousRouteProvider>
                  </BrowserRouter>
                </Provider>
              </ThemeProvider>
            </StyledEngineProvider>
          </>
        );
      },
    }),
  });
}

const paramsToObject = (params: URLSearchParams) => {
  const obj: Dictionary<unknown> = {};
  for (const pair of params.entries()) {
    obj[pair[0]] = pair[1];
  }
  return obj;
};
