import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ApplicationState } from "../../reducers/store";
import { CustomWorkerRequest, WorkerModel } from "../../definitions/model/Worker";
import { AdminCraftsmanActionTypes } from "../../constants/admin/admin.craftsman.actiontypes";
import WorkersService from "../../pages/Workers/workers.service";
import { operationFailedActionGeneral } from "..";
import { AppAction } from "../../definitions/Action";

type AdminCraftsmanState = {
  craftsmen: { Results: WorkerModel[]; Count: number };
  loading: boolean;
};

type AdminCraftsmanActions = {
  getCraftsmen: (customWorkerRequest: CustomWorkerRequest, skip: number, take: number) => Promise<void>;
  toggleCraftsmanGreenTag: (craftsmanId: string) => Promise<void>;
};

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, AdminCraftsmanActionTypes.CRAFTSMAN_ACTION_FAILED);
};

export const useAdminCraftsman = (): [AdminCraftsmanState, AdminCraftsmanActions] => {
  const craftsmen = useSelector<ApplicationState, AdminCraftsmanState>((state) => ({
    craftsmen: state.admin.craftsmen,
    loading: state.admin.loading,
  }));

  const dispatch: ThunkDispatch<ApplicationState, any, AnyAction> = useDispatch();

  return [
    craftsmen,
    {
      getCraftsmen: (customWorkerRequest: CustomWorkerRequest, skip: number, take: number) =>
        dispatch(getCraftsmen(customWorkerRequest, skip, take)),
      toggleCraftsmanGreenTag: (craftsmanId: string) => dispatch(toggleCraftsmanGreenTag(craftsmanId)),
    },
  ];
};

const getCraftsmen =
  (customWorkerRequest: CustomWorkerRequest, skip: number, take: number) =>
  async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    dispatch({ type: AdminCraftsmanActionTypes.GET_CRAFTSMEN });
    try {
      const craftsmen = await WorkersService.getWorkers(customWorkerRequest, skip, take);
      dispatch({
        type: AdminCraftsmanActionTypes.GET_CRAFTSMEN_SUCCEEDED,
        payload: craftsmen,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const toggleCraftsmanGreenTag =
  (craftsmanId: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    dispatch({ type: AdminCraftsmanActionTypes.TOGGLE_CRAFTSMAN_GREENTAG });
    try {
      const craftsmen = await WorkersService.toggleCraftsmanGreenTag(craftsmanId);
      dispatch({
        type: AdminCraftsmanActionTypes.TOGGLE_CRAFTSMAN_GREENTAG_SUCCEEDED,
        payload: craftsmanId,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };
