import { CompanyTestimonialModel } from "../definitions/Company";
import ApiService from "./api.service";

const CompanyTestimonialService = {
  getCompanyTestimonials: async (
    companies: string[],
    skip: number,
    take: number
  ): Promise<CompanyTestimonialModel[]> => {
    const companiesParams = companies.map((t) => "&companies=" + t).join("");
    const url = `/api/admin/company/testimonials?skip=${skip}&take=${take}${companiesParams}`;
    let result = await ApiService.get<CompanyTestimonialModel[]>(url);
    result = result.map((ad) => ({
      ...ad,
    }));
    return result;
  },

  addCompanyTestimonial: async (testimonial: CompanyTestimonialModel): Promise<CompanyTestimonialModel> => {
    const data = {
      Testimonial: testimonial,
    };
    const url = `/api/admin/company/${testimonial.CompanyId}/testimonials/`;
    let result = await ApiService.post<CompanyTestimonialModel>(url, data);
    result = {
      ...result,
    };
    return result;
  },

  editCompanyTestimonial: async (testimonial: CompanyTestimonialModel): Promise<CompanyTestimonialModel> => {
    const data = {
      Testimonial: testimonial,
    };
    const url = `/api/admin/company/${testimonial.CompanyId}/testimonials/`;
    let result = await ApiService.put<CompanyTestimonialModel>(url, data);
    result = {
      ...result,
    };
    return result;
  },

  deleteCompanyTestimonial: async (companyId: string, testimonialId: string): Promise<boolean> => {
    const url = `/api/admin/company/${companyId}/testimonials/${testimonialId}`;
    await ApiService.delete(url);
    return true;
  },
};

export default CompanyTestimonialService;
