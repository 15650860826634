import React, { ChangeEvent, useReducer, useEffect, useMemo } from "react";
import { Grid, Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import MTextField from "../../../../components/Base/MyhouseTextField";
import User from "../../../../definitions/model/User";
import { AppThunkDispatch } from "../../../../definitions/Action";
import { UserType } from "../../../../constants/enums";
import EditAdminDialog from "./EditAdminDialog";
import { ApplicationState } from "../../../../reducers/store";
import { connect, ConnectedProps } from "react-redux";
import {
  getAdminUsers,
  updateUserRole,
} from "../../../../actions/admin/admin.actions";
import {
  AdminTable,
  Column,
  Icon,
} from "../../components/AdminTable/AdminTable";

type AdminState = {
  searchString: string;
  selectedAdmin: User | null;
  editDialogShown: boolean;
  editDialogAddMode: boolean;
};

const initialState: AdminState = {
  searchString: "",
  selectedAdmin: null,
  editDialogShown: false,
  editDialogAddMode: true,
};

const reducer = (state: AdminState, action: { type: string; payload: any }) => {
  return { ...state, [action.type]: action.payload };
};

const AdministratorsPage = (props: AdministratorsPageConnectedProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const columns: Column<User>[] = useMemo(
    () => [
      {
        name: "Email",
        accessor: "Email",
      },
      {
        name: "Role",
        accessor: "Role",
        format: (data) => UserType[data],
      },
      {
        name: "Edit",
        icon: Icon.Edit,
        callback: (user) => handleEdit(user),
      },

      {
        name: "Delete",
        icon: Icon.Delete,
        callback:(user) => handleDelete(user),
      },
    ],
    []
  );

  useEffect(() => {
    if (!props.administrators || !props.administrators.length) {
      props.getAdminUsers();
    }
  }, [props.administrators?.length]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: "searchString", payload: event.target.value });

  const handleEdit = (admin: User) => {
    dispatch({ type: "selectedAdmin", payload: admin });
    dispatch({ type: "editDialogShown", payload: true });
    dispatch({ type: "editDialogAddMode", payload: false });
  };

  const handleAdd = () => {
    dispatch({ type: "selectedAdmin", payload: null });
    dispatch({ type: "editDialogShown", payload: true });
    dispatch({ type: "editDialogAddMode", payload: true });
  };

  const handleEditDialogSave = (
    id: string,
    role: UserType,
    communeNumber: number
  ) => {
    props.updateUserRole(id, role, communeNumber);
    dispatch({ type: "editDialogShown", payload: false });
  };

  const handleDelete = (admin: User) => {
    props.updateUserRole(admin.Id, UserType.User, 0);
    if (props.currentUser?.Id === admin.Id) {
      //UserService.logout(); // user will remain admin until log off
      // window.location.href =
      //   "https://giphy.com/gifs/thumbs-up-arnold-schwarzenegger-hand-on-fire-7TtvTUMm9mp20/fullscreen";
    }
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid container spacing={3} item sm={10} xs={12}>
          <Grid item container>
            <MTextField
              fullWidth
              placeholder="Search by title"
              onChange={handleChange}
            />
          </Grid>

          <Grid item container>
            <AdminTable
              data={props.administrators.filter((admin) =>
                admin.Email.toLocaleLowerCase().includes(
                  state.searchString.toLowerCase()
                )
              )}
              columns={columns}
              pagination={"client"}
              headerButton={
                <Grid container item justifyContent="flex-start">
                  <Button color="primary" variant="contained">
                    <Add onClick={() => handleAdd()} />
                  </Button>
                </Grid>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <EditAdminDialog
        open={state.editDialogShown}
        email={state.selectedAdmin?.Email ?? ""}
        id={state.selectedAdmin?.Id ?? ""}
        role={state.selectedAdmin?.Role ?? UserType.ExternalManager}
        communeNumber={state.selectedAdmin?.CommuneNumber ?? 0}
        addAdminMode={state.editDialogAddMode}
        handleClose={() =>
          dispatch({ type: "editDialogShown", payload: false })
        }
        handleSave={handleEditDialogSave}
      ></EditAdminDialog>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  administrators: state.admin.administrators,
  currentUser: state.user.user,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getAdminUsers: () => dispatch(getAdminUsers()),
  updateUserRole: (id: string, role: UserType, communeNumber: number) =>
    dispatch(updateUserRole(id, role, communeNumber)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type AdministratorsPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(AdministratorsPage);
