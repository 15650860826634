import { format } from "date-fns";

export const getColor = (value: string) => {
    switch (value) {
        case "Boligområde":
            return "#cc6700";
        case "Område til offentlige formål":
            return "#fd6160";
        case "Rekreativt område":
            return "#3ffd3f";
        case "Erhvervsområde":
            return "#6efefe";
        case "Blandet bolig og erhverv":
            return "#eef103";
        case "Centerområde":
            return "#fc6efb";
        case "Tekniske anlæg":
            return "#4c898e";
        // case 7:
        //     return "#ce2819";
        default:
            return "#9e9e9e";
    }
};

export const createFeatureFromGeoJson = (map: any, maps: any, colorFunc: any, colorField: string, item: any, getMessageText: any) => {
    const feature = new maps.Data();
    feature.addGeoJson({ type: "Feature", geometry: JSON.parse(item.GeoJson) });
    feature.setStyle({
        strokeColor: colorFunc(colorField),
        strokeOpacity: 0.9,
        strokeWeight: 2,
        fillColor: colorFunc(colorField),
        fillOpacity: 0.7
    });
    feature.data = item;
    feature.addListener(
      "click",
      function (event: any) {
        // show something for a polygon
        getMessageText(item);
      }
    );

    return feature;
};

export const mapStyles =  [
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ];

  
export const formatDateToString = (date: Date | string | number | null) =>
  date ? format(new Date(date), "yyyy-MM-dd") : null;
export const formatStringToDate = (date: Date | string | null) => (date ? new Date(date) : null);