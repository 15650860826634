import React, { useEffect } from "react";
import { Typography, Container, List, Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../components/Layout/MainContainer";
import { useServicebookWorker } from "./workers.actions";
import { LinkRequestState, CraftsmanType } from "../../definitions/model/Worker";
import WorkersGroup from "./components/workers-group";
import WorkerLinkRequestItem from "./components/worker-link-request";
import DetailsSkeleton from "../../components/Base/DetailsSkeleton";
import { useTranslation } from "react-i18next";
import { useUnit } from "../../actions";

const UnitWorkersPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const [
    { unitWorkersLoaded, unitWorkers, workerRequests, loading },
    { getUnitWorkers, getWorkerLinkRequests, processWorkerLinkRequest },
  ] = useServicebookWorker();
  const [{ unit }] = useUnit();

  useEffect(() => {
    const getAsync = async () => {
      if (unit) {
        await getWorkerLinkRequests(unit.Id);
        if (!unitWorkersLoaded) {
          await getUnitWorkers(unit.Id);
        }
      }
    };
    getAsync();
    return () => {};
  }, [unit?.Id]);

  const processRequest = (workerId: string, unitId: string, state: LinkRequestState) => {
    processWorkerLinkRequest(workerId, unitId, state).then(() => {
      getUnitWorkers(unitId);
      getWorkerLinkRequests(unitId);
    });
  };

  return (
    <MainContainer title={t("ServiceBook.Workers.MyCraftsmen") ?? ""}>
      <Container maxWidth="md">
        <Typography variant="body1">
          {" "}
          {unitWorkersLoaded && !loading && !unitWorkers.length
            ? t("ServiceBook.Workers.NoUnitWorkers")
            : t("ServiceBook.Workers.YourWorkers")}{" "}
        </Typography>
        {/* {!workerRequestsLoaded ? (
          <List disablePadding>
            {new Array(5).fill(0).map((_, idx) => (
              <WorkerSkeleton key={idx} />
            ))}
          </List>
        ) : ( */}
        {/* <> */}
        {!!workerRequests.length &&
          workerRequests.map((x) => <WorkerLinkRequestItem key={x.Id} {...x} processRequest={processRequest} />)}
        {/* </>
        )} */}
        {!unitWorkersLoaded || loading ? (
          <List disablePadding>
            {new Array(5).fill(0).map((_, idx) => (
              <DetailsSkeleton key={idx} />
            ))}
          </List>
        ) : (
          <>
            {unit && !!unitWorkers.length &&
              CraftsmanType.filter((type) => type.key !== 200).map((type, i) => (
                <WorkersGroup
                  key={i}
                  workers={unitWorkers.filter((w) => w.Competencies.includes(type.key))}
                  title={t(type.value)}
                  mode="unit"
                  targetId={unit.Id}
                />
              ))}
            <Grid item xs={12} container justifyContent="center" alignItems="center">
              <Grid item>
                <Button variant="contained" color="secondary" onClick={() => navigate("/workers/find")}>
                  {t("ServiceBook.Workers.FindCraftsman")}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </MainContainer>
  );
};

export default UnitWorkersPage;
