import React from "react";
import { Box, Avatar, styled} from "@mui/material";
import { useTranslation } from "react-i18next";
import PopperMenu from "../Layout/PopperMenu";

const Items = styled("div")(({ theme }) => ({
  height: "100%",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  "& span": {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const Circle = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  width: 36,
  height: 36,
  borderRadius: "50%",
  margin: "0 8px 0 5px",
  background: theme.palette.primary.dark,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    width: 26,
    height: 26,
    fontSize: 12,
  },
}));

type Props = {
  avatarUrl: string | any;
};

const UserInfo = ({ avatarUrl }: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation("translation");

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        "& ul": {
          padding: 0,
        },
      }}
    >
      <Items onClick={(event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget)}>
        <Box component="span"> {t("UserProfile.MyProfile")}</Box>
        <Circle>
          <Avatar src={avatarUrl} />
        </Circle>
      </Items>

      <PopperMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} paperProps={{ sx: { marginRight: "35px" } }} />
    </Box>
  );
};

export default UserInfo;
