import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  DialogTitle,
  Grid,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";

type RatingDialogProps = {
  existingRating: number | null;
  existingComment: string | null;
  showComment: boolean;
  open: boolean;
  objectName?: string;
  handleOk: (rating: number, comment: string | null) => void;
  handleClose: () => void;
};

const RatingDialog = (props: RatingDialogProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const [rating, setRating] = useState<number | null>(null);
  const [comment, setComment] = useState<string | null>(null);
  useEffect(() => {
    setRating(props.existingRating);
  }, [props.existingRating]);
  useEffect(() => {
    setComment(props.existingComment);
  }, [props.existingComment]);

  useEffect(() => {
    if (!props.open) {
      setRating(null);
    }
  }, [props.open]);

  const handleOkClick = () => {
    if (rating !== null) {
      props.handleOk(rating, comment);
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">
        {t("Base.Dialog.Assess")} {props.objectName ?? ""}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Rating
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
          {props.showComment && (
            <TextField
              label={t("Base.Dialog.Comment")}
              multiline={true}
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              InputProps={{
                sx: {
                  backgroundColor: "transparent",
                  lineHeight: "130%",
                },
              }}
            ></TextField>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleOkClick}
          disabled={!rating}
          variant="contained"
          color="secondary"
        >
          {t("General.Buttons.Ok")}
        </Button>
        <Button
          onClick={props.handleClose}
          variant="contained"
          color="secondary"
        >
          {t("General.Buttons.Undo")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RatingDialog;
