import React from "react";
import {
  Box,
  Grid,
  Button,
  Divider,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment, styled,
} from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useForm, Controller } from "react-hook-form";

import {
  RecurringInterval,
  PriceType,
} from "../../../../../definitions/model/Subscriptions";

export type PriceFormData = {
  RecurringInterval: RecurringInterval;
  Type: PriceType;
  Amount: number;
};

type PriceFormProps = {
  onSubmit: (values: PriceFormData) => void;
  handleCloseForm: () => void;
};

type PriceTypeValues = {
  text: string;
  value: PriceType;
};

type RecurringIntervalValues = {
  text: string;
  value: RecurringInterval;
};

const PriceTypeValues: PriceTypeValues[] = [
  {
    text: "One time",
    value: "one_time",
  },
  {
    text: "Recurring",
    value: "recurring",
  },
];

const RecurringIntervalValues: RecurringIntervalValues[] = [
  {
    text: "Day",
    value: "day",
  },
  {
    text: "Week",
    value: "week",
  },
  {
    text: "Month",
    value: "month",
  },
  {
    text: "Year",
    value: "year",
  },
];

const StyledToggleButton = styled(ToggleButton)({
    padding: "5px 11px",
    textTransform: "none",
    fontSize: 13,
    "&$selected": {
      background: "#54602c",
      color: "white",
    },
});

const PriceForm = ({
  onSubmit,
  handleCloseForm,
}: PriceFormProps): JSX.Element => {
  const methods = useForm<PriceFormData>({
    defaultValues: {
      RecurringInterval: RecurringIntervalValues[3].value,
      Type: PriceTypeValues[0].value,
      Amount: 0,
    },
  });

  const { handleSubmit, control } = methods;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box my={3}>
        <Grid item>
          <Typography variant="h4">Product price</Typography>
        </Grid>
        <Divider />
      </Box>
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <Controller 
            name="Amount" 
            control={control}
            render={({ field: {onChange} }) => (
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Price"
                type="number"
                onChange={e => onChange(+e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">DKK</InputAdornment>
                  ),
                }}
              />
            )}
            rules={{required: true}}
          />
        </Grid>
        <Grid container item alignItems="center" sm={6} xs={12}>
          <Controller
            name="Type"
            control={control}
            render={({ field }) => (
              <ToggleButtonGroup {...field} exclusive>
                {PriceTypeValues.map((type) => (
                  <StyledToggleButton key={type.value} value={type.value}>
                    {type.text}
                  </StyledToggleButton>
                ))}
              </ToggleButtonGroup>
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel>Billing period</InputLabel>
            <Controller
              name="RecurringInterval"
              control={control}
              render={({ field }) => (
                <Select {...field}>
                  {RecurringIntervalValues.map((interval) => (
                    <MenuItem
                      key={interval.value}
                      value={interval.value}
                      style={{ padding: 10 }}
                    >
                      {interval.text}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Button variant="contained" color="secondary" onClick={handleCloseForm}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="secondary">
          Create price
        </Button>
      </Grid>
    </form>
  );
};

export default PriceForm;
