import React, { useContext, useEffect, useMemo, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, styled, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { CO2EmissionSourceGroup } from "../../CO2Plan";
import { ClimatPlanWidgetModel } from "../../ClimatePlanPage";
import { CO2Context } from "../CO2ContextWrapper";

type CO2TargetESGWidgetsProps = {
  group: CO2EmissionSourceGroup;
};

// ESG means Emission Source Group
const CO2TargetESGWidgetsComponent = (props: CO2TargetESGWidgetsProps): JSX.Element => {
  const { widgets, renderWidget, WidgetPage } = useContext(CO2Context);
  const widgetsToUse = useMemo(() => {
    return widgets?.filter((c) => c.Pages.includes(WidgetPage));
  }, [widgets]);

  const { t } = useTranslation("translation");
  const [groupWidgets, setGroupWidgets] = useState<ClimatPlanWidgetModel[]>([]);


  useEffect(() => {
    if (widgetsToUse) {
      const widgets = widgetsToUse.filter(
        (widget) => props.group.Id.toLocaleLowerCase() === widget.Tag?.toLocaleLowerCase() && widget.Position === 4 // 4 is a stream position
      );
      setGroupWidgets(widgets);
    }
  }, [props.group, widgetsToUse]);

  return (
      <Grid container item xs={12} md={8} mt={3}>
        {!!groupWidgets.length && (
          <>
           <Typography sx={{position: "relative", height: 0, top: "20px"}} variant="body2">{t("ClimatePlan.ShowGroupWidgets")}</Typography>
            {groupWidgets.map((widget) => (
              <StyledAccordion
                key={widget.Id}
                defaultExpanded
                elevation={0}
                sx={{ width: "100%" }}
              >
                <StyledAccordionSummary expandIcon={<ExpandMore color="inherit" />}>            
                </StyledAccordionSummary>
                <StyledAccordionDetails sx={{ padding: 0 }}>
                  <Grid container item direction="column" spacing={1} mb={7}>
                    <Grid item>
                      <Box minWidth={275}>{renderWidget && renderWidget(widget)}</Box>
                    </Grid>
                  </Grid>
                </StyledAccordionDetails>
              </StyledAccordion>
            ))}
          </>
        )}
      </Grid>
  );
};

export default CO2TargetESGWidgetsComponent;

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,
  "&.MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.divider,
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: 0,
  "&.MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.divider,
  },
  "& > div > div > div > .MuiPaper-root.MuiPaper-outlined.MuiCard-root": {
    border: "none !important",
  },
}));

const StyledAccordion = styled(Accordion)({
  marginBottom: 10,
  width: "100%",
  "&:last-of-type": {
    borderRadius: 10,
  },
  "&:first-of-type": {
    borderRadius: 10,
  },
  "&.MuiPaper-root.MuiAccordion-root": {
    backgroundColor: "transparent",
  },
  "&.MuiAccordionDetails-root": {
    padding: 0,
  },
});
