import { Grid, IconButton, Skeleton, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Autoplay, Navigation } from "swiper";
import { theme } from "../../../constants/theme";
import { MenuListItem, menuClick } from "../../Overview/Components/MenuBlockList";
import { useUnit, useUser } from "../../../actions";
import { useNavigate } from "react-router-dom";
import { NavigationItem } from "../../../definitions/Menu";
import { GetTranslatedText } from "../../../helpers/translation-helper";
import "swiper/css";
import "swiper/css/navigation";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styled } from "@mui/system";
import { useBuilding } from "../../../actions/building.actions";

export type Co2SliderItem = {
  title: string;
  background: string;
  targetUrl: string;
  orderNumber: number;
};

type SliderListProps = {
  navigationItems: NavigationItem[] | null;
  co2Items?: Co2SliderItem[];
};

const StyledGrid = styled(Grid)(() => ({
  "& .swiper-button-disabled": {
    opacity: 0,
    cursor: "default",
  },
  "& .wrapper-centred": {
    justifyContent: "center",
  },
}));

const MenuListSlider = ({ navigationItems, co2Items }: SliderListProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [{ unit }] = useUnit();
  const [{ user, authUserRating }, { openLoginDialog, setAuthDestination }] = useUser();
  const [{ building }] = useBuilding();
  const navigate = useNavigate();
  const sliderRef = useRef<SwiperRef>(null);
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const isMd = useMediaQuery(theme.breakpoints.down("lg"));
  const isLg = useMediaQuery(theme.breakpoints.down("xl"));

  useEffect(() => {
    if (!navigationItems) {
      setLoading(true);
    }
    setLoading(false);
  }, [navigationItems, user]);

  const sliderItemClick = (item: NavigationItem) => {
    if (user) {
      menuClick(item.TargetUrl, item.UnitSensitive, unit, building, navigate);
      return;
    }
    setAuthDestination(false, item.TargetUrl, authUserRating);
    openLoginDialog(false);
  };

  const co2SliderItemClick = (item: Co2SliderItem) => {
    if (user) {
      navigate(item.targetUrl);
      return;
    }

    setAuthDestination(false, item.targetUrl, authUserRating);
    openLoginDialog(false);
  };

  useEffect(() => {
    SwiperCore.use([Autoplay, Navigation]);
  }, []);

  const slidesPerView = isXs ? 1 : isSm ? 2 : isMd ? 3 : 5;
  const slidesCount = navigationItems?.length || 0;

  return (
    <>
      {!loading && (
        <>
          <StyledGrid item container xs={12} lg={11} alignItems="center" sx={{ flexWrap: "nowrap" }} my={1}>
            <IconButton className="arrow-left">
              <ArrowBackIosNewIcon />
            </IconButton>
            <Swiper
              style={{
                width: "100%",
              }}
              navigation={{ prevEl: ".arrow-left", nextEl: ".arrow-right" }}
              modules={[Navigation]}
              ref={sliderRef}
              speed={1500}
              watchOverflow={true}
              onTouchStart={(swiper) => {
                swiper.params.speed = 300;
              }}
              onNavigationNext={(swiper) => {
                swiper.params.speed = 300;
              }}
              onNavigationPrev={(swiper) => {
                swiper.params.speed = 300;
              }}
              loop={slidesCount > slidesPerView + 2}
              autoplay={{ delay: 4000 }}
              slidesPerView={slidesPerView}
              wrapperClass={slidesPerView >= slidesCount ? "wrapper-centred" : ""}
            >
              {navigationItems &&
                navigationItems.map((item) => (
                  <SwiperSlide key={item.OrderNumber}>
                    <Grid justifyContent="center" container>
                      <MenuListItem
                        fullWidth
                        key={item.OrderNumber}
                        onClick={() => sliderItemClick(item)}
                        title={GetTranslatedText(item.Titles)}
                        background={item.BackgroundImageUrl}
                        isPublished={item.IsPublished}
                        targetUrl={item.TargetUrl}
                      />
                    </Grid>
                  </SwiperSlide>
                ))}
              {co2Items &&
                co2Items.map((item) => (
                  <SwiperSlide key={item.orderNumber}>
                    <Grid justifyContent="center" container>
                      <MenuListItem
                        fullWidth
                        key={item.orderNumber}
                        title={item.title}
                        background={item.background}
                        isPublished={true}
                        targetUrl={item.targetUrl}
                        onClick={() => co2SliderItemClick(item)}
                      />
                    </Grid>
                  </SwiperSlide>
                ))}
            </Swiper>
            <IconButton className="arrow-right">
              <ArrowForwardIosIcon />
            </IconButton>
          </StyledGrid>
        </>
      )}
      {loading && (
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <Skeleton width="300px" height="200px"></Skeleton>
          </Grid>
          {!isSm && (
            <Grid item>
              <Skeleton width="300px" height="200px"></Skeleton>
            </Grid>
          )}
          {!isMd && (
            <Grid item>
              <Skeleton width="300px" height="200px"></Skeleton>
            </Grid>
          )}
          {!isLg && (
            <>
              <Grid item>
                <Skeleton width="300px" height="200px"></Skeleton>
              </Grid>
              <Grid item>
                <Skeleton width="300px" height="200px"></Skeleton>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default MenuListSlider;
