import ApiService from "../api.service";
import {
  SystemMessageModel,
  CreateSystemMessageContract,
  SystemMessageType,
  UpdateSystemMessageContract,
  SystemMessageReplacements,
} from "../../definitions/model/SystemMessage";
import { FormBuilderDTO, LandingModel, LandingType } from "../../definitions/Landing";

const SystemMessageService = {
  getMessageTemplate: async (messageId: string, withGrapesData = false): Promise<LandingModel> => {
    const url = `/api/admin/systemmessages/emails/${messageId}/meta?withGrapesData=${withGrapesData}`;
    const result = await ApiService.get<LandingModel>(url);
    return result;
  },
  getMessageEditor: async (id: string, type: LandingType): Promise<FormBuilderDTO> => {
    const url = `/api/admin/systemmessages/message/${id}/editor/${type}`;
    const result = await ApiService.get<FormBuilderDTO>(url);
    return result;
  },
  getSystemMessages: async (): Promise<SystemMessageModel[]> => {
    const url = `/api/admin/systemmessages`;
    const result = await ApiService.get<SystemMessageModel[]>(url);
    return result;
  },

  getSystemMessage: async (id: string): Promise<SystemMessageModel> => {
    const url = `/api/admin/systemmessages/${id}`;
    const result = await ApiService.get<SystemMessageModel>(url);
    return result;
  },

  getSystemMessageTypes: async (): Promise<SystemMessageType[]> => {
    const url = `/api/admin/systemmessages/types`;
    const result = await ApiService.get<SystemMessageType[]>(url);
    return result;
  },
  getSystemMessageMissingTypes: async (): Promise<SystemMessageType[]> => {
    const url = `/api/admin/systemmessages/types/missing`;
    const result = await ApiService.get<SystemMessageType[]>(url);
    return result;
  },
  addSystemMessage: async (
    name: string,
    messageSubject: string,
    type: number,
    domains: string[],
    toUser: boolean
  ): Promise<SystemMessageModel> => {
    const data: CreateSystemMessageContract = {
      Name: name,
      MessageSubject: messageSubject,
      Type: type,
      Domains: domains,
      ToUser: toUser,
    };

    const url = `/api/admin/systemmessages`;
    const result = await ApiService.post<SystemMessageModel>(url, data);
    return result;
  },
  sendTestMessage: async (
    recipient: string,
    type: SystemMessageType,
    replacements: SystemMessageReplacements[]
  ): Promise<void> => {
    const url = `/api/admin/systemmessages/test`;
    await ApiService.post<SystemMessageModel>(url, {
      Recipient: recipient,
      Type: type.Value,
      Replacements: replacements,
    });
  },
  editSystemMessage: async (
    messageId: string,
    name: string,
    messageSubject: string,
    domains: string[],
    toUser: boolean
  ): Promise<SystemMessageModel> => {
    const data: UpdateSystemMessageContract = {
      MessageId: messageId,
      Name: name,
      MessageSubject: messageSubject,
      Domains: domains,
      ToUser: toUser,
    };

    const url = `/api/admin/systemmessages/`;
    const result = await ApiService.put<SystemMessageModel>(url, data);
    return result;
  },

  deleteSystemMessage: async (id: string): Promise<boolean> => {
    const url = `/api/admin/systemmessages/${id}`;
    const result = await ApiService.delete<SystemMessageModel>(url);
    return !!result;
  },
};

export default SystemMessageService;
