import { Dispatch } from "redux";
import { AppAction } from "../../definitions/Action";

import { AdminActionTypes } from "../../constants/admin/admin.actiontypes";
import { operationFailedActionGeneral, useAppDispatch } from "..";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../reducers/store";
import { CompanyAdModel } from "../../definitions/Company";
import { AdminCompanyAdActionTypes } from "../../constants/admin/admin.company-ad.actiontypes";
import CompanyAdService from "../../services/company-ad.service";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, AdminActionTypes.ADMIN_OPERATION_FAILED);
};

export const getCompanyAds = (skip: number, take: number) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: AdminCompanyAdActionTypes.GET_COMPANY_ADS,
    });
    const result = await CompanyAdService.getCompanyAds(skip, take);
    dispatch({
      type: AdminCompanyAdActionTypes.GET_COMPANY_ADS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const addCompanyAd = (ad: CompanyAdModel) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: AdminCompanyAdActionTypes.ADD_COMPANY_AD,
    });
    const result = await CompanyAdService.addCompanyAd(ad);
    dispatch({
      type: AdminCompanyAdActionTypes.ADD_COMPANY_AD_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const editCompanyAd = (ad: CompanyAdModel) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: AdminCompanyAdActionTypes.EDIT_COMPANY_AD,
    });
    const result = await CompanyAdService.editCompanyAd(ad);
    dispatch({
      type: AdminCompanyAdActionTypes.EDIT_COMPANY_AD_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const deleteCompanyAd = (companyId: string, adId: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: AdminCompanyAdActionTypes.DELETE_COMPANY_AD,
    });
    await CompanyAdService.deleteCompanyAd(companyId, adId);
    dispatch({
      type: AdminCompanyAdActionTypes.DELETE_COMPANY_AD_SUCCEEDED,
      payload: adId,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const useCompanyAds = () => {
  const dispatch = useAppDispatch();
  const ads = useSelector((state: ApplicationState) => state.admin.companyAds);

  const actions = {
    getCompanyAds: (skip: number, take: number) => dispatch(getCompanyAds(skip, take)),
    addCompanyAd: (ad: CompanyAdModel) => dispatch(addCompanyAd(ad)),
    editCompanyAd: (ad: CompanyAdModel) => dispatch(editCompanyAd(ad)),
    deleteCompanyAd: (companyId: string, adId: string) => dispatch(deleteCompanyAd(companyId, adId)),
  };

  return { ads, actions };
};
