import React, { useState } from "react";
import { Button, Grid, LinearProgress, List, ListItem, ListItemText } from "@mui/material";
import BaseDialog from "../../../components/Dialogs/BaseDialog";
import { useTranslation } from "react-i18next";
import { OwnedBuildingDto } from "../../../definitions/model/Building";

type SelectCurrentBuildingDialogProps = {
  open: boolean;
  closeDialog: () => void;
  buildings: OwnedBuildingDto[];
  setCurrentBuilding: (buildingId: string) => Promise<void>;
};

const SelectCurrentBuildingDialog = ({
  open,
  closeDialog,
  setCurrentBuilding,
  buildings,
}: SelectCurrentBuildingDialogProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const [selected, setSelected] = useState("");
  const [isLoading, setLoading] = useState(false);

  const onListItemClick = (id: string) => () => setSelected(id);

  const onSelectClick = () => {
    setLoading(true);
    setCurrentBuilding(selected)
      .then(() => closeDialog())
      .finally(() => setLoading(false));
  };

  return (
    <BaseDialog
      titleComponent={t("HouseManagement.ChooseNewHouse") ?? ""}
      actionsComponent={
        <Button variant="contained" color="secondary" onClick={onSelectClick}>
          {t("General.Buttons.Choose")}
        </Button>
      }
      closeDialog={closeDialog}
      open={open}
    >
      <Grid container justifyContent="center">
        <Grid item lg={8} md={9} sm={10} xs={12}>
          <List>
            {buildings.map((b) => (
              <ListItem selected={selected === b.Id} key={b.Id} button onClick={onListItemClick(b.Id)}>
                <ListItemText
                  primary={`${b.Address.Street} ${b.Address.HouseNo}`}
                  secondary={`${b.Address.PostalCode} ${b.Address.Commune.CommuneName}`}
                />
              </ListItem>
            ))}
          </List>
          {isLoading && <LinearProgress />}
        </Grid>
      </Grid>
    </BaseDialog>
  );
};

export default SelectCurrentBuildingDialog;
