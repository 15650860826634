import React, { useEffect, ChangeEvent, useReducer, useMemo } from "react";
import { Grid, Tooltip, Button, Tabs, Tab } from "@mui/material";
import { Add } from "@mui/icons-material";
import MTextField from "../../../../components/Base/MyhouseTextField";

import { useAlertContext } from "../../../../components/Base";
import { useCompanies } from "../../../../actions/admin/admin.company.actions";
import CompanyCreationDialog from "./components/CompanyCreationDialog";
import CompanyEditDialog from "./components/CompanyEditDialog";
import RatingDialog from "../../../../components/Dialogs/RatingDialog";
import { AdminTable, Column, Icon } from "../../components/AdminTable/AdminTable";
import { CompanyModel } from "../../../../definitions/Company";
import { TabPanel } from "../../components/TabPanel";
import CompanyAds from "./components/CompanyAds";
import CompanyTestimonials from "./components/CompanyTestimonials";
import { useAdmin } from "../../../../actions/admin/admin.actions";

type CompaniesPageState = {
  searchString: string;
  addCompanyModalShown: boolean;
  companyToEdit: CompanyModel | null;
  companyToAssignRating: CompanyModel | null;
  currentTab: number;
};

const initialState: CompaniesPageState = {
  searchString: "",
  addCompanyModalShown: false,
  companyToEdit: null,
  companyToAssignRating: null,
  currentTab: 0,
};

const reducer = (state: CompaniesPageState, action: { type: string; payload: unknown }) => {
  return { ...state, [action.type]: action.payload };
};

const CompaniesPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [{ companies }, actions] = useCompanies();
  const [adminState] = useAdmin();

  const handleTabChange = (event: React.ChangeEvent<any>, newTab: number) => {
    dispatch({ type: "currentTab", payload: newTab });
  };

  const columns: Column<CompanyModel>[] = useMemo(
    () => [
      {
        name: "Name",
        accessor: "Name",
      },
      {
        name: "Sustainability rating",
        accessor: "Rating",
      },
      {
        name: "Assign rating",
        callback: (data) => dispatch({ type: "companyToAssignRating", payload: data }),
        icon: Icon.Rating,
      },
      {
        name: "Edit",
        icon: Icon.Edit,
        callback: (data) => dispatch({ type: "companyToEdit", payload: data }),
      },

      {
        name: "Delete",
        icon: Icon.Delete,
        callback: (data) => handleDeleteClick(data.Id),
      },
    ],
    []
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: "searchString", payload: event.currentTarget.value });

  const { showAlert } = useAlertContext();

  useEffect(() => {
    actions.getCompanies(0, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (adminState.error) {
      showAlert({
        severity: "error",
        text: adminState.error,
      });
    }
  }, [adminState.error]);

  const showAddCompanyModal = () => {
    dispatch({ type: "addCompanyModalShown", payload: true });
  };

  const handleModalClose = () => {
    dispatch({ type: "addCompanyModalShown", payload: false });
  };

  const handleDeleteClick = (companyId: string) => {
    actions.deleteCompany(companyId);
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid container spacing={3} item sm={10} xs={12}>
          <Tabs
            value={state.currentTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            sx={{ width: "100%" }}
          >
            <Tab label="Companies" />
            <Tab label="Ads on map" />
            <Tab label="Testimonials" />
          </Tabs>
          <TabPanel value={state.currentTab} index={0}>
            <Grid item container>
              <MTextField fullWidth placeholder="Search by title" onChange={handleChange} />
            </Grid>

            <Grid item container>
              <AdminTable
                data={companies.filter((company) =>
                  company.Name.toLocaleLowerCase().includes(state.searchString.toLowerCase())
                )}
                columns={columns}
                loading={adminState.loading}
                pagination={"client"}
                headerButton={
                  <Grid container item justifyContent="flex-start">
                    <Tooltip title="Add company">
                      <Button color="primary" variant="contained" onClick={showAddCompanyModal}>
                        <Add />
                      </Button>
                    </Tooltip>
                  </Grid>
                }
              />
            </Grid>
          </TabPanel>
          <TabPanel value={state.currentTab} index={1}>
            <CompanyAds />
          </TabPanel>
          <TabPanel value={state.currentTab} index={2}>
            <CompanyTestimonials />
          </TabPanel>
        </Grid>
      </Grid>
      <CompanyCreationDialog
        open={state.addCompanyModalShown}
        createCompany={actions.addCompany}
        handleClose={handleModalClose}
      />
      <CompanyEditDialog
        company={state.companyToEdit}
        open={!!state.companyToEdit}
        loading={adminState.loading}
        editCompany={actions.editCompany}
        handleClose={() => dispatch({ type: "companyToEdit", payload: null })}
      />
      <RatingDialog
        open={!!state.companyToAssignRating}
        existingRating={state.companyToAssignRating?.Rating ?? null}
        existingComment={null}
        showComment={false}
        objectName={state.companyToAssignRating?.Name}
        handleClose={() => dispatch({ type: "companyToAssignRating", payload: null })}
        handleOk={(rating, _) => {
          if (state.companyToAssignRating) {
            actions.assignRating(state.companyToAssignRating.Id, rating);
            dispatch({ type: "companyToAssignRating", payload: null });
          }
        }}
      />
    </>
  );
};

export default CompaniesPage;
