import React, { useEffect } from "react";
import { Typography, List, Grid, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useServicebookWorker } from "./workers.actions";
import { LinkRequestState, CraftsmanType } from "../../definitions/model/Worker";
import WorkersGroup from "./components/workers-group";
import WorkerLinkRequestItem from "./components/worker-link-request";
import DetailsSkeleton from "../../components/Base/DetailsSkeleton";
import { useTranslation } from "react-i18next";

const BuildingWorkersPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const [
    { buildingWorkersLoaded, buildingWorkers, workerRequests, loading },
    { getBuildingWorkers, getWorkerLinkRequests, processWorkerLinkRequest },
  ] = useServicebookWorker();
  const { addressURL } = useParams<{ addressURL: string }>();
  const [_buildingId, setBuildingId] = React.useState<string>("");

  useEffect(() => {
    const getAsync = async (buildingId: string) => {
      //await getWorkerLinkRequests(buildingId);
      if (!buildingWorkersLoaded) {
        await getBuildingWorkers(buildingId);
      }
    };

    if (addressURL) {
      const addressParts = addressURL.split("_");
      const id = addressParts[addressParts.length - 1];
      if (id) {
        setBuildingId(id);
        getAsync(id);
      }
    }

    return () => {};
  }, [addressURL]);

  const processRequest = (workerId: string, buildingId: string, state: LinkRequestState) => {
    processWorkerLinkRequest(workerId, buildingId, state).then(() => {
      getBuildingWorkers(buildingId);
      getWorkerLinkRequests(buildingId);
    });
  };

  return (
    <>
      <Typography variant="body1">
        {" "}
        {buildingWorkersLoaded && !loading && !buildingWorkers.length
          ? t("ServiceBook.Workers.NoBuildingWorkers")
          : t("ServiceBook.Workers.YourWorkers")}{" "}
      </Typography>
      {/* {!workerRequestsLoaded ? (
          <List disablePadding>
            {new Array(5).fill(0).map((_, idx) => (
              <WorkerSkeleton key={idx} />
            ))}
          </List>
        ) : ( */}
      {/* <> */}
      {!!workerRequests.length &&
        workerRequests.map((x) => <WorkerLinkRequestItem key={x.Id} {...x} processRequest={processRequest} />)}
      {/* </>
        )} */}
      {!buildingWorkersLoaded || loading ? (
        <List disablePadding>
          {new Array(5).fill(0).map((_, idx) => (
            <DetailsSkeleton key={idx} />
          ))}
        </List>
      ) : (
        <>
          {!!buildingWorkers.length &&
            CraftsmanType.filter((type) => type.key !== 200).map((type, i) => (
              <WorkersGroup
                key={i}
                workers={buildingWorkers.filter((w) => w.Competencies.includes(type.key))}
                title={t(type.value)}
                mode="building"
                targetId={_buildingId}
              />
            ))}
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate(`/buildings/${addressURL}/workers/find`)}
              >
                {t("ServiceBook.Workers.FindCraftsman")}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default BuildingWorkersPage;
