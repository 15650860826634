import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Container, Grid, Link, Typography, styled, useTheme } from "@mui/material";
import { StatisticsArticleDto } from "../../../definitions/model/CO2Stat";
import { useParams } from "react-router-dom";
import { useCO2StatActions } from "../../../actions/co2stat.actions";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, replace } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LogoContainer = styled(Grid)(() => ({
  width: "45px",
  height: "26px",
  display: "flex",
  padding: 4,
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#00000080",
  borderRadius: 5,
}));

const ArticleContent = styled(Grid)({
  "& > p": {
    display: "flex",
    flexDirection: "column",
  },
});

const StyledLogo = styled("img")(() => ({
  maxWidth: "100%",
  maxHeight: "100%",
  height: "min-content",
}));

const ClimatePlanArticlePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const params = useParams<{ uniqueName: string }>();
  const statActions = useCO2StatActions();
  const [article, setArticle] = useState<StatisticsArticleDto | undefined>();

  useEffect(() => {
    const getAsync = async () => {
      try {
        if (params.uniqueName) {
          const article = await statActions.getArticle(params.uniqueName);
          setArticle(article);
        }
      } catch (e) {
        console.log(e);
      } finally {
        //loading false
      }
    };
    getAsync();

    return () => {};
  }, []);

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        overflow: "hidden",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        style={{
          minHeight: "100vh",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Container maxWidth="lg" sx={{ display: "flex", flex: 1, flexDirection: "column", alignItems: "flex-start" }}>
          {(window.history.state.idx !== 0) && (
            <Button
              color="inherit"
              onClick={() => replace("/klimaplan/media")}
              type="submit"
              sx={{ marginBottom: "10px" }}
            >
              <ArrowBack fontSize="small" />
              {t("General.Buttons.Back")}
            </Button>
          )}
          <Card sx={{ flex: 1, mb: 2, minWidth: "100%" }} variant="outlined">
            <CardContent>
              {article ? (
                <>
                  <Grid container justifyContent="space-between">
                    <Grid item container xs={8} sm={9} md={10} lg={11}>
                      <Typography mr={1} variant="caption">
                        {new Date(article.Created).toLocaleString("da-dk")}
                      </Typography>
                      <Typography variant="caption">
                        <Link color="inherit" href={`mailto:${article.Author.Email}`}>
                          {getAuthorName(article)}
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item container justifyContent={"flex-end"} xs={4} sm={3} md={2} lg={1}>
                      <Grid container direction="column" alignItems="flex-end">
                        <LogoContainer>
                          <StyledLogo src={article.Commune.LogoUrl} />
                        </LogoContainer>
                        <Typography align="right" variant="caption">
                          {article.Commune.Name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container direction="column">
                    <Typography paragraph variant="h4">
                      {article.Title}
                    </Typography>
                    <ArticleContent
                      item
                      container
                      direction="column"
                      dangerouslySetInnerHTML={{ __html: article.Content }}
                    />
                  </Grid>
                </>
              ) : null}
            </CardContent>
          </Card>
        </Container>
      </Grid>
    </Container>
  );
};

export default ClimatePlanArticlePage;

export const getAuthorName = (article: StatisticsArticleDto) => {
  if (article.Author.FirstName) {
    if (article.Author.LastName) {
      return article.Author.FirstName + " " + article.Author.LastName;
    }
    return article.Author.FirstName;
  }
  return article.Author.Email;
};
