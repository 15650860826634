import { Dispatch } from "redux";
import { AdminCompanyActionTypes } from "../../constants/admin/admin.company.actiontypes";
import { AppAction, AppThunkDispatch } from "../../definitions/Action";
import CompanyService from "../../services/company.service";

import { AdminActionTypes } from "../../constants/admin/admin.actiontypes";

import { operationFailedActionGeneral } from "..";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../reducers/store";
import { CompanyGeneralInfo, CompanySustainabilityInfo, CompanyModel } from "../../definitions/Company";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, AdminActionTypes.ADMIN_OPERATION_FAILED);
};

export const getCompanies = (skip: number, take: number) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: AdminCompanyActionTypes.GET_COMPANIES,
    });
    const result = await CompanyService.getCompanies(skip, take);
    dispatch({
      type: AdminCompanyActionTypes.GET_COMPANIES_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const addCompany =
  (generalInfo: CompanyGeneralInfo, sustainabilityInfo: CompanySustainabilityInfo) =>
  async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({
        type: AdminCompanyActionTypes.ADD_COMPANY,
      });
      const result = await CompanyService.addCompany(generalInfo, sustainabilityInfo);
      dispatch({
        type: AdminCompanyActionTypes.ADD_COMPANY_SUCCEEDED,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const editCompany = (company: CompanyModel) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: AdminCompanyActionTypes.EDIT_COMPANY,
    });
    const result = await CompanyService.editCompany(company);
    dispatch({
      type: AdminCompanyActionTypes.EDIT_COMPANY_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const deleteCompany = (id: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: AdminCompanyActionTypes.DELETE_COMPANY,
    });
    await CompanyService.deleteCompany(id);
    dispatch({
      type: AdminCompanyActionTypes.DELETE_COMPANY_SUCCEEDED,
      payload: id,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const assignRating = (id: string, rating: number) => async (dispatch: Dispatch<AppAction>) => {
  try {
    await CompanyService.assignRating(id, rating);
    dispatch({
      type: AdminCompanyActionTypes.ASSING_COMPANY_RATING_SUCCEEDED,
      payload: { id, rating },
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const updateCompanyLogo = (id: string, picture: File) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: AdminCompanyActionTypes.UPDATE_COMPANY_LOGO,
    });
    const logoUrl = await CompanyService.updateCompanyLogo(id, picture);
    dispatch({
      type: AdminCompanyActionTypes.UPDATE_COMPANY_LOGO_SUCCEEDED,
      payload: { id, logoUrl },
    });

    return logoUrl;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const useCompaniesState = () =>
  useSelector((state: ApplicationState) => ({
    companies: state.admin.companies,
    loading: state.admin.loading,
  }));

const useCompaniesActions = () => {
  const dispatch: AppThunkDispatch = useDispatch();
  return {
    getCompanies: (skip: number, take: number) => dispatch(getCompanies(skip, take)),
    addCompany: (generalInfo: CompanyGeneralInfo, sustainabilityInfo: CompanySustainabilityInfo) =>
      dispatch(addCompany(generalInfo, sustainabilityInfo)),
    editCompany: (company: CompanyModel) => dispatch(editCompany(company)),
    deleteCompany: (id: string) => dispatch(deleteCompany(id)),
    assignRating: (id: string, rating: number) => dispatch(assignRating(id, rating)),
    updateCompanyLogo: (id: string, picture: File) => dispatch(updateCompanyLogo(id, picture)),
  };
};

export const useCompanies = (): [ReturnType<typeof useCompaniesState>, ReturnType<typeof useCompaniesActions>] => {
  const state = useCompaniesState();
  const actions = useCompaniesActions();
  return [state, actions];
};
