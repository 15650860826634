import React from "react";
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { CO2PlanMetaQuestionDto } from "../../../../ClimatePlan/shared/CO2Plan";
import { useTranslation } from "react-i18next";
import { TranslationText } from "../../../../../definitions/Menu";
import {
  Chip,
  Grid,
  List,
  ListItem,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";

type MetaQuestionAnswersChartType = {
  question: CO2PlanMetaQuestionDto;
  height: string | number | undefined;
  width: string | number | undefined;
};

type GroupToShow = {
  Amount: number;
  ShortName: string;
  Tooltip: string;
  Id: string;
  index?: number;
  color?: string;
  legendIcon?: string;
};

const MetaQuestionAnswersChart = (props: MetaQuestionAnswersChartType) => {
  const { i18n } = useTranslation("translation");
  const getText = (texts: TranslationText[]) => {
    return texts.find((text) => text.Language === i18n.language)?.Text ?? "";
  };

  const colors = ["#eb8ff9", "#a0ed6e", "#ffba27", "#99ecff", "#fff048", "#69bfff"];
  const groups: GroupToShow[] = props.question.Answers.filter((a) => !a.UserValue).map((answer, index) => ({
    Id: answer.Id,
    ShortName: getText(answer.AnswerText),
    color: colors[index % colors.length],
    Amount: answer.Count,
    Tooltip: getText(answer.AnswerText),
  }));

  return (
    <Grid item container mb={4} spacing={2} sx={{ p: 2, border: "1px dashed grey" }}>
      <Grid item>
        {getText(props.question.QuestionText) + ` (${props.question.PersonsAnsweredCount})`}
        <ResponsiveContainer height={props.height} width={props.width}>
          <BarChart data={groups}>
            <Bar dataKey="Amount" fill="#8884d8" isAnimationActive>
              {groups.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Bar>
            <Tooltip cursor={{ fill: "transparent" }} />
          </BarChart>
        </ResponsiveContainer>
      </Grid>
      <Grid item container justifyContent="flex-start">
        <StyledList>
          {groups.map((item, index) => (
            <ListItem key={item.Id} sx={{ width: "max-content", paddingLeft: 0 }}>
              <div
                style={{ height: 30, width: 30, marginRight: 5, backgroundColor: `${item.color}`, borderRadius: 10 }}
                key={index}
              />
              {item.ShortName + " - " + item.Amount}
            </ListItem>
          ))}
        </StyledList>
        <Box sx={{ p: 2, border: "1px dashed grey" }}>
          {props.question.Answers.filter((a) => a.UserValue).map((answer, index) => (
            <TableContainer component={Paper} key={answer.Id}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Chip label={getText(answer.UserValueLabel)} />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {answer.UserValuesList.map((v, vi) => (
                    <StyledTableRow key={vi}>
                      <TableCell component="th" scope="row">
                        {v}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default MetaQuestionAnswersChart;

const StyledList = styled(List)(() => ({
  display: "flex",
  flexDirection: "column",
  maxHeight: 200,
  flexWrap: "wrap",
  minWidth: 260,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
