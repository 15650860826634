import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import {
  CO2EmissionSourceGroupDescription,
  CO2SponsorSolution,
  EmissionSourceDto,
} from "../../../../ClimatePlan/shared/CO2Plan";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";
import { TransferList } from "../../../../../components/Base";
import { useAdminCO2 } from "../../../../../actions/admin/admin.co2plan.actions";
import { useAdminLandings } from "../../../../../actions";
import { useAdmin } from "../../../../../actions/admin/admin.actions";

type CompanyFormData = {
  CompanyId: string;
  EmissionSourceGroupId: string;
  EmissionSourceChildGroups: string[];
  DisplayName: string;
  CO2Emission: number;
  WidgetId: string | null;
};

type SponsorSolutionEditDialogProps = {
  solution: CO2SponsorSolution | null;
  open: boolean;
  handleClose: () => void;
};

const SponsorSolutionCreateEditDialog = (props: SponsorSolutionEditDialogProps): JSX.Element => {
  const [{ CO2EmissionSourceGroups: groups }, { editCO2SponsorSolutions, createCO2SponsorSolutions }] = useAdminCO2();
  const [{ widgets }] = useAdminLandings();
  const [{ companies }] = useAdmin();
  const {
    handleSubmit,
    register,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<CompanyFormData>();

  const [selectedGroup, setGroup] = useState<CO2EmissionSourceGroupDescription | null>(null);
  const [subGroupsOptions, setSubGroupsOptions] = useState<Array<EmissionSourceDto>>([]);
  const [selectedSubGroups, setSelectedSubGroups] = useState<Array<EmissionSourceDto>>([]);

  const { t } = useTranslation("translation");

  useEffect(() => {
    const mapChildSources = (childSources: EmissionSourceDto[]): Array<EmissionSourceDto> => {
      let childs = [...childSources];

      if (childSources.length > 0 && childSources[0].ChildSources.length > 0) {
        const childChilds = mapChildSources(childSources[0].ChildSources);
        childs = [...childs, ...childChilds];
      }

      return childs;
    };

    if (selectedGroup) {
      const options = mapChildSources(selectedGroup.ChildSources);
      setSubGroupsOptions(options);
      setSelectedSubGroups(options.filter((o) => props.solution?.EmissionSourceChildGroups.indexOf(o.Id) !== -1));
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (props.solution) {
      reset({
        CompanyId: props.solution.CompanyId,
        EmissionSourceGroupId: props.solution.EmissionSourceGroupId,
        EmissionSourceChildGroups: props.solution.EmissionSourceChildGroups,
        DisplayName: props.solution.DisplayName,
        CO2Emission: props.solution.CO2Emission,
        WidgetId: props.solution.WidgetId,
      });

      if (props.solution.EmissionSourceGroupId) {
        setGroup(groups.find((gr) => gr.Id === props.solution?.EmissionSourceGroupId) ?? null);
      }
    }
  }, [props.solution]);

  useEffect(() => {
    if (!props.solution) {
      reset({
        CompanyId: "",
        EmissionSourceGroupId: "",
        EmissionSourceChildGroups: [],
        DisplayName: "",
        CO2Emission: 0,
        WidgetId: "",
      });
      setGroup(null);
      setSelectedSubGroups([]);
    }
  }, [props.solution]);

  const submit = (values: CompanyFormData) => {
    if (props.solution) {
      editCO2SponsorSolutions(
        props.solution.Id,
        values.CompanyId,
        values.EmissionSourceGroupId,
        values.EmissionSourceChildGroups,
        values.DisplayName,
        values.CO2Emission,
        values.WidgetId ?? null
      ).then(() => props.handleClose());
    } else {
      createCO2SponsorSolutions(
        values.CompanyId,
        values.EmissionSourceGroupId,
        values.EmissionSourceChildGroups,
        values.DisplayName,
        values.CO2Emission,
        values.WidgetId
      ).then(() => props.handleClose());
    }
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Create sponsored solution</DialogTitle>
      <form onSubmit={handleSubmit(submit)}>
        <DialogContent>
          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel>Group</InputLabel>
            <Controller
              name="EmissionSourceGroupId"
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  error={!!errors.EmissionSourceGroupId}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    setGroup(groups.find((gr) => gr.Id === e.target.value) ?? null);
                  }}
                >
                  {groups.map((group) => (
                    <MenuItem key={group.Id} value={group.Id} style={{ padding: "10px 20px" }}>
                      {t(group.ShortName)}
                    </MenuItem>
                  ))}
                </Select>
              )}
              control={control}
            />
          </FormControl>
          <Box sx={{ marginTop: 1 }}>
            <TransferList
              options={subGroupsOptions}
              selected={selectedSubGroups}
              fieldLabel="Sub groups"
              placeholder="Sub groups"
              onChanges={(selected) =>
                setValue(
                  "EmissionSourceChildGroups",
                  selected.map((s) => s.Id)
                )
              }
              getOptionLabel={(o: EmissionSourceDto) => t(o.TargetName || o.Name)}
              getOptionSelected={(s: EmissionSourceDto, o: EmissionSourceDto) => s.Id === o.Id}
            />
          </Box>

          {selectedGroup && (
            <Grid container direction="column">
              <Typography variant="body1">Emission source group details:</Typography>
              <Typography variant="body2">Short name: {t(selectedGroup.ShortName)}</Typography>
              <Typography variant="body2">Name: {t(selectedGroup.Name)}</Typography>
              <Typography variant="body2">Is per person: {selectedGroup.IsPerPerson ? " yes" : " no"}</Typography>
              <Typography variant="body2">
                Average yearly CO<sub>2</sub>e emission: {selectedGroup.AverageCO2Emission}
              </Typography>
              <Typography variant="body2">
                Goal yearly CO<sub>2</sub>e emission: {selectedGroup.GoalCO2Emission}
              </Typography>
            </Grid>
          )}

          <TextFieldConnector register={register("DisplayName", { required: true })}>
            <TextField
              variant="outlined"
              autoFocus
              margin="dense"
              label="Solution text"
              fullWidth
              error={!!errors.DisplayName}
            />
          </TextFieldConnector>

          <TextFieldConnector register={register("CO2Emission", { required: true })}>
            <TextField
              variant="outlined"
              margin="dense"
              label="CO2 emission"
              type="number"
              fullWidth
              error={!!errors.CO2Emission}
            />
          </TextFieldConnector>

          <Typography variant="body2">
            If there is no numbers for emission - use average CO<sub>2</sub>e emission from emission source group
          </Typography>

          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel>Company</InputLabel>
            <Controller
              name="CompanyId"
              render={({ field }) => (
                <Select {...field} error={!!errors.CompanyId}>
                  {companies.map((company) => (
                    <MenuItem key={company.Id} value={company.Id} style={{ padding: "10px 20px" }}>
                      {company.Name}
                    </MenuItem>
                  ))}
                </Select>
              )}
              control={control}
              rules={{ required: true }}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel>Widget</InputLabel>
            <Controller
              name="WidgetId"
              render={({ field }) => (
                <Select {...field}>
                  <MenuItem style={{ padding: "10px 20px" }} value="">
                    Empty
                  </MenuItem>
                  {widgets.map((widget) => (
                    <MenuItem key={widget.Id} value={widget.Id} style={{ padding: "10px 20px" }}>
                      {widget.Title}
                    </MenuItem>
                  ))}
                </Select>
              )}
              control={control}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="button" onClick={props.handleClose} color="secondary">
            {t("General.Buttons.Close")}
          </Button>
          <Button variant="contained" type="submit" color="secondary">
            {t("General.Buttons.Save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SponsorSolutionCreateEditDialog;
