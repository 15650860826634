import React, { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { AppThunkDispatch } from "../../../../../definitions/Action";
import { clearSelectedCO2Plan } from "../../../../../actions/admin/admin.actions";
import { CircularProgress, ThemeProvider, styled } from "@mui/material";
import { CO2Plan, CO2UnitAddress } from "../../../../ClimatePlan/shared/CO2Plan";
import { getAdminCO2Plan } from "../../../../../actions/admin/admin.co2plan.actions";
import ClimatePlanPage, { ClimatPlanWidgetModel, ContextProps } from "../../../../ClimatePlan/shared/ClimatePlanPage";
import { useUser, useUnit } from "../../../../../actions";
import { WidgetPage } from "../../../../../constants/enums";
import { useNavigate } from "react-router-dom";
import { ApplicationState } from "../../../../../reducers/store";
import { communeTheme } from "../../../../../constants/theme";
import BaseDialog from "../../../../../components/Dialogs/BaseDialog";

const AdminCO2PlanViewerDialog = (props: AdminCO2PlanViewerDialogProps): JSX.Element => {
  const { t, i18n } = useTranslation("translation");
  const [co2Plan, setCo2Plan] = useState<CO2Plan | undefined>();
  const [{ unit }] = useUnit();
  const [{ user, loading }] = useUser();
  const navigate = useNavigate();
  const { error } = useSelector((state: ApplicationState) => state.co2);
  const defaultContext: ContextProps = {
    showEconomyItems: true,
    WidgetPage: 5,
    historyNavigate: () => {},
    onExitActions: () => {},
    renderWidget: (model: ClimatPlanWidgetModel, isBorder?: boolean): JSX.Element => <></>,
    family: null,
    unit: null,
    user: null,
    widgets: [],
    renderTopBar: (): JSX.Element => <></>,
    renderUserNotificationAlert: (): JSX.Element => <></>,
  };
  const [contextData, setContextData] = useState(defaultContext);

  useEffect(() => {
    setCo2Plan(undefined);
    setContextData(defaultContext);

    if (props.open && props.unit) {
      props.getPlan(props.unit.UnitId, props.companyId).then((p) => {
        if (p) {
          setCo2Plan(p);

          setContextData({
            ...defaultContext,
            user: p.PlanPersons[0],
            unit: { Id: props.unit?.UnitId ?? "", CommuneNumber: 0, PlanId: props.unit?.PlanId ?? "" },
          });
        }
      });
    }
  }, [props.unit, props.open]);

  useEffect(() => {
    if (co2Plan && co2Plan.Translations?.length > 0) {
      co2Plan.Translations.forEach((item) => {
        i18n.addResource(item.LanguageId, "translation", item.KeyPath, item.Text, { silent: false, keySeparator: "." });
      });
    }
  }, [co2Plan]);

  const historyNavigate = (route: string) => {
    navigate(route);
  };

  const IsMultiSelected = useMemo(() => {
    if (co2Plan) {
      for (const category of co2Plan.InitialSurvey.Categories) {
        for (const group of category.EmissionSourceGroups) {
          for (const source of group.PersonEmissionSources) {
            for (const emissionSource of source.EmissionSources) {
              if (
                emissionSource.CombinedMaxAmount &&
                emissionSource.CombinedValue &&
                emissionSource.CombinedMaxAmount !== emissionSource.CombinedValue
              ) {
                return true;
              }
            }
          }
        }
      }
    }

    return false;
  }, [co2Plan]);

  return (
    <StyledBaseDialog
      open={props.open}
      closeDialog={() => {
        props.clearSelectedCO2Plan();
        props.handleClose();
      }}
      titleComponent={t("Plan details") ?? ""}
    >
      {unit && co2Plan && (
        <ThemeProvider theme={communeTheme}>
          <ClimatePlanPage
            widgets={null}
            WidgetPage={WidgetPage.CO2Calculator}
            loading={loading}
            historyNavigate={historyNavigate}
            onExitActions={() => {}}
            showEconomyItems={true}
            user={user}
            error={error}
            readonly
            isMultiSelect={IsMultiSelected}
            unit={{
              Id: unit.Id,
              PlanId: unit.PlanId,
              CommuneNumber: Number(unit.Address.Commune.CommuneName),
              UnitArea: unit.UnitData.Area,
            }}
            showPropertyDescription={false}
          />
        </ThemeProvider>
      )}
      {(!co2Plan || !props.unit) && (
        <div style={{ textAlign: "center" }}>
          {" "}
          <CircularProgress />
        </div>
      )}
    </StyledBaseDialog>
  );
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getPlan: (unitId: string, companyId?: string) => dispatch(getAdminCO2Plan(unitId, companyId)),
  clearSelectedCO2Plan: () => dispatch(clearSelectedCO2Plan()),
});

const connector = connect(null, mapDispatchToProps);

type AdminCO2PlanViewerDialogProps = ConnectedProps<typeof connector> & {
  open: boolean;
  unit: CO2UnitAddress | null;
  companyId?: string;
  handleClose: () => void;
};

export default connector(AdminCO2PlanViewerDialog);
const StyledBaseDialog = styled(BaseDialog)((theme) => ({
  "& .MuiPaper-root": {
    maxWidth: theme.theme.breakpoints.values.xl,
  },
  "& .MuiDialogContent-root": {
    padding: 0,
  },
  "& .MuiDialog-paper": {
    paddingBottom: 0,
  },
}));
