import React, { useState } from "react";
import {
  Button,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import BaseDialog from "../../../components/Dialogs/BaseDialog";
import { OwnedUnitDto } from "../../../definitions/model/unit/Unit";
import { useTranslation } from "react-i18next";

type SelectCurrentHouseDialogProps = {
  open: boolean;
  closeDialog: () => void;
  houses: OwnedUnitDto[];
  setCurrentHouse: (unitId: string) => Promise<void>;
};

const SelectCurrentHouseDialog = ({
  open,
  closeDialog,
  setCurrentHouse,
  houses,
}: SelectCurrentHouseDialogProps): JSX.Element => {
  const {t} = useTranslation("translation");
  const [selected, setSelected] = useState("");
  const [isLoading, setLoading] = useState(false);

  const onListItemClick = (id: string) => () => setSelected(id);

  const onSelectClick = () => {
    setLoading(true);
    setCurrentHouse(selected)
      .then(() => closeDialog())
      .finally(() => setLoading(false));
  };

  return (
    <BaseDialog
      titleComponent={t("HouseManagement.ChooseNewHouse") ?? ""}
      actionsComponent={
        <Button variant="contained" color="secondary" onClick={onSelectClick}>
          {t("General.Buttons.Choose")}
        </Button>
      }
      closeDialog={closeDialog}
      open={open}
    >
      <Grid container justifyContent="center">
        <Grid item lg={8} md={9} sm={10} xs={12}>
          <List>
            {houses.map((u) => (
              <ListItem
                selected={selected === u.Id}
                key={u.Id}
                button
                onClick={onListItemClick(u.Id)}
              >
                <ListItemText
                  primary={`${u.Address.Street} ${u.Address.HouseNo}`}
                  secondary={`${u.Address.PostalCode} ${u.Address.Commune.CommuneName}`}
                />
              </ListItem>
            ))}
          </List>
          {isLoading && <LinearProgress />}
        </Grid>
      </Grid>
    </BaseDialog>
  );
};

export default SelectCurrentHouseDialog;
