import React, { useEffect, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";
import {Button, Grid} from "@mui/material";
import { Add, CheckCircle } from "@mui/icons-material";

import { ApplicationState } from "../../../../reducers/store";
import { AppThunkDispatch } from "../../../../definitions/Action";
import {
  getBenefitClubs,
  addBenefitClub,
  setBenefitClubPublish,
  deleteBenefitClub,
  reorderBenefitClubs,
} from "../../../../actions/admin/admin.actions";
import {
  BenefitClub,
  RegisterBenefitClubContract,
  BenefitClubType,
} from "../../../../definitions/Landing";
import BenefitClubCreateDialog, {
  BenefitClubFormData,
} from "./components/BenefitClubCreateDialog";
import { useDefaultReducer } from "../../../../helpers/hooks";
import {
  AdminTable,
  Column,
  Icon,
} from "../../components/AdminTable/AdminTable";

type BenefitClubsPageState = {
  benefitClubsList: Array<BenefitClub>;
  addBenefitClubModalShown: boolean;
  skip: number;
  take: number;
};

const initialState: BenefitClubsPageState = {
  benefitClubsList: [],
  addBenefitClubModalShown: false,
  skip: 0,
  take: 50,
};

const BenefitClubsPage = (props: BenefitClubsPageConnectedProps) => {
  const [state, dispatch] = useDefaultReducer(initialState);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.benefitClubsList.length === 0)
      props.getBenefitClubs(state.skip, state.take);
  }, [state.skip, state.take]);

  const showAddBenefitClubModal = () => {
    dispatch({ type: "addBenefitClubModalShown", payload: true });
  };

  useEffect(() => {
    dispatch({ type: "benefitClubsList", payload: props.benefitClubsList });
  }, [props.benefitClubsList]);

  const handleModalClose = (formData?: BenefitClubFormData) => {
    dispatch({ type: "addBenefitClubModalShown", payload: false });
    if (formData) {
      const data: RegisterBenefitClubContract = {
        Title: formData.Name,
        TemplateId: formData.TemplateId,
        LinkUrl: "",
        Type: formData.Type ?? BenefitClubType.ClubsPage,
      };
      props.addBenefitClub(data);
    }
  };

  const publishBenefitsClubHandle = (benefitClub: BenefitClub) => {
    if (benefitClub.IsPublished) {
      props.setBenefitClubPublish(benefitClub.Id, false);
    } else {
      props.setBenefitClubPublish(benefitClub.Id, true);
    }
  };

  const deleteBenefitClub = (benefitClubId: string) => () => {
    props.deleteBenefitClub(benefitClubId);
  };

  const columns: Column<BenefitClub>[] = useMemo(
    () => [
      {
        name: "Published",
        accessor: "IsPublished",
        format: (data) => (data ? <CheckCircle color="secondary" /> : ""),
      },
      {
        name: "Name",
        accessor: "Title",
      },
      {
        name: "Created",
        accessor: "Created",
        format: (data) => new Date(data).toLocaleString("da-dk"),
      },
      {
        name: "Status",
        icon: Icon.Disable,
        format: (data) => (data.IsPublished ? Icon.Disable : Icon.Public),
        callback: (data) => publishBenefitsClubHandle(data),
      },
      {
        name: "Edit",
        callback: (data) => navigate(`benefitclubs/${data.Id}`),
        icon: Icon.Edit,
      },
      {
        name: "Delete",
        callback: (data) => deleteBenefitClub(data.Id),
        icon: Icon.Delete,
      },
    ],
    []
  );

  return (
    <>
      <Grid container justifyContent="center">
        {state.benefitClubsList.length > 0 && (
          <Grid item md={9} sm={10} xs={12}>
            <AdminTable
              columns={columns}
              data={state.benefitClubsList}
              pagination="client"
              loading={props.loading}
              headerButton={
                <Grid container item justifyContent="flex-start">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={showAddBenefitClubModal}
                    sx={{margin: 1}}
                  >
                    <Add />
                  </Button>
                </Grid>
              }
            />
          </Grid>
        )}
      </Grid>
      <BenefitClubCreateDialog
        open={state.addBenefitClubModalShown}
        handleClose={handleModalClose}
      />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  benefitClubsList: state.admin.benefitClubsList,
  loading: state.admin.loading,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getBenefitClubs: (skip: number, take: number) =>
    dispatch(getBenefitClubs(skip, take)),
  addBenefitClub: (data: RegisterBenefitClubContract) =>
    dispatch(addBenefitClub(data)),
  setBenefitClubPublish: (id: string, publish: boolean) =>
    dispatch(setBenefitClubPublish(id, publish)),
  deleteBenefitClub: (id: string) => dispatch(deleteBenefitClub(id)),
  reorderBenefitClubs: (clubs: BenefitClub[]) =>
    dispatch(reorderBenefitClubs(clubs)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type BenefitClubsPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(BenefitClubsPage);
