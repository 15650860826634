import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { TextFieldConnector } from "../../../components/Base/FormConnector";
import { useBuilding } from "../../../actions/building.actions";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useAlertContext } from "../../../components/Base/MyhouseAlert";
import { BuildingUnitDto, InviteTenantDto } from "../../../definitions/model/Building";
import { GroupMemberDto } from "../../../definitions/model/Group";

type EditTenantDialogProps = {
  open: boolean;
  member: GroupMemberDto | null;
  buildingId: string;
  unitId?: string;
  units: BuildingUnitDto[];
  handleClose: () => void;
};

type FormData = {
  UnitId: string;
  Name: string;
  Email: string;
  TenantNumber: string;
  DebitorNumber: string;
};

const EditBuildingTenantDialog = (props: EditTenantDialogProps) => {
  const { t } = useTranslation("translation");
  const [, buildingActions] = useBuilding();
  const alert = useAlertContext();
  const [loading, setLoading] = React.useState(false);
  const methods = useForm<FormData>({
    defaultValues: {
      UnitId: "",
      Name: "",
      Email: "",
    },
  });
  const buildingTenants = props.units.flatMap((x) => x.Members);

  const {
    handleSubmit,
    register,
    control,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    reset();
    if (props.unitId) setValue("UnitId", props.unitId);
  }, [props.open]);

  useEffect(() => {
    if (props.member) {
      setValue("Name", props.member.Name);
      setValue("TenantNumber", props.member.TenantNumber);
      setValue("DebitorNumber", props.member.DebitorNumber);
    }
  }, [props.member]);

  const submit = async (data: FormData) => {
    try {
      setLoading(true);

      if (props.member) {
        await buildingActions.updateTenant(props.buildingId, props.member.UserId, {
          UserId: props.member.UserId,
          Source: data.UnitId,
          TenantNumber: data.TenantNumber,
          DebitorNumber: data.DebitorNumber,
        });
        return;
      }

      if (buildingTenants.find((x) => x.Name.includes(data.Email)))
        alert.showAlert({ text: t("Family.MemberAlreadyExists"), severity: "warning" });

      const tenantData: InviteTenantDto = {
        Name: data.Name,
        Email: data.Email,
        Source: data.UnitId,
        TenantNumber: data.TenantNumber,
        DebitorNumber: data.DebitorNumber,
      };
      await buildingActions.inviteTenant(props.buildingId, [tenantData]);
    } finally {
      close();
      setLoading(false);
    }
  };

  const close = () => {
    props.handleClose();
  };

  return (
    <Dialog open={props.open} aria-labelledby="form-dialog-title" onClose={close}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submit)}>
          <DialogTitle id="form-dialog-title">
            {props.member ? t("Family.EditMember") : t("Family.InviteMember")}
          </DialogTitle>
          <DialogContent style={{ overflowY: "visible" }}>
            <FormControl variant="outlined" fullWidth margin="dense" disabled={!!props.member}>
              <InputLabel>{t("Building.Apartment")}</InputLabel>
              <Controller
                name="UnitId"
                render={({ field }) => (
                  <Select {...field} fullWidth style={{ marginBottom: 10 }} variant="outlined">
                    {props.units.map((unit) => (
                      <MenuItem key={unit.Id} value={unit.Id} style={{ padding: "10px 20px" }}>
                        {`${unit.Address.HouseNo} ${unit.Address.Floor}-${unit.Address.DoorNumber}`}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                control={control}
              />
            </FormControl>
            <TextFieldConnector register={register("Name", { required: true })}>
              <TextField
                margin={"dense"}
                fullWidth
                variant="outlined"
                size="small"
                label={t("Base.ContactMeBox.Name")}
                placeholder={t("Base.ContactMeBox.EnterName") ?? ""}
                disabled={!!props.member}
                error={!!errors.Name}
              />
            </TextFieldConnector>
            {!props.member && (
              <TextFieldConnector register={register("Email", { required: true })}>
                <TextField
                  margin={"dense"}
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={t("Base.ContactMeBox.Email")}
                  placeholder={t("Base.ContactMeBox.Email") ?? ""}
                  error={!!errors.Email}
                />
              </TextFieldConnector>
            )}
            <TextFieldConnector register={register("TenantNumber", { required: false, pattern: /^[a-zA-Z0-9]{6}$/ })}>
              <TextField
                margin={"dense"}
                fullWidth
                variant="outlined"
                size="small"
                label={t("Base.ContactMeBox.TenantNumber")}
                placeholder={t("Base.ContactMeBox.TenantNumber") ?? ""}
                error={!!errors.TenantNumber}
              />
            </TextFieldConnector>
            <TextFieldConnector register={register("DebitorNumber", { required: false, pattern: /^[a-zA-Z0-9]{6}$/ })}>
              <TextField
                margin={"dense"}
                fullWidth
                variant="outlined"
                size="small"
                label={t("Base.ContactMeBox.DebitorNumber")}
                placeholder={t("Base.ContactMeBox.DebitorNumber") ?? ""}
                error={!!errors.DebitorNumber}
              />
            </TextFieldConnector>
          </DialogContent>
          <DialogActions>
            <LoadingButton loading={loading} type="submit" color="secondary" variant="contained">
              {t("General.Buttons.Save")}
            </LoadingButton>
            <Button onClick={close} color="secondary" variant="outlined">
              {t("General.Buttons.Close")}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default EditBuildingTenantDialog;
