import React, { useEffect, useState } from "react";
import {
  List,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  LinearProgress,
} from "@mui/material";
import {
  GroupMembersDto,
  GroupMemberDto,
} from "../../../definitions/model/Group";
import { GroupMemberRole } from "../../../constants/enums";
import { PersonAdd, PersonRemove } from "@mui/icons-material";
import { useTranslatedGroupRoles } from "../../../helpers/hooks";
import { useAlertContext } from "../../../components/Base";
import { ApplicationState } from "../../../reducers/store";
import { useSelector } from "react-redux";
import { useUser } from "../../../actions/user.actions";
import { useTranslation } from "react-i18next";

type FamilyMemberListProps = {
  members: GroupMemberDto[];
  selectedMember?: GroupMemberDto;
  unitId: string;
  listItemClickAction?: (member: GroupMemberDto) => void;
  onAddAction?: (memberId: string, unitId: string) => Promise<GroupMembersDto>;
  onBlockAction?: (memberId: string, unitId: string) => Promise<GroupMembersDto>;
  onRemoveAction?: (
    memberId: string,
    unitId: string
  ) => Promise<GroupMembersDto>;
};

const FamilyMembersList = ({
  members,
  selectedMember,
  unitId,
  listItemClickAction,
  onAddAction,
  onBlockAction,
  onRemoveAction,
}: FamilyMemberListProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const [{user}] = useUser();
  const [changedUserId, setChangedUserId] = useState("");
  const isOwner =
    members.find((x) => x.UserId === user?.Id)?.Role ===
    GroupMemberRole.Administrator;

  const error = useSelector((state: ApplicationState) => state.family.error);

  const { showAlert } = useAlertContext();

  useEffect(() => {
    if (error) showAlert({ text: error, severity: "error" });
  }, [error]);

  const { getDisplayGroupRole } = useTranslatedGroupRoles();

  const onListItemClickHandler = (user: GroupMemberDto) => () => {
    if (listItemClickAction) listItemClickAction(user);
  };

  const onAddClickHandler = (member: GroupMemberDto) => () => {
    if (onAddAction) {
      setChangedUserId(member.UserId);
      onAddAction(member.UserId, unitId)
        .catch(console.log)
        .finally(() => setChangedUserId(""));
    }
  };

  const onBlockClickHandler = (member: GroupMemberDto) => () => {
    if (onBlockAction) {
      setChangedUserId(member.UserId);
      onBlockAction(member.UserId, unitId).finally(() => setChangedUserId(""));
    }
  };

  const onRemoveClickHandler = (member: GroupMemberDto) => () => {
    if (onRemoveAction) {
      setChangedUserId(member.UserId);
      onRemoveAction(member.UserId, unitId)
        .catch(console.log)
        .finally(() => setChangedUserId(""));
    }
  };

  const isListItemSelected = (userId: string) =>
    listItemClickAction && selectedMember && selectedMember.UserId === userId;

  return (
    <List style={{ width: "100%" }}>
      {members.map((member) => (
        <React.Fragment key={member.UserId}>
          <ListItem
            button={!!listItemClickAction as any}
            selected={isListItemSelected(member.UserId)}
            onClick={onListItemClickHandler(member)}
            alignItems="flex-start"
          >
            <ListItemAvatar>
              <Avatar
                variant="square"
                alt={member.Name}
                src={member.AvatarUrl}
              />
            </ListItemAvatar>
            <ListItemText
              primary={member.Name}
              secondary={getDisplayGroupRole(member.Role)}
            />
            {member.Role === GroupMemberRole.NotValidMember && (
              <ListItemSecondaryAction>
                <>
                  {isOwner && onAddAction && (
                    <Tooltip title="Godkend anmodning">
                      <IconButton
                        onClick={onAddClickHandler(member)}
                        size="large"
                      >
                        <PersonAdd />
                      </IconButton>
                    </Tooltip>
                  )}
                  {isOwner && onBlockAction && (
                    <Tooltip title={t("General.Buttons.Reject")}>
                      <IconButton
                        onClick={onBlockClickHandler(member)}
                        size="large"
                      >
                        <PersonRemove />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              </ListItemSecondaryAction>
            )}
            {member.Role !== GroupMemberRole.NotValidMember &&
              member.UserId !== user?.Id &&
              isOwner &&
              onRemoveAction && (
                <ListItemSecondaryAction>
                  <Tooltip title={t("General.Buttons.Reject")}>
                    <IconButton
                      onClick={onRemoveClickHandler(member)}
                      size="large"
                    >
                      <PersonRemove />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              )}
          </ListItem>
          {changedUserId === member.UserId && <LinearProgress />}
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}
    </List>
  );
};

export default FamilyMembersList;
