import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { GrapesEditor, GrapesTypes, LoadComponentOptions } from "../../defenitions";
import { Grid, Button, Typography, ThemeProvider, StyledEngineProvider, Chip } from "@mui/material";
import AutocompleteRegistration from "../../../Autocomplete/AutocompleteRegistration";
import { Provider } from "react-redux";
import store from "../../../../reducers/store";
import { getOptionalTheme } from "../../../../constants/theme";
import { isReactComponent } from ".";
import { Step } from "../../../Dialogs/AuthDialog";
import { PreviousRouteProvider } from "../../../../routes/PreviouseRouteContext";
import { BrowserRouter } from "react-router-dom";
import { UserRating } from "../../../../definitions/model/User";
import { useTranslation } from "react-i18next";
import CompanyService from "../../../../services/company.service";
import { convertType } from "../../../../pages/ClimatePlan/shared/co2-helpers";
import { useUnit, useUser } from "../../../../actions";
import CommuneService from "../../../../services/admin/admin.commune.service";
import { useSearchParams } from "../../../../helpers/hooks";
import LoadingOverlay from "../../../LoadingOverlay";
import AddressHelper from "../../../../helpers/address-helper";

type MoveInAutocompleteProps = {
  registrationStep: Step;
  redirectUrl?: string;
  ignoreNotifications: boolean;
  btnText: string;
  btnPosition: "flex-start" | "center" | "flex-end";
  buttonVariant: "text" | "outlined" | "contained";
  buttonColor: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  inputVariant: "filled" | "outlined" | "standard";
  inputColor: "error" | "success" | "info" | "warning" | "primary" | "secondary";
  userRating: UserRating;
  employerId?: string;
  communeNumber?: number;
  isCO2Multiselect: boolean;
  withLoginButton: boolean;
  loginOnly: boolean;
  loginAndStay: boolean;
};

export function MoveInAutocomplete(props: MoveInAutocompleteProps): JSX.Element {
  const { t } = useTranslation("translation");
  const [{ unit: stateUnit }, { getUnit }] = useUnit();
  const [{ loading }] = useUser();
  const [autocompleteValue, setAutocompleteValue] = useState("");
  const [redirectParams, setRedirectParams] = useState("");
  const [unit, setUnit] = useState(stateUnit);
  const searchParams = useSearchParams();

  useEffect(() => {
    if (stateUnit && unit?.Id !== stateUnit.Id) {
      setUnit(stateUnit);
    }
  }, [stateUnit]);

  useEffect(() => {
    if (!autocompleteValue && unit) {
      const value = AddressHelper.GetAutocompleteInput(unit.Address);
      if (autocompleteValue !== value) setAutocompleteValue(value);
    }

    return () => {};
  }, [unit]);

  useEffect(() => {
    const getAsync = async () => {
      try {
        const unitId = searchParams.get("hid");
        //TODO:rewrite to more generic solution.
        //This works for only one case when the route is in the format of /unit-route/{gid}
        const redirectParams = searchParams.get("gid");
        if (redirectParams) {
          setRedirectParams("/" + redirectParams);
        }
        if (unitId) {
          const paramsUnit = await getUnit(unitId, false);
          if (paramsUnit) setUnit(paramsUnit);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getAsync();

    return () => {};
  }, []);

  const ignoreNotifications = convertType(props.ignoreNotifications);
  const employerId = convertType(props.employerId);

  return (
    <Grid container>
      <Grid item xs={12}>
        <LoadingOverlay loading={loading} />
        <AutocompleteRegistration
          placeholder={t("Autocomplete.Placeholder")}
          size="small"
          simpleRegistration={true}
          registrationDestination={props.redirectUrl ? props.redirectUrl + redirectParams : "unit-route"}
          registrationStep={props.registrationStep}
          inputValue={autocompleteValue}
          userRating={props.userRating}
          ignoreNotifications={ignoreNotifications}
          employerId={employerId}
          communeNumber={convertType(props.communeNumber)}
          isCO2Multiselect={convertType(props.isCO2Multiselect)}
          withLogInButton={props.withLoginButton}
          buttonColor={props.buttonColor}
          buttonVariant={props.buttonVariant}
          nextButtonText={props.btnText}
          loginOnly={props.loginOnly}
          loginAndStay={props.loginAndStay}
        >
          <Grid container justifyContent={props.btnPosition}>
            <Button
              style={{
                width: 150,
              }}
              variant={props.buttonVariant}
              color={props.buttonColor}
              type="submit"
            >
              {props.btnText}
            </Button>
          </Grid>
        </AutocompleteRegistration>
      </Grid>
    </Grid>
  );
}

export default function (editor: GrapesEditor, options: LoadComponentOptions, landingTheme?: string): void {
  const domComponents = editor.DomComponents;
  const blockManager = editor.BlockManager;
  const defaultType = domComponents.getType("default");
  const { model, view } = defaultType;
  const compName = "MoveInAutocomplete";
  blockManager.add(GrapesTypes.RegisterFormComponent, {
    label: "Move in autocomplete",
    category: options.categoryLabel,
    attributes: { class: "fa fa-home" },
    content: `<div data-gjs-type="${GrapesTypes.RegisterFormComponent}"></div>`,
  });

  const traits = [
    {
      type: "select",
      label: "Registration step",
      changeProp: 1,
      name: "registrationStep",
      options: [
        { id: "register", name: "register" },
        { id: "registerPhone", name: "register with Phone" },
      ],
    },
    {
      label: "User Rating",
      name: "userRating",
      changeProp: 1,
      type: "select",
      options: [
        { id: 0, name: "0" },
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 3, name: "3" },
        { id: 4, name: "4" },
        { id: 5, name: "5" },
      ],
    },
    {
      label: "Ignore Notifications",
      name: "ignoreNotifications",
      changeProp: 1,
      type: "checkbox",
    },
    {
      label: "With Login",
      name: "withLogin",
      changeProp: 1,
      type: "checkbox",
    },
    {
      label: "Login Only",
      name: "loginOnly",
      changeProp: 1,
      type: "checkbox",
    },
    {
      label: "Login And Stay",
      name: "loginAndStay",
      changeProp: 1,
      type: "checkbox",
    },
    {
      label: "Redirect url",
      name: "redirectUrl",
      changeProp: 1,
    },
    {
      label: "CO2 multiselect",
      name: "isCO2Multiselect",
      changeProp: 1,
      type: "checkbox",
    },
    {
      type: "select",
      label: "employerId",
      name: "employerId",
      changeProp: 1,
      typeid: 0,
      options: [],
    },
    {
      attributes: { style: "display: none" },
      label: "employerName",
      name: "employerName",
      changeProp: 1,
    },
    {
      type: "select",
      label: "Commune",
      name: "communeNumber",
      changeProp: 1,
      typeid: 0,
      options: [],
    },
    {
      attributes: { style: "display: none" },
      label: "communeName",
      name: "communeName",
      changeProp: 1,
    },
    {
      label: "Button title",
      name: "btnText",
      changeProp: 1,
    },
    {
      type: "select",
      label: "Button position",
      changeProp: 1,
      name: "btnPosition",
      typeid: 0,
      options: [
        { id: "flex-start", name: "start" },
        { id: "center", name: "center" },
        { id: "flex-end", name: "end" },
      ],
    },
    {
      type: "select",
      label: "Button Variant",
      changeProp: 1,
      name: "buttonVariant",
      typeid: 0,
      options: [
        { id: "outlined", name: "outlined" },
        { id: "contained", name: "contained" },
      ],
    },
    {
      type: "select",
      label: "Button Color",
      changeProp: 1,
      name: "buttonColor",
      typeid: 0,
      options: [
        { id: "inherit", name: "inherit" },
        { id: "primary", name: "primary" },
        { id: "secondary", name: "secondary" },
        { id: "default", name: "default" },
      ],
    },
    {
      type: "select",
      label: "Input Variant",
      changeProp: 1,
      name: "inputVariant",
      typeid: 0,
      options: [
        { id: "outlined", name: "outlined" },
        { id: "filled", name: "filled" },
        { id: "standard", name: "standard" },
      ],
    },
    {
      type: "select",
      label: "Input Color",
      changeProp: 1,
      name: "inputColor",
      typeid: 0,
      options: [
        { id: "inherit", name: "inherit" },
        { id: "primary", name: "primary" },
        { id: "secondary", name: "secondary" },
        { id: "default", name: "default" },
      ],
    },
  ];

  const subscriptions = traits.map((trait) => trait.name);

  const getCompanies = async () => {
    const companies = (await CompanyService.getCompanies(0, 50)).map((company) => ({
      id: company.Id,
      name: company.Name,
    }));
    return companies;
  };

  const getCommunes = async () => {
    const communes = (await CommuneService.getCommunes()).map((commune) => ({
      id: commune.Commune.CommuneNumber,
      name: commune.Commune.CommuneName,
    }));

    return communes;
  };

  domComponents.addType(GrapesTypes.RegisterFormComponent, {
    isComponent(el: HTMLElement) {
      if (
        (el.getAttribute && el.getAttribute("data-gjs-type") === GrapesTypes.RegisterFormComponent) ||
        (el.attributes && (el.attributes as any)["data-gjs-type"] === GrapesTypes.RegisterFormComponent) ||
        isReactComponent(el, compName)
      ) {
        return {
          type: GrapesTypes.RegisterFormComponent,
        };
      }
    },
    model: {
      defaults: {
        ...model.prototype.defaults,
        inputColor: "primary",
        inputVariant: "outlined",
        buttonVariant: "outlined",
        buttonColor: "primary",
        droppable: false,
        withLogin: true,
        loginOnly: false,
        loginAndStay: false,
        userRating: "0",
        redirectUrl: "",
        registrationStep: "register",
        btnText: "Næste",
        btnPosition: "flex-start",
        traits: [...model.prototype.defaults.traits, ...traits],
      },
    },
    view: view.extend({
      init(element: any) {
        getCompanies().then((res) => {
          element.model.getTrait("employerId").set("options", res);
        });

        getCommunes().then((res) => {
          element.model.getTrait("communeNumber").set("options", res);
        });

        subscriptions.forEach((subscription) => {
          this.listenTo(this.model, `change:${subscription}`, this.handleChanges);
        });
      },

      handleChanges() {
        const employerIdTrait = this.model.getTrait("employerId");
        if (employerIdTrait?.getValue()) {
          const employerId = employerIdTrait.getValue();
          const emplyersList = employerIdTrait.get("options") || [];
          const employer = emplyersList.find((e: { id: string }) => e.id == employerId);
          if (employer) this.model.getTrait("employerName").set("value", employer.name);
        }

        const communeNumberTrait = this.model.getTrait("communeNumber");
        if (communeNumberTrait?.getValue()) {
          const communeNumber = communeNumberTrait.getValue();
          const communesList = communeNumberTrait.get("options") || [];
          const commune = communesList.find((e: { id: string }) => e.id == communeNumber);
          if (commune) this.model.getTrait("communeName").set("value", commune.name);
        }

        const root = ReactDOM.createRoot(this.el);
        root.unmount();
        this.render();
      },

      onRender() {
        const { el } = this;
        const comps = this.model.get("components");
        const {
          registrationStep,
          userRating,
          ignoreNotifications,
          withLogin,
          loginOnly,
          loginAndStay,
          redirectUrl,
          buttonVariant,
          buttonColor,
          inputColor,
          inputVariant,
          btnText,
          btnPosition,
          employerId,
          employerName,
          communeNumber,
          communeName,
          isCO2Multiselect,
        } = this.model.attributes;
        comps.reset();
        const compString = `
          <${compName} 
            registrationStep=${registrationStep}  
            userRating=${userRating}
            ignoreNotifications=${ignoreNotifications}
            redirectUrl="${redirectUrl}"
            buttonVariant=${buttonVariant}
            buttonColor=${buttonColor}
            inputColor=${inputColor}
            inputVariant=${inputVariant}
            btnText="${btnText}"
            btnPosition=${btnPosition}
            employerId=${employerId}
            communeNumber=${communeNumber}
            isCO2Multiselect=${isCO2Multiselect}
            withLoginButton=${withLogin}
            loginOnly=${loginOnly}
            loginAndStay=${loginAndStay}
          ></${compName}>
        `;
        comps.add(compString);
        const root = ReactDOM.createRoot(el);
        root.render(
          <>
            <StyledEngineProvider>
              <ThemeProvider theme={getOptionalTheme(landingTheme)}>
                <Provider store={store}>
                  <BrowserRouter>
                    <PreviousRouteProvider>
                      <MoveInAutocomplete
                        registrationStep={registrationStep}
                        userRating={userRating}
                        ignoreNotifications={ignoreNotifications}
                        withLoginButton={withLogin}
                        loginOnly={loginOnly}
                        loginAndStay={loginAndStay}
                        redirectUrl={redirectUrl}
                        buttonVariant={buttonVariant}
                        buttonColor={buttonColor}
                        inputColor={inputColor}
                        inputVariant={inputVariant}
                        btnText={btnText}
                        btnPosition={btnPosition}
                        employerId={employerId}
                        communeNumber={communeNumber}
                        isCO2Multiselect={isCO2Multiselect}
                      />
                      {redirectUrl ? (
                        <Chip
                          sx={{ m: 0.5 }}
                          variant="outlined"
                          color="secondary"
                          label={`redirectUrl: ${redirectUrl}`}
                        />
                      ) : (
                        <Typography color="error">please set the value for redirect url in the settings</Typography>
                      )}
                      {employerName && (
                        <Chip sx={{ m: 0.5 }} variant="outlined" color="secondary" label={`Company: ${employerName}`} />
                      )}
                      {communeName && (
                        <Chip sx={{ m: 0.5 }} variant="outlined" color="secondary" label={`Commune: ${communeName}`} />
                      )}
                    </PreviousRouteProvider>
                  </BrowserRouter>
                </Provider>
              </ThemeProvider>
            </StyledEngineProvider>
          </>
        );
      },
    }),
  });
}
