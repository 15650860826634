import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Fade, styled } from "@mui/material";
import MenuBlockList from "./MenuBlockList";
import { useLanding, UserSensitiveItems, useUnit, useUser } from "../../../actions";
import { useCO2 } from "../../ClimatePlan/shared/co2plan.actions";
import Widget from "../../NewsStream/components/Widget";
import { WidgetPage } from "../../../constants/enums";
import { useNavigation } from "../navigation.actions";
import i18n from "../../../i18n";
import { LanguageType } from "../../../definitions/Menu";

const MenuListWrapper = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    width: 530,
  },
}));

const WidgetGrid = styled(Grid)(() => ({
  width: 250,
  margin: "8px 0 10px 8px",
  "@media (max-width: 992px)": {
    width: "100%",
    marginLeft: 0,
  },
}));

const OverviewContent = () => {
  const navigate = useNavigate();
  const [climateState, { getTotalCO2PlansCount }] = useCO2();
  const [unitState] = useUnit();
  const [{ uiMode }] = useUser();
  const [{ unitNavigationItems, buildingNavigationItems }, { getNavigationItems }] = useNavigation();
  const [{ widgets }, { getWidgetsByPage }] = useLanding();

  useEffect(() => {
    getTotalCO2PlansCount();

    return () => {};
  }, []);
  useEffect(() => {
    if (!unitState.loading && !climateState.loading) {
      const sensitiveItems: UserSensitiveItems = {
        climatplan: climateState.co2Plan,
        unit: unitState.unit,
      };
      getWidgetsByPage(WidgetPage.Overview, 0, 5, "", null, sensitiveItems, i18n.language as LanguageType);
    }
  }, [unitState.unit, climateState.co2Plan]);

  useEffect(() => {
    const link = document.getElementById("i396d");
    if (link) link.addEventListener("click", redirect);

    return () => {
      if (link) link.removeEventListener("click", redirect);
    };
  }, [widgets]);

  const redirect = () => {
    navigate("/sustainabilitycheck");
  };

  return (
    <Fade in>
      <Grid container justifyContent="center">
        <MenuListWrapper item>
          <MenuBlockList
            totalCO2Plans={climateState.totalCO2Plans}
            navigationItems={uiMode === "building" ? buildingNavigationItems : unitNavigationItems}
            unitLoading={unitState.loading}
            getNavigationItems={getNavigationItems}
          />
        </MenuListWrapper>
        <WidgetGrid item>
          {widgets
            .filter((c) => c?.Pages.includes(WidgetPage.Overview))
            .map((widget) => (
              <Grid key={widget.Id} item>
                <Widget model={widget} isBorder={false} />
              </Grid>
            ))}
        </WidgetGrid>
      </Grid>
    </Fade>
  );
};

export default OverviewContent;
