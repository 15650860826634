import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Grid, Link, TextField, Button, Typography, LinearProgress, Zoom, styled } from "@mui/material";
import FacebookLogin, { ReactFacebookLoginInfo, ReactFacebookFailureResponse } from "react-facebook-login";
import { useTranslation } from "react-i18next";
import PasswordField from "../../Base/PasswordField";
import AuthModel from "../../../definitions/model/Auth";
import { Step } from "../AuthDialog";
import { useNavigate } from "react-router-dom";
import { isInAppBrowser } from "../../../helpers";
import User from "../../../definitions/model/User";
import NavigationHelper from "../../../helpers/navigation-helper";
import { TextFieldConnector } from "../../Base/FormConnector";
import { useUnitActions, useUser } from "../../../actions";
import Unit from "../../../definitions/model/unit/Unit";

const StyledButton = styled(Button)(() => ({
  "&.MuiButton-root": {
    minWidth: 200,
  },
  "& .MuiButton-label": {
    justifyContent: "center",
  },
}));

const FacebookLoginWrapper = styled("div")(({ theme }) => ({
  "& .FbButton": {
    borderRadius: theme.shape.borderRadius,
    fontFamily: "inherit",
    lineHeight: "37px",
    backgroundColor: "#1877F2",
    border: "none",
    outline: "none",
    minWidth: 200,
    color: "white",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#0a54b3",
    },
    "& i": {
      marginRight: 7,
    },
  },
}));

type LoginFormData = {
  Email: string;
  Password: string;
  TermsOfUse: boolean;
  Notifications: boolean;
};

type LoginFormProps = {
  state: AuthModel;
  setStep: (step: Step, state: AuthModel) => void;
  employerId?: string;
  communeNumber?: number;
  loginOnly?: boolean;
  loginButtonText?: string;
};

const LoginForm = ({
  state,
  setStep,
  employerId,
  communeNumber,
  loginOnly,
  loginButtonText,
}: LoginFormProps): JSX.Element => {
  const methods = useForm<LoginFormData>({
    defaultValues: {
      Email: state.Email,
      Password: state.Password,
      TermsOfUse: false,
      Notifications: false,
    },
  });
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const [processing, setProcessing] = useState(false);
  const redirectUri = `${window.location.origin}${window.location.pathname}?flogin=true`;
  const [{ error, authDestination, loginAndStay }, { authorize, authorizeFacebook }] = useUser();
  const [anError, setAnError] = useState(error);
  const [, { updateUserProfile, moveInToProperty }] = useUser();
  const { getUnit } = useUnitActions();
  useEffect(() => {
    setAnError(error);
  }, [error]);

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
    getValues,
  } = methods;

  const redirectUser = async (data: (User | Unit | null)[]) => {
    if (!data) return;
    const user = data[0];
    let unit = data[1] as Unit;
    if (user && (employerId || communeNumber)) {
      await updateUserProfile({
        Name: user.Name,
        Email: user.Email,
        Phone: user.Phone,
        EmployerId: employerId,
        CommuneNumber: communeNumber,
        Language: user.Language,
      });
    }

    if (state.PropertyId && unit?.Id != state.PropertyId) unit = await getUnit(state.PropertyId);

    if (state.SimpleRegistration && state.PropertyId) await moveInToProperty(state.PropertyId, false);

    if (loginAndStay) return;
    
    if (authDestination) {
      NavigationHelper.GoByRoute(navigate, unit, null, authDestination);
      return;
    }

    navigate("/overview");
  };

  const tryLogin = ({ Email, Password }: LoginFormData) => {
    setProcessing(true);
    authorize(Email, Password)
      .then(redirectUser, (error) => {
        console.log(error);
      })
      .finally(() => setProcessing(false));
  };

  const responseFacebook = (response: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (response.error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      setAnError(response.error.message);
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (response.status === "unknown") {
      setAnError("Authorization.Errors.FacebookAuthCommon");
      return;
    }

    setProcessing(true);
    authorizeFacebook((response as ReactFacebookLoginInfo).id)
      .then(redirectUser)
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setProcessing(false));
  };

  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (!loginOnly && !window.FB)
        setAnError(t("Authorization.Errors.FacebookInitializationError", { hostname: window.location.hostname }));
    }, 2000);
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(tryLogin)}>
        <Grid container spacing={1}>
          <Grid item xs={12} mt={1}>
            <TextFieldConnector register={register("Email", { required: true })}>
              <TextField
                variant="outlined"
                fullWidth
                type="email"
                size="small"
                label={t("Base.Dialog.LoginEmailLabelPlaceholder")}
                style={{ maxWidth: 400 }}
                error={!!errors.Email}
              />
            </TextFieldConnector>
          </Grid>
          <Grid item xs={12}>
            <TextFieldConnector register={register("Password", { required: true })}>
              <PasswordField
                variant="outlined"
                fullWidth
                size="small"
                label={t("Base.Dialog.PasswordPlaceholder")}
                style={{ maxWidth: 400 }}
                error={!!errors.Password}
              />
            </TextFieldConnector>
          </Grid>
          <Grid container item justifyContent="center" direction="column" alignItems="center">
            <Grid item>
              <Link underline="hover" onClick={() => setStep("forgotPassword", getValues())}>
                <Typography variant="body2">{t("Authorization.Forms.ForgetPassword")}</Typography>
              </Link>
            </Grid>
          </Grid>
          {anError ? (
            <Zoom in={!!anError}>
              <Grid item container justifyContent="center">
                <Typography color="error">{t(anError)}</Typography>
              </Grid>
            </Zoom>
          ) : (
            <Grid item> </Grid>
          )}
          <Grid item container justifyContent="center">
            <Grid item>
              {!loginOnly && (
                <Link underline="hover" onClick={() => setStep("register", getValues())}>
                  <Typography variant="body2"> {t("Base.Dialog.SignUpText")}</Typography>
                </Link>
              )}
            </Grid>
          </Grid>
          <Grid item container direction="column" justifyContent="space-between" spacing={1}>
            <Grid container justifyContent="center" item>
              <StyledButton
                variant="contained"
                color="secondary"
                disabled={formState.isSubmitting || processing}
                type="submit"
              >
                {loginButtonText ?? t("Authorization.Buttons.LogIn")}
              </StyledButton>
            </Grid>
            <Grid container item justifyContent="center">
              <Typography variant="body1">{t("Base.Dialog.Or")}</Typography>
            </Grid>
            <Grid container justifyContent="center" item>
              {process.env.REACT_APP_FACEBOOK_APP_ID && (
                <FacebookLoginWrapper>
                  <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                    autoLoad={false}
                    isMobile={isInAppBrowser}
                    disableMobileRedirect={false}
                    fields="name,email,picture"
                    scope="public_profile,email"
                    callback={responseFacebook}
                    icon="fa-facebook"
                    textButton={t("Authorization.Buttons.FacebookLogin") ?? ""}
                    isDisabled={processing}
                    redirectUri={redirectUri}
                    cssClass="FbButton"
                  />
                </FacebookLoginWrapper>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
      {processing ? <LinearProgress /> : <div style={{ minHeight: 4 }}></div>}
    </>
  );
};

export default LoginForm;
