import React, { useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAction from "@mui/material/ListItemSecondaryAction";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import _ from "lodash";
import { HistoryModel } from "../../../../definitions/model/Dashboard";
import format from "date-fns/format/index";

type HistoryViewProps = {
  history: HistoryModel[];
  height: string;
};

const HistoryView = (props: HistoryViewProps): JSX.Element => {
  const groupped = _.chain(props.history)
    .groupBy((x) => x.Name)
    .map((v, k) => ({ name: k, items: v }))
    .orderBy((v) => v.name)
    .value();

  return (
    <Box height={props.height}>
      <List   sx={{
        height: "100%",
        overflowY: "auto",
        paddingTop: 0,
        paddingBottom: 0,
      }}>
        {groupped.map((h, index) => (
          <HistoryItem key={index} name={h.name} history={h.items} />
        ))}
      </List>
    </Box>
  );
};

type HistoryItemProps = {
  history: HistoryModel[];
  name: string;
};

const formatDate = (date: string) => {
  const d = new Date(date);
  if (d.getFullYear() === 1) return "null";
  return format(d, "dd MMM yy HH:mm");
};

const HistoryItem = (props: HistoryItemProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggle = () => setIsExpanded(!isExpanded);

  return <>
    <ListItem>
      <ListItemText>
        {props.name} {props.history.length}
      </ListItemText>
      <ListItemAction>
        <IconButton onClick={toggle} size="large">
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </ListItemAction>
    </ListItem>

    <Collapse in={isExpanded} mountOnEnter unmountOnExit>
      <Box ml={2}>
        <List>
          {props.history.map((h, i) => (
            <ListItem key={i}>
              <ListItemText>
                New: {h.NewValue} Old: {h.OldValue} {formatDate(h.Date)}
              </ListItemText>
            </ListItem>
          ))}
          <ListItem button onClick={toggle}>
            <ListItemText>Collapse</ListItemText>
            <ListItemAction>
              <IconButton onClick={toggle} size="large">
                <ExpandLess />
              </IconButton>
            </ListItemAction>
          </ListItem>
        </List>
      </Box>
    </Collapse>
  </>;
};

export default HistoryView;
