import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Chip,
  Grid,
  Rating,
  Tab,
  Tabs,
  TextField,
  TextFieldProps,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useMemo, useReducer } from "react";
import { useAdminUser } from "../../../../actions/admin/admin.user.actions";
import { MTextField } from "../../../../components/Base";
import User, { RemovedUserLogItemModel, UserRemovalReason } from "../../../../definitions/model/User";
import { AdminTable, Column, Icon } from "../../components/AdminTable/AdminTable";
import { TabPanel } from "../../components/TabPanel";
import { format } from "date-fns";
import daLocale from "date-fns/locale/da";
import { UserType } from "../../../../constants/enums";
import { useCompanies } from "../../../../actions/admin/admin.company.actions";
import { CompanyModel } from "../../../../definitions/Company";
import ConfirmDialog from "../../../../components/Dialogs/ConfirmDialog";

type UsersPageState = {
  currentTab: number;
  removeUserDialogShown: boolean;
  selectedUser?: User;
  filterString: string;
  company: CompanyModel | null;
  leaveCompanyUserId: { userId: string; companyId: string } | null;
  page: number;
  take: number;
  usersCount: number;
  logEntriesCount: number;
  resetPaging: boolean;
};

const initialState: UsersPageState = {
  currentTab: 0,
  removeUserDialogShown: false,
  page: 0,
  take: 10,
  filterString: "",
  company: null,
  leaveCompanyUserId: null,
  usersCount: 0,
  logEntriesCount: 0,
  resetPaging: false,
};

const reducer = (state: UsersPageState, action: { type: keyof UsersPageState; payload: any }) => {
  return { ...state, [action.type]: action.payload };
};

const UsersPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [adminUserState, adminActions] = useAdminUser();
  const [companiesState, companyActions] = useCompanies();

  const usersColumns: Column<User>[] = useMemo(
    () => [
      {
        name: "Email",
        accessor: "Email",
      },
      {
        name: "Name",
        accessor: "Name",
      },
      {
        name: "Address",
        accessor: "Address",
      },
      {
        name: "Created",
        accessor: "Created",
        format: (val) =>
          format(new Date(val), "dd. MMM yyyy", {
            locale: daLocale,
          }),
      },
      {
        name: "LastLogin",
        accessor: "LastLogin",
        format: (val) => {
          if (val === "0001-01-01T00:00:00Z") return "Never";
          return format(new Date(val), "dd. MMM yyyy 'kl.'HH.mm", {
            locale: daLocale,
          });
        },
      },
      {
        name: "Terms accepted",
        accessor: "TermsVersion",
      },
      {
        name: "Role",
        accessor: "Role",
        format: (role) => UserType[role],
      },
      {
        name: "Company",
        format: (data) => {
          const companyChips = data.Companies?.map((c: string) => {
            const company = companiesState.companies.find((x) => x.Id === c);
            if (!company) return "";

            return (
              <>
                <Chip
                  size="small"
                  color="secondary"
                  sx={{ m: 0.5 }}
                  label={company.Name}
                  key={`chip_key_${company.Id}`}
                  onDelete={() =>
                    dispatch({ type: "leaveCompanyUserId", payload: { userId: data.Id, companyId: company.Id } })
                  }
                />
                <ConfirmDialog
                  open={
                    !!state.leaveCompanyUserId &&
                    state.leaveCompanyUserId.userId === data.Id &&
                    state.leaveCompanyUserId.companyId === company.Id
                  }
                  text={`Are you sure want to quit '${data.Name}' from '${company.Name}'?`}
                  OkClickHandler={async () => {
                    await adminActions.removeUserFromCompany(data.Id, company.Id);
                    dispatch({ type: "leaveCompanyUserId", payload: null });
                  }}
                  onClose={() => dispatch({ type: "leaveCompanyUserId", payload: null })}
                />
              </>
            );
          });

          companyChips.push(
            <Autocomplete
              value={null}
              size="small"
              key={`autocomplete_key_${data.Id}`}
              color="secondary"
              onChange={async (event, val) => {
                if (val) await adminActions.joinUserToCompany(data.Id, val?.Id);
              }}
              options={companiesState.companies}
              loading={companiesState.loading}
              getOptionLabel={(o) => o.Name}
              isOptionEqualToValue={(o, value) => {
                return state.company?.Id === o.Id;
              }}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...(params as TextFieldProps)}
                  label="Join Company"
                  variant="outlined"
                  placeholder="Join Company"
                />
              )}
            ></Autocomplete>
          );

          return companyChips;
        },
      },
      {
        name: "Rating",
        format: (data) => (
          <Rating
            value={data.Rating}
            size="small"
            color="secondary"
            onChange={async (e, value) => {
              await adminActions.changeUserRating(data.Id, value ?? 0);
            }}
          />
        ),
      },
      {
        name: "Delete",
        icon: Icon.Delete,
        callback: (data) => {
          handleRemoveUser(data);
        },
      },
    ],
    [state.filterString, state.company, state.leaveCompanyUserId, companiesState.companies]
  );

  const logColumns: Column<RemovedUserLogItemModel>[] = useMemo(
    () => [
      {
        name: "Email",
        accessor: "Email",
      },
      {
        name: "Facebook Id",
        accessor: "FacebookId",
      },
      {
        name: "Removed date",
        accessor: "DeletedDate",
        format: (val) =>
          format(new Date(val), "dd. MMM yyyy 'kl.'HH.mm", {
            locale: daLocale,
          }),
      },
      {
        name: "Reason",
        accessor: "Reason",
        format: (data: UserRemovalReason) => {
          switch (data) {
            case UserRemovalReason.Undefined:
              return "Unknown";
            case UserRemovalReason.SelfRemoval:
              return "User removed themselves";
            case UserRemovalReason.OldUserJobRemoval:
              return "User was removed because of no activity";
            case UserRemovalReason.AdminRemoval:
              return "User was removed by an administrator";
          }
        },
      },
    ],
    []
  );

  const handleChange = (event: React.ChangeEvent<any>, newTab: number) => {
    dispatch({ type: "page", payload: 0 });
    dispatch({ type: "currentTab", payload: newTab });
    dispatch({ type: "filterString", payload: "" });
  };

  const handleFilterChange = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const val = event.target.value;
    dispatch({ type: "filterString", payload: val });

    dispatch({ type: "page", payload: 0 });
    dispatch({ type: "resetPaging", payload: true });
  };

  const handleCompanyChange = async (val: CompanyModel | null) => {
    dispatch({ type: "company", payload: val ?? null });
  };

  const handleRemoveUser = async (user: User) => {
    await adminActions.removeUser(user.Id);
    await adminActions.getUsers(state.filterString, state.company?.Id ?? null, state.page * state.take, state.take);
  };

  const changePagination = ({ page, rows }: { page: number; rows: number }) => {
    dispatch({ type: "page", payload: page });
    dispatch({ type: "take", payload: rows });
  };

  useEffect(() => {
    companyActions.getCompanies(0, 0);

    return () => {};
  }, []);

  useEffect(() => {
    const getData = async () => {
      switch (state.currentTab) {
        case 0:
          await adminActions.getUsers(
            state.filterString,
            state.company?.Id ?? null,
            state.page * state.take,
            state.take
          );

          break;
        case 1:
          await adminActions.getRemovedUsersLog(state.filterString, state.page * state.take, state.take);
          break;
      }
    };
    getData();

    return () => {};
  }, [state.currentTab, state.page, state.take, state.filterString, state.company]);

  return (
    <Grid container justifyContent="center">
      <Grid container spacing={3} item sm={10} xs={12}>
        <Tabs
          value={state.currentTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          sx={{ width: "100%" }}
        >
          <Tab label="Users" />
          <Tab label="Removed users log" />
        </Tabs>
      </Grid>

      <TabPanel value={state.currentTab} index={0}>
        <Grid container>
          <Grid item xs={12} sm={8}>
            <MTextField
              fullWidth
              placeholder="Search by name or email"
              onChange={handleFilterChange}
              value={state.filterString}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              value={state.company}
              onChange={(event, val) => handleCompanyChange(val)}
              options={companiesState.companies}
              loading={companiesState.loading}
              getOptionLabel={(o) => o.Name}
              isOptionEqualToValue={(o, value) => {
                return state.company?.Id === o.Id;
              }}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField {...(params as TextFieldProps)} label="Company" variant="outlined" placeholder="Company" />
              )}
            ></Autocomplete>
          </Grid>
        </Grid>

        <AdminTable
          data={adminUserState.users.Results}
          columns={usersColumns}
          pagination={"server"}
          loading={adminUserState.loading}
          onPaginationChange={changePagination}
          count={adminUserState.users.Count}
          resetPaging={state.resetPaging}
        />
      </TabPanel>
      <TabPanel value={state.currentTab} index={1}>
        <Grid item container>
          <MTextField
            fullWidth
            placeholder="Search by email"
            onChange={handleFilterChange}
            value={state.filterString}
          />
        </Grid>
        <AdminTable
          data={adminUserState.log.Results}
          columns={logColumns}
          pagination={"server"}
          loading={false}
          onPaginationChange={changePagination}
          count={adminUserState.log.Count}
          resetPaging={state.resetPaging}
        />
      </TabPanel>
    </Grid>
  );
};

export default UsersPage;
