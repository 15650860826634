import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Select, MenuItem, TextField, FormControl, InputLabel } from "@mui/material";
import { GroupMemberRole } from "../../../constants/enums";
import { useTranslatedGroupRoles } from "../../../helpers/hooks";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { TextFieldConnector } from "../../../components/Base/FormConnector";
import { useBuilding } from "../../../actions/building.actions";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { GroupMemberDto, PendingGroupMemberDto } from "../../../definitions/model/Group";
import { useAlertContext } from "../../../components/Base/MyhouseAlert";

type EditMemberDialogProps = {
  open: boolean;
  buildingId: string;
  members: GroupMemberDto[];
  pendingMembers: PendingGroupMemberDto[];
  addMemberMode: boolean;
  memberRoles: GroupMemberRole[];
  handleClose: () => void;
};

type FormData = {
  Name: string;
  Email: string;
  Role: GroupMemberRole;
};

const EditBuildingMemberDialog = (props: EditMemberDialogProps) => {
  const { getDisplayGroupRole } = useTranslatedGroupRoles();
  const { t } = useTranslation("translation");
  const [, buildingActions] = useBuilding();
  const alert = useAlertContext();
  const [loading, setLoading] = React.useState(false);
  const methods = useForm<FormData>({
    defaultValues: {
      Name: "",
      Email: "",
    },
  });

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    reset();
  }, [props.open]);

  const submit = async (data: FormData) => {
    try {
      if (
        props.members.find((x) => x.Name.includes(data.Email)) ||
        props.pendingMembers.find((x) => x.Email.includes(data.Email))
      )
        alert.showAlert({ text: t("Family.MemberAlreadyExists"), severity: "warning" });

      setLoading(true);
      await buildingActions.inviteMember(props.buildingId, data.Name, data.Email, data.Role);
      close();
    } finally {
      setLoading(false);
    }
  };

  const close = () => {
    props.handleClose();
  };

  return (
    <Dialog open={props.open} aria-labelledby="form-dialog-title" onClose={close}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submit)}>
          <DialogTitle id="form-dialog-title">{t("Family.InviteMember")}</DialogTitle>
          <DialogContent style={{ overflowY: "visible" }}>
            <TextFieldConnector register={register("Name", { required: true })}>
              <TextField
                margin={"dense"}
                fullWidth
                variant="outlined"
                size="small"
                label={t("Base.ContactMeBox.Name")}
                placeholder={t("Base.ContactMeBox.EnterName") ?? ""}
                error={!!errors.Name}
              />
            </TextFieldConnector>
            <TextFieldConnector register={register("Email", { required: true })}>
              <TextField
                margin={"dense"}
                fullWidth
                variant="outlined"
                size="small"
                label={t("Base.ContactMeBox.Email")}
                placeholder={t("Base.ContactMeBox.Email") ?? ""}
                disabled={!props.addMemberMode}
                error={!!errors.Email}
              />
            </TextFieldConnector>
            <FormControl variant="outlined" fullWidth margin="dense">
              <InputLabel>{t("Family.Role")}</InputLabel>
              <Controller
                name="Role"
                render={({ field }) => (
                  <Select {...field} fullWidth style={{ marginBottom: 10 }} variant="outlined">
                    {props.memberRoles.map((role) => (
                      <MenuItem key={role} value={role} style={{ padding: "10px 20px" }}>
                        {getDisplayGroupRole(role)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                control={control}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <LoadingButton loading={loading} type="submit" color="secondary" variant="contained">
              {t("General.Buttons.Save")}
            </LoadingButton>
            <Button onClick={close} color="secondary" variant="outlined">
              {t("General.Buttons.Close")}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default EditBuildingMemberDialog;
