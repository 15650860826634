import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import CompanyAdDataForm from "./CompanyAdDataForm";
import { CompanyAdModel, CompanyModel } from "../../../../../definitions/Company";

type CompanyAdDialogProps = {
  companies: CompanyModel[];
  open: boolean;
  handleClose: () => void;
  createAd: (ad: CompanyAdModel) => Promise<void>;
};

const CompanyAdCreationDialog = (props: CompanyAdDialogProps): JSX.Element => {
  const submit = (ad: CompanyAdModel) => {
    props.createAd(ad).then(() => props.handleClose());
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Create company</DialogTitle>
      <CompanyAdDataForm submit={submit} handleClose={props.handleClose} companies={props.companies} />
    </Dialog>
  );
};

export default CompanyAdCreationDialog;
