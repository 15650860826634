import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Skeleton, Stack } from "@mui/material";
import { useBuilding } from "../../actions/building.actions";
import BuildingInfo from "./components/BuildingInfo";
import { useTranslation } from "react-i18next";
import AddressHelper from "../../helpers/address-helper";
import { LoadingButton } from "@mui/lab";
import { Building } from "../../definitions/model/Building";
import { useUser } from "../../actions";
import { useAlertContext } from "../../components/Base";

const BuildingJoinPage = (): JSX.Element => {
  const { addressURL, token } = useParams<{ addressURL: string; token: string }>();
  const { t } = useTranslation("translation");
  const alert = useAlertContext();
  const navigate = useNavigate();
  const [{ building, groupMembers, loading, error }, buidingActions] = useBuilding();
  const [{ user }, { setAuthDestination, openRegisterDialog, _getBasicData }] = useUser();
  const [acceptLoading, setAcceptLoading] = React.useState(false);

  useEffect(() => {
    if (addressURL) {
      const addressParts = addressURL.split("_");
      const id = addressParts[addressParts.length - 1];
      if (building?.Id !== id) {
        buidingActions.getBuilding(id);
        if (user) buidingActions.getBuildingMembers(id);
      }
    }
  }, [addressURL]);

  useEffect(() => {
    if (error) {
      alert.showAlert({ severity: "error", text: error });
    }
  }, [error]);

  useEffect(() => {
    if (user && groupMembers && building) {
      groupMembers.Members?.find((m) => m.UserId === user.Id) && navigateToBuilding(building);
    }
  }, [user, groupMembers, building]);

  const navigateToBuilding = (b: Building) => {
    const buildingUrl = `/buildings/${AddressHelper.GetBuildingAddressUrl(b)}`;
    navigate(buildingUrl, { replace: true });
  };

  const acceptClick = (b: Building) => {
    if (!user) {
      const setDestinationAsync = async () => {
        await setAuthDestination(false, window.location.pathname, 1);
      };
      setDestinationAsync();
      openRegisterDialog();
      return;
    }

    setAcceptLoading(true);
    const acceptAsync = async () => {
      try {
        if (!token) return;
        await buidingActions.acceptInviteMember(b.Id, token);
        await _getBasicData();
        navigateToBuilding(b);
      } catch (e) {
        console.error(e);
      } finally {
        setAcceptLoading(false);
      }
    };

    acceptAsync();
  };

  return (
    <>
      <Stack sx={{ width: "100%" }} mb={2} spacing={2}>
        {building && (
          <Alert
            severity="success"
            action={
              <LoadingButton
                loading={acceptLoading}
                color="secondary"
                size="small"
                variant="outlined"
                onClick={() => acceptClick(building)}
              >
                {t("General.Buttons.Accept")}
              </LoadingButton>
            }
          >
            {t("Building.JoinAdministrationMessage", {
              address: AddressHelper.GetAutocompleteInput(building.Address),
            })}
          </Alert>
        )}
        {loading && <Skeleton animation="wave" variant="text" width="100%" height={70} />}
      </Stack>
      <BuildingInfo />
    </>
  );
};

export default BuildingJoinPage;
