import React from "react";
import { Grid, Box, Skeleton } from "@mui/material";
import { repeatSkeleton } from "../../../components/Base/DetailsSkeleton";

const TimelineSkeleton = () => (
  <Box height="230px">
    <Grid container justifyContent="space-between">
      <Grid container item>
        <Grid container item xs={6}>
          <Box flexGrow={1}></Box>
          <Box pr={3}>
            <Grid container>
              <Box mb={1} flexGrow={1}></Box>
              <Skeleton width={40} height={24} />
            </Grid>
            {repeatSkeleton({ width: 100, height: 20 }, 5)}
          </Box>
        </Grid>
      </Grid>
      <Grid container  justifyContent="flex-end">
        <Grid container item xs={6}>
            <Box pl={3}>
              <Grid container>
                <Skeleton width={40} height={24} />
              </Grid>
              {repeatSkeleton({ width: 100, height: 20 }, 4)}
            </Box>
          </Grid>
        </Grid>
      </Grid>
  </Box>
);
export default TimelineSkeleton;
