import React, { useState } from "react";
import {
  Button,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useForm } from "react-hook-form";

import { CreateProductRequest } from "../../../../../definitions/model/Subscriptions";
import FileInput from "../../../../../components/Base/FileInput";
import { useTranslation } from "react-i18next";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";

type ProductDialogProps = {
  open: boolean;
  handleClose: () => void;
  createProduct: (data: CreateProductRequest) => Promise<void>;
};

export type ProductFormData = {
  Name: string;
  Description: string;
};

const CreateProductDialog = ({
  open,
  handleClose,
  createProduct,
}: ProductDialogProps): JSX.Element => {
  const [picture, setPicture] = useState<File | null>(null);

  const { t } = useTranslation("translation");

  const methods = useForm<ProductFormData>({
    defaultValues: {
      Name: "",
      Description: "",
    },
  });
  const { register, handleSubmit, formState: {errors} } = methods;

  const submit = (data: ProductFormData) => {
    createProduct({ ...data, Picture: picture });
    handleClose();
  };

  const uploadPictureHandler = (img: File) => {
    setPicture(img);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm">
      <DialogTitle>Opret Product</DialogTitle>
      <form onSubmit={handleSubmit(submit)}>
        <DialogContent>
          <TextFieldConnector register={register("Name", {required: true})}>
            <TextField
              autoFocus
              margin="dense"
              label="Name"
              type="text"
              fullWidth
              error={!!errors.Name}
            />
          </TextFieldConnector>
          <TextFieldConnector register={register("Description", {required: true})}>
            <TextField
              margin="dense"
              label="Description"
              type="text"
              fullWidth
              error={!!errors.Description}
            />
          </TextFieldConnector>
          <FileInput getPicture={uploadPictureHandler}>
            <Typography style={{ marginTop: 20 }}>
              Upload nyt produktbillede
            </Typography>
          </FileInput>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="secondary">
            {t("General.Buttons.Undo")}
          </Button>
          <Button type="submit" variant="contained" color="secondary">
            {t("General.Buttons.Create")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateProductDialog;
