import React from "react";
import { Grid, CardHeader, CardContent, Card, CardMedia, Typography, styled } from "@mui/material";

import MCard from "../../../components/Base/MyhouseCard";
import NoPhoto from "../../../assets/img/no_house.jpg";
import { useTranslation } from "react-i18next";
import { OISBuildingData } from "../../../definitions/model/Building";

type RelatedBuildingsProps = {
  buildings: OISBuildingData[];
};

const DataGridStyledRow = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: 2,
}));

const RelatedBuildings = ({ buildings }: RelatedBuildingsProps): JSX.Element => {
  const { t } = useTranslation("translation");

  return buildings.length ? (
    <MCard type="main" title="Relaterede bygninger">
      <CardContent>
        <Grid container spacing={4}>
          {buildings.map((item: OISBuildingData) => (
            <Grid item sm={6} xs={12} key={item.BuildingId}>
              <Card style={{ border: `1px solid #eee`, height: "100%" }}>
                <SCardMedia image={NoPhoto} />
                <SCardHeader subheader={`${item.Type}`} title={`${item.Street} ${item.HouseNumber}`} />
                <SCardContent>
                  <Grid container direction="column">
                    {item.RoofText && (
                      <DataGridStyledRow item>
                        <Typography style={{ fontWeight: "bold" }}>{t("Pages.House.Roof")}</Typography>
                        <Typography>{item.RoofText}</Typography>
                      </DataGridStyledRow>
                    )}
                    {item.WallText && (
                      <DataGridStyledRow item>
                        <Typography style={{ fontWeight: "bold" }}>{t("Pages.House.Wall")}</Typography>
                        <Typography>{item.WallText}</Typography>
                      </DataGridStyledRow>
                    )}
                    {item.HeatingInstallationText && (
                      <DataGridStyledRow item>
                        <Typography style={{ fontWeight: "bold" }}>{t("Pages.House.HeatingInstallation")}</Typography>
                        <Typography>{item.HeatingInstallationText}</Typography>
                      </DataGridStyledRow>
                    )}
                    {item.TypeOfHeating && (
                      <DataGridStyledRow item>
                        <Typography style={{ fontWeight: "bold" }}>{t("Pages.House.HeatingType")}</Typography>
                        <Typography>{item.TypeOfHeating}</Typography>
                      </DataGridStyledRow>
                    )}
                  </Grid>
                </SCardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </MCard>
  ) : (
    <></>
  );
};

export default RelatedBuildings;

const SCardMedia = styled(CardMedia)(() => ({
  "&.MuiCardMedia-root": {
    height: 155,
  },
}));

const SCardHeader = styled(CardHeader)(() => ({
  "&.MuiCardHeader-root": {
    backgroundColor: "#fff",
    paddingBottom: 0,
  },
  "& .MuiCardHeader-title": {
    fontSize: 12,
  },
  "& .MuiCardHeader-content": {
    display: "flex",
    flexDirection: "column-reverse",
  },
}));

const SCardContent = styled(CardContent)(() => ({
  "&.MuiCardContent-root": {
    paddingTop: 0,
    "&:last-child": {
      paddingBottom: 16,
    },
  },
}));
