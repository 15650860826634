import React, { useState } from "react";
import { Drawer, Grid, Typography, IconButton, Button, Skeleton, Divider, Badge } from "@mui/material";
import { Menu, Email, Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { UserType } from "../../constants/enums";
import UnitHelper from "../../helpers/unit-helper";
import { TopBarProps, startPageNavigationItems } from "./TopBar";
import { useAddToHomescreenPrompt } from "../../helpers/useAddToHomescreenPrompt";
import AddToHomeScreenDialog from "../Dialogs/AddToHomeScreenDialog";
import { menuClick } from "../../pages/Overview/Components/MenuBlockList";
import UserAvatar from "./UserAvatar";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { GetTranslatedText } from "../../helpers/translation-helper";
import { useBuilding } from "../../actions/building.actions";
import { useUnit, useUser } from "../../actions";
import { useNavigation } from "../../pages/Overview/navigation.actions";

const DrawerHeader = styled(Grid)(() => ({
  padding: "7px 22px",
  "& p": {
    fontSize: 8,
    letterSpacing: 5.33,
  },
}));

const DrawerProfile = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: "15px 22px 20px 22px",
}));

const DrawerList = styled(Grid)(() => ({
  padding: "20px 22px",
  "& p": {
    paddingBottom: 15,
    cursor: "pointer",
  },
}));

const ProfileImg = styled(UserAvatar)(() => ({
  width: 60,
  height: 60,
  fontSize: 30,
  margin: "8px 0",
}));

const SmallProfileImg = styled(UserAvatar)(() => ({
  width: 40,
  height: 40,
  fontSize: 20,
}));

const ProfileLink = styled(Typography)(() => ({
  letterSpacing: 3,
  textDecoration: "underline",
  cursor: "pointer",
}));

type HumburgerProps = {
  isAdmin?: boolean;
  craftsmensLoginUrl?: string;
  registerClick?: () => void;
  loginClick?: () => void;
  startPage?: boolean;
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "60vw",
    backgroundColor: theme.palette.background.grayPaper,
  },
  "& .MuiPaper-root.MuiDrawer-paper": {
    borderRadius: 0,
  },
}));

const HamburgerMenu = (props: HumburgerProps & TopBarProps): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const [{ building }] = useBuilding();
  const [open, setOpen] = useState(false);
  const [openAddHomeScreen, setOpenAddHomeScreen] = useState(false);
  const [{ user, mainUser, uiMode }, userActions] = useUser();
  const [{ unit, loading: unitLoading }] = useUnit();
  const [{ unitNavigationItems, buildingNavigationItems }] = useNavigation();

  const closeDrawer = () => setOpen(false);
  const openDrawer = () => setOpen(true);
  const [hasInstalled, promptToInstall] = useAddToHomescreenPrompt();

  const addToHomescreenClick = async () => {
    try {
      await promptToInstall();
    } catch {
      setOpenAddHomeScreen(true);
    }
  };

  const logOut = () => {
    userActions.logout();
    navigate("/");
  };

  return (
    <>
      <Grid container alignItems="center" sx={{ width: "auto" }}>
        {user && !props.startPage && (
          <IconButton onClick={() => navigate("/postbox")}>
            <Email fontSize="large" color={"inherit"} />
          </IconButton>
        )}
        <IconButton onClick={openDrawer}>
          <Menu fontSize="large" color={"inherit"} />
        </IconButton>
        <StyledDrawer anchor="right" open={open} onClose={closeDrawer}>
          <DrawerHeader container justifyContent="space-between" alignItems="center" sx={{ paddingRight: 1 }}>
            <Grid item>
              <Typography>{t("Footer.Myhouse", { hostname: window.location.hostname })}</Typography>
              {unit && <Typography style={{ letterSpacing: 1 }}>{UnitHelper.GetFullAddress(unit.Address)}</Typography>}
            </Grid>
            <Grid item>
              <Close onClick={closeDrawer} />
            </Grid>
          </DrawerHeader>
          <DrawerProfile container direction="column" alignItems="center" onClick={closeDrawer}>
            {user && (
              <>
                <Typography variant="body2">{t("Menu.SignedIn")}</Typography>
                <Typography variant="body2">{user?.Name}</Typography>
                {!mainUser && <ProfileImg variant="circular" user={user}></ProfileImg>}
                {mainUser && (
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={<SmallProfileImg variant="circular" user={user} />}
                    sx={{ marginRight: 2 }}
                  >
                    <ProfileImg variant="circular" user={mainUser} />
                  </Badge>
                )}
                <ProfileLink variant="body2" onClick={() => navigate("/profile")}>
                  {t("Menu.EditProfile")}
                </ProfileLink>
                {!!user?.Role && user?.Role >= UserType.ExternalManager && (
                  <ProfileLink variant="body2" onClick={() => navigate("/admin")}>
                    {t("Menu.Admin")}
                  </ProfileLink>
                )}
                {!mainUser && (
                  <ProfileLink variant="body2" onClick={logOut}>
                    {t("Menu.LogOut")}
                  </ProfileLink>
                )}
                {mainUser && (
                  <ProfileLink variant="body2" onClick={userActions.unTryUser}>
                    ({user.Name}) {t("Menu.LogOut")}
                  </ProfileLink>
                )}
              </>
            )}
            {!user && !props.hideButtons && (
              <>
                {props.craftsmensLoginUrl && (
                  <Button href={props.craftsmensLoginUrl} target="_blank" rel="noopener noreferrer">
                    <ProfileLink variant="body2">{t("Menu.PartnerLogin")}</ProfileLink>
                  </Button>
                )}
                {props.registerClick && !props.hideAuth && (
                  <ProfileLink variant="body2" onClick={props.registerClick}>
                    {t("General.Buttons.Create")}
                  </ProfileLink>
                )}
                {props.loginClick && (
                  <ProfileLink variant="body2" onClick={props.loginClick}>
                    {t("Menu.HomeownerLogin")}
                  </ProfileLink>
                )}
              </>
            )}
            {/* {!hasInstalled && ( */}
            <ProfileLink variant="body2" align="center" onClick={addToHomescreenClick}>
              {t("Base.Dialog.AddToHomeScreenTitle")}
            </ProfileLink>
            {/* )} */}
          </DrawerProfile>
          <DrawerList container direction="column" alignItems="flex-end" onClick={closeDrawer}>
            {props.startPage && (
              <>
                {startPageNavigationItems.map((item) => (
                  <Grid onClick={() => navigate(item.url)} key={item.id}>
                    <Typography variant="body2">{t(item.name)}</Typography>
                  </Grid>
                ))}
                <Divider sx={{ minWidth: "100%" }} />
              </>
            )}
            {(uiMode === "unit"
              ? unitNavigationItems
              : buildingNavigationItems.filter((item) => !item.UnitSensitive || (building && item.UnitSensitive))
            )
              .filter(
                (item) =>
                  item.TargetUrl !== "/overview" &&
                  item.IsPublished &&
                  (!item.UserSensitive || (item.UserSensitive && user?.Rating && item.Rating <= user.Rating))
              )
              .map((item, index) => {
                if (item.UnitSensitive && unitLoading) {
                  return <Skeleton width="100%" height={30} animation="wave" key={index} />;
                } else {
                  return (
                    <Grid
                      onClick={() => menuClick(item.TargetUrl, item.UnitSensitive, unit, building, navigate, uiMode)}
                      key={item.OrderNumber}
                    >
                      <Typography align="right" variant="body2">
                        {GetTranslatedText(item.Titles)}
                      </Typography>
                    </Grid>
                  );
                }
              })}
          </DrawerList>
        </StyledDrawer>
      </Grid>
      <AddToHomeScreenDialog open={openAddHomeScreen} handleClose={() => setOpenAddHomeScreen(false)} />
    </>
  );
};

export default HamburgerMenu;
