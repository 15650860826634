import { Dispatch, AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { ApplicationState } from "../../reducers/store";
import { AppAction } from "../../definitions/Action";
import CO2PlanAdminService from "../../services/admin/admin.co2plan.service";
import { CO2PlanActionTypes } from "../../pages/ClimatePlan/shared/co2plan.actiontypes";
import {
  CO2EconomyLink,
  CO2Plan,
  CO2PlanMetaCategoryType,
  MetaQuestions,
} from "../../pages/ClimatePlan/shared/CO2Plan";
import { AdminCO2PlanActionTypes } from "../../constants/admin/admin.co2plan.actiontypes";
import { AdminActionTypes } from "../../constants/admin/admin.actiontypes";
import { operationFailedActionGeneral } from "..";
import { CO2PlanAdminStoreState } from "../../reducers/admin/admin.co2plan.reducer";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, AdminActionTypes.ADMIN_OPERATION_FAILED);
};

export const getAdminCO2Plan =
  (unitId: string, companyId?: string) =>
  async (dispatch: Dispatch<AppAction>): Promise<CO2Plan | undefined> => {
    try {
      dispatch({
        type: CO2PlanActionTypes.GET_CO2_PLAN,
      });

      const result = await CO2PlanAdminService.getPlanAsAdmin(unitId, companyId);
      const fulfilledActions = result.ActionsTimeLine.map((record) => {
        record.WasFulfilledWhenLoad = record.IsFulfilled;
        return record;
      });
      result.ActionsTimeLine = fulfilledActions;

      dispatch({
        type: CO2PlanActionTypes.SET_CO2_PLAN,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const getUnitsWithCO2Plans =
  (
    skip: number,
    take: number,
    communes: Array<number>,
    address: string,
    orderBy: string,
    desc: boolean,
    company?: string
  ) =>
  async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({ type: AdminCO2PlanActionTypes.GET_UNITS_WITH_CO2_PLANS });
      const result = await CO2PlanAdminService.getUnitsWithCO2Plans(
        skip,
        take,
        communes,
        address,
        orderBy,
        desc,
        company
      );
      dispatch({
        type: AdminCO2PlanActionTypes.GET_UNITS_WITH_CO2_PLANS_SUCCEEDED,
        payload: { plans: result.Results, resetExisting: skip === 0 },
      });
      dispatch({
        type: CO2PlanActionTypes.GET_TOTAL_CO2_PLANS_COUNT_SUCCEEDED,
        payload: result.Count,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const getCO2SponsorSolutions = (skip: number, take: number) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminCO2PlanActionTypes.GET_CO2_SPONSOR_SOLUTIONS });
    const result = await CO2PlanAdminService.getSponsorSolutions(skip, take);
    dispatch({
      type: AdminCO2PlanActionTypes.GET_CO2_SPONSOR_SOLUTIONS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const createCO2SponsorSolutions =
  (
    companyId: string,
    emissionSourceGroupId: string,
    emissionSourceChildGroups: string[],
    displayName: string,
    CO2Emission: number,
    widgetId: string | null
  ) =>
  async (dispatch: Dispatch<AppAction>) => {
    try {
      const result = await CO2PlanAdminService.createSponsorSolution(
        companyId,
        emissionSourceGroupId,
        emissionSourceChildGroups,
        displayName,
        CO2Emission,
        widgetId
      );
      dispatch({
        type: AdminCO2PlanActionTypes.ADD_CO2_SPONSOR_SOLUTION_SUCCEEDED,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const editCO2SponsorSolutions =
  (
    id: string,
    companyId: string,
    emissionSourceGroupId: string,
    emissionSourceChildGroups: string[],
    displayName: string,
    CO2Emission: number,
    widgetId: string | null
  ) =>
  async (dispatch: Dispatch<AppAction>) => {
    try {
      const result = await CO2PlanAdminService.editSponsorSolution(
        id,
        companyId,
        emissionSourceGroupId,
        emissionSourceChildGroups,
        displayName,
        CO2Emission,
        widgetId
      );
      dispatch({
        type: AdminCO2PlanActionTypes.EDIT_CO2_SPONSOR_SOLUTION_SUCCEEDED,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const deleteCO2SponsorSolutions = (id: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    await CO2PlanAdminService.deleteSponsorSolution(id);
    dispatch({
      type: AdminCO2PlanActionTypes.DELETE_CO2_SPONSOR_SOLUTION_SUCCEEDED,
      payload: id,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const getCO2EconomyItems = (skip: number, take: number) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminCO2PlanActionTypes.GET_CO2_ECONOMY_ITEMS });
    const result = await CO2PlanAdminService.getCO2EconomyItems(skip, take);
    dispatch({
      type: AdminCO2PlanActionTypes.GET_CO2_ECONOMY_ITEMS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const createCO2EconomyItem =
  (
    emissionSourceGroupId: string,
    displayName: string,
    externalLink: string,
    hideCommunes: Array<number>,
    showCommunes: Array<number>,
    links: Array<CO2EconomyLink>
  ) =>
  async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({
        type: AdminCO2PlanActionTypes.ADD_CO2_ECONOMY_ITEM,
      });
      const result = await CO2PlanAdminService.createCO2EconomyItem(
        emissionSourceGroupId,
        displayName,
        externalLink,
        hideCommunes,
        showCommunes,
        links
      );
      dispatch({
        type: AdminCO2PlanActionTypes.ADD_CO2_ECONOMY_ITEM_SUCCEEDED,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const editCO2EconomyItem =
  (
    id: string,
    emissionSourceGroupId: string,
    displayName: string,
    externalLink: string,
    hideCommunes: Array<number>,
    showCommunes: Array<number>,
    links: Array<CO2EconomyLink>
  ) =>
  async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({
        type: AdminCO2PlanActionTypes.EDIT_CO2_ECONOMY_ITEM,
      });

      const result = await CO2PlanAdminService.editCO2EconomyItem(
        id,
        emissionSourceGroupId,
        displayName,
        externalLink,
        hideCommunes,
        showCommunes,
        links
      );
      dispatch({
        type: AdminCO2PlanActionTypes.EDIT_CO2_ECONOMY_ITEM_SUCCEEDED,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const deleteCO2EconomyItem = (id: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: AdminCO2PlanActionTypes.DELETE_CO2_ECONOMY_ITEM,
    });
    await CO2PlanAdminService.deleteCO2EconomyItem(id);
    dispatch({
      type: AdminCO2PlanActionTypes.DELETE_CO2_ECONOMY_ITEM_SUCCEEDED,
      payload: id,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const getAgeGroupedItems = () => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminCO2PlanActionTypes.GET_AGE_GROUPED_ITEMS });
    const result = await CO2PlanAdminService.getAgeGroupedItems();

    dispatch({
      type: AdminCO2PlanActionTypes.GET_AGE_GROUPED_ITEMS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const getCO2PlanMetaItems = (skip: number, take: number) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminCO2PlanActionTypes.GET_CO2_PLAN_META_ITEMS });
    const result = await CO2PlanAdminService.getCO2PlanMetaItems(skip, take);
    dispatch({
      type: AdminCO2PlanActionTypes.GET_CO2_PLAN_META_ITEMS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const getCO2PlanMetaStats = (metaId: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminCO2PlanActionTypes.GET_CO2_PLAN_META_STATS });
    const result = await CO2PlanAdminService.getCO2PlanMetaStats(metaId);
    dispatch({
      type: AdminCO2PlanActionTypes.GET_CO2_PLAN_META_STATS_SUCCEEDED,
      payload: result,
    });

    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const createCO2PlanMetaItem =
  (
    displayName: string,
    initialSurvey: boolean,
    targetSurvey: boolean,
    mainToolBar: boolean,
    companies: Array<string>,
    communes: Array<number>,
    questions: Array<MetaQuestions>,
    targetCategory: CO2PlanMetaCategoryType,
    placeAfterSourceId: string
  ) =>
  async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({
        type: AdminCO2PlanActionTypes.ADD_CO2_PLAN_META_ITEM,
      });
      const result = await CO2PlanAdminService.createCO2PlanMetaItem(
        displayName,
        initialSurvey,
        targetSurvey,
        mainToolBar,
        companies,
        communes,
        questions,
        targetCategory,
        placeAfterSourceId
      );
      dispatch({
        type: AdminCO2PlanActionTypes.ADD_CO2_PLAN_META_ITEM_SUCCEEDED,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const editCO2PlanMetaItem =
  (
    id: string,
    displayName: string,
    initialSurvey: boolean,
    targetSurvey: boolean,
    mainToolBar: boolean,
    companies: Array<string>,
    communes: Array<number>,
    questions: Array<MetaQuestions>,
    targetCategory: CO2PlanMetaCategoryType,
    placeAfterSourceId: string
  ) =>
  async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({
        type: AdminCO2PlanActionTypes.EDIT_CO2_PLAN_META_ITEM,
      });

      const result = await CO2PlanAdminService.editCO2PlanMetaItem(
        id,
        displayName,
        initialSurvey,
        targetSurvey,
        mainToolBar,
        companies,
        communes,
        questions,
        targetCategory,
        placeAfterSourceId
      );
      dispatch({
        type: AdminCO2PlanActionTypes.EDIT_CO2_PLAN_META_ITEM_SUCCEEDED,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const deleteCO2PlanMetaItem = (id: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: AdminCO2PlanActionTypes.DELETE_CO2_PLAN_META_ITEM,
    });
    await CO2PlanAdminService.deleteCO2PlanMetaItem(id);
    dispatch({
      type: AdminCO2PlanActionTypes.DELETE_CO2_PLAN_META_ITEM_SUCCEEDED,
      payload: id,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const useCO2PlanActions = () => {
  const dispatch = useDispatch<ThunkDispatch<ApplicationState, any, AnyAction>>();
  return {
    getCO2PlanMetaItems: (skip: number, take: number) => dispatch(getCO2PlanMetaItems(skip, take)),
    getUnitsWithCO2Plans: (
      skip: number,
      take: number,
      communes: Array<number>,
      address: string,
      orderBy: string,
      desc: boolean,
      company?: string
    ) => dispatch(getUnitsWithCO2Plans(skip, take, communes, address, orderBy, desc, company)),
    getCO2SponsorSolutions: (skip: number, take: number) => dispatch(getCO2SponsorSolutions(skip, take)),
    createCO2SponsorSolutions: (
      companyId: string,
      emissionSourceGroupId: string,
      emissionSourceChildGroups: string[],
      displayName: string,
      CO2Emission: number,
      widgetId: string | null
    ) =>
      dispatch(
        createCO2SponsorSolutions(
          companyId,
          emissionSourceGroupId,
          emissionSourceChildGroups,
          displayName,
          CO2Emission,
          widgetId
        )
      ),
    editCO2SponsorSolutions: (
      id: string,
      companyId: string,
      emissionSourceGroupId: string,
      emissionSourceChildGroups: string[],
      displayName: string,
      CO2Emission: number,
      widgetId: string | null
    ) =>
      dispatch(
        editCO2SponsorSolutions(
          id,
          companyId,
          emissionSourceGroupId,
          emissionSourceChildGroups,
          displayName,
          CO2Emission,
          widgetId
        )
      ),
    deleteCO2SponsorSolutions: (id: string) => dispatch(deleteCO2SponsorSolutions(id)),
    getCO2EconomyItems: (skip: number, take: number) => dispatch(getCO2EconomyItems(skip, take)),
    createCO2EconomyItem: (
      emissionSourceGroupId: string,
      displayName: string,
      externalLink: string,
      hideCommunes: Array<number>,
      showCommunes: Array<number>,
      links: Array<CO2EconomyLink>
    ) =>
      dispatch(
        createCO2EconomyItem(emissionSourceGroupId, displayName, externalLink, hideCommunes, showCommunes, links)
      ),
    editCO2EconomyItem: (
      id: string,
      emissionSourceGroupId: string,
      displayName: string,
      externalLink: string,
      hideCommunes: Array<number>,
      showCommunes: Array<number>,
      links: Array<CO2EconomyLink>
    ) =>
      dispatch(
        editCO2EconomyItem(id, emissionSourceGroupId, displayName, externalLink, hideCommunes, showCommunes, links)
      ),
    deleteCO2EconomyItem: (id: string) => dispatch(deleteCO2EconomyItem(id)),
    getAgeGroupedItems: () => dispatch(getAgeGroupedItems()),
    createCO2PlanMetaItem: (
      displayName: string,
      initialSurvey: boolean,
      targetSurvey: boolean,
      mainToolBar: boolean,
      companies: Array<string>,
      communes: Array<number>,
      questions: Array<MetaQuestions>,
      targetCategory: CO2PlanMetaCategoryType,
      placeAfterSourceId: string
    ) =>
      dispatch(
        createCO2PlanMetaItem(
          displayName,
          initialSurvey,
          targetSurvey,
          mainToolBar,
          companies,
          communes,
          questions,
          targetCategory,
          placeAfterSourceId
        )
      ),
    editCO2PlanMetaItem: (
      id: string,
      displayName: string,
      initialSurvey: boolean,
      targetSurvey: boolean,
      mainToolBar: boolean,
      companies: Array<string>,
      communes: Array<number>,
      questions: Array<MetaQuestions>,
      targetCategory: CO2PlanMetaCategoryType,
      placeAfterSourceId: string
    ) =>
      dispatch(
        editCO2PlanMetaItem(
          id,
          displayName,
          initialSurvey,
          targetSurvey,
          mainToolBar,
          companies,
          communes,
          questions,
          targetCategory,
          placeAfterSourceId
        )
      ),
    deleteCO2PlanMetaItem: (id: string) => dispatch(deleteCO2PlanMetaItem(id)),
    getCO2PlanMetaStats: (metaId: string) => dispatch(getCO2PlanMetaStats(metaId)),
  };
};

export const useAdminCO2 = (): [CO2PlanAdminStoreState, ReturnType<typeof useCO2PlanActions>] => {
  const state = useSelector((state: ApplicationState) => state.adminCo2Plan);
  const actions = useCO2PlanActions();

  return [state, actions];
};
