import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useState } from "react";
import { LandingMetaModel } from "../../../../../definitions/Landing";
import { PathAutocompleteFieldProps, AutocompleteItem } from "../defenitions";
import { Controller } from "react-hook-form";
import {TextFieldProps} from "@mui/material";

const PathAutocompleteField = (
  props: PathAutocompleteFieldProps
): JSX.Element => {
  const [options, setOptions] = useState<AutocompleteItem[]>([]);

  const getOptionSelected = (
    option: AutocompleteItem,
    value: AutocompleteItem | string
  ) => {
    return typeof value !== "string" && option.path === value.path;
  };

  const getOptionLabel = (o: AutocompleteItem | string) => {
    if (typeof o === "object") {
      return o.label
    }
    return o
  }


  useEffect(() => {
    props.onLoad("").then((x) => setOptions(makeOptions(x.Results)));
  }, []);

  const makeOptions = (landings: LandingMetaModel[]) => {
    const options = landings.map((l) => ({
      label: `${l.Title} - /landing/${l.UniqueName}`,
      path: `/landing/${l.UniqueName}`,
      title: l.Title,
    }));

    options.unshift({
      label: "All",
      path: "all",
      title: "All",
    });

    return options;
  };

  return (
    <Controller
      render={({ field: {onChange, value} }) => (
        <Autocomplete
          freeSolo
          disableClearable
          value={value as any}
          options={options}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={getOptionSelected}
          onChange={(e, v: AutocompleteItem | string) => {
            if (v && typeof v !== "string") {
              onChange(v.path)
            }
          }}
          defaultValue={props.defaultValue}
          onInputChange={(e, v) => onChange(v)}
          loading={props.loading}
          fullWidth
          renderInput={(params) => (
            <TextField
              margin="dense"
              variant="outlined"
              label={props.label}
              {...params as TextFieldProps}
            />
          )}
        />
      )}
      name={props.name}
      control={props.control}
    />
  );
};

export default PathAutocompleteField;
