import * as React from 'react';
import {BsListCheck} from 'react-icons/bs';
import {BsCheckCircle} from 'react-icons/bs';
import {HiTrendingUp} from 'react-icons/hi';
import {BsPatchCheck} from 'react-icons/bs';
import {AiOutlinePushpin} from 'react-icons/ai';
import { partsToShow } from '../../CO2Plan';

export type MenuItem = {
  key: string;
  root: string;
  icon: React.ReactElement;
  mobileName?: string;
  partsToShow: partsToShow;
};


export const menuItems: MenuItem[] = [
  
    {
      key: "ClimatePlan.SideMenu.MуTest",     
      mobileName: "ClimatePlan.SideMenu.MуTest",
      root: "/co2calculator",
      partsToShow: partsToShow.InitialSurvey,
      icon: <BsListCheck fontSize={30} color="inherit"  />,
    },
    {
      key: "ClimatePlan.SideMenu.Status",
      mobileName: "ClimatePlan.SideMenu.Status",
      partsToShow: partsToShow.InitialResults,
      root: "/co2calculator/initial-results",
      icon:  <BsCheckCircle fontSize={30} color="inherit" />,
    },
    {
      key: "ClimatePlan.SideMenu.MyGoal",
      mobileName: "ClimatePlan.SideMenu.MyGoal",
      partsToShow: partsToShow.TargetSurvey,
      root: "/co2calculator/target-survey",
      icon: <HiTrendingUp fontSize={30} color="inherit" />,
    },
    {
      key: "ClimatePlan.SideMenu.NewStatus",
      mobileName: "ClimatePlan.SideMenu.NewStatus",
      partsToShow: partsToShow.TargetResults,
      root: "/co2calculator/target-results",
      icon: <BsPatchCheck fontSize={30} color="inherit" />,
    },
    {
      key: "ClimatePlan.SideMenu.ToDoLists",
      mobileName: "ClimatePlan.SideMenu.ToDo",
      partsToShow: partsToShow.ActionsTimeline,
      root: "/co2calculator/todo",
      icon: <AiOutlinePushpin fontSize={30} color="inherit" />,
    }
  
  ];