import React from "react";
import BaseDialog from "../../../components/Dialogs/BaseDialog";
import { Button, Grid, Icon, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import Logo from "../../../assets/ourClimatePlan.png";

type Props = {
  open: boolean;
  closeDialog: () => void;
  changePropertyData: ChangePropertyData;
};

export type ChangePropertyData = {
  adressIn?: string;
  adressOut?: string;
};

const LogoTypography = styled(Typography)(({ theme }) => ({
  letterSpacing: 12,
  transform: "translateY(-20px)",
  [theme.breakpoints.up("sm")]: {
    letterSpacing: 8,
    fontSize: 12,
  },
}));

const ChangePropertyDialog = (props: Props) => {
  const { t } = useTranslation("translation");

  return (
    <BaseDialog
      open={props.open}
      closeDialog={props.closeDialog}
      titleComponent={
        <Grid container justifyContent="start">
          <LogoTypography variant="body2">myhouse</LogoTypography>
        </Grid>
      }
    >
      <Typography variant="body1">
        {t("Family.YouMovedOut")} {props.changePropertyData.adressOut}
      </Typography>
      {props.changePropertyData.adressIn && (
        <Typography variant="body1">
          {t("Base.Popovers.To")} {props.changePropertyData.adressIn}
        </Typography>
      )}
      <Grid marginTop={4} container justifyContent="center">
        <Button variant="contained" onClick={props.closeDialog} color="secondary">
          {t("General.Buttons.Ok")}
        </Button>
      </Grid>
    </BaseDialog>
  );
};

export default ChangePropertyDialog;
