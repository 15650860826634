import React from "react";
import { Grid, Typography, Link, GridProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavigationHelper from "../../helpers/navigation-helper";

interface WrapperGridProps extends GridProps {
  transparent?: boolean;
}

const WrapperGrid = styled(Grid, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "transparent",
})<WrapperGridProps>(({ theme, transparent }) => ({
  padding: "20px 0",
  background: transparent ? "transparent" : theme.palette.background.grayPaper,
}));

const FooterLink = styled(Link)(() => ({
  color: "inherit",
  fontSize: 13,
  cursor: "pointer",
}));

const InfoGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.primary.contrastText,
  padding: "20px 0",
  "& p": {
    textAlign: "center",
    fontSize: 10,
  },
}));

type FooterProps = {
  transparent?: boolean;
  hideFooterLinks?: boolean;
};

const Footer = (props: FooterProps): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const klimaplan = NavigationHelper.IsClimateSite();
  
  return (
    <Grid container>
      {!props.hideFooterLinks && (
        <WrapperGrid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          transparent={props.transparent}
        >
          <Grid item>
            <Typography align="center" variant="body2">
              {klimaplan && (
                <FooterLink onClick={() => navigate("/validation")} underline="hover">
                  {t("Footer.Validation")}
                </FooterLink>
              )}{" "}
              {klimaplan ? (
                <FooterLink onClick={() => navigate("/about-our-climate")} underline="hover">
                  {t("Footer.Myhouse", { hostname: window.location.hostname })}
                </FooterLink>
              ) : (
                <FooterLink onClick={() => navigate("/about-us")} underline="hover">
                  {t("Footer.Myhouse", { hostname: window.location.hostname })}
                </FooterLink>
              )}{" "}
              <FooterLink onClick={() => navigate("/contact")} underline="hover">
                {t("Footer.TermsOfUseAndPrivacyPolicy")}
              </FooterLink>
            </Typography>
          </Grid>
          {!klimaplan && (
            <Grid item>
              <Typography align="center" variant="body2">
                <FooterLink href="https://www.facebook.com/www.myhouse.dk/" target="_blank" underline="hover">
                  {t("Footer.FollowUsOnFacebook")}{" "}
                </FooterLink>{" "}
              </Typography>
            </Grid>
          )}
        </WrapperGrid>
      )}
      <InfoGrid container justifyContent="center">
        <Typography>{t("Footer.Address", { hostname: window.location.hostname })}</Typography>
      </InfoGrid>
    </Grid>
  );
};

export default Footer;
