import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import NoPhoto from "../../../assets/img/no_house.jpg";
import { useDoubleClick, useTranslatedGroupRoles } from "../../../helpers/hooks";
import { GroupMemberRole, UserType } from "../../../constants/enums";
import { useTranslation } from "react-i18next";
import UnitHelper from "../../../helpers/unit-helper";
import { useUser } from "../../../actions/user.actions";
import { OwnedBuildingDto } from "../../../definitions/model/Building";
import { useBuilding } from "../../../actions/building.actions";

const StyledCard = styled(Card, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "isMovedIn" && propName !== "currentHouse",
})<{ isMovedIn: boolean; currentHouse: boolean }>(({ theme, isMovedIn, currentHouse }) => ({
  transition: "border-color 0.3s",
  cursor: isMovedIn ? "pointer" : "initial",
  height: "100%",
  borderColor: currentHouse ? theme.palette.primary.main : theme.palette.grey[400],
  "&:hover": {
    borderColor: theme.palette.grey[600],
  },
}));

const StyledCardMedia = styled(CardMedia, {
  shouldForwardProp: (propName: string) => propName !== "imageUrl",
})<{ imageUrl: string; component: React.ReactNode }>(({ imageUrl }) => ({
  backgroundImage: `url('${imageUrl}'), url('${NoPhoto}')`,
  paddingTop: 155,
}));

type BuildingCardProps = {
  building: OwnedBuildingDto;
  onBuildingClick: (building: OwnedBuildingDto) => void;
  onMembersClick: (id: string) => void;
  onChnageCurrentBuilding: (id: string) => Promise<void>;
  onMoveOut: (unitId: string, hasCo2Plan: boolean) => void;
};

const BuildingCard = ({
  building,
  onBuildingClick,
  onMembersClick,
  onChnageCurrentBuilding,
  onMoveOut,
}: BuildingCardProps): JSX.Element => {
  const isCardDisabled = building.CurrentUserRole === GroupMemberRole.NotValidMember;

  const { t } = useTranslation("translation");
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [{ user }] = useUser();
  const { getDisplayGroupRole } = useTranslatedGroupRoles();
  const [{ building: activeBuilding }] = useBuilding();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const handleBuildingClick = (building: OwnedBuildingDto) => (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isCardDisabled || user?.Role === UserType.Administrator) onBuildingClick(building);
  };

  const handleChangeBuildingClick = (buildingId: string) => (e: React.MouseEvent) => {
    e.stopPropagation();
    onChnageCurrentBuilding(buildingId);
    handleClose(e);
  };

  const handleMoveOut = (buildignId: string) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onMoveOut(buildignId, false);
    handleClose(e);
  };

  const handleMembersClick = (buildingId: string) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onMembersClick(buildingId);
    handleClose(e);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onCardClick = isCardDisabled
    ? () => {}
    : useDoubleClick(handleBuildingClick(building), handleChangeBuildingClick(building.Id), isXs ? 600 : 300);

  const address = UnitHelper.GetFullAddress(building.Address, false);

  return (
    <StyledCard
      isMovedIn={isCardDisabled}
      currentHouse={activeBuilding?.Id == building.Id}
      variant="outlined"
      onClick={onCardClick}
    >
      <CardHeader
        title={`${address}${building.PropertyNumber ? ` (${building.PropertyNumber})` : ""}`}
        subheader={`${building.Address.PostalCode} ${building.Address.Commune.CommuneName}`}
        action={
          <IconButton size="small" onClick={handleClick}>
            <MoreVert />
          </IconButton>
        }
      />
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem sx={{ padding: "4px" }} disabled={isCardDisabled} onClick={handleMembersClick(building.Id)}>
          {t("BuildingManagement.EditMembers")}
        </MenuItem>
        <MenuItem sx={{ padding: "4px" }} onClick={handleMoveOut(building.Id)}>
          {t("BuildingManagement.MoveOut")}
        </MenuItem>
      </Menu>

      <StyledCardMedia imageUrl={building.Photo} component="div" />
      <CardContent>
        <Typography>
          {t("BuildingManagement.BuildingOwner")}:{" "}
          {user?.Name === building.OwnerName ? t("General.Management.You") : building.OwnerName}
        </Typography>
        <Typography>
          {t("General.Management.Role")}: {getDisplayGroupRole(building.CurrentUserRole)}
        </Typography>
        <Typography>{!isCardDisabled && `${t("General.Management.Members")}: ${building.MembersCount}`}</Typography>
        {Boolean(building.NotApprovedMembers) && !isCardDisabled && (
          <Typography>
            {t("General.Management.Pending")}: {building.NotApprovedMembers}
          </Typography>
        )}
        <Grid container justifyContent="center" sx={{ marginTop: "18px" }}>
          {activeBuilding?.Id == building.Id && (
            <Chip
              sx={{ fontSize: 12 }}
              variant="outlined"
              size="small"
              label={t("BuildingManagement.SelectedBuilding")}
              color="secondary"
            />
          )}
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

export default BuildingCard;
