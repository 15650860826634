import React, { CSSProperties } from "react";
import {
  Button,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
type LocalPlanInfoProps = {
  localPlan: {
    Name: string;
    Description: string;
    DocUrl: string;
  };
  style?: CSSProperties;
};

const LocalPlanInfo = ({
  localPlan,
  style,
}: LocalPlanInfoProps): JSX.Element => {
  const { t } = useTranslation("translation");

  return (
    <ListItem style={style}>
      {localPlan && (
        <ListItemText>
          <ListItemText
            primary={
              <span>
                {localPlan.Name} <br /> {localPlan.Description}
              </span>
            }
            secondary={<span>Status</span>}
          />
          <ListItemSecondaryAction>
            <Button
              href={localPlan.DocUrl}
              target="_blank"
              variant="contained"
              color="secondary"
            >
              {t("General.Buttons.Open")}
            </Button>
          </ListItemSecondaryAction>
        </ListItemText>
      )}
    </ListItem>
  );
};

export default LocalPlanInfo;
