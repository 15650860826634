import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from 'react-i18next';
import { ClubModel } from "../../../../../definitions/model/Club";
import { useForm } from "react-hook-form";
import { useAlertContext } from "../../../../../components/Base";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";

type ClubFormData = {
  Name: string;
  Description: string;
  TemplateId: string;
};

type ClubDialogProps = {
  open: boolean;
  club?: ClubModel;
  handleClose: () => void;
  createClub: (title: string, description: string) => Promise<void>;
};

const ClubCreationDialog = (props: ClubDialogProps): JSX.Element => {
  const { handleSubmit, register } = useForm<ClubFormData>();
  const { showAlert } = useAlertContext();

  const { t } = useTranslation("translation");

  const submit = (values: ClubFormData) =>
    props
      .createClub(values.Name, values.Description)
      .then(() => {
        showAlert({
          severity: "success",
          text: "Club created",
        });
        props.handleClose();
      })
      .catch(() => {
        showAlert({
          severity: "error",
          text: "Club not created",
        });
      });

  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Create article group</DialogTitle>
      <form onSubmit={handleSubmit(submit)}>
        <DialogContent>
          <TextFieldConnector register={register("Name", {required: true})}>
            <TextField
              variant="outlined"
              autoFocus
              margin="dense"
              label="Title"
              fullWidth
            />
          </TextFieldConnector>

          <TextFieldConnector register={register("Description", {required: true})}>
            <TextField
              variant="outlined"
              margin="dense"
              label="Description"
              multiline
              maxRows={3}
              fullWidth
            />
          </TextFieldConnector>

        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="button" onClick={props.handleClose} color="secondary">
            {t("General.Buttons.Undo")}
          </Button>
          <Button variant="contained" type="submit" color="secondary">
            {t("General.Buttons.Save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ClubCreationDialog;
