import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useBuilding } from "../../actions/building.actions";
import Folders from "../House/Documents/Folders";
import { DocumentSourceType } from "../../definitions/Document";
import { GroupMemberRole } from "../../constants/enums";
import { useUser } from "../../actions";

const BuildingDocumentsPage = (): JSX.Element => {
  const { addressURL } = useParams<{ addressURL: string }>();
  const [{ building, groupMembers }, buildingActions] = useBuilding();
  const [{ user }] = useUser();
  const userRole = groupMembers?.Members?.find((x) => x.UserId === user?.Id)?.Role ?? GroupMemberRole.NotValidMember;
  const isAdministration =
    userRole === GroupMemberRole.Owner ||
    userRole === GroupMemberRole.Administrator ||
    userRole === GroupMemberRole.Manager ||
    userRole === GroupMemberRole.Caretacker;

  useEffect(() => {
    if (addressURL) {
      const addressParts = addressURL.split("_");
      const id = addressParts[addressParts.length - 1];
      if (building?.Id !== id) {
        buildingActions.getBuilding(id);
      }
      if (!groupMembers || groupMembers.BuildingId !== id) {
        buildingActions.getBuildingMembers(id);
      }
    }
  }, [addressURL]);

  return (
    <>
      {building && (
        <Folders
          readonly={!isAdministration}
          sourceItemId={building.Id}
          sourceType={DocumentSourceType.Building}
          usePermissions
          childSourceItems={building.Units.map((u) => ({
            id: u.Id,
            name: `${u.Address.Street} ${u.Address.HouseNo} ${u.Address.Floor}-${u.Address.DoorNumber}`,
          }))}
          addBasicFolders
        />
      )}
    </>
  );
};

export default BuildingDocumentsPage;
