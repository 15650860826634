import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import {
  Controller,
  useForm,
  useFieldArray,
  UseFormRegister,
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormGetValues,
} from "react-hook-form";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from "@mui/material";
import {
  CO2EconomyItem,
  CO2EconomyLink,
  CO2EconomyLinkCategory,
  CO2EmissionSourceGroupDescription,
  EmissionSourceDto,
} from "../../../../ClimatePlan/shared/CO2Plan";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";
import { CommuneLocation } from "../../../../../definitions/model/unit/Commune";
import { TransferList } from "../../../../../components/Base";
import { AddLinkOutlined, LinkOff } from "@mui/icons-material";
import EconomyItemsGroupsInput from "./EconomyItemsGroupsInput";

export type EconomyItemFormData = {
  Id: string;
  EmissionSourceGroupId: string;
  DisplayName: string;
  ExternalLink: string;
  SubCategoryLinks: Array<SubCategoryLink>;
  HideCommunes: Array<CommuneLocation>;
  ShowCommunes: Array<CommuneLocation>;
};

export type SubCategoryLink = {
  Id: string;
  Link: string;
  Categories: Array<CO2EconomyLinkCategory>;
};

type EconomyItemEditDialogProps = {
  communes: Array<CommuneLocation>;
  economyItem: CO2EconomyItem | null;
  open: boolean;
  groups: CO2EmissionSourceGroupDescription[];
  handleClose: () => void;
  createCO2EconomyItem: (
    emissionSourceGroupId: string,
    displayName: string,
    externalLink: string,
    hideCommunes: Array<number>,
    showCommunes: Array<number>,
    links: Array<CO2EconomyLink>
  ) => Promise<void>;
  editCO2EconomyItem: (
    id: string,
    emissionSourceGroupId: string,
    displayName: string,
    externalLink: string,
    hideCommunes: Array<number>,
    showCommunes: Array<number>,
    links: Array<CO2EconomyLink>
  ) => Promise<void>;
};

export const GetFlatSources = (
  sources: Array<EmissionSourceDto>,
  prefix: string
) => {
  let result: Array<EmissionSourceDto> = [];
  for (const source of sources.sort((a, b) => a.Code - b.Code)) {
    const targetName = prefix
      ? `${prefix} -> ${source.TargetName ?? source.Name}`
      : `${source.TargetName ?? source.Name}`;
    result.push({ ...source, TargetName: targetName });

    if (source.ChildSources.length > 0)
      result = result.concat(GetFlatSources(source.ChildSources, targetName));
  }

  return result;
};

const EconomyItemCreateEditDialog = (
  props: EconomyItemEditDialogProps
): JSX.Element => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<EconomyItemFormData>();

  const [selectedGroup, setGroup] =
    useState<CO2EmissionSourceGroupDescription | null>(null);
  const [selectedHideCommunes, setSelectedHideCommunes] = useState<
    Array<CommuneLocation>
  >([]);
  const [selectedShowCommunes, setSelectedShowCommunes] = useState<
    Array<CommuneLocation>
  >([]);

  const { t } = useTranslation("translation");

  useEffect(() => {
    if (props.economyItem) {
      const links: Array<SubCategoryLink> = props.economyItem.Links.map((l) => {
        return { Id: l.SubCategoryId, Link: l.Link, Categories: l.Categories };
      });
      reset({
        EmissionSourceGroupId: props.economyItem.EmissionSourceGroupId,
        DisplayName: props.economyItem.DisplayName,
        ExternalLink: props.economyItem.ExternalLink,
        SubCategoryLinks: links,
      });

      if (props.economyItem.EmissionSourceGroupId) {
        setGroup(
          props.groups.find(
            (gr) => gr.Id === props.economyItem?.EmissionSourceGroupId
          ) ?? null
        );
      }
    }
  }, [props.economyItem]);

  useEffect(() => {
    if (!props.economyItem) {
      reset({
        Id: "",
        EmissionSourceGroupId: "",
        DisplayName: "",
        ExternalLink: "",
        HideCommunes: [],
        ShowCommunes: [],
        SubCategoryLinks: [],
      });
      setGroup(null);
      setSelectedHideCommunes([]);
      setSelectedShowCommunes([]);
    }
  }, [props.economyItem]);

  useEffect(() => {
    if (
      props.economyItem != null &&
      props.communes &&
      props.communes.length > 0
    ) {
      setSelectedHideCommunes(
        props.economyItem.HideCommunes.map((sc) => {
          return props.communes.filter(
            (c) => c.Commune.CommuneNumber === sc
          )[0];
        })
      );
      setSelectedShowCommunes(
        props.economyItem.ShowCommunes.map((sc) => {
          return props.communes.filter(
            (c) => c.Commune.CommuneNumber === sc
          )[0];
        })
      );
    }
  }, [props.communes, props.economyItem]);

  const submit = (values: EconomyItemFormData) => {
    const links = values.SubCategoryLinks.map((l) => {
      const sources = GetFlatSources(
        selectedGroup?.ChildSources || [],
        ""
      ).filter((source) =>
        l.Categories.find((category) => source.Id === category.CategoryId)
      );

      const link: CO2EconomyLink = {
        SubCategoryId: sources[0].Id,
        SubCategoryName: sources[0].TargetName || sources[0].Name,
        Link: l.Link,
        Categories: sources.map((source) => ({
          CategoryId: source.Id,
          CategoryName: source.Name,
        })),
      };
      return link;
    });

    if (props.economyItem) {
      props
        .editCO2EconomyItem(
          props.economyItem.Id,
          values.EmissionSourceGroupId,
          values.DisplayName,
          values.ExternalLink,
          values.HideCommunes.map((c) => c.Commune.CommuneNumber),
          values.ShowCommunes.map((c) => c.Commune.CommuneNumber),
          links
        )
        .then(() => props.handleClose());
    } else {
      props
        .createCO2EconomyItem(
          values.EmissionSourceGroupId,
          values.DisplayName,
          values.ExternalLink,
          values.HideCommunes.map((c) => c.Commune.CommuneNumber),
          values.ShowCommunes.map((c) => c.Commune.CommuneNumber),
          links
        )
        .then(() => props.handleClose());
    }
  };

  const setShowCommunesSelected = (selected: Array<CommuneLocation>) => {
    setValue("ShowCommunes", selected);

    const hideCommunes = getValues("HideCommunes");
    if (selected.length > 0 && hideCommunes?.length > 0) {
      setValue("HideCommunes", []);
      setSelectedHideCommunes([]);
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create economy item</DialogTitle>
      <form onSubmit={handleSubmit(submit)}>
        <DialogContent>
          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel>Group</InputLabel>
            <Controller
              name="EmissionSourceGroupId"
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  error={!!errors.EmissionSourceGroupId}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    setGroup(
                      props.groups.find((gr) => gr.Id === e.target.value) ??
                        null
                    );
                  }}
                >
                  {props.groups.map((group) => (
                    <MenuItem
                      key={group.Id}
                      value={group.Id}
                      style={{ padding: "10px 20px" }}
                    >
                      {t(group.ShortName)}
                    </MenuItem>
                  ))}
                </Select>
              )}
              control={control}
            />
          </FormControl>
          <TextFieldConnector
            register={register("DisplayName", { required: true })}
          >
            <TextField
              variant="outlined"
              autoFocus
              margin="dense"
              label="Button name"
              fullWidth
              error={!!errors.DisplayName}
            />
          </TextFieldConnector>

          <TextFieldConnector
            register={register("ExternalLink", { required: true })}
          >
            <TextField
              variant="outlined"
              margin="dense"
              label="External link"
              fullWidth
              error={!!errors.ExternalLink}
            />
          </TextFieldConnector>

          {selectedGroup && (
            <SubCategoriesFields
              {...{
                control,
                register,
                errors,
                setValue,
                getValues,
                childSources: selectedGroup.ChildSources,
              }}
            ></SubCategoriesFields>
          )}

          <Box sx={{ marginTop: 1 }}>
            <TransferList
              options={props.communes}
              selected={selectedShowCommunes}
              fieldLabel="Communes to show"
              placeholder="Communes to show"
              onChanges={setShowCommunesSelected}
              getOptionLabel={(o: CommuneLocation) => o.Commune.CommuneName}
              getOptionSelected={(s: CommuneLocation, o: CommuneLocation) =>
                s.Commune.CommuneNumber === o.Commune.CommuneNumber
              }
            />
          </Box>
          <Box sx={{ marginTop: 1.5 }}>
            <TransferList
              disabled={watch().ShowCommunes?.length > 0}
              options={props.communes}
              selected={selectedHideCommunes}
              fieldLabel="Communes to hide"
              placeholder="Communes to hide"
              onChanges={(selected: Array<CommuneLocation>) =>
                setValue("HideCommunes", selected)
              }
              getOptionLabel={(o: CommuneLocation) => o.Commune.CommuneName}
              getOptionSelected={(s: CommuneLocation, o: CommuneLocation) =>
                s.Commune.CommuneNumber === o.Commune.CommuneNumber
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            type="button"
            onClick={props.handleClose}
            color="secondary"
          >
            {t("General.Buttons.Close")}
          </Button>
          <Button variant="contained" type="submit" color="secondary">
            {t("General.Buttons.Save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

type SubCategoriesFieldsType = {
  control: Control<EconomyItemFormData, any>;
  register: UseFormRegister<EconomyItemFormData>;
  errors: FieldErrors<EconomyItemFormData>;
  childSources: Array<EmissionSourceDto>;
  setValue: UseFormSetValue<EconomyItemFormData>;
  getValues: UseFormGetValues<EconomyItemFormData>;
};

const SubCategoriesFields = ({
  control,
  register,
  errors,
  childSources,
  setValue,
  getValues,
}: SubCategoriesFieldsType): JSX.Element => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "SubCategoryLinks",
  });

  const GetSourcesLayers = (sources: EmissionSourceDto[]) => {
    let sourcesLayers: EmissionSourceDto[][] = [];
    sourcesLayers = [sources];

    if (sources[0].ChildSources.length > 0) {
      sourcesLayers = [
        ...sourcesLayers,
        ...GetSourcesLayers(sources[0].ChildSources),
      ];
    }

    return sourcesLayers;
  };

  const economyLayers = GetSourcesLayers(childSources);

  const sources = GetFlatSources(childSources, "");

  const addSubCategoryLink = () => {
    append({ Id: "", Link: "", Categories: [] });
  };

  return (
    <>
      {fields.map((link, index) => (
          <Grid container key={link.id}>
            <Grid item xs={12}>
              <TextFieldConnector
                register={register(`SubCategoryLinks.${index}.Link`, {
                  required: true,
                })}
              >
                <StyledTextField
                  variant="outlined"
                  margin="dense"
                  label="External link"
                  fullWidth
                  defaultValue={link.Link}
                  error={!!errors.SubCategoryLinks?.[index]?.Link}
                  InputProps={{
                    endAdornment: (
                      <>
                        <Divider orientation="vertical" />
                        <IconButton onClick={() => remove(index)}>
                          <LinkOff />
                        </IconButton>
                      </>
                    ),
                  }}
                />
              </TextFieldConnector>
              <EconomyItemsGroupsInput
                setValue={setValue}
                economyLayers={economyLayers}
                link={link}
                sources={sources}
                getValues={getValues}
                index={index}
              />
            </Grid>
          </Grid>
      ))}
      <Button
        variant="text"
        startIcon={<AddLinkOutlined />}
        onClick={addSubCategoryLink}
      >
        Add Link
      </Button>
    </>
  );
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: 0,
  ".MuiOutlinedInput-notchedOutline": {
    borderBottom: "none",
  },
  ".MuiInputBase-root": {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

export default EconomyItemCreateEditDialog;
