export const SettingsHistoryValues: any = {
    // current permission:
    ReceiveNotifications: "SettingsHistoryValues.ReceiveNotifications",
    ReceiveCalls: "SettingsHistoryValues.ReceiveCalls",
    ReceiveFacebookNotifications: "SettingsHistoryValues.ReceiveFacebookNotifications",
    LastLogin: "SettingsHistoryValues.LastLogin",
    // old permissions, not used anymore:
    ViaEmail: "SettingsHistoryValues.ViaEmail",
    ViaFacebook: "SettingsHistoryValues.ViaFacebook",
    UpdatesViaMail: "SettingsHistoryValues.UpdatesViaMail",
    IsFavoriteNotificationsEnabled: "SettingsHistoryValues.IsFavoriteNotificationsEnabled",
    DIY: "SettingsHistoryValues.DIY",
    Garden: "SettingsHistoryValues.Garden",
    Accommodation: "SettingsHistoryValues.Accommodation",
    HouseDigitalization: "SettingsHistoryValues.HouseDigitalization",
    Culture: "SettingsHistoryValues.Culture",
    Sport: "SettingsHistoryValues.Sport",
}