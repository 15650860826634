import React, { useReducer } from "react";
import {
  Collapse,
  ListItem,
  Typography,
  Grid,
  Button,
  Divider,
  TextField, styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  UserMailModel,
  UserMailContract,
} from "../../../definitions/model/UserMail";
import format from "date-fns/format";
import dalocale from "date-fns/locale/da";

const MessageBody = styled(Grid)(({theme})=>({
  marginTop: 5,
  padding: 5,
  boxSizing: "border-box",
  background: "#FFF",
}))

type PostBoxMessageProps = {
  messages: UserMailModel[];
  replyToMessage: (message: UserMailContract) => void;
  removeMessage: (messageId: string) => void;
  currentuserId?: string;
};

type PostBoxMessageState = {
  open: boolean;
  openReply: boolean;
  text: string;
  [index: string]: any;
};

const initialState: PostBoxMessageState = {
  open: false,
  openReply: false,
  text: "",
};

const reducer = (
  state: PostBoxMessageState,
  action: { type: string; payload: any }
) => {
  return { ...state, [action.type]: action.payload };
};

const PostBoxMessage = (props: PostBoxMessageProps) => {
  const { t } = useTranslation("translation");

  const [state, dispatch] = useReducer(reducer, initialState);

  const toggleOpen = () => {
    if (state.open) {
      dispatch({ type: "openReply", payload: false });
      dispatch({ type: "text", payload: "" });
    }
    dispatch({ type: "open", payload: !state.open });
  };

  const processReply = () => {
    if (!state.openReply) {
      dispatch({ type: "openReply", payload: true });
      return;
    }
    const message: UserMailContract = {
      Subject: props.messages[0].Subject,
      Recipients: props.messages[0].UserIds,
      Html: "",
      Text: state.text,
      ImageUrl: "",
      ThreadId: props.messages[0].ThreadId,
    };

    props.replyToMessage(message);
    dispatch({ type: "text", payload: "" });
    dispatch({ type: "openReply", payload: false });
    dispatch({ type: "open", payload: false });
  };

  const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: "text", payload: event.currentTarget.value });
  };

  const removeMessage = () => {
    props.removeMessage(props.messages[0].Id);
  };

  return (
    <>
      <ListItem selected={state.open}>
        <Grid container direction="column" style={{ minHeight: 63 }}>
          <Grid item container direction="row" justifyContent="space-between">
            <Grid item>
              <Grid item container direction="column" alignItems="flex-start">
                <Typography variant="subtitle1">
                  {t("ServiceBook.Offer.From")}
                  {props.messages[props.messages.length - 1].SendFrom ===
                  props.currentuserId
                    ? t("Pages.PostBox.Me")
                    : props.messages[props.messages.length - 1].SenderName}
                </Typography>
                <Typography variant="body2">
                  {t("Pages.PostBox.Subject")}
                  {props.messages[0].Subject}
                </Typography>
                <Typography variant="body2">
                  {t("Pages.PostBox.Sent")}
                  {format(
                    new Date(props.messages[props.messages.length - 1].Created),
                    "eeee dd/MM.yyyy hh:mm",
                    { locale: dalocale }
                  )}
                </Typography>
              </Grid>
            </Grid>
            {!state.open && (
              <Grid item style={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={toggleOpen}
                >
                  {t("General.Buttons.Open")}
                </Button>
              </Grid>
            )}
          </Grid>
          <Collapse in={state.open}>
            <Grid item container direction="column" spacing={1}>
              <MessageBody item xs={12}>
                {props.messages.reduce(
                  (p: JSX.Element, c, i) => (
                    <Grid
                      style={
                        i < props.messages.length - 1
                          ? {
                              borderLeft: "1px solid #DDD",
                              marginLeft: 5,
                              paddingLeft: 5,
                            }
                          : undefined
                      }
                    >
                      {i < props.messages.length - 1 && (
                        <Grid container direction="column">
                          <Typography
                            variant="body2"
                            fontWeight={600}
                          >
                            {t("ServiceBook.Offer.From")}
                            {c.SendFrom === props.currentuserId
                              ? t("Pages.PostBox.Me")
                              : c.SenderName}
                          </Typography>
                          <Typography variant="body2">
                            {t("Pages.PostBox.Sent")}
                            {format(
                              new Date(c.Created),
                              "eeee dd/MM.yyyy hh:mm",
                              {
                                locale: dalocale,
                              }
                            )}
                          </Typography>
                        </Grid>
                      )}
                      <Typography variant="body1">{c.Text}</Typography>
                      {React.cloneElement(p)}
                    </Grid>
                  ),
                  <></>
                )}
              </MessageBody>
              <Grid item>
                <Collapse in={state.openReply}>
                  <Grid item>
                    <Typography variant="body1">
                      {t("Pages.PostBox.Message")}
                    </Typography>
                    <TextField
                      variant="outlined"
                      multiline
                      minRows={5}
                      fullWidth
                      placeholder="..."
                      value={state.text}
                      onChange={changeValue}
                    />
                  </Grid>
                </Collapse>
              </Grid>
              <Grid item container direction="row-reverse">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={toggleOpen}
                >
                  {t("General.Buttons.Close")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={processReply}
                  sx={{margin: "0 10px"}}
                >
                  {state.openReply
                    ? t("General.Buttons.Send")
                    : t("Pages.PostBox.Answer")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={removeMessage}
                >
                  {t("General.Buttons.Delete")}
                </Button>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </ListItem>
      <Divider />
    </>
  );
};

export default PostBoxMessage;
