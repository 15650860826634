import { AppAction, AppActionThunk } from "../../../../definitions/Action";
import { AdminPricePredictionActionTypes } from "./admin.price-prediction.actiontypes";
import {
  PricePredictionModel,
  PredictionTestUnitModel,
  UpdatePreditcionContract,
} from "./defenitions";
import { PricePredictionsService } from "./admin.price-prediction.service";
import { operationFailedActionGeneral } from "../../../../actions";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(
    payload,
    AdminPricePredictionActionTypes.PRICE_PREDICTIONS_OPERATION_FAILED
  );
};

export const getPredictionModels =
  (): AppActionThunk<Promise<PricePredictionModel[]>> => async (dispatch) => {
    try {
      dispatch({
        type: AdminPricePredictionActionTypes.GET_PRICE_PREDICTIONS,
      });
      const result = await PricePredictionsService.getPredictionModels();
      dispatch({
        type: AdminPricePredictionActionTypes.GET_PRICE_PREDICTIONS_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const deletePredictionModel =
  (id: string): AppActionThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: AdminPricePredictionActionTypes.DELETE_PRICE_PREDICTION,
      });
      const result = await PricePredictionsService.deletePredictionModel(id);
      dispatch({
        type: AdminPricePredictionActionTypes.DELETE_PRICE_PREDICTION_SUCCEEDED,
        payload: id,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const activatePredictionModel =
  (id: string): AppActionThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: AdminPricePredictionActionTypes.SET_ACTIVE_PRICE_PREDICTIONS,
      });
      await PricePredictionsService.activatePredictionModel(id);
      dispatch({
        type: AdminPricePredictionActionTypes.SET_ACTIVE_PRICE_PREDICTIONS_SUCCEEDED,
        payload: id,
      });

      dispatch({
        type: AdminPricePredictionActionTypes.GET_PRICE_PREDICTIONS,
      });
      const result = await PricePredictionsService.getPredictionModels();
      dispatch({
        type: AdminPricePredictionActionTypes.GET_PRICE_PREDICTIONS_SUCCEEDED,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const editPredictionModel =
  (
    data: UpdatePreditcionContract
  ): AppActionThunk<Promise<PricePredictionModel>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: AdminPricePredictionActionTypes.EDIT_PRICE_PREDICTION,
      });
      const result = await PricePredictionsService.editPredictionModel(data);
      dispatch({
        type: AdminPricePredictionActionTypes.EDIT_PRICE_PREDICTION_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const reinitPredictions =
  (): AppActionThunk<Promise<boolean>> => async (dispatch) => {
    try {
      dispatch({
        type: AdminPricePredictionActionTypes.REINIT_PREDTICTIONS,
      });
      const result = await PricePredictionsService.reinitPredictions();
      dispatch({
        type: AdminPricePredictionActionTypes.REINIT_PREDTICTIONS_SUCCEEDEDS,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const renewOnePrediction =
  (id: string): AppActionThunk<Promise<PredictionTestUnitModel>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: AdminPricePredictionActionTypes.RENEW_ONE_PREDTICTION,
      });
      const result = await PricePredictionsService.renewOnePrediction(id);
      dispatch({
        type: AdminPricePredictionActionTypes.RENEW_ONE_PREDTICTION_SUCCEEDEDS,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const renewPredictions =
  (): AppActionThunk<Promise<PredictionTestUnitModel[]>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: AdminPricePredictionActionTypes.RENEW_PREDTICTIONS,
      });
      const result = await PricePredictionsService.renewPredictions();
      dispatch({
        type: AdminPricePredictionActionTypes.RENEW_PREDTICTIONS_SUCCEEDEDS,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const getPredictionTestUnits =
  (): AppActionThunk<Promise<PredictionTestUnitModel[]>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: AdminPricePredictionActionTypes.GET_TEST_UNITS,
      });
      const result = await PricePredictionsService.getPredictionTestUnits();
      dispatch({
        type: AdminPricePredictionActionTypes.GET_TEST_UNITS_SUCCEEDEDS,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const addUnitForPredictionTests =
  (unitId: string): AppActionThunk<Promise<PredictionTestUnitModel>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: AdminPricePredictionActionTypes.ADD_UNIT_FOR_PREDICTION,
      });
      const result = await PricePredictionsService.addUnitForPredictionTests(
        unitId
      );
      dispatch({
        type: AdminPricePredictionActionTypes.ADD_UNIT_FOR_PREDICTION_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const deleteUnitForPredictionTests =
  (testUnitId: string): AppActionThunk<Promise<string>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: AdminPricePredictionActionTypes.DELETE_UNIT_FOR_PREDICTION,
      });
      const result = await PricePredictionsService.deleteUnitForPredictionTests(
        testUnitId
      );
      dispatch({
        type: AdminPricePredictionActionTypes.DELETE_UNIT_FOR_PREDICTION_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };
