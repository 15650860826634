import React, { useState, useEffect } from "react";
import { ConnectedProps, connect } from "react-redux";
import { Grid, Container, Typography, Link, Button, Dialog, Slide, Box, useMediaQuery, useTheme } from "@mui/material";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

import Widget from "../NewsStream/components/Widget";
import { WidgetPosition, WidgetPage } from "../../constants/enums";
import { ApplicationState } from "../../reducers/store";
import { AppThunkDispatch } from "../../definitions/Action";
import { getWidgetsByPage } from "../../actions/landing.actions";

import wine from "../../assets/club/wine.jpg";
import danishClub from "../../assets/club/danishClub.png";
import myhouse from "../../assets/club/myhouse.png";
import { getProduct } from "../../actions/subscriptions.actions";
import ClubContainer from "./components/ClubContainer";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { LanguageType } from "../../definitions/Menu";
import i18n from "../../i18n";

const DialogContainer = styled(Grid)(({ theme }) => ({
  height: 480,
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    height: 400,
  },
}));

const LogosContainer = styled(Grid)(({ theme }) => ({
  padding: "25px 0",
  "& img:nth-child(1)": {
    width: 135,
    height: 39,
  },
  "& img:nth-child(2)": {
    width: 124,
    height: 30,
    margin: "0 50px",
    [theme.breakpoints.down("sm")]: {
      margin: "25px 0",
    },
  },
  "& img:nth-child(3)": {
    width: 112,
    height: 18,
  },
}));

const StyledButton = styled(Button)({
  root: {
    width: 175,
    height: 80,
    borderRadius: 9,
    backgroundColor: "#399536",
    color: "#ffffff",
    lineHeight: 1.29,
    margin: "20px 0",
  },
  label: {
    fontSize: 14,
  },
});

const ClubsSubscribeIntroPage = (props: ClubsSubscribeIntroPageConnectedProps) => {
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmDown = useMediaQuery(theme.breakpoints.down("md"));
  const isXsDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  useEffect(() => {
    props.getWidgetsByPage(WidgetPage.FordelsklubsSubscribe, 0, 10, i18n.language as LanguageType);
  }, [i18n.language]);

  useEffect(() => {
    if (!props.product) props.getProduct();
  }, [props.product]);

  const redirect = () => {
    navigate("/clubs-payment");
  };

  const monthPrice = props.product?.Prices.find((price) => price.RecurringInterval === "month");
  const yearPrice = props.product?.Prices.find((price) => price.RecurringInterval === "year");

  return (
    <ClubContainer>
      <Container maxWidth="md" sx={{ marginBottom: "20px" }}>
        <Grid container justifyContent="space-between">
          <Grid item sm={12} md={8}>
            <Grid item>
              <Typography variant="h3"> {t("Pages.Clubs.WelcomeToClub", { hostname: window.location.hostname })}</Typography>
              <Typography variant="subtitle1" paragraph>
                {t("Pages.Clubs.TextAboutMembershipInClub", { hostname: window.location.hostname })}
              </Typography>
              <Typography variant="body1" paragraph>
                {t("Pages.Clubs.TextAboutGoals")}
              </Typography>
              <Typography variant="body1" paragraph>
                {t("Pages.Clubs.TextAboutOfferMembers")}
              </Typography>
              <Typography variant="body1" paragraph>
                {t("Pages.Clubs.TextAboutPartners")}
              </Typography>
              <Typography variant="body1">{t("Pages.Clubs.TextAboutDenmark")}</Typography>
              <Typography variant="body1" paragraph>
                {t("Pages.Clubs.TextAboutOffersOrganicWines")}
              </Typography>
              <Typography variant="body1">{t("Pages.Clubs.AboutGoodOffers", { hostname: window.location.hostname })}</Typography>
            </Grid>

            <Grid item>
              {monthPrice && yearPrice && (
                <StyledButton variant="contained" onClick={redirect}>
                  {t("Pages.Clubs.BecomeMemberNow")} {yearPrice.Amount} {t("Pages.Clubs.KrPerYear")} {monthPrice.Amount}{" "}
                  {t("Pages.Clubs.KrPerMonth")}
                </StyledButton>
              )}
              <Typography variant="subtitle1">
                {t("Pages.Clubs.VideoAboutMyhouse", { hostname: window.location.hostname })}
                &nbsp;
                <Link onClick={() => setOpen(true)} underline="always">
                  {t("Pages.Clubs.Here")}
                </Link>
              </Typography>
              <Dialog fullWidth maxWidth="md" onClose={() => setOpen(false)} open={open}>
                <DialogContainer>
                  <ReactPlayer
                    url="https://www.youtube.com/embed/LMjc0N5WlaM"
                    controls={false}
                    width="100%"
                    height="100%"
                  />
                </DialogContainer>
              </Dialog>
            </Grid>

            <LogosContainer
              container
              alignItems={isXsDown ? "center" : "flex-end"}
              direction={isXsDown ? "column" : "row"}
            >
              <img src={wine} alt="wine logo" />
              <img src={danishClub} alt="danish club logo" />
              <img src={myhouse} alt="myhouse logo" />
            </LogosContainer>
          </Grid>
          <Grid container item sm={12} md={4} spacing={3}>
            {props.widgets &&
              props.widgets
                .filter((widget) => widget.Position === WidgetPosition.RightSide)
                .map((widget) => (
                  <Grid item key={widget.Id}>
                    <Slide key={widget.Id} direction="up" in={true} mountOnEnter unmountOnExit>
                      <Box minWidth={275} maxWidth={isSmDown ? "100%" : 300}>
                        <Widget model={widget}></Widget>
                      </Box>
                    </Slide>
                  </Grid>
                ))}
          </Grid>
        </Grid>
      </Container>
    </ClubContainer>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  widgets: state.landing.widgets,
  loading: state.club.loading,
  product: state.subscriptions.products.find((p) => {
    return p.Id.toLowerCase() === process.env.REACT_APP_BENEFIT_CLUB_PRODUCT_ID?.toLowerCase();
  }),
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getWidgetsByPage: (page: WidgetPage, skip: number, take: number, languageId?: LanguageType) =>
    dispatch(getWidgetsByPage(page, skip, take, "", null, undefined, languageId)),
  getProduct: () => dispatch(getProduct(process.env.REACT_APP_BENEFIT_CLUB_PRODUCT_ID || "")),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type ClubsSubscribeIntroPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(ClubsSubscribeIntroPage);
