import Unit, { UnitNeighbourHouse, ExtraPriceUserInput, OwnedUnitDto } from "../definitions/model/unit/Unit";
import ApiService from "./api.service";
import { TimelineModel } from "../definitions/Timeline";
import { HouseEconomyModel } from "../definitions/model/unit";
import { LoanOptimazedUrlParams as LoanOptimizationUrlParams } from "../pages/House/PropertyFacts/components/HouseLoans";

const UnitService = {
  getFullUnit: async (unitId: string): Promise<Unit> => {
    const url = `/api/units/${unitId}/full`;
    const result = await ApiService.get<any>(url);
    return result;
  },
  getUnit: async (unitId: string): Promise<Unit> => {
    const url = `/api/units/${unitId}`;
    const result = await ApiService.get<any>(url);
    return result;
  },
  getUnitPhotos: async (unit: Unit, size: string): Promise<string[]> => {
    const serviceUrl = process.env.REACT_APP_IMAGE_SERVICE_URL;
    const match = size.match(/(?<Width>\d+)x(?<Height>\d+)/i);
    if (match && match.groups) {
      const width = match.groups["Width"];
      const height = match.groups["Height"];
      const { HouseNo, Street, PostalCode } = unit.Address;

      const url = `${serviceUrl}/GetAllPhotosByAddress?houseNumber=${HouseNo}&street=${Street}&postCode=${PostalCode}&width=${width}&height=${height}`;

      const response = await ApiService.get<{
        East: string;
        West: string;
        North: string;
        South: string;
      }>(url);
      const photos = Object.entries(response).map(([k, v]) => "data:image/gif;base64," + v);
      return photos;
    }
    return [];
  },
  getUnitTimeline: async (unitId: string): Promise<TimelineModel> => {
    const url = `/api/units/${unitId}/timeline`;
    const result = await ApiService.get<any>(url);
    return result;
  },
  getAboutUnitData: async (unitId: string): Promise<any> => {
    const url = `/api/units/${unitId}/about`;
    const result = await ApiService.get<any>(url);
    return result;
  },
  updateExtraPriceUserInput: async (unitId: string, userInputPrice: ExtraPriceUserInput): Promise<any> => {
    const url = `/api/units/${unitId}/updateUserInputPrice`;
    const result = await ApiService.post<any>(url, userInputPrice);
    return result;
  },
  movedInProperty: async (): Promise<Unit | null> => {
    const url = `/api/users/movedinproperty`;
    const result = await ApiService.get<Unit>(url);
    if (result.Id) return result;
    //api returns an empty object {}
    return null;
  },
  getPendingMoveInProperty: async (): Promise<Unit | null> => {
    const url = `/api/users/pendingmoveinproperty`;
    const result = await ApiService.get<Unit>(url);
    if (result.Id) return result;
    //api returns an empty object {}
    return null;
  },
  getHouseMarketPrice: async (unitId: string): Promise<any[]> => {
    const url = `/api/units/${unitId}/getHouseMarketPrice`;
    const result = await ApiService.get<any>(url);
    return result;
  },
  getNearPriceComparedHouses: async (unitId: string, amount: number): Promise<UnitNeighbourHouse[]> => {
    const url = `/api/units/${unitId}/getNearPriceComparedHouses?amount=${amount}`;
    const result = await ApiService.get<UnitNeighbourHouse[]>(url);
    return result;
  },
  getHouseEconomy: async (unitId: string, shouldUpdate: boolean): Promise<HouseEconomyModel> => {
    const url = `/api/units/${unitId}/economy?shouldUpdate=${shouldUpdate}`;
    const result = await ApiService.get<HouseEconomyModel>(url);
    return result;
  },
  getLoanOptimizedCalculation: async (
    unitId: string,
    { rentePct, restgaeld, institut, optagelseDato, calctype, id }: LoanOptimizationUrlParams
  ): Promise<any> => {
    const url = `/api/units/${unitId}/loanOptimizedCalculation?RentePct=${rentePct}&Restgaeld=${restgaeld}&Institut=${institut}&OptagelseDato=${optagelseDato}&Calctype=${calctype}&id=${id}`;
    const result = await ApiService.get<any>(url);
    return result;
  },
  toggleShareModel: async (unitId: string): Promise<any> => {
    return await ApiService.post<any>("/api/units/sharescannedmodel/" + unitId, null);
  },
  updateInhabitantsCount: async (unitId: string, adults: number, children: number) => {
    const request = {
      UnitId: unitId,
      Adults: adults,
      Children: children,
    };
    return await ApiService.post<any>("/api/units/updateInhabitantsCount", request);
  },
  getOwnedHouses: async (signal?: AbortSignal): Promise<OwnedUnitDto[]> => {
    const url = "/api/units/owned";
    const result = await ApiService.get<OwnedUnitDto[]>(url, null, signal);
    return result;
  },
  getOwnedHousesCount: async (): Promise<number> => {
    const url = "/api/units/owned/count";
    const result = await ApiService.get<number>(url);
    return result;
  },
  getMovedInStatus: async (unitId: string): Promise<boolean> => {
    const url = `/api/units/${unitId}/isOwner`;
    const result = await ApiService.get<boolean>(url);
    return result;
  },
};

export default UnitService;
