import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";


import CompanyDataForm from "./CompanyDataForm";
import { CompanyGeneralInfo, CompanySustainabilityInfo } from "../../../../../definitions/Company";


type CompanyDialogProps = {
  open: boolean;
  handleClose: () => void;
  createCompany: (
    generalInfo: CompanyGeneralInfo,
    sustainanbilityInfo: CompanySustainabilityInfo
  ) => Promise<void>;
};

const CompanyCreationDialog = (props: CompanyDialogProps): JSX.Element => {
  const submit = (
    generalInfo: CompanyGeneralInfo,
    sustainabilityInfo: CompanySustainabilityInfo
  ) => {
    props
      .createCompany(generalInfo, sustainabilityInfo)
      .then(() => props.handleClose());
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create company</DialogTitle>
      <CompanyDataForm submit={submit} handleClose={props.handleClose} />
    </Dialog>
  );
};

export default CompanyCreationDialog;
