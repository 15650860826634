import React, { useContext, useEffect, useState } from "react";
import {
  MenuItem,
  Select,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CO2Context } from "../CO2ContextWrapper";
import { useTranslation } from "react-i18next";
import { CO2EmissionSource } from "../../CO2Plan";

type Source = {
  Id: string;
  Code: number;
  Name: string;
  IsSelected: boolean;
  Value?: number | null;
  CombinedValue?: number | null;
};

type CO2TargetOptionsSourceProps = {
  childSources: Source[];
  selectedSourceId: string;
  personId: string | null;
  disabled: boolean;
  order: number;
  sources: CO2EmissionSource[]
};

const StyledMenuItem = styled(MenuItem)(() => ({
  paddingLeft: 10,
  paddingRight: 10,
  textTransform: "lowercase",
}));

const StyledSelect = styled(Select)({
  textTransform: "lowercase",
  "& .MuiSelect-select.MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#000",
    borderBottom: "unset",
    paddingRight: 0,
  },
  "& .MuiSelect-icon.Mui-disabled": {
    opacity: 0,
  },
});

const CO2TargetHighLevelSourceComponent = (
  props: CO2TargetOptionsSourceProps
): JSX.Element => {
  const context = useContext(CO2Context);
  const [options, setOptions] = useState(props.childSources);
  const { t } = useTranslation("translation");

  useEffect(() => {
    setOptions(
      props.childSources.filter(
        (s) => s.Id == props.selectedSourceId || !s.IsSelected
      )
    );
  }, [props.childSources, props.selectedSourceId]);

  const handleSelectionChange = (sourceId: string) => {
    if (context.targetValue) {
      context.addValueSource(
        sourceId,
        props.personId,
        context.targetValue,
        null,
        true
      );
    } else {
      const selectedSource = props.sources.find((s) => s.Id === sourceId);

      //recalculation emission if CombinedValue of selected Source is null and isMultiSelect is false      
      if (
        !context.isMultiSelect &&
        selectedSource &&
        selectedSource?.CombinedValue === null &&
        selectedSource?.Value
      ) {
        context.addValueSource(
          sourceId,
          props.personId,
          selectedSource?.Value,
          null,
          true
        );
      }
    }
    context.selectHighLevelSource(
      sourceId,
      props.personId,
      props.selectedSourceId,
      props.order,
    );
  };

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledSelect
     sx={{pl: 0.5 }}
      value={props.selectedSourceId}
      disabled={props.disabled}
      fullWidth={isXs}
      onChange={(e) => handleSelectionChange(e.target.value as string)}
    >
      {options.map((source) => (
        <StyledMenuItem value={source.Id} key={source.Id}>
          {t(source.Name)}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
};

export default CO2TargetHighLevelSourceComponent;
