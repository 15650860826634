import React, { useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSystemMessage } from "../../../../actions/admin/admin.system-message.actions";
import {
  Grid,
  LinearProgress,
  Button,
  TextField,
  Box,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  SelectChangeEvent,
  Tab,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Link } from "react-router-dom";
import GrapesJsEditor from "../../../../components/Grapes/GrapesJsEditor";
import { ArrowBack } from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import { FormBuilderDTO, LandingType } from "../../../../definitions/Landing";
import { SystemMessageModel, SystemMessageType } from "../../../../definitions/model/SystemMessage";
import { useTranslation } from "react-i18next";
import { TextFieldConnector } from "../../../../components/Base/FormConnector";
import SystemMessageTestSender from "./SystemMessageTestSender";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { TransferList, useAlertContext } from "../../../../components/Base";
import { useAdmin } from "../../../../actions/admin/admin.actions";
import { SystemStringType } from "../../../../definitions/model/SystemString";

type SystemMessageEditorPageState = {
  loading: boolean;
  message: SystemMessageModel | undefined;
  editor: FormBuilderDTO | undefined;
  types: SystemMessageType[];
  template: string;
  tab: "edit" | "test";
  activeDomains: string[];
};

const initialState: SystemMessageEditorPageState = {
  loading: false,
  message: undefined,
  editor: undefined,
  types: [],
  template: "",
  tab: "edit",
  activeDomains: [],
};

const reducer = (state: SystemMessageEditorPageState, action: any) => {
  return { ...state, [action.type]: action.payload };
};

export type SystemMessageFormData = {
  Name: string;
  MessageSubject: string;
  Type: number | undefined;
  Domains: string[];
  ToUser: boolean;
};

const SystemMessageEditor = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { showAlert } = useAlertContext();
  const [, sysMessageActions] = useSystemMessage();
  const [, adminActions] = useAdmin();
  const methods = useForm<SystemMessageFormData>({
    defaultValues: {
      Name: "",
      MessageSubject: "",
      Type: undefined,
      Domains: [],
      ToUser: true,
    },
  });

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = methods;

  const handleTabChange = (event: React.SyntheticEvent, newValue: "edit" | "test") => {
    dispatch({ type: "tab", payload: newValue });
  };

  useEffect(() => {
    const fetch = async () => {
      dispatch({ type: "loading", payload: true });

      adminActions
        .getSystemStrings(SystemStringType.ActiveDomains)
        .then((x) => dispatch({ type: "activeDomains", payload: x.map((d) => d.Value) }));

      if (id && id !== "new") {
        const message = await sysMessageActions.getSystemMessage(id);
        dispatch({ type: "message", payload: message });

        const types = await sysMessageActions.getSystemMessageTypes();
        dispatch({ type: "types", payload: types });
        if (message) {
          const template = await sysMessageActions.getMessageTemplate(message.MessageTemplateId);
          dispatch({
            type: "template",
            payload: template?.GrapesData?.html ?? "",
          });
        }
      } else {
        const t = await sysMessageActions.getSystemMessageMissingTypes();
        dispatch({ type: "types", payload: t });
      }
      dispatch({ type: "loading", payload: false });
    };

    fetch().catch((e) => showAlert({ text: e.message, severity: "error" }));
  }, [id]);

  useEffect(() => {
    if (state.message) {
      setValue("Name", state.message.Name);
      setValue("MessageSubject", state.message.MessageSubject);
      setValue("Type", state.message.Type);
      setValue("Domains", state.message.Domains);
      setValue("ToUser", state.message.ToUser);
      dispatch({ type: "loading", payload: true });
      sysMessageActions
        .getMessageEditor(state.message.MessageTemplateId)
        .then((e) => dispatch({ type: "editor", payload: e }))
        .finally(() => dispatch({ type: "loading", payload: false }));
    }
  }, [state.message]);

  const handleTypeSelectChange = (event: SelectChangeEvent<number>) => {
    const selectedType = state.types.find((a) => a.Value === event.target.value);

    if (selectedType) setValue("Type", selectedType.Value);
  };

  const onSubmit = async (data: SystemMessageFormData) => {
    if (id === "new") {
      if (data.Type !== undefined) {
        dispatch({ type: "loading", payload: true });
        sysMessageActions
          .addSystemMessage(data.Name, data.MessageSubject, data.Type, data.Domains, data.ToUser)
          .then((m) => {
            if (m) navigate(`/admin/system-messages/${m.Id}`);
          })
          .finally(() => dispatch({ type: "loading", payload: false }));
      }
    } else if (id) {
      dispatch({ type: "loading", payload: true });
      await sysMessageActions.editSystemMessage(id, data.Name, data.MessageSubject, data.Domains, data.ToUser);
      dispatch({ type: "loading", payload: false });
    }
  };

  return (
    <Grid container>
      <Box py={2}>
        <Button component={Link} to={"/admin/system-messages"} type="submit" variant="contained" color="secondary">
          <ArrowBack fontSize="small" />
          {t("General.Buttons.Back")}
        </Button>
      </Box>
      <Grid container>
        <Grid item>
          <TabContext value={state.tab}>
            <TabList onChange={handleTabChange}>
              <Tab label="Edit" value="edit" />
              <Tab label="Test" value="test" disabled={!state.message} />
            </TabList>
            <TabPanel value="edit">
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%", marginBottom: 10 }}>
                <Grid container item justifyContent="flex-start" spacing={1} xs={12} md={6}>
                  <Grid item xs={12}>
                    <TransferList
                      options={state.activeDomains}
                      selected={state.message?.Domains ?? []}
                      fieldLabel="Domains"
                      placeholder="Domains"
                      onChanges={(selected: Array<string>) => setValue("Domains", selected)}
                      getOptionLabel={(o: string) => o}
                      getOptionSelected={(s: string, o: string) => s === o}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth margin="dense">
                      <Controller
                        render={({ field }) => (
                          <ToggleButtonGroup
                            {...field}
                            onChange={(e, value) => field.onChange(value)}
                            exclusive
                            color="secondary"
                          >
                            <ToggleButton value={true}>To User</ToggleButton>
                            <ToggleButton value={false}>From User</ToggleButton>
                          </ToggleButtonGroup>
                        )}
                        name="ToUser"
                        control={control}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldConnector register={register("Name", { required: true })}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Name"
                        error={!!errors.Name}
                        InputLabelProps={{ shrink: true }}
                      />
                    </TextFieldConnector>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldConnector register={register("MessageSubject", { required: true })}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Email Subject"
                        error={!!errors.MessageSubject}
                        InputLabelProps={{ shrink: true }}
                      />
                    </TextFieldConnector>
                  </Grid>
                  <Grid item xs={12}>
                    {state.message && (
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled
                        value={state.message.TypeText}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    {!state.message && (
                      <FormControl variant="outlined" fullWidth margin="dense">
                        <InputLabel shrink={true}>Target Type</InputLabel>
                        <Controller
                          name="Type"
                          render={() => (
                            <Select onChange={handleTypeSelectChange} error={!!errors.Type}>
                              {state.types.map((type) => (
                                <MenuItem key={type.Value} value={type.Value}>
                                  {type.Key}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                          control={control}
                        />
                      </FormControl>
                    )}
                    {state.loading && <LinearProgress color="secondary"></LinearProgress>}
                  </Grid>
                  <Grid item xs={12} justifyContent="flex-end">
                    <Button type="submit" variant="contained" color="secondary">
                      {t("General.Buttons.Save")}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </TabPanel>
            <TabPanel value="test">
              <SystemMessageTestSender
                MessageSubject={state.message?.MessageSubject ?? ""}
                MessageText={state.template}
                SendTestSystemMessage={sysMessageActions.sendTestSystemMessage}
                MessageType={{
                  Key: state.message?.TypeText ?? "",
                  Value: state.message?.Type ?? 0,
                }}
              />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {!state.loading && state.editor && (
          <GrapesJsEditor id="emailTemplateEditor" type={LandingType.Email} builder={state.editor} />
        )}
      </Grid>
    </Grid>
  );
};

export default SystemMessageEditor;
