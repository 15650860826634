import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Grid, Container } from "@mui/material";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";

import ClubContainer from "./components/ClubContainer";
// import PaymentForm from "./components/PaymentForm";
import { AppThunkDispatch } from "../../definitions/Action";
import {
  getProduct,
  processSuccededPayment,
} from "../../actions/subscriptions.actions";
import { PaymentChargeContract } from "../../definitions/model/Subscriptions";
import { ApplicationState } from "../../reducers/store";
import { replace } from "react-router-dom";

// const stripePromise = loadStripe(
//   process.env.REACT_APP_STRIPE_PUBLISH_KEY || ""
// );

const ClubsPaymentPage = (props: ClubsPaymentConnectedProps): JSX.Element => {

  useEffect(() => {
    if (props.alredySubscribed) {
      replace("/articles");
      return;
    }

    if (!props.product) props.getProduct();
  }, [props.product]);

  return (
    <ClubContainer>
      <Container maxWidth="md">
        <Grid container justifyContent="center">
          {/* <Elements stripe={stripePromise}>
            {props.product && (
              <PaymentForm
                processPayment={props.proceedPayment}
                product={props.product}
              />
            )}
          </Elements> */}
        </Grid>
      </Container>
    </ClubContainer>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  product: state.subscriptions.products.find(
    (p) =>
      p.Id.toLowerCase() ===
      process.env.REACT_APP_BENEFIT_CLUB_PRODUCT_ID?.toLowerCase()
  ),
  alredySubscribed: state.subscriptions.subscriptions?.some(
    (s) =>
      s.ProductId.toLowerCase() ===
        process.env.REACT_APP_BENEFIT_CLUB_PRODUCT_ID?.toLowerCase() &&
      s.IsActive
  ),
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  proceedPayment: (data: PaymentChargeContract) =>
    dispatch(processSuccededPayment(data)),
  getProduct: () =>
    dispatch(getProduct(process.env.REACT_APP_BENEFIT_CLUB_PRODUCT_ID || "")),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type ClubsPaymentConnectedProps = ConnectedProps<typeof connector>;

export default connector(ClubsPaymentPage);
