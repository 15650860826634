import { Dispatch } from "redux";

import { UnitConstants } from "../constants/unit.constants";
import { DocumentActions } from "../constants/document.actiontypes";
import { AppAction, AppActionThunk, AppThunkDispatch } from "../definitions/Action";
import Unit, { ExtraPriceUserInput, OwnedUnitDto, UnitNeighbourHouse } from "../definitions/model/unit/Unit";
import { LoanOptimazedUrlParams } from "../pages/House/PropertyFacts/components/HouseLoans";
import UnitService from "../services/unit.service";
import { operationFailedActionGeneral, useAppDispatch } from ".";
import { useSelector } from "react-redux";
import { ApplicationState } from "../reducers/store";
import { ChartData } from "../definitions/Chart";
import { DocumentSourceType } from "../definitions/Document";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, UnitConstants.UNIT_OPERATION_FAILED);
};

export const getUnit =
  (unitId: string, saveToStore = true): AppActionThunk<Promise<Unit>> =>
  async (dispatch) => {
    try {
      if (saveToStore) {
        dispatch({ type: UnitConstants.GET_UNIT });
      }

      const unit = await UnitService.getUnit(unitId);
      if (saveToStore) {
        dispatch({
          type: UnitConstants.GET_UNIT_SUCCEEDED,
          payload: unit,
        });

        if (unit && unit.DocumentFolders && unit.DocumentFolders.length) {
          dispatch({
            type: DocumentActions.GET_FOLDERS_SUCCEDED,
            payload: { result: unit.DocumentFolders, SourceType: DocumentSourceType.Unit },
          });
        }
      }
      return unit;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const getFullUnit =
  (unitId: string): AppActionThunk<Promise<Unit | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({ type: UnitConstants.GET_UNIT });

      const unit = await UnitService.getFullUnit(unitId);
      dispatch({
        type: UnitConstants.GET_UNIT_SUCCEEDED,
        payload: unit,
      });

      if (unit && unit.DocumentFolders && unit.DocumentFolders.length) {
        dispatch({
          type: DocumentActions.GET_FOLDERS_SUCCEDED,
          payload: { result: unit.DocumentFolders, SourceType: DocumentSourceType.Unit },
        });
      }

      return unit;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getAboutUnitData = (unitId: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: UnitConstants.GET_ABOUT_DATA });

    const result = await UnitService.getAboutUnitData(unitId);
    dispatch({
      type: UnitConstants.GET_ABOUT_DATA_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getMovedInProperty = (): AppActionThunk<Promise<Unit | null>> => async (dispatch) => {
  try {
    dispatch({ type: UnitConstants.GET_MOVED_IN_PROPERTY });
    const result = await UnitService.movedInProperty();

    dispatch({
      type: UnitConstants.GET_MOVED_IN_PROPERTY_SUCCEEDED,
      payload: result,
    });
    dispatch({
      type: DocumentActions.GET_FOLDERS_SUCCEDED,
      payload: { result: result?.DocumentFolders ?? [], SourceType: DocumentSourceType.Unit },
    });

    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    return null;
  }
};

export const getPendingMoveInProperty = (): AppActionThunk<Promise<Unit | null>> => async (dispatch) => {
  try {
    dispatch({ type: UnitConstants.GET_PENDING_PROPERTY });
    const result = await UnitService.getPendingMoveInProperty();
    dispatch({
      type: UnitConstants.GET_PENDING_PROPERTY_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    return null;
  }
};

export const getHouseMarketPrice =
  (unitId: string): AppActionThunk<Promise<any[] | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({ type: UnitConstants.GET_HOUSE_MARKET_PRICE });

      const brokers = await UnitService.getHouseMarketPrice(unitId);
      dispatch({
        type: UnitConstants.GET_HOUSE_MARKET_PRICE_SUCCEEDED,
        payload: {
          brokers: brokers.length ? brokers : [],
        },
      });

      return brokers;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getNeighbourHouses =
  (unitId: string, amount: number): AppActionThunk<Promise<UnitNeighbourHouse[] | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({ type: UnitConstants.GET_HOUSE_NEIGHBOUR_HOUSES });
      const neighbourHouses = await UnitService.getNearPriceComparedHouses(unitId, amount);
      dispatch({
        type: UnitConstants.GET_HOUSE_NEIGHBOUR_HOUSES_SUCCEEDED,
        payload: neighbourHouses,
      });

      return neighbourHouses;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getHouseEconomy = (unitId: string, shouldUpdate: boolean) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: UnitConstants.GET_HOUSE_ECONOMY });
    const economy = await UnitService.getHouseEconomy(unitId, shouldUpdate);
    dispatch({
      type: UnitConstants.GET_HOUSE_ECONOMY_SUCCEEDED,
      payload: { economy },
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const updateExtraPriceUserInput =
  (unitId: string, userInputPrice: ExtraPriceUserInput) => async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({ type: UnitConstants.UPDATE_PRICE_INPUT });
      const result = await UnitService.updateExtraPriceUserInput(unitId, userInputPrice);
      dispatch({
        type: UnitConstants.UPDATE_PRICE_INPUT_SUCCEEDED,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getLoanOptimizedCalculation =
  (unitId: string, loanParams: LoanOptimazedUrlParams) => async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({ type: UnitConstants.GET_LOAN_CALCULATION });
      const result = await UnitService.getLoanOptimizedCalculation(unitId, loanParams);
      dispatch({ type: UnitConstants.GET_LOAN_CALCULATION_SUCCEEDED });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const toggleShareModel =
  (unitId: string): AppActionThunk<Promise<any>> =>
  async (dispatch) => {
    dispatch({ type: UnitConstants.TOGGLE_SHARE_MODEL });
    await UnitService.toggleShareModel(unitId);
    dispatch({ type: UnitConstants.TOGGLE_SHARE_MODEL_SUCCEEDED });
  };

export const getOwnedHouses =
  (signal?: AbortSignal): AppActionThunk<Promise<OwnedUnitDto[]>> =>
  async (dispatch) => {
    try {
      dispatch({ type: UnitConstants.GET_OWNED_HOUSES });
      const result = await UnitService.getOwnedHouses(signal);
      dispatch({
        type: UnitConstants.GET_OWNED_HOUSES_SUCCEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const getOwnedHousesCount = (): AppActionThunk<Promise<number>> => async (dispatch) => {
  try {
    dispatch({ type: UnitConstants.GET_OWNED_HOUSES_COUNT });
    const result = await UnitService.getOwnedHousesCount();
    dispatch({
      type: UnitConstants.GET_OWNED_HOUSES_COUNT_SUCCEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

export const trackSearchAutocompleteFilledIn = (): AppActionThunk<void> => (dispatch) => {
  dispatch({
    type: UnitConstants.TRACK_SEARCH_AUTOCOMPLETE_FILLED_IN,
  });
};

export const setBankChartData = (payload: ChartData[]) => (dispatch: AppThunkDispatch) => {
  dispatch({
    type: UnitConstants.SET_BANK_CHART_DATA,
    payload,
  });
};

export const useUnitActions = () => {
  const dispatch = useAppDispatch();

  return {
    getUnit: (unitId: string, saveToStore = true) => dispatch(getUnit(unitId, saveToStore)),
    getFullUnit: (unitId: string) => dispatch(getFullUnit(unitId)),
    getUnitTimeline: (unitId: string) => UnitService.getUnitTimeline(unitId),
    getUnitPhotos: (unit: Unit, size: string) => UnitService.getUnitPhotos(unit, size),
    getMovedInProperty: () => dispatch(getMovedInProperty()),
    getPendingMoveInProperty: () => dispatch(getPendingMoveInProperty()),
    getHouseMarketPrice: (unitId: string) => dispatch(getHouseMarketPrice(unitId)),
    setBankChartData: (payload: ChartData[]) => dispatch(setBankChartData(payload)),
    getOwnedHouses: (signal?: AbortSignal) => dispatch(getOwnedHouses(signal)),
    getOwnedHousesCount: () => dispatch(getOwnedHousesCount()),
    getNeighbourHouses: (unitId: string, amount: number) => dispatch(getNeighbourHouses(unitId, amount)),
    
  };
};

export const useUnitState = () => useSelector((state: ApplicationState) => state.unit);

export const useUnit = (): [ReturnType<typeof useUnitState>, ReturnType<typeof useUnitActions>] => {
  const state = useUnitState();
  const actions = useUnitActions();

  return [state, actions];
};
