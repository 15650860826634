import React, { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import TopBar from "../../components/Layout/TopBar";
import Footer from "../../components/Layout/Footer";
import CookiesBlock from "../StartPage/Components/CookiesBlock";
import InstallPwaBlock from "../StartPage/Components/InstallPwaBlock";
import WelcomePart from "./Components/WelcomePart";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import { useUser } from "../../actions";
import { useNavigate } from "react-router-dom";

const MyHouseStartPage = (): JSX.Element => {
  const navigate = useNavigate();
  const [userState] = useUser();

  useEffect(() => {
    if (userState.user) {
      navigate("/overview");
    }
  }, []);

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        overflow: "hidden",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        style={{
          minHeight: "100vh",
        }}
      >
        <TopBar startPage hideAuth />
        <ErrorBoundary>
          <WelcomePart />
          <CookiesBlock />
        </ErrorBoundary>
        <Footer transparent />
      </Grid>
      <InstallPwaBlock />
    </Container>
  );
};

export default MyHouseStartPage;
