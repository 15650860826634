import React, { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { ErrorBoundary } from "../../../components/ErrorBoundary";
import CookiesBlock from "../../StartPage/Components/CookiesBlock";
import InstallPwaBlock from "../../StartPage/Components/InstallPwaBlock";
import CommercialWelcomePart from "./Components/CommercialWelcomePart";
import TopBar from "../../../components/Layout/TopBar";
import Footer from "../../../components/Layout/Footer";
import { useUser } from "../../../actions";
import { useNavigate } from "react-router-dom";

const FooterWrapper = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 32px)",
    marginRight: 16,
    marginLeft: 16,
  },
}));

const ClimatePlanCommercialStartPage = (): JSX.Element => {
  const theme = useTheme();
  const [{ user }] = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/co2calculator");
    }
  }, []);
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        overflow: "hidden",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        style={{
          minHeight: "100vh",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <TopBar hideAuth />
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          sx={{
            minHeight: "100%",
            flex: 1,
          }}
        >
          <ErrorBoundary>
            <CommercialWelcomePart />
            <CookiesBlock />
          </ErrorBoundary>
        </Grid>
        <InstallPwaBlock />
        <FooterWrapper container item>
          <Footer />
        </FooterWrapper>
      </Grid>
    </Container>
  );
};

export default ClimatePlanCommercialStartPage;
