import React, { useEffect, ChangeEvent, useReducer, useMemo } from "react";
import { Grid, Tooltip, Button, Rating } from "@mui/material";
import { Add } from "@mui/icons-material";
import MTextField from "../../../../../components/Base/MyhouseTextField";
import { useAlertContext } from "../../../../../components/Base";
import { AdminTable, Column, Icon } from "../../../components/AdminTable/AdminTable";
import { CompanyTestimonialModel } from "../../../../../definitions/Company";
import { useCompanies } from "../../../../../actions/admin/admin.company.actions";
import { useCompanyTestimonials } from "../../../../../actions/admin/admin.company-testimonial.actions";
import CompanyTestimonialCreationDialog from "./CompanyTestimonialCreationDialog";
import CompanyTestimonialEditDialog from "./CompanyTestimonialEditDialog";
import { SystemStringType } from "../../../../../definitions/model/SystemString";
import { useAdmin } from "../../../../../actions/admin/admin.actions";

type CompanyTestimonialsState = {
  searchString: string;
  domains: string[];
  addCompanyTestimonialModalShown: boolean;
  testimonialToEdit: CompanyTestimonialModel | null;
};

const initialState: CompanyTestimonialsState = {
  searchString: "",
  domains: [],
  addCompanyTestimonialModalShown: false,
  testimonialToEdit: null,
};

const reducer = (state: CompanyTestimonialsState, action: { type: string; payload: unknown }) => {
  return { ...state, [action.type]: action.payload };
};

const CompanyTestimonials = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { testimonials, actions } = useCompanyTestimonials();
  const [{ companies }, companyActions] = useCompanies();
  const [{ error, loading }, adminActions] = useAdmin();

  const columns: Column<CompanyTestimonialModel>[] = useMemo(
    () => [
      {
        name: "Company",
        accessor: "CompanyName",
      },
      {
        name: "Title",
        accessor: "Title",
        format: (text) => (text.length < 50 ? text : text.slice(0, 50) + "..."),
      },
      {
        name: "Rating",
        format: (data) => <Rating value={data.Rating} size="small" color="secondary" precision={0.5} readOnly />,
      },
      {
        name: "Edit",
        icon: Icon.Edit,
        callback: (data) => dispatch({ type: "testimonialToEdit", payload: data }),
      },

      {
        name: "Delete",
        icon: Icon.Delete,
        callback: (data) => handleDeleteClick(data.CompanyId, data.Id),
      },
    ],
    []
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: "searchString", payload: event.currentTarget.value });

  const { showAlert } = useAlertContext();

  useEffect(() => {
    actions.getCompanyTestimonials([], 0, 50);
    if (companies.length === 0) {
      const take = 1000000; // use a big number to get all companies
      companyActions.getCompanies(0, take);
    }

    adminActions
      .getSystemStrings(SystemStringType.ActiveDomains)
      .then((x) => dispatch({ type: "domains", payload: x.map((d) => d.Value) }));
  }, []);

  useEffect(() => {
    if (error) {
      showAlert({
        severity: "error",
        text: error,
      });
    }
  }, [error]);

  const showAddCompanyTestimonialModal = () => {
    dispatch({ type: "addCompanyTestimonialModalShown", payload: true });
  };

  const handleModalClose = () => {
    dispatch({ type: "addCompanyTestimonialModalShown", payload: false });
  };

  const handleDeleteClick = (companyId: string, testimonialId: string) => {
    actions.deleteCompanyTestimonial(companyId, testimonialId);
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid container spacing={3} item sm={10} xs={12}>
          <Grid item container>
            <MTextField fullWidth placeholder="Search by text or company" onChange={handleChange} />
          </Grid>

          <Grid item container>
            <AdminTable
              data={testimonials.filter(
                (ad) =>
                  ad.Text.toLocaleLowerCase().includes(state.searchString.toLowerCase()) ||
                  ad.CompanyName.toLocaleLowerCase().includes(state.searchString.toLowerCase())
              )}
              columns={columns}
              loading={loading}
              pagination={"client"}
              headerButton={
                <Grid container item justifyContent="flex-start">
                  <Tooltip title="New testimonial">
                    <Button color="primary" variant="contained" onClick={showAddCompanyTestimonialModal}>
                      <Add />
                    </Button>
                  </Tooltip>
                </Grid>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <CompanyTestimonialCreationDialog
        open={state.addCompanyTestimonialModalShown}
        createTestimonial={actions.addCompanyTestimonial}
        companies={companies.filter((c) => c.IsActiveClient)}
        domains={state.domains}
        handleClose={handleModalClose}
      />
      <CompanyTestimonialEditDialog
        testimonial={state.testimonialToEdit}
        open={!!state.testimonialToEdit}
        loading={loading}
        companies={companies.filter((c) => c.IsActiveClient)}
        domains={state.domains}
        handleClose={() => dispatch({ type: "testimonialToEdit", payload: null })}
        editTestimonial={actions.editCompanyTestimonial}
      />
    </>
  );
};

export default CompanyTestimonials;
