import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ProgressBarColors } from "../../CO2Plan";
import { convertToCurency } from "../../../../../services/converter.service";
import CO2BorderLinearProgress from "../CO2BorderLinearProgress";

type CO2TargetEmissionSourcesProgressProps = {
  CO2EmissionSaved: number;
  CO2Emission: number;
  averageCO2Emission: number;
  goalCO2Emission: number;
};

const CO2TargetEmissionSourcesProgress = (
  props: CO2TargetEmissionSourcesProgressProps
): JSX.Element => {
  const getSavingPercentage = () => {
    return Math.round(
      (100 * props.CO2EmissionSaved) /
        (props.CO2Emission + props.CO2EmissionSaved)
    );
  };

  const [comarisonToAveragePercentage, setComparisonToAverage] = useState(0);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [progressBarColor, setProgressBarColor] = useState<ProgressBarColors>(
    ProgressBarColors.Bad
  );

  const { t } = useTranslation("translation");

  useEffect(() => {
    setComparisonToAverage(
      Math.round(
        (100 * (props.CO2Emission - props.averageCO2Emission)) /
          props.averageCO2Emission
      )
    );
  }, [props.CO2Emission, props.averageCO2Emission]);

  // level after which user's emission is considered significantly bigger than average
  const thresholdComparisonToAverage = 30;

  useEffect(() => {
    // calculate a value that fits following conditions:
    // min - 10
    // defaultEmission - 26
    // governmentGoalEmission - 75
    // max - 100

    // the bigger the number the better

    // assume progress bar value is a lineral function: value = a * emission + b
    const a = (75 - 26) / (props.goalCO2Emission - props.averageCO2Emission);
    const b = 75 - a * props.goalCO2Emission;
    let result = a * props.CO2Emission + b;
    if (props.CO2Emission === 0) {
      // special case. 0 emissions should lead to maximum value
      result = 100;
    }
    result = Math.max(10, result);
    result = Math.min(100, result);
    setProgressBarValue(result);
  }, [props.CO2Emission, props.averageCO2Emission, props.goalCO2Emission]);

  useEffect(() => {
    if (progressBarValue >= 75) {
      setProgressBarColor(ProgressBarColors.VeryGood);
    } else if (progressBarValue >= 50) {
      setProgressBarColor(ProgressBarColors.Good);
    } else if (progressBarValue >= 25) {
      setProgressBarColor(ProgressBarColors.Bad);
    } else {
      setProgressBarColor(ProgressBarColors.VeryBad);
    }
  }, [progressBarValue]);

  return (
    <Grid item  container justifyContent="center" alignItems="center">
      <Grid
        item
        xs={12}
        container
        direction="column"
      >
        <CO2BorderLinearProgress
          value={progressBarValue}
          title={convertToCurency(props.CO2Emission, ".") + " kg CO2"}
          barColor={progressBarColor}
        />
      </Grid>
    </Grid>
  );
};

export default CO2TargetEmissionSourcesProgress;
