import React, { useCallback, useEffect } from "react";
import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton, styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ProductModel,
  SubscriptionModel,
} from "../../../../../definitions/model/Subscriptions";
import {
  CellLabel,
  LoadWrapper,
  TablePaginationActions,
  useAlertContext,
} from "../../../../../components/Base";
import ConfirmDialog from "../../../../../components/Dialogs/ConfirmDialog";
import { Add, Check, Delete, Clear } from "@mui/icons-material";
import { useDefaultReducer, useToggler } from "../../../../../helpers/hooks";
import format from "date-fns/format";
import { SearchResult } from "../../../../../definitions/model/SearchResult";
import CreateSubscriptionDialog from "./CreateSubscriptionDialog";

const SpinnerContainer = styled(Grid)<{component: React.ReactNode}>(({theme})=>({
  position: "absolute",
  background: "rgba(0,0,0,0.3)",
  height: "calc(100% - 53px)",
  top: 0,
}))

const Spinner = styled(CircularProgress)(({theme})=>({
  position: "absolute",
  top: "50%",
  left: "calc(50% - 20px)",
}))

const StyledTableRow = styled(TableRow)(({theme})=>({
  height: 59,

  "&:hover": {
    background: theme.palette.grey["200"],
  },

  "& td": {
    padding: "0 16px",
  },
}))


const SubscriptionTableLables = [
  { key: "CustomerEmail", value: "Email" },
  { key: "CurrentPeriodStart", value: "Last payment" },
  { key: "CurrentPeriodEnd", value: "Subscription end" },
  { key: "IsActive", value: "Is active" },
];

type SubscriptionsTableState = {
  page: number;
  subscriptionsPerPage: number;
  subscriptionsFilter: string;
  orderDirection: "asc" | "desc";
  searchTerm: string;
};

const initialState: SubscriptionsTableState = {
  page: 0,
  subscriptionsPerPage: 10,
  subscriptionsFilter: "Created",
  orderDirection: "asc",
  searchTerm: "",
};

type ProductSubscriptionsProps = {
  count: number;
  subscriptions: SubscriptionModel[];
  getProductSubscriptions: (
    skip: number,
    take: number,
    isDescending: boolean,
    sortingColumn: string,
    searchTerm: string
  ) => Promise<SearchResult<SubscriptionModel>>;
  clearSubscriptions: () => void;
  cancelSubscription: (subscriptionId: string) => Promise<SubscriptionModel>;
  createTestSubscription: (
    email: string,
    productId: string,
    priceId: string
  ) => Promise<SubscriptionModel>;
  product: ProductModel | null;
  loading: boolean;
};

const ProductSubscriptions = ({
  count,
  loading,
  product,
  subscriptions,
  cancelSubscription,
  clearSubscriptions,
  getProductSubscriptions,
  createTestSubscription,
}: ProductSubscriptionsProps): JSX.Element => {
  const [state, dispatch] = useDefaultReducer(initialState);
  const [open, toggle] = useToggler(false);
  const { showAlert } = useAlertContext();
  const fetchSubscriptions = useCallback(() => {
    getProductSubscriptions(
      state.page * state.subscriptionsPerPage,
      state.subscriptionsPerPage,
      state.orderDirection === "desc",
      state.subscriptionsFilter,
      state.searchTerm
    );
  }, [state]);

  const handleDelete = (subscriptionId: string) => {
    cancelSubscription(subscriptionId).catch((e) =>
      showAlert({ severity: "error", text: e.statusText })
    );
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch({ type: "page", payload: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch({
      type: "subscriptionsPerPage",
      payload: parseInt(event.target.value, 10),
    });
    dispatch({ type: "page", payload: 0 });
  };

  const handleChangeOrder = (name: string) => () => {
    if (name !== state.subscriptionsFilter) {
      dispatch({ type: "orderDirection", payload: "asc" });
      dispatch({ type: "subscriptionsFilter", payload: name });
    } else {
      dispatch({
        type: "orderDirection",
        payload: state.orderDirection === "asc" ? "desc" : "asc",
      });
    }
  };

  useEffect(() => {
    fetchSubscriptions();

    return () => {
      clearSubscriptions();
    };
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4">Product subscriptions</Typography>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <LoadWrapper result={subscriptions} isLoading={loading}>
          <TableContainer sx={{width: "100%", padding: 0}}>
            <Table stickyHeader style={{ position: "relative" }}>
              <TableHead>
                <TableRow>
                  {SubscriptionTableLables.map((label) => (
                    <CellLabel
                      key={label.key}
                      cellName={label.value}
                      cellKey={label.key}
                      currentCellName={state.subscriptionsFilter}
                      direction={state.orderDirection}
                      onClick={handleChangeOrder}
                    />
                  ))}
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={toggle}
                      startIcon={<Add />}
                    >
                      Create subscription
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{width: "100%", padding: 0}}>
                {subscriptions.map((subscription) => (
                  <StyledTableRow key={subscription.Id}>
                    <TableCell>{subscription.CustomerEmail}</TableCell>
                    <TableCell>
                      {format(
                        new Date(subscription.CurrentPeriodStart),
                        "dd/MM/yyyy"
                      )}
                    </TableCell>
                    <TableCell>
                      {format(
                        new Date(subscription.CurrentPeriodEnd),
                        "dd/MM/yyyy"
                      )}
                    </TableCell>
                    <TableCell>
                      {subscription.IsActive ? <Check /> : <Clear />}
                    </TableCell>
                    <TableCell align="right">
                      {subscription.IsTestSubscription && (
                        <Chip color="primary" size="small" label="Test" />
                      )}
                      {subscription.IsActive && (
                        <ConfirmDialog
                          text="Are you sure want to cancel this subscriptions?"
                          OkClickHandler={() => {
                            handleDelete(subscription.Id);
                          }}
                        >
                          <Tooltip title="Cancle subscription">
                            <IconButton size="large">
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </ConfirmDialog>
                      )}
                    </TableCell>
                  </StyledTableRow>
                ))}
                {loading && (
                  <SpinnerContainer
                    component={<TableRow/>}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <TableCell>
                      <Spinner/>
                    </TableCell>
                  </SpinnerContainer>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      15,
                      20,
                      25,
                      { label: "All", value: count },
                    ]}
                    count={count}
                    rowsPerPage={state.subscriptionsPerPage}
                    page={state.page}
                    SelectProps={{
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </LoadWrapper>
      </Grid>
      {product && (
        <CreateSubscriptionDialog
          open={open}
          closeDialog={toggle}
          product={product}
          createTestSubscription={createTestSubscription}
        />
      )}
    </Grid>
  );
};
export default React.memo(ProductSubscriptions);
