export const getFolderType = (fileType: string): string => {
    switch (fileType) {
      case "image/png":
      case "image/jpg":
      case "image/jpeg":
      case "image/gif":
      case "image/tiff":
      case "image/bmp":
        return "Documents.Pictures";
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "Documents.Excel";
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/msword":
      case "application/vnd.oasis.opendocument.text":
        return "Documents.Word";
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      case "application/vnd.ms-powerpoint":
        return "Documents.PowerPoint";
      case "application/pdf":
        return "Documents.Pdf";
      case "text/htm":
      case "text/html":
      case "text/plain":
        return "Documents.Text";
      case "message/rfc822":
        return "Documents.Email";
      default:
        return "Documents.Other";
    }
  };