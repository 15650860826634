import React from "react";
import { Grid, LinearProgress, styled, Typography } from "@mui/material";
import { ProgressBarColors } from "../CO2Plan";
import { useTranslation } from "react-i18next";

type BorderLinearProgressProps = {
  barColor: ProgressBarColors;
  value: number;
  title?: string;
};

const StyledLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "barColor",
})<{ barColor: ProgressBarColors }>(({ theme, barColor }) => ({
  width: "100%",
  height: 20,
  borderRadius: 10,
  margin: "10px 0 0",
  transform: "translateZ(0)",
  isolation: "isolate",
  position: "relative",
  zIndex: 0,
  "&.MuiLinearProgress-colorPrimary": {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
  },
  "& .MuiLinearProgress-bar": {
    borderRadius: 0,
    backgroundColor: barColor.toString(),
  },
}));

const CO2BorderLinearProgress = ({
  value,
  barColor,
  title,
}: BorderLinearProgressProps): JSX.Element => {
  const { t } = useTranslation("translation");
  return (
    <>
      <StyledLinearProgress
        barColor={barColor}
        variant="determinate"
        value={value}
        title={title}
      />
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="body2">
            {" "}
            {t("ClimatePlan.General.Min")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            {t("ClimatePlan.General.Max")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default CO2BorderLinearProgress;
