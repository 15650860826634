import React, { useState } from "react";
import { MCard, useAlertContext } from "../../../../components/Base";
import { CardContent, Grid, TextField, styled } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useBuilding } from "../../../../actions/building.actions";
import { useUnit } from "../../../../actions";
import { LoadingButton } from "@mui/lab";

const StyledForm = styled("form")(() => ({
  width: "100%",
}));

const SendSuggestionForm = () => {
  const { t } = useTranslation();
  const { handleSubmit, control, reset, watch } = useForm<{ suggestion: string }>({
    defaultValues: { suggestion: "" },
  });
  const [, buildingActions] = useBuilding();
  const [{ unit }] = useUnit();
  const [loading, setLoading] = useState(false);
  const alertContext = useAlertContext();

  const send = async (data: { suggestion: string }) => {
    try {
      if (unit) {
        setLoading(true);
        await buildingActions.sendSuggestion(unit.Id, data.suggestion);
        reset({ suggestion: "" });
      }
    } catch (error) {
      alertContext.showAlert({ text: t("General.Errors.ErrorOccurred"), severity: "error" });
    } finally {
      setLoading(false);
    }
  };

  const suggestionWatch = watch("suggestion");

  return (
    <MCard type="main" title={t("Building.SendSuggestion")}>
      <CardContent>
        <StyledForm onSubmit={handleSubmit(send)}>
          <Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={12}>
              <Controller
                name="suggestion"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={4}
                    placeholder={t("Building.SuggestionText")}
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item container sx={{ justifyContent: "flex-end", alignItems: "center" }}>
              <Grid item>
                <LoadingButton
                  loading={loading}
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={!suggestionWatch.length}
                >
                  {t("General.Buttons.Send")}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </StyledForm>
      </CardContent>
    </MCard>
  );
};

export default SendSuggestionForm;
