import {
  UserAcceptedTerms2018Model,
  UserRegisteredHistoryModel,
} from "./UserSettings";
import { UserType, UserInterest } from "../../constants/enums";
import { LanguageType } from "../Menu";

export enum UserState {
  Active = 0,
  Deactivated = 1,
  Removed = 2,
  All = 3,
}

export type UserRating = 0 | 1 | 2 | 3 | 4 | 5;

export default interface User {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
  Id: string;
  Name: string;
  Email: string;
  Token: string;
  Role: UserType;
  CommuneNumber: number;
  AvatarUrl: string;
  Address: string;
  Phone: string;
  UserInterest: UserInterestModel;
  FaceBookId: string;
  UserState: UserState;
  NewUser: boolean;
  AlarmTarget: string;
  Rating: UserRating;
  LastLogin: Date;
  Language: LanguageType;
  IsCompanyUser: boolean;
  IsCommuneUser: boolean;
  TermsVersion: string;
  Companies: string[];
  Created: string;
  MovedInUnits: string[];
  MovedInBuildings: string[];
}

export interface UserInterestModel {
  LastUpdateTime: Date | string;
  Interests: UserInterest[];
}

export interface UserRegistrationDTO {
  Name: string;
  Email: string;
  FKey: string;
  AvatarUrl: string;
  Password: string;
  Phone: string | undefined;
  SendNotifications: boolean;
  ReceiveCalls: boolean;
  SendFacebookNotifications: boolean;
  Rating: UserRating;
  IgnoreNotifications: boolean;
  EmployerId?: string;
  CommuneNumber: number | null;
  Language: string;  
}

export interface UserProfileDTO {
  Name: string;
  Email: string;
  Phone: string;
  EmployerId?: string;
  CommuneNumber?: number;
  Language: LanguageType;
}

export interface UserSettingsDTO {
  TermsAcceptedModel: UserAcceptedTerms2018Model;
  RegisterHistoryModel: UserRegisteredHistoryModel;
  IsTermsAccepted: boolean;
  ReceiveNotifications: boolean;
  ReceiveCalls: boolean;
  ReceiveFacebookNotifications: boolean;
  IsBidOnHouseAllowed?: boolean;
}

export type CompleteRegistrationData = {
  Name: string;
  Email: string;
  Password: string;
  UnitId: string | null;
  Interests: Array<UserInterest>;
  IgnoreNotifications: boolean;
};

export type UserInterestsFormData = {
  Accommodation: boolean;
  DIY: boolean;
  Culture: boolean;
  Garden: boolean;
  Sport: boolean;
  HouseDigitalization: boolean;
};

export enum UserSubscriptions {
  Clubs,
}

export type RemovedUserLogItemModel = {
  Id: string;
  UserId: string;
  Email: string;
  FacebookId: string;
  DeletedDate: Date;
  Reason: UserRemovalReason;
};

export enum UserRemovalReason {
  Undefined = 0,
  SelfRemoval = 1,
  OldUserJobRemoval = 2,
  AdminRemoval = 3,
}