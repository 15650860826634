import _ from "lodash";
import React, { useContext } from "react";
import { Dispatch, AnyAction } from "redux";

import { CommentModel } from "../../definitions/NewsStream";

type NewsCommentsContextType = {
  comments: _.Dictionary<CommentModel[]>;
  loading: boolean;
  getComments: (
    parentId: string,
    skip: number,
    take: number
  ) => (dispatch: Dispatch<AnyAction>) => Promise<void>;
  postComment: (
    comment: Partial<CommentModel>
  ) => (dispatch: Dispatch<AnyAction>) => Promise<void>;
  editComment: (
    comment: CommentModel
  ) => (dispatch: Dispatch<AnyAction>) => Promise<void>;
  deleteComment: (
    comment: CommentModel
  ) => (dispatch: Dispatch<AnyAction>) => Promise<void>;
};

export const CommentsContext = React.createContext<NewsCommentsContextType>({
  comments: _.groupBy([] as CommentModel[], "parentId") as _.Dictionary<CommentModel[]>,
  getComments: () => async () => {},
  postComment: () => async () => {},
  editComment: () => async () => {},
  deleteComment: () => async () => {},
  loading: false,
});

export const useCommentsContext = () => useContext(CommentsContext);
