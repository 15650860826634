import React, { useEffect, useReducer } from "react";
import {
  Grid,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  Tooltip,
  Fab,
  IconButton,
  styled,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { Add, CheckCircle } from "@mui/icons-material";
import ArticleCreationDialog from "./components/ArticleCreationDialog";
import { useNavigate, useParams } from "react-router-dom";
import ClubEditForm from "./components/ClubEditForm";
import ConfirmDialog from "../../../../components/Dialogs/ConfirmDialog";
import { useAlertContext } from "../../../../components/Base";
import { ArticleCategory } from "../../../../constants/enums";
import { useClubs } from "../../../../actions";

const StyledList = styled(List)(() => ({
  overflowY: "auto",
  marginTop: 10,
  width: "100%",
  height: "70vh",
  padding: 0,
}));

const StyledListItem = styled(ListItem)(() => ({
  borderBottom: "1px rgba(0, 0, 0, 0.12) solid",
  "& button, & a": {
    display: "none",
  },
  "&:hover": {
    backgroundColor: "rgba(0,0,0,0.12)",
    "& button, & a": {
      display: "inline-flex",
    },
  },
}));

type TabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;
  return <div>{value === index && children}</div>;
};

type ClubPageState = {
  currentTab: number;
  openCreationArticle: boolean;
};

const initialState: ClubPageState = {
  currentTab: 0,
  openCreationArticle: false,
};

const reducer = (state: ClubPageState, action: { type: string; payload: unknown }) => {
  return { ...state, [action.type]: action.payload };
};

const ClubEditPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { showAlert } = useAlertContext();
  const navigate = useNavigate();
  const [club, actions] = useClubs();

  const handleChange = (event: React.ChangeEvent<unknown>, newTab: number) => {
    dispatch({ type: "currentTab", payload: newTab });
  };

  const { id } = useParams<{ id: string }>();

  const createArticle = async (
    title: string,
    uniqueName: string,
    templateId: string,
    categories: ArticleCategory[],
    tag: string
  ) => {
    if (club.club) {
      return await actions.createArticle(club.club.Id, title, uniqueName, templateId, categories, tag);
    }
  };

  const handleDelete = (articleId: string) => () => {
    actions
      .deleteArticle(articleId)
      .then((x) => {
        if (x)
          showAlert({
            severity: "success",
            text: "Article deleted",
          });
      })
      .catch(() => {
        showAlert({
          severity: "error",
          text: "Article was not deleted",
        });
      });
  };

  const setOpenDialog = (value: boolean) => dispatch({ type: "openCreationArticle", payload: value });

  useEffect(() => {
    const getAsync = async () => {
      if(id) actions.getClub(id);
    };
    getAsync();
    return () => {};
  }, []);

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={10} container justifyContent="center" spacing={2}>
          <Grid item xs={8}>
            <Tabs
              value={state.currentTab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="Group" />
              <Tab label="Group articles" />
            </Tabs>
          </Grid>
          <Grid item xs={8} role="tabpanel">
            <TabPanel value={state.currentTab} index={0}>
              {club.club && <ClubEditForm club={club.club} editClub={actions.editClub} loading={club.loading} />}
            </TabPanel>
            <TabPanel value={state.currentTab} index={1}>
              <Grid container>
                <Grid item xs={12}>
                  <StyledList>
                    {club.club &&
                      club.club.Articles.map((article) => (
                        <StyledListItem key={article.Id}>
                          <ListItemIcon>{article.IsPublished && <CheckCircle color="secondary" />}</ListItemIcon>
                          <ListItemText primary={article.Title} />
                          <ListItemSecondaryAction>
                            <Tooltip title="Edit article">
                              <IconButton
                                onClick={() => navigate(`/admin/clubs/${article.ClubId}/article/${article.Id}`)}
                                size="large"
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                            <ConfirmDialog
                              text="Are you shure you want to delete this club?"
                              OkClickHandler={handleDelete(article.Id)}
                            >
                              <Tooltip title="Delete article">
                                <IconButton size="large">
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            </ConfirmDialog>
                          </ListItemSecondaryAction>
                        </StyledListItem>
                      ))}
                  </StyledList>
                </Grid>
                <Grid container item justifyContent="flex-end">
                  <Tooltip title="Add article">
                    <Fab color="primary" onClick={() => setOpenDialog(true)}>
                      <Add />
                    </Fab>
                  </Tooltip>
                </Grid>
              </Grid>
            </TabPanel>
          </Grid>
        </Grid>
      </Grid>
      <ArticleCreationDialog
        open={state.openCreationArticle}
        createArticle={createArticle}
        handleClose={() => setOpenDialog(false)}
      />
    </>
  );
};

export default ClubEditPage;
