import ApiService from "./api.service";
import { UserMailModel, UserMailContract, RecipientModel } from "../definitions/model/UserMail";

const PostBoxService = {
  getMessages: async (
    skip?: number,
    take?: number
  ): Promise<{ Count: number; Messages: UserMailModel[] }> => {
    const url = `/api/message/mail/${skip}/${take}`;
    const result = await ApiService.get<{
      Count: number;
      Messages: UserMailModel[];
    }>(url);
    return result;
  },

  getThread: async (threadId: string): Promise<UserMailModel[]> => {
    const url = `/api/message/mail/thread/${threadId}`;
    const result = await ApiService.get<UserMailModel[]>(url);
    return result;
  },

  getMessage: async (messageId: string): Promise<UserMailModel> => {
    const url = `/api/message/mail/${messageId}`;
    const result = await ApiService.get<UserMailModel>(url);
    return result;
  },

  createMessage: async (message: UserMailContract): Promise<UserMailModel> => {
    const url = `/api/message/mail`;
    return await ApiService.post<UserMailModel, UserMailContract>(url, message);
  },

  replyToMessage: async (message: UserMailContract): Promise<UserMailModel> => {
    const url = `/api/message/mail/reply`;
    return await ApiService.post<UserMailModel, UserMailContract>(url, message);
  },

  moveMessageToTrash: async (
    threadId: string,
    messageId: string
  ): Promise<void> => {
    const url = `/api/message/mail/trash/${threadId}/${messageId}`;
    await ApiService.put(url, {});
  },

  removeMessage: async (messageId: string): Promise<string> => {
    const url = `/api/message/mail/${messageId}`;
    return await ApiService.delete(url);
  },

  getRecipients: async (filter: string): Promise<RecipientModel[] | undefined> => {
    const url = `/api/message/recipient?filter=${filter}`;
    const recipients = await ApiService.get<RecipientModel[]>(url);
    return recipients || undefined;
  },

};

export default PostBoxService;
