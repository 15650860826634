import React, { useEffect, ChangeEvent, useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Grid } from "@mui/material";
import _ from "lodash";
import {
  WidgetCreateModel,
  WidgetModel,
} from "../../../../definitions/Landing";
import { WidgetPosition, WidgetPage } from "../../../../constants/enums";
import MTextField from "../../../../components/Base/MyhouseTextField";
import { useNavigate } from "react-router-dom";
import { ApplicationState } from "../../../../reducers/store";
import { AppThunkDispatch } from "../../../../definitions/Action";
import {
  getWidgets,
  deleteWidget,
  setSearchString,
  resetWidgets,
  addWidget,
} from "../../../../actions/landing.actions";
import { getClubs } from "../../../../actions/admin/admin.actions";
import WidgetCreateDialog from "./components/WidgetCreateDialog";
import { getCommunes } from "../../../../actions/admin/admin.commune.actions";
import {
  AdminTable,
  Column,
  Icon,
} from "../../components/AdminTable/AdminTable";

const addSpaces = (text: string) =>
  text && text.replace(/([A-Z])/g, " $1").toLocaleLowerCase();

const Widgets = ({
  clubs,
  widgets,
  widgetsCount,
  loading,
  searchString,
  communes,
  getClubs,
  getWidgets,
  setSearchString,
  deleteWidget,
  resetWidgets,
  addWidget,
  getCommunes,
}: WidgetConnectedProps) => {
  const [pagination, setPagination] = useState([0, 10]);
  const navigate = useNavigate();

  const columns: Column<WidgetModel>[] = useMemo(
    () => [
      {
        name: "Title",
        accessor: "Title",
      },
      {
        name: "Position",
        accessor: "Position",
        format: (data) => addSpaces(WidgetPosition[data]),
      },
      {
        name: "Pages",
        accessor: "Pages",
        format: (data) =>
          addSpaces(data.map((page: any) => WidgetPage[page]).join(",")),
      },
      {
        name: "Edit",
        icon: Icon.Edit,
        callback: (data) => navigate(`widgets/${data.Id}`),
      },
      {
        name: "Delete",
        icon: Icon.Delete,
        callback: (data) => handleDeleteWidgetClick(data.Id),
      },
    ],
    []
  );

  const handleChangeDebounced = _.debounce(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      if (value.length > 2 || value < searchString) {
        setSearchString(value, pagination[1]);
      }
    },
    400
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    handleChangeDebounced(e);
  };

  useEffect(() => {
    getClubs();
  }, []);

  useEffect(() => {
    getWidgets(searchString, pagination[0] * pagination[1], pagination[1]);
    return () => {
      resetWidgets();
    };
  }, [pagination]);

  const handleDeleteWidgetClick = (widgetId: string) => {
    deleteWidget(widgetId);
  };

  return (
    <Grid container justifyContent="center">
      <Grid container spacing={3} item sm={10} xs={12}>
        <Grid item container>
          <MTextField
            fullWidth
            placeholder="Search by title"
            onChange={handleChange}
          />
        </Grid>

        <Grid item container>
          <AdminTable
            data={widgets}
            columns={columns}
            loading={loading}
            pagination={"server"}
            count={widgetsCount}
            onPaginationChange={({ page, rows }) => setPagination([page, rows])}
            headerButton={
              <Grid container item justifyContent="start">
                <WidgetCreateDialog
                  addWidget={addWidget}
                  clubs={clubs}
                  communes={communes}
                  getCommunes={getCommunes}
                />
              </Grid>
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  widgets: state.adminLanding.widgets,
  widgetsCount: state.adminLanding.widgetsCount,
  loading: state.adminLanding.loading,
  searchString: state.adminLanding.searchString,
  clubs: state.admin.clubsList,
  communes: state.admin.communes,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getWidgets: (searchString: string, skip: number, take: number) =>
    dispatch(getWidgets(searchString, skip, take)),
  deleteWidget: (widgetId: string) => dispatch(deleteWidget(widgetId)),
  setSearchString: (searchString: string, take: number) =>
    dispatch(setSearchString(searchString, take)),
  resetWidgets: () => dispatch(resetWidgets()),
  addWidget: (widget: WidgetCreateModel) => dispatch(addWidget(widget)),
  getClubs: () => dispatch(getClubs(0)),
  getCommunes: () => dispatch(getCommunes()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type WidgetConnectedProps = ConnectedProps<typeof connector>;

export default connector(Widgets);
