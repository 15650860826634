import { MapCoords, WindowPosition, LocalPlanData } from './../../../definitions/Map';
declare let google: any;

export abstract class MapLayer {
    constructor(
        map: any,
        type: LayerType,
        displayName: string,
        defaultZoom = 12
    ) {
        this.Type = type;
        this.DisplayName = displayName;
        this.Objects = [];
        this.defaultZoom = defaultZoom;
        this.active = true;
        this.mapElement = map;
    }

    readonly Type: LayerType;
    readonly DisplayName: string;
    public Objects: Array<any>;
    public defaultZoom: number;
    public MessageText(item: any) { }
    protected mapElement: any;
    get Active(): boolean {
        return this.active;
    }
    protected active: boolean;
    abstract ToggleLayer(show: boolean): void;
}

export enum LayerType {
    Plans,
    Crime,
    Flood,
    Noise,
    Radon,
    Price,
    Property,
    Ground
}

export class PolygonLayer extends MapLayer {
    public showPolygonInfo: (data: LocalPlanData, windowPosition: WindowPosition) => void

    constructor(
        map: any,
        displayName: string,
        defaultZoom = 12,
        action: (data: LocalPlanData, windowPosition: WindowPosition) => void
    ) {
        super(map, LayerType.Plans, displayName, defaultZoom);

        this.showPolygonInfo = action
    }

    ToggleLayer(show: boolean) {
        this.active = show;
        if (!this.Objects.length) return;

        if (this.Active) {
            const mapBounds = new google.maps.LatLngBounds();

            this.Objects.forEach(elem => {
                elem.setMap(this.mapElement);

                elem.addListener("click", (event: any) => {
                    const target = Object.keys(event).find(key => event[key] instanceof MouseEvent);
                    target && this.showPolygonInfo(elem.data, { x: event[target].x, y: event[target].y })
                })

                elem.forEach(function (e: any) {
                    e.getGeometry().forEachLatLng((point: any) => {
                        mapBounds.extend(point);
                    });
                });
            });

            if (this.defaultZoom) {
                this.mapElement.setZoom(this.defaultZoom);
            } else if (!mapBounds.isEmpty()) {
                this.mapElement.fitBounds(mapBounds);
                this.mapElement.setZoom(this.mapElement.getZoom());
            }
            return;
        }

        this.Objects.forEach((elem: any) => {
            google.maps.event.clearInstanceListeners(elem);
            elem.setMap(null);
        });
    }
}

class WmsLayer extends MapLayer {
    public imageOverlay: any;
    public overlayName: string;
    public dataServiceSettings: any;
    public showOverlayInfo: (event: any) => void;
    public mapListener: EventListener;

    constructor(
        private map: any,
        type: LayerType,
        displayName: string,
        defaultZoom = 12,
        private targetLocation: any,
        action: ((coords: MapCoords, windowPosition: WindowPosition) => void) | null
    ) {
        super(map, type, displayName, defaultZoom);

        this.showOverlayInfo = (event: any) => {
            const target = Object.keys(event).find(key => event[key] instanceof MouseEvent);
            if (action && target) {
                action(event.latLng, { x: event[target].x, y: event[target].y })
            }
            this.MessageText(event);
        }
    }

    ToggleLayer(show: boolean) {
        this.active = show;

        if (this.Active) {
            this.mapElement.overlayMapTypes.insertAt(0, this.imageOverlay);

            if (this.defaultZoom && this.targetLocation) {
                this.mapElement.setCenter(this.targetLocation);
                this.mapElement.setZoom(this.defaultZoom);
            }
            this.mapListener = this.map.addListener("click", this.showOverlayInfo)
        } else {
            let currentIndex = 0;
            this.mapElement.overlayMapTypes.forEach((overlay: any, index: number) => {
                if(overlay.name === this.overlayName) currentIndex = index;
            });
            this.mapElement.overlayMapTypes.removeAt(currentIndex);
            google.maps.event.removeListener(this.mapListener);
        }
    }

    wmsParams() {
        return [
            "FORMAT=" + this.dataServiceSettings.format,
            "TRANSPARENT=" + this.dataServiceSettings.transparent,
            "LAYERS=" + this.dataServiceSettings.layers,
            "SERVICE=" + this.dataServiceSettings.service,
            "VERSION=" + this.dataServiceSettings.version,
            "Request=" + this.dataServiceSettings.request,
            "styles=" + this.dataServiceSettings.styles,
            "CRS=" + this.dataServiceSettings.crs,
            "WIDTH=" + this.dataServiceSettings.width,
            "HEIGHT=" + this.dataServiceSettings.height,
            "exceptions=" + this.dataServiceSettings.exceptions
        ];
    }

    getTileUrl(coord: any, zoom: number) {
        const lULP = new google.maps.Point(coord.x * 256, (coord.y + 1) * 256);
        const lLRP = new google.maps.Point((coord.x + 1) * 256, coord.y * 256);

        const projectionMap = new MercatorProjection();

        const lULg = projectionMap.fromDivPixelToLatLng(lULP, zoom);
        const lLRg = projectionMap.fromDivPixelToLatLng(lLRP, zoom);

        const lUL_Latitude = lULg.lat();
        const lUL_Longitude = lULg.lng();
        const lLR_Latitude = lLRg.lat();
        let lLR_Longitude = lLRg.lng();
        //GJ: there is a bug when crossing the -180 longitude border (tile does not render) - this check seems to fix it
        if (lLR_Longitude < lUL_Longitude) {
            lLR_Longitude = Math.abs(lLR_Longitude);
        }

        const urlResult = `${this.dataServiceSettings.baseUrl}${this.wmsParams().join("&")}&bbox=${lUL_Latitude},${lUL_Longitude},${lLR_Latitude},${lLR_Longitude}`;
        return urlResult;
    }
}

export class GroundLayer extends WmsLayer {
    overlayName = "Ground";

    constructor(
        map: any,
        displayName: string,
        defaultZoom = 12,
        targetLocation: any,
        apiUrl: string,
        action: (coords: MapCoords, windowPosition: WindowPosition) => void
    ) {
        super(map, LayerType.Ground, displayName, defaultZoom, targetLocation, action);

        this.dataServiceSettings = {
            baseUrl: apiUrl,
            format: "image/png",
            transparent: "true",
            layers: "ground",
            service: "WMS",
            version: "1.3.0",
            request: "GetMap",
            styles: "",
            crs: "EPSG:4326",
            width: "256",
            height: "256",
            exceptions: "inimage"
        };

        this.imageOverlay = new google.maps.ImageMapType({
            getTileUrl: this.getTileUrl.bind(this),
            tileSize: new google.maps.Size(256, 256),
            maxZoom: 20,
            minZoom: 2,
            radius: 1738000,
            opacity: 0.6,
            isPng: true,
            name: this.overlayName
        });
    }
}

export class CrimeLayer extends WmsLayer {
    overlayName = "Crime";

    constructor(
        map: any,
        displayName: string,
        defaultZoom = 12,
        targetLocation: any,
        apiUrl: string,
        action: (coords: MapCoords, windowPosition: WindowPosition) => void
    ) {
        super(map, LayerType.Crime, displayName, defaultZoom, targetLocation, action);

        this.dataServiceSettings = {
            baseUrl: apiUrl,
            format: "image/png",
            transparent: "true",
            layers: "crime",
            service: "WMS",
            version: "1.3.0",
            request: "GetMap",
            styles: "",
            crs: "EPSG:4326",
            width: "256",
            height: "256",
            exceptions: "inimage"
        };

        this.imageOverlay = new google.maps.ImageMapType({
            getTileUrl: this.getTileUrl.bind(this),
            tileSize: new google.maps.Size(256, 256),
            maxZoom: 20,
            minZoom: 2,
            radius: 1738000,
            opacity: 0.6,
            isPng: true,
            name: this.overlayName
        });
    }
}

export class RadonLayer extends WmsLayer {
    overlayName = "Radon";

    constructor(
        map: any,
        displayName: string,
        defaultZoom = 12,
        targetLocation: any,
        apiUrl: string,
        action: (coords: MapCoords, windowPosition: WindowPosition) => void
    ) {
        super(map, LayerType.Radon, displayName, defaultZoom, targetLocation, action);


        this.dataServiceSettings = {
            baseUrl: apiUrl,
            format: "image/png",
            transparent: "true",
            layers: "radon",
            service: "WMS",
            version: "1.3.0",
            request: "GetMap",
            styles: "",
            crs: "EPSG:4326",
            width: "256",
            height: "256",
            exceptions: "inimage"
        };

        this.imageOverlay = new google.maps.ImageMapType({
            getTileUrl: this.getTileUrl.bind(this),
            tileSize: new google.maps.Size(256, 256),
            maxZoom: 20,
            minZoom: 2,
            radius: 1738000,
            opacity: 0.6,
            isPng: true,
            name: this.overlayName
        });
    }
}

export class MercatorProjection {
    private pixelOrigin_: any;
    private pixelsPerLonDegree_: number;
    private pixelsPerLonRadian_: number;

    constructor() {
        const MERCATOR_RANGE = 256;
        this.pixelOrigin_ = new google.maps.Point(
            MERCATOR_RANGE / 2,
            MERCATOR_RANGE / 2
        );
        this.pixelsPerLonDegree_ = MERCATOR_RANGE / 360;
        this.pixelsPerLonRadian_ = MERCATOR_RANGE / (2 * Math.PI);
    }

    public fromLatLngToPoint(latLng: any, opt_point: any) {
        const me = this;

        const point = opt_point || new google.maps.Point(0, 0);

        const origin = me.pixelOrigin_;
        point.x = origin.x + latLng.lng() * me.pixelsPerLonDegree_;
        // NOTE(appleton): Truncating to 0.9999 effectively limits latitude to
        // 89.189.  This is about a third of a tile past the edge of the world tile.
        const siny = this.bound(
            Math.sin(this.degreesToRadians(latLng.lat())),
            -0.9999,
            0.9999
        );
        point.y =
            origin.y +
            0.5 * Math.log((1 + siny) / (1 - siny)) * -me.pixelsPerLonRadian_;
        return point;
    }

    public fromDivPixelToLatLng(pixel: any, zoom: number) {
        const me = this;

        const origin = me.pixelOrigin_;
        const scale = Math.pow(2, zoom);
        const lng = (pixel.x / scale - origin.x) / me.pixelsPerLonDegree_;
        const latRadians = (pixel.y / scale - origin.y) / -me.pixelsPerLonRadian_;
        const lat = this.radiansToDegrees(
            2 * Math.atan(Math.exp(latRadians)) - Math.PI / 2
        );

        return new google.maps.LatLng(lat, lng);
    }

    public fromDivPixelToSphericalMercator(pixel: any, zoom: number) {
        const me = this;

        const coord = me.fromDivPixelToLatLng(pixel, zoom);
        const r = 6378137.0;
        const x = r * this.degreesToRadians(coord.lng());
        const latRad = this.degreesToRadians(coord.lat());
        const y = (r / 2) * Math.log((1 + Math.sin(latRad)) / (1 - Math.sin(latRad)));

        return new google.maps.Point(x, y);
    }

    private bound(value: number, opt_min: number, opt_max: number) {
        if (opt_min !== null) value = Math.max(value, opt_min);
        if (opt_max !== null) value = Math.min(value, opt_max);
        return value;
    }

    private degreesToRadians(deg: number) {
        return deg * (Math.PI / 180);
    }

    private radiansToDegrees(rad: number) {
        return rad / (Math.PI / 180);
    }
}

export class FloodLayer extends WmsLayer {
    overlayName = "Flood";
    private floodLevel = 0;

    constructor(
        map: any,
        displayName: string,
        defaultZoom = 12,
        targetLocation: any,
        apiUrl: string,
        action: (floodLevel: number, windowPosition: WindowPosition) => void
    ) {
        super(map, LayerType.Flood, displayName, defaultZoom, targetLocation, null);

        this.dataServiceSettings = {
            baseUrl: apiUrl,
            format: "image/png",
            transparent: "TRUE",
            layers: "dhm_bluespot_ekstremregn",
            servicename: "dhm",
            service: "WMS",
            version: "1.3.0",
            request: "GetMap",
            styles: "bluespot_ekstremregn_0_075",
            crs: "EPSG:3857",
            width: "816",
            height: "260",
        };

        this.imageOverlay = new google.maps.ImageMapType({
            getTileUrl: this.getTileUrl.bind(this),
            tileSize: new google.maps.Size(256, 256),
            maxZoom: 20,
            minZoom: 2,
            radius: 1738000,
            opacity: 0.6,
            isPng: true,
            name: this.overlayName
        });

        this.showOverlayInfo = (event: any) => {
            const floodLevel = this.CalcFloodLevel(event.latLng.lat(), event.latLng.lng())
            const target = Object.keys(event).find(key => event[key] instanceof MouseEvent);
            target && action(floodLevel, { x: event[target].x, y: event[target].y })
            this.MessageText(event);
        }
    }

    getTileUrl(coord: any, zoom: number): string {
        const lULP = new google.maps.Point(coord.x * 256, (coord.y + 1) * 256);
        const lLRP = new google.maps.Point((coord.x + 1) * 256, coord.y * 256);

        const projectionMap = new MercatorProjection();

        const lULg = projectionMap.fromDivPixelToSphericalMercator(lULP, zoom);
        const lLRg = projectionMap.fromDivPixelToSphericalMercator(lLRP, zoom);

        const lUL_Latitude = lULg.y;
        const lUL_Longitude = lULg.x;
        const lLR_Latitude = lLRg.y;
        let lLR_Longitude = lLRg.x;
        //GJ: there is a bug when crossing the -180 longitude border (tile does not render) - this check seems to fix it
        if (lLR_Longitude < lUL_Longitude) {
            lLR_Longitude = Math.abs(lLR_Longitude);
        }
        const urlResult = `${this.dataServiceSettings.baseUrl}${this.wmsParams().join("&")}&bbox=${lUL_Longitude},${lUL_Latitude},${lLR_Longitude},${lLR_Latitude}`;
        return urlResult;
    }

    getCoordinates(lat: number, lng: number) {
        const RADIUS = 6378137.0;
        const y = Math.log(Math.tan(Math.PI / 4 + (lat * Math.PI) / 180 / 2)) * RADIUS;
        const x = (lng * Math.PI) / 180 * RADIUS;

        return { x: x, y: y };
    }

    getSelectedTileUrl(width: number, height: number, lat: number, lng: number): string {
        const coord = this.getCoordinates(lat, lng);
        const IUL_lng = coord.x - width / 2;
        const IUL_lat = coord.y - height / 2;
        const lLR_lng = coord.x + width / 2;
        const lLR_lat = coord.y + height / 2;

        const urlResult = `${this.dataServiceSettings.baseUrl}${this.wmsParams().join("&")}&bbox=${IUL_lng},${IUL_lat},${lLR_lng},${lLR_lat}`;
        return urlResult;
    }

    wmsParams(): string[] {
        return [
            "FORMAT=" + this.dataServiceSettings.format,
            "SERVICENAME=" + this.dataServiceSettings.servicename,
            "login=" + this.dataServiceSettings.username,
            "password=" + this.dataServiceSettings.password,
            "id=" + this.dataServiceSettings.id,
            "LAYERS=" + this.dataServiceSettings.layers,
            "TRANSPARENT=" + this.dataServiceSettings.transparent,
            "SERVICE=" + this.dataServiceSettings.service,
            "VERSION=" + this.dataServiceSettings.version,
            "Request=" + this.dataServiceSettings.request,
            "styles=" + this.dataServiceSettings.styles,
            "SRS=" + this.dataServiceSettings.srs,
            "CRS=" + this.dataServiceSettings.crs,
            "WIDTH=" + this.dataServiceSettings.width,
            "HEIGHT=" + this.dataServiceSettings.height
        ];
    }

    //loads tile on virtual canvas where it pass through all img pixels and counts how many times every blue pixes is appear
    public CalcFloodLevel(lat: number, lng: number): number {
        const width = 256;
        const height = 256;
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext("2d");
        const marks = [0, 0, 0, 0, 0];
        const img = new Image();
        img.src = this.getSelectedTileUrl(width, height, lat, lng);
        img.setAttribute("crossOrigin", "");
        img.onload = () => {
            if (context) {
                context.drawImage(img, 0, 0);

                // get image data of every pixel of tile
                for (let x = 0; x < width; x++) {
                    for (let y = 0; y < height; y++) {
                        const imageData = context.getImageData(x, y, 1, 1).data;
                        // check if is not transparent
                        if (imageData[2] > 0) {
                            // check if it equals to one of 5 blue tone (0,0,255; 42,42,255; 84,84,255; 126,126,255; 168, 168, 255; 210,210,255)
                            for (let mul = 0; mul < marks.length; mul++) {
                                if (imageData[0] === 42 * mul) {
                                    marks[marks.length - mul - 1]++;
                                }
                            }
                        }
                    }
                }

                if (marks.every((x) => x === 0)) {
                    this.floodLevel = 0;
                    return;
                }

                this.floodLevel = marks
                    .map((x, i) => x * (i + 1))
                    .reduce((a, b) => a + b) / marks.reduce((a, b) => a + b) / 5;
            }
        };
        return Math.round(this.floodLevel);
    }
}

export class NoiseLayer extends WmsLayer {
    overlayName = "Noise";

    constructor(
        map: any,
        displayName: string,
        defaultZoom = 12,
        targetLocation: any,
        apiUrl: string,
        action: (coords: MapCoords, windowPosition: WindowPosition) => void
    ) {
        super(map, LayerType.Noise, displayName, defaultZoom, targetLocation, action);

        this.dataServiceSettings = {
            baseUrl: apiUrl,
            format: "image/png",
            transparent: "true",
            layers: "noise",
            service: "WMS",
            version: "1.3.0",
            request: "GetMap",
            styles: "",
            crs: "EPSG:4326",
            width: "256",
            height: "256",
            exceptions: "inimage"
        };

        this.imageOverlay = new google.maps.ImageMapType({
            getTileUrl: this.getTileUrl.bind(this),
            tileSize: new google.maps.Size(256, 256),
            maxZoom: 20,
            minZoom: 2,
            radius: 1738000,
            opacity: 0.6,
            isPng: true,
            name: this.overlayName
        });
    }
}