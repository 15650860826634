import React, { useEffect, useReducer, useState } from "react";
import { Box, Button, CircularProgress, FormControlLabel, Grid, Switch, Tab, Tabs } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import WidgetEditForm from "./components/WidgetEditForm";
import GrapesJsEditor from "../../../../components/Grapes/GrapesJsEditor";
import { getWidget, getEditor, updateWidget, useAdminLandings } from "../../../../actions";
import { AppThunkDispatch } from "../../../../definitions/Action";
import { connect, ConnectedProps } from "react-redux";
import { WidgetModel, WidgetMetaModel, FormBuilderDTO, LandingType } from "../../../../definitions/Landing";
import { getClubs } from "../../../../actions/admin/admin.actions";
import { ApplicationState } from "../../../../reducers/store";
import { getCommunes } from "../../../../actions/admin/admin.commune.actions";
import LanguageChangeMenu from "../../../../components/Layout/LanguageChangeMenu";
import { ArrowBack } from "@mui/icons-material";
import { LanguageType } from "../../../../definitions/Menu";

type TabPanelProps = {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;

  return (
    <Box mt={4} width="100%" role="tabpanel" hidden={value !== index}>
      {value === index && (
        <Grid container justifyContent="center">
          {children}
        </Grid>
      )}
    </Box>
  );
};

type WidgetState = {
  widget: WidgetModel | null;
  editor: FormBuilderDTO | null;
  currentTab: number;
  widgetLoading: boolean;
  editorLoading: boolean;
  languageId: LanguageType;
  themeChecked: boolean;
};

const initialState: WidgetState = {
  widget: null,
  editor: null,
  currentTab: 0,
  widgetLoading: false,
  editorLoading: false,
  languageId: "da",
  themeChecked: false,
};

const reducer = (state: WidgetState, action: { type: string; payload: any }) => {
  return { ...state, [action.type]: action.payload };
};

const WidgetEditor = (props: WidgetEditorConnectedProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { id } = useParams<{ id: string }>();

  const onFullfilled = (type: string) => (result: unknown) => dispatch({ type, payload: result });
  const setIsLoading = (name: string, value: boolean) => dispatch({ type: name + "Loading", payload: value });

  const [languageId, setLanguageId] = useState<LanguageType>("da");

  const [, { addWidgetLocalization }] = useAdminLandings();

  const handleChange = (event: React.ChangeEvent<any>, newTab: number) => {
    dispatch({ type: "currentTab", payload: newTab });
  };

  const updateWidget = (widget: WidgetMetaModel) => {
    props.updateWidget(widget).then((data: any) => {
      dispatch({ type: "widget", payload: data });
    });
  };

  const getWidgetAsync = async () => {
    if (!state.widget && id) {
      setIsLoading("widget", true);
      try {
        const widget = await props.getWidget(id);
        onFullfilled("widget")(widget);
      } finally {
        setIsLoading("widget", false);
      }
    }

    if (state.widget) {
      setIsLoading("editor", true);
      const contentId = state.widget.LocalizedContent[state.languageId]
        ? state.widget.LocalizedContent[state.languageId]
        : id;

      try {
        if (contentId) {
          const editor = await props.getEditor(contentId);
          onFullfilled("editor")(editor);
        }
      } finally {
        setIsLoading("editor", false);
      }
    }
  };

  useEffect(() => {
    if (!props.clubs.length) props.getClubs();
  }, []);

  useEffect(() => {
    if (languageId && state.languageId !== languageId) {
      getWitgetLocalization(languageId);
    }
  }, [languageId]);

  useEffect(() => {
    getWidgetAsync();
  }, [id, state.widget, state.languageId]);

  const getWitgetLocalization = async (languageId: string) => {
    setIsLoading("widget", true);
    onFullfilled("languageId")(languageId);
    if (state.widget?.Id && !state.widget?.LocalizedContent[languageId]) {
      const widget = await addWidgetLocalization(state.widget.Id, languageId);
      onFullfilled("widget")(widget);
    }
    setIsLoading("widget", false);
  };

  const handleChangeTheme = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading("editor", true);

    new Promise<void>((resolve, reject) => {
      onFullfilled("themeChecked")(event.target.checked);
      resolve();
    }).finally(() => {
      setIsLoading("editor", false);
    });
  };

  return (
    <Grid container justifyContent="center">
      <Grid
        container
        item
        display={"flex"}
        xs={12}
        justifyContent={"space-between"}
        wrap={"nowrap"}
        alignItems={"flex-start"}
      >
        <Grid item justifyContent={"flex-start"} display={"flex"} alignItems={"center"}>
          <Button component={Link} to={"/admin/widgets"} type="submit" variant="outlined" color="secondary">
            <ArrowBack fontSize="small" />
            Back
          </Button>
        </Grid>

        <Grid item md={6} sm={9} xs={12}>
          <Tabs
            value={state.currentTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            sx={{ width: "100%" }}
          >
            <Tab label="Widget info" />
            <Tab label="Editor" />
          </Tabs>
        </Grid>

        <Grid
          container
          item
          spacing={2}
          alignItems={"center"}
          justifyContent={"flex-end"}
          sx={{ maxWidth: "200px", marginTop: "-8px", visibility: state.currentTab == 1 ? "visible" : "hidden" }}
        >
          <Grid item>
            <FormControlLabel
              style={{
                marginLeft: 0,
              }}
              control={
                <Switch
                  color="secondary"
                  size="small"
                  aria-label="ccxcxc"
                  checked={state.themeChecked}
                  onChange={handleChangeTheme}
                />
              }
              label="Theme switch"
              labelPlacement="start"
            />
          </Grid>

          <Grid item sx={{ zIndex: 500 }}>
            <LanguageChangeMenu
              loading={state.widgetLoading || state.editorLoading}
              languageId={languageId}
              setLanguageId={setLanguageId}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item container>
        <TabPanel value={state.currentTab} index={0}>
          <Grid item lg={6} md={9} sm={11} xs={12}>
            {state.widget && props.clubs.length > 0 && (
              <WidgetEditForm
                updateWidget={updateWidget}
                item={state.widget}
                clubs={props.clubs}
                communes={props.communes}
                getCommunes={props.getCommunes}
              />
            )}
          </Grid>
        </TabPanel>
        <TabPanel value={state.currentTab} index={1}>
          <Grid item xs={12}>
            {!state.widgetLoading && !state.editorLoading && state.editor ? (
              <GrapesJsEditor
                id="widgetEditor"
                builder={state.editor}
                type={LandingType.Widget}
                themeToUse={state.themeChecked ? "communeTheme" : "theme"}
                externalId={state.widget?.Id}
              />
            ) : (
              <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  clubs: state.admin.clubsList,
  loading: state.admin.loading,
  communes: state.admin.communes,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getWidget: (widgetId: string) => dispatch(getWidget(widgetId)),
  getEditor: (editorId: string) => dispatch(getEditor(editorId)),
  updateWidget: (widget: WidgetMetaModel) => dispatch(updateWidget(widget)),
  getClubs: () => dispatch(getClubs(0)),
  getCommunes: () => dispatch(getCommunes()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type WidgetEditorConnectedProps = ConnectedProps<typeof connector>;

export default connector(WidgetEditor);
