import React, { useEffect } from "react";
import { Card, Link, useMediaQuery, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CarIcon from "./../../pages/ClimatePlan/shared/components/img/klima-icons/Transport-Icon.svg";
import ElectricityIcon from "./../../pages/ClimatePlan/shared/components/img/klima-icons/Appliances-Icon.svg";
import FoodIcon from "./../../pages/ClimatePlan/shared/components/img/klima-icons/Food-Icon.svg";
import HomeIcon from "./../../pages/ClimatePlan/shared/components/img/klima-icons/Home-Icon.svg";
import MobileIcon from "./../../pages/ClimatePlan/shared/components/img/klima-icons/Mobile-Icon.svg";
import ShoppingIcon from "./../../pages/ClimatePlan/shared/components/img/klima-icons/Clothing-Icon.svg";
import VacationIcon from "./../../pages/ClimatePlan/shared/components/img/klima-icons/Travel-Icon.svg";
import { LandingArticleDto } from "../../definitions/Landing";
import { ArticleCategory } from "../../constants/enums";
import { getColorByType, theme } from "../../constants/theme";
import { useTranslation } from "react-i18next";

const CardStyled = styled(Card, {
  shouldForwardProp: (propName: string) => propName !== "type",
})<{ type: "preview" | "full" }>(({ theme, type }) => ({
  width: "100%",
  padding:  type === "full" ? 8 : 0,
  border: type === "preview" ? "1px solid" : "null",
  borderColor: type === "preview" ? theme.palette.divider : "null",
  "& .article-main-content, & .article-preview": {
    background: "none",
    border: "none",
    "& div > p > span > a": {
      color: theme.palette.primary.main,
    },
  },
  "& .article-main-content": {
    display: type === "preview" ? "none" : "auto",
  },
  "& .article-preview": {
    display: type !== "preview" ? "none" : "auto",
    "& .g-container": {
      maxHeight: 200,
      overflow: "hidden",
    },
  },
}));

const LinkStyled = styled(Link,{
  shouldForwardProp: (propName: string) => propName !== "categorized",
})<{ categorized: boolean }>(({ theme, categorized }) => ({
  display: "block",
  marginTop: -24,
  marginLeft: categorized ? 241 : 20,
  fontSize: 10,
  [theme.breakpoints.down("sm")]: {
    marginLeft: categorized ? 21 : 20,
  },
}));

const CategoryIcon = styled("img")({
  width: 28,
  borderRadius: 5,
  marginLeft: 4,
});

const CategoryList = styled("div", {
  shouldForwardProp: (propName: string) => propName !== "isXs",
})<{ isXs: boolean }>(({ theme, isXs }) => ({
  color: getColorByType("secondary").main,
  textAlign: isXs ? "end" : "inherit",

  position: !isXs ? "absolute" : "inherit",
  right: !isXs ? 10 : "inherit",
  top: !isXs ? 10 : "inherit",
}));

type LandingArticleProps = {
  model: LandingArticleDto;
  type: "preview" | "full";
  categorized: boolean;
  url?: string;
};

const LandingArticle = (props: LandingArticleProps): JSX.Element => {
  const { model, type, categorized } = props;
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  const redirect = () => {
    navigate(model.NavigateTo);
  };

  useEffect(() => {
    const style = document.createElement("style");
    style.setAttribute("data-meta", "widget-" + model.Id);
    style.innerHTML = model.Css;
    document.head.appendChild(style);

    const article = document.getElementById(model.Id);
    let title: HTMLElement | null;
    if (article) {
      title = article.querySelector(".title");

      if (title) {
        title.addEventListener("click", redirect);
      }
    }

    return () => {
      document.head.removeChild(style);
      if (title) title.removeEventListener("click", redirect);
    };
  }, [model]);

  const getCategoryIcon = (category: ArticleCategory): React.ReactElement => {
    switch (category) {
      case ArticleCategory.Transport:
        return (
          <CategoryIcon
            src={CarIcon}
            key="1"
            alt=""
          ></CategoryIcon>
        );
      case ArticleCategory.Vacation:
        return (
          <CategoryIcon
            src={VacationIcon}
            key="2"
            alt=""
          ></CategoryIcon>
        );
      case ArticleCategory.Clothes:
        return (
          <CategoryIcon
            src={ShoppingIcon}
            key="3"
            alt=""
          ></CategoryIcon>
        );
      case ArticleCategory.Food:
        return (
          <CategoryIcon
            src={FoodIcon}
            key="4"
            alt=""
          ></CategoryIcon>
        );
      case ArticleCategory.Gadgets:
        return (
          <CategoryIcon
            src={MobileIcon}
            key="5"
            alt=""
          ></CategoryIcon>
        );
      case ArticleCategory.Electricity:
        return (
          <CategoryIcon
            src={ElectricityIcon}
            key="6"
            alt=""
          ></CategoryIcon>
        );
      case ArticleCategory.Heating:
        return (
          <CategoryIcon
            src={HomeIcon}
            key="7"
            alt=""
          ></CategoryIcon>
        );
      default:
        return <></>;
    }
  };

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <CategoryList isXs={isXs}>
        {model.Categories?.map((category) => getCategoryIcon(category))}
      </CategoryList>
      <CardStyled
        variant="elevation"
        elevation={0}
        id={model.Id}
        type={type}
        dangerouslySetInnerHTML={{ __html: model.Html }}
      ></CardStyled>
      {type === "preview" && (
        <LinkStyled color="inherit" categorized={categorized} onClick={redirect}>
          {t("Base.Popovers.ReadMore")}
        </LinkStyled>
      )}
    </div>
  );
};

export default LandingArticle;
