import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm, Controller } from "react-hook-form";

import TemplatesSelector from "../../Templates/components/TemplatesSelector";
import { TemplateType } from "../../../../../constants/enums";
import { BenefitClubType } from "../../../../../definitions/Landing";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";

type BenefitClubCreateDialogProps = {
  open: boolean;
  handleClose: (club?: BenefitClubFormData) => void;
};

export type BenefitClubFormData = {
  Name: string;
  Type: BenefitClubType | null;
  TemplateId: string | null;
};

const BenefitClubCreateDialog = (
  props: BenefitClubCreateDialogProps
): JSX.Element => {
  const methods = useForm<BenefitClubFormData>({
    defaultValues: {
      Name: "",
      Type: null,
      TemplateId: null,
    },
  });
  const { register, handleSubmit, formState: { errors }, control } = methods;

  const { t } = useTranslation("translation");

  const handleClose = () => {
    props.handleClose();
  };

  const submit = (data: BenefitClubFormData) => {    
    props.handleClose(data);
  };

  const selectItems = Object.entries(BenefitClubType).filter(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([key, val]) => !Number.isInteger(val)
  );

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Opret Fordelsklub</DialogTitle>
      <form onSubmit={handleSubmit(submit)}>
        <DialogContent>
          <TextFieldConnector register={register("Name", { required: true })}>
            <TextField
              autoFocus
              margin="dense"
              name="Name"
              type="text"
              fullWidth
              error={!!errors.Name}
            />
          </TextFieldConnector>
          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel>Fordelsklub type</InputLabel>
            <Controller
              name="Type"
              render={({ field }) => (
                <Select {...field}>
                  {selectItems.map(([key, val]) => (
                    <MenuItem
                      key={key}
                      value={key}
                      style={{ padding: "10px 20px" }}
                    >
                      {val}
                    </MenuItem>
                  ))}
                </Select>
              )}
              control={control}
            />
          </FormControl>
          <TemplatesSelector
            name="TemplateId"
            templatesType={TemplateType.Fordelsklub}
            control={control}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="secondary">
            {t("General.Buttons.Undo")}
          </Button>
          <Button variant="contained" type="submit" color="secondary">
            {t("General.Buttons.Create")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BenefitClubCreateDialog;
