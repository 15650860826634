import { Button, Grid, LinearProgress, TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { TextFieldConnector } from "../../../../../components/Base/FormConnector";
import { ClubModel } from "../../../../../definitions/model/Club";

type FormData = {
  Name: string;
  UniqueName: string;
  Description: string;
};

type ClubEditFormProps = {
  club: ClubModel;
  loading: boolean;
  editClub: (club: ClubModel) => Promise<void>;
};

const ClubEditForm = (props: ClubEditFormProps): JSX.Element => {
  const methods = useForm<FormData>({
    defaultValues: {
      Description: props.club.Description,
      UniqueName: props.club.UniqueName,
      Name: props.club.Name,
    },
  });

  const { register, handleSubmit } = methods;

  const sumbit = (values: FormData) => {
    props.editClub({
      ...props.club,
      ...values,
    });
  };

  return (
    <form onSubmit={handleSubmit(sumbit)}>
      <TextFieldConnector register={register("Name", {required: true})}>
        <CustomTextField label="Name" />
      </TextFieldConnector>
      <TextFieldConnector 
        register={register("UniqueName", {
          required: true,
          validate: (value) => /[a-z0-9_-]+$/gi.test(value)
        })}
      >
        <CustomTextField label="Unique name" />
      </TextFieldConnector>
      <TextFieldConnector register={register("Description", {required: true})}>
        <CustomTextField label="Description" multiline maxRows={3} />
      </TextFieldConnector>
      <Grid container justifyContent="flex-end">
        <Button type="submit" color="secondary" variant="contained">
          Update
        </Button>
      </Grid>
      {props.loading && <LinearProgress />}
    </form>
  );
};

const CustomTextField = (props: TextFieldProps): JSX.Element => (
  <TextField variant="outlined" margin="dense" fullWidth {...props} />
);

export default ClubEditForm;
