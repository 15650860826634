export const UserConstants = {
  USER_OPERATION_FAILED: "USER_OPERATION_FAILED",
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",
  OPEN_FACEBOOK_LOGIN: "OPEN_FACEBOOK_LOGIN",
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  LOGOUT: "USERS_LOGOUT",
  GET_USER: "GET_USER",
  GET_USER_SUCCEEDED: "GET_USER_SUCCEEDED",
  SET_USERNAME: "SET_USERNAME",
  INITIATE_MOVE_IN: "INITIATE_MOVE_IN",
  MOVE_IN_UNIT: "MOVE_IN_UNIT",
  MOVE_IN_UNIT_SUCCESS: "MOVE_IN_UNIT_SUCCESS",
  MOVE_OUT_UNIT: "MOVE_OUT_UNIT",
  MOVE_OUT_UNIT_SUCCESS: "MOVE_OUT_UNIT_SUCCESS",
  COMPLETE_REGISTRATION: "MOVE_OUT_UNITE_SUCCESS",
  COMPLETE_REGISTRATION_SUCCESS: "COMPLETE_REGISTRATION_SUCCESS",
  INIT_SIGNALR_SUCCEEDED: "INIT_SIGNALR_SUCCEEDED",
  START_SIGNALR: "START_SIGNALR",
  START_SIGNALR_SUCCEEDED: "START_SIGNALR_SUCCEEDED",
  STOP_SIGNALR: "STOP_SIGNALR",
  STOP_SIGNALR_SUCCEEDED: "STOP_SIGNALR_SUCCEEDED",
  UPDATE_USER_INTERESTS: "UPDATE_USER_INTERESTS",
  UPDATE_USER_INTERESTS_SUCCEEDED: "UPDATE_USER_INTERESTS_SUCCEEDED",
  REMOVE_USER: "REMOVE_USER",
  REMOVE_USER_SUCCEEDED: "REMOVE_USER_SUCCEEDED",
  GET_USER_SETTIINGS: "GET_USER_SETTIINGS",
  GET_USER_SETTIINGS_SUCCEEDED: "GET_USER_SETTIINGS_SUCCEEDED",
  UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
  UPDATE_USER_PROFILE_SUCCEEDED: "UPDATE_USER_PROFILE_SUCCEEDED",
  UPDATE_USER_NOTIFICATION_SETTINGS: "UPDATE_USER_NOTIFICATION_SETTINGS",
  UPDATE_USER_NOTIFICATION_SETTINGS_SUCCEEDED: "UPDATE_USER_NOTIFICATION_SETTINGS_SUCCEEDED",
  CHANGE_USER_PASSWORD: "CHANGE_USER_PASSWORD",
  CHANGE_USER_PASSWORD_SUCCEEDED: "CHANGE_USER_PASSWORD_SUCCEEDED",
  GET_USER_SETTINGS_HISTORY: "GET_USER_SETTINGS_HISTORY",
  GET_USER_SETTINGS_HISTORY_SUCCEEDED: "GET_USER_SETTINGS_HISTORY_SUCCEEDED",
  UPDATE_USER_PICTURE: "UPDATE_USER_PICTURE",
  UPDATE_USER_PICTURE_SUCCEEDED: "UPDATE_USER_PICTURE_SUCCEEDED",
  GET_USER_SUBSCRIPTIONS: "GET_USER_SUBSCRIPTIONS",
  GET_USER_SUBSCRIPTIONS_SUCCEEDED: "GET_USER_SUBSCRIPTIONS_SUCCEEDED",
  CHANGE_USER_CURRENT_HOUSE: "CHANGE_USER_CURRENT_HOUSE",
  CHANGE_USER_CURRENT_HOUSE_SUCCEEDED: "CHANGE_USER_CURRENT_HOUSE_SUCCEEDED",
  CHANGE_USER_CURRENT_BUILDING: "CHANGE_USER_CURRENT_BUILDING",
  CHANGE_USER_CURRENT_BUILDING_SUCCEEDED: "CHANGE_USER_CURRENT_BUILDING_SUCCEEDED",
  SUBSCRIBE_TO_PHOTO_UPDATE: "SUBSCRIBE_TO_PHOTO_UPDATE",
  SUBSCRIBE_TO_PHOTO_UPDATE_SUCCEEDED: "SUBSCRIBE_TO_PHOTO_UPDATE_SUCCEEDED",
  CHECK_IS_USER_SUBSCRIBED_TO_PHOTO_UPDATE: "CHECK_IS_USER_SUBSCRIBED_TO_PHOTO_UPDATE",
  CHECK_IS_USER_SUBSCRIBED_TO_PHOTO_UPDATE_SUCCEEDED: "CHECK_IS_USER_SUBSCRIBED_TO_PHOTO_UPDATE_SUCCEEDED",
  SUBSCRIBE_TO_PRICE_UPDATE: "SUBSCRIBE_TO_PRICE_UPDATE",
  SUBSCRIBE_TO_PRICE_UPDATE_SUCCEEDED: "SUBSCRIBE_TO_PRICE_UPDATE_SUCCEEDED",
  OPEN_CREATE_DIALOG: "OPEN_CREATE_DIALOG",
  OPEN_CREATE_DIALOG_SUCCEEDED: "OPEN_CREATE_DIALOG_SUCCEEDED",
  OPEN_LOGIN_DIALOG: "OPEN_LOGIN_DIALOG",
  OPEN_LOGIN_DIALOG_SUCCEEDED: "OPEN_LOGIN_DIALOG_SUCCEEDED",
  SHOW_MOVE_IN_DIALOG: "SHOW_MOVE_IN_DIALOG",
  HIDE_MOVE_IN_DIALOG: "HIDE_MOVE_IN_DIALOG",
  SET_REGISTRATION_COMPLETE: "SET_REGISTRATION_COMPLETE",
  CHECK_IS_FACEBOOK_USER_EXIST: "CHECK_IS_FACEBOOK_USER_EXIST",
  CHECK_IS_FACEBOOK_USER_EXIST_SUCCEEDED: "CHECK_IS_FACEBOOK_USER_EXIST_SUCCEEDED",
  SET_PWA_OPTIONS: "SET_PWA_OPTIONS",
  GET_USER_THEME: "GET_USER_THEME",
  GET_USER_THEME_SUCCEEDED: "GET_USER_THEME_SUCCEEDED",
  SET_LANGUAGE: "SET_LANGUAGE",
  GET_LANGUAGE: "GET_LANGUAGE",
  SET_AUTH_DESTINATION: "SET_AUTH_DESTINATION",
  SET_IS_MAINTENANCE: "SET_IS_MAINTENANCE",
  ACCEPT_NEW_TERMS: "ACCEPT_NEW_TERMS",
  NEW_TERMS_ACCEPTED: "NEW_TERMS_ACCEPTED",
  LEAVE_ALL_COMMUNES: "LEAVE_ALL_COMMUNES",
  LEAVE_ALL_COMMUNES_SUCCEEDED: "LEAVE_ALL_COMMUNES_SUCCEEDED",
  GET_USER_LOG_MESSAGES: "GET_USER_LOG_MESSAGES",
  GET_USER_LOG_MESSAGES_SUCCEEDED: "GET_USER_LOG_MESSAGES_SUCCEEDED",
  TRY_USER: "TRY_USER",
  TRY_USER_SUCCEEDED: "TRY_USER_SUCCEEDED",
  UNTRY_USER: "UNTRY_USER",
  UNTRY_USER_SUCCEEDED: "UNTRY_USER_SUCCEEDED",
  USER_FAILED_ENTERING_ADRESS: "USER_FAILED_ENTERING_ADRESS",
  USER_TYPPED_ADDRESS_FAILED_TO_REGISTER: "USER_TYPPED_ADDRESS_FAILED_TO_REGISTER",
  USER_REGISTERED_AND_STOP: "USER_REGISTERED_AND_STOP",
  USER_SEEN_CO2_REGISTER_AND_STOP: "USER_SEEN_CO2_REGISTER_AND_STOP",
  USER_CREATED_PLAN_AND_STOP_WITHOUT_FILLING: "USER_CREATED_PLAN_AND_STOP_WITHOUT_FILLING",
  SET_UI_MODE: "SET_UI_MODE",
  UPDATE_USER_LANGUAGE: "UPDATE_USER_LANGUAGE",
  UPDATE_USER_LANGUAGE_SUCCEEDED: "UPDATE_USER_LANGUAGE_SUCCEEDED",
  USER_RETURN_AND_FINISH_PLAN: "USER_RETURN_AND_FINISH_PLAN",
  USER_START_STYLES_SURVEY: "USER_START_STYLES_SURVEY",
  USER_FINISH_STYLES_SURVEY: "USER_FINISH_STYLES_SURVEY",
  USER_MAIL_CLICK_STYLES_SURVEY: "USER_MAIL_CLICK_STYLES_SURVEY"
};
