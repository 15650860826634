import React from "react";
import { CO2EmissionSurvey } from "../CO2Plan";
import { CO2Context } from "./CO2ContextWrapper";
import { ContextProps } from "../ClimatePlanPage";
import { useAppDispatch } from "../../../../actions";
import { setActiveGroupIndex } from "../co2plan.actions";

type ReadonlySurveyContextProps = {
  planId: string;
  defaultCO2Emission: number;
  governmentGoalCO2Emission: number;
  unchangeableBasicEmission: number;
  isTargetSurvey: boolean;
  IsSinglePersonPlan: boolean;
  survey: CO2EmissionSurvey;
  children: React.ReactNode;
  contextData: ContextProps;
};

const ReadonlySurveyContextWrapper = (
  props: ReadonlySurveyContextProps
): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <CO2Context.Provider
      value={{
        addValueSource: () => {},
        addOptionSource: () => {},
        selectHighLevelSource: () => {},
        selectSponsorSolution: () => {},
        setDueYear: () => {},
        renderWidget: props.contextData.renderWidget,
        setActiveGroupIndex: (index: number) =>
          dispatch(
            setActiveGroupIndex(index, props.survey, props.isTargetSurvey)
          ),
        markTimelineActionAsFulfilled: async () => {},
        setEconomyCost: () => {},
        registerCO2EconomyItemLinkClick: async () => {},
        setTargetValue: () => {},
        saveEmissionSurvey: async () => {},
        planId: props.planId,
        defaultCO2Emission: props.defaultCO2Emission,
        governmentGoalCO2Emission: props.governmentGoalCO2Emission,
        unchangeableBasicEmission: props.unchangeableBasicEmission,
        targetValue: null,
        missingSourceGroups: props.survey.MissingSourceGroups ?? [],
        isTargetSurvey: props.isTargetSurvey,
        IsSinglePersonPlan: props.IsSinglePersonPlan,
        ...props.contextData,
      }}
    >
      {props.children}
    </CO2Context.Provider>
  );
};

export default ReadonlySurveyContextWrapper;
