import React, { useEffect, ChangeEvent, useReducer, useMemo } from "react";
import { Grid, Tooltip, Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { format } from "date-fns";

import MTextField from "../../../../../components/Base/MyhouseTextField";
import { useAlertContext } from "../../../../../components/Base";
import { AdminTable, Column, Icon } from "../../../components/AdminTable/AdminTable";
import { CompanyAdModel } from "../../../../../definitions/Company";
import { useCompanyAds } from "../../../../../actions/admin/admin.company-ad.actions";
import CompanyAdCreationDialog from "./CompanyAdCreationDialog";
import CompanyAdEditDialog from "./CompanyAdEditDialog";
import { useCompanies } from "../../../../../actions/admin/admin.company.actions";
import { useAdmin } from "../../../../../actions/admin/admin.actions";

type CompanyAdsState = {
  searchString: string;
  addCompanyAdModalShown: boolean;
  adToEdit: CompanyAdModel | null;
};

const initialState: CompanyAdsState = {
  searchString: "",
  addCompanyAdModalShown: false,
  adToEdit: null,
};

const reducer = (state: CompanyAdsState, action: { type: string; payload: unknown }) => {
  return { ...state, [action.type]: action.payload };
};

const CompanyAds = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { ads, actions } = useCompanyAds();
  const [{ companies }, companyActions] = useCompanies();
  const [adminState] = useAdmin();

  const columns: Column<CompanyAdModel>[] = useMemo(
    () => [
      {
        name: "Company",
        accessor: "CompanyName",
      },
      {
        name: "Text",
        accessor: "Text",
        format: (text) => (text.length < 50 ? text : text.slice(0, 50) + "..."),
      },
      {
        name: "Active from",
        accessor: "ActiveFrom",
        format: (date) => (date ? format(date, "yyyy-MM-dd") : ""),
      },
      {
        name: "Active to",
        accessor: "ActiveTo",
        format: (date) => (date ? format(date, "yyyy-MM-dd") : ""),
      },
      {
        name: "Edit",
        icon: Icon.Edit,
        callback: (data) => dispatch({ type: "adToEdit", payload: data }),
      },

      {
        name: "Delete",
        icon: Icon.Delete,
        callback: (data) => handleDeleteClick(data.CompanyId, data.Id),
      },
    ],
    []
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: "searchString", payload: event.currentTarget.value });

  const { showAlert } = useAlertContext();

  useEffect(() => {
    actions.getCompanyAds(0, 50);
    if (companies.length === 0) {
      const take = 1000000; // use a big number to get all companies
      companyActions.getCompanies(0, take);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (adminState.error) {
      showAlert({
        severity: "error",
        text: adminState.error,
      });
    }
  }, [adminState.error]);

  const showAddCompanyAdModal = () => {
    dispatch({ type: "addCompanyAdModalShown", payload: true });
  };

  const handleModalClose = (ad?: CompanyAdModel) => {
    dispatch({ type: "addCompanyAdModalShown", payload: false });
  };

  const handleDeleteClick = (companyId: string, adId: string) => {
    actions.deleteCompanyAd(companyId, adId);
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid container spacing={3} item sm={10} xs={12}>
          <Grid item container>
            <MTextField fullWidth placeholder="Search by text or company" onChange={handleChange} />
          </Grid>

          <Grid item container>
            <AdminTable
              data={ads.filter(
                (ad) =>
                  ad.Text.toLocaleLowerCase().includes(state.searchString.toLowerCase()) ||
                  ad.CompanyName.toLocaleLowerCase().includes(state.searchString.toLowerCase())
              )}
              columns={columns}
              loading={adminState.loading}
              pagination={"client"}
              headerButton={
                <Grid container item justifyContent="flex-start">
                  <Tooltip title="New ad">
                    <Button color="primary" variant="contained" onClick={showAddCompanyAdModal}>
                      <Add />
                    </Button>
                  </Tooltip>
                </Grid>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <CompanyAdCreationDialog
        open={state.addCompanyAdModalShown}
        createAd={actions.addCompanyAd}
        companies={adminState.companies}
        handleClose={handleModalClose}
      />
      <CompanyAdEditDialog
        ad={state.adToEdit}
        open={!!state.adToEdit}
        loading={adminState.loading}
        companies={adminState.companies}
        handleClose={() => dispatch({ type: "adToEdit", payload: null })}
        editAd={actions.editCompanyAd}
      />
    </>
  );
};

export default CompanyAds;
