import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import {
  NeighborGroupFileType,
  AnnouncementType,
  EventSubscriberDto,
  NeighborGroupAnnouncement,
  NeighborGroupAnnouncementContract,
  NeighborGroupFile,
} from "../../../definitions/model/NeighborGroup";
import { TextFieldConnector } from "../../../components/Base/FormConnector";
import { NIL } from "uuid";
import FileDropZone from "../../../components/Base/FileDropZone";
import { FileType } from "../../../definitions/Document";
import { v4 as uuid } from "uuid";
import { Delete } from "@mui/icons-material";
import ImportSubscribersDialog from "../Dialogs/ImportSubscribersDialog";
import UserAvatar from "../../../components/Layout/UserAvatar";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate, useParams } from "react-router";

type AnnouncementFormData = {
  topic: string;
  description: string;
  id: string;
  maxSubscribers: number;
  private: boolean;
  range: number;
};

const FileInputWrapper = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const AnnouncementForm = (props: {
  addOrUpdate: (announcement: NeighborGroupAnnouncementContract) => Promise<void>;
  announcement?: NeighborGroupAnnouncement;
  type: AnnouncementType;
  uploadFile: (file: File, type: NeighborGroupFileType) => Promise<FileType | undefined>;
  deleteFile: (fileId: string) => Promise<void>;
  updateSubscribers: (userIds: EventSubscriberDto[]) => Promise<void>;
  goBack: () => void;
  picture: FileType | undefined;
  fileList: NeighborGroupFile[];
  setFileList: React.Dispatch<React.SetStateAction<NeighborGroupFile[]>>;
}) => {
  const [loading, setLoading] = useState(false);
  const [showSubscribersDialog, setShowSubscribersDialog] = useState(false);
  const [showRange, setShowRange] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { groupId } = useParams<{ groupId: string; id: string }>();
  const { picture, fileList, setFileList } = props;

  const methods = useForm<AnnouncementFormData>({
    defaultValues: {
      topic: "",
      description: "",
      maxSubscribers: 0,
      private: false,
      range: 0,
    },
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    setFileList(props.announcement?.Documents || []);
    reset({
      topic: props.announcement?.Topic || "",
      description: props.announcement?.Description || "",
      maxSubscribers: props.announcement?.MaxSubscribers || 0,
      range: props.announcement?.Range || 0,
    });
    setShowRange(props.announcement?.Range !== 0);
  }, [props.announcement]);

  const submit = async (data: AnnouncementFormData, e: any) => {
    try {
      setLoading(true);
      await props.addOrUpdate({
        Id: props.announcement?.Id || NIL,
        Topic: data.topic,
        Description: data.description,
        Type: props.type,
        MaxSubscribers: data.maxSubscribers,
        Created: props.announcement?.Created || new Date(),
        Updated: props.announcement?.Updated || new Date(),
        OwnerId: props.announcement?.OwnerId || NIL,
        Subscribers: [],
        Range: data.range,
      });

      //reset();
    } finally {
      setLoading(false);
      navigate("/neighbor-groups/" + groupId, { state: { openAnnouncements: true } });
    }
  };

  const addFiles = async (files: FileType[]) => {
    for (const file of files) {
      if (file.File) {
        const tempId = uuid();
        setFileList([
          ...fileList,
          { Name: file.Name, FileExtension: file.FileExtension, Id: tempId, dirty: true, Published: file.Published },
        ]);
        const fileType = await props.uploadFile(file.File, NeighborGroupFileType.Document);
        if (fileType) {
          setFileList([...fileList.filter((file) => file.Id !== tempId), fileType]);
        }
      }
    }
  };

  const removeFile = async (fileId: string) => {
    try {
      setFileList(
        fileList.map((x) => {
          if (x.Id === fileId) {
            return { ...x, dirty: true };
          }
          return x;
        })
      );
      await props.deleteFile(fileId);

      setFileList((prev) => prev.filter((file) => file.Id !== fileId));
    } catch (e) {
      console.log(e);
    }
  };

  const addSubscribers = async (subscribers: EventSubscriberDto[]) => {
    try {
      setLoading(true);
      setShowSubscribersDialog(false);
      const newSubscribers = [...(props.announcement?.Subscribers || []), ...subscribers];
      await props.updateSubscribers(newSubscribers);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const removeSubscribers = async (userId: string) => {
    try {
      setLoading(true);
      const subscribers = props.announcement?.Subscribers?.filter((s) => s.Id !== userId) || [];

      await props.updateSubscribers(subscribers);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const changeRangeShow = () => {
    reset({ range: 0 });
    setShowRange(!showRange);
  };

  return (
    <>
      <Grid item container>
        <FormProvider {...methods}>
          <form style={{ width: "100%" }} onSubmit={handleSubmit(submit)}>
            <FormControl fullWidth>
              <Grid container direction="column" spacing={1}>
                {(picture?.Url || props.announcement?.Picture?.Url) && (
                  <Grid
                    item
                    width={150}
                    height={150}
                    sx={{
                      backgroundImage: loading ? "" : `url(${picture?.Url || props.announcement?.Picture?.Url || ""})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      display: "flex",
                      borderRadius: 1,
                      border: theme.palette.divider + " 1px solid",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></Grid>
                )}

                {/* <Grid item>
                  <TextFieldConnector register={register("topic", { required: true })}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder={t("NeighborGroups.AnnouncementTitle") ?? ""}
                      disabled={loading}
                    ></TextField>
                  </TextFieldConnector>
                </Grid> */}
                <Grid item>
                  <TextFieldConnector register={register("description", { required: true })}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      multiline
                      minRows={6}
                      maxRows={6}
                      error={!!errors.description}
                      placeholder={t("NeighborGroups.AnnouncementDescription") ?? ""}
                      disabled={loading}
                    ></TextField>
                  </TextFieldConnector>
                </Grid>
                {/* <Grid item xs={12}>
                  <MyhouseCheckbox label={"Adjust event coverage"} onClick={changeRangeShow} checked={showRange} />
                  {showRange && (
                    <ControllerConnector name="range" rules={{ required: false }}>
                      <Slider aria-label="Temperature" valueLabelDisplay="auto" step={1} marks min={0} max={5} />
                    </ControllerConnector>
                  )}
                </Grid> */}
                <Grid item>
                  <Typography>{t("NeighborGroups.Attachments") ?? ""}</Typography>
                  <List disablePadding>
                    {fileList.map((file) => (
                      <ListItem key={file.Id}>
                        <ListItemButton disabled={file.dirty}>
                          <ListItemText primary={file.Name} />
                          <ListItemSecondaryAction>
                            {file.dirty ? (
                              <CircularProgress />
                            ) : (
                              <IconButton onClick={() => removeFile(file.Id)}>
                                <Delete />
                              </IconButton>
                            )}
                          </ListItemSecondaryAction>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <FileInputWrapper item>
                  <FileDropZone
                    addFiles={addFiles}
                    removeFile={removeFile}
                    chooseFileText={t("Base.Popovers.ChooseFile")}
                    loading={false}
                  />
                </FileInputWrapper>
                {props.type === AnnouncementType.Event && (
                  <>
                    <Grid item container spacing={1} justifyContent={"space-between"} alignItems={"center"}>
                      <Grid item>
                        <Typography>{t("NeighborGroups.MaxSubscribers") ?? ""}</Typography>
                      </Grid>
                      <Grid item>
                        <TextFieldConnector register={register("maxSubscribers", { required: false })}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="number"
                            disabled={loading}
                            inputProps={{ min: 0 }}
                          ></TextField>
                        </TextFieldConnector>
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Typography variant="caption">{t("NeighborGroups.MaxSubscribersHeplerText")}</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={loading}
                        onClick={() => setShowSubscribersDialog(true)}
                      >
                        {t("NeighborGroups.AddSubscribers")}
                      </Button>
                    </Grid>
                  </>
                )}
                <Grid item container spacing={1} justifyContent={"flex-end"} marginBottom={1}>
                  <Grid item>
                    <Button type="submit" variant="outlined" color="secondary" disabled={loading}>
                      <ArrowForwardIosIcon />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </FormControl>
          </form>
        </FormProvider>
        <Grid item container>
          {props.announcement?.Subscribers?.map((x) => (
            <Grid item key={x.Id} sx={{ display: "flex", mx: 1, minWidth: 350, justifyContent: "space-between" }}>
              <Grid item sx={{ display: "flex" }}>
                <Grid item sx={{ display: "flex", alignItems: "center", justifyContent: "center", mr: 1 }}>
                  <UserAvatar small user={x} />
                </Grid>
                <Grid item sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography p={0}>{x.Name}</Typography>
                  <Typography variant="caption">{x.Address}</Typography>
                  <Typography variant="caption">
                    {x.Confirmed ? t("NeighborGroups.Confirmed") : t("NeighborGroups.Unconfirmed")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={async () => {
                    await removeSubscribers(x.Id);
                  }}
                >
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <ImportSubscribersDialog
        open={showSubscribersDialog}
        closeDialog={() => setShowSubscribersDialog(false)}
        announcement={props.announcement!}
        updateSubscribers={addSubscribers}
      />
    </>
  );
};

export default AnnouncementForm;
