import React, { useEffect } from "react";
import { ConnectedProps, connect } from "react-redux";
import { Grid, Container, Typography, Button, Slide, Box, useMediaQuery, useTheme, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Widget from "../NewsStream/components/Widget";
import { WidgetPosition, WidgetPage } from "../../constants/enums";
import ClubContainer from "./components/ClubContainer";
import { ApplicationState } from "../../reducers/store";
import { AppThunkDispatch } from "../../definitions/Action";
import { getWidgetsByPage } from "../../actions/landing.actions";
import { getProduct } from "../../actions/subscriptions.actions";

import wines from "../../assets/club/wines.jpg";
import wineglass from "../../assets/club/wineglass.jpg";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { LanguageType } from "../../definitions/Menu";

const ImageWrapper = styled(Grid)(({ theme }) => ({
  background: `url(${wineglass})`,
  backgroundSize: "cover",
  marginBottom: 30,
  padding: "100px 0 0 10px",
  position: "relative",
  "& p, & h6": {
    color: theme.palette.primary.contrastText,
  },
  [theme.breakpoints.down("sm")]: {
    padding: "80px 10px 10px 10px",
  },
}));

const RegistrationButton = styled(Button)(({ theme }) => ({
  borderRadius: 18,
  background: "#399536",
  color: theme.palette.primary.contrastText,
  margin: "20px 0",
  "& span": {
    fontSize: 14,
    fontWeight: "bold",
  },
}));

const Image = styled("img")(({ theme }) => ({
  width: 220,
  marginTop: 20,
  boxShadow: "0 0 0 1px black",
  position: "absolute",
  right: -15,
  bottom: -15,
  [theme.breakpoints.down("sm")]: {
    width: "auto",
    height: 130,
    top: -35,
  },
}));

const StyledButton = styled(Button)({
  "&.MuiButton-root": {
    width: 175,
    height: 80,
    borderRadius: 9,
    backgroundColor: "#399536",
    color: "#ffffff",
    lineHeight: 1.29,
    margin: "20px 0",
  },
  "& MuiButton-label": {
    fontSize: 14,
  },
});

const ClubsSubscribeIntroPage = (props: ClubsSubscribeIntroPageConnectedProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmDown = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation("translation");

  useEffect(() => {
    props.getWidgetsByPage(WidgetPage.FordelsklubsSubscribe, 0, 10, i18n.language as LanguageType);
  }, [props.getWidgetsByPage, i18n.language]);

  useEffect(() => {
    if (!props.product) props.getProduct();
  }, [props.product]);

  const redirect = () => {
    navigate("/clubs-payment");
  };

  const monthPrice = props.product?.Prices.find((price) => price.RecurringInterval === "month");
  const yearPrice = props.product?.Prices.find((price) => price.RecurringInterval === "year");

  return (
    <ClubContainer>
      <Container maxWidth="md" sx={{ marginBottom: "20px" }}>
        <Grid container justifyContent="space-between">
          <Grid item sm={12} md={8}>
            <Grid item>
              <Typography variant="h3" paragraph>
                {t("Pages.Clubs.Title1", { hostname: window.location.hostname })}
              </Typography>
              <ImageWrapper container justifyContent="space-between">
                <Grid item>
                  {/* <Typography
                    variant="h6"
                    className={classes.bold}
                    style={{ padding: 0 }}
                  >
                    Prøv 3 måneder gratis
                  </Typography>
                  {monthPrice && (
                    <Typography variant="body1" paragraph>
                      Derefter kun {monthPrice.Amount},- / måned
                    </Typography>
                  )} */}
                  <RegistrationButton variant="contained" onClick={redirect}>
                    {t("Pages.Clubs.SignUpToday")}
                  </RegistrationButton>
                  <Typography variant="body1">{t("Pages.Clubs.NoteAboutOffer")}</Typography>
                </Grid>
                <Image src={wines} alt="wines" />
              </ImageWrapper>
            </Grid>

            <Grid item>
              <Typography variant="body1" paragraph fontWeight={"bold"}>
                {t("Pages.Clubs.TextAboutMyhouse", { hostname: window.location.hostname })}
              </Typography>
              <Typography variant="body1" paragraph>
                {t("Pages.Clubs.TextAboutBenefits", { hostname: window.location.hostname })}
              </Typography>
              {/* {monthPrice && (
                <Typography variant="body1">
                  Prøv 3 mdr. gratis og derefter {monthPrice.Amount},-/mdr. –
                  det skal kunne betale sig at være medlem
                </Typography>
              )} */}
              {monthPrice && yearPrice && (
                <StyledButton variant="contained" onClick={redirect}>
                  {t("Pages.Clubs.BecomeMemberNow")} {yearPrice.Amount}
                  {t("Pages.Clubs.KrPerYear")} {monthPrice.Amount}
                  {t("Pages.Clubs.KrPerMonth")}
                </StyledButton>
              )}
            </Grid>
          </Grid>
          <Grid container item sm={12} md={4} spacing={3}>
            {props.widgets &&
              props.widgets
                .filter((widget) => widget.Position === WidgetPosition.RightSide)
                .map((widget) => (
                  <Grid item key={widget.Id}>
                    <Slide key={widget.Id} direction="up" in={true} mountOnEnter unmountOnExit>
                      <Box minWidth={275} maxWidth={isSmDown ? "100%" : 300}>
                        <Widget model={widget}></Widget>
                      </Box>
                    </Slide>
                  </Grid>
                ))}
          </Grid>
        </Grid>
      </Container>
    </ClubContainer>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  widgets: state.landing.widgets,
  loading: state.club.loading,
  product: state.subscriptions.products.find((p) => {
    return p.Id.toLowerCase() === process.env.REACT_APP_BENEFIT_CLUB_PRODUCT_ID?.toLowerCase();
  }),
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getWidgetsByPage: (page: WidgetPage, skip: number, take: number, languageId?: LanguageType) =>
    dispatch(getWidgetsByPage(page, skip, take, "", null, undefined, languageId)),
  getProduct: () => dispatch(getProduct(process.env.REACT_APP_BENEFIT_CLUB_PRODUCT_ID || "")),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type ClubsSubscribeIntroPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(ClubsSubscribeIntroPage);
