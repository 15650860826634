import React from "react";
import Layout from "./AdminPanelBasicLayout";
import { Outlet } from "react-router-dom";
import {
  Widgets,
  SupervisorAccount,
  Map,
  MenuBook,
  PersonAdd,
  ErrorOutline,
  Web,
  DeveloperBoard,
  DynamicFeed,
  Group,
  CardMembership,
  Comment,
  EnergySavingsLeaf,
  Business,
  Book,
  ViewModule,
  OfflineBolt,
  Message,
  Info,
  Engineering,
  ManageAccounts,
  Settings,
} from "@mui/icons-material";
import { MenuItemModel } from "./components/MenuItem";
import User from "../../definitions/model/User";
import { UserType } from "../../constants/enums";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import { useUser } from "../../actions/user.actions";
// import NeighborGroupsPage from "./pages/NeighborGroups/NeighborGroupsPage";
// import EditNeighborGroupPage from "./pages/NeighborGroups/EditNeighborGroupPage";

export interface AdminStateProps {
  user: User;
}

const AdminLayout = (): JSX.Element => {
  const [{ user }] = useUser();
  const pathname = "/admin";
  const listItems: MenuItemModel[] = [
    {
      icon: <ErrorOutline />,
      text: "Dashboard",
      href: pathname + "/dashboard",
    },
  ];

  if (user && user.Role >= UserType.Manager) {
    listItems.push(
      {
        icon: <Widgets />,
        text: "Widgets",
        href: pathname + "/widgets",
      },
      {
        icon: <Group />,
        text: "Articles groups",
        href: pathname + "/articleGroups",
      },
      {
        icon: <Business />,
        text: "Companies",
        href: pathname + "/companies",
      },
      {
        icon: <PersonAdd />,
        text: "Lead alarms",
        href: pathname + "/lead-alarms",
      },
      // {
      //   icon: <Build />,
      //   text: "Craftmens Campaign",
      //   href: pathname + "/craftsmens",
      // },
      {
        icon: <Web />,
        text: "Landings",
        href: pathname + "/landings",
      },
      {
        icon: <Map />,
        text: "Map",
        href: pathname + "/map",
      },
      {
        icon: <MenuBook />,
        text: "News",
        href: pathname + "/news",
      },
      {
        icon: <DynamicFeed />,
        text: "Fordelsklubber",
        href: pathname + "/benefitclubs",
      },
      {
        icon: <DeveloperBoard />,
        text: "Templates",
        href: pathname + "/templates",
      },
      {
        icon: <CardMembership />,
        text: "Products",
        href: pathname + "/products",
      },
      {
        icon: <Comment />,
        text: "Comments",
        href: pathname + "/comments",
      },
      {
        icon: <EnergySavingsLeaf />,
        text: "CO2 Plans",
        href: pathname + "/co2",
      },
      {
        icon: <ViewModule />,
        text: "Navigation",
        href: pathname + "/navigation",
      },
      {
        icon: <Book />,
        text: "Behaviour rules",
        href: pathname + "/behaviourrules",
      },
      {
        icon: <OfflineBolt />,
        text: "Price Predictions",
        href: pathname + "/price-predictions",
      },
      {
        icon: <Message />,
        text: "System Messages",
        href: pathname + "/system-messages",
      },
      {
        icon: <Settings />,
        text: "System Settings",
        href: pathname + "/system-settings",
      },
      {
        icon: <Info />,
        text: "Aggregate view",
        href: pathname + "/aggregate-view",
      },
      {
        icon: <Engineering />,
        text: "Craftsmen",
        href: pathname + "/green-craftsmen",
      },
      {
        icon: <Engineering />,
        text: "Data Policy Editor",
        href: pathname + "/terms-editor",
      }
      // {
      //   icon: <Diversity3 />,
      //   text: "Global neighborgroups",
      //   href: pathname + "/neighbor-groups",
      // }
    );
  }

  if (user && user.Role === UserType.ExternalManager) {
    listItems.push({
      icon: <EnergySavingsLeaf />,
      text: "CO2 Plans",
      href: pathname + "/co2",
    });
  }

  if (user && user.Role === UserType.Administrator) {
    listItems.push({
      icon: <SupervisorAccount />,
      text: "Administrators",
      href: pathname + "/administrators",
    });
    listItems.push({
      icon: <ManageAccounts />,
      text: "Users",
      href: pathname + "/users",
    });
  }

  return (
    <Layout itemsList={listItems} user={user}>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </Layout>
  );
};

export default AdminLayout;
